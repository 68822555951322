import template from "./template_crud";

export const getStorageMaterial = template.getById('/projects','/storage');
export const getStorageMaterialStatus = template.getById('/projects','/storage/status');
export const getStorageEquipment = template.getById('/projects','/storage/equipment')
export const getOurAllStorageMaterial = template.getList('/storage/report/materials');
// our storage
const storageBldg = '/storage/bldg';
export const getOurStorage = template.getList(storageBldg);
export const addOurStorage = template.addOne(storageBldg);
export const getOurStorageById = template.getById(storageBldg);
export const updateOurStorage = template.updById(storageBldg);
export const deleteOurStorage = template.deleteById(storageBldg);
// our storage doc: add material
const ourStorageDocAddMaterial = '/storage/doc/addmaterial';
export const addOurStorageMaterial = template.addOne(ourStorageDocAddMaterial);
export const addOurStorageMaterialById = template.getById(ourStorageDocAddMaterial);
export const addOurStorageMaterialDelete = template.deleteById(ourStorageDocAddMaterial);
export const addOurStorageMaterialList = template.getList(ourStorageDocAddMaterial);
// our storage doc: move to project
const ourStorageDocMoveProject = '/storage/doc/movetoproject';
export const addOurStorageMoveProject = template.addOne(ourStorageDocMoveProject);
export const getOurStorageMoveProject = template.getList(ourStorageDocMoveProject);
export const getOurStorageMoveProjectById = template.getById(ourStorageDocMoveProject)
export const deleteOurStorageMoveProjectById = template.deleteById(ourStorageDocMoveProject);
export const deleteOurStorageMoveProjectRow = {
  path: (obj)=> ourStorageDocMoveProject+ `/${obj.id}/${obj.idMaterial}`,
  type: 'DELETE'
}
// doc removal material from project storage
const removalStorageMaterial = '/doc/removal_p/material'; 
export const removeStorageMaterial = template.addOne(removalStorageMaterial);
export const getRemovalStorageMaterial = template.getList(removalStorageMaterial);
export const getRemovalStorageMaterialById = template.getById(removalStorageMaterial);
export const getRemovalStorageMaterialByIdPrint = template.getById(removalStorageMaterial,'/print');
export const deleteRemovalStorageMaterial = template.deleteById(removalStorageMaterial);
// doc removal equipment from project storage
const removalStorageEquipment = '/doc/removal_p/equipment'; 
export const removeStorageEquipment = template.addOne(removalStorageEquipment);
export const getRemovalStorageEquipment = template.getList(removalStorageEquipment);
export const getRemovalStorageEquipmentById = template.getById(removalStorageEquipment);
export const getRemovalStorageEquipmentByIdPrint = template.getById(removalStorageEquipment,'/print')
export const deleteRemovalStorageEquipment = template.deleteById(removalStorageEquipment);


export const getOurStorageMaterial = template.getById('/storage','/material');
export const getOurStorageMaterialAll = template.getById('/storage','/material/ALL');
export const deleteOurStorageMaterial = {
  path: (idStorage)=>(obj)=>`/storage/${idStorage}/material/${obj.id}`,
  type: 'DELETE'
}
export const updateOurStorageMaterial = {
  path: (obj)=>`/storage/${obj.id}/material/${obj.idMaterial}`,
  type: 'PUT'
}
const moveProject = '/storage/move/storage'
export const getDocMoveProject = template.getProjectId(moveProject)
export const getDocMoveProjectById = template.getProjectIdById(moveProject)
export const addDocMoveProject = template.postProjectId(moveProject)
export const delDocMoveProjectById = template.deleteProjectById(moveProject)