import React from 'react';
import PageList from '../../components/PageListAPI'
import { getDeliveryDoc } from '../../api/acceptance'

function Acceptance(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='deliveryDoc'
        title='Документы поставки'
        columns={[
          { id: 'date', name: 'Дата', type: 'date', formatDate: 'DD.MM.YYYY', width:10 },
          { id: 'typeDocument', name: 'Вид документа' },
        ]}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getDeliveryDoc,
          param: {
            id: props.match.params.id
          }
        }}
        {...props}
        headerInit={{
          sortBy:'date', 
          sortDirection:'desc',          
        }}
        csv={false}
      />
    </div>
  )
}

export default Acceptance;