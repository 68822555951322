import React from 'react'
import Grid from '@material-ui/core/Grid';
import CardMenu from './source/CardMenu'

const Card = ({ arr, name }) => <Grid item><CardMenu arr={arr} name={name} /></Grid>

function StorageMenu({ match }) {
  const str = (typeof match.params.id !== 'undefined') ? `/projects/${match.params.id}` : ''
  const state = {
    equipment: [
      { to: str + '/storage/equipment', primary: 'Остатки на складе' },
      { to: str + '/storage/equipment/dynamics', primary: 'Расходы' },
      { to: str + '/storage/equipment/removal', primary: 'Документы списания' },
      { to: str + '/storage/equipment/removal/add', primary: 'Списать материалы' },
    ],
    materials: [
      { to: str + '/storage/materials', primary: 'Остатки на складе' },
      { to: str + '/storage/materials/dynamics', primary: 'Динамика' },
      { to: str + '/storage/materials/removal', primary: 'Документы списания', add:true },
      { to: str + '/storage/materials/move', primary: 'Переместить на главный склад',  add:true },
    ],
    inventory: [
      { to: str + '/storage/inventory', primary: 'Список' },
      { to: str + '/storage/inventory/removal', primary: 'Переместить на склад',  add:true },
    ],
    // arenda: [
    //   { to: str + '/storage/arenda', primary: 'Склад', disabled: true },
    // ],
    consumables: [
      { to: str + '/storage/consumables', primary: 'Список', disabled: true },
      { to: str + '/storage/consumables/removal', primary: 'Документы списания', disabled: true },
      { to: str + '/storage/consumables/removal/add', primary: 'Списать', disabled: true },
    ]
  }
  return <Grid container>
    <Card arr={state.materials} name='Сметные материалы' />
    {/*<Card arr={state.equipment} name={'Оборудование'} />
      <Card arr={state.arenda} name={'Аренда'} />*/}
    <Card arr={state.inventory} name='Инвентарные объекты' />
    {/*<Card arr={state.consumables} name='Расходные материалы' /> */}
  </Grid>
}

export default StorageMenu;
