import React from 'react';
import * as api from '../../api/facility'
import PageList from '../../components/PageListAPI'

export default function FacilityMachineList(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='facilityResp'
        title='Техника на объекте'
        columns={[
          { id: 'dateCreate', name: 'Дата', type: 'date', formatDate: 'LLL', width: 220 },
          { id: 'govNumber', name: 'Гос. номер', width: 150  },
          { id: 'model', name: 'Модель' },
        ]}
        createItem={true}
        deleteItem={true}
        idName='id'
        loadAPI={{
          request: api.getFacilityMachine,
          param: { idFacility: props.match.params.idFacility },
        }
        }
        deleteAPI={api.delFacilityMachine(props.match.params.idFacility)}
        click={false}
        {...props}
      />
    </div>
  )
}