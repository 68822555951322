import React from 'react';
import PageList from '../../components/PageListAPI'
import { getProjectWayBill, delProjectWayBill } from '../../api/projects'

export default function WayBillList(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='way-bill-list'
        title='Путевые листы'
        columns={[
          { id: 'calendarDate', name: 'Дата по плану', type: 'date', formatDate: 'DD.MM.YYYY' },
          { id: 'calendarTime', name: 'Время', },
          { id: 'machine', name: 'Машина', },
          { id: 'driver', name: 'Водитель', },
          { id: 'medicalCheckup', name: 'Медосмотр', },
          { id: 'conditionCheckup', name: 'Техосмотр', },

        ]}
        createItem={false}
        deleteItem={true}
        loadAPI={{
          request: getProjectWayBill,
          querty: {
          },
          param: {
            id: props.match.params.idProject
          },
          transform: res=>{
            return {...res, get: res.get.map(e=>({...e,medicalCheckup:e.medicalCheckup?'пройден':'' , conditionCheckup:e.conditionCheckup?'пройден':''  }))}
          }
        }}
        deleteAPI={delProjectWayBill(props.match.params.idProject)}
        {...props}
      />
    </div>
  )
};