import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, List, ListItem, ListItemText, Checkbox } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDispath } from '../../../../functions/fetch'
import { getProjectGantDate } from '../../../../api/form-gant.api'
import { formChangeAsObj } from '../../../../reducers/form';
import moment from 'moment'

export default function DialogAddGant({ formName, idProject, formGWork }) {
  const [open, setOpen] = useState(false)
  const [res, setRes] = useState([])
  const [parentsArr, setParents] = useState([])
  const [selected, setSelected] = useState([])
  const dispatch = useDispatch()
  const autoWorkE = useSelector(state => state.autocomplite[formGWork] || state.autocomplite.default)
  const listWorkE = autoWorkE.arr
  const form = useSelector(state => state.form[formName] || state.form.default)
  const handleClick = () => {
    const { values } = form
    const { date } = values
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      querty: {
        date
      },
      progress: true,
      request: getProjectGantDate,
    })).then((res) => {
      setRes(res.get.work)
      let parents = {}
      res.get.work.forEach(el => {
        if (parents[el.parent]) {
          parents[el.parent].count += 1
        }
        else {
          parents[el.parent] = {
            id: el.parent,
            name: el.parentName,
            count: 1,
          }
        }
      });
      setOpen(true)
      setParents(Object.values(parents))
    })
  }
  const handleAdd = () => {
    let eById = {}
    let eAllIds = []
    const { values } = form
    const { date } = values
    res.filter(e => selected.includes(e.parent)).forEach((obj, i) => {
      const id = 'id' + obj.idWork + '_' + i
      const value = listWorkE.find(e => e.id === obj.idWork && e.groupId === obj.idGroup)
      if (!value) return;
      let newCount = obj.count * (1 - 0.01 * obj.progress)
      const dayDif = moment(obj.end, 'YYYY-MM-DD').diff(moment(date, 'YYYY-MM-DD'), 'days') + 1;
      console.log(obj)
      console.log(newCount / dayDif, newCount, dayDif)
      eById[id] = {
        idGant: obj.id,
        balance: newCount,
        idWork: value.id,
        storageUnit: value.storageUnit,
        groupId: value.groupId,
        groupName: value.groupName,
        groupCountRes: `${obj.count - newCount} | ${newCount} | ${obj.count} | ${dayDif}д`,
        value,
        count: newCount / dayDif
      }
      eAllIds.push(id)
    })
    dispatch(formChangeAsObj({
      eById,
      eAllIds,
    }, { name: formName }))
    setOpen(false)
  }
  return <React.Fragment>
    <Button color='primary' onClick={handleClick}>Добавить из ганта</Button>
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => (setOpen(false))}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">Выбрать группу</DialogTitle>
      <DialogContent >
        <List component="nav" aria-label="main mailbox folders">
          {parentsArr.map(obj => {
            const select = selected.includes(obj.id)
            return <ListItem
              button
              key={obj.id}
              onClick={(e) => { if (select) { setSelected(selected.filter(e => obj.id !== e)) } else { setSelected([...selected, obj.id]) } }}
            >
              <Checkbox
                checked={select}
              />
              <ListItemText primary={obj.name || 'Без группы'} />
            </ListItem>
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Отмена
        </Button>
        <Button onClick={handleAdd} color="primary" autoFocus>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}