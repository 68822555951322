import React, { useState, useEffect } from 'react'
import { Button, Grid, Table, TableHead, TableBody, TableRow, TableCell, IconButton, CircularProgress, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { fetchDispath } from '../../functions/fetch'
import { getInputControlById, updInputControl, updInputControlCert } from '../../api/acceptance'
import moment from 'moment'
import GridProps from '../../components/GridProps'
import DeleteIcon from '@material-ui/icons/Delete';
import { getCertificates, addCertificates } from '../../api/certificates'
import PageList from '../../components/PageListAPI'
import { certificateType } from '../certificates/certificates.enum'
import * as actions from '../../reducers/form';
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import { addFile } from '../../api/list'
import TableFile from '../certificates/TableFile'
import { loadFileAPI } from '../../reducers/materialLoad';
import ListFiles from '../certificates/ListFiles';
import { set as setAutocomplite } from '../../reducers/autocomplite'
import { getUserList } from '../../api/users'

const formNameHeader = 'input-control-header'

export default function InputControlOne(props) {
  const [state, setState] = useState({
    dateAccept: '',
    name: '',
    provider: '',
    count: '',
    storageUnit: '',
    certificates: [],
    estimateName: '',
    estimateStorageUnit: '',
    estimateCount: '',
    estimateId: null
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.idDoc,
      },
      progress: true,
      request: getInputControlById(props.match.params.idProject),
    })).then(res => {
      if (res.success) {
        const { get, obj } = res
        setState({
          dateAccept: moment(obj.dateAccept).format('DD.MM.YYYY'),
          name: obj.name,
          provider: obj.provider,
          count: obj.count + '',
          storageUnit: obj.storageUnit,
          certificates: get,
          estimateName: obj.estimateName,
          estimateStorageUnit: obj.estimateStorageUnit,
          estimateCount: obj.estimateCount,
          estimateId: obj.estimateId,
        })
        dispatch(actions.formInitialize({
          storageConditions: obj.storageConditions,
          typeControl: obj.typeControl,
          controlParameter: obj.controlParameter,
          controlResult: obj.controlResult,
          labourResult: obj.labourResult,
          signaturePerson: obj.signaturePerson,
          signaturePersonName: obj.signaturePerson,
          note: obj.note,
          valuetypeControl: { value: obj.typeControl, label: obj.typeControl }
        }, { name: formNameHeader }))
        dispatch(setAutocomplite({ value: obj.signaturePerson + '', label: obj.signaturePersonName }, { field: 'signaturePerson' }))

      }
    }).catch(err => console.log(err))
    return () => {
      dispatch(actions.formDestroy({ name: formNameHeader }))
    }
  }, [dispatch, props.match.params.idDoc, props.match.params.idProject])
  const handleReload = () => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.idDoc,
      },
      progress: true,
      request: getInputControlById(props.match.params.idProject),
    })).then(res => {
      if (res.success) {
        const { get, obj } = res
        setState({
          dateAccept: moment(obj.dateAccept).format('DD.MM.YYYY'),
          name: obj.name,
          provider: obj.provider,
          count: obj.count + '',
          storageUnit: obj.storageUnit,
          certificates: get,
          estimateName: obj.estimateName,
          estimateStorageUnit: obj.estimateStorageUnit,
          estimateCount: obj.estimateCount,
          estimateId: obj.estimateId,
        })
      }
    }).catch(err => console.log(err))
  }
  const typographyProps1 = { style: { fontWeight: 'bold' } }
  const [openNew, setOpenNew] = useState(false)
  const [openExist, setOpenExist] = useState(false)
  const arrProps = [
    { xs: 6, field: 'storageConditions', type: 'text', label: 'Условия хранения' },
    {
      xs: 6, field: 'typeControl', type: 'autoArr', label: 'Вид контроля',
      textProps: { arr: ['Визуальный', 'Инструментальный', 'Лабораторный'].map(e => ({ value: e, label: e })) }
    },
    { xs: 6, field: 'controlParameter', type: 'text', label: 'Контролируемый параметр' },
    { xs: 6, field: 'labourResult', type: 'text', label: 'Результат лабораторного контроля' },
    { xs: 6, field: 'controlResult', type: 'text', label: 'Результат проверки' },
    {
      xs: 6,
      field: 'signaturePerson', type: 'autoLoad', label: 'Лицо осуществляющее контроль',
      textProps: {
        path: getUserList.path,
        meta: {
          field: 'signaturePerson',
          value: 'id',
          labelFunc: (obj) => (obj.surname || '') + ' ' + (obj.name || '') + ' ' + (obj.patronymic || '')
        }
      }
    },
  ]
  const form = useSelector(state => state.form[formNameHeader] || state.form.default)
  const handleSaveHeader = () => {
    const { values } = form
    dispatch(actions.formSubmitAPI({
      props: {
        storageConditions: values.storageConditions,
        typeControl: values.typeControl,
        controlParameter: values.controlParameter,
        controlResult: values.controlResult,
        labourResult: values.labourResult,
        signaturePerson: values.signaturePerson,
      },
      url: updInputControl(props.match.params.idProject).path({
        id: props.match.params.idDoc
      }),
      method: updInputControl(props.match.params.idProject).type,
    }))
  }
  return <div className='App-paper'>
    <Grid
      container
      spacing={1}
    >
      <GridProps name='Дата поставки' typographyProps1={typographyProps1} value={state.dateAccept} xsStart={3} xsEnd={9} />
      <GridProps name='Наименование материала' typographyProps1={typographyProps1} value={state.name} xsStart={3} xsEnd={9} />
      {state.estimateId && <GridProps name='Наименование поставщика' typographyProps1={typographyProps1} value={state.estimateName} xsStart={3} xsEnd={9} />}
      <GridProps name='Поставщик' typographyProps1={typographyProps1} value={state.provider} xsStart={3} xsEnd={9} />
      <GridProps name='Количество' typographyProps1={typographyProps1} value={state.count + ' ' + state.storageUnit} xsStart={3} xsEnd={9} />
      {state.estimateId && <GridProps name='Количество поставщика' typographyProps1={typographyProps1} value={state.estimateCount + ' ' + state.estimateStorageUnit} xsStart={3} xsEnd={9} />}
    </Grid>
    <hr className='App-divider' />
    <Typography variant='h6'>Параметры</Typography>
    <SimpleAdd formName={formNameHeader} arr={arrProps} gridProps={{ xs: 12 }} />
    <Button color='primary' onClick={handleSaveHeader}>Сохранить изменения</Button>
    <hr className='App-divider' />
    <Typography variant='h6'>Сертификаты</Typography>
    <div>
      <Button color={openNew ? 'secondary' : 'primary'} onClick={() => { setOpenNew(state => !state); setOpenExist(false); }}>Добавить новый</Button>
      <Button color={openExist ? 'secondary' : 'primary'} onClick={() => { setOpenExist(state => !state); setOpenNew(false) }}>Добавить существующий</Button>
    </div>
    <TableCertificates
      certificates={state.certificates}
      setReload={() => handleReload()}
      idProject={props.match.params.idProject}
      idDoc={props.match.params.idDoc}
    />
    <TableExistCertificates {...props} open={openExist} close={() => setOpenExist(false)} setReload={() => handleReload()} />
    <CertificatesAdd match={props.match} open={openNew} close={() => setOpenNew(false)} setReload={() => handleReload()} />
    <Button color="primary" onClick={() => props.history.goBack()}>Назад</Button>
  </div>
}

function TableCertificates({ certificates, setReload, idProject, idDoc }) {
  const dispatch = useDispatch()
  const handleDelete = (obj) => {
    dispatch(actions.formSubmitAPI({
      props: {
        idCertificate: obj.id,
        typeUpd: 'del'
      },
      url: updInputControlCert(idProject).path({
        id: idDoc
      }),
      method: updInputControlCert(idProject).type,
      endFunc: () => {
        setReload()
      }
    }))
  }
  return <React.Fragment>
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCellHead name='Тип' />
          <TableCellHead name='Наименование' />
          <TableCellHead name='Документ выдан' />
          <TableCellHead name='Номер' />
          <TableCellHead name='Дата' />
          <TableCellHead name='Действителен до' />
          <TableCellHead name='Файлы' />
          <TableCellHead name='Удалить' />
        </TableRow>
      </TableHead>
      <TableBody>
        {certificates.map(obj => {
          return <TableRow>
            <TableCellHead name={obj.typeDoc} />
            <TableCellHead name={obj.name} />
            <TableCellHead name={obj.issuedBy} />
            <TableCellHead name={obj.docNumber} />
            <TableCellHead name={obj.docDate ? moment(obj.docDate).format('DD.MM.YYYY') : ''} />
            <TableCellHead name={obj.validTo ? moment(obj.validTo).format('DD.MM.YYYY') : ''} />
            <TableCell>
              <ListFiles arrFile={obj.files.filter(e => !!e.id)} />
            </TableCell>
            <TableCellHead><IconButton
              aria-label="Delete"
              size='small'
              onClick={(e) => { e.stopPropagation(); handleDelete(obj) }}
            ><DeleteIcon fontSize='small' /></IconButton>
            </TableCellHead>
          </TableRow>
        })}
      </TableBody>
    </Table>
  </React.Fragment>
}
function TableExistCertificates({ history, match, location, open, close, setReload }) {
  const dispatch = useDispatch()
  if (!open) return null
  const handleClick = (row, obj) => {
    dispatch(actions.formSubmitAPI({
      props: {
        idCertificate: obj.id,
        typeUpd: 'add'
      },
      url: updInputControlCert(match.params.idProject).path({
        id: match.params.idDoc
      }),
      method: updInputControlCert(match.params.idProject).type,
      endFunc: () => {
        setReload()
        close()
      }
    }))
  }
  return <PageList
    name='CertificatesList'
    columns={[
      { id: 'docNumber', name: 'Номер документа', },
      { id: 'dateCreate', name: 'Дата создания', type: 'date', formatDate: 'LL' },
      { id: 'name', name: 'Наименование документа', },
      { id: 'typeDoc', name: 'Тип документа', },
      { id: 'validTo', name: 'Действителен до', type: 'date', formatDate: 'LL' },
    ]}
    createItem={false}
    deleteItem={false}
    idName='id'
    history={history}
    match={match}
    location={location}
    loadAPI={{
      param: {
        id: match.params.idProject
      },
      request: getCertificates,
    }}
    filterProps={[
      {
        arr: certificateType.map(e => ({ value: e, name: e })),
        field: 'typeDoc',
        name: 'Тип документа',
        type: 'select'
      },
      {
        type: 'find_text',
        field: 'docNumber',
        name: 'Поиск по номеру'
      },
      {
        type: 'find_text',
        field: 'name',
        name: 'Поиск по наименованию'
      },
    ]}
    headerInit={{
      sortBy: 'date',
      sortDirection: 'desc',
    }}
    goBack={false}
    click={false}
    onClickFunction={handleClick}
    csv={false}
    classes={{
      container: {
        height: '45vh'
      }
    }}
  />
}


const initialState = {
  typeDoc: null,
  name: null,
  issuedBy: null,
  docNumber: null,
  docDate: null,
  validTo: null,
  fById: {},
  fAllIds: [],
  errorValidate: {
    name: false,
  }
}
const formName = 'CertificatesAdd'
function CertificatesAdd({ match, open, close, setReload }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize(initialState, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const arrProps = [
    { field: 'typeDoc', type: 'autoArr', label: 'Тип документа', textProps: { arr: certificateType.map(e => ({ value: e, label: e })) } },
    { field: 'name', type: 'text', label: 'Наименование документа', validator: [value => value !== ''], errorText: 'Поле обязательно', },
    { field: 'issuedBy', type: 'text', label: 'Документ выдан' },
    { field: 'docNumber', type: 'text', label: 'Номер документа' },
    { field: 'docDate', type: 'text', label: 'Дата документа', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { field: 'validTo', type: 'text', label: 'Действителен до', textProps: { type: 'date' }, labelProps: { shrink: true }, },
  ]
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const [loadingFile, setLoadingFile] = useState(false)
  const handleOk = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        typeDoc: values.typeDoc,
        name: values.name,
        issuedBy: values.issuedBy,
        docNumber: values.docNumber,
        docDate: values.docDate,
        validTo: values.validTo,
        files: Object.values(values.fById).map(e => e.id),
      },
      url: addCertificates.path({ id: match.params.idProject }),
      method: addCertificates.type,
      endFunc: (res) => {
        dispatch(actions.formSubmitAPI({
          props: {
            idCertificate: res.id,
            typeUpd: 'add'
          },
          url: updInputControlCert(match.params.idProject).path({
            id: match.params.idDoc
          }),
          method: updInputControlCert(match.params.idProject).type,
          endFunc: () => {
            setReload()
            close()
          }
        }))
      }
    }))
  };
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoadingFile(true)
    let fileName = e.target.files[0].name
    const fileType = e.target.files[0].type
    dispatch(loadFileAPI({ file: e.target.files[0], path: addFile.path }))
      .then(res => {
        if (res.success) {
          dispatch(actions.formObjAdd({ id: res.id, name: fileName, type: fileType }, {
            field: 'fById',
            id: 'id' + res.id,
            name: formName
          }))
          dispatch(actions.formArrayAdd('id' + res.id, {
            field: 'fAllIds', name: formName
          }))
        }
        setLoadingFile(false)
      })
      .catch()
  }
  if (!open) return null;
  return <div>
    <form autoComplete="off" onSubmit={handleOk}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd formName={formName} arr={arrProps} title='Добавить сертификат'>
          <Button disabled={loadingFile} component="label" color='primary'>
            {loadingFile && <CircularProgress size={25} />}
            <span>Прикрепить файл</span>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => _handleImageChange(e)}
            />
          </Button>
        </SimpleAdd>
      </div>
      <TableFile formName={formName} />
      <Button type="submit" color="primary">Сохранить и добавить</Button>
    </form>
  </div>
}

const TableCellHead = ({ name, ...other }) => <TableCell {...(other || {})}>{name}{other?.children}</TableCell>
