import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DescriptionIcon from '@material-ui/icons/Description';
import Chip from '@material-ui/core/Chip';
import { formObjDelete, formArrayDelete } from '../../../../../reducers/form';
import { delFile } from '../../../../../api/list'
import { fetchDispath } from '../../../../../functions/fetch'

export default function TableFile ({ formName }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values={} } = form
  const { fById = {}, fAllIds = [] } = values
  return <div>
    {fAllIds.map(key => {
      const obj = fById[key]
      return <CustChip key={key} row={key} obj={obj} formName={formName} />
    })}
  </div>
}

function CustChip({ row,obj, formName }) {
  const dispatch = useDispatch()
  const [deleted,setDeleted] = useState(false)
  const handleDelete = () => {
    setDeleted(true)
    dispatch(fetchDispath({
      param: {
        id: obj.id,
      },
      progress: false,
      request: delFile,
      buff: true
    }))
      .then(res => {
        setDeleted(false)
        if (!res) return;
        dispatch(formArrayDelete(row, { field: 'fAllIds', name: formName }));
        dispatch(formObjDelete({ id: row, field: 'fById', name: formName }));
      })
  }
  return <Chip
    disabled={deleted}
    key={row}
    icon={<DescriptionIcon />}
    label={obj.name}
    onDelete={()=>handleDelete()}
  />
}