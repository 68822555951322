import React, { useEffect, useState } from 'react'
import { useDispatch, } from 'react-redux'
import { getPlanByIdAPI } from '../../../api/tariff.api'
import { fetchDispath } from '../../../functions/fetch'
import { Button, Typography, Grid } from '@material-ui/core'
import GridProps from '../../../components/GridProps'
import { moduleAccessEnum } from '../../../enum/module-access.enum'

export default function TariffOne({ history, match }) {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    name: '',
    isActive: false,
    price: 0,
    maxUser: 0,
    moduleAccessRus: [],
    period: 0
  })
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idDoc
      },
      progress: true,
      request: getPlanByIdAPI,
    })).then(res => {
      setState({
        ...res.get,
        moduleAccessRus: res.get.moduleAccess.map(e => {
          const find = moduleAccessEnum.find(s => s.value === e)
          return find?.label
        })
      })
    }).catch(err => console.log(err))
  }, [dispatch, match.params.idDoc])
  return <div className='App-paper'>
    <Typography variant='h6'>Тариф {state.name}</Typography>
    <Grid
      item
      container
      xs={9}
    >
      <GridProps name='Активный ' value={state.isActive ? 'Да' : 'Нет'} />
      <GridProps name='Стоимость в период' value={state.price} />
      <GridProps name='Максимальное количество пользователей ' value={state.maxUser} />
      <GridProps name='Период оплаты (мес) ' value={state.period} />
      <Grid>
        Доступные модули
        <ul>
          {state.moduleAccessRus.map((e, i) => {
            return <li key={i}>{e}</li>
          })}
        </ul>
      </Grid>
    </Grid>
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}