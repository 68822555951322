import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import * as api from '../../api/facility'

import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import { getMachine } from '../../api/list'

const formName = 'FacilityMachineAdd'

export default function FacilityMachineAdd({ history, match }) { 
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ idMachine: '', errorValidate: { } }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [
    {
      field: 'idMachine', type: 'autoLoad', label: 'техника',
      textProps: {
        path: getMachine.path,
        meta: {
          field: 'machine',
          value: 'id',
          labelFunc: (obj) => obj.model+ ' '+ obj.govNumber 
        }
      }
    },
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        idMachine: values.idMachine,
        workers: values.workers
      },
      url: api.addFacilityMachine.path({ idFacility: match.params.idFacility }),
      method: api.addFacilityMachine.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Добавить технику на объект'
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}
