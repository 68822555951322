import React from 'react';
import * as api from '../../api/facility'
import PageList from '../../components/PageListAPI'

export default function FacilityList(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='facility'
        title='Список доступа'
        columns={[
          { id: 'name', name: 'Имя' },
          { id: 'surname', name: 'Фамилия' },
          { id: 'patronymic', name: 'Отчество' },
        ]}
        createItem={true}//!!!!!!!!!!!!!!!!!!!!
        deleteItem={true}
        idName='id'
        loadAPI={{
          param: {
            idFacility: props.match.params.id,
          },
          request: api.getFacilityAccess
        }
        }
        deleteAPI={api.delFacilityAccess(props.match.params.id)}
        click={false}
        {...props}
      />
    </div>
  )
}