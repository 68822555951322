const MAIN ='snackbar/' 
export const enqueueSnackbar = notification => ({
    type: MAIN+'ENQUEUE',
    notification: {
        key: new Date().getTime(),// + Math.random()*100,
        ...notification,
    },
});

export const removeSnackbar = key => ({
    type: MAIN+'REMOVE',
    key,
});

export const initialState = {
    notifications: [],
};

export default function notifier (state = initialState, action) {
  switch (action.type) {
    case MAIN+'ENQUEUE': 
      return { ...state, notifications: [...state.notifications, { ...action.notification, }, ], };
    case MAIN+'REMOVE':
      return {
        ...state,
        notifications: state.notifications.filter(
            notification => notification.key !== action.key,
        ),
      };

    default:
      return state;
  }
};
