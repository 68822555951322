import { getProjectId, postProjectId, deleteProjectById } from './template_crud';
export const getWork = getProjectId('/work');
export const updWorkPrice ={
	path: (obj) => `/projects/${obj.id}/work/price`,
	type: 'PUT'
}
export const addWork = postProjectId('/work/add');
export const loadEstimateToWork = postProjectId('/work/load-estimate');
export const getWorkById = {
	path: (obj) => `/projects/${obj.id}/work/${obj.idWork}`,
	type: 'GET'
}
export const updateWorkById = {
	path: (obj) => `/projects/${obj.id}/work/${obj.idWork}`,
	type: 'PUT'
}
export const deleteWorkById = deleteProjectById('/work');
export const getWorkMaterial = {
	path: (obj) => `/projects/${obj.id}/work/${obj.idWork}/materials`,
	type: 'GET'
}
export const addWorkMaterial = {
	path: (obj) => `/projects/${obj.id}/work/${obj.idWork}/materials`,
	type: 'POST'
}

export const addWorkResources = {
	path: (obj) => `/projects/${obj.id}/work/${obj.idWork}/resources`,
	type: 'POST'
}
export const updWorkResources = {
	path: (obj) => `/projects/${obj.id}/work/${obj.idWork}/resources/${obj.idResource}`,
	type: 'PUT'
}
export const deleteWorkResources = {
	path: (obj) => `/projects/${obj.id}/work/${obj.idWork}/resources/${obj.idResource}`,
	type: 'DELETE'
}