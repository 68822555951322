import React from 'react'
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment'
import getColor from '../../../functions/getColorAttend'

export default function PWRTable({ dateStart, dateEnd, generate }) {
  const formTable = useSelector(state => state.formTable['pwr'] || state.formTable.default)
  const dateStartM = moment(dateStart);
  const dateEndM = moment(dateEnd);
  const dif = dateEndM.diff(dateStartM, 'days')
  let arrDay = [{
    name: dateStartM.format('DD.MM'),
    day: dateStartM.format('YYYY-MM-DD')
  }]
  for (let i = 0; i < dif - 1; i++) {
    const d = dateStartM.add(1, 'day')
    arrDay.push({
      name: d.format('DD.MM'),
      day: d.format('YYYY-MM-DD')
    })
  }
  if (!generate) return null
  return (<Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Имя</TableCell>
        {arrDay.map(el => {
          return <TableCell padding='checkbox' key={el.name}>{el.name}</TableCell>
        })}
      </TableRow>
    </TableHead>
    <TableBody>
      {formTable.allIds.map(el => {
        const obj = formTable.byId[el]
        return <TableRow key={el}>
          <TableCell>{obj.snp}</TableCell>
          {arrDay.map(el => {
            return <TableCell padding='checkbox' key={el.day}>
              <div style={{ width: 20, height: 20, background: getColor(obj.date[el.day]) }} ></div>
            </TableCell>
          })}
        </TableRow>
      })}
    </TableBody>
  </Table>
  )
}