import React from 'react';
import PageList from '../../../components/PageListAPI'
import can from '../../../functions/can'
import { getCarRental, deleteCarRental } from '../../../api/doc'

export default function CarRental(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='carRental'
        title='Документы аренды спец. техники'
        columns={[
          { id: 'model', name: 'Марка,модель авто', },
          { id: 'licensePlate', name: 'Гос. знак' },
          { id: 'transportationDetails', name: 'Сведения о перевозке' },
          { id: 'companyShortName', name: 'Организаця' },
          {
            id: 'driver', name: 'Водитель', type: 'component',
            component: ({ obj }) => <div>
              {obj.driverSurname}  {obj.driverName[0]}.  {obj.driverPatronymic[0]}.
            </div>
          },
          { id: 'dateStart', name: 'Дата начала', type: 'date' },
          { id: 'dateEnd', name: 'Дата окончания', type: 'date' },
          { id: 'hours', name: 'Часы' },
          { id: 'price', name: 'Стоимость за час' },
        ]}
        createItem={can('shipment:update')}
        deleteItem={can('shipment:delete')}
        loadAPI={{
          querty: {
            idProject: props.match.params.idProject
          },
          request: getCarRental,
        }}
        deleteAPI={deleteCarRental}
        click={false}
        {...props}
      />
    </div>
  )
};


