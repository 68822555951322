import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton, Tooltip, Input, Table, InputLabel,
  TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMaterialFromFile, selectColumnMaterialFromFile, editTextMaterialLoad } from '../../reducers/materialLoad';
import ClearIcon from '@material-ui/icons/Clear'
import DoneIcon from '@material-ui/icons/Done'
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';


const useStyle = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  input: {
    display: 'none',
  },
  edt: {
    minWidth: 50,
    padding: '1px', /* Поля вокруг текста */
  },
  main: {
    margin: theme.spacing(1)
  },
}));

export default function TableMaterial() {
  const classes = useStyle()
  const dispatch = useDispatch()
  const data = useSelector(state => state.materialLoad)
  return <Table className={classes.table}>
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {Object
          .keys(data.column)
          .filter(key => data.column[key].visible)
          .map(key => <TableHeadCell key={key} id={key} classes={classes} />)}
      </TableRow>
    </TableHead>
    <TableBody>
      {data.allIds
        .map(row => {
          const obj = data.byId[row]
          return <TableRow key={obj.row}>
            <TableCell className={classes.edt} size='small'>
              <Tooltip title="Delete">
                <IconButton aria-label="Delete" size='small' className={classes.margin} onClick={() => { dispatch(deleteMaterialFromFile({ id: row })) }}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </TableCell>
            {Object
              .keys(data.column)
              .filter(key => data.column[key].visible)
              .map(key => <TableCellCustom value={obj[key]} row={row} key={row + ' ' + key} col={key} />)}
          </TableRow>
        })}
    </TableBody>
  </Table>
}

function TableCellCustom({ value = '', row, col }) {
  const dispatch = useDispatch()
  const [edit, changeEdit] = useState(false)
  const [editText, changeEditText] = useState(value)
  const handleOk = () => dispatch(editTextMaterialLoad({ id: row, key: col, value: editText }))
  return <TableCell size='small' onClick={() => { if (!edit) changeEdit(true) }}>
    {edit ?
      <div>
        <Input
          value={editText}
          onChange={(e) => changeEditText(e.target.value)}
        />
        <IconButton onClick={() => { handleOk(); changeEdit(false) }}><DoneIcon /></IconButton>
        <IconButton onClick={() => { changeEditText(value); changeEdit(false) }}><ClearIcon /></IconButton>
      </div>
      :
      <div>{value}</div>
    }
  </TableCell>
}

function TableHeadCell({ id, classes }) {
  const dispatch = useDispatch()
  const [enter, setEnter] = useState(false)
  return (
    <TableCell className={classes.edt} padding='none' >
      <div onMouseLeave={() => setEnter(false)} onMouseEnter={() => setEnter(true)}>
        {(enter) ?
          <IconButton aria-label="Delete" size='small' onClick={() => {
            dispatch(selectColumnMaterialFromFile({ column: id, check: false }))
          }}>
            <CancelIcon fontSize='small' />
          </IconButton> : <InputLabel>{id} </InputLabel>
        }
      </div>
    </TableCell>
  )
}