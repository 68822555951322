import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../../../reducers/autocomplite'
import { addMaterials } from '../../../../api/receipt'
import { getMaterials } from '../../../../api/material'
import DocTable from '../../../../components/DocTable'
import DocAdd from '../../../../components/DocAdd'
import { getStorageUnit } from '../../../../api/list'

const formName = 'ReceiptMaterialsAdd'
const formArr = 'material'
const materialArr = 'storageUnit'
export default function ReceiptMaterialsAdd({ match, history }) {
  const idProject = match.params.id
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      allIds: [],
      byId: {},
      date: '',
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: formArr }))
    dispatch(initialAutocomplite({ field: materialArr }))
    return () => {
      dispatch(clearAutocomplite({ field: formArr }))
      dispatch(clearAutocomplite({ field: materialArr }))
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(
      getMaterials.path({ id: idProject }) + '?balance=provider&type=balance',
      {
        field: formArr,
        value: 'id',
        hidden: ['balance', 'storageUnit'],
        labelFunc: (obj) => obj.name + ' ' + obj.typeMark + ' ' + obj.vendorCode,
        filter: (arr)=> arr.filter(e=>e.count>0),
      }))
    dispatch(loadAutocompliteAPI(getStorageUnit.path, {
      field: materialArr,
      value: 'id',
      labelFunc: (obj) => obj.name
    }))
  }, [dispatch, idProject])
  const handleSave = () => {
    const { date, byId, allIds, name } = values
    const arr = []
    allIds.forEach(key => {
      const obj = byId[key]
      if (obj.count > 0) arr.push({
        idMaterial: obj.idMaterial,
        count: obj.count,
        coef: obj.coef,
        idStorageUnit: obj.idStorageUnit
      })
    })
    dispatch(actions.formSubmitAPI({
      props: {
        arr: arr,
        date: date,
        idProject: idProject,
        name: name
      },
      url: addMaterials.path,
      method: addMaterials.type,
      history: history
    }))
  }
  return <div className='App-paper'>
    <DocAdd
      formName={formName}
      history={history}
      title='Документ получение материалов от заказчика'
      handleSave={handleSave}
      noTableProps={[
        { field: 'date', label: 'Дата поставки', type: 'text', textProps: { type: 'date' } },
        { xs: 9, field: 'name', label: 'Наименование документа', type: 'text', },
      ]}
    >
      <DocTable
        formName={formName}
        column={[
          {
            id: 'value', type: 'auto', name: 'Материал', formArr: formArr, width: '45%',
            filtered: true, changeValue: text => ({ idMaterial: text.value, value: text, balance: text.balance, storageUnit: text.storageUnit })
          },
          {
            id: 'count', type: 'number', name: 'Количество', title: obj => `max ${obj.balance}`, width: '20%',
            textProps: obj => ({ error: obj.count > obj.balance, helperText: obj.count > obj.balance ? 'Больше чем по смете' : '' })
          },
          { id: 'storageUnit', type: 'string', name: 'Ед.изм' },
          {
            id: 'valueStorageUnit', type: 'auto', name: 'Ед. изм. поставщика', formArr: materialArr, width: '15%',
            filtered: false, changeValue: text => ({ idStorageUnit: text.value, valueStorageUnit: text, })
          },
          { id: 'coef', type: 'number', name: 'K=(new/old)' },
        ]}
        rowDefault={{
          idMaterial: '',
          count: '',
          balance: '',
          value: null,
          valueStorageUnit: null,
          coef: 1,
          idStorageUnit: null
        }}
      />
    </DocAdd>
  </div>
}