import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import { addRegions } from '../../../api/list'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'


export default function RegionAdd({ history }) {
  const dispatch = useDispatch()
  const formName = 'RegionAdd'
  useEffect(() => {
    dispatch(actions.formInitialize({ name: '', errorValidate: { name: false } }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [{
    field: 'name', label: 'Наименование', type: 'text',
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  }]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: { name: values.name, },
      url: addRegions.path,
      method: addRegions.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd formName={formName} arr={arrProps} title='Добавить регион' />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}
