import React, { useEffect, useState } from 'react'
import {
  getProjectMachineId,
} from '../../api/projects'
import { useDispatch } from 'react-redux'
import { Grid, Button, Tooltip, Typography } from '@material-ui/core'
import { fetchDispath } from '../../functions/fetch'
import GridProps from '../../components/GridProps'
import moment from 'moment'

export default function OneCalendar({ match, history }) {
  const docId = match.params.idDoc
  const idProject = match.params.idProject;
  const [param, setParam] = useState({
    date: '',
    time: '',
    duration: '',

    mileageStart: '',
    mileageEnd: '',
    link: '',
    pswd: '',
    dateStart: '',
    dateEnd: '',

    model: '',
    govNumber: '',

    driver: '',
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: { id: docId, },
      progress: true,
      request: getProjectMachineId(idProject),
    })).then(res => {
      setParam({
        date: moment(res.get.date).format('DD.MM.YYYY'),
        time: res.get.time,
        duration: res.get.duration,
        mileageStart: res.get.mileageStart,
        mileageEnd: res.get.mileageEnd,
        link: res.get.link,
        pswd: res.get.pswd,
        dateStart: res.get.dateStart,
        dateEnd: res.get.dateEnd,
        model: res.get.model,
        govNumber: res.get.govNumber,
        driver: `${res.get.surname} ${res.get.name} ${res.get.patronymic}`,
      })
    }).catch(err => console.log(err))
  }, [dispatch, docId, idProject])
  const handleClick = (text) => {
    navigator.clipboard.writeText(text)
  }
  return <div className='App-paper'>
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item container xs={8}>
        <GridProps name='Модель транспорта:' value={param.model} />
        <GridProps name='Гос. номер:' value={param.govNumber} />
        <GridProps name='Водитель:' value={param.driver} />
        <GridProps name='Дата:' value={param.date} />
        <GridProps name='Время:' value={param.time} />
        <GridProps name='Продолжительность:' value={param.duration} />
        <GridProps name='Начало работы:' value={param.dateStart ? moment(param.dateStart).format('LLL') : ''} />
        <GridProps name='Конец работы:' value={param.dateEnd ? moment(param.dateEnd).format('LLL') : ''} />
        <GridProps name='Значение одометра на старте:' value={param.mileageStart} />
        <GridProps name='Значение одометра в конце:' value={param.mileageEnd} />
        <GridProps name='Ссылка:'
          value={
            <div onClick={()=>handleClick(`${window.location.origin}/machine-set/${param.link}`)} style={{ cursor: 'pointer' }}>
              <Tooltip title="Копировать в буфер ссылку" placement="bottom">
                <Typography >{`${window.location.origin}/machine-set/${param.link}`}</Typography>
              </Tooltip>
            </div>
          } />
        <GridProps name='Пароль для доступа:'
        value={
          <div onClick={()=>handleClick(param.pswd)} style={{ cursor: 'pointer' }}>
            <Tooltip title="Копировать в буфер пароль" placement="bottom">
              <Typography >{param.pswd}</Typography>
            </Tooltip>
          </div>
        } />
      </Grid>
    </Grid>
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
  </div>
}