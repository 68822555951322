import React from 'react';
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux';
import * as actions from '../../../reducers/formTable';
import { fetchDispath } from '../../../functions/fetch'
import { getRemovalStorageMaterialById, getRemovalStorageMaterialByIdPrint } from '../../../api/storage'
import moment from 'moment'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import print from '../../../functions/print'
import { ENUM_TYPE_REMOVAL } from './removalPMaterial.const'

class RemovalPMaterialById extends React.Component {
  constructor(props){
    super()
    this.state={
      name:'removalStorageMaterialById',
      dateRemoval:'',
      user:'',
      id:''
    }
    props.actions.init({name:this.state.name})
  }
  componentDidMount() {
    this.load(this.state)
  }
  load = ()=>{
    this.props.actions.fetchDispath({
      param: {
        id:this.props.match.params.idRemoval
      },
      progress: true,
      request: getRemovalStorageMaterialById,
    }).then(res=>{
      this.setState({...res.header, typeRemovalRus: ENUM_TYPE_REMOVAL.find(e=>e.value===res.header.typeRemoval).label})
      this.props.actions.load({get:res.get, count:res.get.length},{ name:this.state.name, id:'id'})
    }).catch(err=>console.log(err))
  }
  handlePrint(){
    print(
      this.props.actions.fetchDispath,
      {
        param: {
          id:this.props.match.params.idRemoval
        },
        request: getRemovalStorageMaterialByIdPrint,
      }
    )
  }
  componentWillUnmount(){
    this.props.actions.clear(this.state.name)
  }
  render(){
    const data =this.props.formTable[this.state.name]||this.props.formTable.default
    return <div className='App-paper'>
      <Button color='primary' onClick={()=>this.handlePrint()}>Печать </Button>
      <Typography variant="body1" gutterBottom>
        Пользователь: {this.state.user}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Дата списания: {moment(this.state.dateRemoval).format('DD.MM.YYYY')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Тип списания: {this.state.typeRemovalRus}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Наименование</TableCell>
            <TableCell>Количество</TableCell>
            <TableCell>Ед. изм.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data.allIds.map(row =>{
          const obj = data.byId[row];
          return(
            <TableRow id='row' key={row}>
              <TableCell component="th" scope="row">{obj.name}</TableCell>
              <TableCell >{obj.count}</TableCell>
              <TableCell >{obj.storageUnit}</TableCell>
            </TableRow>
          )
        })}
        </TableBody>
      </Table>
      <Button color="primary" onClick={()=>this.props.history.goBack()}>Назад </Button>
      </div>
  }
}

const mapStateToProps = store => {
  return {
    formTable: store.formTable
  }
}
const mapDispatchToProps = dispatch => {
  return {actions: {
    load: (obj,meta) => dispatch(actions.loadFormTable(obj,meta)),
    clear : obj => dispatch(actions.clearFormTable(obj)),
    init : obj => dispatch(actions.initFormTable(obj)),
    fetchDispath: obj => dispatch(fetchDispath(obj)),
  }}
}
export default connect(  
  mapStateToProps,
  mapDispatchToProps
)(RemovalPMaterialById);