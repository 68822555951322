import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogTitle, TextField, DialogContent, DialogActions, Button, List, ListItem, ListItemSecondaryAction, IconButton, Grid, Typography } from '@material-ui/core'
import * as actions2 from '../../reducers/form';
import DeleteIcon from '@material-ui/icons/Delete';
import { addRowFormTable, editFieldTable, deleteFormTable } from '../../reducers/formTable';
import { add, editLabel } from '../../reducers/autocomplite';
import { addGroup, editGroup, deleteGroup } from '../../api/estimate'
import { green, red } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';

export default function DialogAddEditGroup({ idProject, changeGroup, onClose }) {
  const [add, changeAdd] = useState(false)
  const formGroup = useSelector(state => state.formTable['groupEstimate'] || state.formTable.default)
  const { byId = {}, allIds = [] } = formGroup
  return (
    <Dialog anchor='top' open={changeGroup} maxWidth='sm' fullWidth>
      <DialogTitle id="simple-dialog-title">Управление группами</DialogTitle>
      <DialogContent>
        <Button color='primary' onClick={() => changeAdd(true)}>Добавить</Button>
        <List >
          {add && <ItemAdd idProject={idProject} onClose={() => changeAdd(false)} />}
          {allIds.map(e => <React.Fragment key={e}>
            <ItemGroup id={e} obj={byId[e]} idProject={idProject} />
            <hr className='App-divider' />
          </React.Fragment>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Закрыть
      </Button>
      </DialogActions>
    </Dialog>
  );
}

function ItemGroup({ obj, id, idProject }) {
  const [edit, chageEdit] = useState(false);
  const [deleted, changeDeleted] = useState(false)
  const [textEdit, changeText] = useState(obj.name)
  const [numberEdit, changeNumber] = useState(obj.number)

  const dispatch = useDispatch()
  const onSaveChange = () => {
    dispatch(actions2.formSubmitAPI({
      props: { name: textEdit, number: numberEdit },
      url: editGroup(idProject).path({ id: obj.id }),
      method: editGroup(idProject).type,
      endFunc: (res) => {
        chageEdit(false)
        dispatch(editFieldTable(textEdit, { id, field: 'name', name: 'groupEstimate' }))
        dispatch(editFieldTable(numberEdit, { id, field: 'number', name: 'groupEstimate' }))
        dispatch(editLabel(numberEdit + '. ' + textEdit, { value: obj.id.toString(), field: 'group' }))
      }
    }, 'dialogEL'))
  }
  const onDeleted = () => {
    dispatch(actions2.formSubmitAPI({
      url: deleteGroup(idProject).path({ id: obj.id }),
      method: deleteGroup(idProject).type,
      endFunc: (res) => {
        changeDeleted(false)
        dispatch(deleteFormTable({ id }, { id, name: 'groupEstimate' }))
        dispatch(editLabel('DELETED', { value: obj.id.toString(), field: 'group' })) // сделать нормальное удаление
      }
    }, 'dialogEL'))
  }
  if (deleted) {
    return <ListItem>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <Grid item>
          <Typography>Вы уверены?</Typography>
        </Grid>
        <Grid item>
          <IconButton style={{ color: green[500] }} onClick={() => onDeleted()} size='small'><CheckIcon /></IconButton>
          <IconButton style={{ color: red[500] }} onClick={() => changeDeleted(false)} size='small'><CloseIcon /></IconButton>
        </Grid>
      </Grid>
    </ListItem>
  }
  if (edit) {
    return <ListItem>
      <TextField
        onChange={(e) => changeNumber(e.target.value)}
        value={numberEdit}
      />
      <TextField
        onChange={(e) => changeText(e.target.value)}
        value={textEdit}
        fullWidth
      />
      <IconButton style={{ color: green[500] }} onClick={() => onSaveChange()} size='small'><CheckIcon /></IconButton>
      <IconButton style={{ color: red[500] }} onClick={() => chageEdit(false)} size='small'><CloseIcon /></IconButton>
    </ListItem>
  }
  return <ListItem>
    {obj.number}. {obj.name}
    <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="edit" onClick={() => { chageEdit(true) }}><Edit /></IconButton>
      <IconButton edge="end" aria-label="delete" onClick={() => { changeDeleted(true) }}><DeleteIcon /></IconButton>
    </ListItemSecondaryAction>
  </ListItem>
}

function ItemAdd({ idProject, onClose }) {
  const [textEdit, changeText] = useState('')
  const dispatch = useDispatch()
  const onSaveChange = () => {
    dispatch(actions2.formSubmitAPI({
      props: { name: textEdit },
      url: addGroup.path({ id: idProject }),
      method: addGroup.type,
      endFunc: (res) => {
        dispatch(addRowFormTable({
          id: res.get.id,
          name: res.get.name,
          number: res.get.number,
          res: [],
          ressumm: 0
        }, { name: 'groupEstimate', end: true }))
        dispatch(add({ value: res.get.id, label: res.get.number + '. ' + res.get.name }, { field: 'group' }))
        onClose()
      },
    }, 'dialogEL'))
  }
  return <ListItem>
    <TextField
      onChange={(e) => changeText(e.target.value)}
      value={textEdit}
      fullWidth
    />
    <IconButton style={{ color: green[500] }} onClick={() => onSaveChange()} size='small'><CheckIcon /></IconButton>
    <IconButton style={{ color: red[500] }} onClick={() => onClose(false)} size='small'><CloseIcon /></IconButton>
  </ListItem>
}