import React from 'react';
import PageList from '../../../components/PageListAPI'
import can from '../../../functions/can'
import { deleteStorageUnit, getStorageUnit } from '../../../api/list'

export default function StorageUnit(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='storageUnit'
        columns={[{ id: 'name', name: 'Название' }]}
        createItem={can('material:create')}
        deleteItem={can('material:delete')}
        loadAPI={{
          request: getStorageUnit,
        }}
        deleteAPI={deleteStorageUnit}
        {...props}
      />
    </div>
  )
};