import React, { useState } from 'react'
import { Tab, Tabs, Typography } from '@material-ui/core';
import ProjectMain from './ProjectMain'
import ProjectUsers from './ProjectUsers'
import ProjectArchive from './ProjectArchive'
import ProjectCopy from './ProjectCopy'
import ProjectSettingTemplate from './ProjectSettingTemplate'
function ProjectSettings({ match, history, location }) {
  const [selectTabs, setTabs] = useState(0)
  return (
    <div className='App-paper'>
      <Typography variant='h6'>Настройка проекта</Typography>
      <Tabs value={selectTabs} onChange={(e, value) => setTabs(value)} indicatorColor='primary'>
        <Tab label="Основные" />
        <Tab label="Пользователи" />
        <Tab label="Копировать данные" />
        <Tab label="Архивировать" />
        <Tab label="Шаблон" />
      </Tabs>
      {selectTabs === 0 && <ProjectMain idProject={match.params.idProject} history={history} />}
      {selectTabs === 1 && <ProjectUsers match={match} history={history} location={location} />}
      {selectTabs === 2 && <ProjectCopy idProject={match.params.idProject} history={history} />}
      {selectTabs === 3 && <ProjectArchive match={match} history={history} location={location} />}
      {selectTabs === 4 && <ProjectSettingTemplate idProject={match.params.idProject} />}
    </div>
  )
}

export default ProjectSettings;