
export default function validateProps(arrProps, values) {
  let checkAll = false
  let props = {}
  arrProps.forEach(obj => {
    if (obj.validator) {
      let check = false
      obj.validator.forEach(func => {
        check = check || !func(values[obj.field])
      })
      props[obj.field] = check
      checkAll = checkAll || check
    }
  })
  return { check: checkAll, validate: props }
}