import React from 'react'
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';

function PWATableNA({ selectWorker }) {
  const table = useSelector(state => state.formTable['project-workrer-na'] || state.formTable.default)
  return (<React.Fragment>
    {(table.allIds.length>0)&&<Typography variant='h6'>Не учтены</Typography>}
    <List component="nav">
      {table.allIds.map(el => {
        const obj = table.byId[el]
        return <ListItem key={el} button onClick={() => selectWorker(obj)}>
          <ListItemText primary={obj.snp} />
        </ListItem>
      })}
    </List>
  </React.Fragment>
  )
}
export default PWATableNA;