import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import 'moment/locale/ru';
import * as actions from '../../../../reducers/formTable';
import { fetchDispath } from '../../../../functions/fetch'
import { getToolWriteById } from '../../../../api/doc'
import { getLabel } from '../function'
// import print from '../../../../functions/print'

class UseToolById extends Component {
  constructor(props){
    super()
    this.state={
      nameTable:'usetoolbyid',
      gSurname : '',
      gName : '',
      surname : '',
      name : '',
      patronymic : '',
      position : '',
      date : '',
      type: ''
    }
    props.actions.init({name:this.state.nameTable})
  }
  componentDidMount() {
    this.load(this.state)
  }
  componentWillUnmount(){
    this.props.actions.clear(this.state.nameTable)
  }
  load = ()=>{
    this.props.actions.fetchDispath({
      param: {
        id:this.props.match.params.id,
        idProject:this.props.match.params.idProject,
      },
      progress: true,
      request: getToolWriteById,
    }).then(res=>{
      this.setState({...res.doc})
      this.props.actions.load({
        get:res.get.map(obj=>({name:obj.name, id:obj.inventoryNumber, inventoryNumber:obj.inventoryNumber.toString().padStart(5, '0') })), 
        count:res.get.length
      }, { name:this.state.nameTable, id:'id'})
    }).catch(err=>console.log(err))
  }
  handlePrint(){
    // print(
      // this.props.actions.fetchDispath,
      // {
        // param: {
          // id:this.props.match.params.idShipment,
        // },
        // request: printShipmentMaterialById,
      // }
    // )
  }
  render(){
    const data =this.props.formTable[this.state.nameTable]||this.props.formTable.default
    return (
      <div className='App-paper'>
        <Button color='primary' onClick={()=>this.handlePrint()}>Печать </Button>
        <Typography variant="body1" gutterBottom >Принял: {this.state.gSurname} {this.state.gName}</Typography>
        <Typography variant="body1" gutterBottom>Сдал: {this.state.surname} {this.state.name} {this.state.patronymic}</Typography>
        <Typography variant="body1" gutterBottom>Должность: {this.state.position}</Typography>
        <Typography variant="body1" gutterBottom>Тип списания: {getLabel(this.state.type)}</Typography>
        <Typography variant="body1" gutterBottom>Дата: {moment(this.state.date).format('LLL') }</Typography>
        <Table style={{minWidth: 400}}>
          <TableHead>
            <TableRow>
            <TableCell >Инвентарный номер</TableCell>
            <TableCell >Наименование</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data.allIds.map(row =>{
            const obj = data.byId[row];
            return(
              <TableRow id='row' key={row}>
              <TableCell >{obj.inventoryNumber}</TableCell>
              <TableCell >{obj.name}</TableCell>
              </TableRow>
            )
          })}
          </TableBody>
        </Table>
        <Button color="primary" onClick={()=>this.props.history.goBack()}>Назад </Button>
      </div>
  )};
}

const mapStateToProps = store => {
  return {
    formTable: store.formTable
  }
}
const mapDispatchToProps = dispatch => {
  return {actions: {
    load: (obj,meta) => dispatch(actions.loadFormTable(obj,meta)),
    clear : obj => dispatch(actions.clearFormTable(obj)),
    init : obj => dispatch(actions.initFormTable(obj)),
    fetchDispath: obj => dispatch(fetchDispath(obj)),
  }}
}
export default connect(  
  mapStateToProps,
  mapDispatchToProps
)(UseToolById);
