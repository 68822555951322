import React, { useCallback } from 'react';
import { useSelector, } from 'react-redux'
import { Typography, Grid, Button } from '@material-ui/core';
import moment from 'moment'
import 'moment/locale/ru';
import { useDispatch } from 'react-redux'
import { fetchDispath } from '../../functions/fetch'
import { printKS3ById } from '../../api/formks2ks3'
import print from '../../functions/print'
export default function KS3ByIdHeader ({ formName, idDoc, idFacility }) {
  const dispatch = useDispatch()
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { doc = {} } = formTable
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          id: idDoc
        },
        request: printKS3ById(idFacility),
      }
    )
  }
  return <div>
    <Button onClick={handlePrint} color='primary'>Печать</Button>
    <Grid
      item
      container
    // xs={9}
    >
      <GridProps name='Инвестор:' value={doc.investor + ' ' + doc.investorAdress || ''} />
      <GridProps name='Заказчик(Генподрядчик):' value={doc.customer + ' ' + doc.customerAdress || ''} />
      <GridProps name='Подрядчик (Субподрядчик):' value={doc.contractor + ' ' + doc.contractorAdress || ''} />
      <GridProps name='Стройка:' value={doc.facilityName + '' + doc.facilityAddress} />
      <GridProps name='Объект:' value={doc.projectName} />
      <GridProps name='Договор подряда (контракт):' value={
        <Typography><b>номер: </b>{doc.contractNumber} <b>дата:</b>{doc.contractDateDay + '.' + doc.contractDateMonth + '.' + doc.contractDateYear}</Typography>
      } />
      <GridProps name='Номер документа:' value={doc.ksPrefix + '' + doc.number} />
      <GridProps name='Отчетный период' value={
        <Typography><b>c: </b>{moment(doc.dateStart).format('DD.MM.YYYY')} <b>по: </b>{moment(doc.dateEnd).format('DD.MM.YYYY')}</Typography>
      } />
      <GridProps name='Итого:' value={ Math.round(100*Number(doc.summAll))/100} />
      <GridProps name='Сумма НДС:' value={Math.round(100*Number(doc.nds))/100} />
      <GridProps name='Всего с учетом НДС:' value={Math.round(100*Number(doc.summWithNds))/100 } />
    </Grid>
  </div>
}

function GridProps({ name = '', value = '', xsStart = 3, xsEnd = 9 }) {
  return <React.Fragment>
    <Grid item xs={xsStart}><Typography><b>{name}</b></Typography></Grid>
    <Grid item xs={xsEnd}>{(typeof value === 'object') ? value : <Typography>{value}</Typography>}</Grid>
  </React.Fragment>
}