import React, { useState } from 'react';
import {TableCell, TableRow, Checkbox, Input, Typography} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as actions2 from '../../../../reducers/form';
import { editFieldTable } from '../../../../reducers/formTable';

export default function TableRowMaterial({ multiSelect, formName, selectMulti, row }) {
  const obj = useSelector(store => store.formTable[formName]?.byId[row])
  const isItemSelected = useSelector(store => store.form[formName]?.values?.selectId.indexOf(row)!== -1)
  const column = useSelector(store => store.form[formName]?.values.column ||{})
  const dispatch = useDispatch()
  const handleSelect = (checked) => {
    if (multiSelect) {
      selectMulti(row, checked)
      return;
    }
    if (checked) {
      dispatch(actions2.formArrayAdd(row, { field: 'selectId', name: formName }))
      return;
    }
    dispatch(actions2.formArrayDelete(row, { field: 'selectId', name: formName }))
  }
  console.log('upd-row')
  return (
    <TableRow key={row}>
      <TableCell size='small'>
        <Checkbox
          id={'checkbox'+row}
          checked={isItemSelected}
          onClick={(e) => handleSelect(!isItemSelected)}
        />
      </TableCell>
      {Object
        .keys(column)
        .filter(key => column[key].visible)
        .map(key => <TableCellNew key={key} row={row} coll={key} value={obj[key]} formName={formName} />)}
    </TableRow>
  )
}

function TableCellNew({ row, coll, value, formName }) {
  const [edit, setEdit] = useState(false)
  const [editText, setEditText] = useState('')
  const dispatch = useDispatch()
  const handleOk = () => {
    if(value!==editText){ dispatch(editFieldTable(editText, { field: coll, id: row, name: formName }))
    }setEdit(false)
  }
  const onClick = () => { if (edit) return; setEdit(true); setEditText(value || '') }
  // console.log('upd-cell')
  return <TableCell
    size='small'
    padding='none'
    onClick={onClick}
  >
    {(edit) ?
        <Input
          id={'input_'+row+'_'+coll}
          value={editText}
          onChange={(e => setEditText(e.target.value))}
          name='edit'
          multiline
          onBlur={handleOk}
          autoFocus
          fullWidth
        />
      : <Typography>{value}</Typography>
    }
  </TableCell>
}