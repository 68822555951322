import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import PageList from '../../components/PageListAPI'
import can from '../../functions/can'
import { deleteMaterialMulti, getMaterialsList, setDeleteMaterial } from '../../api/material'
import { fetchDispath } from '../../functions/fetch'
import { enqueueSnackbar } from '../../reducers/notifier'

export default function MaterialBasket(props) {
  const [reload, setReload] = useState(false)
  const disabledDelete = (obj) => (obj.count - obj.balance) > 0
  const idProject = props.match.params.id
  const tableHead = [
    { id: 'number', name: '№', align: 'center', padding: 'none' },
    { id: 'name', name: 'Наименование', align: 'left' },
    { id: 'typeMark', name: 'Тип, марка', align: 'left' },
    { id: 'vendorCode', name: 'Артикул', align: 'left' },
    { id: 'storageUnit', name: 'Ед. изм.', align: 'left' },
    { id: 'count', name: 'Количество', align: 'right' },
    {
      id: 'restore', name: 'Восстановить', align: 'left', type: 'component',
      component: ({ obj }) => <Restore obj={obj} idProject={idProject} reload={() => setReload(true)} />
    },
  ];
  return (
    <div className='App-paper'>
      <PageList
        name='MaterialBasket'
        columns={tableHead}
        title='Корзина'
        deleteItem={can('material:delete')}
        loadAPI={{
          request: getMaterialsList,
          querty: {
            isdeleted: 1
          },
          param: {
            id: idProject
          },
        }}
        deleteAPI={deleteMaterialMulti(idProject)}
        disabledDelete={disabledDelete}
        filterProps={[
          {
            type: 'find_text',
            field: 'find',
            name: 'Поиск по наименованию'
          },
          {
            type: 'find_text',
            field: 'number',
            name: 'Поиск по номеру'
          },
        ]}
        csv={false}
        click={false}
        reload={reload}
        reloadFunc={() => setReload(false)}
        deleteMulti
        deleteMultiProps='arr'
        {...props}
      />
    </div>
  )
}

function Restore({ obj, idProject, reload }) {
  const dispath = useDispatch()
  const deleteCustom = () => {
    dispath(fetchDispath({
      param: {
        id: obj.id
      },
      progress: false,
      request: setDeleteMaterial(idProject)
    }))
      .then((res) => {
        if (res) {
          reload()
          dispath(enqueueSnackbar({
            message: `success`,
            options: { variant: 'success' }
          }))
        }
      })
  }
  return <div><Button onClick={deleteCustom}>Восстановить</Button></div>
}