import React, { useState } from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell, IconButton, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import * as actions from '../../../../reducers/form';

export default function SetWorkForZhor({ formName }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const { zById = {}, zAllIds = [] } = values
  const [idRow, setIdRow] = useState(0)
  const dispatch = useDispatch()
  const addRow = () => {
    const newIdRow = idRow + 1
    dispatch(actions.formObjAdd({ work: '' }, { field: 'zById', id: 'id' + newIdRow, name: formName }))
    dispatch(actions.formArrayAdd('id' + newIdRow, { field: 'zAllIds', name: formName }))
    setIdRow(newIdRow)
  }
  return <div>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 10 }} padding='none' >
            <IconButton onClick={addRow}  ><AddIcon /></IconButton>
          </TableCell>
          <TableCell>Наименование работ</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {zAllIds.map(key => {
          return <Row key={key} id={key} obj={zById[key]} formName={formName} />
        })}
        <TableRow style={{ height: 40 * 5 }}>
          <TableCell colSpan={8} />
        </TableRow>
      </TableBody>
    </Table>
  </div>
}

function Row({ id, obj, formName }) {
  const dispatch = useDispatch()
  const deleteRow = () => {
    dispatch(actions.formArrayDelete(id, { field: 'zAllIds', name: formName }))
    dispatch(actions.formObjDelete({ id: id, field: 'zById', name: formName }))
  }
  return <TableRow>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <IconButton aria-label="Delete" onClick={deleteRow}  ><DeleteIcon /></IconButton>
    </TableCell>
    <TableCell>
      <TextField
        // type='number'
        multiline
        fullWidth
        onChange={(e) => dispatch(actions.formObjChange(e.target.value, { id: id, field: 'zById', fieldObj: 'work', name: formName }))}
        value={obj.work || ''}
      />
    </TableCell>
  </TableRow>
}