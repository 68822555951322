import React from 'react';
import PageList from '../../../components/PageListAPI'
import can from '../../../functions/can'
import { getOurStorage, deleteOurStorage } from '../../../api/storage'

export default function Storage(props) {
  return <div className='App-paper'>
    <PageList
      name='storage'
      columns={[
        { id: 'name', name: 'Наименование' },
        { id: 'address', name: 'Адрес' },
      ]}
      createItem={can('storage:update')}
      deleteItem={can('storage:delete')}
      loadAPI={{
        request: getOurStorage,
      }}
      deleteAPI={deleteOurStorage}
      {...props}
    />
  </div>
}


