import React from 'react'
import { Table, TableBody, TableCell, TableRow, TableHead, TableSortLabel, InputBase, Select, Tooltip, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../reducers/formTable';
import { fetchDispath } from '../../../../functions/fetch'
import { updMOPPriceById } from "../../../../api/doc";
import { nds } from '../../../../enum/nds.enum'
import AllPrice from '../../shipmentMaterial/add/source/AllPrice'

const column = [
  { id: 'name', name: 'Наименование' },
  { id: 'count', name: 'Количество' },
  { id: 'storageUnit', name: 'Ед.изм' },
  { id: 'balance', name: 'По смете' },
  { id: 'price', name: 'Цена за ед.' },
  { id: 'nds', name: 'НДС' },
  { id: 'summ', name: 'Сумма' },
  { id: 'summNDS', name: 'Сумма с НДС' },
]
export default function TableOrderProvider({ formName, idDoc }) {
  const dispatch = useDispatch()
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { allIds = [], byId = {}, sortBy = 'name', sortDirection = 'asc' } = formTable
  const handleRequestSort = (sortByNew) => {
    let sortDirectionNew = 'desc';
    if (sortBy === sortByNew && sortDirection === 'desc') {
      sortDirectionNew = 'asc';
    }
    dispatch(actions.setSortFormTable({ sortBy: sortByNew, sortDirection: sortDirectionNew }, { name: formName }))
  };
  let summ = 0
  return <React.Fragment>
    <Table>
      <TableHead>
        <TableRow>
          {column.map(obj => {
            return (<TableCell
              key={obj.id}
              align={obj.align || 'inherit'}
              sortDirection={sortBy === obj.id ? sortDirection : false}
            >
              <TableSortLabel
                active={sortBy === obj.id}
                direction={sortDirection}
                onClick={() => handleRequestSort(obj.id)}
              >
                {obj.name}
              </TableSortLabel>
            </TableCell>);
          })}
          <TableCell
            key='dateOrder'
            align='inherit'
            sortDirection={sortBy === 'dateOrder' ? sortDirection : false}
          >
            <TableSortLabel
              active={sortBy === 'dateOrder'}
              direction={sortDirection}
              onClick={() => handleRequestSort('dateOrder')}
            >
              Дата доставки

            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allIds.map(row => {
          const obj = byId[row]
          summ += Math.round(obj.countNew * obj.priceNew * 100 * ((obj.nds === 'NDS20') ? 1.2 : 1)) / 100
          return <TableRow key={row}>
            <TableCell>
              <span style={{ color: '#656565' }}><Typography>{obj.name}</Typography></span>
              <EditSimple
                value={obj.providerName || ''}
                id={obj.id}
                row={row}
                idDoc={idDoc}
                formName={formName}
                type='string'
                fieldTable='providerName'
                fieldName='providerName'
                tableCell={false}
                inputProps={{ multiline: true, style: { backgroundColor: '#fcfcfc' } }}
              />
            </TableCell>
            <EditSimple value={obj.countNew} id={obj.id} row={row} idDoc={idDoc} formName={formName} type='number' fieldTable='countNew'
              fieldName='count' inputProps={{ style: { color: obj.countNew > obj.balance ? '#f00' : '#000' } }} />
            <TableCell style={{ color: '#656565' }}><Typography>{obj.storageUnit}</Typography></TableCell>
            <TableCell style={{ color: '#656565' }}><Typography>{obj.balance}</Typography></TableCell>
            <EditSimple value={obj.priceNew} id={obj.id} row={row} idDoc={idDoc} formName={formName} type='number' fieldTable='priceNew' fieldName='price' />
            <SelectSwitch value={obj.nds} id={obj.id} row={row} idDoc={idDoc} formName={formName} />
            <TableCell style={{ color: '#656565' }}><Typography>{Math.round(obj.countNew * obj.priceNew * 100) / 100}</Typography></TableCell>
            <TableCell style={{ color: '#656565' }}><Typography>{Math.round(obj.countNew * obj.priceNew * 100 * ((obj.nds === 'NDS20') ? 1.2 : 1)) / 100}</Typography></TableCell>
            <EditSimple value={obj.dateOrder} id={obj.id} row={row} idDoc={idDoc} formName={formName} type='date' fieldTable='dateOrder' fieldName='dateOrder' />
          </TableRow>
        })}
      </TableBody>
    </Table>
    <AllPrice summ={summ || 0} />
  </React.Fragment>
}

function EditSimple({ value, id, row, idDoc, formName, fieldTable, fieldName, type, tableCell = true, inputProps = {} }) {
  const dispatch = useDispatch()
  const handleChange = (e) => {
    dispatch(actions.editFieldTable(e.target.value, { id: row, field: fieldTable, name: formName }))
  }
  const handleBlur = () => {
    dispatch(fetchDispath({
      param: { id: idDoc },
      body: {
        [fieldName]: type === 'date' && value === '' ? null : value,
        id: id
      },
      progress: true,
      request: updMOPPriceById,
    }))
  }
  const Component = tableCell ? TableCell : React.Fragment
  return <Component>
    <Tooltip title="Изменить">
      <InputBase
        value={value}
        type={type}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={(e) => { e.target.select() }}
        fullWidth
        {...inputProps}
      />
    </Tooltip>
  </Component>
}

function SelectSwitch({ value, id, row, idDoc, formName }) {
  const dispatch = useDispatch()
  const handleChange = (e) => {
    dispatch(actions.editFieldTable(e.target.value, { id: row, field: 'nds', name: formName }))
    dispatch(fetchDispath({
      param: { id: idDoc },
      body: {
        nds: e.target.value,
        id: id
      },
      progress: true,
      request: updMOPPriceById,
    }))
  }
  return <TableCell padding='none'>
    <Select
      native
      value={value}
      onChange={handleChange}
      style={{ minWidth: 120 }}
    >
      {nds.map(el => {
        return <option key={el.value} value={el.value}>{el.label}</option>
      })}
    </Select>
  </TableCell>
}