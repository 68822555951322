import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import * as api from '../../../api/list'
import SimpleAdd from '../../../components/SimpleAdd'

const formName = 'MachineChecklistAdd'
export default function MachineChecklistAdd({ history, match }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ checklistId: ''}, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const checklistId = useSelector(state => state.form[formName]?.values.checklistId ||'')
  const arrProps = [
    {
      bp: { md: 12, sm: 12 },
      field: 'checklistId', label: 'чеклист', type: 'autoLoad',
      textProps: {
        path: api.getChecklist.path,
        meta: {
          field: 'checklistId',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    }
  ]
  const handleSave = (e) => {
    e.preventDefault();
    dispatch(actions.formSubmitAPI({
      props: {
        idChecklist: checklistId,
      },
      url: api.addMachineChecklist.path({ id: match.params.id }),
      method: api.addMachineChecklist.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Контрольный лист'
            gridProps={{ xs: 12, xl: 9, lg: 10 }}
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}
