import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core';
import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import { getFacilityById, updFacility, getFacilityResponsible } from '../../api/facility'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite, set as setAutocomplite } from '../../reducers/autocomplite'
import { getCompanies } from '../../api/list'
import moment from 'moment'
import 'moment/locale/ru';
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 220,
    marginTop: 5,
    marginBottom: 3,
    width: '50%',
    overflowX: 'visible'

  },
}))
const formInvestor = 'Investor'
const formCustomer = 'Customer'
const formContractor = 'Contractor'
const formTechCustomer = 'TechCustomer'
const formTechSupervision = 'TechSupervision'
const formDocCompany = 'DocCompany'
const formRespPerson = 'RespPerson'
const formConstruction = 'Construction'

export default function FacilityEdit(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const formName = 'FacilityEdit'
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(initialAutocomplite({ field: formInvestor }))
    dispatch(initialAutocomplite({ field: formCustomer }))
    dispatch(initialAutocomplite({ field: formContractor }))
    dispatch(initialAutocomplite({ field: formRespPerson }))
    dispatch(initialAutocomplite({ field: formTechCustomer }))
    dispatch(initialAutocomplite({ field: formTechSupervision }))
    dispatch(initialAutocomplite({ field: formDocCompany }))
    dispatch(initialAutocomplite({ field: formConstruction }))
    dispatch(fetchDispath({
      progress: true,
      request: getFacilityById,
      param: {
        id: props.match.params.id
      }
    }))
      .then(res => {
        let objInit = {
          name: res.get.name,
          description: res.get.description,
          address: res.get.address,
          investor: res.get.investorId,
          customer: res.get.customerId,
          construction: res.get.constructionId,
          contractor: res.get.contractorId,
          contractNumber: res.get.contractNumber,
          techCustomer: res.get.techCustomerId,
          techSupervision: res.get.techSupervisionId,
          projectDocCompany: res.get.projectDocCompanyId,
          contractDate: moment(res.get.contractDate).format('YYYY-MM-DD'),
          ksPrefix: res.get.ksPrefix,
          developer: null,
          builder: null,
          builderControl: null,
          projectDoc: null,
          personExam: null,
          otherPeople: null,
          errorValidate: { name: false }
        }
        dispatch(actions.formInitialize(objInit, { name: formName }))
        dispatch(setAutocomplite({ value: res.get.investorId + '', label: res.get.investor }, { field: formInvestor }))
        dispatch(setAutocomplite({ value: res.get.customerId + '', label: res.get.customer }, { field: formCustomer }))
        dispatch(setAutocomplite({ value: res.get.contractorId + '', label: res.get.contractor }, { field: formContractor }))
        dispatch(setAutocomplite({ value: res.get.techCustomerId + '', label: res.get.techCustomer }, { field: formTechCustomer }))
        dispatch(setAutocomplite({ value: res.get.techSupervisionId + '', label: res.get.techSupervision }, { field: formTechSupervision }))
        dispatch(setAutocomplite({ value: res.get.projectDocCompanyId + '', label: res.get.projectDocCompany }, { field: formDocCompany }))
        dispatch(setAutocomplite({ value: res.get.constructionId + '', label: res.get.construction }, { field: formConstruction }))

      })
      .catch(err => console.log(err))
    return () => {
      dispatch(clearAutocomplite({ field: formInvestor }))
      dispatch(clearAutocomplite({ field: formCustomer }))
      dispatch(clearAutocomplite({ field: formContractor }))
      dispatch(clearAutocomplite({ field: formTechCustomer }))
      dispatch(clearAutocomplite({ field: formTechSupervision }))
      dispatch(clearAutocomplite({ field: formDocCompany }))
      dispatch(clearAutocomplite({ field: formConstruction }))
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch, props.match.params.id])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getCompanies.path, {
      field: formCustomer,
      value: 'id',
      labelFunc: (obj) => obj.shortName
    }))
    dispatch(loadAutocompliteAPI(getCompanies.path, {
      field: formContractor,
      value: 'id',
      labelFunc: (obj) => obj.shortName
    }))
    dispatch(loadAutocompliteAPI(getFacilityResponsible.path({ idFacility: props.match.params.id }), {
      field: formRespPerson,
      value: 'id',
      labelFunc: (obj) => `${obj.company} ${obj.surname} ${obj.name[0]}.${obj.patronymic[0]}.`
    }))

  }, [dispatch, props.match.params.id])
  const arrProps = [
    { xs: 12, field: 'name', type: 'text', label: 'Наименование', validator: [(value) => value !== ''], errorText: 'Поле обязательно' },
    { xs: 12, field: 'address', type: 'text', label: 'Адрес' },
    { xs: 3, field: 'contractNumber', type: 'text', label: 'Номер договора' },
    { xs: 3, field: 'contractDate', type: 'text', label: 'Дата договора', textProps: { type: 'date' }, labelProps: { shrink: true } },
    { xs: 6, type: 'divider' },
    {
      xs: 4, field: 'investor', type: 'autoLoad', label: 'Инвестор',
      textProps: {
        path: getCompanies.path,
        meta: {
          field: formInvestor,
          value: 'id',
          labelFunc: (obj) => obj.shortName
        }
      }
    },
    {
      xs: 4, field: 'customer', type: 'autoLoad', label: 'Заказчик (Генподрядчик)',
      textProps: {
        path: getCompanies.path,
        meta: {
          field: formCustomer,
          value: 'id',
          labelFunc: (obj) => obj.shortName
        }
      }
    },
    {
      xs: 4, field: 'contractor', type: 'autoLoad', label: 'Подрядчик (Субподрядчик)',
      textProps: {
        path: getCompanies.path,
        meta: {
          field: formContractor,
          value: 'id',
          labelFunc: (obj) => obj.shortName
        }
      }
    },
    {
      xs: 4, field: 'techCustomer', type: 'autoLoad', label: 'Застройщик, Технический заказчик',
      textProps: {
        path: getCompanies.path,
        meta: {
          field: formTechCustomer,
          value: 'id',
          labelFunc: (obj) => obj.shortName
        }
      }
    },
    {
      xs: 4, field: 'construction', type: 'autoLoad', label: 'Лицо, осуществляющее строительство',
      textProps: {
        path: getCompanies.path,
        meta: {
          field: formConstruction,
          value: 'id',
          labelFunc: (obj) => obj.shortName
        }
      }
    },
    {
      xs: 4, field: 'techSupervision', type: 'autoLoad', label: 'Лицо, выполнившее работы, подлежащие освидетельствованию',
      textProps: {
        path: getCompanies.path,
        meta: {
          field: formTechSupervision,
          value: 'id',
          labelFunc: (obj) => obj.shortName
        }
      }
    },
    {
      xs: 4, field: 'projectDocCompany', type: 'autoLoad', label: 'Лицо,осуществляющее подготовку проектной документации',
      textProps: {
        path: getCompanies.path,
        meta: {
          field: formDocCompany,
          value: 'id',
          labelFunc: (obj) => obj.shortName
        }
      }
    },
    { xs: 4, type: 'divider' },
    { xs: 12, field: 'ksPrefix', type: 'text', label: 'КС префикс' },
    { xs: 12, field: 'description', type: 'text', label: 'Описание', textProps: { multiline: true, rows: "4" } },
  ]
  const handleSubmit = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        name: values.name,
        description: values.description,
        address: values.address,
        investor: values.investor,
        customer: values.customer,
        construction: values.construction,
        contractor: values.contractor,
        contractNumber: values.contractNumber,
        contractDate: values.contractDate,
        ksPrefix: values.ksPrefix,
        techCustomer: values.techCustomer,
        techSupervision: values.techSupervision,
        projectDocCompany: values.projectDocCompany,
      },
      url: updFacility.path({ id: props.match.params.id }),
      method: updFacility.type,
      history: props.history
    }, formName))
  };
  return (
    <div className='App-paper' >
      <form autoComplete="off" onSubmit={handleSubmit} className={classes.main}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            title='Редактировать объект'
            formName={formName}
            gridProps={{ xs: 12 }}
          />
        </div>
        <div className={classes.main}>
          <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
          <Button type="submit" color="primary">Изменить</Button>
        </div>
      </form>
    </div>
  );
}
