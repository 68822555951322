import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import * as api from '../../api/storage'
import PageList from '../../components/PageListAPI'

export default function StorageEquipment(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='storageMaterial'
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'storageUnit', name: 'Ед. изм.' },
          { id: 'storage', name: 'На складе', align: 'right' },
        ]}
        loadAPI={{
          param: {
            id: props.match.params.id,
          },
          request: api.getStorageEquipment
        }
        }
        click={false}
        headerLink={<RemotalLink {...props} />}
        {...props}
      />
    </div>
  )
}

function RemotalLink(props) {
  const toLinkRemotal = React.forwardRef((props1, ref) => (<Link ref={ref} to={`${props.match.url}/removal`} {...props1} />))
  return (<div>
    <Button color='primary' component={toLinkRemotal} >Списать </Button>
  </div>
  );
}
