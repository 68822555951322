import React, { useEffect } from 'react'
import { Button, Typography } from '@material-ui/core'
import { getFacility } from '../../api/facility'
import SimpleAdd from '../../components/SimpleAdd'
import { useSelector, useDispatch } from 'react-redux';
import validateProps from '../../functions/validateProps'
import * as actions from '../../reducers/form';
import { enqueueSnackbar } from '../../reducers/notifier'
import { addProject } from '../../api/projects'

const formName = 'project-create-template'

export default function ProjectSettingTemplate({ idProject, history }) {
  const dispatch = useDispatch()
  const arrProps = [
    { field: 'name', label: 'Наименование', type: 'text', validator: [(value) => value !== ''], errorText: 'Поле обязательно' },
    {
      field: 'idFacility', label: 'Объект', type: 'autoLoad',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        path: getFacility.path + '?template=true',
        meta: {
          field: 'idFacility',
          value: 'id',
          labelFunc: (obj) => obj.name,
        },
      }
    },]
  useEffect(() => {
    dispatch(actions.formInitialize({
      name: '', idFacility: null,
      copyIdProject: idProject
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch, idProject])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleClick = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        name: values.name,
        idFacility: values.idFacility,
        users: [],
        material: 1,
        work: 1,
        estimate: 1,
        copyIdProject: Number(idProject),
        isCopy: 1
      },
      url: addProject.path,
      method: addProject.type,
      history: history
    }, formName))
  }
  return <div>
    <Typography variant='h6'>Создать шаблон на основе текущего проекта</Typography>
    <div style={{ padding: '16px' }}>
      <SimpleAdd
        arr={arrProps}
        formName={formName}
      />
    </div>
    <Button color='primary' onClick={handleClick} >Создать</Button>
  </div>
}