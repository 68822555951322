import React from 'react';
import PageList from '../../components/PageListAPI'
import { getDeliveryMaterial } from '../../api/acceptance'

export default function AcceptanceMaterial(props) {
  const tableHead = [
    { id: 'date', name: 'Дата поставки', type: 'date', formatDate: 'DD.MM.YYYY' },
    { id: 'type', name: 'Вид документа' },
    { id: 'provider', name: 'Поставщик/Склад' },
    { id: 'name', name: 'Наименование' },
    { id: 'count', name: 'Количество' },
    { id: 'storageUnit', name: 'Ед.изм.' },
  ];
  return (
    <div className='App-paper'>
      <PageList
        name='AcceptanceMaterial'
        title='Материалы к поставке'
        columns={tableHead}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getDeliveryMaterial,
          param: {
            id: props.match.params.id
          }
        }}
        {...props}
        headerInit={{
          sortBy: 'date',
          sortDirection: 'desc',
        }}
        csv={false}
        click={false}
      />
    </div>
  )
};