import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { formInitialize, formDestroy, formSubmitAPI } from '../../../reducers/form';
import { getWorkerList } from '../../../api/worker'
import { getMaterials } from '../../../api/material'
import { getWorkTemplate } from '../../../api/list'
import { addOutfitPlan, getOutfitPlanWork } from "../../../api/doc";
import { fetchDispath } from '../../../functions/fetch'

import { getTeamList } from '../../../api/team'
import { getWork } from '../../../api/work'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite, load as loadAuto, loadGroup } from '../../../reducers/autocomplite'
import { enqueueSnackbar } from '../../../reducers/notifier';
import DocTable from '../../../components/DocTable'
import DocAdd from '../../../components/DocAdd'
import DialogAddTeam from './source/DialogAddTeam'
import DialogAddMaterial from './source/DialogAddMaterial';
import WorkTab from './source/WorkTab'
import FilesTab from './source/FilesTab'
import moment from 'moment'
import PlanAddHistory from './source/PlanAddHistory'
import DialogAddGant from './source/DialogAddGant'

const formName = 'outfitPlan'
const formWorker = 'worker'
const formMaterial = 'material'
const formMaterialG = 'materialG'
const formWork = 'work'
const formGWork = 'Gwork'
const formTeam = 'teamlist'
const formWorkEstimate = 'workEstimate'
export default function OutfitPlanAdd(props) {
  const dispatch = useDispatch()
  const idProject = props.match.params.idProject
  useEffect(() => {
    dispatch(formInitialize({
      date: moment(Date.now()).format('YYYY-MM-DD'),
      work: '',
      shortDescription: '',
      mById: {}, // Table material
      mAllIds: [], // Table material
      wById: {}, // Table worker
      wAllIds: [], // Table worker
      wkById: {}, // Table work
      wkAllIds: [], // Table work
      fById: {}, // Table files
      fAllIds: [], // Table files
      eById: {}, // Estimate work
      eAllIds: [] // Estimate work
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: formWorker }))
    dispatch(initialAutocomplite({ field: formMaterial }))
    dispatch(initialAutocomplite({ field: formMaterialG }))
    dispatch(initialAutocomplite({ field: formWork }))
    dispatch(initialAutocomplite({ field: formGWork }))
    dispatch(initialAutocomplite({ field: formTeam }))
    dispatch(initialAutocomplite({ field: formWorkEstimate }))
    return () => {
      dispatch(formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formWorker }))
      dispatch(clearAutocomplite({ field: formMaterial }))
      dispatch(clearAutocomplite({ field: formMaterialG }))
      dispatch(clearAutocomplite({ field: formWork }))
      dispatch(clearAutocomplite({ field: formGWork }))
      dispatch(clearAutocomplite({ field: formTeam }))
      dispatch(clearAutocomplite({ field: formWorkEstimate }))
    }
  }, [dispatch])
  useEffect(() => {
    const pathMaterials = getMaterials.path({ id: idProject }) + '?type=balance'
    const metaMaterials = {
      field: formMaterial,
      value: 'id',
      hidden: ['storageUnit', 'idStorageUnit', 'storage', 'count', 'costs'],
      labelFunc: (obj) => obj.name + ' ' + obj.typeMark + ' ' + obj.vendorCode
    }
    dispatch(loadAutocompliteAPI(pathMaterials, metaMaterials))
    dispatch(loadAutocompliteAPI(getWorkerList.path, {
      field: formWorker,
      value: 'id',
      hidden: ['position'],
      labelFunc: (obj) => obj.surname + ' ' + obj.name + ' ' + obj.patronymic
    }))
    dispatch(loadAutocompliteAPI(getWorkTemplate.path, {
      field: formWork,
      value: 'id',
      hidden: [],
      labelFunc: (obj) => obj.name
    }))
    dispatch(loadAutocompliteAPI(getTeamList.path({ id: idProject }), {
      field: formTeam,
      value: 'id',
      hidden: [],
      labelFunc: (obj) => obj.name + ' ' + obj.master
    }))
    dispatch(loadAutocompliteAPI(getWork.path({ id: idProject }), {
      field: formWorkEstimate,
      value: 'id',
      hidden: ['balance', 'storageUnit', 'count'],
      labelFunc: (obj) => obj.name
    }))

    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getOutfitPlanWork,
    })).then(res => {
      let groupWork = {}
      const arWork = res.get.work.map((e, i) => {
        if (!groupWork[e.groupId]) groupWork[e.groupId] = { label: e.groupName, options: [] }
        return {
          label: e.name,
          value: e.id + '_' + e.groupId,
          id: e.id,
          storageUnit: e.storageUnit,
          balance: e.groupBalance,
          count: e.groupCount,
          groupId: e.groupId,
          groupName: e.groupName,
          groupKey: e.groupId
        }
      })
      let groupMaterial = {}
      const arrMaterial = res.get.material.map((e, i) => {
        if (!groupMaterial[e.groupId]) groupMaterial[e.groupId] = { label: e.groupName, options: [] }
        return {
          label: e.name,
          value: e.id + '_' + e.groupId,
          id: e.id,
          storageUnit: e.storageUnit,
          balance: e.groupBalance,// выработано
          count: e.groupCount,// количество в групе
          remainder: e.groupCount - e.groupBalance, // остаток
          groupId: e.groupId,
          groupName: e.groupName,
          storage: e.storage,
          isDisabled: false,
          groupKey: e.groupId
        }
      })
      dispatch(loadAuto(arWork, { field: formGWork, }))
      dispatch(loadGroup(groupWork, { field: formGWork }))
      dispatch(loadAuto(arrMaterial, { field: formMaterialG, }))
      dispatch(loadGroup(groupMaterial, { field: formMaterialG, }))
    }).catch(err => console.log(err))


  }, [dispatch, idProject])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSave = () => {
    const { date, mById, mAllIds, wById, wAllIds, wkById, wkAllIds, shortDescription, fById, eById, eAllIds } = values
    const arrWorker = wAllIds.map(e => wById[e].idWorker)
    const arrMaterial = mAllIds.map(e => {
      const obj = mById[e]
      return {
        id: obj.id,
        count: obj.count,
        groupId: obj.groupId,
        idGant: obj.idGant,
      }
    })
    if (mAllIds.filter(e => mById[e].count === '').length > 0) {
      dispatch(enqueueSnackbar({
        message: `не заполнено количество материалов`,
        options: { variant: 'error' }
      }))
      return;
    }
    const arrWork = []
    wkAllIds.forEach(key => {
      if (wkById[key].name !== '') arrWork.push(wkById[key].name)
    })
    const arrWorkE = []
    eAllIds.forEach(key => {
      const obj = eById[key]
      if (obj.count > 0) {
        arrWorkE.push({
          id: obj.idWork,
          count: obj.count,
          groupId: obj.groupId,
          idGant: obj.idGant,
        })
      }
    })
    dispatch(formSubmitAPI({
      props: {
        date,
        shortDescription,
        work: arrWork,
        workE: arrWorkE,
        worker: arrWorker,
        material: arrMaterial,
        files: Object.values(fById).map(e => e.id)
      },
      url: addOutfitPlan.path({ id: idProject }),
      method: addOutfitPlan.type,
      history: props.history
    }))
  }
  const headerButton = <DialogAddGant formName={formName} idProject={idProject} formGWork={formGWork}/>
  return <div className='App-paper'>
    <PlanAddHistory formMaterialG={formMaterialG} formGWork={formGWork} idProject={idProject} formName={formName} />
    <DocAdd
      formName={formName}
      headerButton={headerButton}
      history={props.history}
      title='Создать Наряд заказ'
      tab={['Сметные работы', 'Материалы', 'Рабочие', 'Доп. работы', 'Файлы']}
      noTableProps={[
        { field: 'date', label: 'Дата наряда', type: 'text', textProps: { type: 'date' } },
        { field: 'shortDescription', label: 'Краткое описание', type: 'text', textProps: { type: 'text' }, xs: 6 },
      ]}
      handleSave={handleSave}
    >
      <DocTable
        formName={formName}
        byIdField='eById'
        allIdsField='eAllIds'
        column={[
          { id: 'groupName', type: 'string', name: 'Раздел' },
          {
            id: 'value', type: 'auto', name: 'Работа', formArr: formGWork, width: '50%', filtered: true, grouped: true,
            changeValue: text => {
              return ({
                balance: text.count-text.balance,
                idWork: text.id,
                storageUnit: text.storageUnit,
                value: text,
                groupId: text.groupId,
                groupName: text.groupName,
                groupCountRes: text.balance+' | '+(text.count-text.balance)+' | '+text.count,
              })
            }
          },
          {
            id: 'count', type: 'number', name: 'Количество',
            title: obj => obj.balance,
            textProps: obj => ({ error: obj.count > obj.balance, helperText: obj.count > obj.balance ? 'Больше чем в смете' : '' })
          },
          { id: 'storageUnit', type: 'string', name: 'Ед.изм' },
          { id: 'groupCountRes', type: 'string', name: 'выполнено|осталось|всего|дней(гант,c тек)' },
        ]}
        rowDefault={{
          balance: 0,
          count: '',
          storageUnit: '',
          idWork: '',
          value: null
        }}
      />
      <React.Fragment>
        <DialogAddMaterial
          rowDefault={{
            id: '',
            count: '',
            storageUnit: '',
            storage: '',
            value: ''
          }}
          formArr={formMaterialG}
          byIdField='mById'
          allIdsField='mAllIds'
          formName={formName}
        />
        <DocTable
          formName={formName}
          byIdField='mById'
          allIdsField='mAllIds'
          column={[
            { id: 'groupName', type: 'string', name: 'Раздел' },
            {
              id: 'value', type: 'auto', name: 'Материал', formArr: formMaterialG, width: '45%', filtered: true, grouped: true,
              changeValue: text => {
                return ({
                  value: text,
                  balance: text.remainder,
                  storageUnit: text.storageUnit,
                  id: text.id,
                  storage: text.storage,
                  groupId: text.groupId,
                  groupName: text.groupName,
                })
              }
            },
            {
              id: 'count', type: 'number', name: 'Количество',
              title: obj => obj.balance,
              textProps: obj => {
                return ({ error: obj.count > obj.balance, helperText: obj.count > obj.balance ? 'Больше чем в смете' : '' })
              }
            },
            // { id: 'count', type: 'number', name: 'Количество', width: '20%', },
            { id: 'storage', type: 'string', name: 'На складе' },
            { id: 'storageUnit', type: 'string', name: 'Ед.изм' }
          ]}
          rowDefault={{
            id: '',
            count: '',
            storageUnit: '',
            storage: '',
            value: ''
          }}
        />
      </React.Fragment>
      <React.Fragment>
        <DialogAddTeam
          formTeam={formTeam}
          formName={formName}
          formWorker={formWorker}
          idProject={idProject}
        />
        <DocTable
          formName={formName}
          byIdField='wById'
          allIdsField='wAllIds'
          column={[
            {
              id: 'value', type: 'auto', name: 'Работник', formArr: formWorker, width: '50%', filtered: true,
              changeValue: text => ({ position: text.position, idWorker: text.value, value: text, })
            },
            { id: 'position', type: 'string', name: 'Должность' },
          ]}
          rowDefault={{
            position: '',
            idWorker: '',
            value: null
          }}
        />
      </React.Fragment>
      <WorkTab formName={formName} />
      <FilesTab formName={formName} />
    </DocAdd>
  </div>
}

