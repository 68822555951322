import React, { useState, useEffect, useCallback } from 'react';
import { deleteWorkEstimate, loadWorkEstimate } from '../../api/list'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/formTable';
import { Link } from 'react-router-dom';
import { fetchDispath } from '../../functions/fetch'
import { enqueueSnackbar } from '../../reducers/notifier'
import NotDeleteIcon from '@material-ui/icons/Clear'
import OkDeleteIcon from '@material-ui/icons/Done'
import DeleteIcon from '@material-ui/icons/Delete';
import {
  IconButton, Typography, Table, TableHead,
  TableRow, TableCell, TableSortLabel, TableBody,
  TableContainer, Button, Breadcrumbs
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
  },
  butt: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1
  },
  row: {
    color: '#000000',
    '&$selected': {
      color: '#aaaaaa'
    }
  },
  selected: {},
  table: {
    height: 700
  },
  cell: {
    // fontSize: '0.8rem'
  },
  pagination: {},
  container: {
    height: '65vh'
  }
}));

export default function WorkEstimateList({ location, match, history }) {
  const tableActions = {};
  const name = 'resources'
  const classesPage = useStyles()
  const dispatch = useDispatch();
  const formTable = useSelector(state => state.formTable[name] || state.formTable.default)
  const [arrParent, setArrParent] = useState([])
  useEffect(() => {
    const parent = (arrParent.length > 0) ? arrParent[arrParent.length - 1].id : undefined
    dispatch(fetchDispath({
      querty: {
        parent,
      },
      progress: true,
      request: loadWorkEstimate,
    })).then(res => {
      dispatch(actions.loadFormTable({ ...res }, { name: name, id: 'id' }))
    }).catch(err => console.log(err))
  }, [dispatch, name, arrParent])

  const load = useCallback(() => {
    const parent = (arrParent.length > 0) ? arrParent[arrParent.length - 1] : undefined
    dispatch(fetchDispath({
      querty: {
        parent,
      },
      progress: true,
      request: loadWorkEstimate,
    })).then(res => {
      dispatch(actions.loadFormTable({ ...res }, { name: name, id: 'id' }))
    }).catch(err => console.log(err))
  }, [dispatch, name, arrParent])


  useEffect(() => {
    dispatch(actions.initFormTable({ name: name, header: {} }))
    return () => {
      dispatch(actions.clearFormTable(name))
    }
  }, [dispatch, name])


  const deleteCustom = (param, body) => {
    dispatch(fetchDispath({
      param,
      body,
      progress: false,
      request: deleteWorkEstimate
    }))
      .then((res) => {
        let msg = {}
        if (res) {
          load()
          msg = {
            message: `success`,
            options: { variant: 'success' }
          }
        }
        else {
          msg = {
            message: `not deleted`,
            options: { variant: 'error' }
          }
        }
        dispatch(enqueueSnackbar(msg))
      })
  }
  const handleDeleteOne = (id) => {
    deleteCustom({
      id: formTable.byId[id].id
    })
  }
  const handleSort = (sortBy, sortDirection) => {
    dispatch(actions.setSortFormTable({ sortBy, sortDirection }, { name: name }))
  }
  const toLinkAdd = React.forwardRef((props, ref) => (
    <Link ref={ref} to={`${match.url}/nf/add`} {...props} />
  ));
  const toLinkAddFolder = React.forwardRef((props, ref) => (
    <Link ref={ref} to={`${match.url}/f/add`} {...props} />
  ));
  const toLinkEditFolder = React.forwardRef((props, ref) => {
    const obj = arrParent[arrParent.length - 1] || { id: -1 }
    return <Link ref={ref} to={`${match.url}/f/${obj.id}`} {...props} />
  });
  const pushParent = (obj) => setArrParent([...arrParent, { id: obj.id, name: obj.name }])
  const selectParent = (id) => {
    if (id === -1) {
      setArrParent([])
      return;
    }
    const findIndex = arrParent.findIndex(e => e.id === id)
    setArrParent(arrParent.slice(0, findIndex + 1))
  }
  return (
    <div className='App-paper'>
      <Typography variant='h6'>Работы смета</Typography>
      <div className={classesPage.header}>
        <Button component={toLinkAdd} color="primary" >Добавить работу</Button>
        <Button component={toLinkAddFolder} color="primary" >Добавить папку</Button>
        <Button component={toLinkEditFolder} color="primary" disabled={arrParent.length === 0} >Редактировать папку</Button>
      </div>
      <CollapsedBreadcrumbs arrParent={arrParent} selectParent={selectParent} />
      <TableCardForm
        formName={name}
        classes={classesPage}
        tableActions={{
          onDeleteRow: handleDeleteOne,
          handleSelectAllClick: () => { },
          setSort: handleSort,
          ...tableActions
        }}
        tableData={formTable}
        history={history}
        match={match}
        pushParent={pushParent}
      />
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
    </div>
  )
}

function CollapsedBreadcrumbs({ arrParent, selectParent }) {
  return (
    <Breadcrumbs maxItems={4} aria-label="breadcrumb" >
      <Typography color="inherit" onClick={() => selectParent(-1)} style={{ cursor: 'pointer' }}>
        root
      </Typography>
      {arrParent.map((e, i) => {
        let onClick;
        if (arrParent.length - 1 !== i) onClick = () => selectParent(e.id)
        return <Typography key={i} color="inherit" onClick={onClick} style={{ cursor: arrParent.length - 1 !== i ? 'pointer' : undefined }}>
          {e.name}
        </Typography>
      })}
    </Breadcrumbs>
  );
}


function TableCardForm({
  formName,
  classes = {},
  tableActions,
  tableData,
  history,
  match,
  pushParent,
}) {
  const { allIds = [], byId = {} } = tableData
  return (<React.Fragment>
    <TableContainer className={classes.container}>
      <Table size='small' stickyHeader aria-label="sticky table" >
        <TableHeadCustom formName={formName} />
        <TableBody>{
          allIds.map(row => {
            const obj = byId[row]
            return (<RowTableForm
              classes={classes}
              rowProps={{
                onClick: () => { history.push(tableActions.onClickRow(row)); }
              }}
              obj={obj}
              tableActions={tableActions}
              row={row}
              key={row}
              match={match}
              history={history}
              pushParent={pushParent}
            />)
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </React.Fragment>
  )
}

function TableHeadCustom({ formName }) {
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { sortBy = 'name', sortDirection = 'asc' } = formTable
  const dispatch = useDispatch()
  const handleRequestSort = (sortByNew) => {
    let sortDirectionNew = 'desc';
    if (sortBy === sortByNew && sortDirection === 'desc') {
      sortDirectionNew = 'asc';
    }
    dispatch(actions.setSortFormTable({ sortBy: sortByNew, sortDirection: sortDirectionNew }, { name: formName }))
  };
  return <TableHead>
    <TableRow>
      <TableCell>Тип</TableCell>
      <TableCell sortDirection={sortBy === 'name' ? sortDirection : false}      >
        <TableSortLabel
          active={sortBy === 'name'}
          direction={sortDirection}
          onClick={() => handleRequestSort('name')}
        >
          Наименование
        </TableSortLabel>
      </TableCell>
      <TableCell sortDirection={sortBy === 'count' ? sortDirection : false}      >
        <TableSortLabel
          active={sortBy === 'count'}
          direction={sortDirection}
          onClick={() => handleRequestSort('count')}
        >
          Количество
        </TableSortLabel>
      </TableCell>
      <TableCell sortDirection={sortBy === 'storageUnit' ? sortDirection : false}      >
        <TableSortLabel
          active={sortBy === 'storageUnit'}
          direction={sortDirection}
          onClick={() => handleRequestSort('storageUnit')}
        >
          Ед.изм.
        </TableSortLabel>
      </TableCell>
      <TableCell sortDirection={sortBy === 'price' ? sortDirection : false}      >
        <TableSortLabel
          active={sortBy === 'price'}
          direction={sortDirection}
          onClick={() => handleRequestSort('price')}
        >
          Цена
        </TableSortLabel>
      </TableCell>
      <TableCell align='right'></TableCell>
    </TableRow>
  </TableHead>
}

function RowTableForm({ classes, obj, tableActions, row, match, history, pushParent }) {
  const [isDeleted, changeDeleted] = useState(false)
  if (isDeleted) {
    return <RowDeleted classes={classes} tableActions={tableActions} handleDeleted={() => changeDeleted(false)} row={row} />
  }
  const handleClick = () => {
    if (obj.isFolder) pushParent(obj)
    else history.push(`${match.url}/nf/${obj.id}`)
  };
  if (obj.isFolder) {
    return <TableRow
      classes={{ root: classes.row, selected: classes.selected }}
      onClick={handleClick}
    >
      <TableCell style={{ width: '7%' }} padding='none'><FolderIcon size='small' /></TableCell>
      <TableCell>{obj.name}</TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell align='right' padding='none'>
        <IconButton
          aria-label="Delete"
          size='small'
          className={classes.margin}
          onClick={(e) => { e.stopPropagation(); changeDeleted(true) }}
        ><DeleteIcon fontSize='small' /></IconButton>
      </TableCell>
    </TableRow>
  }
  return (
    <TableRow
      classes={{ root: classes.row, selected: classes.selected }}
      onClick={handleClick}
    >
      <TableCell style={{ width: '7%' }} padding='none' />
      <TableCell>{obj.name}</TableCell>
      <TableCell>{obj.count}</TableCell>
      <TableCell>{obj.storageUnit}</TableCell>
      <TableCell>{obj.price}</TableCell>
      <TableCell align='right' padding='none'>
        <IconButton
          aria-label="Delete"
          size='small'
          className={classes.margin}
          onClick={(e) => { e.stopPropagation(); changeDeleted(true) }}
        ><DeleteIcon fontSize='small' /></IconButton>
      </TableCell>
    </TableRow>
  )
}

// потом переделать а пока так 
function RowDeleted({ classes, tableActions, handleDeleted, row }) {
  return <TableRow className={classes.row}>
    <TableCell colSpan={2} > <Typography>Вы уверенны что хотите удалить эту строку? </Typography></TableCell>
    <TableCell align='right' style={{ width: 10 }}>
      <IconButton
        className={classes.margin}
        size='small'
        onClick={(e) => { e.stopPropagation(); tableActions.onDeleteRow(row); }}
      >
        <OkDeleteIcon fontSize='small' />
      </IconButton>
      <IconButton
        size='small'
        className={classes.margin}
        onClick={(e) => { e.stopPropagation(); handleDeleted(); }}
      >
        <NotDeleteIcon fontSize='small' />
      </IconButton>
    </TableCell>
  </TableRow>
}