import React from 'react'
import { useDispatch } from 'react-redux'
import DescriptionIcon from '@material-ui/icons/Description';
import Chip from '@material-ui/core/Chip';
import { fetchDispath } from '../../../functions/fetch'
import { getFile } from '../../../api/list'

export default function ListFiles({ arrFile }) {
  const dispatch = useDispatch()
  const handleClick = (name) => {
    dispatch(fetchDispath({
      param: {
        id: name,
      },
      progress: false,
      request: getFile,
      buff: true
    }))
      .then(res => {
        if (!res) return;
        res.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
        })
      })
  }
  return <div>
    {arrFile.map(obj => {
      return <Chip
        key={obj.id}
        icon={<DescriptionIcon />}
        label={obj.name}
        onClick={() => handleClick(obj.name)}
      />
    })}
  </div>
}