import React from 'react';
import PageList from '../../../components/PageListAPI'
import { loadArendaEquipmentWriteOf } from '../../../api/shipment'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  row: {
    backgroundColor: '#ffffff',
    '&$selected': {
      backgroundColor: '#f54949'
    }
  },
  selected: {},
}));
export default function ArendaEquipmentListWtireOff(props) {
  const classes = useStyles()
  const column = [
    { id: 'name', name: 'Оборудование', },
    { id: 'serialNumber', name: 'Серийный номер', },
    { id: 'dateStart', name: 'Начало аренды', type: 'date' },
    { id: 'dateEnd', name: 'Окончание аренды', type: 'date' },
  ]
  return (
    <div className='App-paper'>
      <PageList
        name='ArendaEquipmentListWtireOff'
        title='Оборудование на балансе'
        columns={column}
        idName='id'
        loadAPI={{
          querty: {
            idProject: props.match.params.id
          },
          request: loadArendaEquipmentWriteOf,
        }}
        tableProps={{
          selected: obj => obj.alarm,
          classes: classes
        }}
        filterProps={[
          {
            arr: [{ value: '1', name: 'Срок аренды вышел' }, { value: '0', name: 'Аренда не вышла' }],
            field: 'alarm',
            name: 'Статус',
            type: 'select'
          },
        ]}
        {...props}
      />
    </div>
  )
}