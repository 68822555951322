import moment from 'moment'
export const COLUMNS_ARR = ['selected', 'pos', 'estNumber', 'name', 'start', 'end', 'count', 'storageUnit', 'price', 'sum', 'perf', 'sumPerf', 'avgCount','avgCountFact']
export const COLUMNS_OBJ = {
  selected: { hide: false, name: '', widthMin: 50, width: 50, type: 'number', value: 'selected', align: 'center' },
  pos: { hide: false, name: 'Позиция', widthMin: 70, width: 70, type: 'number', value: 'pos', align: 'center' },
  estNumber: { hide: false, name: '№ смета', widthMin: 70, width: 70, type: 'number', value: 'estNumber', align: 'center' },
  name: { hide: false, name: 'Наименование', widthMin: 400, width: 400, type: 'string', value: 'name', align: 'left' },
  start: { hide: false, name: 'Дата начала', widthMin: 130, width: 130, type: 'date', value: 'start', align: 'center' },
  end: { hide: false, name: 'Дата окончания', widthMin: 130, width: 130, type: 'date', value: 'end', align: 'center' },
  count: { hide: false, name: 'Количество', widthMin: 100, width: 100, type: 'number', value: 'count', align: 'center' },
  storageUnit: { hide: false, name: 'Ед. изм.', widthMin: 80, width: 80, type: 'string', value: 'storageUnit', align: 'center' },
  price: { hide: false, name: 'Цена', widthMin: 100, width: 100, type: 'numberLocal', value: 'price', align: 'center' },
  sum: { hide: false, name: 'Сумма', widthMin: 100, width: 100, type: 'numberLocal', value: 'sum', align: 'center' },
  perf: { hide: false, name: 'Выполнено', widthMin: 100, width: 100, type: 'numberLocal', value: 'perf', align: 'center' },
  sumPerf: { hide: false, name: 'Сумма(выполнено)', widthMin: 140, width: 140, type: 'numberLocal', value: 'sumPerf', align: 'center' },
  avgCount: { hide: false, name: 'Выполнение сутки, план', widthMin: 170, width: 170, type: 'numberLocal', value: 'avgCount', align: 'center' },
  avgCountFact: { hide: false, name: 'Выполнение сутки, факт', widthMin: 170, width: 170, type: 'numberLocal', value: 'avgCountFact', align: 'center' },
}

// const difTime = (obj)=> (obj.end.diff(, 'days') + 1)
const difTime = (obj)=> {
  const dateNow = moment()
  if(dateNow.isBefore(obj.start)){
    return obj.end.diff(obj.start, 'days') + 1
  }
  else{
    return obj.end.diff(dateNow, 'days') + 1
  }
} 
export const columnsObjTransform = (obj)=> ({
  selected: obj.selected,
  pos: obj.pos,
  estNumber: obj.estNumber,
  name: obj.name,
  start: obj.start?.format('DD.MM.YYYY'),
  end: obj.end?.format('DD.MM.YYYY'),
  count: obj.count,
  storageUnit: obj.storageUnit,
  price: obj.price?.toLocaleString(),
  sum: obj.sum?.toLocaleString(),
  avgCount: (Math.round(1000 * (obj.count / (obj.end.diff(obj.start, 'days') + 1))) / 1000).toLocaleString(),
  perf: obj.perf,
  sumPerf: obj.sumPerf,
  avgCountFact: obj.balanceM > 0 ? difTime(obj) > 0 ? (Math.round(1000 * obj.balanceM / difTime(obj)) / 1000).toLocaleString() : 'график просрочен':obj.avgCountFact 
})

export const columnsObjTransformView = (obj)=> ({
  name: obj.name,
  start: obj.start?.format('DD.MM.YYYY'),
  end: obj.end?.format('DD.MM.YYYY'),
  startPlan: obj.startPlan?.format('DD.MM.YYYY'),
  endPlan: obj.endPlan?.format('DD.MM.YYYY'),
  count: obj.count+' '+obj.storageUnit,
  price: obj.price?.toLocaleString()+' p.',
  sum: obj.sum?.toLocaleString()+' p.',
  avgCount: (Math.round(1000 * (obj.count / (obj.end.diff(obj.start, 'days') + 1))) / 1000).toLocaleString(),
  perf: obj.perf+' '+obj.storageUnit,
  sumPerf: obj.sumPerf+' p.',
  avgCountFact: obj.balanceM > 0 ? difTime(obj) > 0 ? (Math.round(1000 * obj.balanceM / difTime(obj)) / 1000).toLocaleString()+' '+obj.storageUnit : 'график просрочен':obj.avgCountFact?obj.avgCountFact+' '+obj.storageUnit:'',
  progress: obj.progress+'%',
})

export const columnsObjTransformParent = (obj)=>({
  selected: obj.selected,
  pos: obj.pos, 
  estNumber: '',
  name: obj.name, 
  start: obj.start?.format('DD.MM.YYYY'),
  end: obj.end?.format('DD.MM.YYYY'), 
  count: '',
  storageUnit: '',
  price: '',
  sum: obj.sum?.toLocaleString(), 
  avgCount: '',
  perf: '',
  sumPerf: obj.balanceM > 0 ?(Math.round(1000 * obj.price * (obj.count - obj.balanceM)) / 1000).toLocaleString() : obj.sumPerf,
  avgCountFact: ''
} )

export const formViewProps = 'gant-view'
export const formName = 'form-gant'
export const formFacilityProject = 'form-facility-project'
