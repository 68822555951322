import React from 'react'
import Grid from '@material-ui/core/Grid'
// import check from '../../functions/can'
import CardMenu from './source/CardMenu'
function AnalyticMenu({ match }) {
  let str = `/projects/${match.params.idProject}/analytics`
  const arr = {
    documents: [
      { to: str + '/project', primary: 'Проект' },
      { to: str + '/material', primary: 'Материалы' },
      // { to: str + '/work', primary: 'Работы' },
      // { to: str + '/worker', primary: 'Присутствие рабочих' },
    ].filter(key => typeof key !== 'boolean'),
  }
  return <Grid container>
    {arr.documents.length > 0 && <Grid item><CardMenu arr={arr.documents} name={'Аналитика'} /></Grid>}
  </Grid>
}

export default AnalyticMenu;