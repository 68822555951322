import React, { useEffect, useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import * as actions from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'
import { getFacility } from '../../../api/facility'
import { loadProject } from '../../../api/projects'
import { addNewUser } from '../../../api/users'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../../reducers/autocomplite'
import TableProject from './source/TableAddUserProject'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'
import isEmail from 'validator/lib/isEmail'
import { rules } from '../../../rbac-rules'

const initialState = {
  email: '',
  password: '',
  name: '',
  surname: '',
  patronymic: '',
  role: '',
  valueRole: null,
  facilityProject: {},
  pAllIds: [],
  pById: {}
}
const formName = 'UserAdd'
const tableName = 'facilityArr'
const msgErr = 'Поле обязательно'
export default function UserAdd({ history }) {
  const dispatch = useDispatch()
  const [value, setValue] = useState(0)
  useEffect(() => {
    dispatch(actions.formInitialize(initialState, { name: formName }))
    dispatch(initialAutocomplite({ field: tableName }))

    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: tableName }))

    }
  }, [dispatch])
  const arrProps = [
    { field: 'email', type: 'text', label: 'Email', validator: [isEmail], errorText: 'Это не email' },
    { field: 'surname', type: 'text', label: 'Фамилия', validator: [(value) => value !== ''], errorText: msgErr },
    { field: 'name', type: 'text', label: 'Имя', validator: [(value) => value !== ''], errorText: msgErr },
    { field: 'patronymic', type: 'text', label: 'Отчество' },
    { field: 'password', type: 'text', label: 'Пароль', textProps: { type: 'password' }, validator: [(value) => value !== ''], errorText: msgErr },
    { field: 'passwordTwo', type: 'text', label: 'Подтверждения пароля', textProps: { type: 'password' }, validator: [(value) => value !== ''], errorText: msgErr },
    {
      field: 'role', type: 'autoArr', label: 'Права доступа',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: { arr: Object.keys(rules).map(key => ({ value: key, label: rules[key] })) },
    },
  ]
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getFacility.path, {
      field: tableName,
      value: 'id',
      labelFunc: (obj) => obj.name
    }))
    dispatch(fetchDispath({
      progress: true,
      request: loadProject,
    })).then(res => {
      let facilityProject = {}
      let pAllIds = []
      let pById = {}
      res.get.forEach(value => {
        const { id, name, idFacility } = value
        pAllIds.push('id' + id)
        pById['id' + id] = { id, name, idFacility, selected: false }
        if (facilityProject[idFacility]) {
          facilityProject[idFacility].push('id' + id)
        }
        else {
          facilityProject[idFacility] = ['id' + id]
        }
      })
      dispatch(actions.formChangeAsObj({ facilityProject, pById, pAllIds }, { name: formName }))
    }).catch(err => console.log(err))
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSubmit = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    if (values.password !== values.passwordTwo) {
      dispatch(enqueueSnackbar({
        message: `Пароли не совпадают`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        email: values.email,
        password: values.password,
        name: values.name,
        surname: values.surname,
        patronymic: values.patronymic,
        role: values.role,
        project: Object.values(values.pById).filter(obj => obj.selected).map(obj => obj.id)
      },
      url: addNewUser.path,
      method: addNewUser.type,
      history: history
    }, formName))
  }
  return (<div className='App-paper' >
    <Typography variant='h6'>Создание пользователя</Typography>
    <Tabs value={value} onChange={(e, v) => setValue(v)} indicatorColor='primary'>
      <Tab label="Основные" />
      <Tab label="Проекты" />
    </Tabs>
    <form autoComplete="off" onSubmit={handleSubmit}>
      {value === 0 && <div style={{ padding: '16px' }}>
        <SimpleAdd
          arr={arrProps}
          formName={formName}
        />
      </div>}
      {value === 1 && <TableProject formName={formName} tableName={tableName} />}
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
      <Button type="submit" color='primary'>Сохранить</Button>
    </form>
  </div>)
}