import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import * as actions from '../../reducers/form';
import * as api from '../../api/storage'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import DocTable from '../../components/DocTable'
import DocAdd from '../../components/DocAdd'

const formName = 'StorageEquipmentRemovalAdd'
const formAuto = 'equipment'

export default function StorageEquipmentRemovalAdd({ match, history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      allIds: [],
      byId: {},
      date: moment(Date.now()).format('YYYY-MM-DD')
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: formAuto }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formAuto }))
    }
  }, [dispatch])
  useEffect(() => {
    const path = api.getStorageEquipment.path({ id: match.params.id }) + '?balance=our&type=balance'
    const meta = {
      field: 'equipment',
      value: 'id',
      hidden: ['storage', 'storageUnit'],
      labelFunc: (obj) => obj.name
    }
    dispatch(loadAutocompliteAPI(path, meta))
  }, [dispatch, match.params.id])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const saveServer = () => {
    let body = {
      arr: [],
      date: values.date,
      idProject: match.params.id
    }
    values.allIds.forEach(key => {
      body.arr.push({
        idEquipment: values.byId[key].id,
        count: values.byId[key].count
      })
    })
    dispatch(actions.formSubmitAPI({
      props: body,
      url: api.removeStorageEquipment.path,
      method: api.removeStorageEquipment.type,
      history: history
    }, formName))
  }
  return <div className='App-paper'>
    <DocAdd
      formName={formName}
      history={history}
      title='Списать расходные материалы'
      noTableProps={[
        { field: 'date', label: 'Дата наряда', type: 'text', textProps: { type: 'date' } },
      ]}
      handleSave={saveServer}
    >
      <DocTable
        formName={formName}
        column={[
          {
            id: 'valueMaterial', type: 'auto', name: 'Материал', formArr: formAuto,
            filtered: true, changeValue: text => ({
              storage: text.storage,
              storageUnit: text.storageUnit,
              id: text.value,
              value: text,
            }),
            width: '30%'
          },
          {
            id: 'count', type: 'number', name: 'Количество', title: obj => `max ${obj.storage}`, width: '25%',
            textProps: obj => ({ error: obj.count > obj.storage, helperText: obj.count > obj.storage ? 'Больше чем на складе' : '' })
          },
          { id: 'storageUnit', type: 'string', name: 'Ед.изм' },
        ]}
        rowDefault={{
          count: '',
          storage: '',
          id: '',
          storageUnit: '',
          value: null
        }}
      />
    </DocAdd>
  </div>
}