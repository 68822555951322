import React, { useState, useEffect } from 'react'
import PageList from '../../components/PageListAPI'
import { useDispatch } from 'react-redux';
import { getOcrDoc } from '../../api/ocr'
import { docType } from './ocrConst'
import {  getTackCheck } from '../../api/ocr'
import { fetchDispath } from '../../functions/fetch'

export default function OcrDocList(props) {
  const dispatch = useDispatch()
  const [check, setCheck] = useState(false)
  useEffect(() => {
    if (check) return;
    setCheck(true)

    dispatch(fetchDispath({
      param: {
        id: props.match.params.idProject
      },
      progress: true,
      request: getTackCheck,
    })).then().catch(err => console.log(err))
  }, [dispatch, check, props.match.params.idProject])
  return <div className='App-paper'>
    <PageList
      name='equipment'
      columns={[
        { id: 'verified', name: 'Статус', type: 'component', component: ({ obj }) => <div>{obj.verified ? 'Проверен' : 'Распознан'}</div> },
        { id: 'number', name: 'Номер документа' },
        { id: 'dateCreate', name: 'Дата загрузки', type: 'date', formatDate: 'DD.MM.YYYY' },
        { id: 'date', name: 'Дата документа', type: 'date', formatDate: 'DD.MM.YYYY' },
        { id: 'customer', name: 'Плательщик' },
        { id: 'supplier', name: 'Поставщик' },
        { id: 'typeRus', name: 'Тип документа' },
        { id: 'amount', name: 'Сумма документа' },
      ]}
      createItem={false}
      deleteItem={false}
      loadAPI={{
        request: getOcrDoc,
        param: {
          id: props.match.params.idProject
        },
        transform: (res => {
          return {
            count: res.count,
            get: res.get.map(obj => ({ ...obj, typeRus: docType[obj.type] }))
          }
        }),
      }}
      headerInit={{
        sortBy: 'dateCreate',
        sortDirection: 'desc',
      }}
      title='Распознанные документы'
      {...props}
    />
  </div>
}
