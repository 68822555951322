import template from "./template_crud";
const machine = '/machine'

export const getMachineInfo = template.getById(machine);
export const updMachineStart = template.updById(machine,'/start');
export const updMachineEnd = template.updById(machine,'/end');
export const updMachineTaskResult = template.updById(machine,'/task_result');
export const removeMachineTaskResult = template.updById(machine,'/task_result_remove');
export const updMachineTaskFinish = template.updById(machine,'/task_finish');
//checklist
export const getMachineCheckList = template.getById(machine,'/c_list');
export const startMachineCheckList = {
    path: (obj) => `${machine}/${obj.id}/c_list/start`,
    type: 'POST'
};
export const getCheckListWBId =  {
    path: (obj) => `${machine}/${obj.id}/c_list/${obj.idChWB}`,
    type: 'GET'
}
export const updCheckListWBIdAnswer =  {
    path: (obj) => `${machine}/${obj.id}/c_list/${obj.idChWB}/answer`,
    type: 'PUT'
}
export const updCheckListWBIdStop =  {
    path: (obj) => `${machine}/${obj.id}/c_list/${obj.idChWB}/stop`,
    type: 'PUT'
}