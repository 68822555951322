import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../reducers/form';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { addMaterialsMany } from '../../../api/material'
import { getStorageUnit, addStorageUnit } from '../../../api/list'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
}))
const formName = 'MaterialAdd'
export default function MaterialAdd ({ match, history }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      name: '',
      typeMark: '',
      vendorCode: '',
      number: '',
      count: 0,
      storageUnit: '',
      price: 0,
      estimate: false
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const arrProps = [
    { field: 'name', type: 'text', label: 'Наименование', validator: [value => value !== ''], errorText: 'Поле обязательно' },
    { field: 'typeMark', type: 'text', label: 'Тип, марка' },
    { field: 'vendorCode', type: 'text', label: 'Артикул' },
    { field: 'number', type: 'text', label: 'Номер' },
    { field: 'count', type: 'text', label: 'Количество по смете', textProps: { autoComplete: 'off', type: 'number' }, validator: [value => value !== 0 && value !== ''], errorText: 'Поле обязательно' },
    { field: 'price', type: 'text', label: 'Цена за еденицу', textProps: { autoComplete: 'off', type: 'number' } },
    {
      field: 'storageUnit', label: 'Еденица хранения', type: 'autoLoadCreate',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        createAPI: addStorageUnit,
        createBody: 'name',
        path: getStorageUnit.path,
        meta: {
          field: 'storageUnit',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
  ]
  const form = useSelector(state => state.form[formName] || state.form.default)
  const arrSu = useSelector(state => state.autocomplite.storageUnit || state.autocomplite.default)
  const { values } = form
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    const su = arrSu.arr.find(obj => obj.value === values.storageUnit)
    if (!su) return;
    const params = {
      idProject: match.params.id,
      arr: [{
        A: values.name,
        B: values.count,
        C: su.label,
        D: values.price,
        E: values.number,
        F: values.vendorCode,
        G: values.typeMark
      }],
      column: {
        A: "name",
        B: "count",
        C: "storageUnit",
        D: "price",
        E: "number",
        F: "vendorCode",
        G: "typeMark"
      },
      estimate: values.estimate,
      consist: 'add'
    }
    dispatch(actions.formSubmitAPI({
      props: params,
      url: addMaterialsMany.path,
      method: addMaterialsMany.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper' >
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Добавить материал'
          />
          <div><FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={values.estimate || false}
                onChange={(e) => {
                  dispatch(actions.formChange(e.target.checked, { field: 'estimate', name: formName }))
                }}
                color="primary"
              />
            }
            label="Материалы заказчика"
          /></div>
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">{(!match.params.idMaterial) ? 'Сохранить' : 'Изменить'}</Button>
      </form>
    </div>
  );
}
