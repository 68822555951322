import React from 'react';
import { API_BO } from '../../conf'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom';

export const butt = [
  {
    primary: "Объекты",
    toLink: React.forwardRef((props, ref) => (<Link ref={ref} to={API_BO + '/facility/our'} {...props} />))
  },
  {
    primary: "Справочники",
    toLink: React.forwardRef((props, ref) => (<Link ref={ref} to={API_BO + '/lists'} {...props} />))
  },
  {
    primary: "Склад",
    toLink: React.forwardRef((props, ref) => (<Link ref={ref} to={API_BO + '/storagemenu'} {...props} />))
  },
  {
    primary: "Тендер",
    toLink: React.forwardRef((props, ref) => (<Link ref={ref} to={API_BO + '/tender'} {...props} />))
  },
  {
    primary: "Другое",
    toLink: React.forwardRef((props, ref) => (<Link ref={ref} to={API_BO + '/multi'} {...props} />))
  },
]

export default function ListButton() {
  return (<div>
    {butt.map(e => {
      return <Button 
      key={e.primary} 
      color='inherit' 
      size='small'
      component={e.toLink} 
        style={{ 'textTransform':'capitalize'}}>{e.primary}</Button>
    })}
  </div>)
}