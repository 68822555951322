import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, List } from '@material-ui/core'
import ListItemLink from './ListItemLink'
import { API_BO } from '../../../conf'

const useStyles = makeStyles((theme) => ({
  card: {
    width: 240,
    margin: theme.spacing(2)
  },
  title: {
    fontSize: 18,
  },
}));
export default function CardMenu({ arr, name }) {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <Typography variant="h5" color='primary' component="h2" className={classes.title}>
        {name}
      </Typography>
      <List>
        {arr.map(key =>
          <ListItemLink key={key.to}
            {...key}
            to={API_BO + key.to}
          />
        )}
      </List>
    </div>
  );
}