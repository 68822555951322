import React from 'react';
import PageList from '../../components/PageListAPI'
import { moveProject } from '../../api/inventory'
import { getOurStorage } from '../../api/storage'

export default function InventoryMoveProject(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='position'
        columns={[
          { id: 'id', name: 'Номер' },
          { id: 'storageName', name: 'Склад' },
          { id: 'projectName', name: 'Проект' },
        ]}
        createItem={true}
        deleteItem={true}
        loadAPI={{
          request: moveProject.getList
        }}
        filterProps={[
          {
            type: 'autocomplite',
            path: getOurStorage.path,
            field: 'idStorage',
            name: 'Склад',
          },
        ]}
        click={false}
        deleteAPI={moveProject.deleteById}
        {...props}
      />
    </div>
  )
}