import React, { useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { formArrayDelete, formObjDelete } from '../../reducers/form';
import { addRowFormTable, editFieldTable } from '../../reducers/formTable'


export default function DivideMaterial({ formName }) {
  const [open, setOpen] = useState(false)
  const [divideValue, setDivideValue] = useState(0)
  const [maxDivideValue, setMaxDivideValue] = useState(0)
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const { selectedEstimate=[], selectedEstimateById } = values
  const handleDivide = () => {
    const obj = selectedEstimateById[selectedEstimate[0]]
    setOpen(true)
    setDivideValue(Number(obj.count))
    setMaxDivideValue(Number(obj.count))
  }
  const handleDivideOk = () => {
    const newDivide = Math.round(Number(divideValue)*1000000)/1000000
    const id = selectedEstimate[0]
    const obj = selectedEstimateById[id]
    const objTwo = { ...obj, count: newDivide, summ: obj.price * newDivide }
    const newCount = Math.round(Number(obj.count*1000000) - Number(newDivide*1000000))/1000000
    const newPrice = Math.round(obj.price * newCount*1000000)/1000000
    batch(() => {
      dispatch(formArrayDelete(id, { field: 'selectedEstimate', name: formName }))
      dispatch(formObjDelete({ id: id, field: 'selectedEstimateById', name: formName }))

      dispatch(editFieldTable(newCount, { id, field: 'count', name: formName }))
      dispatch(editFieldTable(newPrice, { id, field: 'summ', name: formName }))
      dispatch(addRowFormTable(objTwo, { name: formName }))
    })
    setOpen(false)
  }
  const handleChange = (e) => {
    if (e.target.value <= maxDivideValue) {
      setDivideValue(e.target.value)
    }
  }
  return <React.Fragment>
    <Button color="primary" onClick={handleDivide} disabled={selectedEstimate.length !== 1}>Разделить</Button>
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Разделить материал</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Внимание. Если разделенный материал не переместить, то данное разделение действует только до обновления стараницы
          </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="count"
          label="Количество"
          type='number'
          fullWidth
          value={divideValue}
          inputProps={{
            max: maxDivideValue
          }}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Отменить
        </Button>
        <Button onClick={handleDivideOk} color="primary">
          Разделить
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}