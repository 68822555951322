import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { fetchDispath, } from '../../functions/fetch'
import { getMe } from '../../api/app_worker'
import { Typography, List, ListItem, ListItemText } from '@material-ui/core'
import { API_BO } from '../../conf'

export default function WorkerMain({ history }) {
  const [state, setState] = useState({
    name: '',
    surname: '',
    patronymic: '',
    avatar: '',
    avatarName: '',
    login: '',
    chapter: [],
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getMe,
    })).then(res => {
      if (res.success) {
        setState({
          name: res.get.name || '',
          surname: res.get.surname || '',
          patronymic: res.get.patronymic || '',
          avatar: res.get.avatar || '',
          avatarName: res.get.avatarName || '',
          login: res.get.login || '',
          chapter: []
        })
      }
    }).catch(err => console.log(err))
  }, [dispatch])
  const arr = [
    { value: 'driver', label: 'Задание водителя', path: '/worker-app/driver' }
  ]
  return <div style={{ textAlign: 'center' }}>
    <img
      src="/logo.svg"
      alt="logo"
      style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '50%', maxWidth: '200px' }}
    />
    <Typography variant='h6'>Добро пожаловать {state.surname} {state.name} {state.patronymic}</Typography>
    <div style={{  }}>
      <List>
        {arr.map(e => {
          return <ListItem button key={e.value} onClick={() => { history.push(`${API_BO}${e.path}`) }} >
            <ListItemText primary={<Typography style={{ textAlign: 'center' }}>{e.label}</Typography>} />
          </ListItem>
        })}
      </List>
    </div>
  </div>
}