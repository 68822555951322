import React from 'react';
import PageList from '../../../components/PageListAPI'
import { getRegions, deleteRegions } from '../../../api/list'

export default function RegionList(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='region-list'
        columns={[{ id: 'name', name: 'Название' }]}
        createItem={true}
        deleteItem={true}
        loadAPI={{
          request: getRegions,
        }}
        deleteAPI={deleteRegions}
        pagination={false}
        tableProps={{
          pagination: false,
        }}
        {...props}
      />
    </div>
  )
};