import React from 'react'
import { Dialog, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { formSubmitAPI } from '../../../reducers/form';
import { setWorkerState } from '../../../api/project-worker'

function SetPWADriver({ snp, onClose, open, date, idProject, id }) {
  const dispatch = useDispatch()
  const arr = [
    { color: '#00ff00', name: 'attent', primary: 'Пришел' },
    { color: '#ffaa00', name: 'late', primary: 'Опоздал' },
    { color: '#ff0000', name: 'absent', primary: 'Отсутствует' },
  ]
  const selectAction = (state) => {
    dispatch(formSubmitAPI({
      props: {
        state,
        date: date
      },
      url: setWorkerState.path({ id: idProject, idWorker: id }),
      method: setWorkerState.type,
      endFunc: () => onClose(true)
    }))
  }
  return (<Dialog anchor='top' open={open} onClose={() => onClose(false)} >
    <DialogTitle id="simple-dialog-title">{snp}</DialogTitle>
    <List>
      {arr.map(obj =>
        <ListItem button key={obj.name} onClick={() => selectAction(obj.name)} style={{ background: obj.color }}>
          <ListItemText primary={obj.primary} />
        </ListItem>
      )}
    </List>
  </Dialog>);
}

export default SetPWADriver;
