import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import { updSpecializations, getSpecializationsById } from '../../../api/list'
import { fetchDispath } from '../../../functions/fetch'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'

export default function SpecializationOne({ history, match }) {
  const formName = 'SpecializationOne'
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(actions.formInitialize({ name: '', errorValidate: { name: false } }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getSpecializationsById,
      param: {
        id: match.params.idDoc
      }
    })).then(res => {
      dispatch(actions.formChange(res.get.name, { field: 'name', name: formName }))
    }).catch(err => console.log(err))
  }, [dispatch, match.params.idDoc])
  const arrProps = [{
    field: 'name', label: 'Наименование', type: 'text',
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  }]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        name: values.name,
      },
      url: updSpecializations.path({ id: match.params.idDoc }),
      method: updSpecializations.type,
      history: history
    }, formName))
  }
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd formName={formName} arr={arrProps} title='Изменить специализацию' />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Изменить</Button>
      </form>
    </div>
  );
}