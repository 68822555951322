import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/header'
import { loadProjectById } from '../../api/projects.js'
import { fetchDispath } from '../../functions/fetch'

export default function ProjectDescription({ location }) {
  const dispatch = useDispatch()
  const project = useSelector(state => state.header)
  const { projectId = 0 } = project
  const pathname = location.pathname
  useEffect(() => {
    if (projectId !== 0) return;
    const searchPath = pathname.search(/\/projects\/[0-9]+/i)
    if (searchPath !== -1) {
      let newID = Number(pathname.substring(searchPath + 10).split('/')[0])
      if (projectId !== newID) {
        dispatch(fetchDispath({
          param: {
            id: newID
          },
          progress: true,
          request: loadProjectById,
        }))
        .then(res=>{
          dispatch(actions.headerSetProject({ id: res.get.id, name: res.get.name }))
          dispatch(actions.headerSetObject({ id: res.get.facilityId, name: res.get.facility }))
        })
      }
    }
  }, [pathname, projectId, dispatch])
  return <div></div>
}