import React, { useState, useEffect, useCallback } from 'react';
import { deleteWorkEstimate, loadWorkEstimate } from '../../api/list'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/formTable';
import { fetchDispath } from '../../functions/fetch'
import { enqueueSnackbar } from '../../reducers/notifier'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {
  Typography, Table, TableHead,
  TableRow, TableCell, TableSortLabel, TableBody,
  TableContainer, Button, Breadcrumbs
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import { loadEstimateToWork } from '../../api/work'
import { formSubmitAPI } from '../../reducers/form';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
  },
  butt: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1
  },
  table: {
    height: 700
  },
  cell: {
    // fontSize: '0.8rem'
  },
  pagination: {},
  container: {
    height: '35vh'
  }
}));

export default function WorkLoad({ location, match, history }) {
  const tableActions = {};
  const name = 'resources'
  const classesPage = useStyles()
  const dispatch = useDispatch();
  const formTable = useSelector(state => state.formTable[name] || state.formTable.default)
  const [arrParent, setArrParent] = useState([])
  const [arrSelect, setArrSelect] = useState([])
  const [objSelect, setObjSelect] = useState({})
  useEffect(() => {
    const parent = (arrParent.length > 0) ? arrParent[arrParent.length - 1].id : undefined
    dispatch(fetchDispath({
      querty: {
        parent,
      },
      progress: true,
      request: loadWorkEstimate,
    })).then(res => {
      dispatch(actions.loadFormTable({ ...res }, { name: name, id: 'id' }))
    }).catch(err => console.log(err))
  }, [dispatch, name, arrParent])

  const load = useCallback(() => {
    const parent = (arrParent.length > 0) ? arrParent[arrParent.length - 1] : undefined
    dispatch(fetchDispath({
      querty: {
        parent,
      },
      progress: true,
      request: loadWorkEstimate,
    })).then(res => {
      dispatch(actions.loadFormTable({ ...res }, { name: name, id: 'id' }))
    }).catch(err => console.log(err))
  }, [dispatch, name, arrParent])


  useEffect(() => {
    dispatch(actions.initFormTable({ name: name, header: {} }))
    return () => {
      dispatch(actions.clearFormTable(name))
    }
  }, [dispatch, name])


  const deleteCustom = (param, body) => {
    dispatch(fetchDispath({
      param,
      body,
      progress: false,
      request: deleteWorkEstimate
    }))
      .then((res) => {
        let msg = {}
        if (res) {
          load()
          msg = {
            message: `success`,
            options: { variant: 'success' }
          }
        }
        else {
          msg = {
            message: `not deleted`,
            options: { variant: 'error' }
          }
        }
        dispatch(enqueueSnackbar(msg))
      })
  }
  const handleDeleteOne = (id) => {
    deleteCustom({
      id: formTable.byId[id].id
    })
  }
  const handleSort = (sortBy, sortDirection) => {
    dispatch(actions.setSortFormTable({ sortBy, sortDirection }, { name: name }))
  }
  const pushParent = (obj) => setArrParent([...arrParent, { id: obj.id, name: obj.name }])
  const selectParent = (id) => {
    if (id === -1) {
      setArrParent([])
      return;
    }
    const findIndex = arrParent.findIndex(e => e.id === id)
    setArrParent(arrParent.slice(0, findIndex + 1))
  }
  const handleLoad = () => {
    dispatch(formSubmitAPI({
      props: {
        arr: arrSelect.map(key => objSelect[key].id)
      },
      url: loadEstimateToWork.path({ id: match.params.idProject }),
      method: loadEstimateToWork.type,
      history: history
    }, 'formName'))
  }
  return (
    <div className='App-paper'>
      <Typography variant='h6'>Загрузить из шаблона</Typography>
      <CollapsedBreadcrumbs arrParent={arrParent} selectParent={selectParent} />
      <TableCardForm
        formName={name}
        classes={classesPage}
        tableActions={{
          onDeleteRow: handleDeleteOne,
          handleSelectAllClick: () => { },
          setSort: handleSort,
          ...tableActions
        }}
        tableData={{ allIds: formTable.allIds.filter(e => !arrSelect.includes(e)), byId: formTable.byId }}
        history={history}
        match={match}
        pushParent={pushParent}
        setArrSelect={setArrSelect}
        setObjSelect={setObjSelect}
        undo={false}
      />
      <TableCardForm
        formName={name}
        classes={classesPage}
        tableActions={{
          onDeleteRow: handleDeleteOne,
          handleSelectAllClick: () => { },
          setSort: handleSort,
          ...tableActions
        }}
        tableData={{ allIds: arrSelect, byId: objSelect }}
        history={history}
        match={match}
        pushParent={pushParent}
        setArrSelect={setArrSelect}
        setObjSelect={setObjSelect}
        undo={true}
      />
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
      <Button color='primary' component="span" onClick={handleLoad}>Загрузить</Button>
    </div>
  )
}

function CollapsedBreadcrumbs({ arrParent, selectParent }) {
  return (
    <Breadcrumbs maxItems={4} aria-label="breadcrumb" >
      <Typography color="inherit" onClick={() => selectParent(-1)} style={{ cursor: 'pointer' }}>
        root
      </Typography>
      {arrParent.map((e, i) => {
        let onClick;
        if (arrParent.length - 1 !== i) onClick = () => selectParent(e.id)
        return <Typography key={i} color="inherit" onClick={onClick} style={{ cursor: arrParent.length - 1 !== i ? 'pointer' : undefined }}>
          {e.name}
        </Typography>
      })}
    </Breadcrumbs>
  );
}


function TableCardForm({
  formName,
  classes = {},
  tableData,
  pushParent,
  setArrSelect,
  setObjSelect,
  undo = false
}) {
  const { allIds = [], byId = {} } = tableData
  return (<React.Fragment>
    <TableContainer className={classes.container}>
      <Table size='small' stickyHeader aria-label="sticky table" >
        <TableHeadCustom formName={formName} />
        <TableBody>{
          allIds.map(row => {
            const obj = byId[row]
            return (<RowTableForm
              key={row}
              obj={obj}
              id={row}
              pushParent={pushParent}
              setArrSelect={setArrSelect}
              setObjSelect={setObjSelect}
              undo={undo}
            />)
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </React.Fragment>
  )
}

function TableHeadCustom({ formName }) {
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { sortBy = 'name', sortDirection = 'asc' } = formTable
  const dispatch = useDispatch()
  const handleRequestSort = (sortByNew) => {
    let sortDirectionNew = 'desc';
    if (sortBy === sortByNew && sortDirection === 'desc') {
      sortDirectionNew = 'asc';
    }
    dispatch(actions.setSortFormTable({ sortBy: sortByNew, sortDirection: sortDirectionNew }, { name: formName }))
  };
  return <TableHead>
    <TableRow>
      <TableCell>Тип</TableCell>
      <TableCell sortDirection={sortBy === 'name' ? sortDirection : false}      >
        <TableSortLabel
          active={sortBy === 'name'}
          direction={sortDirection}
          onClick={() => handleRequestSort('name')}
        >
          Наименование
        </TableSortLabel>
      </TableCell>
      <TableCell sortDirection={sortBy === 'count' ? sortDirection : false}      >
        <TableSortLabel
          active={sortBy === 'count'}
          direction={sortDirection}
          onClick={() => handleRequestSort('count')}
        >
          Количество
        </TableSortLabel>
      </TableCell>
      <TableCell sortDirection={sortBy === 'storageUnit' ? sortDirection : false}      >
        <TableSortLabel
          active={sortBy === 'storageUnit'}
          direction={sortDirection}
          onClick={() => handleRequestSort('storageUnit')}
        >
          Ед.изм.
        </TableSortLabel>
      </TableCell>
      <TableCell sortDirection={sortBy === 'price' ? sortDirection : false}      >
        <TableSortLabel
          active={sortBy === 'price'}
          direction={sortDirection}
          onClick={() => handleRequestSort('price')}
        >
          Цена
        </TableSortLabel>
      </TableCell>
      <TableCell align='right'></TableCell>
    </TableRow>
  </TableHead>
}

function RowTableForm({ obj, pushParent, setArrSelect, id, undo, setObjSelect }) {
  const handleClick = () => {
    if (obj.isFolder) pushParent(obj)
    else {
      if (undo) {
        setArrSelect(e => (e.filter(h => h !== id)))
      }
      else {
        setArrSelect(e => ([...e, id]))
        setObjSelect(e => ({ ...e, [id]: obj }))
      }
    }
  };
  if (obj.isFolder) {
    return <TableRow
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <TableCell style={{ width: '7%' }} padding='none'><FolderIcon size='small' /></TableCell>
      <TableCell>{obj.name}</TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
    </TableRow>
  }
  return (
    <TableRow
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <TableCell style={{ width: '7%' }} padding='none' >
        {
          (undo) ? <ArrowUpwardIcon size='small' />
            : <ArrowDownwardIcon size='small' />
        }
      </TableCell>
      <TableCell>{obj.name}</TableCell>
      <TableCell>{obj.count}</TableCell>
      <TableCell>{obj.storageUnit}</TableCell>
      <TableCell>{obj.price}</TableCell>
    </TableRow>
  )
}
