import React, { useState, useEffect } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { initFormTable, clearFormTable, loadFormTable } from '../../../reducers/formTable';
// import * as actionAuto from '../../reducers/autocomplite'
import { fetchDispath } from '../../../functions/fetch'
import { getFacilityMaterial } from '../../../api/facility'
import { Button, Table, TableHead, TableBody, TableRow, TableCell, Typography, TableSortLabel } from '@material-ui/core';
import AutocompliteMulti from './AutocompliteMulti'

function GetMultiMaterial() {
  const dispatch = useDispatch()
  const materialName = 'getMM'
  const projectName = 'getMP'
  const [arrProject, setArrProject] = useState([])
  useEffect(() => {
    dispatch(initFormTable({ name: materialName }))
    dispatch(initFormTable({ name: projectName }))
    return () => {
      dispatch(clearFormTable(materialName))
      dispatch(clearFormTable(projectName))
    }
  }, [dispatch])
  const loadMaterial = () => {
    const arr = arrProject.map(e => e.value)
    if (arr.length === 0) return;
    dispatch(fetchDispath({
      querty: {
        arr: arr
      },
      progress: true,
      request: getFacilityMaterial,
    })).then(res => {
      const { list, project } = res.get
      let gobj = {}
      project.forEach((el, i) => {
        gobj[el.id] = { res: [] };
      });
      const newList = list.map((obj, i) => {
        gobj[obj.idProject].res.push('id' + obj.id + '_' + i);
        const will = Math.round((obj.storage + obj.costs) * 1000) / 1000
        const wool = Math.round((obj.count - obj.storage - obj.costs) * 1000) / 1000
        return { ...obj, will, wool }
      });
      batch(() => {
        dispatch(loadFormTable({ count: newList.length, get: newList }, { name: materialName, id: 'id' }))
        dispatch(loadFormTable({
          count: project.length, get: project.map(e => {
            return { ...e, res: gobj[e.id].res }
          })
        }, { name: projectName, id: 'id' }))

      })
    }).catch(err => console.log(err))
  }
  return <div className='App-paper'>
    <Button onClick={loadMaterial} color='primary'>Загрузить</Button>
    <AutocompliteMulti handleChange={(e) => setArrProject(e)} />
    <TableM />
  </div>
}

export default GetMultiMaterial



function TableM() {
  const material = useSelector(state => state.formTable['getMM'] || state.formTable.default)
  const project = useSelector(state => state.formTable['getMP'] || state.formTable.default)
  const header = [
    { id: 'number', name: '№', align: 'center' },
    { id: 'name', name: 'Наименование', align: 'left' },
    { id: 'storageUnit', name: 'Ед. изм.', align: 'left' },
    { id: 'count', name: 'Количество по смете', align: 'right' },
    { id: 'will', name: 'Поставлено', align: 'right' },
    { id: 'wool', name: 'Требуется докупить', align: 'right' },
    { id: 'costs', name: 'Расходы', align: 'right' },
    { id: 'storage', name: 'Остаток складе', align: 'right' },
  ]
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('number')
  const handleRequestSort = (property) => {
    const orderBy = property;
    let orderNew = 'desc';
    if (orderBy === property && order === 'desc') {
      orderNew = 'asc';
    }
    setOrder(orderNew)
    setOrderBy(property)
  };
  return <Table>
    <TableHead>
      <TableRow >
        {header.map(obj => {
          const align = obj.align || 'inherit'
          return (<TableCell
            key={obj.id}
            align={align}
            sortDirection={orderBy === obj.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === obj.id}
              direction={order}
              onClick={(e) => handleRequestSort(obj.id)}
            >
              {obj.name}
            </TableSortLabel>
          </TableCell>);
        })}
      </TableRow>
    </TableHead>
    <TableBody>
      {project.allIds.map(e => {
        const proj = project.byId[e]
        return <Project
          key={e}
          header={header}
          material={material} proj={proj}
          order={order}
          orderBy={orderBy}
        />
      })}
    </TableBody>
  </Table>
}
function desc(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}
function stableSort(array, objs, orderBy, order) {
  const stabilizedThis = array.map(key => ([key, objs[key][orderBy]]));
  const ord = order === 'desc' ? 1 : -1
  stabilizedThis.sort((a, b) => {
    return ord * desc(a[1], b[1]);
  });
  return stabilizedThis.map(el => el[0]);
}


function Project({ header, material, proj, order, orderBy }) {
  const [open, setOpen] = useState(true)
  return <React.Fragment>
    <TableRow onClick={() => setOpen(!open)}>
      <TableCell colSpan={header.length - 1} size='small'>
        <Typography component="div" style={{ fontWeight: 'bold' }}>{proj.name}</Typography>
      </TableCell>
    </TableRow>
    {open &&
      stableSort(proj.res, material.byId, orderBy, order)
        .map(id => {
          const mat = material.byId[id]

          return <TableRow key={id}>
            {header.map(e => {
              const align = e.align || 'inherit'
              return <TableCell key={e.id} size='small' align={align}>{mat[e.id]}</TableCell>
            })}
          </TableRow>
        })}
  </React.Fragment>
}
