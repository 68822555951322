import React from 'react';
import * as api from '../../api/facility'
import PageList from '../../components/PageListAPI'
import typeResp from './typeResp.enum'

export default function FacilityList(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='facilityResp'
        title='Представители ответственных лиц'
        columns={[
          { id: 'company', name: 'Компания' },
          { id: 'fullName', name: 'ФИО' },
          { id: 'position', name: 'Должность' },
          { id: 'doc', name: 'Документ о представительстве' },
          { id: 'typeResp', name: 'Тип' },
          { id: 'date', name: 'Сроки полномочий', type: 'date', formatDate: 'DD.MM.YYYY' },
        ]}
        createItem={true}
        deleteItem={true}
        idName='id'
        loadAPI={{
          request: api.getFacilityResponsible,
          param: { idFacility: props.match.params.idFacility },
          transform: (res => {
            return {
              count: res.count,
              get: res.get.map(obj => {
                return { ...obj, fullName: obj.surname + ' ' + obj.name + ' ' + obj.patronymic, typeResp: typeResp.find(e => e.value === obj.typeResp)?.label }
              })
            }
          }),
        }
        }
        filterProps={[
          {
            arr: typeResp.map(e => ({ value: e.value, name: e.label })),
            field: 'typeResp',
            name: 'Тип',
            type: 'select'
          },
        ]}
        deleteAPI={api.delFacilityResponsible(props.match.params.idFacility)}
        {...props}
      />
    </div>
  )
}