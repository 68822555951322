import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function ListItemLink({ icon, primary, to, classes, disabled, onClick }) {
  const renderLink = React.forwardRef((props, ref) => (<Link ref={ref} to={to} {...props} />))
  return (
    <li>
      <ListItem button component={renderLink} onClick={() => { onClick(); }} className={classes} disabled={disabled}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

