import React from 'react';
import PageList from '../../components/PageListAPI'
import { getKS3ById } from '../../api/formks2ks3'
import KS3ByIdHeader from './KS3ByIdHeader'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    height: '50vh'
  }
}))
const formName = 'ks2list'
export default function KS3ById(props) {
  const classes = useStyles()
  const tableHead = [
    { id: 'number', name: '№', },
    { id: 'name', name: 'Наименование пусковых комплексов, этапов, объектов, видов выполненных работ, оборудования, затрат', },
    { id: 'priceStart', name: 'Стоимость  с начала проведения работ', },
    { id: 'priceYear', name: 'Стоимость  с начала года', },
    { id: 'pricePeriod', name: 'Стоимость   в том числе за отчетный период', },
  ];
  const idDoc = props.match.params.idDoc
  const idFacility = props.match.params.idFacility
  return (
    <div className='App-paper'>
      <PageList
        name={formName}
        columns={tableHead}
        classes={classes}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getKS3ById(idFacility),
          param: {
            id: idDoc
          },
          transform: (res) => {
            let summAll = 0;
            res.get.forEach(e => {
              summAll += Number(e.pricePeriod);
            });
            return {
              doc: {
                ...res.doc,
                summAll: summAll,
                nds: summAll * 0.2,
                summWithNds: summAll * 1.2
              },
              get: [
                {
                  number: '',
                  name: 'Всего работ и затрат, включаемых в стоимость работ',
                  priceStart: res.doc.priceStart,
                  priceYear: res.doc.priceYear,
                  pricePeriod: res.doc.pricePeriod,
                },
                {
                  number: '',
                  name: 'в том числе:',
                  priceStart: '',
                  priceYear: '',
                  pricePeriod: '',
                }
                , ...res.get]
            }
          }
        }}
        headerObj={<KS3ByIdHeader formName={formName} idFacility={idFacility} idDoc={idDoc} />}
        tableProps={{
          pagination: false
        }}
        pagination={false}
        csv={false}
        click={false}
        {...props}
      />
    </div>
  )
}