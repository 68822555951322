import React from 'react';

// import 'styles/loading.scss';

const Loading = () => (
  <div className="loading-container">
    <span className="loading" />
  </div>
);

export default Loading;