import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/form';
import { getWorkerList, addWorkerRating } from '../../api/worker'
import Button from '@material-ui/core/Button'
import StarChange from './source/StarChange'
import SimpleAdd from '../../components/SimpleAdd'
import { enqueueSnackbar } from '../../reducers/notifier'
import validateProps from '../../functions/validateProps'
const formName = 'RatingAdd'
export default function RatingAdd ({ history }) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(actions.formInitialize({
      idWorker: '',
      star: 0,
      comment: '',
      errorValidate: {
        comment: false,
        idWorker: false
      }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const arrProps = [
    {
      field: 'idWorker', label: 'Работник', type: 'autoLoad',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        path: getWorkerList.path,
        meta: {
          field: 'worker',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
    { field: 'star', type: 'component', textProps: { component: () => <StarChange formName={formName} /> } },
    { field: 'comment', label: 'Коментарий', type: 'text', textProps: { multiline: true, rows: "4" } }
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    if (values.star === 0) {
      dispatch(enqueueSnackbar({
        message: `необходимо поставить оценку`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        idWorker: values.idWorker,
        star: values.star,
        comment: values.comment,
      },
      url: addWorkerRating.path,
      method: addWorkerRating.type,
      history: history
    }, formName))
  }
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave} >
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            title='Оценить сотрудника'
            arr={arrProps}
            formName={formName}
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  )
}