import template from "./template_crud";
export const login = template.post('/user/login');
export const resetMail = template.post('/user/reset-mail');
export const resetPwd = template.post('/user/reset-pwd');
export const loginAccount = template.post('/user/login-account');
export const getUserList = template.getList('/user/list');

const user = '/user'
export const addUserDeleteProject = template.updById(user,'/project');
export const getUserProject = template.getById(user,'/project');
export const getUserNotProject = template.getById(user,'/notproject');
export const changeAccessUser = template.updById(user,'/role');
export const loadUser = template.getById(user);

const profile = '/user/profile';
export const getProfile = template.get(profile);
export const updProfile = template.upd(profile);
export const getOurProject = template.get('/user/project');
export const updPassword = template.upd('/user/password');
export const addNewUser = template.addOne('/user/registration');