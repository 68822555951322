import React, { useEffect, useState } from 'react'
import { batch, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core'
import * as actions from '../../reducers/formTable';
import { fetchDispath } from '../../functions/fetch'
// import { enqueueSnackbar } from '../../reducers/notifier'
import { getShare } from '../../api/share'
import GroupTableShare from './GroupTableShare'

const formGname = 'groupEstimateShare'
const formRname = 'groupResEstimateShare'
export default function EstimateListShare({ match }) {
  const dispatch = useDispatch()
  const [summGroup, setSummGroup] = useState(0)
  useEffect(() => {
    dispatch(actions.initFormTable({ name: formGname }))
    dispatch(actions.initFormTable({ name: formRname }))
    return () => {
      dispatch(actions.clearFormTable(formGname))
      dispatch(actions.clearFormTable(formRname))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      querty: {
        link: match.params.id
      },
      progress: true,
      request: getShare,
    })).then(res => {
      const { list, group } = res.get
      let gobj = {}
      let summGroupNew = 0;
      group.forEach((el, i) => {
        gobj[el.id] = { res: [], summ: 0 };
      });
      list.forEach((el, i) => {
        const summ = Number(el.summ)
        gobj[el.idGroup].res.push('id' + el.id + '_' + i);
        gobj[el.idGroup].summ += summ
        summGroupNew += summ
      });
      batch(() => {
        dispatch(actions.loadFormTable({ count: list.length, get: list }, { name: formRname, id: 'id' }))
        dispatch(actions.loadFormTable({
          count: group.length, get: group.map(e => {
            return { ...e, res: gobj[e.id].res, ressumm: gobj[e.id].summ }
          })
        }, { name: formGname, id: 'id' }))
        setSummGroup(summGroupNew)
      })
    }).catch(err => console.log(err))
  }, [dispatch, match.params.id])
  return <div className='App-paper'>
    <Typography variant='h6'> Смета</Typography>
    <Typography variant='h6'>{'Всего по смете :' + (Math.round(100 * summGroup) / 100).toLocaleString()}</Typography>
    <GroupTableShare formGname={formGname} formRname={formRname} />
  </div>
}