import React from 'react'
import { useSelector } from "react-redux";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
export default function WorkList({ addWork }) {
  const materialArr = useSelector(state => state.autocomplite.work || state.autocomplite.default)
  const filterArr = materialArr.arr.filter(obj => {
    return !(typeof obj.select !== 'undefined')
  })
  return <Table>
    <TableHead>
      <TableRow>
        {['Наименование'].map((e,i)=><TableCell key={i}>{e}</TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
    {filterArr.map((v,i)=>{
      return <TableRow onClick={()=>addWork(v)} key={i}>
      <TableCell>{v.label}</TableCell>
      </TableRow>
    })}
    </TableBody>
  </Table>
}