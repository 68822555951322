import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core'
import { Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Chip } from '@material-ui/core'
import { fetchDispath, } from '../../functions/fetch'
import { getDriver, getDriverCurrent } from '../../api/app_worker'
import moment from 'moment'
import CachedIcon from '@material-ui/icons/Cached';

const LIMIT = 50
export default function WorkerDriver({ history, match }) {
  const [selected, setSelected] = useState('main')
  const handleBack = () => {
    if (selected === 'main') {
      history.goBack()
    }
    else {
      setSelected('main')
    }
  }
  const handleSelect = (id) => {
    history.push(`${match.url}/${id}`)
  }
  return <div style={{ textAlign: 'center', padding: 10 }}>
    <Typography variant='h6'>Путевые листы</Typography>
    <div style={{ textAlign: 'left' }}>
      <Button color="primary" onClick={handleBack}>Назад</Button>
    </div>
    {selected === 'main' ? <WorkerDriverMain selectItem={(e) => setSelected(e)} /> : null}
    {selected === 'now' ? <WorkerDriverNow handleSelect={handleSelect} /> : null}
    {selected === 'list' ? <WorkerDriverList handleSelect={handleSelect} /> : null}
  </div>
}

function WorkerDriverMain({ selectItem }) {
  return <List>
    {[{ value: 'now', label: ' Задание на сегодня' }, { value: 'list', label: 'Список' }].map(e => {
      return <ListItem button key={e.value} onClick={() => { selectItem(e.value) }} >
        <ListItemText primary={<Typography style={{ textAlign: 'center' }}>{e.label}</Typography>} />
      </ListItem>
    })}
  </List>
}

function WorkerDriverNow({ handleSelect }) {
  const [arrCalendar, setArrCalendar] = useState([{ id: 0, machine: 'Заданий на текущую дату нет' }])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getDriverCurrent,
    })).then(res => {
      if (res.success && res.get.length > 0) {
        setArrCalendar(res.get)
      }
    }).catch(err => console.log(err))
  }, [dispatch])
  return <List>
    {arrCalendar.map(e => {
      return <React.Fragment key={e.id}>
        <ListItem
          button={Boolean(e.idWaybill)}
          onClick={() => { if (e.idWaybill) { handleSelect(e.idWaybill) } }}
        >
          <ListItemText
            primary={<Typography style={{ textAlign: 'center' }}>{e.machine}</Typography>}
            secondary={
              <Typography style={{ color: '#8d8d8d' }}>{e.calendarTime}{!Boolean(e.idWaybill) && <span style={{ color: '#E94545' }}>{' нет путевого листа '}</span>}</Typography>
            }
          />
        </ListItem>
      </React.Fragment>
    })}
  </List>
}

function WorkerDriverList({ handleSelect }) {
  const [arrCalendar, setArrCalendar] = useState([])
  const [offset, setOffset] = useState(0)
  const [count, setCount] = useState(-1)
  const [currentMonth, setCurrentMonth] = useState('')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      querty: {
        limit: LIMIT, offset
      },
      progress: true,
      request: getDriver,
    })).then(res => {
      if (res.success) {
        const newArr = []
        setCurrentMonth(old => {
          let current = old
          res.get.forEach(e => {
            const day = moment(e.calendarDate).format('DD')// + ' ' + e.calendarTime
            const month = moment(e.calendarDate).format('MMMM YYYY')
            const visible = month !== current
            current = month
            newArr.push({ ...e, day, month, visible })
          })
          return current
        })
        setArrCalendar(old => ([...old, ...newArr]))
        setCount(res.count)
      }
    }).catch(err => console.log(err))
  }, [dispatch, offset])
  const handleLoad = () => {
    if (offset + LIMIT < count) {
      setOffset(e => e + LIMIT)
    }
  }
  return <List style={{ maxWidth: 300, margin: 'auto' }}>
    {arrCalendar.map(e => {
      return <React.Fragment key={e.id}>
        {e.visible && <ListItem>
          <ListItemText
            primary={
              <div style={{ textAlign: 'center' }}>
                <Chip variant="outlined" color="primary" size="small" label={e.month} />
              </div>
            }
          />
        </ListItem>}
        <ListItem
          button={Boolean(e.idWaybill)}
          onClick={() => { if (e.idWaybill) { handleSelect(e.idWaybill) } }}
        >
          <ListItemAvatar>
            <Avatar>{e.day}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography style={{}}>{e.machine}</Typography>}
            secondary={
              <Typography style={{ color: '#8d8d8d' }}>{e.calendarTime}{!Boolean(e.idWaybill) && <span style={{ color: '#E94545' }}>{' нет путевого листа '}</span>}</Typography>
            }
          />
        </ListItem>
      </React.Fragment>
    })}
    <ListItem>
      <ListItemText
        primary={
          <div style={{ textAlign: 'center' }}>
            <Chip variant="outlined" color="primary" size="small" label='Загрузить еще' icon={<CachedIcon />} onClick={handleLoad} />
          </div>
        }
      />
    </ListItem>
  </List>
}


