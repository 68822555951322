import React, { useState } from 'react'
import { Button, Dialog, Table, TableBody, TableHead, TableRow, TableCell, Checkbox, DialogContent, TableContainer } from '@material-ui/core'
import { fetchDispath } from '../../../../functions/fetch'
import { getOcrDoc, getOcrDocById } from '../../../../api/ocr'
import { useDispatch } from "react-redux";
import moment from 'moment'
import * as actions from '../../../../reducers/form';

const column = [
  { id: 'number', name: 'Номер документа' },
  { id: 'dateCreate', name: 'Дата загрузки', type: 'date', formatDate: 'DD.MM.YYYY' },
  { id: 'date', name: 'Дата документа', type: 'date', formatDate: 'DD.MM.YYYY' },
  { id: 'customer', name: 'Плательщик/ИНН' },
  { id: 'supplier', name: 'Поставщик/ИНН' },
  { id: 'amount', name: 'Сумма документа' },
]
export default function DialogAddEntera({ idProject, formName, setEntera }) {
  const [open, setOpen] = useState(false)
  const [arr, setArr] = useState([])
  const [dialogChecked, setChecked] = useState(0)
  const [load, setLoad] = useState(false)
  const dispatch = useDispatch()
  const handleOpen = () => {
    dispatch(fetchDispath({
      querty: {
      },
      param: {
        id: idProject
      },
      progress: true,
      request: getOcrDoc,
    })).then(res => {
      setArr(res.get.map(e => {
        return { ...e, customer: e.customer ? e.customer : e.customerInn, supplier: e.supplier ? e.supplier : e.supplierInn }
      }))
      setOpen(true)
    }).catch(err => console.log(err))
  }
  const handleSelectFile = () => {
    if (dialogChecked === 0) return;
    setLoad(true)
    dispatch(fetchDispath({
      param: {
        id: idProject,
        idDoc: dialogChecked
      },
      progress: true,
      request: getOcrDocById,
    }))
      .then(res => {
        setEntera(dialogChecked)
        res.get.forEach((obj, i) => {
          let summ = 0
          let summNDS = 0
          let nds = 'none'
          let price = Number(obj.price)
          if (obj.price && obj.vatRate && obj.quantity) {
            const count = Number(obj.quantity)
            nds = (obj.vatRate === 'PERCENT_20') ? 'NDS20' : 'none'
            summ = Math.round(count * price * 100) / 100
            summNDS = Math.round(count * price * 100) / 100 * ((nds === 'NDS20') ? 1.2 : 1)
          }
          dispatch(actions.formObjAdd({
            nameEstimate: obj.name,
            count: obj.quantity,
            balance: null,
            storage: null,
            value: null,
            idEstimate: null,
            storageUnit: obj.unitName,
            nds,
            summ,
            summNDS,
            price,
          }, { field: 'mById', id: 'id' + (10000 + i), name: formName }))
          dispatch(actions.formArrayAdd('id' + (10000 + i), { field: 'mAllIds', name: formName }))
          setLoad(false)
          setOpen(false)
        })
      })
  }
  return <div>
    <Button color="primary" onClick={handleOpen}>Загрузить из распознанных</Button>
    <Dialog
      fullWidth
      maxWidth={'lg'}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <Button color="primary" onClick={() => setOpen(false)} disabled={load}>Закрыть</Button>
        <Button color="primary" onClick={handleSelectFile} disabled={load}>Выбрать</Button>
        <TableContainer style={{ height: '65vh' }}>
          <Table size='small' stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell />
                {column.map(e => {
                  return <TableCell key={e.id}>{e.name}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {arr.map(obj => {
                return <TableRow key={obj.id} onClick={() => setChecked(obj.id)} style={{ cursor: 'pointer'}} selected={dialogChecked === obj.id}>
                  <TableCell>
                    <Checkbox
                      edge="start"
                      checked={dialogChecked === obj.id}
                      tabIndex={-1}
                      disableRipple
                    />
                  </TableCell>
                  {column.map(col => {
                    if (col.type === 'date') {
                      return <TableCell key={col.id}>{moment(obj[col.id]).format('LLL')}</TableCell>
                    }
                    return <TableCell key={col.id}>{obj[col.id]}</TableCell>
                  })}
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  </div>
}