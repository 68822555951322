import template from "./template_crud";


// tender-our
const region = '/tenders_our'
export const getTenderOur = template.getList(region);
export const addTenderOur = template.addOne(region)
export const getTenderOurById = template.getById(region)
export const updTenderOur = template.updById(region)
export const deleteTenderOur = template.deleteById(region)

// estimate 
export const addTenderOurLot = {
    path: (obj) => `${region}/${obj.id}/estimate`,
    type: 'POST'
}