import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import IconMaterials from '@material-ui/icons/ListAlt'
import { ListItem, ListItemIcon, ListItemText, Drawer, List } from '@material-ui/core';
import SelectedProject from '../listLinlDraver/SelectedProject'
import { API_BO } from '../../conf'
import { Link } from 'react-router-dom';
import ListProjectSelect from './ListProjectSelect'
import { butt } from './ListButton'


const drawerWidth = 240;
const useStyleDriver = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justify: 'flex-start',
    padding: '0 8px',
    // marginLeft: 'auto',
    marginRight: 'auto',
    marginLeft: '33px',
    ...theme.mixins.toolbar,
  },
}))

export default function LeftDrawer({ handleDrawerClose, open, variant, history }) {
  const classes = useStyleDriver()
  const [selectedDriver, setSelected] = useState('')
  const facility = useSelector(state => state.header) || {}
  const facilityLink = React.forwardRef((props, ref) => (<Link ref={ref} to={`${API_BO}/facility/our/${facility.facilityId}`} {...props} />))
  const facilityDocLink = React.forwardRef((props, ref) => (<Link ref={ref} to={`${API_BO}/facility/our/${facility.facilityId}/menu`} {...props} />))
  const accountLink = React.forwardRef((props, ref) => (<Link ref={ref} to={`${API_BO}/accounts`} {...props} />))
  const objectDisabled = typeof facility.facilityId === 'undefined' || facility.facilityId === 0
  return <Drawer
    variant={variant}
    className={classNames(classes.drawer, {
      [classes.drawerOpen]: open,
      [classes.drawerClose]: !open,
    })}
    classes={{
      paper: classNames({
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      }),
    }}
    open={open}
  >
    <div className={classes.toolbar}>
      <img src='/logo.svg' alt='logo' width='80%' height={'120%'} />
    </div>
    <hr className='App-divider' />
    <List component="nav">
      {variant === 'temporary' && <React.Fragment>
        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon> <ChevronLeftIcon /> </ListItemIcon>
          <ListItemText primary="Главное" primaryTypographyProps={{ style: { fontWeight: 'bold' } }} />
        </ListItem>
        <ListItem>
          <ListItemText primary={localStorage.getItem('accountName') || ''} primaryTypographyProps={{ style: { fontWeight: 'bold' } }} />
        </ListItem>
        <ListItem button component={accountLink}>
          <ListItemText primary='Аккаунты' />
        </ListItem>
        {butt.map(e => {
          return <ListItem key={e.primary} button component={e.toLink}>
            <ListItemText primary={e.primary} />
          </ListItem>
        })}
      </React.Fragment>}
      {variant === 'temporary' &&
        <ListItem>
          <ListItemText inset primary={"Объект"} primaryTypographyProps={{ style: { fontWeight: 'bold' } }} />
        </ListItem>
      }
      {variant !== 'temporary' &&
        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon> {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}</ListItemIcon>
          <ListItemText primary={"Объект"} primaryTypographyProps={{ style: { fontWeight: 'bold' } }} />
        </ListItem>
      }

      <ListItem button component={facilityLink} disabled={objectDisabled}>
        <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
        <ListItemText primary={'Главное'} />
      </ListItem>
      <ListItem button component={facilityDocLink} disabled={objectDisabled}>
        <ListItemIcon><IconMaterials /></ListItemIcon>
        <ListItemText primary={'Меню'} />
      </ListItem>
      <hr className='App-divider' />
      {open && <ListItem disabled={typeof facility.facilityId === 'undefined' || facility.facilityId === 0}>
        <ListProjectSelect facilityId={facility.facilityId} history={history} />
      </ListItem>}
    </List>
    <hr className='App-divider' />
    <SelectedProject open={open} selectedDriver={selectedDriver} setSeelected={name => setSelected(name)} />
    <hr className='App-divider' />
    <ListItem button onClick={handleDrawerClose}>
      <ListItemIcon> {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}</ListItemIcon>
      <ListItemText primary={'Скрыть панель'} />
    </ListItem>
  </Drawer>
}