import { stopLoadRequest, startLoadRequest } from './loading'
import { actionCreatorObj, edit, load, actionCreatorEmpty } from '../functions/redux'
import { errorDispath, fetchGenerate } from '../functions/fetch'
const MAIN = 'project/'
const LOAD_PROJECT = MAIN+'LOAD'
const LOAD_FACILITY_PROJECT = MAIN+'LOAD_FACILITY_PROJECT'
const EDIT_PROJECT = MAIN+'EDIT'
const SELECT_PROJECT = MAIN+'SELECT'
const CLEAR_PROJECT = MAIN+'CLEAR'
export const clearProject = actionCreatorEmpty(CLEAR_PROJECT)
export const loadProject = actionCreatorObj(LOAD_PROJECT)
export const loadFacilityProject = actionCreatorObj(LOAD_FACILITY_PROJECT)
export const editProject = actionCreatorObj(EDIT_PROJECT)
export const selectProject = actionCreatorObj(SELECT_PROJECT)

export function selectProjectAPI(id) {
  return dispatch => {
    fetchGenerate(`/projects/${id}`, 'GET')
      .then(project => {
        if (project.success) {
          dispatch(selectProject(project.get))
        }
      })
      .catch(errorDispath(dispatch, false))
  }
}

export function loadProjectAPI() {
  return dispatch => {
    dispatch(startLoadRequest())
    fetchGenerate('/projects', 'GET')
      .then(project => {
        if (project.success) {
          dispatch(loadProject(project.get))
        }
        dispatch(stopLoadRequest())
      })
      .catch(errorDispath(dispatch))
  }
}
export const initialState = {
  byId: {},
  allIds: [],
  selected: {
    id: 0,
  },
};
export default function redProject(state = initialState, action) {
  switch (action.type) {
    case LOAD_PROJECT: return load(state, action.payload, 'id')
    case LOAD_FACILITY_PROJECT: return loadFacility(state, action.payload)
    case EDIT_PROJECT: return edit(action.payload)
    case SELECT_PROJECT: return { ...state, selected: action.payload }
    case CLEAR_PROJECT: return { ...state, users: initialState.users, usersNot: initialState.usersNot }
    default: return state;
  }
}

function loadFacility(state, obj) {
  let byId = {}
  let allIds = []
  obj.forEach(row => {
    row.project.forEach(value => {
      let id = 'id' + value.id
      byId[id] = {
        id: value.id,
        name: value.name,
        facility: 'id' + row.id
      };
      allIds.push(id)
    })
  })
  return ({ ...state, allIds: allIds, byId: byId })
}