import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, List, ListItemText, ListItem, } from '@material-ui/core';
import classNames from 'classnames';
import { statusAll } from './type.enum'

const useStyles2 = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
  dialogContent: {
    heigth: '30vh',
    zIndex: 2
  },
  root: {
    minHeigth: '500',
    height: '400'
  },
  selected: {
    background: '#bbbbbb'
  },
  auto: {
  }
}))
export default function DialogChangeStatus({ open, onClose, selectedNow, changeStatus }) {
  const classes = useStyles2()
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">Установить статус</DialogTitle>
      <DialogContent >
        <List component="nav" aria-label="main mailbox folders">
          {statusAll.map(stat => {
            const selected = selectedNow.value===stat.value
            return <ListItem
              key={stat.value}
              button
              onClick={() => !selected ? changeStatus(stat) : null}
              className={classNames({ [classes.selected]: selected, })}
            >
              <ListItemText primary={stat.label} />
            </ListItem>
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}