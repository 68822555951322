import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { getFacilityOur } from '../../api/facility'
import PageList from '../../components/PageListAPI'

export default function FacilityOurList(props) {
  const [template, setTemplate] = useState(0)
  const [reload, setReload] = useState(false)
  return (
    <div className='App-paper'>
      <Button color={template ? 'secondary' : 'primary'} onClick={() => { setTemplate(e => !e); setReload(true) }}>Шаблоны</Button>
      <PageList
        name='facility'
        columns={[{ id: 'name', name: 'Название' }, { id: 'address', name: 'Адрес' }]}
        idName='id'
        loadAPI={{
          querty: {
            template: template?1:0
          },
          request: getFacilityOur
        }}
        pagination={false}
        tableProps={{ pagination: false }}
        reload={reload}
        reloadFunc={() => setReload(false)}
        goBack={false}
        csv={false}
        {...props}
      />
    </div>
  )
}