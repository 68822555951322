import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { selectHistory } from '../../../../reducers/history';
import { addFilterAutocomplite } from '../../../../reducers/autocomplite'
import * as actions from '../../../../reducers/form';

export default function PlanAddHistory({ formMaterialG, formGWork, idProject, formName }) {
  const dispatch = useDispatch()
  const [idRowM, setIdrowM] = useState(-1)
  const [idRowW, setIdrowW] = useState(-1)
  const historyMatWork = useSelector(state => state.history)
  const materialArr = useSelector(state => state.autocomplite[formMaterialG] || state.autocomplite.default)
  const workArr = useSelector(state => state.autocomplite[formGWork] || state.autocomplite.default)
  useEffect(() => {
    if (!historyMatWork.click) return;
    const [type, key] = historyMatWork.click.split('_')
    switch (type) {
      case 'material': {
        const obj = historyMatWork[idProject].byIdM[key]
        dispatch(selectHistory(null))
        const text = materialArr.arr.find(e => obj.idMP.toString() === e.id.toString())
        if (!text) return;
        const newIdRow = idRowM - 1
        const objAuto = {
          count: '',
          value: text,
          balance: text.remainder,
          storageUnit: text.storageUnit,
          id: text.id,
          storage: text.storage,
          groupId: text.groupId,
          groupName: text.groupName,
        }
        dispatch(actions.formObjAdd(objAuto, { field: 'mById', id: 'id' + newIdRow, name: formName }))
        dispatch(actions.formArrayAdd('id' + newIdRow, { field: 'mAllIds', name: formName }))
        dispatch(addFilterAutocomplite(text.value, { field: formMaterialG, id: 'id' + newIdRow }))
        setIdrowM(newIdRow)
        return
      }
      case 'work': {
        const obj = historyMatWork[idProject].byIdW[key]
        dispatch(selectHistory(null))
        const text = workArr.arr.find(e => obj.idMP.toString() === e.id.toString())
        if (!text) return;
        const newIdRow = idRowW - 1
        const objAuto = {
          count: '',
          balance: text.count - text.balance,
          idWork: text.id,
          storageUnit: text.storageUnit,
          value: text,
          groupId: text.groupId,
          groupName: text.groupName,
        }
        dispatch(actions.formObjAdd(objAuto, { field: 'eById', id: 'id' + newIdRow, name: formName }))
        dispatch(actions.formArrayAdd('id' + newIdRow, { field: 'eAllIds', name: formName }))
        dispatch(addFilterAutocomplite(text.value, { field: formGWork, id: 'id' + newIdRow }))
        setIdrowW(newIdRow)
        return
      }
      default:
        return;
    }
  }, [historyMatWork.click])
  return <div></div>
}