import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import PageList from './PageListCustom'
import can from '../../functions/can'
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core'
import { setDeleteMaterialMulti, getMaterialsList, getMaterialsTags } from '../../api/material'
const useStyles = makeStyles(theme => ({
  pagination: {
    fontSize: '0.8rem'
  },
  cell: {
    fontSize: '0.8rem'
  },
  table: {
    tableLayout: 'fixed',
    width: '100%'
  }
}))
export default function Material(props) {
  const classes = useStyles()
  const [all, setAll] = useState(false)
  const [ourEst, setOurEst] = useState(false)
  const tableHead = [
    { id: 'number', name: '№', align: 'left', width: '6%', padding: 'none' },
    !all && {
      id: 'name', name: 'Наименование,Тип,Артикул', align: 'left', padding: 'none', type: 'component',
      component: ({ obj }) => <div>
        {obj.name}  {obj.typeMark}  {obj.vendorCode}
      </div>
    },
    all && { id: 'name', name: 'Наименование', align: 'left', width: '21%', padding: 'none' },
    all && { id: 'typeMark', name: 'Тип, марка', align: 'left', width: '10%', padding: 'none' },
    all && { id: 'vendorCode', name: 'Артикул', align: 'left', width: '10%', padding: 'none' },
    { id: 'storageUnit', name: 'Ед. изм.', align: 'left', padding: 'none' },
    !ourEst&&{ id: 'count', name: 'По смете', align: 'right', padding: 'none' },
    ourEst&&{ id: 'countOur', name: 'Свой', align: 'right', padding: 'none' },
    ourEst&&{ id: 'countEst', name: 'Давальческий', align: 'right', padding: 'none' },
    { id: 'estimate', name: 'Кол-во по счету', align: 'right', padding: 'none' },
    { id: 'ordered', name: 'Заказано', align: 'right', padding: 'none' },
    { id: 'balance', name: 'Требуется заказать', align: 'right', padding: 'none' },
    { id: 'will', name: 'Поставлено', align: 'right', padding: 'none' },
    { id: 'costs', name: 'Выработано', align: 'right', padding: 'none' },
    { id: 'storage', name: 'Остаток складе', align: 'right', padding: 'none' },
  ].filter(key => typeof key !== 'boolean');
  return (
    <div className='App-paper'>
      <PageList
        name='material'
        classes={classes}
        columns={tableHead}
        createItem={can('material:update')}
        deleteItem={can('material:delete')}
        loadAPI={{
          request: getMaterialsList,
          param: {
            id: props.match.params.id
          },
          transform: (res => {
            return {
              count: res.count,
              get: res.get.map(obj => {
                let will = Math.round((obj.storage + obj.costs) * 1000) / 1000
                let balance = Math.round((obj.count - obj.storage - obj.costs) * 1000) / 1000
                const our = obj.customerCount.find(e=>!e.customer)
                const est = obj.customerCount.find(e=>e.customer)
                return { ...obj, will, balance, countOur:our.count, countEst:est.count  }
              })
            }
          }),
        }}
        deleteAPI={setDeleteMaterialMulti(props.match.params.id)}
        headerLink={<HeaderLink url={props.match.url} history={props.history} />}
        filterProps={[
          {
            type: 'find_text',
            field: 'find',
            name: 'Поиск по наименованию'
          },
          {
            type: 'find_text',
            field: 'number',
            name: 'Поиск по номеру'
          },
          {
            type: 'autocomplite_multi',
            path: getMaterialsTags.path({ id: props.match.params.id }),
            field: 'tags',
            name: 'Тэги',
          },

        ]}
        deleteMulti
        deleteMultiProps='arr'
        additionalActions={[
          { name: 'группировать наименование', action: () => setAll(!all) },
          { name: 'разделять давальческие', action: () => setOurEst(old=>!old) },
          
        ]}
        {...props}
      />
    </div>
  )
}

function HeaderLink({ url, history }) {
  const [open, changeOpen] = useState(false)
  const [anchorRef, changeRef] = useState(null)
  const handleToggle = (e) => {
    changeOpen(!open)
    changeRef(!anchorRef ? e.currentTarget : null)
  }
  const handleClose = (e) => {
    changeOpen(false)
    changeRef(null)
  }
  const toLinkBasket = React.forwardRef((props1, ref) => (<Link ref={ref} to={url + '/basket'} {...props1} />));
  const toLinkPrice = React.forwardRef((props1, ref) => (<Link ref={ref} to={url + '/price'} {...props1} />));
  return <React.Fragment>
    {can('material:update') && <React.Fragment>
      <Button onClick={handleToggle} color="primary" >Загрузить </Button>
      <Menu
        // id="fade-menu"
        anchorEl={anchorRef}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      // TransitionComponent={Fade}
      >
        <MenuItem onClick={() => { history.push('/multi/material/load'); handleClose(); }} >Загрузить из файла</MenuItem>
        <MenuItem onClick={() => { history.push(url + '/grand'); handleClose(); }} >Загрузить из гранд </MenuItem>
        <MenuItem onClick={() => { history.push(url + '/doc'); handleClose(); }} >Загрузить из РД</MenuItem>
      </Menu>
      <Button key='basket' component={toLinkBasket} color="primary" >Корзина</Button>
      <Button key='set-price' component={toLinkPrice} color="primary" >Установить цены</Button>
    </React.Fragment>}
  </React.Fragment>
}