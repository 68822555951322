import React from 'react';
import PageList from '../../components/PageListAPI'
import can from '../../functions/can'
import { getRemovalStorageEquipment, deleteRemovalStorageEquipment } from '../../api/storage'

export default function StorageEquipmentRemoval(props) {
  const tableHead = [
    { id: 'id', name: '№' },
    { id: 'user', name: 'Создатель' },
    { id: 'dateRemoval', name: 'Дата списания', type: 'date', formatDate: 'DD.MM.YYYY' },
  ];
  return (
    <div className='App-paper'>
      <PageList
        name='removalStorageMaterial'
        columns={tableHead}
        createItem={can('storage:update')}
        deleteItem={can('storage:delete')}
        loadAPI={{
          query: {
            id: props.match.params.id
          },
          request: getRemovalStorageEquipment,
        }}
        deleteAPI={{
          path: deleteRemovalStorageEquipment.path,
          type: deleteRemovalStorageEquipment.type
        }}
        {...props}
      />
    </div>
  )
}

