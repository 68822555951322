import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { formObjAdd, formArrayAdd, formObjDelete, formArrayDelete } from '../../../../reducers/form';
import { Table, TableHead, TableBody, TableRow, TableCell, IconButton, CircularProgress } from '@material-ui/core'
import { loadFileAPI } from '../../../../reducers/materialLoad';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete'
import { delFile } from '../../../../api/list'
import { fetchDispath } from '../../../../functions/fetch'

export default function FilesTab({ formName }) {
  const [idRow, setRow] = useState(0)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoading(true)
    let fileName = e.target.files[0].name
    dispatch(loadFileAPI({ file: e.target.files[0], path: `/files/add` }))
      .then(res => {
        if (res.success) {
          dispatch(formObjAdd({ id: res.id, name: fileName }, {
            field: 'fById',
            id: 'id' + (idRow + 1),
            name: formName
          }))
          dispatch(formArrayAdd('id' + (idRow + 1), {
            field: 'fAllIds', name: formName
          }))
          setRow(idRow + 1)
        }
        setLoading(false)
      })
      .catch()
  }
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const { fAllIds = [], fById = {} } = values
  return <div>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 10 }} padding='none'>
            <IconButton disabled={loading} component="label">
              {loading?<CircularProgress size={25}/>:<AddIcon />}
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => _handleImageChange(e)}
              />
            </IconButton>
          </TableCell>
          <TableCell>Название</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fAllIds
          .map(row => {
            const obj = fById[row];
            return <Row key={row} row={row} obj={obj} formName={formName}/>
          })}
        <TableRow style={{ height: 40 * 5 }}>
          <TableCell colSpan={8} />
        </TableRow>
      </TableBody>
    </Table>
  </div>
}


function Row({row, obj, formName}) {
  const dispatch = useDispatch()
  const [deleted,setDeleted] = useState(false)
  const handleDelete = () => {
    setDeleted(true)
    dispatch(fetchDispath({
      param: {
        id: obj.id,
      },
      progress: false,
      request: delFile,
      buff: true
    }))
      .then(res => {
        setDeleted(false)
        if (!res) return;
        dispatch(formArrayDelete(row, { field: 'fAllIds', name: formName }));
        dispatch(formObjDelete({ id: row, field: 'fById', name: formName }));
      })
  }
  return <TableRow key={row}>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <IconButton onClick={() => handleDelete()} disabled={deleted} ><DeleteIcon /></IconButton>
    </TableCell>
    <TableCell>{obj.name}</TableCell>
  </TableRow>
}