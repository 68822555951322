import React from 'react';
import { useSelector } from 'react-redux';
import { Bar } from "react-chartjs-2";

export default function BarAnProject({ formName, width, height }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { line, planArr, factArr } = form.values
  const data = {
    labels: line,
    datasets: [
      {
        label: 'Затраты по плану',
        backgroundColor: 'rgba(25, 185, 230,0.2)',
        borderColor: 'rgba(25, 185, 230,1)',
        borderWidth: 1,
        data: planArr
      },
      {
        label: 'Фактические затраты',
        backgroundColor: 'rgba(230, 25, 73,0.2)',
        borderColor: 'rgba(230, 25, 73,1)',
        borderWidth: 1,
        data: factArr
      }
    ]
  };
  return (
    <Bar
      data={data}
      width={width}
      height={height}
      options={{
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }}
    />)
}
