import React from 'react'
import { useSelector } from 'react-redux'
import { Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@material-ui/core'
export default function PlanFactWork({ field }) {
  const tableField = useSelector(state => state.formTable[field] || state.formTable.default)
  const { allIds = [], byId = {} } = tableField
  return <div>
    <TableContainer style={{ height: '70vh' }}>
      <Table size='small' stickyHeader aria-label="sticky table" >
        <TableHead>
          <TableRow>
            <TableCell>План</TableCell>
            <TableCell>Факт</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allIds.map(key => {
            const obj = byId[key]
            const arrPlan = obj.plan.split('\n').filter(v => v !== '')
            const arrFact = obj.fact.filter(v => v !== '')
            const diff = Math.min(arrPlan.length, arrFact.length) // Math.abs(arrPlan.length - arrFact.length)
            const arrDif = arrPlan.length > arrFact.length ? arrPlan.slice(diff + 1) : arrFact.slice(diff + 1)
            return <React.Fragment key={key}>
              <TableRow>
                <TableCell colSpan={2}><b>{obj.shortDescription}{!obj.idFact ? ' (факт не заполнен)' : ''}</b></TableCell>
              </TableRow>
              {[...Array(diff).keys()].map(i => {
                return <TableRow key={i}>
                  <TableCell>{arrPlan[i]}</TableCell>
                  <TableCell>{arrFact[i]}</TableCell>
                </TableRow>
              })}
              {arrPlan.length !== arrFact.length &&
                <React.Fragment>
                  <TableRow>
                    {arrPlan.length > arrFact.length && <TableCell>{arrPlan[diff]}</TableCell>}
                    <TableCell rowSpan={Math.abs(arrPlan.length - arrFact.length)}></TableCell>
                    {arrFact.length > arrPlan.length && <TableCell>{arrFact[diff]}</TableCell>}
                  </TableRow>
                  {arrDif.map((v, i) => {
                    return <TableRow key={i}>
                      <TableCell>{v}</TableCell>
                    </TableRow>
                  })}

                </React.Fragment>
              }
            </React.Fragment>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
}