import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { formInitialize, formDestroy, formChangeAsObj, formSubmitAPI } from '../../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite, load as loadAuto } from '../../../reducers/autocomplite'
import { addFilterAutocomplite } from '../../../reducers/autocomplite'
import { fetchDispath } from '../../../functions/fetch'
import { getWorkTemplate } from '../../../api/list'
import { getTeamList } from '../../../api/team'
import { getOutfitPlanById, editOutfitPlanById, getOutfitPlanWork } from '../../../api/doc'
import { getMaterials } from '../../../api/material'
import { getWorkerList } from '../../../api/worker'
import moment from 'moment'
import DocTable from '../../../components/DocTable'
import FileTabEdit from './source/FileTabEdit'
import WorkTab from './source/WorkTab'
import DialogAddTeam from './source/DialogAddTeam'
import DialogAddMaterial from './source/DialogAddMaterial';
import DocAdd from '../../../components/DocAdd'

const formName = 'outfitPlan'
const arrMaterial = 'material'
const arrWorker = 'worker'
const formWork = 'work'
const formTeam = 'teamlist'
const formWorker = 'worker'
const formMaterialG = 'materialG'
const formGWork = 'Gwork'

export default function OutfitPlanEditById(props) {
  const [stopLoad, setStopLoad] = useState(false)
  const dispatch = useDispatch()
  const idProject = props.match.params.idProject
  const idOutfit = props.match.params.id
  const autoMaterial = useSelector(state => state.autocomplite[formMaterialG] || state.autocomplite.default)
  const autoWorker = useSelector(state => state.autocomplite[arrWorker] || state.autocomplite.default)
  const autoWorkE = useSelector(state => state.autocomplite[formGWork] || state.autocomplite.default)
  const isLoadMaterial = autoMaterial.load
  const isLoadWorker = autoWorker.load
  const isLoadWorkE = autoWorkE.load
  const listMaterial = autoMaterial.arr
  const listWorker = autoWorker.arr
  const listWorkE = autoWorkE.arr
  useEffect(() => {
    dispatch(formInitialize({
      date: moment(Date.now()).format('YYYY-MM-DD'),
      shortDescription: '',
      work: '',
      mById: {}, // Table material
      mAllIds: [], // Table material
      wById: {}, // Table worker
      wAllIds: [], // Table worker
      wkById: {}, // Table work
      wkAllIds: [], // Table work
      fById: {}, // Table files
      fAllIds: [], // Table files
      eById: {}, // Estimate work
      eAllIds: [], // Estimate work
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: arrWorker }))
    dispatch(initialAutocomplite({ field: formWork }))
    dispatch(initialAutocomplite({ field: arrMaterial }))
    dispatch(initialAutocomplite({ field: formMaterialG }))
    dispatch(initialAutocomplite({ field: formGWork }))
    dispatch(initialAutocomplite({ field: formTeam }))
    return () => {
      dispatch(formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: arrWorker }))
      dispatch(clearAutocomplite({ field: formWork }))
      dispatch(clearAutocomplite({ field: arrMaterial }))
      dispatch(clearAutocomplite({ field: formGWork }))
      dispatch(clearAutocomplite({ field: formMaterialG }))
      dispatch(clearAutocomplite({ field: formTeam }))
    }
  }, [dispatch])
  useEffect(() => {
    const pathMaterials = getMaterials.path({ id: idProject }) + '?balance=our&type=balance'
    const metaMaterials = {
      field: arrMaterial,
      value: 'id',
      hidden: ['storage', 'storageUnit', 'idStorageUnit', 'count', 'costs'],
      labelFunc: (obj) => obj.name + ' ' + obj.typeMark + ' ' + obj.vendorCode
    }
    dispatch(loadAutocompliteAPI(pathMaterials, metaMaterials))
    dispatch(loadAutocompliteAPI(getWorkerList.path, {
      field: arrWorker,
      value: 'id',
      hidden: ['position'],
      labelFunc: (obj) => obj.surname + ' ' + obj.name + ' ' + obj.patronymic
    }))
    dispatch(loadAutocompliteAPI(getWorkTemplate.path, {
      field: formWork,
      value: 'id',
      hidden: [],
      labelFunc: (obj) => obj.name
    }))
    dispatch(loadAutocompliteAPI(getTeamList.path({ id: idProject }), {
      field: formTeam,
      value: 'id',
      hidden: [],
      labelFunc: (obj) => obj.name + ' ' + obj.master
    }))
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getOutfitPlanWork,
    })).then(res => {
      let groupWork = {}
      let groupMaterial = {}
      res.get.work.forEach(e => {
        if (groupWork[e.groupId]) {
          groupWork[e.groupId].options.push({
            label: e.name,
            value: e.id + '_' + e.groupId,
            id: e.id,
            storageUnit: e.storageUnit,
            balance: e.groupBalance,
            count: e.groupCount,
            groupId: e.groupId,
            groupName: e.groupName,
          })
        }
        else {
          groupWork[e.groupId] = {
            label: e.groupName,
            options: [{
              label: e.name,
              value: e.id + '_' + e.groupId,
              id: e.id,
              storageUnit: e.storageUnit,
              balance: e.groupBalance,
              count: e.groupCount,
              groupId: e.groupId,
              groupName: e.groupName,
            }]
          }
        }
      })
      res.get.material.forEach(e => {
        if (groupMaterial[e.groupId]) {
          groupMaterial[e.groupId].options.push({
            label: e.name,
            value: e.id + '_' + e.groupId,
            id: e.id,
            storageUnit: e.storageUnit,
            balance: e.groupBalance,// выработано
            count: e.groupCount,// количество в групе
            remainder: e.groupCount - e.groupBalance, // остаток
            groupId: e.groupId,
            groupName: e.groupName,
            storage: e.storage,

          })
        }
        else {
          groupMaterial[e.groupId] = {
            label: e.groupName,
            options: [{
              label: e.name,
              value: e.id + '_' + e.groupId,
              id: e.id,
              storageUnit: e.storageUnit,
              balance: e.groupBalance,
              count: e.groupCount,
              remainder: e.groupCount - e.groupBalance, // остаток
              groupId: e.groupId,
              groupName: e.groupName,
              storage: e.storage,
            }]
          }
        }
      })
      dispatch(loadAuto(Object.values(groupWork), { field: formGWork, }))
      dispatch(loadAuto(Object.values(groupMaterial), { field: formMaterialG, }))
    }).catch(err => console.log(err))
  }, [dispatch, idProject])
  useEffect(() => {
    if (!(isLoadMaterial && isLoadWorker && !stopLoad && isLoadWorkE)) return;
    console.log('start load')
    dispatch(fetchDispath({
      param: {
        idProject: idProject,
        id: idOutfit
      },
      progress: true,
      request: getOutfitPlanById,

    }))
      .then((res) => {
        setStopLoad(true)
        let mById = {}
        let mAllIds = []
        let wById = {}
        let wAllIds = []
        let wkById = {}
        let wkAllIds = []
        let fById = {}
        let fAllIds = []
        let eById = {}
        let eAllIds = []
        res.get.material.forEach((obj, i) => {
          const id = 'id' + obj.id + '_' + i
          console.log(listMaterial)
          const group = listMaterial.find(e => e.label === obj.groupName)
          if (!group) return;
          const value = group.options.find(e => e.id === obj.id)
          // if (value) { dispatch(addFilterAutocomplite(value.value, { field: arrMaterial, id: id })) }
          mById[id] = { ...obj, value, storage: value.storage }
          mAllIds.push(id)
        })
        res.get.worker.forEach((obj, i) => {
          const id = 'id' + obj.id + '_' + i
          const value = listWorker.find(e => e.value === obj.id.toString())
          if (value) { dispatch(addFilterAutocomplite(value.value, { field: arrWorker, id: id })) }
          wById[id] = { ...obj, value, idWorker: obj.id }
          wAllIds.push(id)
        })
        res.get.workE.forEach((obj, i) => {
          const id = 'id' + obj.id + '_' + i
          const group = listWorkE.find(e => e.label === obj.groupName)
          if (!group) return;
          const value = group.options.find(e => e.id === obj.id)
          eById[id] = { ...obj, value, idWork: obj.id }
          eAllIds.push(id)
        })
        res.get.files.forEach((obj, i) => {
          const id = 'id' + obj.id + '_' + i
          fById[id] = { ...obj, deleted: false }
          fAllIds.push(id)
        })
        res.get.work.filter(e => e !== '').forEach((v, i) => {
          const id = 'id_' + i
          wkById[id] = { name: v, id: i, deleted: false }
          wkAllIds.push(id)
        })
        dispatch(formChangeAsObj({
          mById: mById, // Table material
          mAllIds: mAllIds, // Table material
          wById: wById, // Table worker
          wAllIds: wAllIds, // Table worker
          wkById: wkById, // Table work
          wkAllIds: wkAllIds, // Table work
          fById,
          fAllIds,
          eById,
          eAllIds,
          date: res.get.byId.date,
          shortDescription: res.get.byId.shortDescription
        }, { name: formName }))
      })
  }, [dispatch, idProject, idOutfit, isLoadMaterial, isLoadWorker, listMaterial, listWorker, stopLoad, listWorkE, isLoadWorkE])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSave = () => {
    const { mById, mAllIds,
      wById, wAllIds,
      wkById, wkAllIds, fById,
      fAllIds, shortDescription,
      eAllIds, eById } = values
    const arrWorker = wAllIds.map(e => wById[e].idWorker)
    const arrMaterial = mAllIds.map(e => {
      const obj = mById[e]
      return {
        id: obj.id,
        groupId: obj.groupId,
        count: obj.count
      }
    })
    const arrWork = []
    wkAllIds.forEach(key => {
      if (wkById[key].name !== '') arrWork.push(wkById[key].name)
    })
    const arrWorkE = []
    eAllIds.forEach(key => {
      const obj = eById[key]
      if (obj.count > 0) {
        arrWorkE.push({
          id: obj.idWork,
          count: obj.count,
          groupId: obj.groupId
        })
      }
    })
    const arrFiles = fAllIds.filter(key => !fById[key].deleted).map(key => fById[key].id)
    dispatch(formSubmitAPI({
      props: {
        work: arrWork,
        shortDescription: shortDescription,
        worker: arrWorker,
        workE: arrWorkE,
        material: arrMaterial,
        files: arrFiles
      },
      url: editOutfitPlanById.path({ idProject: idProject, id: idOutfit }),
      method: editOutfitPlanById.type,
      history: props.history
    }))
  }
  return <div className='App-paper'>
    <DocAdd
      formName={formName}
      history={props.history}
      title='Изменить наряз заказ'
      tab={['Сметные работы', 'Материалы', 'Рабочие', 'Работы', 'Файлы']}
      noTableProps={[
        { field: 'date', label: 'Дата наряда', type: 'text', textProps: { type: 'date', disabled: true } },
        { field: 'shortDescription', label: 'Краткое описание', type: 'text', textProps: { type: 'text' }, xs: 6 },
      ]}
      handleSave={handleSave}
    ><DocTable
        formName={formName}
        byIdField='eById'
        allIdsField='eAllIds'
        column={[
          { id: 'groupName', type: 'string', name: 'Раздел' },
          {
            id: 'value', type: 'auto', name: 'Работа', formArr: formGWork, width: '50%', filtered: false,
            changeValue: text => {
              return ({
                balance: text.count - text.balance,
                idWork: text.id,
                storageUnit: text.storageUnit,
                value: text,
                groupId: text.groupId,
                groupName: text.groupName,
              })
            }
          },
          {
            id: 'count', type: 'number', name: 'Количество',
            title: obj => obj.balance,
            textProps: obj => ({ error: obj.count > obj.balance, helperText: obj.count > obj.balance ? 'Больше чем в смете' : '' })
          },
          { id: 'storageUnit', type: 'string', name: 'Ед.изм' },
        ]}
        rowDefault={{
          balance: 0,
          count: '',
          storageUnit: '',
          idWork: '',
          value: null
        }}
      />
      <React.Fragment>
        <DialogAddMaterial
          rowDefault={{
            id: '',
            count: '',
            storageUnit: '',
            storage: '',
            value: ''
          }}
          formArr={formMaterialG}
          byIdField='mById'
          allIdsField='mAllIds'
          formName={formName}
        />
        <DocTable
          formName={formName}
          byIdField='mById'
          allIdsField='mAllIds'
          column={[
            { id: 'groupName', type: 'string', name: 'Раздел' },
            {
              id: 'value', type: 'auto', name: 'Материал', formArr: formMaterialG, width: '45%', filtered: false,
              changeValue: text => {
                return ({
                  value: text,
                  balance: text.remainder,
                  storageUnit:
                    text.storageUnit,
                  id: text.id,
                  storage: text.storage,
                  groupId: text.groupId,
                  groupName: text.groupName,
                })
              }
            },
            {
              id: 'count', type: 'number', name: 'Количество',
              title: obj => obj.balance,
              textProps: obj => {
                return ({ error: obj.count > obj.balance, helperText: obj.count > obj.balance ? 'Больше чем в смете' : '' })
              }
            },
            // { id: 'count', type: 'number', name: 'Количество', width: '20%', },
            { id: 'storage', type: 'string', name: 'На складе' },
            { id: 'storageUnit', type: 'string', name: 'Ед.изм' }
          ]}
          rowDefault={{
            id: '',
            count: '',
            storageUnit: '',
            storage: '',
            value: ''
          }}
        />
      </React.Fragment>
      <React.Fragment>
        <DialogAddTeam
          formTeam={formTeam}
          formName={formName}
          formWorker={formWorker}
          idProject={idProject}
        />
        <DocTable
          formName={formName}
          byIdField='wById'
          allIdsField='wAllIds'
          column={[
            {
              id: 'value', type: 'auto', name: 'Работник', formArr: formWorker, width: '50%', filtered: true,
              changeValue: text => ({ position: text.position, idWorker: text.value, value: text, })
            },
            { id: 'position', type: 'string', name: 'Должность' },
          ]}
          rowDefault={{
            position: '',
            idWorker: '',
            value: null
          }}
        />
      </React.Fragment>
      <WorkTab formName={formName} />
      <FileTabEdit formName={formName} />
    </DocAdd>
  </div>
}

