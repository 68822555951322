import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import * as actions from '../../../reducers/form';
import SimpleAdd from '../../../components/SimpleAdd'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import { updAccountsTByIdAPI } from '../../../api/tariff.api'

const formName = 'acc-tariff-edit'
const arrProps = [
  {
    xs: 12, bp: { md: 6 },
    field: 'creditDays', label: 'Дни', type: 'text', textProps: { type: 'number' },
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
]

export default function AccountTariffEdit({ history, match }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      creditDays: 0,
      errorValidate: { creditDays: false, }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const values = useSelector(state => state.form[formName]?.values)
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        creditDays: values.creditDays,
        isActive: true,
      },
      url: updAccountsTByIdAPI.path({ idDoc: match.params.idDoc, idTariff: match.params.idTariff }),
      method: updAccountsTByIdAPI.type,
      history: history
    }, formName))
  };
  return <div className='App-paper'>
    <form autoComplete="off" onSubmit={handleSave}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd
          formName={formName}
          arr={arrProps}
          title='Добавить кредит'
          gridProps={{ xs: 12, direction: 'column', alignItems: "stretch" }}
        />
      </div>
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>
}