import React, { useState, useEffect } from 'react'
import { Typography, Button, Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { fetchDispath } from '../../functions/fetch'
import { getWorkerRatingById } from '../../api/worker'
import GridProps from '../../components/GridProps'
import moment from 'moment'
import 'moment/locale/ru';
import Rating from '../../components/Rating'
export default function RatingById ({ history, match }) {
  const [state, setState] = useState({
    star: '',
    date: '',
    worker: '',
    user: '',
    comment: '',
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.id,
      },
      progress: true,
      request: getWorkerRatingById,
    })).then(res => {
      if (res.success) {
        setState({
          star: res.get.star,
          date: res.get.date,
          worker: res.get.name + ' ' + res.get.surname + ' ' + res.get.patronymic,
          user: res.get.userName + ' ' + res.get.userSurname,
          comment: res.get.comment || ''
        })
      }
    }).catch(err => console.log(err))
  }, [dispatch,match.params.id])
  return <div className='App-paper'>
    <Grid
      item
      container
      xs={6}
    >
      <GridProps name='Работник' value={state.worker} xsStart={3} xsEnd={9} />
      <GridProps name='Добавил' value={state.user} xsStart={3} xsEnd={9} />
      <GridProps name='Дата' value={moment(state.date).format('LLL')} xsStart={3} xsEnd={9} />
    </Grid>
    <Rating value={Number(state.star)}/>
    <Typography>Комментарий</Typography>
    <Typography>{state.comment}</Typography>
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}