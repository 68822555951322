import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Popper, TextField, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { formObjChangeObj } from '../../../reducers/form';
import AutoComplite from '../../../components/Autocomplite'
import { autoVat } from '../ocrConst'
import { fetchImg } from '../../../functions/fetch'

export default function OrderByIdTable({ formName, imgPath }) {
  // const [size, setSize] = useState(0.5)
  const size = 0.5
  const [anchorElTable, setAnchorElTable] = useState(null);
  const [posCell, setPosCell] = useState({ leftX: 486, topY: 541, rightX: 3405, pageNumber: 1, bottomY: 589 })
  const [posRow, setPosRow] = useState({ leftX: 486, topY: 541, rightX: 3405, pageNumber: 1, bottomY: 589 })
  const [posHeader, setPosHeader] = useState({ leftX: 486, topY: 541, rightX: 3405, pageNumber: 1, bottomY: 589 })
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const { allIds = [], byId = {}, amount, amountRowHeaderPosition, amountRowPosition, amountPosition } = values
  const onBlure = () => setAnchorElTable(null)
  return <div>
    <Table>
      <TableHead>
        <TableRow>
          {[{ width: 2, label: '№', },
          { width: 6, label: 'Код', },
          { width: 14, label: 'Наименование товара', },
          { width: 7, label: 'Ед. изм.', },
          { width: 4, label: 'Кол-во', },
          { width: 5, label: 'Цена', },
          { width: 7, label: 'Сумма', },
          { width: 4, label: 'НДС', },
          { width: 6, label: 'Налог', },
          { width: 7, label: 'Итого' }].map((e, i) => <TableCell key={i} style={{ width: e.width * 0.62 + '%' }}>{e.label}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {allIds.map((key, i) => {
          const obj = byId[key]
          return <TableRow
            key={obj.id}
            onClick={(e) => {
              setPosHeader(obj.headerPosition);
              setPosRow(obj.position);
              setAnchorElTable(e.currentTarget);
            }}
          >
            <TableCell>{i + 1}</TableCell>
            <SimpleCell id={key} obj={obj} setPosCell={setPosCell} formName={formName} onBlure={onBlure} field={'recognizedGoodCode'} fieldPos={'goodCodePosition'} />
            <SimpleCell id={key} obj={obj} setPosCell={setPosCell} formName={formName} onBlure={onBlure} field={'recognizedName'} fieldPos={'namePosition'} />
            <SimpleCell id={key} obj={obj} setPosCell={setPosCell} formName={formName} onBlure={onBlure} field={'recognizedUnit'} fieldPos={'unitNamePosition'} />
            <SimpleCell id={key} obj={obj} setPosCell={setPosCell} formName={formName} onBlure={onBlure} field={'recognizedQuantity'} fieldPos={'quantityPosition'} />
            <SimpleCell id={key} obj={obj} setPosCell={setPosCell} formName={formName} onBlure={onBlure} field={'recognizedPrice'} fieldPos={'pricePosition'} />
            <SimpleCell id={key} obj={obj} setPosCell={setPosCell} formName={formName} onBlure={onBlure} field={'recognizedAmountWithoutVat'} fieldPos={'amountWithoutVatPosition'} />
            <AutoCell id={key} obj={obj} setPosCell={setPosCell} formName={formName} onBlure={onBlure} field={'valueVatRate'} fieldPos={'vatRatePosition'} fieldName={'recognizedVatRate'} />
            <SimpleCell id={key} obj={obj} setPosCell={setPosCell} formName={formName} onBlure={onBlure} field={'recognizedVat'} fieldPos={'vatPosition'} />
            <SimpleCell id={key} obj={obj} setPosCell={setPosCell} formName={formName} onBlure={onBlure} field={'recognizedAmount'} fieldPos={'amountPosition'} />
          </TableRow>
        })}
        {amountRowHeaderPosition && <TableRow
          onClick={(e) => {
            setPosHeader(amountRowHeaderPosition);
            setPosRow(amountRowPosition);
            setAnchorElTable(e.currentTarget);
          }}
        >
          <TableCell colSpan={9}><b>Всего:</b></TableCell>
          <TableCell onMouseOver={() => setPosCell(amountPosition)}>
            <div onBlur={() => onBlure()} ><b>{amount}</b></div>
          </TableCell>
        </TableRow>}
      </TableBody>
    </Table>
    <CustomPopoverTable
      anchorEl={anchorElTable}
      handleClose={() => setAnchorElTable(null)}
      size={size}
      posCell={posCell}
      posRow={posRow}
      posHeader={posHeader}
      imgPath={imgPath}
    />
  </div>
}

function SimpleCell({ id, obj, setPosCell, field, fieldPos, onBlure, formName }) {
  const dispatch = useDispatch()
  const handleChange = (value) => {
    dispatch(formObjChangeObj({ [field]: value }, { name: formName, field: 'byId', id: id }))
  }
  // console.log(obj)
  return <TableCell onMouseOver={() => setPosCell(obj[fieldPos])}>
    <TextField value={obj[field]} onChange={(e) => handleChange(e.target.value)} onBlur={() => onBlure()} fullWidth multiline />
  </TableCell>
}
function AutoCell({ id, obj, setPosCell, field, fieldName, fieldPos, onBlure, formName }) {
  const dispatch = useDispatch()
  const handleChange = (value) => {
    dispatch(formObjChangeObj({ [field]: value, fieldName: value.label }, { name: formName, field: 'byId', id: id }))
  }
  return <TableCell onMouseOver={() => setPosCell(obj[fieldPos])}>
    <AutoComplite
      arr={autoVat}
      action={handleChange}
      value={obj[field]}
      palaseHolder=''
      onBlur={() => onBlure()}
    />
  </TableCell>
}

function CustomPopoverTable({ anchorEl, size, posCell, posRow, posHeader, imgPath }) {
  const { pageNumber: pageNumberHeader } = posHeader || { pageNumber: 1 }
  const { pageNumber: pageNumberRow } = posRow || { pageNumber: 1 }
  const dispatch = useDispatch()
  const [urlHeader, setUrlHeader] = useState('')
  const [urlRow, setUrlRow] = useState('')
  useEffect(() => {
    console.log('load img')
    console.log(pageNumberHeader !== pageNumberRow, pageNumberHeader, pageNumberRow)
    dispatch(fetchImg(imgPath + '/' + pageNumberHeader))
      .then(response => response.blob())
      .then(blob => {
        let url = URL.createObjectURL(blob);
        setUrlHeader(`url(${url})`)
        if (pageNumberHeader === pageNumberRow) setUrlRow(`url(${url})`)
      })
    if (pageNumberHeader !== pageNumberRow) {
      dispatch(fetchImg(imgPath + '/' + pageNumberRow))
        .then(response => response.blob())
        .then(blob => {
          let url = URL.createObjectURL(blob);
          setUrlRow(`url(${url})`)
        })
    }
  }, [dispatch, imgPath, pageNumberHeader, pageNumberRow])
  if (!posCell || !posRow || !posHeader) return null
  return <Popper
    open={!!anchorEl}
    anchorEl={anchorEl}
    placement="top"
    style={{ zIndex: 2000, }}
    onFocus={() => console.log('popper')}
  >
    <div style={{
      width: '100%',
      backgroundColor: '#fff',
      padding: '5px',
      boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
      borderRadius: '2px',
    }}>
      <div style={{
        backgroundImage: urlHeader,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `-${posHeader.leftX * size}px -${posHeader.topY * size}px`,
        backgroundSize: 3534 * size,
        height: (posHeader.bottomY - posHeader.topY) * size,
        width: (posHeader.rightX - posHeader.leftX) * size
      }} />
      <div style={{
        backgroundImage: urlRow,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `-${posRow.leftX * size}px -${posRow.topY * size}px`,
        backgroundSize: 3534 * size,
        height: (posRow.bottomY - posRow.topY) * size,
        width: (posRow.rightX - posRow.leftX) * size,
        position: 'relative'
      }} >
        <div style={{
          position: 'absolute',
          top: '0px',
          left: (posCell.leftX - posRow.leftX) * size,
          width: (posCell.rightX - posCell.leftX) * size,
          height: (posCell.bottomY - posCell.topY) * size,
          opacity: 0.5,
          backgroundColor: '#f11a1a'

        }} ></div>
      </div>
    </div>
  </Popper>
}


