import React, { useEffect, useCallback, useState } from 'react';
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/formTable';
import { fetchDispath } from '../../functions/fetch'
import { getRemovalStorageEquipmentById, getRemovalStorageEquipmentByIdPrint } from '../../api/storage'
import moment from 'moment'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import print from '../../functions/print'

const formName = 'removalStorageMaterialById'
export default function StorageEquipmentRemovalById ({ match, history }) {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    name: '',
    dateRemoval: '',
    user: '',
    id: ''
  })
  useEffect(() => {
    dispatch(actions.initFormTable({ name: formName }))
    return () => {
      dispatch(actions.clearFormTable(formName))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idRemoval
      },
      progress: true,
      request: getRemovalStorageEquipmentById,
    })).then(res => {
      setState({ ...res.header })
      dispatch(actions.loadFormTable({ get: res.get, count: res.get.length }, { name: formName, id: 'id' }))
    }).catch(err => console.log(err))
  }, [dispatch, match.params.idRemoval])
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          id: match.params.idRemoval
        },
        request: getRemovalStorageEquipmentByIdPrint,
      }
    )
  }
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  return <div className='App-paper'>
    <Button color='primary' onClick={() => handlePrint()}>Печать </Button>
    <Typography variant="body1" gutterBottom>
      Пользователь: {state.user}
    </Typography>
    <Typography variant="body1" gutterBottom>
      Дата списания: {moment(state.dateRemoval).format('DD.MM.YYYY')}
    </Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Наименование</TableCell>
          <TableCell>Количество</TableCell>
          <TableCell>Ед. изм.</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {formTable.allIds.map(row => {
          const obj = formTable.byId[row];
          return (
            <TableRow id='row' key={row}>
              <TableCell component="th" scope="row">{obj.name}</TableCell>
              <TableCell >{obj.count}</TableCell>
              <TableCell >{obj.storageUnit}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}