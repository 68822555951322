import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { getInputControl, getInputControlPrint } from '../../api/acceptance'
import PageList from '../../components/PageListAPI'
import print from '../../functions/print'
import { useDispatch } from 'react-redux'
import { fetchDispath } from '../../functions/fetch'

export default function InputControlList({ history, match, location }) {
  const dispatch = useDispatch()
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          id: match.params.idProject,
        },
        request: getInputControlPrint,
      }
    )
  }
  return <div className='App-paper'>
    <PageList
      name='InputControlList'
      title='Журнал входного контроля'
      columns={[
        { id: 'dateAccept', name: 'Дата поставки', type: 'date', formatDate: 'DD.MM.YYYY' },
        { id: 'name', name: 'Наименование сметное/ поставщика', },
        { id: 'provider', name: 'Поставщик', },
        { id: 'certificates', name: 'Сертификаты', },
        { id: 'count', name: 'Количество сметное/ поставщика', },
      ]}
      createItem={false}
      deleteItem={false}
      idName='id'
      history={history}
      match={match}
      location={location}
      loadAPI={{
        param: {
          id: match.params.idProject
        },
        request: getInputControl,
        transform: (res) => {
          return {
            count: res.count,
            get: res.get.map(obj => {
              let count = obj.count + ' ' + obj.storageUnit
              let name = obj.name
              if (!!obj.estimateId) {
                if (obj.count !== obj.estimateCount && obj.storageUnit !== obj.estimateStorageUnit) {
                  count = count + '/' + obj.estimateCount + ' ' + obj.estimateStorageUnit
                }
                name = name + '/' + obj.estimateName
              }
              return { ...obj, count, name, certificates: obj.certificates.join('; ') }
            })
          }
        }
      }}
      headerLink={<Button color='primary' onClick={handlePrint} >Печать</Button>}
      headerInit={{
        sortBy: 'dateAccept',
        sortDirection: 'desc',
      }}
    />
  </div>
}