import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import SimpleAdd from '../../components/SimpleAdd'
import validateProps from '../../functions/validateProps'
import { fetchDispath } from '../../functions/fetch'
import { enqueueSnackbar } from '../../reducers/notifier'
import {
  getProjectWayBillById, updProjectWayBillById,
  updProjectWayBillByIdFixed, getProjectWayBillByIdPrint,
  addWayBillTask, updWayBillTask, deleteWayBillTask,
} from '../../api/projects'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment'
import {
  Button, Typography, Tabs, Tab, Table, TableHead,
  TableRow, TableCell, TableBody, IconButton, TextField
} from '@material-ui/core';
import print from '../../functions/print'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { AddOldTask } from '../machineCalendar/WayBillCreateForDialog'

const formName = 'WayBillOne'
export default function WayBillOne({ history, match }) {
  const dispatch = useDispatch()
  const [fixed, setFixed] = useState(false)
  const [idMachine, setIdMachine] = useState('')
  useEffect(() => {
    dispatch(actions.formInitialize({
      typeTransportation: null,
      typeCommerce: null,
      typeConveyance: null,
      dateStart: null,
      dateEnd: null,
      medicalCheckup: null,
      medicalDate: null,
      medicalBio: null,
      conditionCheckup: null,
      conditionDate: null,
      conditionBio: null,
      mileageStart: null,
      mileageEnd: null,
      dateLineStart: null,
      dateLineEnd: null,
      fuel: null,
      driver: '',
      model: '',
      typeM:null,
      license: null,
      snils: null,
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        idProject: match.params.idProject,
        id: match.params.idDoc
      },
      progress: true,
      request: getProjectWayBillById,
    }))
      .then((res) => {
        if (!res) return;
        let mById = {}
        const mAllIds = []
        if (res.task && res.task.length > 0) {
          res.task.forEach(obj => {
            const id = 'id-' + obj.id
            mById[id] = obj;
            mAllIds.push(id);
          })
        }
        dispatch(actions.formChangeAsObj({
          typeTransportation: res.get.typeTransportation,
          typeCommerce: res.get.typeCommerce,
          typeConveyance: res.get.typeConveyance,
          valuetypeTransportation: { value: res.get.typeTransportation, label: res.get.typeTransportation },
          valuetypeCommerce: { value: res.get.typeCommerce, label: res.get.typeCommerce },
          valuetypeConveyance: { value: res.get.typeConveyance, label: res.get.typeConveyance },
          dateStart: res.get.dateStart ? moment(res.get.dateStart).format('YYYY-MM-DDTHH:mm') : null,
          dateEnd: res.get.dateEnd ? moment(res.get.dateEnd).format('YYYY-MM-DDTHH:mm') : null,
          medicalCheckup: res.get.medicalCheckup,
          medicalDate: res.get.medicalDate ? moment(res.get.medicalDate).format('YYYY-MM-DDTHH:mm') : null,
          medicalBio: res.get.medicalBio,
          conditionCheckup: res.get.conditionCheckup,
          conditionDate: res.get.conditionDate ? moment(res.get.conditionDate).format('YYYY-MM-DDTHH:mm') : null,
          conditionBio: res.get.conditionBio,
          mileageStart: res.get.mileageStart,
          mileageEnd: res.get.mileageEnd,
          dateLineStart: res.get.dateLineStart ? moment(res.get.dateLineStart).format('YYYY-MM-DDTHH:mm') : null,
          dateLineEnd: res.get.dateLineEnd ? moment(res.get.dateLineEnd).format('YYYY-MM-DDTHH:mm') : null,
          fuel: res.get.fuel,
          driver: res.get.surname + ' ' + res.get.name + ' ' + res.get.patronymic,
          model: res.get.model + ' ' + res.get.govNumber,
          typeM:res.get.typeM,
          mById,
          mAllIds,
          license: res.license,
          snils: res.snils,
        }, { name: formName }))
        setFixed(res.get.fixed)
        setIdMachine(res.get.idMachine)
      })
  }, [dispatch, match.params.idProject, match.params.idDoc,])
  const arrProps = [
    {
      xs: 12, bp: { md: 6 }, field: 'typeTransportation', type: 'autoArr', label: 'Вид перевозки ',
      textProps: { selectProps: { isDisabled: fixed }, arr: ['', 'коммерческие', 'для собственных нужд', 'работа спецтехники'].map(e => ({ value: e, label: e })) }
    },
    {
      xs: 12, bp: { md: 6 },
      field: 'typeCommerce', type: 'autoArr', label: 'Если коммерческое, то ',
      textProps: {
        arr: ['',
          'регулярная перевозка пассажиров и багажа',
          'перевозка грузов по договору перевозки грузов или договору фрахтования, в том числе по договору аренды транспорта с экипажем',
          'перевозка пассажиров и багажа по заказу',
          'перевозка пассажиров и багажа легковым такси',
          'организованная перевозка групп детей автобусами по договору фрахтования',
        ].map(e => ({ value: e, label: e })),
        selectProps: { isDisabled: fixed }
      }
    },
    {
      xs: 12, bp: { md: 6 }, field: 'typeConveyance', type: 'autoArr', label: 'Вид сообщения',
      textProps: { selectProps: { isDisabled: fixed }, arr: ['городское', 'пригородное', 'междугороднее'].map(e => ({ value: e, label: e })) }
    },
    { xs: 12, bp: { md: 6 }, type: 'divider' },
    { xs: 12, bp: { md: 4 }, field: 'dateStart', type: 'text', label: 'срок действия путевого листа начало', textProps: { type: 'datetime-local', disabled: fixed }, labelProps: { shrink: true }, },
    { xs: 12, bp: { md: 4 }, field: 'dateEnd', type: 'text', label: 'срок действия путевого листа окончание', textProps: { type: 'datetime-local', disabled: fixed }, labelProps: { shrink: true }, },
    { xs: 12, bp: { md: 4 }, type: 'divider' },
    { xs: 12, bp: { md: 4 }, field: 'medicalBio', type: 'text', label: 'ФИО Медика', textProps: { disabled: fixed } },
    { xs: 12, bp: { md: 4 }, field: 'medicalDate', type: 'text', label: 'Дата медосмотра', textProps: { type: 'datetime-local', disabled: fixed }, labelProps: { shrink: true }, },
    {
      xs: 12, bp: { md: 4 },
      field: 'medicalCheckup', type: 'component',
      textProps: {
        component: (values, handleChange) => {
          console.log(values)
          return <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values || false}
                  onChange={(e) => handleChange(e.target.checked, 'medicalCheckup')}
                  name="medicalCheckup"
                  color="primary"
                  disabled={fixed}
                />
              }
              label="Медосмотр пройден"
            />
          </div>
        }
      },
    },


    { xs: 12, bp: { md: 4 }, field: 'conditionBio', type: 'text', label: 'ФИО Механика', textProps: { disabled: fixed } },
    { xs: 12, bp: { md: 4 }, field: 'conditionDate', type: 'text', label: 'Дата техосмотра', textProps: { type: 'datetime-local', disabled: fixed }, labelProps: { shrink: true }, },
    {
      xs: 12, bp: { md: 4 },
      field: 'conditionCheckup', type: 'component',
      textProps: {
        component: (values, handleChange) => {

          return <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values || false}
                  onChange={(e) => handleChange(e.target.checked, 'conditionCheckup')}
                  name="conditionCheckup"
                  color="primary"
                  disabled={fixed}
                />
              }
              label="Техосмотр пройден"
            />
          </div>
        }
      },
    },
    { xs: 12, bp: { md: 4 }, field: 'mileageStart', type: 'text', label: 'Значение одометра на старте', textProps: { type: 'number', disabled: fixed }, },
    { xs: 12, bp: { md: 4 }, field: 'mileageEnd', type: 'text', label: 'Значение одометра в конце', textProps: { type: 'number' }, },
    { xs: 12, bp: { md: 4 }, type: 'divider' },
    { xs: 12, bp: { md: 4 }, field: 'dateLineStart', type: 'text', label: 'Время выхода на линию', textProps: { type: 'datetime-local', disabled: fixed }, labelProps: { shrink: true }, },
    { xs: 12, bp: { md: 4 }, field: 'dateLineEnd', type: 'text', label: 'Время возвращения на базу', textProps: { type: 'datetime-local' }, labelProps: { shrink: true }, },
    { xs: 12, bp: { md: 4 }, type: 'divider' },
    { xs: 12, bp: { md: 4 }, field: 'fuel', type: 'text', label: 'Топливо', textProps: { type: 'number', disabled: fixed } },
  ]
  useEffect(() => {
    dispatch(actions.formInitialize({
      errorValidate: {
      }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSubmit = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        typeTransportation: values.typeTransportation,
        typeCommerce: values.typeCommerce,
        typeConveyance: values.typeConveyance,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
        medicalCheckup: values.medicalCheckup,
        medicalDate: values.medicalDate,
        medicalBio: values.medicalBio,
        conditionCheckup: values.conditionCheckup,
        conditionDate: values.conditionDate,
        conditionBio: values.conditionBio,
        mileageStart: values.mileageStart,
        mileageEnd: values.mileageEnd,
        dateLineStart: values.dateLineStart,
        dateLineEnd: values.dateLineEnd,
        fuel: values.fuel,
      },
      url: updProjectWayBillById.path({ id: match.params.idDoc, idProject: match.params.idProject, }),
      method: updProjectWayBillById.type,
      history: history
    }))
  }
  const handleFix = (e) => {
    e.preventDefault();
    dispatch(actions.formSubmitAPI({
      props: {},
      url: updProjectWayBillByIdFixed.path({ id: match.params.idDoc, idProject: match.params.idProject, }),
      method: updProjectWayBillByIdFixed.type,
      endFunc: (res) => {
        if (res.success) {
        }
        setFixed(e => !e)
      }
    }))
  }
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          idProject: match.params.idProject,
          id: match.params.idDoc
        },
        request: getProjectWayBillByIdPrint,
      }
    )
  }
  const [valueTab, setValueTab] = useState(0)
  return (<div className='App-paper' style={{ padding: '16px' }}>
    <Typography variant='h6' style={{ marginBottom: '8px' }}>Путевой лист</Typography>
    <Button color='primary' onClick={() => handlePrint()} disabled={!values.license || !values.snils}>Печать </Button>
    <Typography>Транспорт: {values.model}</Typography>
    <Typography>Водитель: {values.driver}</Typography>
    {values.license ?
      <Typography>Водительское удостоверение: {values.license.docNumber} кат.:{values.license.docCat}</Typography>
      : <Typography style={{ color: '#AA0000', fontWeight: 700 }}>ВНИМАНИЕ Водительское удостоверение отсутствует, печать невозможна</Typography>
    }
    {values.snils ?
      <Typography>СНИЛС: {values.snils.docNumber}</Typography>
      : <Typography style={{ color: '#AA0000', fontWeight: 700 }}>ВНИМАНИЕ СНИЛС водителя отсутствует, печать невозможна</Typography>
    }
    <Button onClick={handleFix} color='primary'>{fixed ? 'Снять фиксацию' : 'Фиксировать'}</Button>
    <Tabs value={valueTab} onChange={(e, v) => setValueTab(v)} indicatorColor='primary'>
      <Tab key={0} label='Сведения о перевозке' />
      <Tab key={1} label='Задание водителю' />
    </Tabs>
    {valueTab === 0 && <form autoComplete="off" onSubmit={handleSubmit} >
      <SimpleAdd
        arr={arrProps}
        formName={formName}
        gridProps={{ xs: 12 }}
      />
      <Button type="submit" color='primary' >Сохранить</Button>
    </form>
    }
    {valueTab === 1 && <div><TaskTable params={match.params} idMachine={idMachine} typeM={values.typeM}/></div>}
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
  </div>)
}

const rowDefault = {
  id: '',
  order: '',
  arrivalTime: '',
  loadingAddress: '',
  unloadingAddress: '',
  cargoName: '',
  count: '',
  distance: '',
  transported: '',
  typwWork:'',
  description:'',
}
const column = [
  { id: 'order', type: 'text', name: 'в чье распоряжение' },
  { id: 'arrivalTime', type: 'time', name: 'время прибытия, ч. мин' },
  { id: 'loadingAddress', type: 'text', name: 'адрес погрузки' },
  { id: 'unloadingAddress', type: 'text', name: 'адрес разгрузки' },
  { id: 'cargoName', type: 'text', name: 'наименование груза' },
  { id: 'count', type: 'number', name: 'количество ездок' },
  { id: 'distance', type: 'number', name: 'расстояние, км' },
  { id: 'transported', type: 'number', name: 'перевезти, тонн' },
  { id: 'description', type: 'text', name: 'примечание' },
]
const columnSpec = [
  { id: 'order', type: 'text', name: 'в чье распоряжение' },
  { id: 'arrivalTime', type: 'time', name: 'время прибытия, ч. мин' },
  { id: 'typeWork', type: 'text', name: 'вид работы' },
  { id: 'description', type: 'text', name: 'примечание' },
]
function TaskTable({ params, idMachine, typeM }) {
  const [idRow, setIdRow] = useState(0)
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values = {} } = form
  const allIds = values.mAllIds || [];
  const byId = values.mById || {};
  const addRow = () => {
    const newIdRow = idRow + 1
    dispatch(actions.formObjAdd(rowDefault, { field: 'mById', id: 'id' + newIdRow, name: formName }))
    dispatch(actions.formArrayAdd('id' + newIdRow, { field: 'mAllIds', name: formName }))
    setIdRow(newIdRow)
  }
  const handleCreate = (task, id) => {
    const { id: idTask, ...other } = task
    dispatch(actions.formSubmitAPI({
      props: other,
      url: addWayBillTask.path({ id: params.idDoc, idProject: params.idProject, }),
      method: addWayBillTask.type,
      endFunc: (res) => {
        if (res.idTask) {
          dispatch(actions.formObjChangeObj({ id: res.idTask }, { field: 'mById', id: id, name: formName }))
        }
      }
    }))
  }
  const col = typeM==='спец. автомобиль'?columnSpec:column
  return <div>
    <AddOldTask
      idProject={params.idProject}
      idMachine={idMachine}
      formName={formName}
      createNew={handleCreate}
    />
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 20 }} padding={'none'}>
            <IconButton onClick={addRow}  ><AddIcon /></IconButton>
          </TableCell>
          {col.map(obj => {
            return <TableCell key={obj.id} style={{ width: obj.width ? obj.width : undefined }}>{obj.name}</TableCell>
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {allIds
          .map(row => {
            const obj = byId[row];
            return <TaskTableRow
              id={row}
              key={row}
              obj={obj}
              params={params}
              col={col}
            />
          })}
        <TableRow style={{ height: 20 * 5 }}>
          <TableCell colSpan={col.length + 1} />
        </TableRow>
      </TableBody>
    </Table>
  </div>
}
function TaskTableRow({ id, obj, params, col }) {
  const [edited, setEdited] = useState(false)
  const dispatch = useDispatch()
  const deleteRow = () => {
    if (obj.id === '') {
      dispatch(actions.formArrayDelete(id, { field: 'mAllIds', name: formName }))
      dispatch(actions.formObjDelete({ id: id, field: 'mById', name: formName }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {},
      url: deleteWayBillTask.path({ id: params.idDoc, idProject: params.idProject, idTask: obj.id }),
      method: deleteWayBillTask.type,
      endFunc: (res) => {
        dispatch(actions.formArrayDelete(id, { field: 'mAllIds', name: formName }))
        dispatch(actions.formObjDelete({ id: id, field: 'mById', name: formName }))
      }
    }))
  }
  const handleChange = (e, field) => {
    dispatch(actions.formObjChangeObj({ [field]: e.target.value }, { field: 'mById', id: id, name: formName }))
  }
  const handleSave = () => {
    const { id: idTask, ...other } = obj
    let url;
    if (idTask === '') {
      url = addWayBillTask.path({ id: params.idDoc, idProject: params.idProject, })
    }
    else {
      url = updWayBillTask.path({ id: params.idDoc, idProject: params.idProject, idTask: idTask })
    }
    dispatch(actions.formSubmitAPI({
      props: other,
      url: url,
      method: idTask === '' ? addWayBillTask.type : updWayBillTask.type,
      endFunc: (res) => {
        setEdited(false)
        if (res.idTask) {
          dispatch(actions.formObjChangeObj({ id: res.idTask }, { field: 'mById', id: id, name: formName }))
        }
      }
    }))
  }
  return <TableRow>
    <TableCell style={{ width: 20 }} padding={'none'}>
      {!edited && <React.Fragment>
        <IconButton onClick={deleteRow}  ><DeleteIcon /></IconButton>
        <IconButton onClick={() => setEdited(true)}  ><EditIcon /></IconButton>
      </React.Fragment>}
      {edited && <React.Fragment>
        <IconButton onClick={handleSave}  ><DoneIcon /></IconButton>
        <IconButton onClick={() => setEdited(false)}  ><ClearIcon /></IconButton>
      </React.Fragment>}
    </TableCell>
    {col.map(e => {
      if (edited) {
        return <TableCell padding='none' style={{ width: e.width ? e.width : undefined }}>
          <TextField
            fullWidth
            margin="dense"
            type={e.type}
            value={obj[e.id]}
            onChange={(s => { handleChange(s, e.id) })}
            onFocus={e => { if (e.type === 'number') e.target.select() }}
          />
        </TableCell>
      }
      else {
        return <TableCell key={id + '' + e.id} style={{ width: e.width ? e.width : undefined }}>{obj[e.id]}</TableCell>
      }
    })}
  </TableRow>
}
