import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux'
export default function AllPrice({ formName }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byId ={}, allIds=[] } = form.values
  let summ = 0;
  allIds.forEach(key=>{
    summ+=Number(byId[key].price)
  })
  return <div style={{ textAlign: 'right' }}>
    <Grid
      container
      direction="row"
      justify='flex-end'
      alignItems="flex-end"
    >
      <Grid item xs={3}><Typography variant='h6'>Всего</Typography></Grid>
      <Grid item xs={3}><Typography variant='h6'>{Number(summ).toLocaleString()}</Typography></Grid>
    </Grid>
  </div>
}