import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'
import { addProvider, getCompanyAPI } from '../../../api/list'
import { fetchDispath } from '../../../functions/fetch'

const arrProps = [
  {
    bp: { md: 6, sm: 12 },
    field: 'fullName', type: 'text', label: 'Полное название компании*',
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'name', type: 'text', label: 'Сокращенное название компании*',
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
  {// 10-12
    bp: { md: 6, sm: 12 },
    field: 'inn', type: 'text', label: 'ИНН*',
    validator: [value => value !== '', v => v.length >= 10 && v.length <= 12], errorText: 'Поле обязательно',
  },
  { // 13 символов,15
    bp: { md: 6, sm: 12 },
    field: 'ogrn', type: 'text', label: 'ОГРН, ОГРНИП*',
    validator: [value => value !== '', v => v !== '' && v.length >= 13 && v.length <= 15], errorText: 'Поле обязательно',
  },
  { // 9
    bp: { md: 6, sm: 12 },
    field: 'kpp', type: 'text', label: 'КПП',
    validator: [v => v === '' || v.length === 9], errorText: 'Неправильное значение',
  },
  {// 8-10
    bp: { md: 6, sm: 12 },
    field: 'okpo', type: 'text', label: 'ОКПО',
    validator: [v => v === '' || ( v.length >= 8 && v.length <= 10)], errorText: 'Неправильное значение',
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'oktmo', type: 'text', label: 'ОКТМО',
  },
  { bp: { md: 6, sm: 12 }, field: 'adress', type: 'text', label: 'Адрес', },
  { bp: { md: 6, sm: 12 }, field: 'phone', type: 'text', label: 'Телефон', },
  { bp: { md: 6, sm: 12 }, field: 'email', type: 'text', label: 'Почта', },
]
const formName = 'ProviderAdd'

export default function CompaniesAdd({ history, modal = false, modalClose }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ fullName: '', name: '', inn: '', ogrn: '', kpp: '', okpo: '', oktmo: '', adress: '', phone: '', email: '' }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    const { fullName, name, inn, ogrn, kpp, okpo, oktmo, adress, phone, email } = values
    dispatch(actions.formSubmitAPI({
      props: {
        fullName,
        name,
        inn,
        ogrn,
        kpp,
        okpo,
        oktmo,
        adress,
        phone,
        email
      },
      url: addProvider.path,
      method: addProvider.type,
      history: history,
      endFunc: (res) => {
        if (modal) {
          modalClose({ success: res.success, get: res.get })
        }
      }
    }, formName))
  };
  const handleLoad = () => {
    dispatch(fetchDispath({
      querty: {
        inn: values.inn
      },
      progress: false,
      request: getCompanyAPI,
    }))
      .then(res => {
        if (res.success) {
          dispatch(actions.formChangeAsObj({
            fullName: res.get.fullName,
            name: res.get.shortName,
            inn: res.get.inn,
            ogrn: res.get.ogrn,
            kpp: res.get.kpp,
            okpo: res.get.okpo,
            oktmo: res.get.oktmo,
            adress: res.get.address,
            phone: res.get.phone,
            email: res.get.email
          }, { name: formName }))
        }
        else {
          dispatch(enqueueSnackbar({
            message: `Компания не найдена`,
            options: { variant: 'error' }
          }))
        }
      })
      .catch(err => console.log(err))
  }
  const handleBack = () => {
    if (!modal) {
      history.goBack()
      return;
    }
    modalClose({ success: false })
  }
  return (
    <div className='App-paper' >
      <Button onClick={handleLoad} color="primary" >Заполнить по ИНН</Button>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd formName={formName} arr={arrProps} title='Добавить поставщика' gridProps={{ xs: 12 }} />
        </div>
        <Button color="primary" onClick={handleBack}>{modal ? 'Закрыть' : 'Назад'} </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}