import React from 'react'
import Grid from '@material-ui/core/Grid';
import checkCan from '../../functions/can'
import CardMenu from './source/CardMenu'

function CartProps({ arr, name }) {
  const arrNew = arr.map(({ check, to, primary, disabled,add }) =>
    checkCan(check) && { to, primary, disabled, add: add }
  ).filter(key => typeof key !== 'boolean')
  return arrNew.length > 0 && <Grid item><CardMenu arr={arrNew} name={name} /></Grid>
}

function OurStorageMenu() {
  const arr = {
    storage: [
      { check: 'storage:our:read', to: '/storage/bldg', primary: 'Список', add: true  },
    ],
    remains: [
      { check: 'storage:read', to: '/storage/report/remains', primary: 'Остатки на складе' },
    ],
    documents: [
      { check: 'storage:read', to: '/storage/doc/move/project', primary: 'Переместить на проект' },
      { check: 'storage:read', to: '/storage/doc/addmaterial', primary: 'Добавить на склад' },
    ],
    inventory: [
      { check: 'inventory:read', to: '/inventory', primary: 'Список'  },
      { check: 'inventory:read', to: '/inventory/move/project', primary: 'Переместить на проект' },
    ],
  }
  return <Grid container>
    <CartProps arr={arr.storage} name={'Склады'} />
    <CartProps arr={arr.remains} name={'Отчеты'} />
    <CartProps arr={arr.documents} name={'Документы'} />
    <CartProps arr={arr.inventory} name={'Инвентарные объекты'} />
  </Grid>
}
export default OurStorageMenu;