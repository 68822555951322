import React from 'react';
import { getMaterials, deleteMaterials } from '../../../api/receipt'
import PageList from '../../../components/PageListAPI'

export default function ReceiptMaterialsList(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='receiptMaterialsProject'
        title='Документы получение материалов от заказчика'
        columns={[
          { id: 'date', name: 'Дата', type: 'date', formatDate: 'DD.MM.YYYY', width:160 },
          { id: 'name', name: 'Наименование' },
        ]}
        createItem={true}
        deleteItem={true}
        idName='id'
        history={props.history}
        match={props.match}
        location={props.location}
        loadAPI={{
          querty: {
            idProject: props.match.params.id
          },
          request: getMaterials,
        }}
        deleteAPI={deleteMaterials}
        disabledDelete={(obj) => obj.related}
        headerInit={{
          sortBy: 'date',
          sortDirection: 'desc',
        }}
      />
    </div>
  )
}


