import React from 'react';
import * as api from '../../../api/doc'
import PageList from '../../../components/PageListAPI'
import { Chip } from '@material-ui/core'
import { statusAll } from './source/type.enum'

function getStatus(status) {
  return statusAll.find(e => e.value === status)
}

export default function OrderProviderList({ history, match, location }) {
  const idProject = match.params.idProject
  const head = [
    { id: 'id', name: '№' },
    { id: 'date', name: 'Дата создания', type: 'date' },
    { id: 'provider', name: 'Поставщик', },
    { id: 'dateOrder', name: 'Желаемая дата доставки', type: 'date', formatDate: 'DD.MM.YYYY' },
    { id: 'user', name: 'Добавил', },
    { id: 'title', name: 'Описание', },
    {
      id: 'status', name: 'Статус', type: 'component',
      component: ({ obj }) => <div>
        <Chip label={obj.statusRus} variant="outlined" style={{ color: obj.statusColor, borderColor: obj.statusColor }} />
      </div>
    }
  ].filter(key => key)
  return <div className='App-paper'>
    <PageList
      name='MaterialOrderProject'
      title='Заказ поставщику'
      columns={head}
      createItem={true}
      deleteItem={true}
      idName='id'
      history={history}
      match={match}
      location={location}
      loadAPI={{
        querty: {
          idProject: idProject
        },
        request: api.getMOP,
        transform: (res => {
          return {
            count: res.count,
            get: res.get.map(obj => {
              return { ...obj, statusRus: getStatus(obj.status).label, statusColor: getStatus(obj.status).color }
            })
          }
        }),
      }}
      deleteAPI={api.deleteMOP}
      headerInit={{
        sortBy: 'date',
        sortDirection: 'desc',
      }}
      disabledDelete={(obj) => obj.related}
      filterProps={[
        {
          arr: statusAll.map(e => ({ value: e.value, name: e.label })),
          field: 'status',
          name: 'Статус',
          type: 'select'
        },
      ]}
    />
  </div>
}

