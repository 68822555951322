import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import * as api from '../../../api/material'
import { getStorageUnit, addStorageUnit } from '../../../api/list'
import { fetchDispath } from '../../../functions/fetch'
import { set as setAutocomplite } from '../../../reducers/autocomplite'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 220,
    marginTop: 5,
    marginBottom: 3,
    width: '50%',
    overflowX: 'visible'

  },
}))
const formName = 'MaterialUpdate'
export default function MaterialUpdate({ history, match }) {
  const classes = useStyles()
  const arrProps = [
    { field: 'name', type: 'text', label: 'Наименование', validator: [value => value !== ''], errorText: 'Поле обязательно' },
    { field: 'typeMark', type: 'text', label: 'Тип, марка' },
    { field: 'vendorCode', type: 'text', label: 'Артикул' },
    { field: 'number', type: 'text', label: 'Номер' },
    { field: 'ourCount', type: 'text', label: 'Количество по смете', textProps: { autoComplete: 'off', type: 'number' } },
    { field: 'estCount', type: 'text', label: 'Количество давальческих материалов', textProps: { autoComplete: 'off', type: 'number' } },
    { field: 'price', type: 'text', label: 'Цена за еденицу', textProps: { autoComplete: 'off', type: 'number' } },
    {
      field: 'idStorageUnit', label: 'Еденица хранения', type: 'autoLoadCreate',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        createAPI: addStorageUnit,
        createBody: 'name',
        path: getStorageUnit.path,
        meta: {
          field: 'idStorageUnit',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
  ]
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.id,
        idMaterial: match.params.idMaterial
      },
      progress: true,
      request: api.getMaterialById,
    })).then(res => {
      dispatch(actions.formInitialize({
        name: res.get.name,
        vendorCode: res.get.vendorCode,
        typeMark: res.get.typeMark,
        number: res.get.number,
        ourCount: res.get.ourStatus.count,
        estCount: res.get.estStatus.count,
        storageUnit: res.get.storageUnit,
        price: res.get.price,
        idStorageUnit: res.get.idStorageUnit,
        idStorageUnit2: res.get.idStorageUnit2,
      }, { name: formName }))
      dispatch(setAutocomplite({ value: res.get.idStorageUnit + '', label: res.get.storageUnit }, { field: 'idStorageUnit' }))
    }).catch(err => console.log(err))
  }, [dispatch, match.params.id, match.params.idMaterial])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleUpdate = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    const params = {
      name: values.name,
      vendorCode: values.vendorCode,
      typeMark: values.typeMark,
      number: values.number,
      storageUnit: values.idStorageUnit,
      price: values.price,
      ourCount: values.ourCount,
      estCount: values.estCount,
    }
    dispatch(actions.formSubmitAPI({
      props: params,
      url: api.updateMaterial.path({
        id: match.params.id,
        idMaterial: match.params.idMaterial
      }),
      method: api.updateMaterial.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper' >
      <form autoComplete="off" onSubmit={handleUpdate} className={classes.main}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Изменить материал'
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}
