import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, Tooltip } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import * as actions2 from '../../../../reducers/form';
import PropTypes from 'prop-types';

const styles = theme => ({
  table: {
  },
  autocomplite: {
    minWidth: 300,
  },
  number: {
    //   maxWidth: 100
  },
});
class TableWorker extends React.Component {
  render() {
    const { classes, form, formName } = this.props
    const { values } = form[formName] || form.default
    const { wAllIds = [], wById = {} } = values
    return (<div className={classes.tableShipment}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 10 }} padding='none'>
            </TableCell>
            <TableCell align='left' padding='none' style={{ width: '40%' }}>Работник</TableCell>
            <TableCell>Должность</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {wAllIds
            .map(row => {
              const obj = wById[row];
              return (
                <Row
                  key={row}
                  id={row}
                  obj={obj}
                  formName={this.props.formName}
                />
              )
            })}
          <TableRow style={{ height: 40 * 5 }}>
            <TableCell colSpan={8} />
          </TableRow>
        </TableBody>
      </Table>
    </div>)
  }
}
TableWorker.propTypes = {
  formName: PropTypes.string.isRequired,
};
const mapStateToProps = store => {
  return {
    form: store.form,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    actions: {
      formArrayAdd: (obj, meta) => dispatch(actions2.formArrayAdd(obj, meta)),
      formArrayDelete: (obj, meta) => dispatch(actions2.formArrayDelete(obj, meta)),
      formObjAdd: (obj, meta) => dispatch(actions2.formObjAdd(obj, meta)),
      formObjDelete: meta => dispatch(actions2.formObjDelete(meta)),
      formObjChangeObj: (obj, meta) => dispatch(actions2.formObjChangeObj(obj, meta)),
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TableWorker));

function Row({ obj, formName, id }) {
  const dispatch = useDispatch()
  const deleteRow = () => dispatch(actions2.formObjChange(!obj.deleted, { id: id, field: 'wById', fieldObj: 'deleted', name: formName }))
  return <TableRow style={{ background: (obj.deleted) ? '#f3bd48' : undefined }}>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <Tooltip title="Delete">
        <IconButton aria-label="Delete"
          onClick={deleteRow}  ><DeleteIcon /></IconButton>
      </Tooltip>
    </TableCell>
    <TableCell>{`${obj.surname} ${obj.name} ${obj.patronymic}`}</TableCell>
    <TableCell>{obj.position}</TableCell>
  </TableRow>
}