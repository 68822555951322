import template from "./template_crud";
const order = '/orders/my';
export const getOrdersMy = template.getProjectId(order);
export const deleteOrdersMyById = template.deleteProjectById(order);
export const addOrdersMy = {
  path: (obj) => `/projects/${obj.id}/orders/my`,
  type: 'POST'
}
const orderLenta = '/orders/lenta';
export const getOrdersLenta = template.getProjectId(orderLenta)
export const changeStatusOrder = {
  path: (obj) => `/projects/${obj.id}/orders/lenta`,
  type: 'POST'
}
const orderAll = '/order/material/all';
export const getOrdersMaterialsAll = template.get(orderAll);
export const deleteOrdersMaterialsAll = template.deleteById(orderAll);