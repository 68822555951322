import { getProjectId } from "./template_crud";
export const getAnalyticsPrice = getProjectId('/analytics/price');
export const getAnalyticsOrder = getProjectId('/analytics/order');
export const getAnalyticsMaterial = getProjectId('/analytics/material');
export const getAnalyticsMaterialById = {
  path: (obj) => `/projects/${obj.id}/analytics/material/${obj.idMaterial}`,
  type: 'GET'
}
export const getAnalyticsProject = getProjectId('/analytics');
export const getAnalyticsWork = getProjectId('/analytics/work');
export const getAnalyticsWorkById = {
  path: (obj) => `/projects/${obj.id}/analytics/work/${obj.idWork}`,
  type: 'GET'
}
export const getAnalyticsWorker = getProjectId('/analytics/worker');