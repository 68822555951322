import React, { useEffect, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button, Typography, Grid, } from '@material-ui/core';
import { fetchDispath } from '../../../functions/fetch'
import * as api from '../../../api/list'
import * as actions from '../../../reducers/form';

const questionDefault = {
  question: '',
  description: '',
  answerType: '',
  answerParam: '',
}
export default function ChecklistEditQuestion(props) {
  const [propsChecklist, setProps] = useState({
    name: ''
  })
  const [newQ, setNewQ] = useState(false)
  const [questions, setQuestions] = useState([])
  const [questionLastNumber, setQuestionsLastNumber] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.id,
      },
      progress: true,
      request: api.getChecklistById,
    }))
      .then(res => {
        setProps({
          name: res.get.name
        })
        setQuestions(res.questions)
        if (res.questions.length > 0) {
          setQuestionsLastNumber(res.questions[res.questions.length - 1].questionNumber)
        }
      })
      .catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, props.match.params.id])
  const handleReload = () => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.id,
      },
      progress: true,
      request: api.getChecklistById,
    }))
      .then(res => {
        setProps({
          name: res.get.name
        })
        setQuestions(res.questions)
        if (res.questions.length > 0) {
          setQuestionsLastNumber(res.questions[res.questions.length - 1].questionNumber)
        }
      })
      .catch(err => console.log(err))
  }
  return <div className='App-paper'>
    {propsChecklist.name}
    <Button color="primary" onClick={() => { setNewQ(true) }}>Создать </Button>
    <Grid container spacing={2} direction="column">
      {questions.map(e => {
        return <Grid item key={e.id}>
          <AccordionQuestion
            questionOld={e.question}
            numberQuestionOld={e.questionNumber}
            idCheckList={props.match.params.id}
            param={e}
            reload={() => handleReload()}
          />
        </Grid>

      })}
      {newQ && <Grid item>
        <AccordionQuestion
          numberQuestionOld={questionLastNumber}
          questionOld={'Новый вопрос'}
          idCheckList={props.match.params.id}
          param={{ ...questionDefault, questionNumber: questionLastNumber, id: 'not save' }}
          reload={() => { setNewQ(false); handleReload() }}
        />
      </Grid>}
    </Grid>
    <Button color="primary" onClick={() => props.history.push('/checklist')}>Назад </Button>
  </div>
}
const arrType = [
  { value: 'button', label: 'кнопки' },
  { value: 'checkbox', label: 'чекбокс' },
  { value: 'text', label: 'текстовое поле' },
]
function AccordionQuestion({ questionOld, numberQuestionOld, idCheckList, param, reload }) {
  const [open, setOpen] = useState(false)
  const [question, setQuestion] = useState('')
  const [description, setDescription] = useState('')
  const [answerType, setType] = useState('checkbox')
  const [numberQuestion, setNumberQuestion] = useState(0)
  const [buttonNumber, setButtonNumber] = useState(2)
  const [buttonArr, setButtonArr] = useState([{ name: 'Да' }, { name: 'Нет' }])
  const dispatch = useDispatch()
  const handleOpen = () => {
    setQuestion(param.question)
    setDescription(param.description)
    setType(param.answerType)
    setNumberQuestion(param.questionNumber)
    if (param.type === 'button' && param.answerParam) {
      setButtonNumber(param.answerParam.buttonNumber)
      setButtonArr(param.answerParam.buttonArr)
    }
    else {
      setButtonNumber(2)
      setButtonArr([{ name: 'Да' }, { name: 'Нет' }])
    }
  }
  const handleSave = () => {
    setOpen(false)
    if (param.id === 'not save') {
      dispatch(actions.formSubmitAPI({
        props: {
          questionNumber: numberQuestion,
          question,
          description,
          answerType,
          answerParam: answerType === 'button' ? { buttonNumber, buttonArr } : {},
        },
        url: api.addChecklistQuestion.path({ id: idCheckList }),
        method: api.addChecklistQuestion.type,
        endFunc: (res) => {
          if (res.success) reload()
        }
      }, 'formName'))
    }
    else {
      dispatch(actions.formSubmitAPI({
        props: {
          questionNumber: numberQuestion,
          question,
          description,
          answerType,
          answerParam: answerType === 'button' ? { buttonNumber, buttonArr } : {},
        },
        url: api.updChecklistQuestion(idCheckList).path({ id: param.id }),
        method: api.updChecklistQuestion(idCheckList).type,
        endFunc: (res) => {
          if (res.success) reload()
        }
      }, 'formName'))
    }

  }
  return <div className='App-paper'>
    <div
      onClick={() => {
        if (!open) handleOpen()
        setOpen(old => !old)
      }}
      style={{ cursor: 'pointer' }}
      onMouseDown={() => { return false }}
      onSelect={() => { return false }}
    >
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid xs={2}>
          <Typography >{numberQuestionOld}</Typography>
        </Grid>
        <Grid xs={8}>
          <Typography >{questionOld}</Typography>
        </Grid>
      </Grid>
    </div>
    {
      open && <Grid
        container
        direction="row"
        space={4}
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <TextField
            required
            label="Номер"
            fullWidth
            type='number'
            value={numberQuestion}
            onChange={(e) => setNumberQuestion(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Вопрос"
            fullWidth
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Дополнительное описание"
            multiline
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            value={answerType}
            onChange={(e) => { setType(e.target.value); }}
            label="тип"
            fullWidth
            SelectProps={{
              native: true,
            }}
          >
            {arrType.map(e => {
              return <option key={e.value} value={e.value}>{e.label}</option>
            })}
          </TextField>
        </Grid>
        {answerType === 'button' &&
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="flex-end"
          >
            <Grid item xs={2}>
              <TextField
                select
                value={buttonNumber}
                onChange={(e) => {
                  setButtonNumber(e.target.value);
                  setButtonArr([...Array(Number(e.target.value)).keys()].map((i) => ({ name: (i + 1) + '' })))
                }}
                label="Количество вариантов ответа"
                fullWidth
                SelectProps={{
                  native: true,
                }}
              >
                {[{ value: 2, label: '2' }, { value: 3, label: '3' }].map(e => {
                  return <option key={e.value} value={e.value}>{e.label}</option>
                })}
              </TextField>
            </Grid>
            <Grid item container xs={9}>
              {buttonArr.map((e, i) => {
                return <Grid item key={i}> <TextField
                  required
                  label={"Текст кнопки " + (i + 1)}
                  multiline
                  fullWidth
                  value={e.name}
                  onChange={(e) => {
                    const newArr = [...buttonArr]
                    newArr[i] = { name: e.target.value }
                    setButtonArr(newArr)
                  }
                  }
                /></Grid>
              })}
            </Grid>
          </Grid>
        }
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button color="primary" onClick={() => setOpen(old => !old)}>Отмена </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={handleSave}>Сохранить </Button>
          </Grid>
        </Grid>
      </Grid>
    }
  </div>
}