import React from 'react';
import * as api from '../../../../api/doc'
import PageList from '../../../../components/PageListAPI'
import can from '../../../../functions/can'
import { getLabel } from '../function'

export default function ToolWrite({ history, match, location }) {
  return (
    <div className='App-paper'>
      <PageList
        name='useToolList'
        title='Документы приемки оборудования'
        columns={[
          { id: 'id', name: '№' },
          { id: 'user', name: 'Выдал', },
          { id: 'worker', name: 'Получил', },
          { id: 'position', name: 'Должность', },
          { id: 'type', name: 'Тип списания', },
          { id: 'date', name: 'Дата', type: 'date' },
        ]}
        createItem={can('useTool:create')}
        deleteItem={can('useTool:delete')}
        idName='id'
        history={history}
        match={match}
        location={location}
        loadAPI={{
          param: {
            id: match.params.idProject
          },
          request: api.getToolWrite,
          transform: (res => {
            return {
              count: res.count,
              get: res.get.map(obj => {
                return {
                  id: obj.id,
                  user: obj.gSurname + ' ' + obj.gName,
                  worker: obj.surname + ' ' + obj.name[0] + '.' + obj.patronymic[0] + '.',
                  position: obj.position,
                  date: obj.date,
                  type: getLabel(obj.type)
                }
              })
            }
          }),
        }}
        deleteAPI={api.deleteToolWriteById(match.params.idProject)}
        headerInit={{
          sortBy: 'date',
          sortDirection: 'desc',
        }}
      />
    </div>
  )
}


