import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDispath } from '../functions/fetch'
import {
  Button, Menu, MenuItem, CircularProgress,
} from '@material-ui/core'
import moment from 'moment'
import print from '../functions/print'
import 'moment/locale/ru';
import { Grid, Typography } from '@material-ui/core'
import GridProps from './GridProps'
import { Link } from 'react-router-dom';
import { API_BO } from '../conf'
import { putPaidStatusShipmentById, putFileShipmentById } from "../api/shipment";
import { enqueueSnackbar } from '../reducers/notifier'
import { loadFileAPI } from '../reducers/materialLoad';
import { addFile } from '../api/list'
import DeleteButton from './DeleteButtonDoc'

const initDefault = {
  obj: {
    provider: '',
    projectName: '',
    date: new Date(),
    statusRus: '',
    allDelivery: false
  }
}
export default function ShipmentByIdHeader({
  name,
  printRequest,
  typeDelivery,
  match,
  history,
  setFile,
  arrFile,
  deleteAPI
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [loadingFile, setLoadingFile] = useState(false)
  const [paidStatusRus, setPaidStatusRus] = useState(undefined)
  const dispatch = useDispatch()
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          id: match.params.idShipment,
        },
        request: printRequest,
      }
    )
  }
  const handleDelivery = () => {
    setAnchorEl(null)
    history.push(`${API_BO}/projects/${match.params.idProject}/delivery/doc/${typeDelivery}_${match.params.idShipment}`);
  }
  const formTable = useSelector(state => state.formTable[name] || state.formTable.default)
  const { obj } = formTable || initDefault.obj
  const handlePaid = () => {
    setAnchorEl(null)
    fetch({
      param: {
        id: match.params.idShipment,
      },
      body: {
        status: (obj.paidStatus === 'paid') ? 'not_paid' : 'paid',
      },
      progress: true,
      request: putPaidStatusShipmentById,
    })
      .then((res) => {
        if (res.success) {
          dispatch(enqueueSnackbar({
            message: `Успешно`,
            options: { variant: 'success' }
          }))
          setPaidStatusRus((obj.paidStatus === 'not_paid') ? 'Оплачено' : 'Не оплачено')
        }
      })
  }
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoadingFile(true)
    dispatch(loadFileAPI({ file: e.target.files[0], path: addFile.path }))
      .then(res => {
        if (res.success) {
          fetch({
            param: {
              id: match.params.idShipment,
            },
            body: {
              idFile: res.id,
            },
            progress: true,
            request: putFileShipmentById,
          }).then(() => {
            setFile([...arrFile, { id: res.id, name: res.name }])
          })
        }
        setLoadingFile(false)
      })
      .catch()
  }
  return <div>
    <Button aria-haspopup="true" color='primary' onClick={(e) => setAnchorEl(e.currentTarget)}>
      Действие
    </Button>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem onClick={handleDelivery} disabled={obj.allDelivery}>Оприходовать</MenuItem>
      <MenuItem onClick={handlePaid}>Изменить статус оплаты</MenuItem>
    </Menu>
    {typeDelivery === 'purchasemtp' && <Button disabled={loadingFile} component="label" color='primary'>
      {loadingFile && <CircularProgress size={25} />}
      <span>Прикрепить файл</span>
      <input
        type="file"
        style={{ display: "none" }}
        onChange={(e) => _handleImageChange(e)}
      />
    </Button>}
    <Button color='primary' onClick={handlePrint} >Печать </Button>
    <DeleteButton
      isDisabled={obj.related}
      deleteAPI={deleteAPI}
      idDoc={match.params.idShipment}
      history={history}
    />
    <Grid
      item
      container
      xs={9}
    >
      <Grid item xs={6}><Typography>Поставщик</Typography></Grid>
      <Grid item xs={6}><Typography><Link to={'/provider/' + obj.idProvider} style={{ textDecoration: 'none' }}>{obj.provider}</Link></Typography></Grid>
      <GridProps name='Дата' value={moment(obj.date).format('LLL')} />
      <GridProps name='Статус оплаты' value={paidStatusRus || obj.paidStatusRus} />
      <GridProps name='Статус доставки' value={obj.statusRus} />
      <GridProps name='Дата доставки' value={moment(obj.dateDelivery).format('LL')} />
      <GridProps name='Добавил' value={obj.user} />
    </Grid>
    <p />
  </div>
}