import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../reducers/formTable';
import { fetchDispath } from '../../functions/fetch'
import { getOutfitFactDay } from '../../api/doc'
import { Button, Tab, Tabs, Grid, Typography, TextField } from '@material-ui/core';
import PageList from '../../components/PageListAPI'
import download from '../../functions/download'

const tableW = 'OurfitOPByIdW'
const tableWAll = 'OurfitOPByIdWAll'
const tableWE = 'OurfitOPByIdWE'
const tableWEAll = 'OurfitOPByIdWEAll'
const tableWork = 'OurfitOPByIdWork'
const tableM = 'OurfitOPByIdM'
const tableMAll = 'OurfitOPByIdMAll'

export default function OutfitDayFact ({ match, history, location }) {
  const [value, setValue] = useState(0)
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [group, setGroup] = useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.initFormTable({ name: tableWork }))
    dispatch(actions.initFormTable({ name: tableW }))
    dispatch(actions.initFormTable({ name: tableWE }))
    dispatch(actions.initFormTable({ name: tableM }))
    dispatch(actions.initFormTable({ name: tableWAll }))
    dispatch(actions.initFormTable({ name: tableWEAll }))
    dispatch(actions.initFormTable({ name: tableMAll }))
    return () => {
      dispatch(actions.clearFormTable(tableM))
      dispatch(actions.clearFormTable(tableW))
      dispatch(actions.clearFormTable(tableWE))
      dispatch(actions.clearFormTable(tableMAll))
      dispatch(actions.clearFormTable(tableWAll))
      dispatch(actions.clearFormTable(tableWEAll))
      dispatch(actions.clearFormTable(tableWork))
    }
  }, [dispatch])
  const getFact = () => {
    if (!dateStart) return;
    if (!dateEnd) return;
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
      },
      querty: {
        dateStart: dateStart,
        dateEnd: dateEnd
      },
      progress: true,
      request: getOutfitFactDay,

    }))
      .then((res) => {
        if (!res) return;

        dispatch(actions.loadFormTable({ get: res.get.work.map((e, i) => ({ id: i, ...e })) }, { name: tableWork, id: 'id' }))
        dispatch(actions.loadFormTable({ get: res.get.material }, { name: tableM, id: 'id' }))
        dispatch(actions.loadFormTable({ get: res.get.worker }, { name: tableW, id: 'id' }))
        dispatch(actions.loadFormTable({ get: res.get.workE }, { name: tableWE, id: 'id' }))

        let unicWorker = {}
        res.get.worker.forEach(obj => {
          if (unicWorker[obj.idWorker]) return
          unicWorker[obj.idWorker] = {...obj}
        });
        dispatch(actions.loadFormTable({ get: Object.values(unicWorker) }, { name: tableWAll, id: 'idWorker' }))

        let allMaterial = {}
        res.get.material.forEach(obj => {
          if (allMaterial[obj.id]) {
            allMaterial[obj.id].count += obj.count
          }
          else {
            allMaterial[obj.id] = {...obj}
          }
        })
        dispatch(actions.loadFormTable({ get: Object.values(allMaterial) }, { name: tableMAll, id: 'id' }))

        let allWorkE = {}
        res.get.workE.forEach(obj => {
          if (allWorkE[obj.id]) {
            allWorkE[obj.id].count += obj.count
          }
          else {
            allWorkE[obj.id] = {...obj}
          }
        })
        dispatch(actions.loadFormTable({ get: Object.values(allWorkE) }, { name: tableWEAll, id: 'id' }))
      })
  }
  const tableProps = [
    {
      formName: group ? tableWEAll : tableWE,
      columns: [
        !group && { id: 'date', name: 'Дата', type: 'date', formatDate: 'DD.MM.YYYY' },
        { id: 'name', name: 'Наименование' },
        { id: 'count', name: 'Количество' },
        { id: 'storageUnit', name: 'Ед.изм' }].filter(key => !!key),
    },
    {
      formName: group ? tableMAll : tableM,
      columns: [
        !group && { id: 'date', name: 'Дата', type: 'date', formatDate: 'DD.MM.YYYY' },
        { id: 'name', name: 'Наименование' },
        { id: 'count', name: 'Количество' },
        { id: 'storageUnit', name: 'Ед.изм' }].filter(key => !!key),
    },
    {
      formName: group ? tableWAll : tableW,
      columns: [
        !group && { id: 'date', name: 'Дата', type: 'date', formatDate: 'DD.MM.YYYY' },
        { id: 'worker', name: 'ФИО' },
        { id: 'position', name: 'Должность' },
      ].filter(key => !!key)
    },
    {
      formName: tableWork,
      columns: [
        { id: 'date', name: 'Дата', type: 'date', formatDate: 'DD.MM.YYYY' },
        { id: 'work', name: 'Работы' },
      ],
    },
  ]
  const formTable = useSelector(state => state.formTable)
  const handleClickCSV = () => {
    const { formName, columns } = tableProps[value]
    download(formTable[formName].allIds, formTable[formName].byId, columns.map(obj => obj.id), columns.map(obj => obj.name), formName + '.csv')
  }
  return <div className='App-paper'>
    <Typography variant='h6'>Наряд заказ факт на день</Typography>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      spacing={2}
    >
      <Grid item>
        <Button color="primary" onClick={getFact} >Сформировать</Button>
      </Grid>
      <Grid item>
        <TextField
          id="dateStart"
          name='dateStart'
          type={"date"}
          label='Дата начала'
          value={dateStart}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setDateStart(e.target.value)}
        />
      </Grid>
      <Grid item>
        <TextField
          id="dateEnd"
          name='dateEnd'
          type={"date"}
          label='Дата окончания'
          value={dateEnd}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setDateEnd(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Button color={group ? 'secondary' : 'primary'} onClick={() => setGroup(!group)}>Группировать</Button>
      </Grid>
      <Grid item>
        <Button color='primary' onClick={handleClickCSV}>Скачать</Button>
      </Grid>
    </Grid>
    <Tabs value={value} onChange={(e, v) => setValue(v)} indicatorColor='primary'>
      <Tab label='Сметные работы' />
      <Tab label='Материалы' />
      <Tab label='Работники' />
      <Tab label='Доп. Работы' />
    </Tabs>
    <PageList
      name={tableProps[value].formName}
      columns={tableProps[value].columns}
      idName='id'
      csv={false}
      click={false}
      notLoad={true}
      notClear={true}
      match={match}
      history={history}
      location={location}
      goBack={false}
      pagination={false}
      tableProps={{ pagination: false }}
    />
    <Button color='primary' onClick={() => history.goBack()}>Назад</Button>
  </div>
}