import React from 'react'
import Grid from '@material-ui/core/Grid'
import check from '../../functions/can'
import CardMenu from './source/CardMenu'

function CartProps({ arr, idProject, name }) {
  const str = (typeof idProject !== 'undefined') ? `/projects/${idProject}` : ''
  const arrNew = arr.map(obj =>
    check(obj.check) && { to: str + obj.to, primary: obj.primary, disabled: obj.disabled, add: obj.add }
  ).filter(key => typeof key !== 'boolean')
  return arrNew.length > 0 && <Grid item><CardMenu arr={arrNew} name={name} /></Grid>
}

export default function ProductionMenu(props) {
  const idProject = props.match.params.idProject
  const orders = [
    { check: 'order:read', to: '/orders/my', primary: 'Мои заказы', add: true },
  ]
  const task = [
    { check: 'taskProvider:read', to: '/form/gant', primary: 'Диаграмма ганта' },
    { check: 'taskProvider:read', to: '/form/gant/price', primary: 'Кассовый план' },
    { check: 'taskProvider:read', to: '/form/schedule', primary: 'График работ' },
    { check: 'taskProvider:read', to: '/calendar', primary: 'Календарь' },
  ]
  const machine = [
    { check: 'taskProvider:read', to: '/machine-calendar', primary: 'Календарь техники' },
    { check: 'taskProvider:read', to: '/machine-list', primary: 'Список техники' },
    { check: 'taskProvider:read', to: '/machine-workplace', primary: 'Рабочее место' },
    { check: 'taskProvider:read', to: '/waybill', primary: 'Путевые листы' },
  ]
  const outfit = [
    { check: 'taskProvider:read', to: '/outfit/plan', primary: 'Наряд заказ план', add: true },
    { check: 'taskProvider:read', to: '/outfit/fact', primary: 'Наряд заказ факт' },
    { check: 'taskProvider:read', to: '/outfit/fact/day', primary: 'Факт день' },
    { check: 'taskProvider:read', to: '/outfit/planfact/day', primary: 'План/Факт день' },
    { check: 'taskProvider:read', to: '/zhor', primary: 'Общий журнал работ' },
  ]
  const worker = [
    { check: 'worker:read', to: '/worker', primary: 'Список', add: true },
    { check: 'worker:read', to: '/team', primary: 'Бригады', add: true },
    { check: 'worker:read', to: '/worker/attent', primary: 'Присутствие' },
    { check: 'worker:read', to: '/worker/resources', primary: 'Доступные ресурсы' },
    { check: 'worker:read', to: '/analytics/worker', primary: 'Отчёт по присутствию' },
  ]
  const tool = [
    { check: 'useTool:read', to: '/usetool/transfer', primary: 'Выдача оборудования', add: true },
    { check: 'useTool:read', to: '/usetool/acceptance', primary: 'Приемка оборудования', add: true },
    { check: 'useTool:read', to: '/storage/inventory', primary: 'Список' },
  ]
  const formKs2 = [
    { check: 'work:read', to: '/form/ks2', primary: 'КС2', add: true },
    { check: 'work:read', to: '/form/ks2/table', primary: 'КС2 Сводная таблица' },
  ]
  const actRD = [
    { check: 'work:read', to: '/act/ihw', primary: 'Акт скрытых работ', add: true },
  ]
  const remarks = [
    { check: 'taskProvider:read', to: '/remarks', primary: 'Замечания', add: true },

  ]
  return <Grid container>
    <CartProps arr={task} idProject={idProject} name='Планирование работ' />
    <CartProps arr={machine} idProject={idProject} name='Техника' />
    <CartProps arr={outfit} idProject={idProject} name='Наряд на работу' />
    <CartProps arr={orders} idProject={idProject} name='Заказ материалов' />
    <CartProps arr={worker} idProject={idProject} name='Работники' />
    <CartProps arr={tool} idProject={idProject} name='Инструменты' />
    <CartProps arr={formKs2} idProject={idProject} name='Акты о приемке работ' />
    <CartProps arr={actRD} idProject={idProject} name='Акты РД' />
    <CartProps arr={remarks} idProject={idProject} name='Замечания' />
  </Grid>
}