import React from 'react';
import PageList from '../../components/PageListAPI'
import { getInventory, getInventoryEquipment } from '../../api/inventory'
import { getWorkerList } from '../../api/worker'
import { getOurStorage } from '../../api/storage'
import { loadProject } from '../../api/projects'

export default function InventoryList(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='InventoryList'
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'inventoryNumber', name: 'Инвентраный номер' },
          { id: 'serialNumber', name: 'Серийный номер' },
          { id: 'projectName', name: 'Проект' },
          { id: 'storageName', name: 'Склад' },
          { id: 'fullName', name: 'Работник' },
        ]}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getInventory,
          querty: {
            all: 1
          }
        }}
        filterProps={[
          {
            type: 'autocomplite',
            path: getWorkerList.path,
            field: 'idWorker',
            name: 'Работник',
            meta: {
              labelFunc: (obj) => obj.surname + ' ' + obj.name[0] + '.' + obj.patronymic[0],
            }
          },
          {
            type: 'autocomplite',
            path: getOurStorage.path,
            field: 'idStorage',
            name: 'Скалад',
          },
          {
            type: 'autocomplite',
            path: loadProject.path,
            field: 'idProject',
            name: 'Проект',
            meta: {
              labelFunc: (obj) => (obj.facility || '') + ' ' + obj.name,
            }
          },
          {
            type: 'autocomplite',
            path: getInventoryEquipment.path,
            field: 'idEquipment',
            name: 'Тип',
          },
        ]}
        click={false}
        // deleteAPI={api.deletePosition}
        {...props}
      />
    </div>
  )
}