import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getMaterialsList } from '../../../api/material'
import { fetchDispath } from '../../../functions/fetch'
import * as actions from '../../../reducers/form';
import {
  Table, TableBody, TableCell, TableHead, Button,
  TableRow, TableContainer, InputBase, Typography, IconButton
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import download from '../../../functions/download'

const formName = 'OrderMaterialPrice'
export default function OrderMaterialProvider({ match, history }) {
  const dispatch = useDispatch()
  const projectId = match.params.idProject
  useEffect(() => {
    dispatch(actions.formInitialize({ byId: {}, allIds: [], selected: [] }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: { id: projectId, },
      progress: true,
      request: getMaterialsList,
    })).then(res => {
      let byId = {}
      const allIds = res.get.map((v, i) => {
        const rowId = 'b' + v.id + '_' + i
        byId[rowId] = {
          id: v.id,
          pos: v.number,
          name: v.name,
          type: v.typeMark,
          code: v.vendorCode,
          su: v.storageUnit,
          balance: Math.round((v.count - v.storage - v.costs) * 1000) / 1000,
          count: Math.round((v.count - v.storage - v.costs) * 1000) / 1000
        }
        return rowId
      })
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formName }))
    })
  }, [dispatch, projectId])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byId, allIds = [], selected = [] } = form.values
  const handleSelect = (id) => {
    dispatch(actions.formArrayAdd(id, { name: formName, field: 'selected' }))
    dispatch(actions.formArrayDelete(id, { name: formName, field: 'allIds' }))
  }
  const handleChangeCount = (value, row) => {
    dispatch(actions.formObjChangeObj({ count: value }, { field: 'byId', id: row, name: formName }))

  }
  const handleDelete = (id) => {
    dispatch(actions.formArrayAdd(id, { name: formName, field: 'allIds' }))
    dispatch(actions.formArrayDelete(id, { name: formName, field: 'selected' }))
  }
  const handleClickCSV = () => {
    let sById = {}
    selected.forEach(key => {
      const obj = byId[key]
      sById[key] = {
        name: (obj.name + ' ' + obj.type + ' ' + obj.code).replaceAll(/;/g,' ').trim(),
        su: obj.su,
        count: obj.count
      }
    })
    download(selected, sById, ['name', 'su', 'count'], ['Наименование', 'ед. изм.', 'количество'], 'order.csv')
  }
  return <div className='App-paper'>
    <Typography variant='h6'>Заказать материалы</Typography>
    <TableContainer style={{ height: '41vh' }}>
      <Table size='small' stickyHeader >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 10 }} padding={'none'} />
            {['Позиция', 'Наименование', 'Тип, марка', 'Код ', 'Единица измерения', 'Количество'].map((v, i) => {
              return <TableCell key={i} style={{ minWidth: '10%' }}>{v}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {selected.map(key => {
            const obj = byId[key]
            return <TableRow key={key}>
              <TableCell style={{ width: 10 }} padding={'none'}>
                <IconButton onClick={() => handleDelete(key)}  ><DeleteIcon /></IconButton>
              </TableCell>
              {['pos', 'name', 'type', 'code', 'su'].map(v => {
                return <TableCell key={v}>{obj[v]}</TableCell>
              })}
              <TableCell>
                <InputBase value={obj.count} type='number' onChange={(e) => handleChangeCount(e.target.value, key)} />
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TableContainer style={{ height: '41vh' }}>
      <Table size='small' stickyHeader >
        <TableHead>
          <TableRow>
            {['Позиция', 'Наименование', 'Тип, марка', 'Код ', 'Единица измерения', 'Требуется заказать'].map((v, i) => {
              return <TableCell key={i} style={{ minWidth: '10%' }}>{v}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {allIds.map(key => {
            const obj = byId[key]
            return <TableRow key={key} onClick={() => handleSelect(key)}>
              {['pos', 'name', 'type', 'code', 'su', 'balance'].map(v => {
                return <TableCell key={v}>{obj[v]}</TableCell>
              })}
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
    <Button color="primary" onClick={handleClickCSV}>Печать</Button>
  </div>
}