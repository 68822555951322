import React from 'react'
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import getColor from '../../../functions/getColorAttend'

function PWATableA() {
  const table = useSelector(state => state.formTable['project-workrer-a'] || state.formTable.default)
  return (<React.Fragment>
    <Typography variant='h6'>Учтены</Typography>
    <List component="nav">
      {table.allIds.map(el => {
        const obj = table.byId[el]
        return <ListItem key={el} >
          <ListItemIcon>
            <div style={{ width: 20, height: 20, background: getColor(obj.state) }} ></div>
          </ListItemIcon>
          <ListItemText primary={obj.snp} />
        </ListItem>
      })}
    </List>
  </React.Fragment>
  )
}
export default PWATableA;