import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../reducers/form';
import { initialAutocomplite, clearAutocomplite, load as loadAutocomplite } from '../../../../reducers/autocomplite'
import { getWorkerListTool } from '../../../../api/worker'
import { addToolWrite } from "../../../../api/doc";
import { fetchDispath } from '../../../../functions/fetch'
import DocTable from '../../../../components/DocTable'
import DocAdd from '../../../../components/DocAdd'
import { getArray } from '../function'

const formName = 'AddToolWrite'
const formArr = 'worker'
const formEquipment = 'equipment'

export default function AddToolWrite({ match, history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ idWorker: '', byId: {}, allIds: [], typeWriteoff: '' }, { name: formName }))
    dispatch(initialAutocomplite({ field: formArr }))
    dispatch(initialAutocomplite({ field: formEquipment }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formArr }))
      dispatch(clearAutocomplite({ field: formEquipment }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
      },
      progress: true,
      request: getWorkerListTool,

    }))
      .then(res => {
        if (!res.success) return;
        let objWorker = {}
        res.get.forEach(obj => {
          const objEquipment = { value: obj.inventoryNumber, label: obj.inventoryNumber.toString().padStart(5, '0') + ' ' + obj.name }
          if (objWorker[obj.idWorker]) {
            objWorker[obj.idWorker].equipment.push(objEquipment)
          }
          else {
            objWorker[obj.idWorker] = {
              value: obj.idWorker,
              label: obj.worker,
              equipment: [objEquipment]
            }
          }
        })
        dispatch(loadAutocomplite(Object.values(objWorker), { field: formArr }))
      }).catch(err => console.log(err))
  }, [dispatch, match.params.idProject])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const handleSave = () => {
    const { byId, idWorker, typeWriteoff } = form.values
    let arr = Object.values(byId).filter(e => e.idInventory > 0).map(e => e.idInventory)
    dispatch(actions.formSubmitAPI({
      props: {
        idWorker: idWorker,
        type: typeWriteoff,
        arr: arr,
      },
      url: addToolWrite.path({ id: match.params.idProject }),
      method: addToolWrite.type,
      history: history
    }, formName))
  }
  const handleChangeWoreker = (e) => {
    dispatch(loadAutocomplite(Object.values(e.equipment), { field: formEquipment }))
    dispatch(actions.formChangeAsObj({ arrEquipment: e.equipment, byId: {}, allIds: [], selected: [] }, { name: formName }))
  }
  return <div className='App-paper'>
    <DocAdd
      history={history}
      formName={formName}
      title='Приемка оборудования от работника'
      handleSave={handleSave}
      noTableProps={[
        { field: 'idWorker', xs:6, label: 'Работник', type: 'autoRedux', change: handleChangeWoreker, textProps: { formArr: formArr } },
        { field: 'typeWriteoff', label: 'Тип списания', type: 'autoArr', textProps: { arr: getArray } },
      ]}
    >
      <DocTable
        formName={formName}
        column={[{
          id: 'value', type: 'auto', name: 'Оборудование', formArr: formEquipment,
          filtered: true, changeValue: text => ({ idInventory: text.value, value: text })
        }]}
        rowDefault={{
          idInventory: '',
          value: null
        }}
      />
    </DocAdd>
  </div>
}