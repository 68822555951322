import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { formInitialize, formDestroy, formSubmitAPI } from '../../../reducers/form';
import { getMaterials } from '../../../api/material'
import { addMOP } from "../../../api/doc";
// import { fetchDispath } from '../../../functions/fetch'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite, addFilterManyAutocomplite } from '../../../reducers/autocomplite'
import { enqueueSnackbar } from '../../../reducers/notifier';
import DocTable from '../../../components/DocTable'
import DocAdd from '../../../components/DocAdd'
import FilesTab from './source/FilesTab'
import moment from 'moment'
import { loadProvider, addProvider } from '../../../api/list'
import { statusAll } from './source/type.enum'
import * as actions from '../../../reducers/form';
import { TableCell, Select } from '@material-ui/core';
import { getMSN, addMSNfunction } from '../../../api/material'
import AutocompliteWithLoadCreate from '../../../components/AutocompliteWithLoadCreate'
import DialogAddEntera from './source/DialogAddEntera'
import { nds } from '../../../enum/nds.enum'
import AllPrice from '../shipmentMaterial/add/source/AllPrice'
import CompaniesAdd from '../../list/provider/ProviderAdd'

const formName = 'outfitPlan'
const formMaterial = 'material'
const formCopy = 'MOP_Copy'
const formMaterialSelected = 'materialSelected'


export default function OrderProviderAdd(props) {
  const dispatch = useDispatch()
  const idProject = props.match.params.idProject
  const [entera, setEntera] = useState(false)
  const [idEntera, setIdEntera] = useState(null)
  useEffect(() => {
    dispatch(formInitialize({
      dateOrder: moment(Date.now()).format('YYYY-MM-DD'),
      status: '',
      title: '',
      nds: '',
      idProvider: 0,
      mById: {}, // Table material
      mAllIds: [], // Table material
      fById: {}, // Table files
      fAllIds: [], // Table files
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: formMaterial }))
    return () => {
      dispatch(formDestroy({ name: formName }))
      dispatch(formDestroy({ name: formCopy }))
      dispatch(clearAutocomplite({ field: formMaterial }))
    }
  }, [dispatch])

  useEffect(() => {
    const pathMaterials = getMaterials.path({ id: idProject }) + '?type=balance'
    const metaMaterials = {
      field: formMaterial,
      value: 'id',
      hidden: ['storageUnit', 'idStorageUnit', 'storage', 'count', 'balance'],
      labelFunc: (obj) => obj.name + ' ' + obj.typeMark + ' ' + obj.vendorCode
    }
    dispatch(loadAutocompliteAPI(pathMaterials, metaMaterials))
  }, [dispatch, idProject])

  const mop = useSelector(state => state.form[formCopy] || state.form.default)
  const material = useSelector(state => state.autocomplite[formMaterial] || state.autocomplite.default)
  const [mopLoad, setMopLoad] = useState(false)

  useEffect(() => {
    if (mopLoad) return;
    if (!material.load) return;
    const { values } = mop
    if (!values.arr) return;
    let newId = 0
    let objAdd = {}
    let arrAdd = []
    let filterAdd = []
    console.log(values, material.arr)
    values.arr.forEach((obj, i) => {
      const findMaterial = material.arr.find(e => obj.id.toString() === e.value)
      console.log(findMaterial)
      if (!findMaterial) return;
      newId += 1
      objAdd['id' + newId] = {
        value: findMaterial,
        balance: findMaterial.balance,
        storageUnit: findMaterial.storageUnit,
        id: findMaterial.value,
        storage: findMaterial.storage,
        count: obj.count,
      }
      arrAdd.push('id' + newId)
      filterAdd.push({ value: findMaterial.value, id: 'id' + newId })
    })
    dispatch(addFilterManyAutocomplite(filterAdd, { field: formMaterial }))
    dispatch(actions.formChangeAsObj({
      mAllIds: arrAdd,
      mById: objAdd,
      idRow: newId
    }, { name: formName }))
    setMopLoad(true)
  }, [dispatch, mopLoad, material.load])

  const selectedMaterial = useSelector(state => state.form[formMaterialSelected] || state.form.default)
  const [selectedLoad, setSelectedLoad] = useState(typeof selectedMaterial.values.arr === 'undefined')

  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    if (selectedLoad) return;
    if (!material.load) return;
    const { arr, arrCount = [] } = selectedMaterial.values
    if (arr.length === 0) return;
    let newId = 0
    let objAdd = {}
    let arrAdd = []
    let filterAdd = []
    arr.forEach((id, i) => {
      const findMaterial = material.arr.find(e => id.toString() === e.value)
      const findMaterialCount = arrCount.find(e => id.toString() === e.id.toString())
      if (!findMaterial) return;
      newId -= 1
      objAdd['id' + newId] = {
        balance: findMaterial.balance,
        value: findMaterial,
        storageUnit: findMaterial.storageUnit,
        id: findMaterial.value,
        storage: findMaterial.storage,
        idEstimate: null,
        valueEstimate: null,
        count: (findMaterialCount) ? findMaterialCount.count : 0,
        nds: '',
        price: 0,
        summ: 0,
        summNDS: 0,
      }
      arrAdd.push('id' + newId)
      filterAdd.push({ value: findMaterial.value, id: 'id' + newId })
    })
    // dispatch(addFilterManyAutocomplite(filterAdd, { field: arrMaterial }))
    dispatch(actions.formChangeAsObj({
      mAllIds: arrAdd,
      mById: objAdd,
    }, { name: formName }))
    setSelectedLoad(true)
  }, [dispatch, material, selectedMaterial, selectedLoad])

  const handleSave = () => {
    const { mById, mAllIds, fById, dateOrder, status, idProvider, title } = values
    const arrMaterial = mAllIds.map(e => {
      const obj = mById[e]
      let ret = {
        id: obj.id,
        count: obj.count,
        price: obj.price,
        nds: obj.nds,
        nameEstimate: obj.nameEstimate,
        idEstimate: obj.idEstimate
      }
      return ret
    })
    if (mAllIds.filter(e => mById[e].count === '').length > 0) {
      dispatch(enqueueSnackbar({
        message: `не заполнено количество материалов`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(formSubmitAPI({
      props: {
        dateOrder,
        status,
        title,
        idProvider,
        material: arrMaterial,
        files: Object.values(fById).map(e => ({ id: e.id, type: e.type })),
        idProject: idProject,
        idDocOcr: idEntera
      },
      url: addMOP.path,
      method: addMOP.type,
      history: props.history
    }))
  }
  const handleChangeNDS = (id, count, price, obj) => {
    const summ = Math.round(count * price * 100) / 100
    const summNDS = Math.round(count * price * 100 * ((obj.nds === 'NDS20') ? 1.2 : 1)) / 100
    dispatch(actions.formObjChangeObj({ summ, summNDS }, { field: 'mById', id: id, name: formName }))
  }
  const getSumm = () => {
    if (!values.mAllIds) return 0
    let s = 0;
    values.mAllIds.forEach(e => {
      const obj = values.mById[e]
      s += obj.summNDS
    })
    return s;
  }
  let summ = getSumm();
  return <div className='App-paper'>
    <DocAdd
      headerButton={<DialogAddEntera idProject={idProject} formName={formName} setEntera={(id) => { setIdEntera(id); setEntera(true) }} />}
      formName={formName}
      history={props.history}
      title='Заказать материалы'
      tab={['Материалы', 'Файлы']}
      noTableProps={[
        {
          field: 'title',
          label: 'Описание',
          type: 'text',
          xs: 6
        },
        {
          field: 'idProvider', label: 'Поставщик', type: 'autoLoadCreateModal',
          textProps: {
            createAPI: addProvider,
            createBody: 'name',
            path: loadProvider.path,
            meta: {
              field: 'provider',
              value: 'id',
              labelFunc: (obj) => obj.name + ' ' + obj.inn || ''
            },
            modal:({ handleClose })=><CompaniesAdd match={props.match} modal={true} modalClose={handleClose}/>
          }, xs: 4,
        },
        {
          field: 'status',
          label: 'Статус',
          type: 'autoArr',
          textProps: {
            arr: statusAll
          }, xs: 3
        },
        {
          field: 'ndsDefault',
          label: 'НДС',
          type: 'autoArr',
          textProps: {
            arr: nds
          }, xs: 3
        },
        { field: 'dateOrder', label: 'Желаемая дата поставки', type: 'text', textProps: { type: 'date' }, xs: 3 },
      ]}
      handleSave={handleSave}
      bottom={<AllPrice summ={summ || 0} />}
    >
      <DocTable
        formName={formName}
        byIdField='mById'
        allIdsField='mAllIds'
        column={[
          entera && { id: 'nameEstimate', type: 'text', name: 'Материал поставщика', textProps: () => ({ multiline: true }), width: '25%' },
          {
            id: 'value', type: 'auto', name: 'Материал смет', formArr: formMaterial, width: '25%', filtered: true, grouped: false,
            changeValue: text => {
              return ({
                value: text,
                balance: text.balance,
                storageUnit: text.storageUnit,
                id: text.value,
                storage: text.storage,
                idEstimate: null,
                valueEstimate: null,
              })
            }
          },
          !entera && { id: 'idEstimate', type: 'component', name: 'Материал поставщика', component: MaterialComponent, componentProps: { idProject } },
          {
            id: 'count', type: 'number', name: 'Количество',
            textProps: obj => {
              return ({ error: obj.count > obj.balance, helperText: obj.count > obj.balance ? 'Больше чем в смете' : '' })
            },
            change: ({ value, id, obj }) => { handleChangeNDS(id, value, obj.price, obj) }
          },
          { id: 'storageUnit', type: 'string', name: 'Ед.изм' },
          { id: 'balance', type: 'string', name: 'По смете', width: '10%', },
          { id: 'storage', type: 'string', name: 'На складе' },
          { id: 'price', type: 'number', name: 'Цена за ед.', change: ({ value, id, obj }) => { handleChangeNDS(id, obj.count, value, obj) } },
          { id: 'nds', type: 'component', name: 'НДС', component: SelectSwitch, componentProps: { idProject } },
          { id: 'summ', type: 'string', name: 'Сумма' },
          { id: 'summNDS', type: 'string', name: 'Сумма с НДС' },
        ].filter(e => e)}
        rowDefault={{
          id: '',
          count: 0,
          storageUnit: '',
          storage: '',
          value: '',
          nds: values.ndsDefault || '',
          price: 0,
          summ: 0,
          summNDS: 0
        }}
      />
      <FilesTab formName={formName} />

    </DocAdd>
  </div>
}

const useStyles = makeStyles({
  formControl2: {
    width: 240,
    // marginTop: 5,
    // marginBottom: 3,
  },
});

function MaterialComponent({ obj, value, formName, idProject, byIdField, id }) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const handleChange = (text) => {
    dispatch(actions.formObjChangeObj({ idEstimate: text.value, valueEstimate: text, estimateName: text.label }, { field: byIdField, id: id, name: formName }))
  }
  if (!obj.id || obj.id === '') return <TableCell></TableCell>
  return <TableCell>
    <AutocompliteWithLoadCreate
      classes={{ formControl: classes.formControl2 }}
      field='idEstimate'
      name=''
      path={getMSN.path({ id: idProject, idMaterial: obj.id })}
      createAPI={addMSNfunction(idProject, obj.id)}
      createBody='name'
      change={handleChange}
      meta={{
        field: 'idEstimate' + obj.idMaterial,
        value: 'id',
        labelFunc: (obj) => obj.name
      }}
      value={{ value: obj.idEstimate || '', label: obj.estimateName || '' }}
      formName={formName}
    />
  </TableCell>
}

function SelectSwitch({ obj, value, formName, id, byIdField }) {
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const summ = Math.round(obj.count * obj.price * 100) / 100
    const summNDS = Math.round(obj.count * obj.price * 100 * ((e.target.value === 'NDS20') ? 1.2 : 1)) / 100
    dispatch(actions.formObjChangeObj({ nds: e.target.value, summ, summNDS }, { field: byIdField, id: id, name: formName }))

  }
  return <TableCell>
    <Select
      native
      value={value}
      onChange={handleChange}
      style={{ minWidth: 120 }}
    >
      {nds.map(el => {
        return <option key={el.value} value={el.value}>{el.label}</option>
      })}
    </Select>
  </TableCell>
}