import React from 'react';
import PageList from '../../components/PageListAPI'
import { getMaterialByIdHistory } from '../../api/material'
export default function MaterialHistory(props) {
  const tableHead = [
    { id: 'date', name: 'Дата', type: 'date' },
    { id: 'full', name: 'Изменил' },
    { id: 'price', name: 'Цена', align: 'right' },
  ];
  return (
    <div className='App-paper'>
      <PageList
        name='materialHistory'
        columns={tableHead}
        loadAPI={{
          request: getMaterialByIdHistory,
          param: {
            id: props.match.params.id,
            idMaterial: props.match.params.idMaterial
          },
          transform: (res => {
            return {
              count: res.count,
              get: res.get.map(obj => {
                return { ...obj, full: obj.surname + ' ' + obj.name }
              })
            }
          }),
        }}
        click={false}
        csv={false}
        {...props}
      />
    </div>
  )
}

