import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeSnackbar } from '../reducers/notifier';
import { useSnackbar } from 'notistack';
let displayed = [];
function Notifier() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // const [displayed, setDisplayed] = useState([])
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications.notifications)
  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };
  const removeDisplayed = (id) => {
    displayed = [...displayed.filter(key => id !== key)];
  };
  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        console.log('dismiss')
        closeSnackbar(key);
        return;
      }
      if (displayed.includes(key)) return;
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, key) => {
          dispatch(removeSnackbar(key));
          removeDisplayed(key)
        }
      });
      storeDisplayed(key)
    })
  }, [notifications, enqueueSnackbar, dispatch, closeSnackbar])
  return null;
}
export default Notifier;
