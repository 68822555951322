import { stopLoadRequest, startLoadRequest } from '../reducers/loading'
import { enqueueSnackbar } from '../reducers/notifier'
import queryString from 'query-string'

import { API } from '../conf'
function ResponseException(status, headers,message) {
  this.status = status
  this.headers = headers
}

export function checkStatus(response) {
  if (!response.ok) throw new ResponseException(response.status, Array.from(response.headers.entries()));
  return response;
}

export const parser = res => {
  const contentType = res.headers.get('content-type');
  if (contentType && res.headers.get('content-type')?.split(';')[0] === 'application/json') {
    return res.json()
  }
  else {
    return res
  }
}

export function errorDispath(dispatch, stopLoading = true) {
  return function (error) {
    const { status } = error
    let msg = {
      message: `Server error`,
      options: { variant: 'error' }
    }
    switch (status) {
      case 500: {
        break
      }
      case 403: {
        msg.message = 'Доступ запрещен'
        break
      }
      case 401: {
        const { headers } = error
        let type = headers.filter(value => value[0] === 'www-authenticate')
        if (type[0][1] === 'Bearer realm="Access"') console.log('logout')
        else console.log('refresh')
        msg.message = 'token expired'
        break
      }
      default: console.log(error, status)
    }
    dispatch(enqueueSnackbar(msg));
    if (stopLoading) dispatch(stopLoadRequest())
  }
}

export function successFalsefetch(dispatch, stopLoading = true) {
  if (stopLoading) dispatch(stopLoadRequest())
  let msg = {
    message: `Действие не выполнено`,
    options: { variant: 'error' }
  }
  dispatch(enqueueSnackbar(msg));
}

export function successfetch(dispatch, stopLoading = true) {
  if (stopLoading) dispatch(stopLoadRequest())
  let msg = {
    message: `success`,
    options: { variant: 'success' }
  }
  dispatch(enqueueSnackbar(msg));
}


export function fetchGenerate(res, method, body, buff = false) {
  let headers = {}
  switch (method) {
    case 'GET': {
      headers = {
        method,
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      }
      break
    }
    case 'POST':
    case 'PUT':
    case 'DELETE': {
      headers = {
        method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token'),
        },
        body: JSON.stringify(body)
      }
      break
    }
    default: { }
  }
  return fetch(API + res, {
    ...headers
  })
    .then(checkStatus)
    .then(parser)
}
/*

{
  querty:
  body:
  param:
  progress:
  request:
  transform:
  response:
}
*/
export function fetchImg(path) {
  return dispatch => {
    return fetch(API + path, {
      method: 'GET',
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'x-access-account': localStorage.getItem('accountId')
      }
    })
      .catch(errorDispath(dispatch, false))
  }
}
export function fetchDispath(obj) {
  return dispatch => {
    const search = (obj.querty) ? '?' + queryString.stringify(obj.querty) : ''
    const path = (obj.param) ? obj.request.path(obj.param) : obj.request.path
    if (obj.progress) {
      dispatch(startLoadRequest())
    }
    return fetchGenerate(path + search, obj.request.type, obj.body, dispatch, obj.progress, obj.buff)
      .then(res => {
        if (obj.progress) dispatch(stopLoadRequest())
        if (typeof obj.response !== 'undefined') {
          if (typeof obj.transform !== 'undefined') dispatch(obj.response(obj.transform(res)))
          else dispatch(obj.response(res))
        }
        if (typeof obj.transform !== 'undefined') return obj.transform(res)
        else return res
      })
      .catch(errorDispath(dispatch, !!obj.progress))
  }
}