import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableRow, TableHead,
  TableFooter, Grid, IconButton, Tooltip, Chip, Typography, Input, Button
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import * as api from '../../api/material'
import { fetchDispath } from '../../functions/fetch'
import can from '../../functions/can';
import GridProps from '../../components/GridProps'
import ChangeStorageUnit from './MaterialChangeStorageUnit'

export default function MaterialById({ match, history }) {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    number: '',
    name: '',
    vendorCode: '',
    typeMark: '',
    price: '',
    storageUnit: '',
  })
  const [openEditStorage, setOpenEditStorage] = useState(false)
  const [tagArray, setTagArray] = useState([])
  const [addTag, setAddTag] = useState(false)
  const [newTag, setNewTag] = useState('')
  const [ourStorage, setOurStorage] = useState(undefined)
  const [estStorage, setEstStorage] = useState(undefined)
  const [storage, setStorage] = useState({
    count: '0',
    balance: '0',
    storage: '0',
    costs: '0',
  })
  const idProectIdMaterial = {
    id: match.params.id,
    idMaterial: match.params.idMaterial
  }
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.id,
        idMaterial: match.params.idMaterial
      },
      progress: true,
      request: api.getMaterialById,
    }))
      .then(res => {
        setState({
          number: res.get.number,
          name: res.get.name,
          vendorCode: res.get.vendorCode,
          typeMark: res.get.typeMark,
          price: res.get.price,
          storageUnit: res.get.storageUnit,
        })
        setStorage({
          count: res.get.count,
          balance: res.get.balance,
          storage: res.get.storage,
          costs: res.get.costs,
        })
        const ourStatus = res.get.ourStatus
        const estStatus = res.get.estStatus
        if ((ourStatus.count + ourStatus.balance + ourStatus.storage) > 0) {
          setOurStorage(ourStatus)
        }
        if ((estStatus.count + estStatus.balance + estStatus.storage) > 0) {
          setEstStorage(estStatus)
        }
      }).catch(err => console.log(err))

    dispatch(fetchDispath({
      param: {
        id: match.params.id,
        idMaterial: match.params.idMaterial
      },
      progress: true,
      request: api.getMaterialByIdTag,
    })).then(res => {
      setTagArray(res.get)
    }).catch(err => console.log(err))

  }, [dispatch, match.params.id, match.params.idMaterial])
  const toLinkChange = React.forwardRef((props, ref) => (<Link ref={ref} to={match.url + '/edit'} {...props} />))
  const toLinkHistory = React.forwardRef((props, ref) => (<Link ref={ref} to={match.url + '/history'} {...props} />))
  const close = () => {
    setOpenEditStorage(!openEditStorage)
  }
  const handleDelete = id => {
    dispatch(fetchDispath({
      param: {
        ...idProectIdMaterial,
        idTag: id
      },
      progress: true,
      request: api.deleteMaterialByIdTagById,
    })).then(res => {
      if (res.success) {
        setTagArray(tagArray.filter(obj => obj.id !== id))
      }
    }).catch(err => console.log(err))
  }
  const handleAddTag = () => {
    dispatch(fetchDispath({
      param: idProectIdMaterial,
      body: {
        tag: newTag
      },
      progress: true,
      request: api.addMaterialByIdTag,
    })).then(res => {
      if (res.success) {
        setTagArray([...tagArray, res.get])
        setAddTag(false)
        setNewTag('')
      }
    }).catch(err => console.log(err))
  }
  const handleChangeTagName = e => {
    setNewTag(e.target.value)
  }
  const changeAddTag = () => {
    setAddTag(!addTag)
  }
  return (
    <div className='App-paper'>
      <Button color="primary" component={toLinkHistory}>История </Button>
      {can('material:update') && <Button color="primary" component={toLinkChange}>Изменить </Button>}
      <ChangeStorageUnit
        storageUnit={state.storageUnit}
        open={state.openEditStorage}
        close={close}
        idProject={match.params.id}
        idMaterial={match.params.idMaterial}
      />
      <Typography variant='h6'>{state.number} {state.name}</Typography>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item container xs={6}>
          <GridProps name='Тип, марка:' value={state.typeMark} />
          <GridProps name='Артикул:' value={state.vendorCode} />
          <GridProps name='Цена за еденицу:' value={state.price} />
          <Grid item xs={6}><Typography>Еденица хранения:</Typography></Grid>
          <Grid
            item
            container
            xs={6}
            spacing={2}
          >
            <Grid item><Typography>{state.storageUnit}</Typography></Grid>
            {!(state.count - state.balance > 0) && <Grid item>
              <Tooltip title="Изменить">
                <IconButton style={{ fontSize: '0.875rem' }} color='primary' size='small' onClick={(e) => close()}>
                  <EditIcon fontSize="inherit" />
                </IconButton></Tooltip>
            </Grid>
            }
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {tagArray.map(obj => {
            return <Grid item key={obj.id}>
              <Chip
                variant="outlined"
                color="primary"
                label={obj.name}
                size="small"
                onDelete={() => handleDelete(obj.id)}
              />
            </Grid>
          })}
          <Grid>
            <IconButton variant="outlined" color="primary" size="small" onClick={changeAddTag}>
              {(addTag) ? <ClearIcon /> : <AddIcon />}
            </IconButton>
          </Grid>
          {addTag && <Grid>
            <Input
              id="standard-adornment-weight"
              value={newTag}
              onChange={handleChangeTagName} />
            <IconButton variant="outlined" color="primary" size="small" onClick={handleAddTag}><DoneIcon /></IconButton>
          </Grid>}
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Вид закупки</TableCell>
            <TableCell align='right'>Количество по смете</TableCell>
            <TableCell align='right'>Поставлено</TableCell>
            <TableCell align='right'>Требуется докупить</TableCell>
            <TableCell align='right'>Расходы</TableCell>
            <TableCell align='right'>Остаток складе</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ourStorage &&
            <Row name="Наш" obj={ourStorage} />}
          {estStorage &&
            <Row name="Поставщик" obj={estStorage} />}
        </TableBody>
        <TableFooter>
          <Row name="Всего" obj={storage} />
        </TableFooter>
      </Table>
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
    </div>
  );
}

function Row({ name, obj }) {
  const count = obj.count
  const needBuy = Math.round((obj.storage + obj.costs) * 1000) / 1000
  const delivered = Math.round((obj.count - obj.storage - obj.costs) * 1000) / 1000
  const costs = obj.costs
  const storage = obj.storage
  return <TableRow>
    <TableCell>{name}</TableCell>
    <TableCell align='right'>{count}</TableCell>
    <TableCell align='right'>{needBuy}</TableCell>
    <TableCell align='right'>{delivered}</TableCell>
    <TableCell align='right'>{costs}</TableCell>
    <TableCell align='right'>{storage}</TableCell>
  </TableRow>
}