import React, { useEffect, useState, useCallback, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Button, IconButton } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function Test({ filePath = '/api/1/files/18f71ec0c3c44273074.pdf' }) {
  const [pdfDoc, setPdfDoc] = useState(null)
  const [pageNum, setPageNum] = useState(1)
  const [isLoad, setIsLoad] = useState(false)
  const [pageCount, setPageCount] = useState(1)
  const [scale, setScale] = useState(1.2)
  const [taskPage, setTaskPage] = useState(null)
  const [objPoint, setObjPoint] = useState({})
  const canvasRef = useRef(null);
  const pointRef = useRef(null);

  const loadPage = useCallback(async (pageNow, scaleNow) => {
    const page = await pdfDoc.getPage(pageNow)
    const viewport = page.getViewport({ scale: scaleNow });
    const canvas = canvasRef.current
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderTask = page.render({
      canvasContext: canvas.getContext('2d'),
      viewport: viewport
    });
    setTaskPage(renderTask)
  }, [pdfDoc])

  useEffect(() => {
    pdfjsLib.getDocument(filePath).promise.then((pdfDoc_) => {
      console.log(pdfDoc_)
      setPdfDoc(pdfDoc_)
      setPageCount(pdfDoc_.numPages);
      setIsLoad(true)
    });
  }, [filePath])

  useEffect(() => {
    if (!isLoad) return;
    loadPage(pageNum, scale)
  }, [pageNum, isLoad, loadPage, scale])

  useEffect(() => {
    if (!taskPage) return;
    taskPage.promise.then(() => {
      setTaskPage(null)
    }).catch(err => {
      console.log(err, 'err')
    })
  }, [taskPage, pageNum, scale])
  const cancelTack = () => {
    if (taskPage) {
      taskPage.cancel()
      setTaskPage(null)
    }
  }
  const handleChangePage = (add) => {
    if (add) {
      if (pageNum !== 1) {
        cancelTack()
        setPageNum(old => (old - 1))

      }
    } else {
      if (pageNum !== pageCount) {
        cancelTack()
        setPageNum(old => (old + 1))
      }
    }
  }
  const getMousePos = (canvas, evt) => {
    var rect = canvas.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    };
  }
  const handleDraw = (e) => {
    const pos = getMousePos(canvasRef.current, e);
    setObjPoint(old => ({ ...old, [pageNum]: [...(old[pageNum] || []), { x: pos.x / scale, y: pos.y / scale }] }))
  }
  const updPoint = (diffX, diffY, i) => {
    const arr = [...objPoint[pageNum]]
    arr[i] = {
      x: arr[i].x + diffX / scale,
      y: arr[i].y + diffY / scale,
    }
    setObjPoint(old => ({ ...old, [pageNum]: arr }))
  }
  const handleMouseDown = (e, i) => {
    const elem = document.getElementById('point' + i);
    const old = {
      left: parseInt(elem.style.left,10),
      top: parseInt(elem.style.top,10),
    }
    const shiftX = e.clientX - elem.getBoundingClientRect().left;
    const shiftY = e.clientY - elem.getBoundingClientRect().top;

    moveAt(e.pageX, e.pageY);

    function moveAt(pageX, pageY) {
      elem.style.left = pageX - shiftX + 'px';
      elem.style.top = pageY - shiftY + 'px';
    }

    function onMouseMove(event) {
      moveAt(event.pageX, event.pageY);
    }

    document.addEventListener('mousemove', onMouseMove);

    elem.onmouseup = function () {
      document.removeEventListener('mousemove', onMouseMove);
      elem.onmouseup = null;
      updPoint(parseInt(elem.style.left,10) - old.left, parseInt(elem.style.top,10) - old.top, i)
    };
  }
  return <div>
    <div>
      <Button onClick={() => handleChangePage(true)}>{'<'}</Button>
      <span>Страница: <span id="page_num">{pageNum}</span> / <span id="page_count">{pageCount}</span></span>
      <Button onClick={() => handleChangePage(false)}>{'>'}</Button>
      <IconButton onClick={() => { if (!taskPage) { cancelTack(); setScale(old => (old - 0.3)) } }}><ZoomOutIcon /></IconButton>
      <IconButton onClick={() => { if (!taskPage) { cancelTack(); setScale(old => (old + 0.3)) } }}><ZoomInIcon /></IconButton>
    </div>
    <canvas ref={canvasRef} onClick={handleDraw} />
    {objPoint[pageNum] && <div ref={pointRef}>{objPoint[pageNum].map((point, i) => {
      const rect = canvasRef.current.getBoundingClientRect();
      return <div
        id={'point' + i}
        key={i}
        style={{
          position: 'absolute',
          top: point.y * scale + rect.top - 4,
          left: point.x * scale + rect.left - 4,
          backgroundColor: '#0a0',
          width: 8,
          height: 8,
          border: '3px solid red',
          borderRadius: '50%',
        }}
        onMouseDown={e => handleMouseDown(e, i)}
      />
    })}</div>}
  </div>
}