import React from 'react'
import PageList from '../../components/PageListAPI'
import can from '../../functions/can'
import { deleteWorkerRating, getWorkerRatingList } from '../../api/worker'

export default function RatingList (props) {
  return <div className='App-paper'>
    <PageList
      name='workerList'
      columns={[
        { id: 'date', name: 'Дата', type: 'date', formatDate: 'LL' },
        { id: 'worker', name: 'Работник' },
        { id: 'user', name: 'Добавил' },
        { id: 'star', name: 'Оценка' },
      ]}
      createItem={can('worker:create')}
      deleteItem={can('worker:delete')}
      loadAPI={{
        request: getWorkerRatingList,
        transform: (res => {
          return {
            count: res.count,
            get: res.get.map(obj => {
              return {
                id: obj.id,
                star: obj.star,
                date: obj.date,
                worker: obj.name + ' ' + obj.surname + ' ' + obj.patronymic,
                user: obj.userName + ' ' + obj.userSurname
              }
            })
          }
        }),
      }}
      
      deleteAPI={deleteWorkerRating}
      headerInit={{
        sortBy: 'surname',
        sortDirection: 'desc',
      }}
      {...props}
    />
  </div>
}