import React, { useState, useEffect } from 'react'
import { Button, Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { fetchDispath, } from '../../functions/fetch'
import { getCertificatesById } from '../../api/certificates'
import moment from 'moment'
import GridProps from '../../components/GridProps'
import ListFile from './ListFiles'

export default function CertificatesById(props) {
  const [state, setState] = useState({
    typeDoc: '',
    name: '',
    issuedBy: '',
    dateCreate: '',
    docNumber: '',
    docDate: '',
    validTo: '',
    files: [],
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.idDoc,
      },
      progress: true,
      request: getCertificatesById(props.match.params.idProject),
    })).then(res => {
      if (res.success) {
        console.log(res.get)
        setState({
          typeDoc: res.get.typeDoc,
          name: res.get.name,
          issuedBy: res.get.issuedBy,
          dateCreate: moment(res.get.dateCreate).format('DD.MM.YYYY'),
          docNumber: res.get.docNumber,
          docDate: moment(res.get.docDate).format('DD.MM.YYYY'),
          validTo: moment(res.get.validTo).format('DD.MM.YYYY'),
          files: res.get.files
        })
      }
    }).catch(err => console.log(err))
  }, [dispatch, props.match.params.idDoc, props.match.params.idProject])
  return <div className='App-paper'>
    <Grid
      container
      spacing={1}
    >
        <GridProps name='Тип документа' typographyProps1={{ style: { fontWeight: 'bold' } }} value={state.typeDoc} xsStart={3} xsEnd={9} />
        <GridProps name='Наименование документа' typographyProps1={{ style: { fontWeight: 'bold' } }} value={state.name} xsStart={3} xsEnd={9} />
        <GridProps name='Документ выдан' typographyProps1={{ style: { fontWeight: 'bold' } }} value={state.issuedBy} xsStart={3} xsEnd={9} />
        <GridProps name='Номер документа' typographyProps1={{ style: { fontWeight: 'bold' } }} value={state.docNumber} xsStart={3} xsEnd={9} />
        <GridProps name='Дата документа' typographyProps1={{ style: { fontWeight: 'bold' } }} value={state.docDate} xsStart={3} xsEnd={9} />
        <GridProps name='Действителен до' typographyProps1={{ style: { fontWeight: 'bold' } }} value={state.validTo} xsStart={3} xsEnd={9} />
    </Grid>
    <ListFile arrFile={state.files} />
    <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
  </div>
}