import React, { useState } from 'react'
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, Table, TableHead, TableBody, TableRow, TableCell, DialogActions, Button } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment'

const useStyles2 = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
  dialogContent: {
    heigth: '30vh',
    zIndex: 2
  },
  root: {
    minHeigth: '500',
    height: '400'
  },
  selected: {
    background: '#bbbbbb'
  },
  auto: {
  }
}))
export default function DialogChangeStatus({ open, onClose, formName, load }) {
  const classes = useStyles2()
  const [selected, setSelected] = useState([])
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const column = [
    { id: 'id', name: '№' },
    { id: 'date', name: 'Дата создания', type: 'date' },
    { id: 'provider', name: 'Поставщик', },
    { id: 'title', name: 'Описание', },
  ]
  const handleClickRow = (id, select) => {
    if (select) {
      setSelected(selected.filter(e => e !== id))
    }
    else {
      setSelected([...selected, id])
    }
  }
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">Установить статус</DialogTitle>
      <DialogContent >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {column.map(e => {
                return <TableCell key={e.id}>{e.name}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {formTable.allIds.map(row => {
              const obj = formTable.byId[row]
              const select = selected.includes(obj.id)
              return <TableRow key={row}
                onClick={() => handleClickRow(obj.id, select)}
                className={classNames({ [classes.selected]: select, })}
              >
                <TableCell></TableCell>
                {column.map(e => {
                  return <TableCell key={e.id}>{(e.type === 'date') ? moment(obj[e.id]).format('DD.MM.YYYY') : obj[e.id]}</TableCell>
                })}
              </TableRow>
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { onClose(); load({ selected }) }} color='primary'>Сформировать</Button>
      </DialogActions>
    </Dialog>
  );
}