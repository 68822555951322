import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import DocTable from '../../components/DocTable'
import DocAdd from '../../components/DocAdd'
import { getUserList } from '../../api/users'
import { fetchDispath } from '../../functions/fetch'
import * as api from '../../api/workdoc'
import { formInitialize, formDestroy, formChangeAsObj } from '../../reducers/form';
import { addFilterAutocomplite } from '../../reducers/autocomplite'

const formName = 'workDocUpdAccess'
const arrUser = 'user'
export default function WorkDocProps ({ match, history }) {
  const [stopLoad, setStopLoad] = useState(false)
  const [loadUser, setLoadUser] = useState([])
  const dispatch = useDispatch()
  const docId = match.params.idDoc
  const autoUser = useSelector(state => state.autocomplite[arrUser] || state.autocomplite.default)
  const isLoadUser = autoUser.load
  const listUser = autoUser.arr
  useEffect(() => {
    dispatch(formInitialize({ byId: {}, allIds: [] }, { name: formName }))
    dispatch(initialAutocomplite({ field: arrUser }))
    return () => {
      dispatch(formDestroy({ name: formName }))
      dispatch(clearAutocomplite(arrUser))
    }
  }, [dispatch])
  // load arr
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getUserList.path, {
      field: arrUser,
      value: 'id',
      labelFunc: (obj) => obj.name + ' ' + obj.surname
    }))
  }, [dispatch])
  useEffect(() => {
    if (!(isLoadUser && !stopLoad)) return;
    dispatch(fetchDispath({
      param: { id: docId, },
      progress: true,
      request: api.getWorkDocByIdAccess,
    })).then(res => {
      setStopLoad(true)
      let byId = {}
      let allIds = []
      let arrIds = []
      res.get.forEach((obj, i) => {
        arrIds.push(obj.id)
        const id = 'id' + obj.id + '_' + i
        const value = listUser.find(e => e.value === obj.id.toString())
        if (value) { dispatch(addFilterAutocomplite(value.value, { field: arrUser, id: id })) }
        byId[id] = { idUser: obj.id, valueUser: value }
        allIds.push(id)
      })
      dispatch(formChangeAsObj({ byId, allIds }, { name: formName }))
      setLoadUser(arrIds)
    }).catch(err => console.log(err))

  }, [dispatch, isLoadUser, stopLoad, listUser, docId])

  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const saveServer = () => {
    const arrRes = Object.values(values.byId).map(e => Number(e.idUser))
    let delUser = loadUser.filter(x => !arrRes.includes(x));
    let addUser = arrRes.filter(x => !loadUser.includes(x));
    dispatch(actions.formSubmitAPI({
      props: {
        addUser,
        delUser
      },
      url: api.updWorkDocProps.path({ id: docId }),
      method: api.updWorkDocProps.type,
      history: history
    }, formName))
  }
  return <div className='App-paper'>
    <DocAdd
      formName={formName}
      history={history}
      title='Изменить настройки'
      handleSave={saveServer}
    >
      <DocTable
        formName={formName}
        column={[
          {
            id: 'valueUser', type: 'auto', name: 'Пользователь', formArr: arrUser,
            filtered: true,
            changeValue: e => ({
              idUser: e.value,
              valueUser: e,
            }),
          },
        ]}
        rowDefault={{
          idUser: '',
          valueUser: null,
        }}
      />
    </DocAdd>
  </div>
}