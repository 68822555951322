export const loadState = () => {
  try {
    const state = {
      role: localStorage.getItem('role') || '',
      id: localStorage.getItem('id') || '',
      name: localStorage.getItem('role') || '',
      refreshToken: localStorage.getItem('refreshToken') || '',
      token: localStorage.getItem('token') || '',
      accountId: localStorage.getItem('accountId') || '',
      accountName: localStorage.getItem('accountName') || '',
    }
    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    localStorage.clear();
    if (state.id) localStorage.setItem('id', state.id);
    if (state.token) localStorage.setItem('token', state.token);
    if (state.refreshToken) localStorage.setItem('refreshToken', state.refreshToken);
    if (state.role) localStorage.setItem('role', state.role);
    if (state.project) localStorage.setItem('project', state.project);
    if (state.accountId) localStorage.setItem('accountId', state.accountId)
    if (state.accountName) localStorage.setItem('accountName', state.accountName)
    if (state.g) localStorage.setItem('g', state.g)
  } catch (err) {
    console.log(err)
  }
};

export const changeToken = (state) => {
  try {
    if (state.token) localStorage.setItem('token', state.token);
    if (state.refreshToken) localStorage.setItem('refreshToken', state.refreshToken);
  } catch (err) {
    console.log(err)
  }
};
