import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide  } from '@material-ui/core'
import AutoComplite from '../../../../components/AutocompliteWithLoad'
import { getUserNotProject } from '../../../../api/users'
import { formSubmitAPI } from '../../../../reducers/form'
import { addRowFormTable } from '../../../../reducers/formTable'
import { addUserDeleteProject } from '../../../../api/users'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 100,
  },
  main: {
    margin: theme.spacing(1)
  },
  paperOpen: {
    minHeight: 500,
  },
  paperClose: {
  },
  autocomplite: {
    minWidth: 300,
  },
}));

export default function AddProjectToUser({ open, hangleOpen, idUser }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openMenu, setOpenMenu] = useState(false)
  const autocomplite = useSelector(state => state.autocomplite.idProject || state.autocomplite.default)
  const handleClose = (add) => {
    if (add === true && autocomplite.value.value) {
      dispatch(formSubmitAPI({
        props: {
          addProject: [autocomplite.value.value]
        },
        url: addUserDeleteProject.path({ id: idUser }),
        method: addUserDeleteProject.type,
        success: addRowFormTable({
          id: autocomplite.value.value,
          facility: autocomplite.value.facility,
          project: autocomplite.value.project,
        }, { name: 'userProject' })
      }))
      hangleOpen(true)
    }
    else {
      hangleOpen(false)
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={hangleOpen}
      maxWidth='sm' fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ className: openMenu ? classes.paperOpen : classes.paperClose }}
    >
      <DialogTitle id="alert-dialog-slide-title">Добавить проект</DialogTitle>
      <DialogContent className={classes.autocomplite}>
        <AutoComplite
          classes={classes}
          field='idProject'
          name='Проект'
          path={getUserNotProject.path({ id: idUser })}
          meta={{
            field: 'idProject',
            value: 'id',
            hidden: ['facility', 'project'],
            labelFunc: (obj) => obj.facility + ' ' + obj.project
          }
          }
          selectProps={{
            onMenuOpen: () => setOpenMenu(true),
            onMenuClose: () => setOpenMenu(false)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Отмена
        </Button>
        <Button onClick={() => handleClose(true)} color="primary" autoFocus>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>)
}