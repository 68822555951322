import { getProjectId, getProjectIdById, deleteProjectById, postProjectId } from "./template_crud";
const ks2 = '/form/ks2';
export const getKS2 = getProjectId(ks2);
export const getKS2NotWork = getProjectId(ks2 + '/notwork');
export const getKS2Table = getProjectId(ks2 + '/list');
export const addKS2 = postProjectId(ks2);
export const getKS2ById = getProjectIdById(ks2);
export const printKS2ById = (id) => ({
  path: (obj) => `/projects/${id}${ks2}/${obj.id}/print`,
  type: 'GET'
});
export const delKS2ById = deleteProjectById(ks2);

const ks3 = '/form/ks3';
const path =  (obj,pathEnd='') => `/facility/${obj.id}${ks3}${pathEnd}` 
export const getKS3 = {
  path: (obj) => path(obj),
  type: 'GET'
};
export const getKS3NotWork = {
  path: (obj) => path(obj,'/notwork'),
  type: 'GET'
};
export const addKS3 = {
  path: (obj) => path(obj),
  type: 'POST'
};;
export const getKS3ById = (id) => ({
  path: (obj) => `/facility/${id}${ks3}/${obj.id}`,
  type: 'GET'
});
export const printKS3ById = (id) => ({
  path: (obj) => `/facility/${id}${ks3}/${obj.id}/print`,
  type: 'GET'
});
export const delKS3ById = (id) => ({
  path: (obj) => `/facility/${id}${ks3}/${obj.id}`,
  type: 'DELETE'
});;