import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core';
import * as actions from '../../../reducers/form';
import { typequipment, getEquipmentById, updEquipment } from '../../../api/list'
import { fetchDispath } from '../../../functions/fetch'
import { set as setAutocomplite } from '../../../reducers/autocomplite'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 220,
    marginTop: 5,
    marginBottom: 3,
    width: '50%',
    overflowX: 'visible'

  },
}))
export default function EquipmentEdit(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const formName = 'EquipmentEdit'
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.id,
      },
      progress: true,
      request: getEquipmentById,
    }))
      .then(res => {
        dispatch(actions.formInitialize({
          name: res.get.name,
          idType: res.get.idType,
          typeE: res.get.typeE,
          storageUnit: res.get.storageUnit
        }, { name: formName }))
        dispatch(setAutocomplite({ value: res.get.idType + '', label: res.get.typeE }, { field: 'idType' }))
      })
      .catch(err => console.log(err))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch, props.match.params.id])
  const arrProps = [
    {
      field: 'name', type: 'text', label: 'Наименование',
      validator: [value => value !== ''], errorText: 'Поле обязательно',
    },
    {
      field: 'storageUnit', label: 'Еденица хранения', type: 'text', textProps: { disabled: true }
    },
    {
      field: 'idType', label: 'Тип', type: 'autoLoadCreate',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        createAPI: typequipment.addOne,
        createBody: 'name',
        path: typequipment.getList.path,
        meta: {
          field: 'idType',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
  ]
  const handleUpdate = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        name: values.name,
        idType: values.idType,
      },
      url: updEquipment.path({
        id: props.match.params.id,
        idMaterial: props.match.params.idMaterial
      }),
      method: updEquipment.type,
      history: props.history
    }, formName))
  };
  return (
    <div className='App-paper' >
      <form autoComplete="off" onSubmit={handleUpdate} className={classes.main}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd formName={formName} arr={arrProps} title='Изменить оборудование' />
        </div>
        <div className={classes.main}>
          <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
          <Button type="submit" color="primary">Изменить</Button>
        </div>
      </form>
    </div>
  );
}
