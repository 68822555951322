import React from 'react';
import { addOurStorageMaterialList, addOurStorageMaterialDelete } from '../../../../api/storage'
import PageList from '../../../../components/PageListAPI'

export default function SmaList(props) {
  return <div className='App-paper'>
    <PageList
      name='SmaLlist'
      columns={[
        { id: 'id', name: '№', },
        { id: 'storageSpace', name: 'Склад', },
        { id: 'user', name: 'Добавил', },
        { id: 'dateAdd', name: 'Дата добавления', type: 'date' },
      ]}
      createItem={true}
      deleteItem={true}
      idName='id'
      history={props.history}
      match={props.match}
      location={props.location}
      loadAPI={{
        request: addOurStorageMaterialList,
      }}
      deleteAPI={addOurStorageMaterialDelete}
      headerInit={{
        sortBy: 'dateAdd',
        sortDirection: 'desc',
      }}
    />
  </div>
}