import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Star, StarBorder } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { formChange } from '../../../reducers/form'

export default function StarChange ({ formName }) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const [over, setOver] = useState(0)
  const hangleChange = (i) => {
    dispatch(formChange(i, { field: 'star', name: formName }))
  }
  let item = []
  for (let i = 1; i < 6; i++) {
    let icon = <StarBorder />
    if (i <= values.star || i <= over) {
      icon = <Star />
    }
    item.push(<IconButton
      color='primary'
      disableRipple={true}
      key={i}
      onClick={() => hangleChange(i)}
      onMouseOver={() => setOver(i)}
      onMouseLeave={() => setOver(0)}
    >{icon}</IconButton>)
  }
  return <div>{item}</div>
}