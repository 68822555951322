import React from 'react'
import { Dialog, DialogTitle, TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../../../reducers/form';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '70%',
    maxHeight: 435,
  },
}));
export default function DialogItemSerialNum({ formName }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const { openDialog = undefined, byId = {}, dialogId = '' } = values
  const onClose = () => {
    dispatch(actions.formChange(false, { field: 'openDialog', name: formName }))
  }
  const handleChange = (e) => {
    const count = e.target.value.split('\n').length
    if (count > byId[dialogId].count) return;
    dispatch(actions.formObjChangeObj({
      serialNumber: e.target.value
    }, { field: 'byId', id: dialogId, name: formName }))
  }
  if (typeof openDialog === 'undefined') return null
  const obj = byId[dialogId] || {}
  return (<Dialog anchor='top' open={openDialog} onClose={onClose} classes={{ paper: classes.paper }}>
    <div className='App-paper'>
      <DialogTitle id="simple-dialog-title">{obj.name || ''}</DialogTitle>
      <TextField
        id="outlined-multiline-flexible"
        label="Серийные номера"
        multiline
        rowsMax={obj.count}
        rows={obj.count}
        value={obj.serialNumber}
        onChange={handleChange}
        fullWidth
      />
    </div>
  </Dialog>);
}