import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import moment from 'moment'
import 'moment/locale/ru';
import { addOurStorageMaterialById } from '../../../../api/storage'
import PageList from '../../../../components/PageListAPI'
import GridProps from '../../../../components/GridProps'



const formName = 'SmaById'
export default function SmaById({ match, history, location }) {
  return (
    <div className='App-paper'>
      <PageList
        name={formName}
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'count', name: 'Количество' },
          { id: 'storageUnit', name: 'Ед. изм.' },
        ]}
        loadAPI={{
          request: addOurStorageMaterialById,
          param: {
            id: match.params.idDoc
          },
          transform: (res => {
            return {
              count: res.get.length,
              get: res.get,
              obj: res.doc
            }
          }),
        }}
        headerInit={{
          obj: {
            storageSpace: '',
            user: '',
            dateAdd: ''
          }
        }}
        tableProps={{
          pagination: false
        }}
        pagination={false}
        click={false}
        headerLink={<Header formName={formName} />}
        csv={false}
        match={match}
        history={history}
        location={location}
      />
    </div>
  )
}

function Header({ formName }) {
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { storageSpace = '', user = '', dateAdd = '' } = formTable.obj
  return <Grid item container xs={9}>
    <GridProps name='Склад' value={storageSpace} />
    <GridProps name='Добавил' value={user} />
    <GridProps name='Дата добавления' value={moment(dateAdd).format('LLL')} />
  </Grid>
}