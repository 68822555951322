import React, { useState, useEffect, useCallback } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux';
import { Button, Popover, Typography } from '@material-ui/core'
import * as actions from '../../reducers/formTable';
import * as actions2 from '../../reducers/form';
import * as actionAuto from '../../reducers/autocomplite'
import { fetchDispath } from '../../functions/fetch'
import { getEstimate, getGroup } from '../../api/estimate'
// import EstimateCreateLink from './EstimateCreateLink'
import TableGroup from './GroupTable'
import EstimateTable from './EstimateTable'
import DialogAddEditGroup from './DialogAddEditGroup'
import CreateShareLink from '../../components/CreateShareLink'


const formName = 'EstimateList'
const formGName = 'groupEstimate'
const formRName = 'groupResEstimate'
const formDialog = 'dialogEL'
const autoGroup = 'group'
export default function EstimateList({ match }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [estimateVisible, setEstimateVisible] = useState(false)
  const [changeGroup, setChangeGroup] = useState(false)
  const dispatch = useDispatch()
  const header = [
    { name: 'Номер', id: 'number' },
    { name: 'Тип', id: 'type' },
    { name: 'Наименование', id: 'name' },
    { name: 'Тип, марка', id: 'typeMark' },
    { name: 'Артикул', id: 'vendorCode' },
    { name: 'Ед.изм.', id: 'storageUnit' },
    { name: 'Количество', align: 'right', id: 'count' },
    { name: 'Цена', align: 'right', id: 'price' },
    { name: 'Сумма', align: 'right', id: 'summ' },
  ]
  useEffect(() => {
    dispatch(actions.initFormTable({ name: formName }))
    dispatch(actions.initFormTable({ name: formGName }))
    dispatch(actions.initFormTable({ name: formRName }))
    dispatch(actions2.formInitialize({
      selectedEstimate: [],
      selectedEstimateById: {},
      selectedGroup: null,
      summEstimate: 0,
      summGroup: 0
    }, { name: formName }))
    dispatch(actions2.formInitialize({
      dialogGroup: true,
      dialogGroupAdd: true,
      dialogGroupName: '',
      dialogGroupNumber: 0,
      dialogGroupId: null,
      dialogGroupIdTable: null,
    }, { name: formDialog }))
    dispatch(actionAuto.initialAutocomplite({ field: autoGroup }))
    return () => {
      dispatch(actions.clearFormTable(formName))
      dispatch(actions.clearFormTable(formGName))
      dispatch(actions.clearFormTable(formRName))
      dispatch(actions2.formDestroy({ name: formName }))
      dispatch(actions2.formDestroy({ name: formDialog }))
      dispatch(actionAuto.clearAutocomplite({ field: autoGroup }))
    }
  }, [dispatch])
  const idProject = match.params.idProject
  const loadEstimate = useCallback(() => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getEstimate,
    }))
      .then(res => {
        let summ = 0
        const arr = res.get.map(e => {
          summ += Math.round(100 * e.count * Number(e.price)) / 100 || 0
          return { ...e, summ: Math.round(100 * e.count * e.price) / 100 }
        })
        batch(() => {
          dispatch(actions.loadFormTable({ count: res.count, get: arr }, { name: formName, id: 'id' }))
          dispatch(actions2.formChange(summ, { field: 'summEstimate', name: formName }))
        })
        if (res.count > 0) setEstimateVisible(true)
      }).catch(err => console.log(err))
  }, [dispatch, idProject])
  const loadGroup = useCallback(() => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getGroup,
    })).then(res => {
      const { list, group } = res
      let gobj = {}
      let summGroup = 0;
      group.forEach((el, i) => {
        gobj[el.id] = { res: [], summ: 0 };
      });
      list.forEach((el, i) => {
        gobj[el.idGroup].res.push('id' + el.id + '_' + i);
        gobj[el.idGroup].summ += Number(el.summ) || 0
        summGroup += Number(el.summ) || 0
      });
      const groupAr = res.group.map(obj => ({ value: obj.id + '', label: obj.number + '. ' + obj.name }))
      batch(() => {
        dispatch(actions.loadFormTable({ count: list.length, get: list }, { name: formRName, id: 'id' }))
        dispatch(actions.loadFormTable({
          count: group.length, get: group.map(e => {
            return { ...e, res: gobj[e.id].res, ressumm: gobj[e.id].summ }
          })
        }, { name: formGName, id: 'id' }))
        dispatch(actionAuto.load(groupAr, { field: autoGroup }))
        dispatch(actions2.formChange(summGroup, { field: 'summGroup', name: formName }))
      })
    }).catch(err => console.log(err))
  }, [dispatch, idProject])
  useEffect(() => {
    loadGroup()
    loadEstimate()
  }, [dispatch, loadGroup, loadEstimate])
  const summEstimate = useSelector(state => state.form[formName]?.values.summEstimate)
  const summGroup = useSelector(state => state.form[formName]?.values.summGroup)
  return <div className='App-paper'>
    <DialogAddEditGroup idProject={idProject} changeGroup={changeGroup} onClose={() => setChangeGroup(false)} />
    <Typography variant='h5'>{'Всего по смете :' + (Math.round(100 * (summEstimate + summGroup)) / 100).toLocaleString(undefined,{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</Typography>
    <Button color="primary" onClick={() => setChangeGroup(true)}>Изменить группу</Button>
    <Button onClick={e => setAnchorEl(e.currentTarget)} color="primary">Доступ по ссылке</Button>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={() => setAnchorEl(null)}
    >
      <CreateShareLink idProject={idProject} type={'estimate'} />
    </Popover>
    <TableGroup
      tableHead={header}
      idProject={idProject}
      loadEstimate={loadEstimate}
      loadGroup={loadGroup}
      formName={formName}
    />
     <EstimateTable
      tableHead={header}
      idProject={idProject}
      reloadWhenAddToGroup={loadGroup}
      loadEstimate={loadEstimate}
      changeVisible={() => setEstimateVisible(false)}
      formName={formName}
    />
  </div>
}