import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../reducers/form';
import ZoomOutOutlinedIcon from '@material-ui/icons/ZoomOutOutlined';
import ZoomInOutlinedIcon from '@material-ui/icons/ZoomInOutlined';
import { typeGant } from '../../enum/gant.enum'
import moment from 'moment'
import 'moment/locale/ru';
import { Button, IconButton } from '@material-ui/core';
import {
  COLUMNS_OBJ,
  columnsObjTransformView,
  columnsObjTransform, columnsObjTransformParent,
} from '../gant/gant.const'
const useStyles = makeStyles(theme => ({
  sticky: {
    position: 'sticky',
    top: 0,
  },
  grid_row: {
    fill: '#ffffff',
    "&:nth-child(2n)": {
      fill: "#f5f5f5"
    },
    '&:hover $arrow_circle': {
      visibility: 'visible',
    },
    '&:hover $triangle': {
      visibility: 'visible',
    }
  },
  textProps: {
    pointerEvents: "none"
  },
  rectProps: {
    fill: '#ffffff',
    fillOpacity: '0.0',
    "&:hover": {
      fill: "#C0C0C0",
      fillOpacity: '1'
    }
  },

  grid_rect_props: {
    cursor: 'pointer'
  },
  unselectable: {
    '-webkit-touch-callout': 'none', /* iOS Safari */
    '-webkit-user-select': 'none',   /* Chrome/Safari/Opera */
    '-khtml-user-select': 'none',    /* Konqueror */
    '-moz-user-select': 'none',      /* Firefox */
    '-ms-user-select': 'none',       /* Internet Explorer/Edge */
    'user-select': 'none'           /* Non-prefixed version, currently
                                    not supported by any browser */
  },
  arow: {
    zIndex: 1
  },
  triangle: {
    cursor: 'ew-resize',
    visibility: 'hidden',
    fill: "#ddd"
  },
  arrow_circle: {
    cursor: 'pointer',
    visibility: 'hidden',
    fill: "#ddd",
    '&:hover': {
      fill: 'yellow',
      stroke: 'black',
      strokeWidth: "2"
    }
  },
  block: {
    '&:hover $arrow_circle': {
      visibility: 'visible',
    },
    '&:hover $triangle': {
      visibility: 'visible',
    }
  },
}));
let svgProps = {
  nameWidth: 300,
  startWidth: 150,
  endWidth: 150,
  sizeDay: 30,
  sizeWeek: 70,
  sizeMonth: 80,
  startDiag: 300,
  yOffset: 85,
  yPading: 50,
  heightRec: 25
}
const moveTypes = {
  progress: 'progress',
  date: 'date',
  dateParent: 'date_parent',
  dateMilestone: 'date_milestone',
  endDate: 'endDate',
  startDate: 'startDate',
  arrowStart: 'arrowStart',
  arrowEnd: 'arrowEnd'
}
const listener = (evt) => {
  const offsetY = 57
  const y = screenYtoSVGUnits(window.scrollY > offsetY ? window.scrollY - offsetY : 0)
  document.getElementById("sticky-rect")
    .setAttributeNS(null, "transform", `translate(0,${y})`)
  document.getElementById("sticky-rect-table")
    .setAttributeNS(null, "transform", `translate(0,${y})`)
}
const listenerTable = (evt) => {
  const offsetY = 57
  const offsetX = 0
  const y = screenYtoSVGUnits(window.scrollY > offsetY ? window.scrollY - offsetY : 0)
  const x = screenXtoSVGUnits(window.scrollX > offsetX ? window.scrollX - offsetX : 0)
  document.getElementById("sticky-rect")
    .setAttributeNS(null, "transform", `translate(0,${y})`)
  document.getElementById("sticky-rect-table")
    .setAttributeNS(null, "transform", `translate(${x},${y})`)
  document.getElementById("sticky-work")
    .setAttributeNS(null, "transform", `translate(${x},0)`)
}
function screenYtoSVGUnits(val) {
  const svg = document.getElementById("svg-full");
  let pt = svg.createSVGPoint();
  pt.x = 0;
  pt.y = val;
  pt = pt.matrixTransform(svg.getCTM().inverse());
  return pt.y;
}
function screenXtoSVGUnits(val) {
  const svg = document.getElementById("svg-full");
  let pt = svg.createSVGPoint();
  pt.x = val;
  pt.y = 0;
  pt = pt.matrixTransform(svg.getCTM().inverse());
  return pt.x;
}
export default function GrandView({ fixTable, allIds = [], byId = {}, formDialog, start, end, count, formName, idProject, formView, setCountSelect, formViewProps }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const viewBoxWidth = 1000;
  const viewBoxHeight = 400;
  const [percent, setPercent] = useState(130)
  useEffect(() => {
    if (fixTable) {
      window.removeEventListener("scroll", listenerTable);
      window.addEventListener("scroll", listener);
      document.getElementById("sticky-rect-table")
        .setAttributeNS(null, "transform", `translate(0,0)`)
      document.getElementById("sticky-work")
        .setAttributeNS(null, "transform", `translate(0,0)`)
    }
    else {
      window.removeEventListener("scroll", listener);
      window.addEventListener("scroll", listenerTable);
    }
    return () => {
      if (fixTable) {
        window.removeEventListener("scroll", listener);
      }
      else {
        window.removeEventListener("scroll", listenerTable);
      }
    }
  }, [fixTable])

  useEffect(() => {
    const columnsArr = ['selected', 'pos', 'estNumber', 'name', 'start', 'end', 'count', 'storageUnit','price','sum','perf','sumPerf', 'avgCount', 'avgCountFact']
    const columnsObj = {
      selected: { hide: false, name: '', widthMin: 50, width: 50, type: 'number', value: 'selected', align: 'center' },
      pos: { hide: false, name: 'Позиция', widthMin: 70, width: 70, type: 'number', value: 'pos', align: 'center' },
      estNumber: { hide: false, name: '№ смета', widthMin: 70, width: 70, type: 'number', value: 'estNumber', align: 'center' },
      name: { hide: false, name: 'Наименование', widthMin: 400, width: 400, type: 'string', value: 'name', align: 'left' },
      start: { hide: false, name: 'Дата начала', widthMin: 130, width: 130, type: 'date', value: 'start', align: 'center' },
      end: { hide: false, name: 'Дата окончания', widthMin: 130, width: 130, type: 'date', value: 'end', align: 'center' },
      count: { hide: false, name: 'Количество', widthMin: 100, width: 100, type: 'number', value: 'count', align: 'center' },
      storageUnit: { hide: false, name: 'Ед. изм.', widthMin: 80, width: 80, type: 'string', value: 'storageUnit', align: 'center' },
      price: { hide: false, name: 'Цена', widthMin: 100, width: 100, type: 'numberLocal', value: 'price', align: 'center' },
      sum: { hide: false, name: 'Сумма', widthMin: 100, width: 100, type: 'numberLocal', value: 'sum', align: 'center' },
      perf: { hide: false, name: 'Выполнено', widthMin: 100, width: 100, type: 'number', value: 'perf', align: 'center' },
      sumPerf: { hide: false, name: 'Сумма(выполнено)', widthMin: 140, width: 140, type: 'numberLocal', value: 'sumPerf', align: 'center' },
      avgCount: { hide: false, name: 'Выполнение сутки, план', widthMin: 170, width: 170, type: 'numberLocal', value: 'avgCount', align: 'center' },
      avgCountFact: { hide: false, name: 'Выполнение сутки, факт', widthMin: 170, width: 170, type: 'numberLocal', value: 'avgCountFact', align: 'center' },
    }
    let startDiag = 0
    columnsArr.forEach(key => {
      startDiag += columnsObj[key].width
    })
    dispatch(actions.formInitialize({
      columnsArr: columnsArr,
      columnsObj: columnsObj,
      columnsArrHide: [],
      startDiag: startDiag, // !!!!summ columnObj.width
      planVisible: true,
    }, { name: formViewProps }))
    return () => {
      dispatch(actions.formDestroy({ name: formViewProps }))

    }
  }, [dispatch, formViewProps])
  const [zoom, setZoom] = useState('day')
  const form2 = useSelector(state => state.form[formViewProps] || state.form.default)
  const { startDiag = 800 } = form2.values
  const widthSVG = viewBoxWidth + svgProps.sizeDay * (count + 10)
  const heightSVG = viewBoxHeight + (allIds.length + 1) * svgProps.yPading
  return <div>
    <div
      style={{
        position: 'sticky'
      }}
    >
      <IconButton color='primary' onClick={() => setPercent(percent + 5)}><ZoomOutOutlinedIcon /></IconButton>
      <IconButton color='primary' onClick={() => setPercent(percent - 5)}><ZoomInOutlinedIcon /></IconButton>
      <Button color={zoom === 'day' ? 'primary' : 'secondary'} onClick={() => setZoom('day')}>День</Button>
      <Button color={zoom === 'week' ? 'primary' : 'secondary'} onClick={() => setZoom('week')}>Неделя</Button>
      <br />
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id='svg-full'
      width={widthSVG}
      height={heightSVG}
      viewBox={`0 0 ${widthSVG * (percent / 100)} ${heightSVG * (percent / 100)}`}
      style={{ overflowX: 'auto', overflowY: 'auto' }}
    >
      <g id='ds' transform={`translate(0,60)`} >
        <BlockRect formName={formName} classes={classes} svgProps={svgProps} startDiag={startDiag} />
      </g>
      <GridTable start={start} end={end} svgProps={svgProps} startDiag={startDiag} heightSVG={heightSVG} zoom={zoom} />
      <g id='ds' transform={`translate(0,60)`} >
        <ClipPathName length={allIds.length} formViewProps={formViewProps} />
        <ArrowAll formName={formName} startDiag={startDiag} zoom={zoom} />
        <BlocView
          formName={formName}
          classes={classes}
          formDialog={formDialog}
          formView={formView}
          startDiag={startDiag}
          zoom={zoom}
          formViewProps={formViewProps}
        />
        <PropsView formView={formView} />
        <BlockTable
          formName={formName}
          classes={classes}
          formDialog={formDialog}
          startDiag={startDiag}
          setCountSelect={setCountSelect}
          formViewProps={formViewProps}
        />
      </g>
      <DateLine start={start} end={end} svgProps={svgProps} startDiag={startDiag} heightSVG={heightSVG} zoom={zoom} />
      <TableName formViewProps={formViewProps} />
      <path id='arrow-add-all' startpoint='m 0 0' d='m 0 0'
        stroke="orange"
        strokeWidth={3}
        strokeDasharray="10,10"
        fill="none" />
    </svg>
  </div>
}

// Get point in global SVG space
function cursorPoint(e) {
  // pt.x = evt.clientX; pt.y = evt.clientY;
  var svg = document.getElementById('svg-full')
  var point = svg.createSVGPoint();
  point.x = e.clientX;
  point.y = e.clientY;
  var ctm = svg.getScreenCTM();
  var inverse = ctm.inverse();
  return point.matrixTransform(inverse);
}
// всплывашка при наведении  
function PropsView({ formView }) {
  const form = useSelector(state => state.form[formView] || state.form.default)
  const { obj, open = false, x, y, height } = form.values
  if (!open) return null
  const widthB = 240
  const heightB = 180
  const xView = 0
  const yView = svgProps.heightRec
  var loc = cursorPoint({ clientX: x, clientY: y });
  const props = {
    progress: { name: 'Прогресс' },
    perf: { name: 'Выполнено' }
  }
  const objView = columnsObjTransformView(obj)
  const objLabel = { ...COLUMNS_OBJ, ...props }
  let arr = []
  switch (obj.typeGant) {
    case 'material':
    case 'work':
    case 'textField':
      arr = ['start', 'end', 'progress', 'perf','sum', 'sumPerf', 'avgCount', 'avgCountFact']
      break;
    case 'parent':
      arr = ['start', 'end','sum', 'sumPerf']
      break;
    case 'milestone':
      arr = ['start']
      break;
    case 'link':
      arr = ['start', 'end', 'progress']
      break;
    default:
      break;
  }
  return <g transform={`translate(${loc.x},${height + svgProps.heightRec * 2})`} visibility={open ? 'visible' : 'hidden'}>
    <rect
      x={xView}
      y={yView}
      width={widthB}
      height={heightB}
      fill="#F0F8FF"
      fillOpacity={0.8}
      stroke='black'
    />
    <text
      x={5}
      y={yView + 15 + 0 + 30 * 0}
      dominantBaseline="middle"
      clipPath='url(#nameChip2)'
    >{obj.name}
    </text>
    {arr.map((key, i) => {
      const value = objView[key]
      const label = objLabel[key]?.name
      return <text
        key={key}
        x={5}
        y={yView + 15 + 30 * (1 + i)}
        clipPath='url(#nameChip2)'
        dominantBaseline="middle"
      >{`${label}: ${value}`}
      </text>
    })}
    <clipPath id="nameChip2">
      <rect x={xView} y={yView} width={widthB} height={heightB} />
    </clipPath>
  </g>
}
// Отсекает область наименование
function ClipPathName({ length, formViewProps }) {
  const form = useSelector(state => state.form[formViewProps] || state.form.default)
  const { columnsArr = [], columnsObj = {} } = form.values
  if (!columnsObj.name || columnsObj.name.hide) return null;
  let width = 0
  let i = 0
  let condition = columnsArr[i]
  while (condition !== 'name' && i < columnsArr.length) {
    width += columnsObj[condition].width
    i += 1;
    condition = columnsArr[i]
  };
  return <clipPath id="nameChip">
    <rect x={width} y={- 15} width={columnsObj.name.width} height={svgProps.yPading * length} />
  </clipPath>
}

function BlockRect({ formName, classes, svgProps, startDiag }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { allIds = [], count = 1 } = form.values
  return allIds.map((key, i) => {
    return <g key={key} transform={`translate(0,${svgProps.yPading * i})`} className={classes.unselectable}>
      <rect key={i} x={0} y={0}
        width={startDiag + (count + 1) * svgProps.sizeDay}
        height={svgProps.yPading}
        className={classes.grid_row}
        fill={(i % 2 > 0) ? '#ffffff' : "#f5f5f5"}
      />
      <line
        x1={0}
        y1={svgProps.yPading * i}
        x2={startDiag + svgProps.sizeDay * (count + 14)}
        y2={svgProps.yPading * i}
        stroke="#e0e0e0"
      />
      <line
        x1={0}
        y1={svgProps.yPading}
        x2={startDiag + svgProps.sizeDay * (count + 14)}
        y2={svgProps.yPading}
        stroke="#e0e0e0"
      />
    </g>
  })
}

function BlockTable({ formName, classes, formDialog, startDiag, setCountSelect, formViewProps }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byId = {}, arrFilter = [] } = form.values
  // const {allIds = [], byId = {}, arrFilter = []} = form.values
  const tree = useSelector(state => state.gant)
  const allIds = tree.flat.map(e => 'id' + e.id)
  let posParent = ''
  let arrPos = []
  const dispatch = useDispatch()
  const posFind = (parent) => {
    const res = []
    const find = (par) => {
      allIds.forEach(row => {
        if (byId[row].parent === par && byId[row].typeGant === 'parent') {
          res.push(byId[row].idAPI)
          find(byId[row].idAPI)
        }
      })
    }
    find(parent)
    return res
  }
  const hideParent = (idAPI) => {
    const arr = posFind(idAPI)
    if (arrFilter.includes(idAPI)) {
      dispatch(actions.formArrayDelete(idAPI, { name: formName, field: 'arrFilter' }))
      arr.forEach(key => dispatch(actions.formArrayDelete(key, { name: formName, field: 'arrFilter' })))
    }
    else {
      dispatch(actions.formArrayAddMany([idAPI, ...arr], { name: formName, field: 'arrFilter' }))
    }
  }
  const arrFiltered = allIds.filter(key => !arrFilter.includes(byId[key]?.parent))
  return <g id="sticky-work">
    {arrFiltered.map((key, i) => {
      const obj = byId[key]
      let pos = ''
      let color = '#ffffff'
      if (posParent !== obj.parent && arrPos.length > 0) {
        do {
          arrPos.pop()
        }
        while (arrPos.length > 0 && arrPos[arrPos.length - 1].id !== obj.parent)
        if (obj.parent) {
          posParent = obj.parent
        }
      }
      pos = [...arrPos.map(e => e.pos), obj.pos].join('.')
      if (!!obj.parent) {
        color = (!!byId['id' + obj.parent]) ? byId['id' + obj.parent].color : '#ffffff'
      }
      if (obj.typeGant === 'parent') {
        arrPos.push({ pos: obj.pos, id: obj.idAPI });
        posParent = obj.idAPI
      }
      return <g
        key={key}
        id={obj.id}
        transform={`translate(0,${svgProps.yPading * i})`} className={classes.unselectable}
      >
        <rect key={i} x={0} y={0}
          width={startDiag}
          height={svgProps.yPading}
          fill={(i % 2 > 0) ? '#ffffff' : "#f5f5f5"}
        />
        <WorkBlock
          formDialog={formDialog}
          obj={obj}
          id={key}
          classes={classes}
          startDiag={startDiag}
          pos={pos}
          color={color}
          arrFilter={arrFilter}
          formName={formName}
          hideParent={hideParent}
          setCountSelect={setCountSelect}
          formViewProps={formViewProps}
        />
      </g>
    })}
  </g>
}

function BlocView({ formName, classes, formDialog, startDiag, zoom, formView, formViewProps }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const viewProps = useSelector(state => state.form[formViewProps] || state.form.default)
  const { allIds = [], byId = {}, start = moment(), arrFilter = [] } = form.values
  const { planVisible = true } = viewProps.values
  // const tree = useSelector(state => state.gant)
  return allIds.filter(key => !arrFilter.includes(byId[key].parent)).map((key, i) => {
    const obj = byId[key]
    const planValid = obj.startPlan?.isValid() && obj.endPlan?.isValid()
    return <g key={key} id={obj.id} transform={`translate(0,${svgProps.yPading * i})`} className={classes.unselectable}>
      {planVisible && planValid && <ViewBlockPlan
        obj={obj} start={start}
        classes={classes}
        svgProps={svgProps}
        startDiag={startDiag}
        zoom={zoom}
        formView={formView}
        height={svgProps.yPading * i}
        id={key}
        formDialog={formDialog}
        formName={formName}
      />
      }
      <ViewBlock
        obj={obj} start={start}
        classes={classes}
        svgProps={svgProps}
        startDiag={startDiag}
        zoom={zoom}
        formView={formView}
        height={svgProps.yPading * i}
        id={key}
        formDialog={formDialog}
        formName={formName}
      />
    </g>
  })
}
function ArrowAll({ formName, startDiag, zoom }) {
  const [idArrow, setIdArrow] = useState('')
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { allIds = [], byId = {}, start = moment(), arrFilter } = form.values
  const arrFiltered = allIds.filter(key => !arrFilter.includes(byId[key].parent))
  let size = 0
  let diff = ''
  switch (zoom) {
    case 'day': {
      size = svgProps.sizeDay;
      diff = 'days'
      break;
    }
    case 'week': {
      size = svgProps.sizeWeek;
      diff = 'weeks'
      break;
    }
    case 'month': {
      size = svgProps.sizeMonth;
      diff = 'months'
      break;
    }
    default: break;
  }
  return <g>
    {arrFiltered.map((key, i) => {
      const obj = byId[key]
      if (obj.typeGant === 'parent' && !arrFilter.includes(obj.idAPI)) return null
      return obj.dependencies.map(arrObj => {
        const toTask = byId[arrObj.to]
        if (toTask.typeGant === 'parent' && !arrFilter.includes(toTask.idAPI)) return null
        if (arrFilter.includes(toTask.parent)) return null
        const toIndex = arrFiltered.findIndex(s => s === arrObj.to)
        return <Arrow
          id={'arrow_form' + key + ' to' + arrObj.id}
          key={'arrow_form' + key + ' to' + arrObj.id}
          start={start}
          from_task={{ start: obj.start, end: obj.end, index: i }}
          to_task={{ start: toTask.start, end: toTask.end, index: toIndex }}
          type={arrObj.type}
          startDiag={startDiag}
          sizeDay={size}
          diff={diff}
          setIdArrow={(e) => setIdArrow(e)}
          idArrow={idArrow}
        />
      })
    })}
    <use href={idArrow} />
  </g>
}

function WorkBlock({ formDialog, obj, id, classes, startDiag, pos, color, arrFilter, formName, hideParent, setCountSelect, formViewProps }) {
  const form2 = useSelector(state => state.form[formViewProps] || state.form.default)
  const { columnsArr = [], columnsObj = {}, columnsArrHide = [] } = form2.values
  const dispatch = useDispatch()
  const handleChangeRow = () => {
    dispatch(actions.formChangeAsObj({ obj, id, open: true, newRow: false }, { name: formDialog }))
  }
  const handleChange = () => {
    hideParent(obj.idAPI)
  }
  const handleSelected = () => {
    setCountSelect(prev => (obj.selected ? prev - 1 : prev + 1))
    dispatch(actions.formObjChangeObj({ ...obj, selected: !obj.selected }, { field: 'byId', id: id, name: formName }))
  }
  let x = 0
  const objView = obj.typeGant === 'parent' ? columnsObjTransformParent({ ...obj, pos }) : columnsObjTransform({ ...obj, pos })

  return <g className={classes.grid_rect_props}>
    <g onClick={handleChangeRow} >
      {obj.typeGant === 'parent' && <rect x={0} y={0} width={startDiag} height={svgProps.yPading} fill={obj.color} />}
      {obj.typeGant !== 'parent' && <rect x={0} y={0} width={columnsObj.selected.width} height={svgProps.yPading} fill={color} />}
      <rect x={0} y={0} width={startDiag} height={svgProps.yPading} className={classes.rectProps} fill='#ffffff' fillOpacity={'0.0'} />
      {columnsArr.filter(e => !columnsArrHide.includes(e)).map(key => {
        const column = columnsObj[key]
        let value = (column.type === 'date') ? obj[key].format('DD.MM.YYYY') : obj[key]
        if (column.type === 'numberLocal' && obj[key]) { value = obj[key].toLocaleString(); }
        if (key === 'pos') value = pos
        if (obj.typeGant === 'parent' && key === 'count') value = ''
        const width = column.width
        x += width
        const props = (key === 'name') ? { clipPath: 'url(#nameChip)' } : {}
        return <text
          key={key}
          x={x - ((column.align === 'center') ? width / 2 : (width - 4))}
          y={svgProps.yPading / 2}
          dominantBaseline="middle"
          textAnchor={column.align === 'center' ? 'middle' : 'start'}
          className={classes.textProps}
          fontWeight={obj.typeGant === 'parent' ? 'bold' : 'normal'}
          {...props}
        >{objView[key]}
        </text>
      })}
    </g>
    {obj.typeGant === 'parent' &&
      <g onClick={handleChange}>
        <g transform={`translate(${columnsObj.selected.width / 2 - 14},${svgProps.yPading / 2 - 16}) scale(1.2) `} >
          {arrFilter.includes(obj.idAPI) && <path fill='#555555' d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>}
          {!arrFilter.includes(obj.idAPI) && <path fill='#555555' d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>}
        </g>
        <rect x={0} y={0} width={columnsObj.selected.width} height={svgProps.yPading} fillOpacity={0.0} />
      </g>
    }
    {obj.typeGant !== 'parent' &&
      <g onClick={handleSelected}>
        <g transform={`translate(${columnsObj.selected.width / 2 - 14},${svgProps.yPading / 2 - 16}) scale(1.2) `} >
          {obj.selected && <path fill='#555555' d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"></path>}
          {!obj.selected && <path fill='#555555' d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>}
        </g>
        <rect
          x={0}
          y={0}
          width={columnsObj.selected.width}
          height={svgProps.yPading}
          fillOpacity={0.0}
        />
      </g>
    }
  </g>
}

function ViewBlock({ obj, classes, svgProps, startDiag, zoom, start, formView, height, id, formDialog, formName }) {
  let startRect = obj.start.diff(start, 'days');
  let widthRect = obj.duration + 1
  let size = svgProps.sizeDay
  switch (zoom) {
    case 'day': {
      size = svgProps.sizeDay;
      startRect = obj.start.diff(start, 'days');
      widthRect = obj.end.diff(obj.start, 'days') + 1
      break;
    }
    case 'week': {
      size = svgProps.sizeWeek;
      startRect = obj.start.diff(start, 'weeks', true);
      widthRect = moment(obj.end).add(1, 'd').diff(obj.start, 'weeks', true)
      break;
    }
    case 'month': {
      size = svgProps.sizeMonth;
      startRect = obj.start.diff(start, 'months', true);
      widthRect = obj.end.diff(obj.start, 'months', true)
      break;
    }
    default: break;
  }
  // const widthPol = size * Math.round(widthRect * (obj.progress) / 100)
  const type = typeGant.find(e => e.value === obj.typeGant)
  const dispatch = useDispatch()
  const handleMouseOver = (e) => {
    dispatch(actions.formChangeAsObj({ obj, open: true, x: e.clientX, y: e.clientY, height }, { name: formView }))
  }
  const handleMoueOut = (e) => {
    dispatch(actions.formChangeAsObj({ obj: null, open: false }, { name: formView }))
  }
  const handleChangeRow = () => {
    dispatch(actions.formChangeAsObj({ obj, id, open: true, newRow: false }, { name: formDialog }))
  }
  if (obj.typeGant === 'parent') {
    const heightPar = Math.round(svgProps.heightRec / 2)
    return <g transform={`translate(${startDiag + startRect * size},10)`}
      className={classes.block}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMoueOut}
    >
      <rect id={obj.id + '_rect_main'} x={0} y={0}
        width={size * widthRect}
        height={heightPar}
        fill={type.color}
      />
      <polygon
        id={obj.id + '_rect_tangle_start'}
        points={`0,${svgProps.heightRec}   0,${heightPar}  ${heightPar},${heightPar}`}
        fill={type.color}
        stroke={type.color}
        strokeWidth={0}
      />
      <polygon
        id={obj.id + '_rect_tangle_end'}
        points={`
        ${size * widthRect},${svgProps.heightRec} 
        ${size * widthRect},${heightPar} 
        ${size * widthRect - heightPar},${heightPar}
        `}
        stroke={type.color}
        strokeWidth={0}
        fill={type.color}
      />
    </g>
  }
  if (obj.typeGant === 'milestone') {
    return <g
      transform={`translate(${startDiag + startRect * size},6.125)`}
      className={classes.block}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMoueOut}
      onDoubleClick={handleChangeRow}
    >
      <rect
        x={- size * (widthRect + 0.5)}
        y={0}
        width={size * (widthRect + 2)}
        height={svgProps.heightRec}
        fillOpacity='0.0'
      />
      <polygon id={obj.id + '_rect_main'}
        points={`
        0,0, 
        ${size * widthRect / 2},${svgProps.heightRec / 2}, 
        0,${svgProps.heightRec}, 
        ${-size * widthRect / 2},${svgProps.heightRec / 2}`}
        fill={type.color}
        stroke={type.color}
        strokeWidth={0}
        draggable
        droppable='true'
        idapi={obj.idAPI}
        idrow={obj.id}
        typemove={moveTypes.dateMilestone}
      />
    </g>
  }
  return <g
    transform={`translate(${startDiag + startRect * size},10)`}
    className={classes.block}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMoueOut}
    onDoubleClick={handleChangeRow}
  >
    <rect
      x={-size}
      y={0}
      width={size}
      height={svgProps.heightRec / 1.5}
      fillOpacity='0.0'
    />
    <rect
      x={size * widthRect}
      y={0}
      width={size}
      height={svgProps.heightRec / 1.5}
      fillOpacity='0.0'
    />
    <rect id={obj.id + '_rect_main'} x={0} y={0}
      width={size * widthRect}
      height={svgProps.heightRec / 1.5}
      fill={type.color}
      idapi={obj.idAPI}
      idrow={obj.id}
      typemove={moveTypes.date}
      fillOpacity='0.8'
    />
    <rect id={obj.id + '_rect_progress'} x={0} y={0}
      width={size * Math.round(widthRect * obj.progress / 100)}
      height={svgProps.heightRec / 1.5}
      fill={type.colorPercent}
      fillOpacity='1'
    />
    <rect id={obj.id + '_rect'} x={0} y={0}
      width={size * widthRect}
      height={svgProps.heightRec / 1.5}
      draggable
      droppable='true'
      style={{ fillOpacity: '.05' }}
      idapi={obj.idAPI}
      idrow={obj.id}
      typemove={moveTypes.date}
      fillOpacity='0.8'
    />
  </g>
}

function ViewBlockPlan({ obj, classes, svgProps, startDiag, zoom, start, formView, height, id, formDialog, formName }) {
  const dispatch = useDispatch()
  let startRect = obj.startPlan.diff(start, 'days') || 0;
  let widthRect = (obj.endPlan.diff(obj.startPlan, 'days') + 1) || 0
  let size = svgProps.sizeDay
  switch (zoom) {
    case 'day': {
      size = svgProps.sizeDay;
      startRect = obj.startPlan.diff(start, 'days');
      widthRect = obj.endPlan.diff(obj.startPlan, 'days') + 1
      break;
    }
    case 'week': {
      size = svgProps.sizeWeek;
      startRect = obj.startPlan.diff(start, 'weeks', true);
      widthRect = moment(obj.endPlan).add(1, 'd').diff(obj.startPlan, 'weeks', true)
      break;
    }
    case 'month': {
      size = svgProps.sizeMonth;
      startRect = obj.startPlan.diff(start, 'months', true);
      widthRect = obj.endPlan.diff(obj.startPlan, 'months', true)
      break;
    }
    default: break;
  }
  const handleChangeRow = () => {
    dispatch(actions.formChangeAsObj({ obj, id, open: true, newRow: false }, { name: formDialog }))
  }
  if (obj.typeGant === 'parent' || obj.typeGant === 'milestone') return null;
  const handleMouseOver = (e) => {
    dispatch(actions.formChangeAsObj({ obj, open: true, x: e.clientX, y: e.clientY, height }, { name: formView }))
  }
  const handleMoueOut = (e) => {
    dispatch(actions.formChangeAsObj({ obj: null, open: false }, { name: formView }))
  }
  return <g
    transform={`translate(${startDiag + startRect * size},10)`}
    className={classes.block}
    onDoubleClick={handleChangeRow}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMoueOut}
  >
    <rect id={obj.id + '_rect_main_plan'} x={0} y={svgProps.heightRec / 1.4}
      width={size * widthRect}
      height={svgProps.heightRec / 3}
      fill={'#808080'}
    />
  </g>
}

const useStylesArrow = makeStyles(theme => ({
  pathArrow: {
    fill: 'none',
    strokeWidth: "3",
  },
}));

function Arrow({ start, from_task, to_task, type, startDiag, sizeDay, diff, setIdArrow, id, idArrow }) {
  const { yPading } = svgProps
  const classes = useStylesArrow()
  const getStart = (task) => {
    const startRectTo = task.start.diff(start, diff, true);
    return {
      x: startDiag + Math.round(startRectTo * sizeDay),
      y: yPading * (task.index + 0.375)
    }
  }
  const getEnd = (task) => {
    const startRectTo = moment(task.end).add(1, 'd').diff(start, diff, true)
    return {
      x: startDiag + Math.round(startRectTo * sizeDay),
      y: yPading * (task.index + 0.375)
    }
  }
  const finishStart = () => {
    const startPoint = getEnd(from_task)
    const endPoint = getStart(to_task)

    if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
      return `M ${startPoint.x} ${startPoint.y} h ${sizeDay / 2} V ${endPoint.y} H ${endPoint.x}  m -5 -5 l 5 5 l -5 5`
    }
    return `M ${startPoint.x} ${startPoint.y} h ${sizeDay / 2}
    v ${(endPoint.y - startPoint.y) / 2}
    H ${endPoint.x - sizeDay / 2}
    V ${endPoint.y} H ${endPoint.x}  m -5 -5 l 5 5 l -5 5`
  }
  const startStart = () => {
    const startPoint = getStart(from_task)
    const endPoint = getStart(to_task)
    if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
      return `M ${startPoint.x} ${startPoint.y} h ${-sizeDay / 2} V ${endPoint.y} H ${endPoint.x}  m -5 -5 l 5 5 l -5 5`
    }
    return `M ${startPoint.x} ${startPoint.y} h ${-(startPoint.x - endPoint.x + sizeDay / 2)}
    V ${endPoint.y}
    h ${sizeDay / 2}
    m -5 -5 l 5 5 l -5 5`
  }
  const finishFinish = () => {
    const startPoint = getEnd(from_task)
    const endPoint = getEnd(to_task)
    if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
      return `M ${startPoint.x} ${startPoint.y} H ${endPoint.x + sizeDay / 2} V ${endPoint.y} H ${endPoint.x}  m 5 -5 l -5 5 l 5 5`
    }
    return `M ${startPoint.x} ${startPoint.y} h ${sizeDay / 2}
    v ${endPoint.y - startPoint.y}
    H ${endPoint.x}
    m 5 -5 l -5 5 l 5 5`
  }
  const startFinish = () => {
    const startPoint = getStart(from_task)
    const endPoint = getEnd(to_task)
    if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
      return `M ${startPoint.x} ${startPoint.y} h ${-sizeDay / 2} v ${yPading / 2} H ${endPoint.x + sizeDay / 2} V ${endPoint.y} H ${endPoint.x} m 5 -5 l -5 5 l 5 5`
    }
    return `M ${startPoint.x} ${startPoint.y} h ${-sizeDay / 2}
    v ${yPading / 2}
    H ${endPoint.x + sizeDay / 2}
    V ${endPoint.y} H ${endPoint.x}  m 5 -5 l -5 5 l 5 5`
  }
  let d = ''
  switch (type) {
    case 'fs':
      d = finishStart()
      break;
    case 'ss':
      d = startStart()
      break;
    case 'ff':
      d = finishFinish()
      break;
    case 'sf':
      d = startFinish()
      break;
    default:
      break;
  }
  const idPath = '#' + id + '_path'
  return <path
    id={id + '_path'}
    onMouseEnter={() => setIdArrow('#' + id + '_path')}
    onMouseLeave={() => { setTimeout(() => setIdArrow(''), 10 * 1000) }}
    className={classes.pathArrow}
    d={d}
    stroke={idArrow !== idPath ? "orange" : "red"}
  />
}

function OneRect({ x, y, width, name }) {
  return <g transform={`translate(${x},${y})`}>
    <rect x={0} y={0} stroke="#a6a6a6" strokeWidth="1" fill="white" width={width} height={60} />
    <text dominantBaseline="middle" textAnchor="middle" x={width / 2} y={30}  >{name}</text>
  </g>
}
// increase column width 
function TableName({ formViewProps }) {
  const form2 = useSelector(state => state.form[formViewProps] || state.form.default)
  const { columnsArr = [], columnsObj = {}, startDiag = 0, columnsArrHide = [] } = form2.values
  const dispatch = useDispatch()
  let x = 0
  let xs = 0
  const [selectMove, setSelectMove] = useState(null)
  const [posX, setPosX] = useState(0)
  const [move, setMove] = useState(0)
  const [typeMove, setTypeMove] = useState(null)
  const handleDragStart = (e) => {
    if (e.target.getAttributeNS(null, 'draggable')) {
      const name = e.target.getAttributeNS(null, 'type')
      setSelectMove(e.target)
      setPosX(e.clientX)
      setTypeMove(name)
    }
  }
  const handleDrag = (e) => {
    if (!selectMove) return;
    setMove(move + e.clientX - posX)
    setPosX(e.clientX)
  }
  const handleDraEnd = () => {
    if (!selectMove) return;
    setSelectMove(null)
    const column = columnsObj[typeMove]
    let newWidth = column.width + move
    if (column && column.widthMin >= newWidth) { newWidth = column.widthMin }
    dispatch(actions.formObjChangeObj({
      width: newWidth,
    }, { field: 'columnsObj', id: typeMove, name: formViewProps }))
    dispatch(actions.formChange(startDiag + newWidth - column.width, { field: 'startDiag', name: formViewProps }))
    setMove(0)
    setTypeMove(false)
  }
  return <g id="sticky-rect-table">
    {columnsArr.filter(e => !columnsArrHide.includes(e)).map(key => {
      const column = columnsObj[key]
      const width = column.width
      x += width
      return <OneRect key={key} x={x - width} y={0} width={width} name={column.name} />
    })}
    <g
      onMouseDown={handleDragStart}
      onMouseMove={handleDrag}
      onMouseUp={handleDraEnd}
      onMouseLeave={handleDraEnd}
    >
      <rect x={0} y={0} height={60} width={x} fill='#ffffff' fillOpacity='0.1' />
      {columnsArr.filter(e => !columnsArrHide.includes(e)).map(key => {
        const column = columnsObj[key]
        const width = column.width
        xs += width
        return <line
          key={key}
          x1={xs + ((typeMove === key) ? move : 0)}
          y1={0}
          x2={xs + ((typeMove === key) ? move : 0)}
          y2={60}
          stroke="#a6a6a6"
          strokeWidth="2"
          draggable
          type={key}
          style={{ cursor: 'ew-resize' }}
        />
      })}
    </g>
  </g>
}

function DateLine({ start, end, svgProps, startDiag, heightSVG, zoom }) {
  const [arr, setArr] = useState({})
  const [nowDate, setNowDate] = useState({
    month: moment().format('MMMM YYYY'),
    day: moment().format('D')
  })
  useEffect(() => {
    const countDay = end.diff(start, 'days') + 10
    let date = moment(start)
    let res = {
      [date.format('MMMM YYYY')]: [{ d: date.format('D'), day: date.day(), m: date.format('MMM') }]
    }
    for (let i = 0; i < countDay; i++) {
      date.add(1, 'd')
      if (res[date.format('MMMM YYYY')]) {
        res[date.format('MMMM YYYY')].push({ d: date.format('D'), day: date.day(), m: date.format('MMM') })
      }
      else {
        res[date.format('MMMM YYYY')] = [{ d: date.format('D'), day: date.day(), m: date.format('MMM') }]
      }
    }
    setArr(res)
  }, [start, end])
  let offset = startDiag
  const heightDateLine = 60
  const classes = useStyles()
  return <g id="sticky-rect" className={classes.sticky}  >
    {Object.keys(arr).map((month) => {
      const days = arr[month]
      const week = days.filter(e => e.day === 1)
      switch (zoom) {
        case 'day':
          offset += svgProps.sizeDay * days.length
          return <g key={month} transform={`translate(${offset - svgProps.sizeDay * days.length})`}>
            <rect
              x={0}
              y={0}
              stroke="#a6a6a6"
              strokeWidth="1"
              fill="white"
              width={svgProps.sizeDay * days.length}
              height={heightDateLine - svgProps.sizeDay}
            />
            <text
              dominantBaseline="middle"
              textAnchor="middle"
              x={svgProps.sizeDay * days.length / 2}
              y={(heightDateLine - svgProps.sizeDay) / 2}
            >{days.length > 1 ? month : moment().month(month).format('MMM')}</text>
            {days.map((day, i) => {
              const isNowDay = month === nowDate.month && day === nowDate.day
              return <React.Fragment key={i}>
                <rect
                  x={i * svgProps.sizeDay}
                  y={svgProps.sizeDay}
                  stroke="#a6a6a6"
                  strokeWidth="1"
                  fill={isNowDay ? "yellow" : (day.day === 0 || day.day === 6) ? "#fc9292" : "white"}
                  width={svgProps.sizeDay}
                  height={svgProps.sizeDay}
                />
                <text
                  x={i * svgProps.sizeDay + svgProps.sizeDay / 2}
                  y={heightDateLine - svgProps.sizeDay + svgProps.sizeDay / 2}
                  dominantBaseline="middle"
                  textAnchor="middle"
                >{day.d}</text>
              </React.Fragment>
            })}
          </g>
        case 'week':
          offset += svgProps.sizeWeek * week.length
          return <g key={month} transform={`translate(${offset - svgProps.sizeWeek * week.length})`}>
            <rect
              x={0}
              y={0}
              stroke="#a6a6a6"
              strokeWidth="1"
              fill="white"
              width={svgProps.sizeWeek * week.length}
              height={heightDateLine - svgProps.sizeDay}
            />
            <text
              dominantBaseline="middle"
              textAnchor="middle"
              x={svgProps.sizeWeek * week.length / 2}
              y={(heightDateLine - svgProps.sizeDay) / 2}
            >{days.length > 1 ? month : moment().month(month).format('MMM')}</text>
            {week.map((day, i) => {
              return <React.Fragment key={i}>
                <rect
                  x={i * svgProps.sizeWeek}
                  y={svgProps.sizeDay}
                  stroke="#a6a6a6"
                  strokeWidth="1"
                  fill="white"
                  width={svgProps.sizeWeek}
                  height={svgProps.sizeDay}
                />
                <text
                  x={i * svgProps.sizeWeek + svgProps.sizeWeek / 2}
                  y={heightDateLine - svgProps.sizeDay + svgProps.sizeDay / 2}
                  dominantBaseline="middle"
                  textAnchor="middle"
                >{day.d + ' ' + day.m}</text>
              </React.Fragment>
            })}

          </g>
        case 'month':
        default:
          return null;
      }

    })}
    <rect x={startDiag} y={0} stroke="#a6a6a6" strokeWidth="1" fill="white" fillOpacity={0} width={svgProps.sizeDay * (end.diff(start, 'days') + 10)} height={heightDateLine} />
  </g>
}
function GridTable({ start, end, svgProps, startDiag, heightSVG, zoom }) {
  const [arr, setArr] = useState({})
  useEffect(() => {
    const countDay = end.diff(start, 'days') + 10
    let date = moment(start)
    let res = {
      [date.format('MMMM')]: [{ d: date.format('D'), day: date.day(), m: date.format('MMM') }]
    }
    for (let i = 0; i < countDay; i++) {
      date.add(1, 'd')
      if (res[date.format('MMMM')]) {
        res[date.format('MMMM')].push({ d: date.format('D'), day: date.day(), m: date.format('MMM') })
      }
      else {
        res[date.format('MMMM')] = [{ d: date.format('D'), day: date.day(), m: date.format('MMM') }]
      }
    }
    setArr(res)
  }, [start, end])
  let offset = startDiag
  const heightDateLine = 60
  return <g>
    {Object.keys(arr).map((month) => {
      const days = arr[month]
      const week = days.filter(e => e.day === 1)
      switch (zoom) {
        case 'day':
          offset += svgProps.sizeDay * days.length
          return <g key={month} transform={`translate(${offset - svgProps.sizeDay * days.length})`}>
            {days.map((day, i) => {
              return <React.Fragment key={i}>
                {(day.day === 0 || day.day === 6) && <rect
                  x={i * svgProps.sizeDay}
                  y={heightDateLine - svgProps.sizeDay}
                  fill="#fc9292"
                  fillOpacity={0.1}
                  width={svgProps.sizeDay}
                  height={heightSVG}
                />}
                <line
                  x1={i * svgProps.sizeDay}
                  y1={svgProps.sizeDay}
                  x2={i * svgProps.sizeDay}
                  y2={heightSVG}
                  stroke="#e0e0e0"
                />
              </React.Fragment>
            })}
          </g>
        case 'week':
          offset += svgProps.sizeWeek * week.length
          return <g key={month} transform={`translate(${offset - svgProps.sizeWeek * week.length})`}>
            {week.map((day, i) => {
              return <React.Fragment key={i}>
                {[1, 2, 3, 4, 5, 6, 7].map((e, j) => {
                  return <line
                    key={j}
                    x1={i * svgProps.sizeWeek + 10 * j}
                    y1={60}
                    x2={i * svgProps.sizeWeek + 10 * j}
                    y2={heightSVG}
                    stroke="#e0e0e0"
                  />
                })
                }
              </React.Fragment>
            })}

          </g>
        case 'month':
        default:
          return null;
      }
    })}
  </g>
}