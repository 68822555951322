import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import SelectFile from './SelectFile'
import TableMaterial from './TableMaterial'
import * as actions2 from '../../../reducers/form';
import { initFormTable, clearFormTable, setFormTable } from '../../../reducers/formTable';
import { fetchDispath } from '../../../functions/fetch'
import { parseMaterialFile } from '../../../api/material'

const formName = 'MultiLoadMaterial'
export default function MaterialLoad({ history }) {
  const [activeStep, setActiveStep] = useState(0)
  const dispatch = useDispatch()
  const fileId = useSelector(state => state.form[formName]?.values.fileId)
  const end = useSelector(state => state.form[formName]?.values.end)
  const start = useSelector(state => state.form[formName]?.values.start)
  const listName = useSelector(state => state.form[formName]?.values.listName)
  useEffect(() => {
    dispatch(initFormTable({ name: formName }))
    dispatch(actions2.formInitialize({
      start: '',
      end: '',
      file: 'test.xlsx',
      fileId: '107',
      estimate: false,
      consist: 'add',
      allIds: [],
      byId: {},
      column: {},
      columnNew: {},
      selectId: [],
      idProject: null,
      listName: '',
      listArr: [{value: "Лист1", label: "Лист1"}],
      countSelect: 10,
      multiSelect: false,
      filePByIds: {},
      filePAllIds: [],
    }, { name: formName }))
    return () => {
      dispatch(clearFormTable(formName))
      dispatch(actions2.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const handleParseMaterial = () => {
    dispatch(fetchDispath({
      querty: {
        id: fileId,
        end: end,
        start: start,
        list: listName
      },
      progress: true,
      request: parseMaterialFile,
    }))
      .then(res => {
        let columnObj = {}
        let column = {}
        let byId = {}
        let idCell = 0
        let allIds = res.map(row => {
          let id = 'id' + idCell
          idCell++
          byId[id] = row
          Object.keys(row).forEach(value => {
            if (columnObj[value]) {
              columnObj[value].count++
            }
            else columnObj[value] = { count: 1, visible: true, select: '', selectName: '' }
          })
          return id;
        })
        Object.keys(columnObj).sort((a, b) => {
          if (a.length < b.length) return -1
          if (a.length > b.length) return 1
          if (a.length === b.length && a < b) return -1
          if (a.length === b.length && a > b) return 1
          return 0
        }).forEach(value => { column[value] = columnObj[value] })
        dispatch(setFormTable({ allIds, byId }, { name: formName }))
        dispatch(actions2.formChangeAsObj({ column: column }, { name: formName }))
        setActiveStep(1)
      }).catch(err => console.log(err))
  }
  const handleBack = () => {
    if (activeStep === 0) return history.goBack()
    setActiveStep(activeStep - 1)
  };
  return (
    <div className='App-paper'>
      {activeStep === 0 && <SelectFile formName={formName} />}
      {activeStep === 1 && <TableMaterial formName={formName} handleBack={handleBack} />}
      {activeStep === 0 &&
        <div>
          <Button onClick={handleBack}>Назад</Button>
          <Button
            disabled={fileId === 0}
            variant="contained"
            color="primary"
            onClick={handleParseMaterial}
          >Далее</Button>
        </div>
      }
    </div>
  );
}