import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import * as api from '../../api/list'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import { Typography } from '@material-ui/core';


export default function WorkEstimateFolderAdd({ history }) {
  const dispatch = useDispatch()
  const formName = 'WorkEstimateFolderAdd'
  useEffect(() => {
    dispatch(actions.formInitialize({ name: '', errorValidate: { name: false }, resource: undefined }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [
    { field: 'name', label: 'Наименование', type: 'text', validator: [value => value !== ''], errorText: 'Поле обязательно' },
    {
      field: 'resource', type: 'autoLoad', label: 'Папка',
      textProps: {
        path: api.loadWorkEstimate.path + '?folder=1',
        meta: {
          field: 'resource',
          value: 'id',
          labelFunc: (obj) => obj.name
        },
        selectProps: { isClearable: true },
      }
    },
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: { name: values.name, isFolder: true, parent: values.resource },
      url: api.addWorkEstimate.path,
      method: api.addWorkEstimate.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Добавить папку'
          />
          <Typography>{values.parent}</Typography>
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}
