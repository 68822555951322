import { getProjectId, putProjectId, postProjectId, deleteProjectById } from "./template_crud";

const name = '/form/gant'
export const getProjectGant = getProjectId(name);
export const getProjectGantPrint = getProjectId(name + '/print');
export const getProjectGantPrice = getProjectId(name + '/month-price');
export const getProjectGantDate = getProjectId(name + '/from-date');
export const addWorkToGant = postProjectId(name);
export const updGantMany = {
    path: (obj) => `/projects/${obj.id}${name + '/upd-many'}`,
    type: 'PUT'
}
export const deleteGantMany = {
    path: (obj) => `/projects/${obj.id}${name + '/delete-many'}`,
    type: 'PUT'
}
export const loadAllToGant = postProjectId(name + '/loadAll');
export const updWorkGant = putProjectId(name);
export const delWorkGant = deleteProjectById(name);

export const setGantDatePlan = {
    path: (obj) => `/projects/${obj.id}${name}/set-plan-date`,
    type: 'POST'
};
export const addArrowWorkToGant = {
    path: (obj) => `/projects/${obj.id}${name}/${obj.idWork}/arrow`,
    type: 'POST'
};
export const delArrowWorkToGant = (id) => ({
    path: (obj) => `/projects/${id}${name}/${obj.idWork}/arrow/${obj.idArrow}`,
    type: 'DELETE'
})


const nameS = '/form/gant-schedule'
export const getProjectGantSchedule = getProjectId(nameS);
export const getProjectGantScheduleCalendar = getProjectId(nameS + '/calendar');
export const getProjectGantScheduleCalendarBusy = getProjectId(nameS + '/calendar/busy');
export const updProjectGantSchedule = {
    path: (obj) => `/projects/${obj.id}${nameS}/${obj.gantId}/${obj.resourceId}`,
    type: 'PUT'
}
export const getGantShCalendar = {
    path: (obj) => `/projects/${obj.idProject}${nameS}/${obj.id}/${obj.resourceId}/calendar`,
    type: 'GET'
};
export const updGantShCalendar = {
    path: (obj) => `/projects/${obj.idProject}${nameS}/${obj.id}/${obj.resourceId}/calendar`,
    type: 'PUT'
};