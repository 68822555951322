import React from 'react';
import PageList from '../../components/PageListAPI'
import { getKS2ById } from '../../api/formks2ks3'
import KS2ByIdHeader from './KS2ByIdHeader'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    height: '50vh'
  }
}))
const formName = 'ks2list'
export default function KS2ById(props) {
  const classes = useStyles()
  const tableHead = [
    { id: 'number', name: '№ п.п.', },
    { id: 'groupNumber', name: '№ по смете', },
    { id: 'name', name: 'Наменование работ', },
    { id: 'storageUnit', name: 'Еденца измерения', },
    { id: 'count', name: 'Количество', },
    { id: 'price', name: 'Цена за еденицу', },
    { id: 'summ', name: 'Стоимость', },
  ];

  return (
    <div className='App-paper'>
      <PageList
        name={formName}
        columns={tableHead}
        classes={classes}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getKS2ById(props.match.params.idProject),
          param: {
            id: props.match.params.idDoc
          },
        }}
        headerObj={<KS2ByIdHeader formName={formName} idProject={props.match.params.idProject} idDoc={props.match.params.idDoc}/>}
        tableProps={{
          pagination: false
        }}
        pagination={false}
        csv={false}
        click={false}
        {...props}
      />
    </div>
  )
}