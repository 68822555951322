import React, { useState, } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TableSortLabel, Table, TableBody, TableHead, TableRow, TableCell, Typography, Checkbox, Button, IconButton, Grid, InputBase } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors';
import { formArrayDelete, formChange, formArrayAdd, formSubmitAPI, formChangeAsObj, formObjAdd, formObjDelete } from '../../reducers/form';
import DeleteIcon from '@material-ui/icons/Clear'
import { addGroupMaterial } from '../../api/estimate'
import AutoComplite from '../../components/Autocomplite'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { deleteRowsFormTable } from '../../reducers/formTable';
import DivideMaterial from './DivideMaterial';
import ReplayIcon from '@material-ui/icons/Replay'

const directionSortConst = {
  ASC: 'asc',
  DESC: 'desc',
};
function stableSort(array, objs, orderBy, order) {
  const stabilizedThis = array.map(key => ([key, String(objs[key][orderBy])]));
  const ord = order === directionSortConst.DESC ? 1 : -1
  stabilizedThis.sort((a, b) => {
    return ord * a[1].localeCompare(b[1], undefined, { numeric: true, sensitivity: 'base' })
  });
  return stabilizedThis.map(el => el[0]);
}
export default function EstimateTable({
  tableHead, idProject, reloadWhenAddToGroup, loadEstimate, changeVisible, formName
}) {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [find, setFind] = useState('')
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { values } = form
  const { selectedEstimate = [] } = values
  const moveToGroup = () => {
    let summ = 0;
    const arr = selectedEstimate.map(id => {
      const obj = formTable.byId[id]
      summ += obj.summ
      return {
        id: obj.id,
        count: obj.count,
        type: obj.type,
        number: Number(obj.number) || ''
      }
    })
    dispatch(formSubmitAPI({
      props: {
        arr: arr
      },
      url: addGroupMaterial.path({ idProject: idProject, id: values.selectedGroup }),
      method: addGroupMaterial.type,
      endFunc: (res) => {
        reloadWhenAddToGroup()
        dispatch(formChangeAsObj({
          selectedEstimate: [],
          summEstimate: values.summEstimate - summ
        }, { name: formName }))
        dispatch(deleteRowsFormTable(selectedEstimate, { name: formName, }))
        if (selectedEstimate.length - formTable.allIds.length === 0) changeVisible(false)
      }
    }, formName))
  }
  const isSelected = (id) => selectedEstimate.indexOf(id) !== -1;
  const handleClickAll = () => {
    if (selectedEstimate.length === formTable.allIds.length) {
      dispatch(formChange([], { field: 'selectedEstimate', name: formName }))
    }
    else {
      dispatch(formChange(formTable.allIds, { field: 'selectedEstimate', name: formName }))
    }
  }
  const handleRequestSort = (event, property) => {
    let orderNew = 'desc';
    if (orderBy === property && order === 'desc') {
      orderNew = 'asc';
    }
    setOrder(orderNew)
    setOrderBy(property)
  };
  const handleFind = (value) => {
    setFind(value)
  }
  const { allIds = [], byId } = formTable
  return (<React.Fragment>
    <Move formName={formName} moveToGroup={moveToGroup} find={find} handleFind={handleFind} reload={loadEstimate} />
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={selectedEstimate.length === allIds.length}
              onClick={() => handleClickAll()}
            />
          </TableCell>
          {tableHead.map(obj => {
            const align = obj.align || 'inherit'
            return (<TableCell
              key={obj.id}
              align={align}
              sortDirection={orderBy === obj.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === obj.id}
                direction={order}
                onClick={(e) => handleRequestSort(e, obj.id)}
              >
                {obj.name}
              </TableSortLabel>
            </TableCell>);
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow >
          <TableCell colSpan={8}>
            <Typography variant='body1'>Без группы</Typography>
          </TableCell>
          <TableCell colSpan={2} align='right'>
            <Typography variant='body1'>{(Math.round(100 * values.summEstimate) / 100).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}</Typography>
          </TableCell>
        </TableRow>
        {
          stableSort((find !== '')
            ? allIds.filter(v => {
              const obj = byId[v]
              return obj.name.includes(find) || obj.typeMark.includes(find) || obj.vendorCode.includes(find)
            })
            : allIds, byId, orderBy, order)
            .map((id, i) => {
              const obj = byId[id]
              return <Row
                key={id}
                tableHead={tableHead}
                obj={obj}
                number={i}
                id={id}
                selected={isSelected(id)}
              />
            })}
      </TableBody>
    </Table>
  </React.Fragment>)
}

function Row(props) {
  const { tableHead, obj, id, selected } = props;
  const dispatch = useDispatch()
  const name = 'EstimateList'
  const metaArray = { field: 'selectedEstimate', name }
  const metaObj = { id, field: 'selectedEstimateById', name }
  const selectMaterial = () => {
    if (selected) {
      dispatch(formArrayDelete(id, metaArray))
      dispatch(formObjDelete(metaObj))
    }
    else {
      dispatch(formArrayAdd(id, metaArray))
      dispatch(formObjAdd(obj, metaObj))
    }
  }
  return (
    <TableRow role="checkbox" onClick={selectMaterial}>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected}
        />
      </TableCell>
      {tableHead.map(e => {
        return <TableCell key={e.id} align={e.align}>{obj[e.id]}</TableCell>
      })}
    </TableRow>
  );
}

function Move({ find, handleFind, reload, moveToGroup, formName }) {
  const dispatch = useDispatch()
  const [isMove, setIsMove] = useState(true)
  const changeGroup = (e) => dispatch(formChange(e.value, { field: 'selectedGroup', name: formName }))
  const group = useSelector(state => state.autocomplite['group'] || state.autocomplite.default)
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <IconButton aria-label="Reload" color='primary' onClick={() => reload()}>
            <ReplayIcon />
          </IconButton>
        </Grid>
        <Grid item><InputBase
          onChange={(e) => handleFind(e.target.value)}
          placeholder={'Найти'}
          value={find}
          fullWidth
          endAdornment={<IconButton aria-label="Delete"
            onClick={() => handleFind('')}>
            <DeleteIcon />
          </IconButton>}
        /></Grid>
        <Grid item >
          <DivideMaterial formName={formName} />
        </Grid>
        <Grid item >
          <Button color="primary" onClick={() => setIsMove(true)}>Переместить</Button>
        </Grid>
        {isMove && <React.Fragment>
          <Grid item xs={3}>
            <AutoComplite
              arr={group.arr}
              action={changeGroup}
              palaseHolder='Группа'
            />
          </Grid>
          <Grid item>
            <IconButton style={{ color: green[500] }} onClick={() => moveToGroup()} size='small'><CheckIcon /></IconButton>
          </Grid>
          <Grid item>
            <IconButton style={{ color: red[500] }} onClick={() => setIsMove(false)} size='small'><CloseIcon /></IconButton>
          </Grid>
        </React.Fragment>}
      </Grid>
    </React.Fragment>
  )
}