import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, MenuItem, Fade, Menu, Dialog,
   DialogTitle,DialogContent, List, ListItemText, ListItem, } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  profileButton: {
    position: 'relative',
    marginRight: '10%',
    marginLeft: 'inherit',
    color: "inherit",
  },
}));
// additionalActions = [{ name:'', action:()=>{}}]
export default function PageListFilretColumn({ csv, columns, handleClickCSV, arrHidden, hideColumn, additionalActions=[] }) {
  const classes = useStyles()
  const [open, changeOpen] = useState(false)
  const [openDialog, changeOpenDialog] = useState(false)
  const [anchorRef, changeRef] = useState(null)
  const handleToggle = (e) => {
    changeOpen(!open)
    changeRef(!anchorRef ? e.currentTarget : null)
  }
  const handleClose = (e) => {
    changeOpen(false)
    changeRef(null)
  }
  return <div className={classes.root}>
    <IconButton
      className={classes.profileButton}
      aria-label="Account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleToggle}
      color="inherit"
    >
      <SettingsIcon />
    </IconButton>
    <Menu
      id="fade-menu"
      anchorEl={anchorRef}
      keepMounted
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <MenuItem onClick={() => { handleClickCSV(); handleClose(); }} disabled={!csv} >Скачать таблицу</MenuItem>
      <MenuItem onClick={() => { changeOpenDialog(true); handleClose(); }} >Колонки</MenuItem>
      {additionalActions.map((v,i)=>{
        return  <MenuItem key={i} onClick={() => { v.action(); handleClose(); }} >{v.name}</MenuItem>
      })}
    </Menu>
    <DialogColumn columns={columns} open={openDialog} onClose={()=>changeOpenDialog(false)} arrHidden={arrHidden} hideColumn={hideColumn}/>
  </div>
}


const useStyles2 = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
  dialogContent: {
    heigth: '30vh',
    zIndex: 2
  },
  root: {
    minHeigth: '500',
    height: '400'
  },
  selected: {
    background: '#bbbbbb'
  },
  auto: {
  }
}))

function DialogColumn({ columns, open, onClose, arrHidden, hideColumn }) {
  const classes = useStyles2()
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">Скрыть колонки</DialogTitle>
      <DialogContent >
        <List component="nav" aria-label="main mailbox folders">
          {columns.map(obj => {
            const selected = arrHidden.includes(obj.id)
            return <ListItem
              key={obj.id}
              button
              onClick={() => selected ? hideColumn(obj.id,false) : hideColumn(obj.id,true)}
              className={classNames({ [classes.selected]: selected, })}
            >
              <ListItemText primary={obj.name} />
            </ListItem>
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}