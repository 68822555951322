import React from 'react';
import { getOrdersLenta } from '../../../api/orders'
import PageList from '../../../components/PageListAPI'

const getColorStatus = (status) => {
  switch (status) {
    case 'ordered': return '#39cc39'
    case 'reject': return  '#C51010'
    case 'not': return undefined
    default: return undefined
  } 
}

export default function OrdersLenta (props) {
  const head = [
    { id: 'date', name: 'Дата заявки', type: 'date', formatDate: 'LL' },
    { id: 'dateDelivery', name: 'Дата поставки', type: 'date', formatDate: 'LL' },
    { id: 'user', name: 'Добавил', },
    { id: 'name', name: 'Наименование', },
    { id: 'count', name: 'Количество', },
    { id: 'storageUnit', name: 'Ед. изм.', },
    {
      id: 'statusRus', name: 'Статус', type: 'component',
      component: ({ obj }) => <div style={{ color: getColorStatus(obj.status) }}>{obj.statusRus}</div>
    },
  ].filter(key => key)
  return (
    <div className='App-paper'>
      <PageList
        name='ordersLenta'
        title='Лента заказов'
        columns={head}
        idName='id'
        loadAPI={{
          param: {
            id: props.match.params.idProject
          },
          request: getOrdersLenta,
          transform: (res => {
            const statusEnum = {
              ordered: 'Заказано',
              reject: 'Отклонено',
              not: 'В обработке'
            };
            return {
              count: res.count,
              get: res.get.map(obj => {
                return { ...obj, statusRus: statusEnum[obj.status] }
              })
            }
          }),
        }
        }
        headerInit={{
          sortBy: 'date',
          sortDirection: 'desc',
        }}
        click={false}
        {...props}
      />
    </div>
  )
}