import { getProjectId, deleteProjectById, post, get } from "./template_crud";
export const getMaterialById = {
  path: (obj) => `/projects/${obj.id}/materials/${obj.idMaterial}`,
  type: 'GET'
}
export const getMaterialByIdHistory = {
  path: (obj) => `/projects/${obj.id}/materials/${obj.idMaterial}/history`,
  type: 'GET'
}
export const getMaterials = getProjectId('/materials');
export const getMaterialsTags = getProjectId('/materials/tag');
export const getMaterialsList = getProjectId('/materials/list');
export const updateMaterial = {
  path: (obj) => `/projects/${obj.id}/materials/${obj.idMaterial}`,
  type: 'PUT'
}
export const updateMaterialPrice = {
  path: (obj) => `/projects/${obj.id}/materials/${obj.idMaterial}/price`,
  type: 'PUT'
}
export const updateMaterialPriceMulty = {
  path: (obj) => `/projects/${obj.id}/materials/price`,
  type: 'PUT'
}
export const updateMaterialStorage = {
  path: (obj) => `/projects/${obj.id}/materials/${obj.idMaterial}/storage`,
  type: 'PUT'
}
export const setDeleteMaterial = (id) => ({
  path: (obj) => `/projects/${id}/materials/${obj.id}/isdeleted`,
  type: 'PUT'
})
export const setDeleteMaterialMulti = (id) => ({
  path: `/projects/${id}/materials/setdelmulti`,
  type: 'PUT'
})
export const deleteMaterialMulti = (id) => ({
  path: `/projects/${id}/materials/delmulti`,
  type: 'PUT'
})
export const deleteMaterial = deleteProjectById('/materials');
export const getMSN = {
  path: (obj) => `/projects/${obj.id}/materials/${obj.idMaterial}/estimate`,
  type: 'GET'
}
export const addMSN = {
  path: (obj) => `/projects/${obj.id}/materials/${obj.idMAterial}/estimate`,
  type: 'POST'
}
export const addMSNfunction = (id, idMaterial) => ({
  path: `/projects/${id}/materials/${idMaterial}/estimate`,
  type: 'POST'
})


export const getMaterialByIdTag = {
  path: (obj) => `/projects/${obj.id}/materials/${obj.idMaterial}/tag`,
  type: 'GET'
}
export const addMaterialByIdTag = {
  path: (obj) => `/projects/${obj.id}/materials/${obj.idMaterial}/tag`,
  type: 'POST'
}
export const deleteMaterialByIdTagById = {
  path: (obj) => `/projects/${obj.id}/materials/${obj.idMaterial}/tag/${obj.idTag}`,
  type: 'DELETE'
}

export const addMaterialsMany = post('/materials/many');

export const parseMaterialFile = get('/materials/parse');