import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { fetchDispath } from '../../../functions/fetch'
import * as actions2 from '../../../reducers/form';
import * as api from '../../../api/workdoc'

const arrCol = ['pos', 'name', 'type', 'code', 'plant', 'su', 'count', 'mass', 'other', 'section']
const formSelected = 'tableSelected'
const useStylesCell = makeStyles(theme => ({
  selected: {
    backgroundColor: 'rgba(14, 101, 235, 0.1)',
    borderWidth: '2px 0px',
    borderStyle: 'solid',
    borderColor: 'rgb(26, 115, 232)'
  },
  selectedCell: {
    outline: '2px solid',
    outlineColor: 'rgb(26, 115, 232)'
  },
  td: {
    border: '1px solid #ccc'
  },
  input: {
    fontSize: '0.875rem',
  },
  inputHidden: {
    fontSize: '0.875rem',
    color: 'transparent',
    textShadow: '0 0 0 #000',
    width: '1px'
  },
}));

export default function SimpleCell({ row, col, value, virtual = false, rowId, rowGlobal, colSpan = '', color = '', docId, formHistory, formTable }) {
  const field = arrCol[col]
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formSelected] || state.form.default)
  const formT = useSelector(state => state.form[formTable] || state.form.default)
  const fHistory = useSelector(state => state.form[formHistory] || state.form.default)
  const { selectRow, selectCellRow, selectCellColumn, lastRow } = form.values
  const classes = useStylesCell()
  const [edit, setEdit] = useState(value || '')
  const [isEdit, setIsEdit] = useState(false)
  const selectedRowCell = selectCellRow === rowId && selectCellColumn === field
  const selectedRow = selectRow === row
  useEffect(() => {
    if (!isEdit) return;
    setEdit(value || '')
  }, [isEdit, value])
  const onClick = () => {
    if (selectedRowCell) return;
    dispatch(actions2.formChangeAsObj({
      selectRow: '',
      selectCellRow: rowId,
      selectCellColumn: field
    }, { name: formSelected }))
  }
  const onChange = (e) => {
    setEdit(e.target.value)
  }
  const onBlur = () => {
    setIsEdit(false)
    if (edit === value) return
    // add to history
    if (field !== 'section') {
      let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
      buffNew.push({
        oldValue: { [field]: value, row: rowId },
        newValue: { [field]: edit, row: rowId },
        action: 1
      })
      dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
      dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))
    }
    else {
      let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
      buffNew.push({
        oldValue: { [field]: value, row: rowId },
        newValue: { [field]: edit, row: rowId },
        action: 2
      })
      dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
      dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))
    }
    if (virtual) {
      if (edit === '') return;
      let arrId = []
      for (let i = lastRow + 1; i <= row; i++) {
        arrId.push('a' + i)
      }
      dispatch(actions2.formArrayAddMany(arrId, { field: 'allIds', end: true, name: formTable }))
      dispatch(actions2.formChangeAsObj({ lastRow: row }, { name: formSelected }))
      if (field !== 'section') {
        dispatch(actions2.formObjChangeObj({ [field]: edit, row: row }, { field: 'byId', id: 'a' + row, name: formTable }))
        dispatch(fetchDispath({
          param: { id: docId, },
          body: {
            action: 1,
            cells: [{
              row: row,
              [field]: edit
            }]
          },
          progress: false,
          request: api.saveChangeTable,
        }))
        return
      }
      else {
        dispatch(actions2.formObjChangeObj({ [field]: edit, }, { field: 'rowProps', id: rowId, name: formSelected }))
      }
    }
    if (field !== 'section') {
      dispatch(actions2.formObjChangeObj({ [field]: edit, }, { field: 'byId', id: row, name: formTable }))
      if (rowId !== rowGlobal) {
        dispatch(actions2.formObjChangeObj({ row: rowId, }, { field: 'byId', id: row, name: formTable }))
      }
      dispatch(fetchDispath({
        param: { id: docId, },
        body: {
          action: 1,
          cells: [{
            row: rowId,
            [field]: edit
          }]
        },
        progress: false,
        request: api.saveChangeTable,
      }))
    }
    else {
      dispatch(actions2.formObjChangeObj({ [field]: edit, }, { field: 'rowProps', id: rowId, name: formSelected }))
      dispatch(fetchDispath({
        param: { id: docId, },
        body: {
          action: 3,
          rows: [{
            row: rowId,
            [field]: edit
          }]
        },
        progress: false,
        request: api.saveChangeTable,
      }))
    }
  }
  const onKeyDown = (e) => {
    if (e.keyCode > 47 && e.keyCode < 91) {
      setIsEdit(true)
      return;
    }
    switch (e.keyCode) {
      case 17: {
        e.preventDefault()
        console.log('e,')
        return;
      }
      case 37: {
        dispatch(actions2.formChangeAsObj({
          selectCellColumn: (col === 0) ? arrCol[8] : arrCol[col - 1]
        }, { name: formSelected }))
        return;
      }
      case 38: {
        if (rowId === 1) return;
        dispatch(actions2.formChangeAsObj({
          selectCellRow: rowId - 1
        }, { name: formSelected }))
        return;
      }
      case 39: {
        dispatch(actions2.formChangeAsObj({
          selectCellColumn: (col === 8) ? arrCol[0] : arrCol[col + 1]
        }, { name: formSelected }))
        return;
      }
      case 40: {
        dispatch(actions2.formChangeAsObj({
          selectCellRow: rowId + 1
        }, { name: formSelected }))
        return;
      }
      case 46: {
        deleteCell()
        return;
      }
      default: { }
    }
  }
  const deleteCell = () => {
    if (virtual) return;
    // history
    if (field !== 'section') {
      if (rowId !== rowGlobal) return;
      let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
      buffNew.push({
        oldValue: { [field]: value, row: rowId },
        newValue: { [field]: '', row: rowId },
        action: 1
      })
      dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
      dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))
    }
    else {
      let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
      buffNew.push({
        oldValue: { [field]: value, row: rowId },
        newValue: { [field]: '', row: rowId },
        action: 2
      })
      dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
      dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))
    }
    if (field !== 'section') {
      if (rowId !== rowGlobal) return;
      dispatch(actions2.formObjChangeObj({ [field]: '', }, { field: 'byId', id: row, name: formTable }))
      dispatch(fetchDispath({
        param: { id: docId, },
        body: {
          action: 1,
          cells: [{
            row: rowId,
            [field]: ''
          }]
        },
        progress: false,
        request: api.saveChangeTable,
      }))
    }
    else {
      dispatch(actions2.formObjChangeObj({ [field]: '', }, { field: 'rowProps', id: rowId, name: formSelected }))
      dispatch(fetchDispath({
        param: { id: docId, },
        body: {
          action: 3,
          rows: [{
            row: rowId,
            [field]: ''
          }]
        },
        progress: false,
        request: api.saveChangeTable,
      }))
    }

  }
  const onPasteEdit = (e) => {
    const { byId } = formT.values
    const pastData = e.clipboardData.getData('text/plain')
    const arrPaste = pastData.split('\n').map(e => {
      return e.split('\t')
    });
    const cellPast = []
    const cellOld = []
    arrPaste.forEach((rows, i) => {
      const newRow = rowId + i
      let objPast = { row: newRow }
      let objOld = { row: newRow }
      rows.forEach((value, j) => {
        objOld[arrCol[col + j]] = (byId['a' + newRow]) ? byId['a' + newRow][arrCol[col + j]] || '' : ''
        objPast[arrCol[col + j]] = objOld[arrCol[col + j]] + value
      })
      cellPast.push(objPast)
      cellOld.push(objOld)
      dispatch(actions2.formObjChangeObj(objPast, { field: 'byId', id: 'a' + newRow, name: formTable }))
    })
    // history 
    let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
    buffNew.push({
      oldValue: cellOld,
      newValue: cellPast,
      action: 6
    })
    dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
    dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))


    dispatch(fetchDispath({
      param: { id: docId, },
      body: { action: 2, cells: cellPast },
      progress: false, request: api.saveChangeTable,
    }))
    setEdit(arrPaste[0][0])
    if (rowId + arrPaste.length > lastRow) {
      let arrId = []
      for (let i = lastRow + 1; i <= rowId + arrPaste.length; i++) {
        arrId.push('a' + i)
      }
      dispatch(actions2.formArrayAddMany(arrId, { field: 'allIds', end: true, name: formTable }))
      dispatch(actions2.formChangeAsObj({ lastRow: rowId + arrPaste.length }, { name: formSelected }))
    }
    e.preventDefault()
  }
  return <td key={row + field}
    style={{ backgroundColor: color, }}
    className={classNames({
      [classes.td]: !selectedRow && !selectedRowCell,
      [classes.selected]: selectedRow,
      [classes.selectedCell]: selectedRowCell,
    })}
    onClick={onClick}
    onDoubleClick={() => setIsEdit(true)}
    colSpan={colSpan}
    align={((isEdit && selectedRowCell) || colSpan !== '' || field === 'name') ? 'left' : 'center'}
  >
    {(isEdit && selectedRowCell) ?
      <div>
        <InputBase
          autoFocus
          value={edit}
          onChange={onChange}
          onBlur={onBlur}
          className={classes.input}
          onPaste={onPasteEdit}
          onKeyUp={(e) => { if (e.keyCode === 13) onBlur() }}
          multiline
        />
      </div>
      : <div style={{ marginLeft: '2px' }}>
        {value}
        {selectedRowCell && <InputBase autoFocus onKeyDown={onKeyDown} value='' className={classes.inputHidden} />}
      </div>
    }
  </td>
}