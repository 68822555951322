export const statusRUS = {
    in_execution: { label:'к исполнению', color:'#900'},
    for_revision: { label:'на доработку', color:'#990'},
    corrected: { label:'исправлено', color:'#0a0'},
    closed: { label:'закрыто', color:'#999'},
}
export const status = ['in_execution','for_revision','corrected','closed',]
export const arrLocation = [
    { field: 'axes', label: 'Оси' },
    { field: 'ranks', label: 'Ряды' },
    { field: 'marks', label: 'Отметки' },
    { field: 'floors', label: 'Этажи' },
    { field: 'tiers', label: 'Ярусы' },
    { field: 'sections', label: 'Секции' },
    { field: 'premises', label: 'Помещения' },
]
