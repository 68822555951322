import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Select, MenuItem, InputLabel, FormControl, Typography, } from '@material-ui/core'
import ByIdProjectList from './source/ByIdProjectList'
import { getUserProject } from '../../../api/users'
import { fetchDispath } from '../../../functions/fetch'
import * as actions2 from '../../../reducers/formTable';
import { addUserDeleteProject, changeAccessUser, loadUser } from '../../../api/users'
import * as actionsForm from '../../../reducers/form';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid'


const roleExist = {
  admin: 'Админ',
  estimate: 'Сметчик',
  purchase: 'Закупщик',
  customer: 'Заказчик',
}

const formUser = 'userProject'
export default function UsersById({ history, match }) {
  const idUser = match.params.idUser
  const dispatch = useDispatch()
  const [changeRole, setChangeRole] = useState(false)
  const [role, setRole] = useState('')
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [patronymic, setPatronymic] = useState('')
  
  useEffect(() => {
    dispatch(actions2.initFormTable({ name: formUser }))
    return () => {
      dispatch(actions2.clearFormTable(formUser))
    }
  }, [dispatch])
  useEffect(() => {
    const fetchUser = {
      param: {
        id: idUser
      },
      progress: true,
    }
    // load user info
    dispatch(fetchDispath({ ...fetchUser, request: loadUser, }))
      .then(res => {
        setName(res.get.name)
        setRole(res.get.role)
        setSurname(res.get.surname)
        setPatronymic(res.get.patronymic||'')
      })
    // load user project
    dispatch(fetchDispath({ ...fetchUser, request: getUserProject, }))
      .then(res => {
        let arr = []
        res.get.forEach(el => {
          el.project.forEach(project => {
            arr.push({
              facility: el.name,
              id: project.id,
              project: project.name
            })
          })
        });
        dispatch(actions2.loadFormTable({ get: arr, count: arr.length }, { name: formUser, id: 'id' }))
      })
  }, [dispatch, idUser])
  const textEditText = (e) => {
    setChangeRole(true)
    setRole(e.target.value)
  }
  const handleShangeRole = (e) => {
    e.preventDefault();
    dispatch(actionsForm.formSubmitAPI({
      props: {
        role: role,
      },
      url: changeAccessUser.path({ id: idUser }),
      method: changeAccessUser.type,
    }))
  }
  const deleteProject = (id, obj) => {
    dispatch(actionsForm.formSubmitAPI({
      props: {
        delProject: [obj.id]
      },
      url: addUserDeleteProject.path({ id: idUser }),
      method: addUserDeleteProject.type,
      success: actions2.deleteFormTable({ id: id }, { name: formUser })
    }))
  }

  return <div className='App-paper'>
    <Typography variant='h6'>{`${surname} ${name} ${patronymic}`}</Typography>
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
    >
      <Grid item>
        <RoleField value={role} arr={roleExist} changeRole={textEditText} />
      </Grid>
      <Grid item>
        {changeRole && <Button type="submit" color='primary' onClick={handleShangeRole}><SaveIcon /></Button>}
      </Grid>
    </Grid>
    <Typography>Доступ к проектам</Typography>
    <ByIdProjectList deleteProject={deleteProject} idUser={idUser} formName={formUser} />
    <Button color='primary' onClick={() => history.goBack()}>Назад </Button>
  </div>
}
const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 240,
  },
}))
const RoleField = ({ value, arr, changeRole }) => {
  const classes = useStyles()
  return <FormControl className={classes.formControl}>
    <InputLabel htmlFor="select-role">Права доступа</InputLabel>
    <Select fullWidth
      value={value}
      onChange={changeRole}
      inputProps={{
        name: 'role',
        id: 'select-role',
      }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {Object.keys(arr).map(value => <MenuItem key={value} value={value}>{arr[value]}</MenuItem>)}
    </Select>
  </FormControl>
}