import React, { useState, useCallback } from 'react'
import { TextField, IconButton, Typography, Grid, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { formObjAdd, formArrayAdd, formObjDelete, formArrayDelete, formChange, formObjChange } from '../../../../reducers/form';
import DeleteIcon from '@material-ui/icons/Delete';
import WorkList from './WorkList';
import AddIcon from '@material-ui/icons/Add';

export default function WorkTab({ formName }) {
  const [value, setValue] = useState('')
  const [show, setShow] = useState(false)
  const [idRow, changeRow] = useState(0)
  const [keyOver, chngeKeyOver] = useState('')
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const changeArr = useCallback((arr) => dispatch(formChange(arr, { field: 'wkAllIds', name: formName })), [dispatch, formName])
  const addRow = (active) => {
    if (!active) return;
    dispatch(formObjAdd({ name: value }, {
      field: 'wkById',
      id: 'id' + (idRow + 1),
      name: formName,
    }))
    dispatch(formArrayAdd('id' + (idRow + 1), {
      field: 'wkAllIds', name: formName, end: true
    }))
    setValue('')
    changeRow(idRow + 1)
  }
  const { wkAllIds = [], wkById = {} } = form.values
  const deleteRow = (id) => {
    dispatch(formArrayDelete(id, { field: 'wkAllIds', name: formName }));
    dispatch(formObjDelete({ id: id, field: 'wkById', name: formName }));
  }
  const handleDragStart = (key) => {
    console.log('start drag', key)
  }
  const handleDragEnd = (key) => {
    if (key === keyOver) return;
    if (keyOver === '') return;
    const overId = wkAllIds.indexOf(keyOver)
    const arrDel = wkAllIds.filter(v => v !== key)
    const arr = [...arrDel.slice(0, overId), key, ...arrDel.slice(overId)]
    changeArr(arr)
    chngeKeyOver('')
  }
  const handleDragOver = (key) => {
    chngeKeyOver(key)
  }
  const addWork = (value) => {
    dispatch(formObjAdd({ name: value.label }, {
      field: 'wkById',
      id: 'id' + (idRow + 1),
      name: formName,
    }))
    dispatch(formArrayAdd('id' + (idRow + 1), {
      field: 'wkAllIds', name: formName, end: true
    }))
    changeRow(idRow + 1)
  }
  return <div>
    <Grid container>
      <Grid item style={{ width: 50 }}> <IconButton onClick={() => addRow(true)}  ><AddIcon /></IconButton></Grid>
      <Grid item xs>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => addRow(e.keyCode === 13)}
          fullWidth
        />
      </Grid>
    </Grid>


    <div>
      {wkAllIds.map((key, index) => {
        const obj = wkById[key]
        return <Row key={key} id={key} index={index + 1} obj={obj} action={{ deleteRow, handleDragStart, handleDragEnd, handleDragOver }} formName={formName} />
      })}
    </div>
    <p />
    <Button onClick={() => setShow(!show)}>{show ? 'Скрыть' : 'Показать шаблоны'}</Button>
    {show && <WorkList addWork={addWork} />}
  </div>
}

function Row({ id, obj, action, index, formName }) {
  const [edit, setEdit] = useState(false)
  const dispatch = useDispatch()
  return <div key={id} draggable
    // onDragStart={() => action.handleDragStart(id)}
    onDragEnd={() => action.handleDragEnd(id)}
    onDragOver={() => action.handleDragOver(id)}
  >
    <Grid
      container
      direction='row'
      justify="flex-start"
      alignItems="center"
    >
      <Grid item ><IconButton aria-label="Delete" onClick={() => action.deleteRow(id)} size='small' ><DeleteIcon /></IconButton></Grid>
      <Grid item style={{ paddingLeft: '1rem' }}><Typography>{index}</Typography></Grid>
      <Grid item style={{ paddingLeft: '1rem' }} xs={9}>{
        edit
          ? <TextField
            onBlur={() => setEdit(false)}
            onChange={(e) => dispatch(formObjChange(e.target.value, { id: id, field: 'wkById', fieldObj: 'name', name: formName }))}
            value={obj.name}
            fullWidth
            autoFocus
          />
          : <div onClick={() => setEdit(true)}><Typography>{obj.name}</Typography></div>
      }</Grid>
    </Grid>
  </div>
}