import React, { useState } from 'react'
import { Table, TableBody, TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

export default function GroupTableShare({ formGname, formRname }) {
  const tableGroup = useSelector(state => state.formTable[formGname] || state.formTable.default)
  const tableResource = useSelector(state => state.formTable[formRname] || state.formTable.default)
  const header = [
    { name: 'тип', id: 'type' },
    { name: '№', id: 'number' },
    { name: 'Наименование', id: 'name' },
    { name: 'Ед.изм.', id: 'storageUnit' },
    { name: 'Количество', align: 'right', id: 'count' },
    { name: 'Цена', align: 'right', id: 'price' },
    { name: 'Сумма', align: 'right', id: 'summ' },
  ]
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const handleRequestSort = (event, property) => {
    const orderBy = property;
    let orderNew = 'desc';
    if (orderBy === property && order === 'desc') {
      orderNew = 'asc';
    }
    setOrder(orderNew)
    setOrderBy(property)
  };
  return (<React.Fragment>
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>№ п.п.</TableCell>
          {header.map(obj => {
            const align = obj.align || 'inherit'
            return (<TableCell
              key={obj.id}
              align={align}
              sortDirection={orderBy === obj.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === obj.id}
                direction={order}
                onClick={(e) => handleRequestSort(e, obj.id)}
              >
                {obj.name}
              </TableSortLabel>
            </TableCell>);
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableGroup.allIds.map(id => {
          return <Row
            key={id}
            tableResource={tableResource}
            tableHead={header}
            group={tableGroup.byId[id]}
            order={order}
            orderBy={orderBy}
          />
        })}
      </TableBody>
    </Table>
  </React.Fragment>)
}

function desc(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}
function stableSort(array, objs, orderBy, order) {
  const stabilizedThis = array.map(key => ([key, objs[key][orderBy]]));
  const ord = order === 'desc' ? 1 : -1
  stabilizedThis.sort((a, b) => {
    return ord * desc(a[1], b[1]);
  });
  return stabilizedThis.map(el => el[0]);
}

function Row({ tableHead, tableResource, group, order, orderBy }) {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell colSpan={7}>
          <Typography variant='h6'>{group.number + '. ' + group.name}</Typography>
        </TableCell>
        <TableCell colSpan={2}>
          <Typography variant='h6'>{(Math.round(100 * group.ressumm) / 100).toLocaleString()}</Typography>
        </TableCell>
      </TableRow>
      {
        stableSort(group.res, tableResource.byId, orderBy, order)
          .map((id, i) => {
            const obj = tableResource.byId[id];
            return <TableRow key={id}>
              <TableCell>{group.number + '.' + (i + 1)}</TableCell>
              {tableHead.map(e => {
                return <TableCell key={e.id} align={e.align}>{obj[e.id]}</TableCell>
              })}
            </TableRow>
          })}
    </React.Fragment>
  );
}
