import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Table, TableHead, TableRow, TableBody, TableCell,
  List, ListItemText, ListItem, IconButton,
  Button, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core'
import { formObjChangeObj, formObjChange } from '../../../../reducers/form';
import { makeStyles } from '@material-ui/core/styles';
import AutoComplite from '../../../../components/Autocomplite'
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';

export default function TableAddUserProject({ formName, tableName }) {
  const [open, setOpen] = useState(false)
  return <div>
    <Button onClick={() => setOpen(true)} color='primary'>Добавить</Button>
    <DialogSelectProject formName={formName} tableName={tableName} open={open} hangleOpenChange={(v) => setOpen(v)} />
    <TableProject formName={formName} tableName={tableName} />
  </div>
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
  dialogContent: {
    heigth: '30vh',
    zIndex: 2
  },
  root: {
    minHeigth: '500',
    height: '400'
  },
  selected: {
    background: '#bbbbbb'
  },
  auto: {
  }
}))

function DialogSelectProject({ formName, tableName, open, hangleOpenChange }) {
  const classes = useStyles()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const arffFacility = useSelector(state => state.autocomplite[tableName] || state.autocomplite.default)
  const [selectedProject, setSelected] = useState([])
  const [valueFacility, setFacility] = useState({ value: '', label: '' })
  const dispatch = useDispatch()
  const hangleAddProject = (arrId) => {
    selectedProject.forEach(id => {
      dispatch(formObjChangeObj({ selected: true }, { field: 'pById', id: id, name: formName }))
    })
    setSelected([])
    hangleOpenChange(false)
  }
  const addArr = (id) => setSelected([...selectedProject, id])
  const dellArr = (id) => setSelected(selectedProject.filter(key => key !== id))
  const { pAllIds = [], pById = {} } = form.values
  let arrProject = pAllIds.filter(key => {
    const obj = pById[key]
    return !obj.selected && String(obj.idFacility) === valueFacility.value
  })
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => hangleOpenChange(false)}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">Добавить проект</DialogTitle>
      <DialogContent >
        <AutoComplite
          arr={arffFacility.arr}
          action={value => { setFacility(value); setSelected([]) }}
          value={valueFacility}
          formName={formName}
          palaseHolder='Объект'
        />
        <Button onClick={() => setSelected(arrProject)} color='primary'>Выделить все</Button>
        <List component="nav" aria-label="main mailbox folders">
          {arrProject.map(key => {
            const obj = pById[key]
            const selected = selectedProject.includes(key)
            return <ListItem
              key={key}
              button
              onClick={() => selected ? dellArr(key) : addArr(key)}
              className={classNames({ [classes.selected]: selected, })}
            >
              <ListItemText primary={obj.name} />
            </ListItem>
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={hangleAddProject} color="primary" autoFocus>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
}



function TableProject({ formName, tableName }) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const arffFacility = useSelector(state => state.autocomplite[tableName] || state.autocomplite.default)
  const deleteRow = (id) => {
    dispatch(formObjChange(false, { id: id, field: 'pById', fieldObj: 'selected', name: formName }))
  }
  const { pAllIds = [], pById = {} } = form.values
  return <Table>
    <TableHead>
      <TableRow>
        {['Объект', 'Проект'].map((name, i) => <TableCell key={i}>{name}</TableCell>)}
        <TableCell style={{ width: 10 }} padding={'none'}/>
      </TableRow>
    </TableHead>
    <TableBody>
      {pAllIds.filter(key => pById[key].selected).map(key => {
        const obj = pById[key]
        const facility = arffFacility.arr.find(v => v.value === obj.idFacility.toString())
        return <TableRow key={key}>
          <TableCell>{facility.label}</TableCell>
          <TableCell>{obj.name}</TableCell>
          <TableCell style={{ width: 10 }} padding={'none'}>
            <IconButton onClick={()=>deleteRow(key)}  ><DeleteIcon /></IconButton>
          </TableCell>
        </TableRow>
      })}
    </TableBody>
  </Table>
}