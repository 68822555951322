import React from 'react';
import PageList from '../../components/PageListAPI'
import can from '../../functions/can'
import { getWorker, deleteWorker } from '../../api/project-worker'
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core'
function ProjectWorker(props) {
  const toLinkEnding = React.forwardRef((props1, ref) => (<Link ref={ref} to='worker/ending' {...props1} />));
  return (
    <div className='App-paper'>
      <PageList
        name='project-worker'
        title='Работники на проекте'
        columns={[
          { id: 'surname', name: 'Фамилия', },
          { id: 'name', name: 'Имя' },
          { id: 'patronymic', name: 'Отчество', },
          { id: 'dateStart', name: 'Начало работы', type: 'date', formatDate: 'DD.MM.YYYY' },
          { id: 'dateEnd', name: 'Окончание работы', type: 'date', formatDate: 'DD.MM.YYYY' },
        ]}
        createItem={can('worker:create')}
        deleteItem={can('worker:delete')}
        loadAPI={{
          request: getWorker,
          querty: {
          },
          param: {
            id: props.match.params.idProject
          }
        }}
        deleteAPI={deleteWorker(props.match.params.idProject)}
        click={false}
        headerLink={
        <Button key='load-grand' component={toLinkEnding} color="primary" >Убрать из проекта</Button>
      }
        {...props}
      />
    </div>
  )
};

export default ProjectWorker

