import React from 'react';
import PageList from '../../components/PageListAPI'
import { moveStorage } from '../../api/inventory'

export default function InventoryMoveStorage(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='position'
        title='Переместить инвентарные объекты на склад'
        columns={[
          { id: 'id', name: 'Номер' },
          { id: 'userName', name: 'Добавил' },
          { id: 'storageName', name: 'Склад' },
        ]}
        createItem={true}
        deleteItem={true}
        loadAPI={{
          request: moveStorage.getList,
          querty: {
            idProject: props.match.params.id
          }
        }}
        click={false}
        deleteAPI={moveStorage.deleteById}
        {...props}
      />
    </div>
  )
};