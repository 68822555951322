import React from 'react';
import PageList from '../../components/PageListAPI'
import { getDelivery, deleteDelivery } from '../../api/acceptance'

export default function AcceptanceDoc(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='deliveryDoc'
        title='Акты приемки материалов'
        columns={[
          { id: 'date', name: 'Дата создания', type: 'date', formatDate: 'LL' },
          {
            id: 'typeRelated', name: 'тип', type: 'component', component: ({ obj }) => {
              switch (obj.typeRelated) {
                case 'purchasemtp': return 'материалы'
                case 'customer': return 'материалы'
                case 'movemtp': return 'склад'
                case 'purchaseequip': return 'оборудование';
                default: return ''
              }
            }
          },
          { id: 'name', name: 'Склад/Поставщик' },
          { id: 'number', name: 'Номер накладной' },
          { id: 'dateAccept', name: 'Дата накладной', type: 'date', formatDate: 'DD.MM.YYYY' },
          { id: 'userName', name: 'Принял' },
        ]}
        createItem={false}
        deleteItem={true}
        loadAPI={{
          request: getDelivery,
          querty: {
            idProject: props.match.params.id
          }
        }}
        filterProps={[
          {
            arr: [{ value: 'purchasemtp', name: 'материалы' }, { value: 'movemtp', name: 'склад' }, { value: 'purchaseequip', name: 'оборудование' }],
            field: 'typeRelated',
            name: 'Тип',
            type: 'select'
          },
        ]}
        {...props}
        deleteAPI={deleteDelivery}
        headerInit={{
          sortBy: 'date',
          sortDirection: 'desc',
        }}
      />
    </div>
  )
}

