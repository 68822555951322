import React from 'react';
import { Grid, Button } from '@material-ui/core'
import { useSelector } from 'react-redux';
import moment from 'moment'
import 'moment/locale/ru';
import { getUseToolById } from '../../../../api/doc'
import PageList from '../../../../components/PageListAPI'
import GridProps from '../../../../components/GridProps'

const formName = 'usetoolbyid'
export default function UseToolById({ history, match, location }) {
  return <div className='App-paper'>
    <PageList
      name={formName}
      columns={[{ id: 'inventoryNumber', name: 'Инвентарный номер' }, { id: 'name', name: 'Наименование' }]}
      pagination={false}
      loadAPI={{
        request: getUseToolById,
        param: {
          id: match.params.id,
          idProject: match.params.idProject,
        },
        transform: (res => {
          return {
            count: res.count,
            get: res.get.map(obj => ({ name: obj.name, id: obj.inventoryNumber, inventoryNumber: obj.inventoryNumber.toString().padStart(5, '0') })),
            obj: {
              gName: res.doc.gName + ' ' + res.doc.gSurname,
              worker: res.doc.surname + ' ' + res.doc.name + ' ' + res.doc.patronymic,
              position: res.doc.position,
              date: res.doc.date,
            }
          }
        }),
      }}
      headerInit={{
        obj: {
          gName: '',
          worker: '',
          position: '',
          date: new Date(),
        }
      }}
      tableProps={{
        pagination: false,
      }}
      click={false}
      headerLink={<Header formName={formName} />}
      csv={false}
      history={history}
      location={location}
      match={match}
      deleteItem={false}
      goBack={false}
      />
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}

function Header({ formName }) {
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { obj } = formTable
  return <div>
    <Grid
      item
      container
      xs={9}
    >
      <GridProps name='Выдал' value={obj.gName} />
      <GridProps name='Получил' value={obj.worker} />
      <GridProps name='Должность' value={obj.position} />
      <GridProps name='Дата' value={moment(obj.date).format('LLL')} />
    </Grid>
  </div>
}