import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchDispath } from '../functions/fetch'
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { enqueueSnackbar } from '../reducers/notifier'

export default function DeleteButton({ isDisabled, deleteAPI, idDoc, history }) {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const handleDeleted = () => {
    dispatch(fetchDispath({
      param: {
        id: idDoc
      },
      progress: false,
      request: deleteAPI
    }))
      .then((res) => {
        if (res) {
          dispatch(enqueueSnackbar({
            message: `success`,
            options: { variant: 'success' }
          }))
          history.goBack()
        }
        else {
          dispatch(enqueueSnackbar({
            message: `not deleted`,
            options: { variant: 'error' }
          }))
        }
      })
  }
  return <React.Fragment>
    <Button color='primary' onClick={() => setOpen(true)} disabled={isDisabled} >Удалить</Button>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{"Удалить документ?"}</DialogTitle>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Отмена
        </Button>
        <Button onClick={handleDeleted} color="primary" autoFocus>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}