import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, Typography } from '@material-ui/core';
import * as actions from '../../reducers/materialLoad';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Drawer from './DrawerColumnEdit'


export default function ColumnEdit() {
  const materialLoad = useSelector(state => state.materialLoad)
  const { column, columnNew, allIds, byId } = materialLoad
  const rowName = byId[allIds[0]]
  return <div>
    <Drawer />
    <Table>
      <TableBody>
        {Object.keys(column).filter(key => column[key].visible).map(key =>
          <ColumnRow key={key} id={key} name={rowName[key] || ''} columnNew={columnNew[key] || ''} />
        )}
      </TableBody>
    </Table>
  </div>
}
function ColumnRow({ id, name, columnNew }) {
  const dispatch = useDispatch()
  const arr = [
    { label: 'Наименование', value: 'name' },
    { label: 'Номер', value: 'number' },
    { label: 'Количество', value: 'count' },
    { label: 'Ед. изм.', value: 'storageUnit' },
    { label: 'Стоимость единицы', value: 'price' },
    { label: 'Тэги', value: 'tags' },
  ]
  const changeSelect = (e) => {
    dispatch(actions.editColumnMaterialFromFile({ id: id, text: e.target.value }))
  }
  return <TableRow>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <IconButton aria-label="Delete"
        onClick={() => {
          dispatch(actions.selectColumnMaterialFromFile({ column: id, check: false }))
        }}>
        <DeleteIcon />
      </IconButton>
    </TableCell>
    <TableCell><Typography>{id}</Typography></TableCell>
    <TableCell><Typography>{name}</Typography></TableCell>
    <TableCell style={{ minWidth: 100 }}>
      <Select
        native
        value={columnNew}
        onChange={(e) => changeSelect(e)}
      >
        <option value="" />
        {arr.map(obj => {
          return <option value={obj.value} key={obj.value}>{obj.label}</option>
        })}
      </Select></TableCell>
  </TableRow>
}