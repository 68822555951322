import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import { addWorker, getWorkerNotProject } from '../../api/project-worker'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'

export default function ProjectWorkerAdd({ match, history }) {
  const formName = 'ProjectWorkerAdd'
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ dateStart: '', idWorker: '' }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const arrProps = [
    {
      field: 'dateStart', type: 'text', label: 'Дата начала работы',
      validator: [(value) => value !== ''], errorText: 'Поле обязательно', textProps: { type: 'date' }, labelProps: { shrink: true }
    },
    {
      field: 'idWorker', label: 'Работник', type: 'autoLoad',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        path: getWorkerNotProject.path({ id: match.params.idProject }),
        meta: {
          field: 'idWorker',
          value: 'id',
          labelFunc: (obj) => obj.surname + ' ' + obj.name + ' ' + obj.patronymic
        }
      }
    },

  ]
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: values,
      url: addWorker.path({ id: match.params.idProject }),
      method: addWorker.type,
      history: history
    }, formName))
  }
  return (<div className='App-paper'>
    <form autoComplete="off" onSubmit={handleSave}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd
          arr={arrProps}
          formName={formName}
          title='Добавить работника'
        />
      </div>
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>)
}