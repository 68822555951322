export default function print(action,props){
  action({
    progress: false,
    buff: true,
    ...props
  })
  .then(res=>{
    if(!res) return;
    const filename =  res.headers.get('Content-Disposition').split('filename=')[1].replace(/"/g,'');
    res.blob().then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);  
      }, 0); 
    })
  })
}