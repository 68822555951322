import React, { useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import HistoryIcon from '@material-ui/icons/History';
import LoadBar from './LoadBar'
import { Hidden, AppBar, Toolbar, CssBaseline, IconButton, Typography, Grid, Button } from '@material-ui/core';
import Avatar from './Avatar'
import ListButton from './ListButton'
import LeftDrawer from './LeftDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { openHistory } from '../../reducers/history';
import HistoryDrawer from './HistoryDrawer'
import { Link } from 'react-router-dom';
import { API_BO } from '../../conf'
import ProjectDescription from './ProjectDescription'
const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBar2: {
    zIndex: theme.zIndex.drawer + 2,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#AA0000'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  profileButton: {
    position: 'relative',
    marginRight: '10%',
    marginLeft: 'inherit',
    color: "inherit"
    ,
  },
  hide: {
    display: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1
  },
  butt: {
    display: 'flex',
  },
}));
export default function Header({ history, location }) {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const header = useSelector(state => state.header)
  const dispatch = useDispatch()
  return (
    <div className={classes.root}>
      <HistoryDrawer history={history} />
      <ProjectDescription location={location} />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar disableGutters={!open} style={{ backgroundColor: '#163dec' }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={() => setOpen(true)}
            size='small'
            className={classNames(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Grid
            container
            direction='column'
            justify="flex-start"
            alignItems="stretch"
          >
            <Hidden xsDown>
              <Grid
                item
                container
                direction="row"
                alignItems="stretch"
              >
                <Grid item xs={6}><ListButton /></Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  xs={6}
                >
                  <Grid item >
                    <IconButton
                      style={{ 'textTransform': 'capitalize' }}
                      color='inherit' size='small'
                      onClick={() => { dispatch(openHistory(true)) }}
                    >
                      <HistoryIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Button
                      color='inherit'
                      size='small'
                      component={React.forwardRef((props, ref) => (<Link ref={ref} to={API_BO + '/accounts'} {...props} />))}
                      style={{ 'textTransform': 'capitalize' }}>Аккаунты</Button>
                  </Grid>
                  <Grid item><Avatar history={history} /></Grid>
                  <Grid item >
                    <Typography color='textPrimary' style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {localStorage.getItem('accountName') || ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Grid item>
              <Typography color='inherit' style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{header.facilityName + ' ' + header.projectName}</Typography>
            </Grid>
          </Grid>
        </Toolbar>
        <div><LoadBar /></div>
      </AppBar>
      <Hidden xsDown>
        <LeftDrawer
          variant="permanent"
          open={open}
          handleDrawerClose={() => setOpen(!open)}
          history={history}
        />
      </Hidden>
      <Hidden smUp>
        <LeftDrawer
          variant="temporary"
          open={open}
          handleDrawerClose={() => setOpen(!open)}
          history={history}
        />
      </Hidden>
    </div>
  );
}