import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { addRowFormTable } from '../../../../reducers/formTable';
import { fetchDispath } from '../../../../functions/fetch'
import { Button, CircularProgress, Dialog, DialogContent, TextField, DialogTitle, DialogActions } from '@material-ui/core';
import { loadFileAPI } from '../../../../reducers/materialLoad';
import { addFile, delFile } from '../../../../api/list'
import { addMOPFileById } from "../../../../api/doc";


export default function ByIdAddFile({ idDoc, formName }) {
  const [loadingFile, setLoadingFile] = useState(false)
  const [newFile, setNewFile] = useState({ id: null, name: '', originalName: '' })
  const [newFileType, setNewFleType] = useState('')
  const [openD, setOpenD] = useState(false)
  const dispatch = useDispatch()
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoadingFile(true)
    let fileName = e.target.files[0].name
    dispatch(loadFileAPI({ file: e.target.files[0], path: addFile.path }))
      .then(res => {
        if (res.success) {
          setNewFile({ id: res.id, name: res.name, originalName: fileName })
          setOpenD(true)
        }
        setLoadingFile(false)
      })
      .catch()
  }
  const handleClose = () => {
    if (!newFile.id) {
      setOpenD(false)
      return;
    }
    dispatch(fetchDispath({
      param: {
        id: newFile.id,
      },
      progress: false,
      request: delFile,
      buff: true
    }))
      .then(res => {
        setNewFile({ id: null, name: '', originalName: '' })
        setOpenD(false)
      })
  }
  const handleOk = () => {
    if (!newFile.id) {
      setOpenD(false)
      return;
    }
    dispatch(fetchDispath({
      param: {
        id: idDoc,
      },
      body: {
        idFile: newFile.id,
        type: newFileType
      },
      progress: false,
      request: addMOPFileById,
      buff: true
    }))
      .then(res => {
        dispatch(addRowFormTable({
          id: newFile.id,
          type: newFileType,
          name: newFile.name,
          originalName: newFile.originalName
        }, { name: formName, end: true }))
        setNewFile({ id: null, name: '', originalName: '' })
        setOpenD(false)
      })
  }
  return <React.Fragment>
    <Button disabled={loadingFile} component="label" color='primary'>
      {loadingFile && <CircularProgress size={25} />}
      <span>Прикрепить файл</span>
      <input
        type="file"
        style={{ display: "none" }}
        onChange={(e) => _handleImageChange(e)}
      />
    </Button>
    <Dialog
      open={openD}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">Тип файла</DialogTitle>
      <DialogContent >
        <TextField
          fullWidth
          margin="dense"
          value={newFileType}
          onChange={(e) => { setNewFleType(e.target.value) }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button onClick={handleOk}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}

