export const docType = {
  UTD: 'УПД',
  TORG12: 'ТОРГ12',
  VAT_INVOICE: 'VAT_INVOICE',
  OFFER: 'OFFER',
  CERTIFICATE: 'CERTIFICATE',
  RECEIPT: 'RECEIPT',
  NONSTANDARD: 'NONSTANDARD',
  UNKNOWN: 'неизвестный'
}
export const autoVat = [
  { value: 'NO_VAT', label: '' },
  { value: 'PERCENT_0', label: '0%' },
  { value: 'PERCENT_10', label: '10%' },
  { value: 'PERCENT_18', label: '18%' },
  { value: 'PERCENT_20', label: '20%' },
]
export const positionDefault = { topY: 1, leftX: 1, rightX: 1, bottomY: 1, pageNumber: 1 }
