import React from 'react'
import { Link } from 'react-router-dom';
import { API_BO } from '../../conf'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList'
const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  main: {
    margin: theme.spacing(1)
  },
  btn: {
    margin: theme.spacing(1)
  },
  text: {
    fontSize: 14,
  },
});

class WorkPlace extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: [
        { key: 1, name: 'Отчет по работе', to: API_BO + '/projects/' + props.match.params.idProject + '/aoc/add' },
        { key: 2, name: 'Заказать материалы', to: API_BO + '/projects/' + props.match.params.idProject + '/orders/materials/add' },
        { key: 3, name: 'Списать со склада', to: API_BO + '/projects/' + props.match.params.idProject + '/storage/materials/removal/add' }
      ],
      admin: [],
      purchase: [],
      estimate: [],
    }
  }
  toLinkAdd = React.forwardRef((props, ref) => (<Link ref={ref} to={`${this.props.match.url}/add`} {...props} />))
  render() {
    const role = localStorage.getItem('role')
    const { classes } = this.props
    return <div>
      <GridList cellHeight={160} className={classes.gridList}>
        {this.state[role].map(obj => {
          return <OnePlaceCustom {...obj} />
        })}
      </GridList>
    </div>
  }
}

export default withStyles(styles)(WorkPlace)

const stylesCard = theme => ({
  card: {
    maxWidth: 270,
    margin: theme.spacing(1),
    backgroundColor: '#8C98DA',
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
});

class OnePlace extends React.Component {
  toLink = React.forwardRef((props, ref) => (<Link ref={ref} to={this.props.to} {...props} />))
  render() {
    return (<Card className={this.props.classes.card}>
      <CardActionArea component={this.toLink} color="primary" >
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {this.props.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    );
  }
}

const OnePlaceCustom = withStyles(stylesCard)(OnePlace);