import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { formObjAdd, formArrayAdd, formObjChangeObj } from '../../../../reducers/form';
import { Table, TableHead, TableBody, TableRow, TableCell, IconButton, CircularProgress } from '@material-ui/core'
import { loadFileAPI } from '../../../../reducers/materialLoad';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete'
import { getFile } from '../../../../api/list'
import GetAppIcon from '@material-ui/icons/GetApp';
import { fetchDispath } from '../../../../functions/fetch'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  row: {
    color: '#000000',
    '&$selected': {
      color: '#aaaaaa'
    }
  },
  selected: {},
}));
export default function FileTabEdit({ formName }) {
  const [idRow, setRow] = useState(0)
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const deleteRow = (id, obj) => {
    dispatch(formObjChangeObj({ deleted: !obj.deleted }, { field: 'fById', id: id, name: formName }))
  }
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoading(true)
    let fileName = e.target.files[0].name
    dispatch(loadFileAPI({ file: e.target.files[0], path: `/files/add` }))
      .then(res => {
        if (res.success) {
          console.log(res, { id: res.id, name: fileName })
          dispatch(formObjAdd({ id: res.id, name: fileName, deleted: false }, {
            field: 'fById',
            id: 'id' + (idRow + 1),
            name: formName
          }))
          dispatch(formArrayAdd('id' + (idRow + 1), {
            field: 'fAllIds', name: formName
          }))
          setRow(idRow + 1)
        }
        setLoading(false)
      })
      .catch()
  }
  const handleClick = (obj) => {
    dispatch(fetchDispath({
      param: {
        id: obj.name,
      },
      progress: false,
      request: getFile,
      buff: true
    }))
      .then(res => {
        if (!res) return;
        res.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = obj.name;
          document.body.appendChild(a);
          a.click();
          setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
        })
      })
  }
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const { fAllIds = [], fById = {} } = values
  return <div>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 10 }} padding='none'>
            <IconButton disabled={loading} component="label">
              {loading ? <CircularProgress size={25} /> : <AddIcon />}
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => _handleImageChange(e)}
              />
            </IconButton>
          </TableCell>
          <TableCell>Название</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fAllIds
          .map(row => {
            const obj = fById[row];
            return <TableRow key={row} selected={obj.deleted} classes={{ root: classes.row, selected: classes.selected, }}>
              <TableCell style={{ width: 10 }} padding={'none'}>
                <IconButton onClick={() => deleteRow(row, obj)}  ><DeleteIcon /></IconButton>
              </TableCell>
              <TableCell>{obj.name}</TableCell>
              <TableCell style={{ width: 10 }} padding={'none'} align='right'>
                <IconButton onClick={() => handleClick(obj)}><GetAppIcon /></IconButton>
              </TableCell>
            </TableRow>
          })}
        <TableRow style={{ height: 40 * 5 }}>
          <TableCell colSpan={8} />
        </TableRow>
      </TableBody>
    </Table>
  </div>
}