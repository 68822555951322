import React from 'react'
import { Star } from '@material-ui/icons'
export default function Rating({ value }) {
  const star = Number(value)
  const gold = Math.floor(star)
  const half = star - Math.floor(star)
  const grey = 5 - Math.ceil(star)
  if (star === 0) return <div></div>
  let item = []
  for (let i = 0; i < gold; i++) {
    item.push(<Star key={'g'+i} style={{ color: '#EB922A' }} />)

  }
  if (half > 0) {
    item.push(<svg key='h' className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ color: "rgb(235, 146, 142)" }}>
      <defs>
        <linearGradient id="half_grad">
          <stop offset={Math.round(100 * half) + '%'} stopColor="#EB922A" />
          <stop offset={Math.round(100 * (1 - half)) + '%'} stopColor="#C2C2C2" stopOpacity="1" />
        </linearGradient>
      </defs>
      <path fill="url(#half_grad)" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
    </svg>)
  }
  for (let i = 0; i < grey; i++) {
    item.push(<Star key={'gr'+i} style={{ color: '#C2C2C2' }} />)
  }
  return item
}
