import React from 'react';
import { getOutfitPlan, deleteOutfitPlanById } from '../../../api/doc'
import PageList from '../../../components/PageListAPI'


export default function OufitPlanList({ history, match, location }) {
  return <div className='App-paper'>
    <PageList
      name='outfitPlan'
      title='Наряд заказ'
      columns={[
        { id: 'id', name: '№' },
        { id: 'date', name: 'Дата наряда', type: 'date', formatDate: 'LL' },
        { id: 'shortDescription', name: 'Краткое описание', },
        { id: 'name', name: 'Добавил', },
      ]}
      createItem={true}
      deleteItem={true}
      idName='id'
      history={history}
      match={match}
      location={location}
      loadAPI={{
        param: {
          id: match.params.idProject
        },
        request: getOutfitPlan,
      }}
      disabledDelete={(obj)=>typeof obj.idFact ==='number'}
      deleteAPI={deleteOutfitPlanById(match.params.idProject)}
      headerInit={{
        sortBy:'date', 
        sortDirection:'desc',          
      }}
    />
  </div>
}