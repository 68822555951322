import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import DocTable from '../../components/DocTable'
import DocAdd from '../../components/DocAdd'
import { getUserList } from '../../api/users'
import { addWorkDocOne } from '../../api/workdoc'

const formName = 'workDocAdd'
const arrUser = 'user'
export default function WorkDocAdd ({ match, history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ byId: {}, allIds: [], title: '' }, { name: formName }))
    dispatch(initialAutocomplite({ field: arrUser }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite(arrUser))
    }
  }, [dispatch])
  // load arr
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getUserList.path, {
      field: arrUser,
      value: 'id',
      labelFunc: (obj) => obj.name + ' ' + obj.surname
    }))
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const saveServer = () => {
    console.log(values.byId)
    console.log(Object.values(values.byId).map(e=>e.idUser))
    dispatch(actions.formSubmitAPI({
      props: {
        users: Object.values(values.byId).map(e=>e.idUser),
        name: values.title,
      },
      url: addWorkDocOne.path,
      method: addWorkDocOne.type,
      history: history
    }, formName))
  }
  return <div className='App-paper'>
    <DocAdd
      formName={formName}
      history={history}
      title='Новая рабочая документация'
      noTableProps={[
        { field: 'title', label: 'Заголовок', type: 'text', textProps: { type: 'text' }, xs: 6 },
      ]}
      handleSave={saveServer}
    >
      <DocTable
        formName={formName}
        column={[
          {
            id: 'valueUser', type: 'auto', name: 'Пользователь', formArr: arrUser,
            filtered: true, 
            changeValue: e => ({
              idUser: e.value,
              valueUser: e,
            }),
            // width: '30%'
          },
        ]}
        rowDefault={{
          idUser: '',
          valueUser: null,
        }}
      />
    </DocAdd>
  </div>
}