import React, { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import CSV from 'comma-separated-values';

export default function CsvViewer({ data, height }) {
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  useEffect(() => {
    const rows = [];
    const columns = [];
    new CSV(data).forEach((array) => {
      if (columns.length < 1) {
        array.forEach((cell, idx) => {
          columns.push({
            key: `key-${idx}`,
            name: idx,
            resizable: true,
          });
        });
      }
      const row = {};
      array.forEach((cell, idx) => {
        row[`key-${idx}`] = cell;
      });
      rows.push(row);
    });
    setRows(rows)
    setColumns(columns)
  }, [data])
  return (
    <ReactDataGrid
      columns={columns}
      rows={rows}
      minHeight={height || 650}
    />
  );
}