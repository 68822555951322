import React from 'react'
import { connect } from 'react-redux';
import { fetchDispath } from '../../../functions/fetch'
import Select from 'react-select';
import NoSsr from '@material-ui/core/NoSsr';
import { components, styles } from '../../../components/AutocompliteComponents'
import { withStyles } from '@material-ui/core/styles';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);


class AutocompliteWithLoad extends React.Component {
  state = { arr: [], }
  componentDidMount() {
    this.props.actions.fetchDispath({
      progress: true,
      request: { path: '/projects', type: 'GET' },
    }).then(res => {
      const obj = {}
      res.get.forEach(e => {
        if (obj[e.idFacility]) {
          obj[e.idFacility].options.push({ value: e.id, label: e.name })
        }
        else {
          obj[e.idFacility] = {
            options: [{ value: e.id, label: e.name }],
            label: e.facility
          }
        }
      });
      this.setState({ arr: Object.values(obj) })
    }).catch(err => console.log(err))

  }
  render() {
    const { handleChange } = this.props
    console.log(this.state.arr)
    return <div>
      <NoSsr>
        <Select
          classes={this.props.classes}
          components={components}
          onChange={handleChange}
          placeholder='Выберите проект'
          native
          isClearable
          isMulti
          options={this.state.arr}
          formatGroupLabel={formatGroupLabel}
        />
      </NoSsr>
    </div>
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      fetchDispath: obj => dispatch(fetchDispath(obj)),
    }
  }
}
export default connect(undefined, mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AutocompliteWithLoad));
