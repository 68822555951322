import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableCell } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as actions2 from '../../../reducers/form';

const useStylesCellFirst = makeStyles(theme => ({
  cell: {
    cursor: 'pointer'
  },
  selected: {
    backgroundColor: '#15b000'
  },
  selectCell: {
    backgroundColor: '#97bf91'
  },
  div: {
    mozUserSelect: 'none',
    khtmlUserSelect: 'none',
    userSelect: 'none',
  }
}));

export default function CellFirst ({ row, value, formSelected }) {
  const classes = useStylesCellFirst()
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formSelected] || state.form.default)
  const { selectRow } = form.values
  const onClick = () => {
    dispatch(actions2.formChangeAsObj({
      selectRow: row,
      selectCellRow: '',
      selectCellColumn: ''
    }, { name: formSelected }))
  }
  return <TableCell
    className={classNames(classes.cell, {
      [classes.selected]: selectRow === row,
      [classes.unselected]: selectRow !== row,
    })}
    onClick={onClick}
  ><div className={classes.div}>{value}</div>
  </TableCell>
}
