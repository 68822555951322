import { getProjectId, postProjectId, deleteProjectById } from "./template_crud";
export const getWorkerById = {
  path: (obj) => `/projects/${obj.id}/worker/${obj.idWorker}`,
  type: 'GET'
}
export const getWorker = getProjectId('/worker');
export const getWorkerNotProject = getProjectId('/worker/notproject');
export const getWorkerAttend = getProjectId('/worker/attend');
export const getWorkerResource = getProjectId('/worker/resource');
export const setWorkerState = {
  path: (obj) => `/projects/${obj.id}/worker/${obj.idWorker}/state`,
  type: 'POST'
}
export const addWorker = postProjectId('/worker');
export const endWorker = postProjectId('/worker/ending');
export const deleteWorker =deleteProjectById('/worker');
