// ts-check
import React from 'react';
import PageList from '../../../components/PageListAPI'
import * as api from '../../../api/list'

export default function Machine(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='Machine'
        title='Техника'
        columns={[
          { id: 'govNumber', name: 'Гос. номер', width: 150 },
          { id: 'model', name: 'Модель' },
        ]}
        createItem={true}
        deleteItem={true}
        loadAPI={{
          request: api.getMachine,
        }}
        deleteAPI={api.deleteMachine}
        {...props}
      />
    </div>
  )
}