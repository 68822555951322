import React from 'react';
import PageList from '../../components/PageListAPI'
import { getInventory, getInventoryEquipment } from '../../api/inventory'
import { getWorkerList } from '../../api/worker'
export default function InventoryProjectList(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='InventoryProjectList'
        title='Список инвентарных объектов на складе'
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'inventoryNumber', name: 'Инвентраный номер' },
          { id: 'serialNumber', name: 'Серийный номер' },
          { id: 'fullName', name: 'Работник' },
        ]}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getInventory,
          querty: {
            all: 1,
            idProject: props.match.params.id
          }
        }}
        filterProps={[
          {
            type: 'autocomplite',
            path: getWorkerList.path,
            field: 'idWorker',
            name: 'Работник',
            meta: {
              labelFunc: (obj) => obj.surname + ' ' + obj.name[0] + '.' + obj.patronymic[0],
            }
          },
          {
            path: getInventoryEquipment.path,
            field: 'idEquipment',
            name: 'Тип',
          },
        ]}
        click={false}
        {...props}
      />
    </div>
  )
}