import React, { useEffect, useState } from 'react';
import { Button, Tabs, Tab, Typography, Table, TableBody, TableCell, TableHead, TableRow, } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import { getTenderOurById } from '../../api/tender.api'
import moment from 'moment'
import { TENDER_STATUS } from './TenderOurList'
import { Link } from 'react-router-dom';

const formName = 'TenderOurOneHeader'
const formContacts = 'TenderContact'
const formLots = 'TenderLots'
export default function TenderOurOne({ match, history }) {
  const dispatch = useDispatch()
  const [tabsId, setTabsId] = useState(0)
  useEffect(() => {
    dispatch(actions.formInitialize({
      name: '',
      statusRus: '',
      facilityName: '',
      typeTenderRus: '',
      dateConfirmStart: '',
      dateConfirmEnd: '',
      dateOfferEnd: '',
      dateSummingEnd: '',
    }, { name: formName }))
    dispatch(actions.formInitialize({ byId: {}, allIds: [], }, { name: formContacts }))
    dispatch(actions.formInitialize({ projectsArr: [], projectsObj: {}, estimateObj: {} }, { name: formLots }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formContacts }))
      dispatch(actions.formDestroy({ name: formLots }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getTenderOurById,
      param: {
        id: match.params.idDoc
      }
    })).then(res => {
      dispatch(actions.formChangeAsObj({
        name: res.get.name,
        facilityName: res.get.facilityName,
        dateConfirmStart: moment(res.get.dateConfirmStart).format('LLL'),
        dateConfirmEnd: moment(res.get.dateConfirmEnd).format('LLL'),
        dateOfferEnd: moment(res.get.dateOfferEnd).format('LLL'),
        dateSummingEnd: moment(res.get.dateSummingEnd).format('LLL'),
        typeTenderRus: res.get.typeTender === 'open' ? 'открытый' : 'закрытый',
        statusRus: TENDER_STATUS.find(s => s.value === res.get.status).label,
        regionsArr: res.get.regions,
        regions: res.get.regions.join(', '),
        specializationsArr: res.get.specializations,
        specializations: res.get.specializations.join(', '),
      }, { name: formName }))
      let byId = {}
      const allIds = res.get.contacts.map(e => {
        const newId = 'id' + e.id;
        byId[newId] = { ...e, fio: [e.surname, e.name, e.patronymic].join(' ') }
        return newId
      });
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formContacts }))
      const { estimate, projects } = res.get.estimate;
      const projectsArr = []
      let projectsObj = {}
      projects.forEach(obj => {
        projectsArr.push(obj.id)
        let groupObj = {}
        const groupArr = obj.group.map(e => {
          groupObj[e.id] = { ...e, child: [] };
          return e.id
        })
        projectsObj[obj.id] = {
          id: obj.id,
          dateStart: moment(obj.dateStart).format('DD.MM.YYY'),
          dateEnd: moment(obj.dateEnd).format('DD.MM.YYY'),
          name: obj.name,
          group: {
            arr: groupArr,
            obj: groupObj,
          }
        }
      })
      let estimateObj = {}
      estimate.forEach(e => {
        estimateObj[e.id] = {
          ...e,
          dateStart: moment(e.dateStart).format('DD.MM.YYY'),
          dateEnd: moment(e.dateEnd).format('DD.MM.YYY'),
          typeEstimate: e.typeEstimate === 'material' ? 'М' : 'Р'
        }
        projectsObj[e.idProject]?.group.obj[e.idGroup]?.child.push(e.id);
      })
      dispatch(actions.formChangeAsObj({ projectsArr, projectsObj, estimateObj }, { name: formLots }))

    }).catch(err => console.log(err))
  }, [dispatch, match.params.idDoc])
  return <div className='App-paper'>
    <HederProps />
    <Tabs value={tabsId} onChange={(e, v) => setTabsId(v)} indicatorColor='primary'>
      <Tab label='Контакты' />
      <Tab label='Лоты' />
      <Tab label='Подали заявки' />
    </Tabs>
    {tabsId === 0 && <Contacts />}
    {tabsId === 1 && <Lots match={match} />}
    {tabsId === 2 && <AccessTender />}
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}

const headerCol = [
  { id: 'name', name: 'Название' },
  { id: 'statusRus', name: 'Статус' },
  { id: 'facilityName', name: 'Объект' },
  { id: 'typeTenderRus', name: 'Тип тендера' },
  { id: 'dateConfirmStart', name: 'Дата начала подтверждения участия', },
  { id: 'dateConfirmEnd', name: 'Дата окончания подтверждения участия', },
  { id: 'dateOfferEnd', name: 'Дата окончания предложения', },
  { id: 'dateSummingEnd', name: 'Дата подведения итогов', },
  { id: 'regions', name: 'Регионы', },
  { id: 'specializations', name: 'Специализация', },
]
function HederProps() {
  const values = useSelector(state => state.form[formName]?.values) || {}
  return <div>
    {headerCol.map(obj => {
      return <div key={obj.id}>{obj.name}:  {values[obj.id]}</div>
    })}
  </div>
}
const contactsCol = [
  // { id: 'name', type: 'text', name: 'Имя' },
  // { id: 'surname', type: 'text', name: 'Фамилия' },
  // { id: 'patronymic', type: 'text', name: 'Отчество' },
  { id: 'fio', type: 'text', name: 'Имя' },
  { id: 'email', type: 'text', name: 'Почта' },
  { id: 'phone', type: 'text', name: 'Телефон' },
  { id: 'jobTitle', type: 'text', name: 'Должность' },
  { id: 'additionalContacts', type: 'text', name: 'Доп. контакт' },
]
function Contacts() {
  const values = useSelector(state => state.form[formContacts]?.values) || { byId: {}, allIds: [] }
  return <div>
    {values.allIds.map((key, i) => {
      return <div key={key}>
        <Typography>{i + 1}</Typography>
        {contactsCol.map(obj => {
          return <div>{obj.name}:  {values.byId[key][obj.id]}</div>
        })}
      </div>
    })}
  </div>
}
const tableHead = [
  { name: 'Номер', id: 'number' },
  { name: 'Тип', id: 'typeEstimate' },
  { name: 'Наименование', id: 'name' },
  { name: 'Ед.изм.', id: 'storageUnit' },
  { name: 'Количество', align: 'right', id: 'count' },
  { name: 'Дата начала', align: 'right', id: 'dateStart' },
  { name: 'Дата окончания', align: 'right', id: 'dateEnd' },
]
function Lots({ match }) {
  const values = useSelector(state => state.form[formLots]?.values) || { projectsArr: [], projectsObj: {}, estimateObj: {} }
  const toLinkAdd = React.forwardRef((propsRef, ref) => (<Link to={match.url + '/lots/add'} {...propsRef} />))
  return <div>
    <Button component={toLinkAdd} color="primary" >Добавить</Button>
    {values.projectsArr.map(key => {
      const project = values.projectsObj[key]
      return <div key={key} style={{ marginBottom: 20 }}>
        <Typography variant='h6'>{project.name}</Typography>
        <div>Дата работ с:  {project.dateStart} по:  {project.dateEnd}</div>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {tableHead.map(obj => {
                const align = obj.align || 'inherit'
                return (<TableCell key={obj.id} align={align}>{obj.name}</TableCell>);
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {project.group.arr.map(groupKey => {
              return <Row
                key={groupKey}
                obj={project.group.obj[groupKey]}
                child={project.group.obj[groupKey].child}
                estimate={values.estimateObj}
              />
            })}
          </TableBody>
        </Table>
      </div>
    })}
  </div>
}

function Row({ obj, child, estimate }) {
  const [show, changeShow] = useState(true)
  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: '#eee' }} onClick={() => changeShow(!show)}>
        <TableCell>{obj.numberPos}</TableCell>
        <TableCell colSpan={6}>{obj.name}</TableCell>
      </TableRow>
      {
        show && child.map((id, i) => {
          const est = { ...estimate[id], number: obj.numberPos + '.' + (i + 1) };
          return <TableRow key={id} >
            {tableHead.map(e => {
              return <TableCell key={e.id} align={e.align}>{est[e.id]}</TableCell>
            })}
          </TableRow>
        })}
    </React.Fragment>
  );
}

function AccessTender() {
  return <div>AccessTender</div>
}