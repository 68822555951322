import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from "react-redux";
import * as actions from '../../../reducers/formTable';
import { fetchDispath } from '../../../functions/fetch'
import { getOutfitFactById, getOutfitFactByIdPrint } from '../../../api/doc'
import { Button, Tab, Tabs, Grid, Typography, IconButton } from '@material-ui/core';
import PageList from '../../../components/PageListAPI'
import GridProps from '../../../components/GridProps'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { getFile } from '../../../api/list'
import GetAppIcon from '@material-ui/icons/GetApp';
import print from '../../../functions/print'
import DialogFile from '../orderOutfitPlan/source/DialogFile'
import VisibilityIcon from '@material-ui/icons/Visibility';
const useStyles = makeStyles(theme => ({
  container: {
    height: '100%'
  },
}));
export default function OutfitFactById({ match, history, location }) {
  const [value, setValue] = useState(0)
  const [visibleList, setVisibleList] = useState({ workE: false, mat: false, worker: false, work: false, files: false })
  const [byId, setById] = useState({ date: '', name: '', work: [] })
  const [fileName, setFileName] = useState('')
  const dispatch = useDispatch();
  const tableW = 'OutfitOPByIdW'
  const tableWE = 'OutfitOPByIdWE'
  const tableM = 'OutfitOPByIdM'
  const tableF = 'OutfitOPByIdF'
  const tableZ = 'OutfitOPByIdZ'
  useEffect(() => {
    dispatch(actions.initFormTable({ name: tableW }))
    dispatch(actions.initFormTable({ name: tableM }))
    dispatch(actions.initFormTable({ name: tableF }))
    dispatch(actions.initFormTable({ name: tableZ }))
    return () => {
      dispatch(actions.clearFormTable(tableM))
      dispatch(actions.clearFormTable(tableW))
      dispatch(actions.clearFormTable(tableF))
      dispatch(actions.clearFormTable(tableZ))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        idProject: match.params.idProject,
        id: match.params.id
      },
      progress: true,
      request: getOutfitFactById,

    }))
      .then((res) => {
        setById({ date: res.get.byId.date, name: res.get.byId.name, work: res.get.work.filter(e => e !== ''), description: res.get.byId.shortDescription })
        dispatch(actions.loadFormTable({ get: res.get.files }, { name: tableF, id: 'id' }))
        dispatch(actions.loadFormTable({ get: res.get.workE }, { name: tableWE, id: 'id' }))
        dispatch(actions.loadFormTable({ get: res.get.material }, { name: tableM, id: 'id' }))
        dispatch(actions.loadFormTable({ get: res.get.zhor }, { name: tableZ, id: 'id' }))
        dispatch(actions.loadFormTable({ get: res.get.worker.map(obj => ({ name: obj.surname + ' ' + obj.name + ' ' + obj.patronymic, position: obj.position })) }, { name: tableW, id: 'id' }))
        setVisibleList({
          mat: res.get.material.length > 0,
          files: res.get.files.length > 0,
          workE: res.get.workE.length > 0,
          worker: res.get.worker.length > 0,
          work: res.get.work.filter(e => e !== '').length > 0,
        })
      })
  }, [dispatch, match.params.idProject, match.params.id, setById])
  const handleClick = (obj) => {
    dispatch(fetchDispath({
      param: {
        id: obj.name,
      },
      progress: false,
      request: getFile,
      buff: true
    }))
      .then(res => {
        if (!res) return;
        res.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = obj.name;
          document.body.appendChild(a);
          a.click();
          setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
        })
      })
  }
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          idProject: match.params.idProject,
          id: match.params.id
        },
        request: getOutfitFactByIdPrint,
      }
    )
  }
  return <div className='App-paper'>
    <DialogFile fileName={fileName} />
    <Typography variant='h6'>Наряд заказ факт</Typography>
    <Button color='primary' onClick={handlePrint} >Печать </Button>
    <Grid
      item
      container
      xs={4}
    >
      <GridProps name='Описание' value={byId.description} />
      <GridProps name='Дата' value={moment(byId.date).format('LL')} />
      <GridProps name='Создал' value={byId.name} />
    </Grid>
    <Tabs value={value} onChange={(e, v) => setValue(v)} indicatorColor='primary'>
      <Tab label='Общий журнал работ' />
      <Tab label='Сметные работы' disabled={!visibleList.workE} />
      <Tab label='Материалы' disabled={!visibleList.mat} />
      <Tab label='Работники' disabled={!visibleList.worker} />
      <Tab label='Доп. Работы' disabled={!visibleList.work} />
      <Tab label='Файлы' disabled={!visibleList.files} />
    </Tabs>
    {value === 0 &&
      <PageListCustom
        name={tableZ}
        columns={[
          { id: 'work', name: 'Наименование работ' },
        ]}
        match={match}
        location={location}
        history={history}
      />
    }
    {value === 1 &&
      <PageListCustom
        name={tableWE}
        columns={[
          { id: 'groupName', name: 'Раздел' },
          { id: 'name', name: 'Наименование' },
          { id: 'count', name: 'Количество' },
          { id: 'storageUnit', name: 'Ед.изм' },
        ]}
        match={match}
        location={location}
        history={history}
      />
    }
    {value === 2 &&
      <PageListCustom
        name={tableM}
        columns={[
          { id: 'groupName', name: 'Раздел' },
          { id: 'name', name: 'Наименование' },
          { id: 'count', name: 'Количество' },
          { id: 'storageUnit', name: 'Ед.изм' },
        ]}
        match={match}
        location={location}
        history={history}
      />
    }
    {value === 3 &&
      <PageListCustom
        name={tableW}
        columns={[
          { id: 'name', name: 'ФИО' },
          { id: 'position', name: 'Должность' },
        ]}
        idName='id'
        match={match}
        history={history}
        location={location}
      />
    }
    {value === 4 && <div style={{ margin: '8px' }}>
      {(byId.work) && byId.work.map((v, i) => <React.Fragment key={i}>
        <Typography >{i + 1}. {v}</Typography>
        <hr className='App-divider' />
      </React.Fragment>
      )}
    </div>
    }
    {value === 5 &&
      <PageListCustom
        name={tableF}
        columns={[
          { id: 'name', name: 'Название' },
          { id: 'fileView', name: '', type: 'component', width: '5%', component: ({ obj }) => <IconButton onClick={() => setFileName(obj.name)}><VisibilityIcon /></IconButton> },

          { id: 'file', name: '', align: 'right', type: 'component', width: '5%', component: ({ obj }) => <IconButton onClick={() => handleClick(obj)}><GetAppIcon /></IconButton> },
        ]}
        match={match}
        history={history}
        location={location}
      />
    }
    <Button color='primary' onClick={() => history.goBack()}>Назад</Button>
  </div>
}

function PageListCustom({ name, columns, match, history, location }) {
  const classes = useStyles()
  return <PageList
    classes={classes}
    name={name}
    columns={columns}
    idName='id'
    csv={false}
    click={false}
    notLoad={true}
    notClear={true}
    match={match}
    history={history}
    location={location}
    pagination={false}
    goBack={false}
    tableProps={{ pagination: false }}
  />
}