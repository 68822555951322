import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import { getPositionById, updPosition } from '../../../api/list'
import { fetchDispath } from '../../../functions/fetch'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'

export default function PositionEdit({ history, match }) {
  const formName = 'StorageunitEdit'
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(actions.formInitialize({ name: '', errorValidate:{ name:false } }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getPositionById,
      param: {
        id: match.params.id
      }
    })).then(res => {
      dispatch(actions.formChange(res.get.name, { field: 'name', name: formName }))
    }).catch(err => console.log(err))
  }, [dispatch, match.params.id])
  const arrProps =[{ field: 'name', label: '  Наименование', type: 'text', validator: [value => value !==''], errorText: 'Поле обязательно'  }]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        name: values.name,
      },
      url: updPosition.path({ id: match.params.id }),
      method: updPosition.type,
      history: history
    }, formName))
  }
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Изменить должность'
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Изменить</Button>
      </form>
    </div>
  );
}