import React from 'react';
import Select from 'react-select';
import {  makeStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import { components, styles } from './AutocompliteComponents'


const useStyle = makeStyles(styles)
export default function Autocomplite ({
  arr,
  palaseHolder,
  value,
  action,
  selectProps,
  textFieldProps={}
}) {
  const classes = useStyle()
  return <div className={classes.root}>
    <NoSsr>
      <Select
        classes={classes}
        options={arr}
        textFieldProps={{
          label: palaseHolder,
          InputLabelProps: {
            shrink: true,
          },
          ...textFieldProps
        }}
        components={components}
        value={value}
        onChange={value=>action(value)}
        placeholder={palaseHolder}
        native
        {...selectProps}
      />
    </NoSsr>
  </div>
}