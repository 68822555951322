import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import checkCan from '../../functions/can'


function arrCard(arr, url) {
  return arr.map(({ check, to, image, name }) =>
    checkCan(check) && { to: url + to, image: process.env.PUBLIC_URL + image, name: name }
  ).filter(key => typeof key !== 'boolean')
}

function ProjectOne({ match }) {
  const arr = [
    { check: 'material:read', to: '/est-menu', image: '/estimate.jpg', name: 'Смета' },
    { check: 'material:read', to: '/documents', image: '/material.jpg', name: 'Снабжение' },
    { check: 'work:read', to: '/prodmenu', image: '/orders.jpg', name: 'Производство' },
    { check: 'storage:read', to: '/storage', image: '/storage.jpg', name: 'Склад' },
    { check: 'project:analytics', to: '/analytics', image: '/analytics.jpg', name: 'Аналитика' },
    { check: 'project:update', to: '/settings', image: '/settings.jpg', name: 'Настройка' },
  ]
  const arrCardForm = arrCard(arr, match.url)
  return (
    <Grid container>
      {arrCardForm.map((obj, index) => (obj) ?
        <Grid item key={index}>
          <ImgMediaCard {...obj} />
        </Grid>
        :
        null)
      }
    </Grid>
  )
}

export default ProjectOne;

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 120,
    margin: theme.spacing(1)
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
}));

function ImgMediaCard({ image, name, to }) {
  const classes = useStyles();
  const toLink = React.forwardRef((props, ref) => (<Link ref={ref} to={to} {...props} />))
  return (
    <Card className={classes.card}>
      <CardActionArea component={toLink}>
        <CardMedia
          component="img"
          className={classes.media}
          height="80"
          width="80"
          image={image}
        />
        <CardContent>
          <Typography gutterBottom >
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

/*
function NewProjectMenu({ match}){

}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import check from '../../functions/can'
import CardMenu from './source/CardMenu'

function CartProps({ arr, idProject, name }) {
  const str = (typeof idProject !== 'undefined') ? `/projects/${idProject}` : ''
  const arrNew = arr.map(obj =>
    check(obj.check) && { to: str + obj.to, primary: obj.primary, disabled: obj.disabled, add: obj.add }
  ).filter(key => typeof key !== 'boolean')
  return arrNew.length > 0 && <Grid item><CardMenu arr={arrNew} name={name} /></Grid>
}

function DocumentMenu(props) {
  const idProject = props.match.params.idProject
  const certificates = [
    { check: 'material:read', to: '/certificates', primary: 'Список ' },
  ]
  const menu = [
    { check: 'shipment:read', to: '/shipment/materials', primary: 'Покупка материалов', add: true },
    { check: 'shipment:read', to: '/receipt/materials', primary: 'Получение давальческих материалов', add: true },
    { check: 'shipment:read', to: '/shipment/order', primary: 'Заказать давальческие материалы' },
  ]
  return <Grid container>
    <CartProps arr={menu} idProject={idProject} name='Проект' />
  </Grid>
}
*/