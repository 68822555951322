import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, InputBase
} from '@material-ui/core'
import { updWorkPrice } from '../../../api/work'

import * as actions from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'

const arrAction = [
  { value: 'multiplication', label: 'Умножить' },
  { value: 'division', label: 'Разделить' },
  { value: 'subtraction', label: 'Вычесть' },
  { value: 'addition', label: 'Добавить' },
]

export default function IncreacePriceDialog({ projectId, formName }) {
  const [open, changeOpen] = useState(false)
  const [action, setAction] = useState('multiplication')
  const [value, setValue] = useState(0)
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const getNewPrce = (price) => {
    switch (action) {
      case 'multiplication': return Math.round(price * value * 100) / 100
      case 'division': return Math.round(price / value * 100) / 100
      case 'subtraction': return price - value
      case 'addition': return price + value
      default: return price
    }
  }
  const handleSave = () => {
    const { byId, allIds = [] } = form.values
    const arrChanged = []
    allIds.forEach(key => {
      const obj = byId[key]
      const newPrice = getNewPrce(obj.priceNew)
      arrChanged.push({ id: obj.id, price: newPrice })
      dispatch(actions.formObjChangeObj({ priceNew: newPrice }, { field: 'byId', id: key, name: formName }))
    })
    dispatch(fetchDispath({
      param: { id: projectId },
      body: {
        arr: arrChanged
      },
      progress: true,
      request: updWorkPrice,
    }))
    changeOpen(false)
  }
  return (
    <React.Fragment>
      <Button onClick={() => changeOpen(true)} color="primary">Изменить цены</Button>
      <Dialog anchor='top' open={open} maxWidth='sm' fullWidth>
        <DialogTitle id="simple-dialog-title">Изменить цены</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Действие</FormLabel>
            <RadioGroup row aria-label="gender" name="row-radio-buttons-group" defaultValue="multiplication" onChange={e => setAction(e.target.value)}>
              {arrAction.map(e => {
                return <FormControlLabel key={e.value} value={e.value} control={<Radio />} label={e.label} />
              })}
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Значение</FormLabel>
            <InputBase
              value={value}
              type='number'
              onChange={(e) => setValue(e.target.value)}
              fullWidth
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => changeOpen(false)} color="primary">
            Закрыть
          </Button>
          <Button onClick={handleSave} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}