import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as actions from '../reducers/form';
import DocRow from './DocRow'

/**
 * column example
 * @example
 * {
 *  id: 'filed', 
 *  name: 'column name '
 *  type: 'text',
 *     //string: siple text not edit
 *     //text: input text
 *     //number: input nulber
 *     //auto: input autocomplite
 *  title:  obj=>obj.count, //return toltip
 *  textProps: obj=> {error:obj.count>10 helperText:''}, //  return textProps example {error helperText}
 *  formArr: 'main autocomplite name',
 *  filtered: true,
 *  changeValue: text=>({id:text.value, name: text.label})
 * }
 */


export default function DocTable({
  formName,
  allIdsField = 'allIds',
  byIdField = 'byId',
  column=[],
  rowDefault ={},
  idRowStart = 0,
  disableAdd = false
}) {
  const [idRow, setIdRow] = useState(idRowStart)
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values = {} } = form
  const allIds = values[allIdsField] || [];
  const byId = values[byIdField] || {};

  const addRow = () => {
    const newIdRow = idRow + 1
    dispatch(actions.formObjAdd(rowDefault, { field: byIdField, id: 'id' + newIdRow, name: formName }))
    dispatch(actions.formArrayAdd('id' + newIdRow, { field: allIdsField, name: formName, end:true }))
    setIdRow(newIdRow)
  }
  return <Table>
    <TableHead>
      <TableRow>
        <TableCell style={{ width: 10 }} padding={'none'}>
          {!disableAdd && <IconButton onClick={addRow}  ><AddIcon /></IconButton>}
        </TableCell>
        {column.map(obj => {
          return <TableCell key={obj.id} style={{ width: obj.width ? obj.width : undefined }}>{obj.name}</TableCell>
        })}
      </TableRow>
    </TableHead>
    <TableBody>
      {allIds
        .map(row => {
          const obj = byId[row];
          return <DocRow
            key={row}
            id={row}
            obj={obj}
            formName={formName}
            column={column}
            allIdsField={allIdsField}
            byIdField={byIdField}
          />
        })}
      <TableRow style={{ height: 20 * 5 }}>
        <TableCell colSpan={column.length+1} />
      </TableRow>
    </TableBody>
  </Table>
}