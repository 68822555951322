import React from 'react';
import { getOurStorageMoveProject, deleteOurStorageMoveProjectById } from '../../../../api/storage'
import PageList from '../../../../components/PageListAPI'

const formName = 'listMoveProject'
export default function ListMoveProject(props) {
  const head = [
    { id: 'projectName', name: 'Проект', },
    { id: 'storageSpace', name: 'Склад', },
    { id: 'statusRus', name: 'Статус', },
    { id: 'date', name: 'Дата', type: 'date' },
  ]
  return (
    <div className='App-paper'>
      <PageList
        name={formName}
        columns={head}
        createItem={true}
        deleteItem={true}
        idName='id'
        history={props.history}
        match={props.match}
        location={props.location}
        loadAPI={{
          request: getOurStorageMoveProject,
          transform: (res => {
            return {
              count: res.count,
              get: res.get.map(obj => {
                return { ...obj, statusRus: (obj.allDelivery) ? 'Доставлено' : 'Заказано' }
              })
            }
          }),
        }}
        deleteAPI={deleteOurStorageMoveProjectById}
        headerInit={{
          sortBy: 'date',
          sortDirection: 'desc',
        }}
        disabledDelete={(obj) => obj.related}
      />
    </div>
  )
}


