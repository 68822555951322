import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import { loadGantArrow, loadGantTree, clearGantThree } from '../../reducers/gant'
import { typeGant } from '../../enum/gant.enum'
import 'moment/locale/ru';
import moment from 'moment'
import GrandView from './GrandView'
import { getShare } from '../../api/share'

const formName = 'form-gant'
const formDialog = 'form-dialog'
const formView = 'form-view'
// const formWork = 'work'
// const formMaterial = 'material_gant'
const formViewProps = 'gant-view'
const oneRow = {
  id: '',
  idAPI: '',
  start: moment(new Date()),
  end: moment(new Date()).add(10, 'd'),
  startPlan: moment(new Date()),
  endPlan: moment(new Date()).add(10, 'd'),
  progress: 0,
  dependencies: [],
  name: '',
  idWork: null,
  idMaterial: null,
  duration: 1,
  pos: 0,
  idGroup: null,
  value: null,
  valueMaterial: null,
  count: 0,
  textField: '',
  typeGant: typeGant[0].value,
  parent: null,
  parentValue: null,
  child: [],
  color: null,
  selected: false,
}

export default function Gant({ match, history }) {
  const dispatch = useDispatch()
  const idProject = match.params.idProject
  const [isLoad, setLoad] = useState(true)
  const [countSelect, setCountSelect] = useState(0)
  useEffect(() => {
    dispatch(actions.formInitialize({
      byId: {},//example_date.byId,
      allIds: [],// example_date.allIds,
      arrFilter: [],
      start: moment(),
      end: moment(),
      count: 0,
      idRow: 0
    }, { name: formName }))
    dispatch(actions.formInitialize({
      obj: oneRow,
      id: '',
      open: false,
      newRow: false
    }, { name: formDialog }))
    dispatch(actions.formInitialize({
      obj: oneRow,
      id: '',
      open: false,
      x: 1000,
      y: 1000,
      height: 0
    }, { name: formView }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formDialog }))
      dispatch(actions.formDestroy({ name: formView }))
      dispatch(clearGantThree())
    }
  }, [dispatch])
  useEffect(() => {
    // пока уберем загрузку
    if (!isLoad) return;
    setLoad(false)
    dispatch(fetchDispath({
      querty: {
        link: match.params.id
      },
      progress: true,
      request: getShare,
    })).then(res => {
      let byId = {}
      const arrStartDate = []
      const arrEndDate = []
      let parentChild = {}
      const dependencies = []
      let allIds = res.get.map((e, i) => {
        const id = 'id' + e.id
        if (e.typeGant === 'parent') {
          if (parentChild[e.id]) {
            parentChild[e.id].row = id
            parentChild[e.id].name = e.name
          }
          else {
            parentChild[e.id] = { row: id, name: e.name, arr: [], start: null, end: null }
          }
        };

        let obj = {
          ...oneRow,
          ...e,
          id: id,
          idAPI: e.id,
          dependencies: e.dependencies || [],
          start: moment(e.start, 'YYYY-MM-DD'),
          end: moment(e.end, 'YYYY-MM-DD'),
          startPlan: moment(e.startPlan, 'YYYY-MM-DD'),
          endPlan: moment(e.endPlan, 'YYYY-MM-DD'),
          duration: 0,
          price: Number(e.price),
          sum: Math.round(Number(e.price) * e.count * 100) / 100,
          perf: e.abs>0?   e.abs: Math.round((e.count * e.progress / 100)* 100000) / 100000 ,
          sumPerf: e.abs>0?  Math.round(10000*Number(e.price)*(e.abs))/10000 : Math.round(Number(e.price) * e.count * e.progress) / 100 
        }
        obj.duration = obj.end.diff(obj.start, 'days')
        dependencies.push(...obj.dependencies.map(e => ({ fromId: obj.idAPI, ...e })))

        if (obj.parent) {
          if (!parentChild[obj.parent]) {
            parentChild[obj.parent] = {
              row: null,
              name: '',
              arr: [],
              start: moment(obj.start),
              end: moment(obj.end)
            }
          }
          parentChild[obj.parent].arr.push(id)
          const startP = (obj.startPlan.isValid()) ? moment.min([obj.startPlan, obj.start]) : moment(obj.start)
          const endP = (obj.endPlan.isValid()) ? moment.max([obj.endPlan, obj.end]) : moment(obj.end)
          if (!parentChild[e.parent].start || parentChild[obj.parent].start > startP) {
            parentChild[obj.parent].start = moment(startP)
          }
          if (!parentChild[e.parent].end || parentChild[obj.parent].end < endP) {
            parentChild[obj.parent].end = moment(endP)
          }
        }
        if (obj.typeGant !== 'parent') {
          arrStartDate.push(obj.start)
          arrStartDate.push(obj.startPlan)
          arrEndDate.push(obj.end)
          arrEndDate.push(obj.endPlan)
        }
        byId[id] = obj
        return id
      })
      allIds = res.arraySort.map(e => 'id' + e)
      allIds.forEach(key => {
        const obj = byId[key]
        if (obj.parent) {
          const par = byId[parentChild[obj.parent].row]
          byId[key].parentValue = {
            value: par.id,
            label: par.pos + ' ' + par.name
          }
        }
        if (obj.typeGant === 'parent') {
          const parent = parentChild[obj.idAPI]
          if (!parent || parent.arr.length === 0) { return; }
          byId[key].child = parent.arr
          byId[key].start = parent.start
          byId[key].end = parent.end
          byId[key].duration = parent.end.diff(parent.start, 'days')
          return;
        };
      })
      const allReverse = [...allIds].reverse()
      allReverse.forEach(key => {
        const obj = byId[key]
        const child = byId[key].child
        if (obj.typeGant === 'parent' && child.length > 0) {
          const arrStart = [...child.map(e => byId[e].start), ...child.map(e => byId[e].startPlan)].filter(e => e.isValid())
          const endStart = [...child.map(e => byId[e].end), ...child.map(e => byId[e].endPlan)].filter(e => e.isValid())
          const startP = moment.min(arrStart)
          const endP = moment.max(endStart)
          obj.start = moment(startP)
          obj.startPlan = moment(startP)
          obj.end = moment(endP)
          obj.endPlan = moment(endP)
        }
      })
      allReverse.forEach(key => {
        const obj = byId[key]
        if (obj.typeGant !== 'parent') { return; }
        const parent = parentChild[obj.idAPI]
        if (!parent || parent.arr.length === 0) { return; }
        byId[key].sum = parent.arr.reduce((partialSum, a) => partialSum + byId[a].sum, 0);
        byId[key].sum = Math.round(byId[key].sum * 100) / 100

        byId[key].sumPerf = parent.arr.reduce((partialSumPerf, a) => partialSumPerf + byId[a].sumPerf, 0);
        byId[key].sumPerf = Math.round(byId[key].sumPerf * 100) / 100
        
      })
      allIds.forEach(key => {
        const obj = byId[key]
        if (obj.dependencies.length === 0) return;
        const dependencies = []
        obj.dependencies.forEach(e => {
          const find = allIds.findIndex(s => byId[s].idAPI === e.to)
          const objTo = byId[allIds[find]]
          if (objTo.parent) {
            const findTo = allIds.findIndex(s => s === 'id' + objTo.parent)
            dependencies.push({
              ...e,
              id: objTo.parent,
              to: 'id' + objTo.parent,
              toIndex: findTo,
              parent: true
            });
          }
          dependencies.push({
            ...e,
            to: objTo.id,
            toIndex: find,
            parent: false
          });
        })
        if (obj.parent) {
          byId['id' + obj.parent].dependencies.push(...dependencies)
        }
        byId[key].dependencies = dependencies
      })
      let startNew = moment(moment.min(arrStartDate.filter(e => e.isValid())))
      let endNew = moment(moment.max(arrEndDate.filter(e => e.isValid())))
      if (!startNew.isValid()) startNew = moment()
      if (!endNew.isValid()) endNew = moment()
      startNew.add(-7, 'd')
      endNew.add(7, 'd')
      batch(() => {
        dispatch(actions.formChangeAsObj({
          byId, allIds,
          start: startNew,
          end: endNew,
          count: endNew.diff(startNew, 'days'),
          idRow: allIds.length + 1
        }, { name: formName }))
        dispatch(loadGantTree(res.tree))
        dispatch(loadGantArrow(dependencies))
      })

    }).catch(err => console.log(err))
  }, [dispatch, idProject, isLoad,match.params.id])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { allIds = [], byId = {}, start = moment(), end = moment(), count = 1 } = form.values
  return <div>
    <GrandView
      allIds={allIds}
      formDialog={formDialog}
      formView={formView}
      start={start} end={end}
      count={count}
      formName={formName}
      idProject={idProject}
      byId={byId}
      zoom={'day'}
      percent={130}
      setCountSelect={setCountSelect}
      formViewProps={formViewProps}
      fixTable={true}
    />
  </div>
}