import React, { useState } from 'react';
import PageList from '../../components/PageListAPI'
import { getDeliveryById } from '../../api/acceptance'
import AcceptanceDocByIdHeader from './source/AcceptanceDocByIdHeader'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ListFile from './source/ListFiles'
const useStyles = makeStyles(theme => ({
  container: {
    height: '100%'
  },
}));
export default function AcceptanceDocById({ match, history, location }) {
  const classes = useStyles()
  const table = useSelector(state => state.formTable.deliveryDoc || state.formTable.default)
  const [arrFile, setFile] = useState([])
  let tableHead = [
    { id: 'name', name: 'Наименование (сметное)' },
    { id: 'count', name: 'Количество' },
    { id: 'storageUnit', name: 'Ед. изм.' },
    { id: 'party', name: 'Партия' },
    { id: 'defects', name: 'Отклонение, дефекты' },
    { id: 'inventoryNumber', name: 'Инвентарный номер', hidden: true },
    { id: 'serialNumber', name: 'Серийный номер', hidden: true },
  ];
  if (table.obj && table.obj.typeDoc !== 'purchaseequip') {
    tableHead = tableHead.filter(obj => !obj.hidden)
  }
  return (
    <div className='App-paper'>
      <PageList
        title='Акт приемки материалов'
        name='deliveryDoc'
        classes={classes}
        columns={tableHead}
        loadAPI={{
          request: getDeliveryById,
          param: {
            id: match.params.idAccept
          },
          transform: (res => {
            let arr = []
            res.get.forEach(el => {
              if (el.estimate) {
                arr.push({
                  ...el.estimate,
                  name: el.estimate.name + ` (${el.name})`,
                  estimate: el.estimate,
                  party: el.party,
                  defects: el.defects,
                  main: {
                    id: el.id,
                    name: el.name,
                    count: el.count,
                    storageUnit: el.storageUnit
                  }
                })
              }
              else {
                arr.push(el)
              }
            });
            setFile(res.files)
            return {
              count: res.count,
              get: arr,
              obj: res.obj
            }
          }),
        }}
        headerInit={{
          obj: {
            date: '',
            userName: '',
            number: ''
          }
        }}
        tableProps={{
          pagination: false
        }}
        pagination={false}
        click={false}
        headerLink={<AcceptanceDocByIdHeader idAccept={match.params.idAccept} />}
        csv={false}
        match={match}
        history={history}
        location={location}
        goBack={false}
      />
      <ListFile arrFile={arrFile} />
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад </Button>
    </div>
  )
}