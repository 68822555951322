import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import { getWorker, endWorker } from '../../api/project-worker'
import DocTable from '../../components/DocTable'
import DocAdd from '../../components/DocAdd'
import moment from 'moment'

const formName = 'workerEnding'
const formAuto = 'worker'

export default function ProjectWorkerEnding({ match, history }) {
  const dispatch = useDispatch()
  const idProject = match.params.idProject
  useEffect(() => {
    dispatch(actions.formInitialize({
      byId: {},
      allIds: [],
      date: moment(Date.now()).format('YYYY-MM-DD'),
      idRow: 0
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: formAuto }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formAuto }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getWorker.path({ id: idProject }), {
      field: formAuto,
      value: 'id',
      hidden: ['dateEnd'],
      labelFunc: (obj) => obj.name + ' ' + obj.surname + ' ' + obj.patronymic
    }))
  }, [dispatch, idProject])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values = {} } = form
  const handleSave = () => {
    let arr = []
    values.allIds.forEach(row => {
      const obj = values.byId[row]
      arr.push(obj.id)
    });
    dispatch(actions.formSubmitAPI({
      props: {
        date: values.date,
        arrWorker: arr
      },
      url: endWorker.path({ id: idProject }),
      method: endWorker.type,
      history: history,
    }, formName))
  }
  return <div className='App-paper'>
    <DocAdd
      history={history}
      formName={formName}
      title='Убрать рабочих с проекта'
      handleSave={handleSave}
      noTableProps={[
        { field: 'date', label: 'Дата', type: 'text', textProps: { type: 'date' } },
      ]}
    >
      <DocTable
        formName={formName}
        column={[{ id: 'value', type: 'auto', name: 'Работник', formArr: formAuto, filtered: true, changeValue: text => ({ id: text.value, value: text }) }]}
        rowDefault={{
          id: '',
          value: null
        }}
      />
    </DocAdd>
  </div>
}