import {stopLoadRequest,startLoadRequest} from './loading'
import {errorDispath,fetchGenerate} from '../functions/fetch'
import {actionCreatorObj} from '../functions/redux'
const MAIN = 'orders/'
const LOAD_ORDERS = MAIN+'LOAD'
const SELECT_ORDERS = MAIN+'SELECT'
const UN_SELECT_ORDERS = MAIN+'UN_SELECT'

export const loadOrders=actionCreatorObj(LOAD_ORDERS)
export const selectOrders = actionCreatorObj(SELECT_ORDERS)
export const unSelectOrders = actionCreatorObj(UN_SELECT_ORDERS)

// Deleted
export function loadOrdersListAPI({newURL,idProject}){
  const query=(idProject)?`/projects/${idProject}/orders`:'/order/list'
  return dispatch =>{
    dispatch(startLoadRequest()) 
    fetchGenerate(query,'GET')
    .then(project => {
      if (project.success){
        dispatch(loadOrders({get:project.get, newURL}))
        dispatch(stopLoadRequest())
      }
    })
    .catch(errorDispath(dispatch))
  }
}

export const initialState = {
    byId: {},
    allIds:[]
};

export default function orders(state = initialState, action){
  switch (action.type){
    case LOAD_ORDERS: return load(state,action.payload)
    case SELECT_ORDERS: return {...state,  allIds: state.allIds.filter(key =>key!==action.payload)}
    case UN_SELECT_ORDERS: {
      return {...state,  allIds: [action.payload,...state.allIds]}
    }
    default : return state;
  }
}
function load(state,{get, newURL}){
  let byId = {}
  let allIds = get.map(row => {
      let id = 'id'+row.id
      byId[id]=row;
      return id;
  })
  return({...state,  allIds:allIds, byId:byId})
}
