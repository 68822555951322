import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { formSubmitAPI, formInitialize, formDestroy } from '../../reducers/form';
import Button from '@material-ui/core/Button';
import { updPassword } from '../../api/users'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'

const formName = 'ProfileEditPass'

function ProfileEditPass({ history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(formInitialize({ idProvider: '', dateStart: '', price: '', idEquipment: '', time: '' }, { name: formName }))
    return () => {
      dispatch(formDestroy({ name: formName }))
    }
  }, [dispatch])
  const arrProps = [
    { field: 'oldpass', type: 'text', label: 'Старый пароль', textProps: { type: 'password' } },
    { field: 'newpass', type: 'text', label: 'Новый пароль', textProps: { type: 'password', autoComplete: "new-password" } },
    { field: 'repeat', type: 'text', label: 'Повторить пароль', textProps: { type: 'password', autoComplete: "new-password" } },
  ]
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.newpass !== values.repeat) {
      dispatch(enqueueSnackbar({ message: `Пароли не совпадают`, options: { variant: 'error' } }))
      return
    }
    dispatch(formSubmitAPI({
      props: {
        newP: values.newpass,
        oldP: values.oldpass
      },
      url: updPassword.path,
      method: updPassword.type,
      history: history
    }, formName))
  }
  return <div className='App-paper'>
    <form autoComplete="off" onSubmit={handleSubmit}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd arr={arrProps} formName={formName} title='Изменить пароль' />
      </div>
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>
}
export default ProfileEditPass;