import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/form';
import { addCertificates } from '../../api/certificates'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import { certificateType } from './certificates.enum'
import { loadFileAPI } from '../../reducers/materialLoad';
import { addFile } from '../../api/list'
import TableFile from './TableFile'

const initialState = {
  typeDoc: null,
  name: null,
  issuedBy: null,
  docNumber: null,
  docDate: null,
  validTo: null,
  fById: {},
  fAllIds: [],
  errorValidate: {
    name: false,
  }
}
const formName = 'CertificatesAdd'
export default function CertificatesAdd({ history, match }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize(initialState, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const arrProps = [
    { field: 'typeDoc', type: 'autoArr', label: 'Тип документа', textProps: { arr: certificateType.map(e => ({ value: e, label: e })) } },
    { field: 'name', type: 'text', label: 'Наименование документа', validator: [value => value !== ''], errorText: 'Поле обязательно', },
    { field: 'issuedBy', type: 'text', label: 'Документ выдан' },
    { field: 'docNumber', type: 'text', label: 'Номер документа' },
    { field: 'docDate', type: 'text', label: 'Дата документа', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { field: 'validTo', type: 'text', label: 'Действителен до', textProps: { type: 'date' }, labelProps: { shrink: true }, },
  ]
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const [loadingFile, setLoadingFile] = useState(false)
  const handleOk = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        typeDoc: values.typeDoc,
        name: values.name,
        issuedBy: values.issuedBy,
        docNumber: values.docNumber,
        docDate: values.docDate,
        validTo: values.validTo,
        files: Object.values(values.fById).map(e => e.id),
      },
      url: addCertificates.path({ id: match.params.idProject }),
      method: addCertificates.type,
      history: history
    }))
  };
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoadingFile(true)
    let fileName = e.target.files[0].name
    const fileType = e.target.files[0].type
    dispatch(loadFileAPI({ file: e.target.files[0], path: addFile.path }))
      .then(res => {
        if (res.success) {
          dispatch(actions.formObjAdd({ id: res.id, name: fileName, type: fileType }, {
            field: 'fById',
            id: 'id' + res.id,
            name: formName
          }))
          dispatch(actions.formArrayAdd('id' + res.id, {
            field: 'fAllIds', name: formName
          }))
        }
        setLoadingFile(false)
      })
      .catch()
  }
  return <div className='App-paper'>
    <form autoComplete="off" onSubmit={handleOk}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd formName={formName} arr={arrProps} title='Добавить сертификат'>
          <Button disabled={loadingFile} component="label" color='primary'>
            {loadingFile && <CircularProgress size={25} />}
            <span>Прикрепить файл</span>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => _handleImageChange(e)}
            />
          </Button>
        </SimpleAdd>
      </div>
      <TableFile formName={formName} />
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>
}