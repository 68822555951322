import { actionCreatorObjMeta, actionCreatorEmptyMeta } from '../functions/redux'
import { errorDispath, fetchGenerate } from '../functions/fetch'

const MAIN = 'autocomplite/'
const INITIAL = MAIN + 'INITIAL'
const LOAD_API = MAIN + 'LOAD_API'
const LOAD = MAIN + 'LOAD'
const LOAD_GROUP = MAIN + 'LOAD_GROUP'
const CLEAR = MAIN + 'CLEAR'
const SET = MAIN + 'SET'
const ADD = MAIN + 'ADD_ITEM'
const EDIT_LABEL = MAIN + 'EDIT_LABEL'
const ADD_FILTER = MAIN + 'ADD_ITEM_FILTER'
const ADD_FILTER_MANY = MAIN + 'ADD_ITEM_FILTER_MANY'
const DELL_FILTER = MAIN + 'DELL_ITEM_FILTER'
const DELL_ITEM = MAIN + 'DELL_ITEM'
const DISABLE_ENABLE_ROW = MAIN + 'DISALBE_ENABLE'
const DISABLE_ENABLE_GROUP_ROW = MAIN + 'DISALBE_ENABLE_GROUP'
// meta: {field:'users'}
export const initialAutocomplite = actionCreatorEmptyMeta(INITIAL)
export const clearAutocomplite = actionCreatorEmptyMeta(CLEAR)
//meta:{
//  field: 'users'
//  value: 'id',
//  label: 'name
//  hidden : ['count',.....]
//  labelFunc: (obj)=> {return: ''}
//}
export const addFilterAutocomplite = actionCreatorObjMeta(ADD_FILTER)
export const disableEnableAutocomplite = actionCreatorObjMeta(DISABLE_ENABLE_ROW)
export const disableEnableGroupAutocomplite = actionCreatorObjMeta(DISABLE_ENABLE_GROUP_ROW)
export const addFilterManyAutocomplite = actionCreatorObjMeta(ADD_FILTER_MANY)
export const dellFilterAutocomplite = actionCreatorObjMeta(DELL_FILTER)
export const dellItemAutocomplite = actionCreatorObjMeta(DELL_ITEM)
export const loadAPI = actionCreatorObjMeta(LOAD_API)
export const load = actionCreatorObjMeta(LOAD)
export const loadGroup = actionCreatorObjMeta(LOAD_GROUP)
export const add = actionCreatorObjMeta(ADD)
export const set = actionCreatorObjMeta(SET)
export const editLabel = actionCreatorObjMeta(EDIT_LABEL)
export function loadAutocompliteAPI(path, meta) {
  return dispatch => {
    return fetchGenerate(path, 'GET')
      .then(project => {
        if (project.success) {
          if(meta.filter){
            dispatch(loadAPI(meta.filter(project.get), meta))
          }
          else{
            dispatch(loadAPI(project.get, meta))
          }
        }
        else {
          dispatch(loadAPI([], meta))
        }
      })
      .catch(errorDispath(dispatch))
  }
}
export const initialState = {
  default: {
    arr: [],
    value: [{ value: '', label: '' }],
    load: false,
    filter: [],
    group: {}
  }
}
export default function redRouter(state = initialState, action) {
  switch (action.type) {
    case INITIAL: return initialAction(state, action.meta)
    case LOAD_API: return loadActionAPI(state, action.payload, action.meta)
    case LOAD: return loadAction(state, action.payload, action.meta)
    case LOAD_GROUP: return loadGroupAction(state, action.payload, action.meta)
    case CLEAR: return clearAction(state, action.meta)
    case SET: return setAction(state, action.payload, action.meta)
    case ADD: return addItem(state, action.payload, action.meta)
    case ADD_FILTER: return addFilter(state, action.payload, action.meta)
    case ADD_FILTER_MANY: return addFilterMany(state, action.payload, action.meta)
    case DELL_FILTER: return delFilter(state, action.payload, action.meta)
    case DELL_ITEM: return delItem(state, action.payload, action.meta)
    case EDIT_LABEL: return editLabelRout(state, action.payload, action.meta)
    case DISABLE_ENABLE_ROW: return disEnable(state, action.payload, action.meta)
    case DISABLE_ENABLE_GROUP_ROW: return disEnableGroup(state, action.payload, action.meta)
    default: return state
  }
}

function editLabelRout(state, label, meta) {
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: state[meta.field].arr.map(e => {
        if (e.value !== meta.value) return e;
        return { ...e, value: meta.value, label }
      }),
    }
  }
}
function disEnable(state, action, meta){
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: state[meta.field].arr.map(e => {
        if (e.value !== meta.value) return e;
        return { ...e, isDisabled: action  }
      }),
    }
  }
}
function disEnableGroup(state, action, meta){
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: state[meta.field].arr.map(e => {
        return { ...e, options: e.options.map(x=>{
          if (x.value !== meta.value) return x;
          return { ...x, isDisabled: action  }
        })}
      }),
    }
  }
}
// meta: field, id
function addFilter(state, value, meta) {
  const arr = state[meta.field].arr
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: arr.map(v => {
        if (v.value === value) {
          return { ...v, select: meta.id }
        }
        return v
      })
    }
  }
}
// arrFilter = [{value,id}]
function addFilterMany(state, arrFilter, meta) {
  const arr = state[meta.field].arr
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: arr.map(v => {
        const find = arrFilter.find(e => v.value === e.value)
        if (find) {
          return { ...v, select: find.id }
        }
        return v
      })
    }
  }
}
function delFilter(state, value, meta) {
  const arr = state[meta.field].arr
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: arr.map(v => {
        if (v.value === value) {
          return { ...v, select: undefined }
        }
        return v
      })
    }
  }
}
function delItem(state, value, meta) {
  const arr = state[meta.field].arr
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: arr.filter(obj => obj.value !== value)
    }
  }
}
function addItem(state, obj, meta) {
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: [...state[meta.field].arr, obj],
    }
  }
}
function initialAction(state, meta) {
  const value = meta.value || { value: '', label: '' }
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: [],
      load: false,
      value: value,
      filter: []
    }
  }
}
function setAction(state, obj, meta) {
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      value: obj,
    }
  }
}
function clearAction(state, meta) {
  const { [meta.field]: value, ...second } = state
  return { ...second }
}

function loadActionAPI(state, arr, meta) {
  let arrAutocomplite = arr.map(value => {
    let row = { value: value[meta.value] + '' }
    if (meta.hidden) meta.hidden.forEach(key => row[key] = value[key])
    if (meta.label) row.label = value[meta.label] + ''
    else row.label = meta.labelFunc(value) + ''
    return row
  })
  let value = state[meta.field].value
  if (meta.defaultValue) value = arrAutocomplite.find(obj => obj.value === meta.defaultValue)
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: arrAutocomplite,
      load: true,
      value
    }
  }
}

function loadAction(state, arr, meta) {
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      arr: arr,
      load: true,
    }
  }
}
function loadGroupAction(state, arr, meta) {
  return {
    ...state,
    [meta.field]: {
      ...state[meta.field],
      group: arr,
      load: true,
    }
  }
}