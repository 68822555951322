import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import * as api from '../../api/facility'
import { fetchDispath } from '../../functions/fetch'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import { getCompanies } from '../../api/list'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import typeResp from './typeResp.enum'

import moment from 'moment'
const textError = 'Поле обязательно'
const formName = 'FacilityResponsibleAdd'
const formCompanies = 'company'
export default function FacilityResponsibleAdd({ history, match }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getCompanies.path, {
      field: formCompanies,
      value: 'id',
      labelFunc: (obj) => obj.shortName
    }))
  }, [dispatch])
  useEffect(() => {
    dispatch(initialAutocomplite({ field: formCompanies }))
    dispatch(fetchDispath({
      progress: true,
      request: api.getOneFacilityResponsible,
      param: {
        id: match.params.id,
        idFacility: match.params.idFacility
      }
    }))
      .then(res => {
        dispatch(actions.formInitialize({
          date: moment(res.get.date).format('YYYY-MM-DD'),
          doc: res.get.doc,
          idCompany: res.get.idCompany,
          name: res.get.name,
          nostroy: res.get.nostroy,
          patronymic: res.get.patronymic,
          position: res.get.position,
          surname: res.get.surname,
          type: res.get.type,
          valuetype: typeResp.find(e => e.value === res.get.type),
          valueidCompany: { value: res.get.idCompany + '', label: res.get.company },

          errorValidate: { name: false, surname: false }
        }, { name: formName }))
      })
      .catch(err => console.log(err))
    return () => {
      dispatch(clearAutocomplite({ field: formCompanies }))
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch, match.params.id, match.params.idFacility])

  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [
    { field: 'surname', type: 'text', label: 'Фамилия', validator: [value => value !== ''], errorText: textError, },
    { field: 'name', type: 'text', label: 'Имя', validator: [value => value !== ''], errorText: textError, },
    { field: 'patronymic', type: 'text', label: 'Отчество' },
    { field: 'idCompany', type: 'autoRedux', label: 'Компания', textProps: { formArr: formCompanies } },
    { field: 'position', type: 'text', label: 'Должность' },
    { field: 'doc', type: 'text', label: 'Документ о представительстве' },
    { field: 'nostroy', type: 'text', label: 'номер НОСТРОЙ' },
    { field: 'date', type: 'text', label: 'Сроки полномочий', textProps: { type: 'date' }, labelProps: { shrink: true } },
    { field: 'type', type: 'autoArr', label: 'Представитель', textProps: { arr: typeResp } }
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        date: values.date,
        doc: values.doc,
        idCompany: values.idCompany+'',
        name: values.name,
        nostroy: values.nostroy,
        patronymic: values.patronymic,
        position: values.position,
        surname: values.surname,
        type: values.type,
      },
      url: api.editFacilityResponsible.path({ idFacility: match.params.idFacility, id: match.params.id, }),
      method: api.editFacilityResponsible.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Редактировать ответственное лицо'
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}
