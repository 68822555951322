import React from 'react'
import { useSelector } from 'react-redux'
import PageList from '../../../components/PageListAPI'
import { getAccountsTByIdAPI } from '../../../api/tariff.api'
import { Typography } from '@material-ui/core'

const formName = 'account-one'

export default function AccountOne(props) {
  return <div className='App-paper'>
    <PageList
      name={formName}
      title='Карточка аккаунта'
      columns={[
        { id: 'planName', name: 'Тариф' },
        { id: 'isActive', name: 'Активность', type: 'component', component: ({ obj }) => <>{obj.isActive ? 'Да' : 'Нет'}</> },
        { id: 'dateEnd', name: 'Срок окончания', type: 'date', formatDate: 'DD.MM.YYYY' },
        { id: 'remainingDays', name: 'Оставшиеся дни' },
        { id: 'creditDays', name: 'Дни в кредит' },
      ]}
      idName='idPlan'
      createItem={false}
      deleteItem={false}
      loadAPI={{
        param: {
          id: props.match.params.idDoc
        },
        request: getAccountsTByIdAPI,
      }}
      headerInit={{
        sortBy: 'dateEnd',
        sortDirection: 'desc',
        obj: { name: '' }
      }}
      headerLink={<HederAccount />}
      pagination={false}
      tableProps={{
        pagination: false,
      }}
      {...props}
    />
  </div>
}

function HederAccount() {
  const obj = useSelector(state => state.formTable[formName]?.obj)
  return <Typography variant='h6'>{obj?.name || ''}</Typography>
}