import React from 'react';
import PageList from '../../components/PageListAPI'
import {
  getProjectMachineProject,
} from '../../api/projects'

export default function ListCalendar(props) {
  const tableHead = [
    { id: 'date', name: 'Дата', type: 'date', formatDate: 'DD.MM.YYYY' },
    { id: 'time', name: 'Время', },
    { id: 'duration', name: 'Продолжительность', width:8},
    { id: 'model', name: 'Модель', },
    { id: 'govNumber', name: 'Гос. номер', },
    { id: 'driver', name: 'Водитель', },
    
  ];
  return (
    <div className='App-paper'>
      <PageList
        title='Список техники'
        name='listMachine'
        // classes={classes}
        columns={tableHead}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getProjectMachineProject,
          param: {
            id: props.match.params.idProject
          },
        }
        }
        {...props}
      />
    </div>
  )
}