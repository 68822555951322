import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography,} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { formSubmitAPI, formInitialize, formDestroy, formChange } from '../../reducers/form';
import { addWorker } from '../../api/worker'
import { addPosition, getPosition } from '../../api/list'

import SimpleAdd from '../../components/SimpleAdd'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import { loadFileAPI } from '../../reducers/materialLoad';
import { addFile } from '../../api/list'

const textError = 'Поле обязательно'
const formName = 'WorkerAdd'
export default function WorkerAdd({ match, history }) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [avatarName, setAvatarName] = useState('')
  const [avatar, setAvatar] = useState(null)
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(formInitialize({
      name: '',
      surname: '',
      patronymic: '',
      idPosition: '',
      series: '',
      numberP: '',
      issued: '',
      issueDate: null,
      sex: '',
      birthdate: null,
      birthplace: '',
      address: '',
      type: '',
      position: null,
      driverLicense: '',
    }, { name: formName }))
    return () => {
      dispatch(formDestroy({ name: formName }))
    }
  }, [dispatch])
  const arrProps0 = [
    { field: 'surname', type: 'text', label: 'Фамилия', validator: [value => value !== ''], errorText: textError, },
    { field: 'name', type: 'text', label: 'Имя', validator: [value => value !== ''], errorText: textError, },
    { field: 'patronymic', type: 'text', label: 'Отчество' },
    {
      field: 'idPosition', type: 'autoLoadCreate', label: 'Должность',
      validator: [value => !!value], errorText: textError,
      textProps: {
        createAPI: addPosition,
        createBody: 'name',
        path: getPosition.path,
        meta: {
          field: 'position',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
    // { field: 'driverLicense', type: 'text', label: 'Водительское удостоверение', },
  ]
  const arrProps = [
    { xs: 6, field: 'type', type: 'autoArr', textProps: { arr: [{ value: 'Паспорт РФ', label: 'Паспорт РФ' }, { value: 'Зарубежный паспорт', label: 'Зарубежный паспорт' }], defaultValue: 'Паспорт РФ', }, label: 'тип документа' },
    { xs: 6, type: 'divider' },
    { xs: 4, field: 'series', type: 'text', label: 'Серия', textProps: { type: 'number' }, },
    { xs: 4, field: 'numberP', type: 'text', label: 'Номер', textProps: { type: 'number' } },
    { xs: 4, type: 'divider' },
    { xs: 3, field: 'issueDate', type: 'text', label: 'Дата выдачи', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { xs: 9, field: 'issued', type: 'text', label: 'Кем выдан' },
    { xs: 3, field: 'sex', type: 'autoArr', label: 'пол', textProps: { arr: [{ value: 'муж.', label: 'муж.' }, { value: 'жен.', label: 'жен.' }], defaultValue: 'муж.', } },
    { xs: 9, type: 'divider' },
    { xs: 3, field: 'birthdate', type: 'text', label: 'Дата рождения', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { xs: 9, field: 'birthplace', type: 'text', label: 'Место рождения' },
    { field: 'address', type: 'text', label: 'Адрес регистрации' },
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(formSubmitAPI({
      props: {
        name: values.name,
        surname: values.surname,
        patronymic: values.patronymic,
        idPosition: values.idPosition,
        type: values.type,
        series: values.series,
        numberP: values.numberP,
        issued: values.issued,
        issueDate: values.issueDate,
        sex: values.sex,
        birthdate: values.birthdate,
        birthplace: values.birthplace,
        address: values.address,
        avatar: avatar,
        driverLicense: values.driverLicense,
      },
      url: addWorker.path,
      method: addWorker.type,
      history: history
    }))
  };
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoading(true)
    dispatch(loadFileAPI({ file: e.target.files[0], path: addFile.path }))
      .then(res => {
        if (res.success) {
          console.log(res)
          setAvatarName(res.name)
          setAvatar(res.id)
        }
        setLoading(false)
      })
      .catch()
  }
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <Typography variant='h6' style={{ marginBottom: '8px' }}>Добавить работника</Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={6}>
              <SimpleAdd formName={formName} arr={arrProps0} gridProps={{ xs: 12 }} />
            </Grid>
            <Grid
              item xs={6} sm={6}
              container
              direction="column"
            >
              <Grid item>
                <Typography>Фото</Typography>
                <input
                  id="contained-button-file"
                  type="file"
                  onChange={(e) => _handleImageChange(e)}
                  disabled={loading}
                />
              </Grid>
              <Grid item>
                {avatar && <img src={`/api/1/files/${avatarName}`} alt='avatar' width={'50%'} height={'50%'}/>}
              </Grid>
            </Grid>
          </Grid>
          <SimpleAdd formName={formName} arr={arrProps} gridProps={{ xs: 12 }} />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  )
}