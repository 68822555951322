import React from 'react';
import { useSelector } from 'react-redux';
import { Line } from "react-chartjs-2";

export default function LineMaterialPrice({ formName, width, height }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { linePrice, arrPrice } = form.values
  const data = {
    labels: linePrice,
    datasets: [
      {
        label: 'Цена закупки',
        backgroundColor: 'rgba(230, 25, 73,0.2)',
        borderColor: 'rgba(230, 25, 73s,1)',
        borderWidth: 1,
        data: arrPrice
      },
      // {
      //   label: 'Цена по плану',
      //   backgroundColor: 'rgba(25, 185, 230,0.2)',
      //   borderColor: 'rgba(25, 185, 230,1)',
      //   borderWidth: 1,
      //   data: [price]
      // },
    ]
  };
  var timeFormat = 'YYYY-MM-DD';
  return (
    <Line
      data={data}
      width={width}
      height={height}
      options={{
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              parser: timeFormat,
              // round: 'day'
              tooltipFormat: 'll HH:mm'
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'value'
            }
          }]
        },
      }}
    />)
}