import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, TableHead, TableBody, TableCell, TableRow, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import AutoComplite from '../../../components/Autocomplite'
import * as actions from '../../../reducers/form';
import { addFilterAutocomplite, dellFilterAutocomplite } from '../../../reducers/autocomplite'
import AddIcon from '@material-ui/icons/Add';

const row = {
  id: '',
  name: '',
  position: '',
  values: null
}
export default function TableTeamAdd({ formName, formArr }) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byId = {}, allIds = [], idRow } = form.values
  const handleAddRow = () => {
    const newIdRow = idRow + 1;
    dispatch(actions.formObjAdd(row, { field: 'byId', id: 'id' + newIdRow, name: formName }))
    dispatch(actions.formArrayAdd('id' + newIdRow, { field: 'allIds', name: formName }))
    dispatch(actions.formChange(newIdRow, { field: 'idRow', name: formName }))
  }
  return <Table>
    <TableHead>
      <TableRow>
        <TableCell style={{ width: 10 }} padding={'none'}>
          <IconButton
            onClick={handleAddRow}  ><AddIcon /></IconButton>
        </TableCell>
        <TableCell>Рабочий</TableCell>
        <TableCell>Должность</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {allIds.map(key => {
        const obj = byId[key]
        return <RowCustom key={key} obj={obj} formArr={formArr} formName={formName} id={key} />
      })}
    </TableBody>
  </Table>
}


function RowCustom({ formName, formArr, id, obj }) {
  const [edit, changeEdit] = useState(true)
  const dispatch = useDispatch()
  const workerArr = useSelector(state => state.autocomplite[formArr] || state.autocomplite.default)
  const filterArr = workerArr.arr.filter(obj => {
    return !(typeof obj.select !== 'undefined' && obj.select !== id)
  })
  const handleChangeWorker = text => {
    if (obj.value) {
      dispatch(dellFilterAutocomplite(obj.value, { field: formArr, id: id }))
    }
    dispatch(actions.formObjChangeObj({
      name: text.label,
      id: text.value,
      position: text.position,
      value: text,
    }, { field: 'byId', id: id, name: formName }))
    dispatch(addFilterAutocomplite(text.value, { field: formArr, id: id }))
  }
  const handleDeleteRow = () => {
    if (obj.value) {
      dispatch(dellFilterAutocomplite(obj.value, { field: formArr, id: id }))
    }
    dispatch(actions.formArrayDelete(id, { field: 'allIds', name: formName }))
    dispatch(actions.formObjDelete({ id: id, field: 'byId', name: formName }))
  }
  return <TableRow>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <IconButton aria-label="Delete"
        onClick={handleDeleteRow}  ><DeleteIcon /></IconButton>
    </TableCell>
    <TableCell>
      {edit ? <AutoComplite
        arr={filterArr}
        action={(e) => { handleChangeWorker(e); changeEdit(false) }}
        value={obj.value}
      />
        : <div onDoubleClick={() => changeEdit(true)}>{obj.name}</div>
      }
    </TableCell>
    <TableCell>{obj.position}</TableCell>
  </TableRow>
}