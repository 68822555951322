import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../../../reducers/autocomplite'
import DocTable from '../../../../components/DocTable'
import DocAdd from '../../../../components/DocAdd'
import { getWorker } from '../../../../api/project-worker'
import { addUseTool } from "../../../../api/doc";
import { getInventory } from '../../../../api/inventory'


const formName = 'AddUserTool'
const formArr = 'tool'
export default function AddUseTool({ match, history }) {
  const dispatch = useDispatch()
  const { idProject } = match.params
  useEffect(() => {
    dispatch(actions.formInitialize({ idWorker: '', byId: {}, allIds: [] }, { name: formName }))
    dispatch(initialAutocomplite({ field: formArr }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formArr }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(
      `${getInventory.path}?free=1&idProject=${idProject}`,
      {
        field: 'tool',
        value: 'inventoryNumber',
        labelFunc: (obj) => obj.inventoryNumber.toString().padStart(5, '0') + ' ' + obj.name
      }
    ))
  }, [dispatch, idProject])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const handleSave = () => {
    const { byId, idWorker } = form.values
    let arr = Object.values(byId).filter(e => e.idInventory > 0).map(e => e.idInventory)
    dispatch(actions.formSubmitAPI({
      props: {
        idWorker: idWorker,
        arr: arr,
      },
      url: addUseTool.path({ id: match.params.idProject }),
      method: addUseTool.type,
      history: history
    }, formName))
  }
  return <div className='App-paper'>
    <DocAdd
      history={history}
      formName={formName}
      title='Выдать оборудование работнику'
      handleSave={handleSave}
      noTableProps={[
        {
          field: 'idWorker', label: 'Работник', type: 'autoLoad', textProps: {
            path: getWorker.path({ id: idProject }),
            meta: {
              field: 'worker',
              value: 'id',
              labelFunc: (obj) => obj.surname + ' ' + obj.name + ' ' + obj.patronymic
            }
          },
          xs:6
        }]}
    >
      <DocTable
        formName={formName}
        column={[{
          id: 'value', type: 'auto', name: 'Оборудование', formArr: formArr,
          filtered: true, changeValue: text => ({ idInventory: text.value, value: text })
        }]}
        rowDefault={{
          idInventory: '',
          value: null
        }}
      />
    </DocAdd>
  </div>
}