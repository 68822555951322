
import React, { useState, useMemo, useCallback } from 'react';
import can from '../../functions/can'
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import AutoComplite from '../../components/AutocompliteWithLoad'
import { loadProjectUsers } from '../../api/projects'
import { formSubmitAPI } from '../../reducers/form'
import { dellItemAutocomplite, set as setAutocomplite } from '../../reducers/autocomplite'
import { addUserDeleteProject } from '../../api/users'
import PageList from '../../components/PageListAPI'
const columns = [
  { id: 'surname', name: 'Фамилия' },
  { id: 'name', name: 'Имя' },
  { id: 'patronymic', name: 'Отчество' },
]
export default function ProjectUsers({ match, history, location }) {
  const formName = 'ProjectUsers'
  const idProject = match.params.idProject
  const [reload, setReload] = useState(false)
  const dispatch = useDispatch()
  const deleteUserCustom = (id) => {
    dispatch(formSubmitAPI({
      props: {
        delProject: [idProject]
      },
      url: addUserDeleteProject.path({ id: id.substr(2) }),
      method: addUserDeleteProject.type,
      endFunc: (e) => {
        setReload(true)
      }
    }, formName))
  }
  return (
    <div>
      <AddUserToProject idProject={idProject} reload={() => setReload(true)} formName={formName} />
      <PageList
        name={formName}
        columns={columns}
        createItem={false}
        deleteItem={can('project:user:delete')}
        tableActions={{
          onDeleteRow: (can('project:user:delete')) ? deleteUserCustom : undefined
        }}
        loadAPI={useMemo(() => ({
          request: loadProjectUsers,
          param: {
            id: idProject
          }
        }), [idProject])}
        match={match}
        history={history}
        location={location}
        csv={false}
        reload={reload}
        reloadFunc={useCallback(() => { setReload(false) }, [])}
        click={false}
        goBack={false}
      />
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
    </div>
  )
}

function AddUserToProject({ idProject, reload, formName }) {
  const [open, changeOpen] = useState(false)
  const [idUser, changeUser] = useState('')
  const dispatch = useDispatch()
  const addUser = () => {
    if (idUser) {
      dispatch(formSubmitAPI({
        props: {
          addProject: [idProject]
        },
        url: addUserDeleteProject.path({ id: idUser }),
        method: addUserDeleteProject.type,
        endFunc: () => {
          reload()
          dispatch(dellItemAutocomplite(idUser, { field: 'idUser' }))
          dispatch(setAutocomplite({ value: '', label: '' }, { field: 'idUser' }))
        }
      }), formName)
      changeUser('')
    }
    changeOpen(false)
  }
  return <React.Fragment>
    <Button color="primary" onClick={() => changeOpen(true)}>Добавить</Button>
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => changeOpen(false)}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">Добавить пользователя</DialogTitle>
      <DialogContent >
        <AutoComplite
          classes={{}}
          field='idUser'
          name='Пользователь'
          path={loadProjectUsers.path({ id: idProject }) + '?not=1'}
          meta={{
            field: 'idUser',
            value: 'id',
            labelFunc: (obj) => obj.name + ' ' + obj.surname
          }}
          formName={formName}
          change={(e) => changeUser(e.value)}
          form={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => changeOpen(false)} color="primary">Отмена</Button>
        <Button onClick={() => addUser()} color="primary" autoFocus>Добавить</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}