import React from 'react';
import PageList from '../../components/PageListAPI'
import { getKS2, delKS2ById } from '../../api/formks2ks3'

export default function KS2List(props) {
  const tableHead = [
    { id: 'number', name: '№', },
    { id: 'dateStart', name: 'отчетный период (с)', type: 'date', formatDate: 'DD.MM.YYYY' },
    { id: 'dateEnd', name: 'отчетный период (по)', type: 'date', formatDate: 'DD.MM.YYYY' },
  ];
  return (
    <div className='App-paper'>
      <PageList
        title='Акт о приемке выолненных работ КС2'
        name='ks2list'
        // classes={classes}
        columns={tableHead}
        createItem={true}
        deleteItem={true}
        loadAPI={{
          request: getKS2,
          param: {
            id: props.match.params.idProject
          },
        }
        }
        deleteAPI={delKS2ById(props.match.params.idProject)}
        {...props}
      />
    </div>
  )
}