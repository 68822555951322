import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import Button from '@material-ui/core/Button';
import { addIHW } from '../../../api/doc'
import SimpleAdd from '../../../components/SimpleAdd'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'

const formName = 'ActHiddenWorksAdd'
export default function ActHiddenWorksAdd({ history, match }) {
  const dispatch = useDispatch()
  const arrProps = [
    { xs: 3, field: 'dateCreate', type: 'text', label: 'Дата составления акта', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { xs: 9, type: 'divider' },
    { xs: 12, textProps: { multiline: true, maxRows: 5, }, field: 'worksName', type: 'text', label: ' К освидетельствованию предъявлены следующие работы:' },
    { xs: 12, textProps: { multiline: true, maxRows: 5, }, field: 'designDoc', type: 'text', label: 'Работы выполнены по проектной документации' },
    { xs: 12, textProps: { multiline: true, maxRows: 5, }, field: 'material', type: 'text', label: 'При выполнении работ применены' },
    { xs: 12, textProps: { multiline: true, maxRows: 5, }, field: 'exeScheme', type: 'text', label: 'Предъявлены документы,подтверждающие соответствие работ предъявленным к ним требованиям' },
    { xs: 3, field: 'dateStart', type: 'text', label: 'Дата начала работ', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { xs: 3, field: 'dateEnd', type: 'text', label: 'Дата окончания работ', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { xs: 6, type: 'divider' },
    { xs: 12, textProps: { multiline: true, maxRows: 5, }, field: 'conformityWorks', type: 'text', label: ' Работы выполнены в соответствии с' },
    { xs: 12, textProps: { multiline: true, maxRows: 5, }, field: 'worksNext', type: 'text', label: 'Разрешается производство последующих работ по' },
    { xs: 12, textProps: { multiline: true, maxRows: 5, }, field: 'moreDetails', type: 'text', label: 'Дополнительные сведения' },
    { xs: 3, field: 'copies', type: 'text', label: 'Количество копий', textProps: { type: 'number', min: '0' }, },
  ]
  useEffect(() => {
    dispatch(actions.formInitialize({
      dateCreate: '',
      worksName: '',
      designDoc: '',
      material: '',
      exeScheme: '',
      dateStart: '',
      dateEnd: '',
      conformityWorks: '',
      worksNext: '',
      moreDetails: '',
      copies: 1,
      errorValidate: {
      }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSubmit = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        idProject: match.params.idProject,
        worksId: [],
        worksNextArr: [],
        dateCreate: values.dateCreate,
        worksName: values.worksName,
        designDoc: values.designDoc,
        material: values.material,
        exeScheme: values.exeScheme,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
        conformityWorks: values.conformityWorks,
        worksNext: values.worksNext,
        moreDetails: values.moreDetails,
        copies: values.copies,
      },
      url: addIHW.path,
      method: addIHW.type,
      history: history
    }))
  }
  return (<div className='App-paper' style={{ padding: '16px' }}>
    <form autoComplete="off" onSubmit={handleSubmit} >
      <SimpleAdd
        title='Акт освидетельствования скрытых работ'
        arr={arrProps}
        formName={formName}
        gridProps={{ xs: 12 }}
      />
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color='primary' >Сохранить</Button>
    </form>
  </div>)
}
