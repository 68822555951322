import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import {
  Button, Dialog, DialogTitle, DialogContent, List, ListItem,
  ListItemText, ListSubheader, Typography, DialogActions,
  ListItemSecondaryAction, IconButton, Checkbox, FormControlLabel
} from '@material-ui/core';

import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'

import {
  updProjectGantSchedule,
} from '../../api/form-gant.api'
import moment from 'moment'
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 200,
    padding: 0,

  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,

  },
}));

export default function DialogSchedule({ formName, selected, idProject, setSelected }) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const form = useSelector(state => state.form[formName] || state.form.default)
  const [obj, setObj] = useState({ depCount: [] })
  const [dep, setDep] = useState({})
  const [arrTime, setTime] = useState([])
  const { byId = {}, count = 0, byIdR, start } = form.values
  const [roundHours, setRoundHours] = useState(false)
  useEffect(() => {
    if (selected === '') return;
    if (open === false) return;
    const [row, index] = selected.split('_')
    const obj = byId[row]
    const dep = obj.dependencies[index]

    setObj(obj)
    setDep(dep)
    const arr = Object.values(byIdR).filter(e => (e.id === obj.idAPI && e.resourceId === dep.id))
    setTime(arr.sort((a, b) => { 
      if(a.pos < b.pos) return -1;
      if(a.pos > b.pos) return 0;
      return 0;
     }))
  }, [selected, byId, count, byIdR, open])
  const handleSet = (day, row, hours, pos) => {
    const daySet = moment(day)
    dispatch(fetchDispath({
      param: {
        id: idProject,
        gantId: obj.idAPI,
        resourceId: dep.id,
      },
      body: {
        date: daySet.format('YYYY-MM-DD'),
        hours: hours
      },
      request: updProjectGantSchedule,
    })).then(e => {
      if (hours === 0) {
        return dispatch(actions.formObjDelete({ id: row, field: 'byIdR', name: formName }));
      }
      dispatch(actions.formObjChangeObj({
        date: daySet,
        hours: hours,
        id: obj.idAPI,
        pos: pos,
        resourceId: dep.id,
        save: true
      }, { field: 'byIdR', id: row, name: formName }))
    })
  }
  const handleAll = () => {
    const date = moment(obj.start, 'DD.MM.YYYY')
    let endHours = roundHours ? ((dep.count * obj.count) % obj.duration) : 0
    const arrSet = []
    const hours = (dep.count * obj.count - endHours) / obj.duration
    dispatch(actions.formObjChange({
      ...obj.depCount,
      [dep.id]: dep.count * obj.count
    },
      { field: 'byId', fieldObj: 'depCount', id: 'id' + obj.idAPI, name: formName })
    )
    const posStart = date.diff(start, 'days') + 1
    for (let i = 0; i < obj.duration; i++) {
      const pos = posStart + i
      arrSet.push(pos)
      const row = obj.idAPI + '_' + dep.id + '_' + pos
      let hoursNew = hours
      if (endHours > 0) { hoursNew += 1; endHours -= 1; }
      handleSet(date, row, hoursNew, pos)
      date.add(1, 'day')
    }
    arrTime.filter(x => !arrSet.includes(x.pos)).forEach(objDel => {
      const row = obj.idAPI + '_' + dep.id + '_' + objDel.pos
      handleSet(objDel.date, row, 0, objDel.pos)
    })
  }
  const handleDelete = (objDel) => {
    dispatch(actions.formObjChange({
      ...obj.depCount,
      [dep.id]: obj.depCount[dep.id] - objDel.hours
    },
      { field: 'byId', fieldObj: 'depCount', id: 'id' + obj.idAPI, name: formName })
    )
    const row = obj.idAPI + '_' + dep.id + '_' + objDel.pos
    handleSet(objDel.date, row, 0, objDel.pos)
  }
  return <React.Fragment>
    <Button color='primary' component="span" onClick={() => setOpen(true)} disabled={selected === ''} >Установить время</Button>
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => { setOpen(false); setSelected('') }}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: 600, position: 'relative', } }}
      subheader={<li />}
    >
      <DialogTitle id="alert-dialog-title">{dep.name}</DialogTitle>
      <DialogContent >
        <Typography>План ч.ч  {dep.count * obj.count}</Typography>
        <Typography>Всего ч.ч. по графику  {obj.depCount[dep.id] || 0}</Typography>
        <Typography>Осталось распределить  {dep.count * obj.count - (obj.depCount[dep.id] || 0)}</Typography>
        <List className={classes.root} component="nav">
          <li className={classes.listSection} >
            <ul className={classes.ul} >
              <ListSubheader key={obj.pos} >
                дата | часы
              </ListSubheader>
              {arrTime.map(obj => {
                return <ListItem key={obj.pos} >
                  <ListItemText primary={obj.date.format('DD.MM.YYYY')} />
                  <ListItemText primary={obj.hours} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={(e) => {
                      if (e.currentTarget.disabled) return;
                      e.currentTarget.disabled = true;
                      handleDelete(obj, e)
                    }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              })}
            </ul>
          </li>
        </List>
        <Typography>Дней по графику  {obj.duration}</Typography>
        <Typography>Среднее за день  {dep.count * obj.count / obj.duration}</Typography>
        <FormControlLabel
          style={{ margin: 4 }}
          control={
            <Checkbox
              checked={roundHours}
              onChange={() => setRoundHours(!roundHours)}
              value="primary"
              inputProps={{ 'aria-label': 'Целые часы' }}
            />
          }
          label='Целые часы'
        />

        <DialogActions><Button color='primary' onClick={handleAll}>Распределить</Button>  </DialogActions>
      </DialogContent>
    </Dialog>
  </React.Fragment>
}