import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, updProfile } from '../../api/users'
import Button from '@material-ui/core/Button';
import { formDestroy, formInitialize, formSubmitAPI, formChange } from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import isEmail from 'validator/lib/isEmail'

const formName = 'ProfileEdit'

function ProfileEdit({ history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    const load = async () => {
      const res = await dispatch(fetchDispath({ progress: true, request: getProfile, }, formName))
      const labelProject = res.get.facility + ' ' + res.get.project
      dispatch(formInitialize({
        name: res.get.name,
        surname: res.get.surname,
        patronymic: res.get.patronymic,
        email: res.get.email,
        labelProject: labelProject || '',
        errorValidate: {
          name: false,
          surname: false,
          email: false
        }
      }, { name: formName }))
    }
    load()
    return () => {
      dispatch(formDestroy({ name: formName }))
    }
  }, [dispatch])
  const arrProps = [
    { field: 'surname', type: 'text', validator: [(value) => value !== ''], errorText: 'Поле обязательно', label: 'Фамилия' },
    { field: 'name', type: 'text', validator: [(value) => value !== ''], errorText: 'Поле обязательно', label: 'Имя' },
    { field: 'patronymic', type: 'text', label: 'Отчество' },
    { field: 'email', type: 'text', validator: [isEmail], errorText: 'Это не email', label: 'Почта' },
  ]
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleUpdate = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    const params = {
      name: values.name,
      surname: values.surname,
      email: values.email,
      patronymic: values.patronymic,
    }
    dispatch(formSubmitAPI({
      props: params,
      url: updProfile.path,
      method: updProfile.type,
      history: history
    }, formName))
  };
  return <div className='App-paper'>
    <form autoComplete="off" onSubmit={handleUpdate}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd
          arr={arrProps}
          title='Редактировать профиль'
          formName={formName}
        />
      </div>
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>
}
export default ProfileEdit;