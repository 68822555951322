export default function download(allId,byId,column,columnName, filename) {
  const data = allId.map(key=>{
    let str = column.map(value=>{
      return byId[key][value]
    })
    return str.join(';')+'\n'
  }) 
  data.unshift('\ufeff'+columnName.join(';')+'\n')
  const file = new Blob(data, {type: 'text/csv'});
  if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
  else { // Others
      var a = document.createElement("a"),
              url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);  
      }, 0); 
  }
}