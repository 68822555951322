import React from 'react';
import PageList from '../../../components/PageListAPI'
import { getUserList } from '../../../api/users'
import { rules } from '../../../rbac-rules'

export default function Users(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='user'
        title='Список пользователей'
        columns={[
          { id: 'surname', name: 'Фамилия', },
          { id: 'name', name: 'Имя', },
          { id: 'patronymic', name: 'Отчество', },
          { id: 'roleRus', name: 'Права', },
        ]}
        createItem={true}
        loadAPI={{
          request: getUserList,
          transform: (res => {
            return {
              count: res.count,
              get: res.get.map(obj => {
                return { ...obj, roleRus: rules[obj.role] }
              })
            }
          }),
        }}
        classes={{}}
        {...props}
      />
    </div>
  )
};