import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import { addCompanies, getCompanyAPI } from '../../../api/list'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'
import { fetchDispath } from '../../../functions/fetch'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../../reducers/autocomplite'
import { getCompanies } from '../../../api/list'

const formCompanies = 'Companies'
const arrProps = [
  {
    bp: { md: 6, sm: 12 },
    field: 'fullName', type: 'text', label: 'Полное название компании*',
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'shortName', type: 'text', label: 'Сокращенное название компании*',
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'inn', type: 'text', label: 'ИНН*',
    validator: [value => value !== '', v => v.length >= 10 && v.length <= 12], errorText: 'Поле обязательно',
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'ogrn', type: 'text', label: 'ОГРН ОГРНИП*',
    validator: [value => value !== '', v => v !== '' && v.length >= 13 && v.length <= 15], errorText: 'Поле обязательно',
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'kpp', type: 'text', label: 'КПП',
    validator: [v => v === '' || v.length === 9], errorText: 'Неправильное значение',
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'okpo', type: 'text', label: 'ОКПО',
    // validator: [v => v === '' || v.length === 12], errorText: 'Неправильное значение',
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'oktmo', type: 'text', label: 'ОКТМО',
  },
  { bp: { md: 6, sm: 12 }, field: 'adress', type: 'text', label: 'Адрес', },
  { bp: { md: 6, sm: 12 }, field: 'phone', type: 'text', label: 'Телефон', },
  { bp: { md: 6, sm: 12 }, field: 'email', type: 'text', label: 'Почта', },
  // { field: 'selfRegulatory', label: 'Саморегулируемая организация', type: 'autoRedux', change: e => handleChangeAuto('selfRegulatory', e.value), textProps: { formArr: formCompanies } },
  // { field: 'selfRegulatoryDoc', type: 'text', label: 'Свидетельство №', },
]
const formName = 'CompaniesAdd'

export default function CompaniesAdd({ history, modal = false, modalClose }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(initialAutocomplite({ field: formCompanies }))

    dispatch(actions.formInitialize({ fullName: '', shortName: '', inn: '', ogrn: '', kpp: '', okpo: '', oktmo: '', adress: '', phone: '', email: '' }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formCompanies }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getCompanies.path, {
      field: formCompanies,
      value: 'id',
      labelFunc: (obj) => obj.shortName
    }))
  }, [dispatch])

  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    const { fullName, shortName, inn, ogrn, kpp, okpo, oktmo, adress, phone, email } = values
    dispatch(actions.formSubmitAPI({
      props: {
        fullName,
        shortName,
        inn,
        ogrn,
        kpp,
        okpo,
        oktmo,
        adress,
        phone,
        email,
        selfRegulatory: null,
        selfRegulatoryDoc: null
      },
      url: addCompanies.path,
      method: addCompanies.type,
      history: history,
      endFunc: (res) => {
        if (modal) {
          modalClose({ success: res.success, get: res.get })
        }
      }
    }, formName))
  };
  const handleLoad = () => {
    dispatch(fetchDispath({
      querty: {
        inn: values.inn
      },
      progress: false,
      request: getCompanyAPI,
    }))
      .then(res => {
        if (res.success) {
          dispatch(actions.formChangeAsObj({
            fullName: res.get.fullName,
            shortName: res.get.shortName,
            inn: res.get.inn,
            ogrn: res.get.ogrn,
            kpp: res.get.kpp,
            okpo: res.get.okpo,
            oktmo: res.get.oktmo,
            adress: res.get.address,
            phone: res.get.phone,
            email: res.get.email
          }, { name: formName }))
        }
        else {
          dispatch(enqueueSnackbar({
            message: `Компания не найдена`,
            options: { variant: 'error' }
          }))
        }
      })
      .catch(err => console.log(err))
  }
  const handleBack = () => {
    if (!modal) {
      history.goBack()
      return;
    }
    modalClose({ success: false })
  }
  return (
    <div className='App-paper' >
      <Button onClick={handleLoad} color="primary" >Заполнить по ИНН</Button>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd formName={formName} arr={arrProps} title='Добавление компании' gridProps={{ xs: 12 }} />
        </div>
        <Button color="primary" onClick={handleBack}>{modal ? 'Закрыть' : 'Назад'} </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}