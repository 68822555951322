import React from 'react'
import PageList from '../../components/PageListAPI'
import { getRemarksListAPI, delRemarksOneAPI } from '../../api/remarks.api'
import { arrLocation, statusRUS } from './remarks.const'
export default function RemarksList(props) {
  return <div className='App-paper'>
    <PageList
      name='workerList'
      title='Замечания'
      columns={[
        { id: 'dateCreate', name: 'Дата создания', type: 'date', formatDate: 'LL' },
        { id: 'reason', name: 'Основание' },
        { id: 'correctionDate', name: 'Дата исправления', type: 'date', formatDate: 'LL' },
        { id: 'creator', name: 'Создатель', type: 'component', component: ({ obj }) => (`${obj?.creator?.surname} ${obj?.creator?.name[0]}. ${obj?.creator?.patronymic[0]}.`) },
        { id: 'responsible', name: 'Исполнитель', type: 'component', component: ({ obj }) => (`${obj?.responsible?.surname} ${obj?.responsible?.name[0]}. ${obj?.responsible?.patronymic[0]}.`) },
        { id: 'status', name: 'Статус', type: 'component', component: ({ obj }) => (<span style={{ color: statusRUS[obj.status].color }}>{statusRUS[obj.status].label}</span>) },
        {
          id: 'location', name: 'Расположение', type: 'component', component: ({ obj }) => {
            const arr = []
            arrLocation.forEach(loc => {
              const val = obj?.location[loc.field]
              if (val && val !== '') {
                arr.push(`${loc.label}: ${val}`)
              }
            })
            return arr.length > 0 ? arr.join(', ') : obj?.location.place
          }
        },
      ]}
      createItem={true}
      deleteItem={true}
      loadAPI={{
        param: {
          id: props.match.params.idProject
        },
        request: getRemarksListAPI,
      }}
      deleteAPI={delRemarksOneAPI(props.match.params.idProject)}
      headerInit={{
        sortBy: 'dateCreate',
        sortDirection: 'desc',
      }}
      {...props}
    />
  </div>
}