import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../../reducers/autocomplite'
import { getMaterials } from '../../../api/material'
import { getEquipment } from '../../../api/list'
import { addDocMoveProject, getOurStorage } from '../../../api/storage'
import DocTable from '../../../components/DocTable'
import DocAdd from '../../../components/DocAdd'

const formName = 'addMoveStorage'
const arrMaterial = 'material'
const arrEquipment = 'materialStorage'
export default function MoveStorageAdd({ match, history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ byId: {}, allIds: [], idStorage: '' }, { name: formName }))
    dispatch(initialAutocomplite({ field: arrMaterial }))
    dispatch(initialAutocomplite({ field: arrEquipment }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite(arrMaterial))
      dispatch(clearAutocomplite(arrEquipment))
    }
  }, [dispatch])
  // load arr
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getEquipment.path, {
      field: arrEquipment,
      value: 'id',
      labelFunc: (obj) => obj.name,
      hidden: ['storageUnit'],
    }))
    dispatch(loadAutocompliteAPI(getMaterials.path({ id: match.params.id }) + '?type=storage', {
      field: arrMaterial,
      value: 'id',
      labelFunc: (obj) => obj.name + ' ' + obj.typeMark + ' ' + obj.vendorCode,
      hidden: ['storageUnit', 'idMaterial', 'storage']
    }))
  }, [dispatch, match.params.id])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const saveServer = () => {
    let arr = []
    values.allIds.forEach(row => {
      const obj = values.byId[row]
      const count = parseFloat(obj.count.replace(',', '.').replace(' ', ''))
      if (obj.idMaterial > 0 && count > 0 && obj.coef > 0)
        arr.push({
          count: count,
          idMaterial: obj.idMaterial,
          idMaterialStorage: obj.idMaterialStorage,
          coef: obj.coef,
        })
    });
    dispatch(actions.formSubmitAPI({
      props: {
        arr: arr,
        idStorage: values.idStorage,
      },
      url: addDocMoveProject.path({ id: match.params.id }),
      method: addDocMoveProject.type,
      history: history
    }, formName))
  }
  return <div className='App-paper'>
    <DocAdd
      formName={formName}
      history={history}
      title='Переместить на склад'
      noTableProps={[
        {
          field: 'idStorage', label: 'Склад', type: 'autoLoad', textProps: {
            path: getOurStorage.path,
            meta: {
              field: 'storage',
              value: 'id',
              labelFunc: (obj) => obj.name
            }
          }
        },
      ]}
      handleSave={saveServer}
    >
      <DocTable
        formName={formName}
        column={[
          {
            id: 'valueMaterial', type: 'auto', name: 'Материал сметы', formArr: arrMaterial,
            filtered: true, changeValue: e => ({
              idMaterial: e.value,
              storageUnit: e.storageUnit,
              storage: e.storage,
              valueMaterial: e,
            }),
            width: '30%'
          },
          { id: 'storageUnit', type: 'string', name: 'Ед.изм' },
          {
            id: 'valueMaterialStorage', type: 'auto', name: 'Материал склада', formArr: arrEquipment,
            filtered: true, changeValue: e => ({
              idMaterialStorage: e.value,
              storageUnitStorage: e.storageUnit,
              valueMaterialStorage: e,
            }),
            width: '30%'
          },
          { id: 'storageUnitStorage', type: 'string', name: 'Ед.изм' },
          {
            id: 'count', type: 'number', name: 'Количество', title: obj => `max ${obj.storage}`, width: '25%',
            textProps: obj => ({ error: obj.count > obj.storage, helperText: obj.count > obj.storage ? 'Больше чем на складе' : '' })
          },
          { id: 'coef', type: 'number', name: 'К(П/С)', title: obj => `max ${obj.storage}`, width: '10%', }
        ]}
        rowDefault={{
          idMaterial: '',
          idMaterialStorage: '',
          storageUnit: '',
          storageUnitStorage: '',
          valueMaterial: null,
          valueMaterialStorage: null,
          count: '',
          storage: '',
          coef: 1,
        }}
      />
    </DocAdd>
  </div>
}