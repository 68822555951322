import React, { useEffect, useState, useCallback } from "react";
import moment from 'moment'
import {
  Popover, TextField, Typography, Table, TableBody,
  TableHead, TableRow, TableCell, Button, IconButton, Tooltip,
  Collapse, Box, Dialog, DialogTitle, DialogContent
} from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { fetchDispath } from '../../functions/fetch'
import { getProjectMachineDay, getProjectWayBillByIdPrint, getProjectWayBillById } from '../../api/projects'
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import print from '../../functions/print'
import WayBillCreateForDialog from '../machineCalendar/WayBillCreateForDialog'

const column = [
  { id: 'timeStart', label: 'начало план' },
  { id: 'timeEnd', label: 'конец план' },
  { id: 'model', label: 'модель' },
  { id: 'govNumber', label: 'гос. номер' },
  { id: 'driver', label: 'водитель' },
  { id: 'waybillExist', label: 'путевой лист' },
  { id: 'status', label: 'статус' },
  { id: 'dateStart', label: 'начало факт' },
  { id: 'dateEnd', label: 'конец факт' },
  { id: 'linkOpen', label: 'доступ' },
]
const STATUS_MACHINE = {
  garage: 'в гараже',
  line: 'на линии',
  finished: 'закончил',
}
export default function WorkplaceMachine({ match }) {
  const [day, setDay] = useState(moment().format('YYYY-MM-DD'))
  // const [limit, setLimit] = useState(100)
  // const [offset, setOffset] = useState(0)
  const [arr, setArr] = useState([])
  // const [arrCount, setArrCount] = useState(0)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [dialogProps, setDialogProps] = useState({ date: moment(), idCalendar: '', idMachine: '' })
  const dispatch = useDispatch()
  const loadData = useCallback(() => {
    dispatch(fetchDispath({
      querty: {
        // limit,
        // offset,
        day,
      },
      param: {
        id: match.params.idProject
      },
      progress: true,
      request: getProjectMachineDay,
    })).then(res => {
      setArr(res.get.map(e => {
        return {
          ...e,
          dateStart: e.dateStart ? moment(e.dateStart).format('HH:mm:ss') : '',
          dateEnd: e.dateEnd ? moment(e.dateEnd).format('HH:mm:ss') : '',
          waybillExist: Boolean(e.waybill),
          status: e.dateStart ? e.dateEnd ? STATUS_MACHINE.finished : STATUS_MACHINE.line : STATUS_MACHINE.garage,
          linkOpen: Boolean(e.link),
        }
      }))
      // setArrCount(res.count)
    }).catch(err => console.log(err))
  }, [day, dispatch, match.params.idProject])
  useEffect(() => {
    loadData()
  }, [loadData])
  return <div className="App-paper">
    <DialogAdd
      open={openAddDialog}
      onClose={() => { setOpenAddDialog(false) }}
      idProject={match.params.idProject}
      handleReload={() => loadData()}
      idCalendar={dialogProps.idCalendar}
      date={dialogProps.date}
      idMachine={dialogProps.idMachine}
    />

    <Typography variant='h6'>Контроль машин на линии</Typography>
    <TextField type='date' label='Дата' value={day} onChange={e => { setDay(e.target.value) }} />
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 10 }}></TableCell>
          {column.map(e => {
            return <TableCell key={e.id}>{e.label}</TableCell>
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {arr.map(obj => {
          return <RowTableMachine
            key={obj.id}
            obj={obj}
            match={match}
            openDialog={() => { setDialogProps({ date: moment(day), idCalendar: obj.id, idMachine: obj.idMachine }); setOpenAddDialog(true) }}
          />
        })}
      </TableBody>
    </Table>
  </div>
}
const columnTask = [
  { id: 'order', type: 'text', name: 'в чье распоряжение' },
  { id: 'loadingAddress', type: 'text', name: 'адрес погрузки' },
  { id: 'unloadingAddress', type: 'text', name: 'адрес разгрузки' },
  { id: 'cargoName', type: 'text', name: 'наименование груза' },
  { id: 'arrivalTime', type: 'time', name: 'время прибытия, ч. мин' },
  { id: 'count', type: 'number', name: 'количество ездок' },
  { id: 'distance', type: 'number', name: 'расстояние, км' },
  { id: 'transported', type: 'number', name: 'перевезти, тонн' },
]
const columnSpec = [
  { id: 'order', type: 'text', name: 'в чье распоряжение' },
  { id: 'arrivalTime', type: 'time', name: 'время прибытия, ч. мин' },
  { id: 'typeWork', type: 'text', name: 'вид работы' },
  { id: 'description', type: 'text', name: 'примечание' },
]
function RowTableMachine({ obj, match, openDialog }) {
  const [openTask, setOpenTask] = useState(false)
  const [taskArray, setTask] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          idProject: match.params.idProject,
          id: obj.waybill,
        },
        request: getProjectWayBillByIdPrint,
      }
    )
  }
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
  }
  const handleOpenTask = () => {
    dispatch(fetchDispath({
      param: {
        idProject: match.params.idProject,
        id: obj.waybill
      },
      progress: true,
      request: getProjectWayBillById,
    })).then(res => {
      setTask(res.task)
      setOpenTask(true)
    })
  }
  const col = obj.typeM === 'спец. автомобиль' ? columnSpec : columnTask
  return <React.Fragment>
    <TableRow>
      <TableCell style={{ width: 10 }}>
        {!openTask && <IconButton disabled={!obj.waybillExist} onClick={handleOpenTask}><ExpandMoreIcon fontSize="small" /></IconButton>}
        {openTask && <IconButton onClick={() => { setOpenTask(false) }}><ExpandLessIcon fontSize="small" /></IconButton>}
      </TableCell>
      {column.map(e => {
        switch (e.id) {
          case 'waybillExist': {
            if (obj.waybillExist) {
              return <TableCell key={e.id}>
                <Button variant="outlined" size="small" color="primary" onClick={handlePrint}>Печать</Button>
              </TableCell>
            }
            return <TableCell key={e.id}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={openDialog}
              >Оформить</Button>
            </TableCell>
          }
          case 'linkOpen':
            return <TableCell key={e.id}>
              <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                <InsertLinkIcon fontSize="small" color="primary" />
              </IconButton>
            </TableCell>
          default:
            return <TableCell key={e.id}>{obj[e.id]}</TableCell>
        }
      })}
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={column.length + 1}>
        <Collapse in={openTask} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography gutterBottom component="div">
              <b>Задание водителю</b>
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  {col.map(e => {
                    return <TableCell key={e.id}><b>{e.name}</b></TableCell>
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {taskArray.map(task => {
                  return <React.Fragment key={task.id}>
                    <TableRow >
                      {col.map(col => {
                        return <TableCell key={col.id}>{task[col.id]}</TableCell>
                      })}
                    </TableRow>

                    {task.taskResult.map(taskRes => {
                      return <TableRow> {col.map(e => {
                        if (['arrivalTime', 'count', 'distance', 'transported'].includes(e.id)) {
                          return <TableCell key={e.id}>{taskRes[e.id]}</TableCell>
                        }
                        return <TableCell key={e.id}></TableCell>
                      })}</TableRow>
                    })}

                  </React.Fragment>
                })}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={() => setAnchorEl(null)}
    >
      <div style={{ margin: 10 }}>
        <div onClick={() => handleCopy(`${window.location.origin}/machine-set/${obj.link}`)} style={{ cursor: 'pointer' }}>
          <Tooltip title="Копировать в буфер ссылку" placement="bottom">
            <Typography >{`${window.location.origin}/machine-set/${obj.link}`}</Typography>
          </Tooltip>
        </div>
        <div onClick={() => handleCopy(obj.pswd)} style={{ cursor: 'pointer' }}>
          <Tooltip title="Копировать в буфер пароль" placement="bottom">
            <Typography >{obj.pswd}</Typography>
          </Tooltip>
        </div>
      </div>
    </Popover>
  </React.Fragment>
}


function DialogAdd({ open, onClose, idProject, handleReload, idCalendar, date, idMachine }) {
  return <Dialog
    open={open}
    maxWidth='sm'
    keepMounted
    fullWidth
    scroll="paper"
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    PaperProps={{ style: { minHeight: 500 } }}
  >
    <DialogTitle id="max-width-dialog-title">Оформить путевой лист</DialogTitle>
    <DialogContent>
      <WayBillCreateForDialog
        idCalendar={idCalendar}
        idProject={idProject}
        date={date.format('YYYY-MM-DD')}
        handleAdd={() => { onClose(); handleReload() }}
        idMachine={idMachine}
      />
    </DialogContent>
  </Dialog>
}