// @ts-check
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string'
import * as actions from '../reducers/formTable';
import CustomTable2 from './TableCardForm'
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { fetchDispath } from '../functions/fetch'
import download from '../functions/download'
import { enqueueSnackbar } from '../reducers/notifier'
import FilterArray from './FilterArray';
import { Typography } from "@material-ui/core";
import PageListColumn from './PageListFilretColumn'
const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
  },
  butt: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1
  },
  row: {
    color: '#000000',
    '&$selected': {
      color: '#aaaaaa'
    }
  },
  selected: {},
  table: {
    height: 700
  },
  cell: {
    // fontSize: '0.8rem'
  },
  pagination: {},
  container: {
    height: '65vh'
  }
}));
/**
 * @typedef {import("./types/PageListAPI").Columns} Columns
 */


/** 
 * @param {import("./types/PageListAPI").PageListAPI} param0 
 */
export default function PageListAPI({
  classes = {},
  name,
  columns,
  createItem = false,
  deleteItem = false,
  idName = 'id',
  tableProps,
  click = true,
  csv = true,
  headerInit = {},
  notLoad = false,
  notClear = false,
  pagination = true,
  reload = false,
  reloadFunc,
  onClickFunction,
  filterProps = [],
  title = '',
  deleteMulti = false,
  deleteMultiProps = 'arr',
  location,
  match,
  history,
  loadAPI,
  deleteAPI,
  disabledDelete,
  headerLink,
  headerObj,
  tableActions = {},
  goBack = true,
  additionalActions = []
}) {

  const classesPage = { ...useStyles(), ...classes }
  const dispatch = useDispatch();
  const searchLocation = location.search
  let obj = queryString.parse(searchLocation)
  if (pagination && !obj.limit) {
    obj.limit = '50'
    obj.offset = '0'
  }
  const [search, setSearch] = useState(obj)
  const [searchOld, setSearchOld] = useState('-1')
  const [arrDelete, setArrDelete] = useState([])
  const [arrHidden, setArrHidden] = useState([])
  const formTable = useSelector(state => state.formTable[name] || state.formTable.default)
  const load = useCallback((search) => {
    dispatch(fetchDispath({
      querty: {
        ...search,
        ...loadAPI.querty
      },
      param: loadAPI.param,
      progress: true,
      request: loadAPI.request,
      transform: loadAPI.transform,
    })).then(res => {
      dispatch(actions.loadFormTable({ ...res }, { name: name, id: idName }))
    }).catch(err => console.log(err))
  }, [dispatch, name, idName])

  useEffect(() => {
    if (notLoad) return;
    dispatch(actions.initFormTable({ name: name, header: { ...headerInit } }))
    return () => {
      if (notClear) return;
      dispatch(actions.clearFormTable(name))
    }
  }, [dispatch, name, notLoad, notClear])
  useEffect(() => {
    if (notLoad) return;
    if (searchLocation === '' && pagination) {
      load(search)
      return;
    }
    if (searchLocation !== searchOld) {
      const searchNew = queryString.parse(searchLocation)
      setSearch(searchNew)
      setSearchOld(searchLocation)
      load(searchNew)
      return;
    }
  }, [dispatch, notLoad, searchLocation, load, searchOld, pagination, search])
  useEffect(() => {
    if (notLoad || !reload) return;
    reloadFunc()
    load(queryString.parse(searchLocation))
  }, [dispatch, notLoad, searchLocation, load, reload, reloadFunc])
  const deleteCustom = (param, body) => {
    dispatch(fetchDispath({
      param,
      body,
      progress: false,
      request: deleteAPI
    }))
      .then((res) => {
        let msg = {}
        if (res) {
          load(search)
          msg = {
            message: `success`,
            options: { variant: 'success' }
          }
        }
        else {
          msg = {
            message: `not deleted`,
            options: { variant: 'error' }
          }
        }
        dispatch(enqueueSnackbar(msg))
      })
  }
  const handleDeleteOne = (id) => {
    deleteCustom({
      id: formTable.byId[id][idName]
    })
  }
  const handleDeleteMulti = () => {
    deleteCustom(undefined, {
      [deleteMultiProps]: arrDelete.map(id => formTable.byId[id][idName])
    })
  }
  const linkURL = (id) => `${match.url}/${formTable.byId[id][idName]}`
  const handleClickCSV = () => {
    download(formTable.allIds, formTable.byId, columns.map(obj => obj.id), columns.map(obj => obj.name), name + '.csv')
  }
  const editFilterValue = (field, value) => {
    let searchNew = {
      ...search,
      limit: '50',
      offset: '0',
      [field]: value
    };
    setSearch(searchNew)
    history.push(`?${queryString.stringify(searchNew)}`)
  }
  const addToArrDelete = (id) => {
    if (arrDelete.includes(id)) {
      setArrDelete(arrDelete.filter(e => id !== e))
    }
    else {
      setArrDelete([...arrDelete, id])
    }
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (disabledDelete) {
        const arr = []
        formTable.allIds.forEach(key => {
          const obj = formTable.byId[key]
          const addDel = disabledDelete(obj)
          if (!addDel) arr.push(key)
        })
        setArrDelete(arr)
        return;
      }
      setArrDelete(formTable.allIds)
      return;
    }
    setArrDelete([])
  };
  const handleSort = (sortBy, sortDirection) => {
    dispatch(actions.setSortFormTable({ sortBy, sortDirection }, { name: name }))
  }
  const handleChangePage = (event, pageNew) => {
    let searchQuery = queryString.parse(searchLocation)
    const { limit, offset } = search
    const page = Number(offset) / Number(limit)
    if (page !== pageNew && event) {
      searchQuery.offset = (Number(limit) * pageNew).toString()
      if (typeof searchQuery.limit === 'undefined') searchQuery.limit = limit
      history.push(`?${queryString.stringify(searchQuery)}`)
      window.scrollTo(0, 0)
    }
  };
  const handleChangeRowsPerPage = event => {
    let searchQuery = queryString.parse(searchLocation)
    if (Number(searchQuery.limit) !== Number(event.target.value)) {
      searchQuery.limit = event.target.value
      searchQuery.offset = '0'
      history.push(`?${queryString.stringify(searchQuery)}`)
    }
  };
  const toLinkAdd = React.forwardRef((props, ref) => (
    <Link ref={ref} to={`${match.url}/add`} {...props} />
  ));
  const hideColumn = (id, add) => {
    if (add) {
      setArrHidden([...arrHidden, id])
    }
    else {
      setArrHidden(arrHidden.filter(e => id !== e))
    }
  }
  return (
    <React.Fragment>
      {title !== '' && <Typography variant='h6'>{title}</Typography>}
      <div className={classesPage.header}>
        {createItem && <Button component={toLinkAdd} color="primary" >Добавить</Button>}
        {headerLink}
        <div className={classesPage.grow} />
        {deleteMulti &&
          <Button onClick={handleDeleteMulti} color="primary" className={classesPage.butt} disabled={arrDelete.length === 0}>Удалить</Button>
        }
        {csv && <PageListColumn
          csv={csv}
          columns={columns}
          handleClickCSV={handleClickCSV}
          arrHidden={arrHidden}
          hideColumn={hideColumn}
          additionalActions={additionalActions}
        />}
      </div>
      {headerObj}
      {filterProps.length > 0 && <FilterArray
        change={editFilterValue}
        arr={filterProps}
        name={name}
      />}
      <CustomTable2
        classes={classesPage}
        tableActions={{
          onDeleteRow: (deleteItem) ? handleDeleteOne : undefined,
          onClickRow: (click) ? linkURL : undefined,
          onClickFunction: onClickFunction,
          disabledDelete: disabledDelete,
          addToArrDelete: addToArrDelete,
          handleSelectAllClick: handleSelectAllClick,
          setSort: handleSort,
          ...tableActions
        }}
        tableHead={columns}
        tableData={formTable}
        history={history}
        search={{ limit: search.limit, page: Number(search.offset) / Number(search.limit) }}
        deleteMulti={deleteMulti}
        arrDeleteMulti={arrDelete}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        arrHidden={arrHidden}
        goBack={goBack}
        {...tableProps}
      />
    </React.Fragment>
  )
}
