import template from "./template_crud";

const shareLink = '/share/link'

export const getShare = template.get('/share')

export const getShareLink = template.get(shareLink)
export const addShareLink = template.addOne(shareLink)
export const updShareProps = {
  path: (obj) => `${shareLink}/${obj.id}`,
  type: 'POST'
}
export const updShareEnable = {
  path: (obj) => `${shareLink}/${obj.id}/enable`,
  type: 'POST'
}