import React, { useState } from 'react'
import DescriptionIcon from '@material-ui/icons/Description';
import { Chip, Dialog, DialogContent } from '@material-ui/core';
import FileViewer from '../../components/fileViewer/FileViever';

export default function ListFiles({ arrFile }) {
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState({ filePath: '', type: '', fileName: '' })
  const handleClick = (obj) => {
    if (obj.name === file.fileName && open) {
      setOpen(false);
      return;
    }
    setFile({ type: (obj.type !== 'xls') ? obj.type : 'xlsx', fileName: obj.name })
    setOpen(true);
    return;
  }
  function substrFileName(str) {
    if (str.length > 7) {
      return str.substr(0, 3) + '...' + str.substr(str.length-4, str.length);
    }
    return str;
  }
  return <div>
    {arrFile.map(obj => {
      return <Chip
        key={obj.id}
        icon={<DescriptionIcon />}
        label={substrFileName(obj.originalName)}
        onClick={() => handleClick(obj)}
      />
    })}
    <Dialog
      open={open}
      maxWidth={'lg'}
      keepMounted
      fullWidth
      heid
      scroll="paper"
      onClose={() => setOpen(false)}
    >
      <DialogContent >
        {open&&<FileViewer
          fileType={file.type}
          fileName={file.fileName}
        />}
      </DialogContent>
    </Dialog>
  </div>
}

