import React, { useEffect } from 'react';
import { Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/form';
import { addFacilityAccess } from '../../api/facility'
import SimpleAdd from '../../components/SimpleAdd'
import { initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import { getUserList } from '../../api/users'

const formName = 'FacilityAccessAdd'

export default function FacilityAccessAdd(props) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(initialAutocomplite({ field: 'user' }))
    dispatch(actions.formInitialize({
      idUser: '',
      oldAdd: true,
      errorValidate: {},
      construction: null,
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: 'user' }))

    }
  }, [dispatch])
  const arrProps = [
    {
      field: 'idUser', type: 'autoLoad', label: 'пользователь',
      textProps: {
        path: getUserList.path,
        meta: {
          field: 'user',
          value: 'id',
          labelFunc: (obj) => obj.surname + ' ' + obj.name + ' ' + obj.patronymic
        }
      }
    },
    {
      field: 'oldAdd',
      type: 'component',
      textProps:{
        component: (values, handleChange) => (<CheckAllAccess values={values} handleChange={handleChange} />)
      }
    }
  ]
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.formSubmitAPI({
      props: {
        idUser: values.idUser,
        oldAdd: values.oldAdd,
      },
      url: addFacilityAccess.path({ idFacility: props.match.params.id }),
      method: addFacilityAccess.type,
      history: props.history
    }), formName)
  }
  return (<div className='App-paper'>
    <form autoComplete="off" onSubmit={handleSubmit}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd
          arr={arrProps}
          title='Открыть доступ к проектам'
          formName={formName}
        />
      </div>
      <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>)
}


function CheckAllAccess({ values, handleChange }) {
  return <FormControlLabel
    control={
      <Checkbox
        checked={values || false}
        onChange={(e) => {
          handleChange(e.target.checked, 'oldAdd')
        }}
        color="primary"
      />
    }
    label="Только новые проекты"
  />
}