import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import * as actions from '../../reducers/formTable';
import { fetchDispath } from '../../functions/fetch'
import { getOutfitPlanDay } from '../../api/doc'
import { Button, Tab, Tabs, Grid, Typography, TextField } from '@material-ui/core';
import PageList from '../../components/PageListAPI'


export default function OurfitDayPlan({ match, history, location }) {
  const [value, setValue] = useState(0)
  const [date, setDate] = useState('')
  const dispatch = useDispatch();
  const tableW = 'OurfitOPByIdW'
  const tableWork = 'OurfitOPByIdWork'
  const tableM = 'OurfitOPByIdM'
  useEffect(() => {
    dispatch(actions.initFormTable({ name: tableWork }))
    dispatch(actions.initFormTable({ name: tableW }))
    dispatch(actions.initFormTable({ name: tableM }))
    return () => {
      dispatch(actions.clearFormTable(tableM))
      dispatch(actions.clearFormTable(tableW))
      dispatch(actions.clearFormTable(tableWork))
    }
  }, [dispatch])
  const getPlan = () => {
    if (!date) return;
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
      },
      querty: {
        date: date
      },
      progress: true,
      request: getOutfitPlanDay,

    }))
      .then((res) => {
        if (!res) return;
        let arrWork = []
        res.get.work.forEach(e => {
          arrWork = [...arrWork, ...e.work.split('\n').filter(e => e !== '')]
        })
        dispatch(actions.loadFormTable({ get: arrWork.map((e, i) => ({ id: i, name: e })) }, { name: tableWork, id: 'id' }))
        dispatch(actions.loadFormTable({ get: res.get.material }, { name: tableM, id: 'id' }))
        dispatch(actions.loadFormTable({ get: res.get.worker.map(obj => ({ name: obj.surname + ' ' + obj.name + ' ' + obj.patronymic, position: obj.position })) }, { name: tableW, id: 'id' }))
      })
  }
  return <div className='App-paper'>
    <Typography variant='h6'>Наряд заказ план на день</Typography>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
    >
      <Grid item>
        <Button color="primary" onClick={getPlan} >Сформировать</Button>
      </Grid>
      <Grid item>
        <TextField
          id="date"
          name='date'
          type={"date"}
          label='Дата'
          value={date}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setDate(e.target.value)}
        />
      </Grid>
    </Grid>
    <Tabs value={value} onChange={(e, v) => setValue(v)} indicatorColor='primary'>
      <Tab label='Работа' />
      <Tab label='Материалы' />
      <Tab label='Работники' />
    </Tabs>
    {value === 0 && <div style={{ margin: '8px' }}>
      <PageListFix
        name={tableWork}
        columns={[
          { id: 'name', name: 'Работы' },
        ]}
        match={match}
        location={location}
        history={history}
        tableProps={{ pagination: false }}
      />
    </div>
    }
    {value === 1 &&
      <PageListFix
        name={tableM}
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'count', name: 'Количество' },
          { id: 'storageUnit', name: 'Ед.изм' },
        ]}
        match={match}
        location={location}
        history={history}
      />
    }
    {value === 2 &&
      <PageListFix
        name={tableW}
        columns={[
          { id: 'name', name: 'ФИО' },
          { id: 'position', name: 'Должность' },
        ]}
        match={match}
        history={history}
        location={location}
      />
    }
    <Button color='primary' onClick={() => history.goBack()}>Назад</Button>
  </div>
}

const PageListFix = ({ name, columns, match, history, location }) => <PageList
  name={name}
  columns={columns}
  idName='id'
  csv={false}
  click={false}
  notLoad={true}
  notClear={true}
  match={match}
  history={history}
  location={location}
  pagination={false}
  goBack={false}
  tableProps={{ pagination: false }}
/>