import { actionCreatorObj, actionCreatorEmpty } from '../functions/redux'
const MAIN = 'loading/'
const START_LOAD_REQUEST = MAIN + 'START'
const STOP_LOAD_REQUEST = MAIN + 'STOP'
const SET_RELOAD_REQUEST = MAIN + 'SET_RELOAD'
const EMPTY_RELOAD_REQUEST = MAIN + 'EMPTY_RELOAD'

export const startLoadRequest = actionCreatorEmpty(START_LOAD_REQUEST)
export const setReloadRequest = actionCreatorObj(SET_RELOAD_REQUEST)
export const stopLoadRequest = actionCreatorEmpty(STOP_LOAD_REQUEST)

export const reload = (reloadAction) => dispatch => {
  dispatch(reloadAction())
}

export const initialState = {
  load: 0,
  reloadAction: actionCreatorEmpty(EMPTY_RELOAD_REQUEST),
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case START_LOAD_REQUEST: return { ...state, load: state.load + 1 }
    case STOP_LOAD_REQUEST: return { ...state, load: state.load > 0 ? state.load - 1 : 0 }
    case SET_RELOAD_REQUEST: return { ...state, reloadAction: action.payload }
    default: return state;
  }
}
