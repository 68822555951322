import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@material-ui/core'
import { fetchDispath } from '../../functions/fetch'
import * as actions2 from '../../reducers/form';
import * as api from '../../api/workdoc'
import SimpleCell from './source/SimpleCell'
import HeaderLink from './source/HeaderLink'
import CellFirst from './source/CellFirst'
const rowExample = {
  pos: '',
  name: '',
  type: '',
  code: '',
  plant: '',
  su: '',
  count: '',
  mass: '',
  other: '',
  row: ''
}
const rowParamExample = {
  color: '',
  isSection: false,
  row: 34,
  section: ''
}
const arrCol = ['pos', 'name', 'type', 'code', 'plant', 'su', 'count', 'mass', 'other', 'section']
const column = [
  { name: 'pos', width: '4.5%', label: 'Позиция' },
  { name: 'name', width: '38.6%', label: 'Наименование и техническая характеристика' },
  { name: 'type', width: '13.6%', label: 'Тип, марка, обозначение документа, опросного листа' },
  { name: 'code', width: '7.9%', label: 'Код оборудования, изделия, материала' },
  { name: 'plant', width: '10.2%', label: 'Завод-изготовитель' },
  { name: 'su', width: '4.5%', label: 'Единица измерения' },
  { name: 'count', width: '4.5%', label: 'Количество' },
  { name: 'mass', width: '5.6%', label: 'Масса единицы, кг' },
  { name: 'other', width: '10%', label: 'Примечание' }
]
const formTable = 'tableRowCell'
const formSelected = 'tableSelected'
const formHistory = 'tableHistory'
export default function WorkDocOne ({ match, history }) {
  const dispatch = useDispatch()
  const docId = match.params.idDoc
  useEffect(() => {
    dispatch(actions2.formInitialize({
      selectRow: '',
      selectCellRow: '',
      selectCellColumn: '',
      lastRow: 0,
      rowProps: {},
      title: ''
    }, { name: formSelected }))
    dispatch(actions2.formInitialize({
      byId: {},
      allIds: []
    }, { name: formTable }))
    dispatch(actions2.formInitialize({
      buff: [],
      current: -1
    }, { name: formHistory }))
    return () => {
      dispatch(actions2.formDestroy({ name: formTable }))
      dispatch(actions2.formDestroy({ name: formSelected }))
      dispatch(actions2.formDestroy({ name: formHistory }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: { id: docId, },
      progress: true,
      request: api.getWorkDocById,
    })).then(res => {
      const { rows, table, header } = res.get
      let byId = {}
      const lastObject = table[table.length - 1];
      let lastRow = 0
      if (lastObject) {
        lastRow = lastObject.row
        let allIds = (Array.from({ length: lastRow }, (v, k) => 'a' + (k + 1)))
        table.forEach(obj => {
          const id = 'a' + obj.row
          byId[id] = obj
        })
        dispatch(actions2.formChangeAsObj({ allIds, byId }, { name: formTable }))
      }
      let rowProps = {}
      rows.forEach(e => {
        rowProps[e.row] = e
      })
      dispatch(actions2.formChangeAsObj({ lastRow, rowProps, title: header.title }, { name: formSelected }))
    }).catch(err => console.log(err))
  }, [dispatch, docId])

  const formTableData = useSelector(state => state.form[formTable] || state.form.default)
  const formSelectedData = useSelector(state => state.form[formSelected] || state.form.default)
  const { byId, allIds = [] } = formTableData.values
  const { rowProps } = formSelectedData.values
  const N = 50

  // const formTableHIstory = useSelector(state => state.form[formHistory] || state.form.default)

  return <div >
    <HeaderLink
      docId={docId}
      match={match}
      history={history}
      formTable={formTable}
      formSelected={formSelected}
      formHistory={formHistory}
      rowExample={rowExample}
      rowParamExample={rowParamExample} />
    <TableContainer style={{ height: '83vh' }}>
      <Table size='small' stickyHeader style={{ borderCollapse: 'collapse', width:'99%' }}>
        <TableHead>
          <TableRow>
            <TableCell align='center'></TableCell>
            {
              column.map((v, i) => {
                return <TableCell key={i} align='center' style={{ width: v.width }}>{v.label}</TableCell>
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {allIds.map((v, i) => {
            const rowId = i + 1
            const obj = { ...rowExample, ...(byId[v] || {}) }
            const row = rowProps[rowId] || rowParamExample
            if (row.isSection) {
              return <TableRow key={v}>
                <CellFirst row={v} value={rowId} formSelected={formSelected} />
                <SimpleCell row={v} rowId={rowId} col={9}
                  value={row.section} rowGlobal={obj.row} color={row.color} colSpan='9' docId={docId}
                  formHistory={formHistory} formTable={formTable} />
              </TableRow>
            }
            return <TableRow key={v}>
              <CellFirst row={v} value={rowId} formSelected={formSelected} />
              {arrCol.slice(0, -1).map((e, i) => {
                return <SimpleCell key={i} row={v} rowId={rowId} col={i}
                  value={obj[e]} rowGlobal={obj.row} color={row.color} docId={docId}
                  formHistory={formHistory} formTable={formTable} />
              })}
            </TableRow>
          })}
          {(Array.from({ length: N }, (v, k) => k + 1)).map(v => {
            const rowID = v + allIds.length
            const obj = rowExample
            const row = (rowProps) ? rowProps[rowID] || rowParamExample : rowParamExample
            if (row.isSection) {
              return <TableRow key={v}>
                <CellFirst row={rowID} value={rowID} formSelected={formSelected} />
                <SimpleCell row={rowID} rowId={rowID} col={9}
                  value={row.section} color={row.color} colSpan='9'
                  virtual={true} docId={docId} formHistory={formHistory} formTable={formTable} />
              </TableRow>
            }
            return <TableRow key={v}>
              <CellFirst row={rowID} value={rowID} formSelected={formSelected} />
              {arrCol.slice(0, -1).map((e, i) => {
                return <SimpleCell key={i} virtual={true} row={rowID}
                  rowId={rowID} col={i} value={obj[e]} color={row.color}
                  docId={docId} formHistory={formHistory} formTable={formTable} />
              })}
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
}

