import React, { useEffect, useState, useCallback } from 'react'
import { fetchDispath } from '../../functions/fetch'
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getProfile } from '../../api/users'
import { rules } from '../../rbac-rules'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom';
import GridProps from '../../components/GridProps'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 240,
  },
  avatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: '#FFA500',
    width: 160,
    height: 160,
  },
}));

function Profile(props) {
  const [data, setData] = useState({ name: '', surname: '', role: '', email: '', patronymic:'' })
  const dispatch = useDispatch()
  const loadDisp = useCallback(() => dispatch(fetchDispath({ progress: true, request: getProfile, })), [dispatch])
  useEffect(() => {
    loadDisp().then(res => {
      setData({
        name: res.get.name,
        surname: res.get.surname,
        patronymic: res.get.patronymic,
        role: rules[res.get.role],
        email: res.get.email,
      })
    })
  }, [loadDisp])
  const classes = useStyles();
  const toLinkEdit = React.forwardRef((prop, ref) => (<Link ref={ref} to={`${props.match.url}/edit`} {...prop} />))
  const toLinkEditPass = React.forwardRef((prop, ref) => (<Link ref={ref} to={`${props.match.url}/editpass`} {...prop} />))
  const { name, surname, role, email,patronymic } = data
  return <div className='App-paper'>
    <Typography variant='h6'>{`${surname} ${name} ${patronymic}`}</Typography>
    <br />
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item>
        <Avatar className={classes.avatar}></Avatar>
      </Grid>
      <Grid item>
        <Typography>Информация</Typography>
        <Grid
          item
          container
          xs={9}
        >
          <GridProps name='Права доступа:' value={role} />
          <GridProps name='Email:' value={email} />
        </Grid>
      </Grid>
    </Grid>
    <Button color="primary" component={toLinkEdit}>Изменить</Button>
    <Button color="primary" component={toLinkEditPass}>Сменить пароль</Button>
  </div>
}

export default Profile;