import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import Button from '@material-ui/core/Button';
import { addCarRental} from '../../../api/doc'
import SimpleAdd from '../../../components/SimpleAdd'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import CompaniesAdd from '../../list/companies/CompaniesAdd'

import { getCompanies } from '../../../api/list'

const formName = 'CarRentalAdd'
export default function CarRentalAdd({ history, match }) {
  const dispatch = useDispatch()
  const arrProps = [

    {
      field: 'companyId', label: 'Организация', type: 'autoLoadCreateModal',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        path: getCompanies.path,
        meta: {
          field: 'company',
          value: 'id',
          labelFunc: (obj) => obj.shortName + ' '+ obj.inn
        },
        modal:({ handleClose })=><CompaniesAdd match={match} modal={true} modalClose={handleClose}/>
      }
    },
    { field: 'hours', type: 'text', label: 'Время аренды', textProps: { type: 'number', min: '0' }, validator: [value => value > 0], errorText: 'не может быть меньше нуля' },
    { xs: 6, field: 'dateStart', type: 'text', label: 'Начало работы', textProps: { type: 'datetime-local' }, labelProps: { shrink: true }, validator: [value => value !== ''], errorText: 'Поле обязательно' },
    { xs: 6, field: 'dateEnd', type: 'text', label: 'Окончание работы', textProps: { type: 'datetime-local' }, labelProps: { shrink: true }, validator: [value => value !== ''], errorText: 'Поле обязательно' },
    { field: 'price', type: 'text', label: 'Стоимость аренды', textProps: { type: 'number' }, validator: [value => value >= 0], errorText: 'Поле обязательно' },
    { field: 'model', type: 'text', label: 'Марка,модель авто', },
    { field: 'licensePlate', type: 'text', label: 'Гос. знак' },
    { field: 'transportationDetails', type: 'text', label: 'Сведения о перевозке' },
    { field: 'driverSurname', type: 'text', label: 'Вамилия водителя' },
    { field: 'driverName', type: 'text', label: 'Имя водителя' },
    { field: 'driverPatronymic', type: 'text', label: 'Отчество водителя' },
    { field: 'drivingLicense', type: 'text', label: 'удостоверение водителя' },
    { field: 'classCar', type: 'text', label: 'класс' },
  ]
  useEffect(() => {
    dispatch(actions.formInitialize({
      model: '',
      licensePlate: '',
      transportationDetails: '',
      driverSurname: '',
      driverName: '',
      driverPatronymic: '',
      dateStart: '',
      dateEnd: '',
      hours: '',
      price: '',
      companyId: null,
      drivingLicense: '',
      classCar: '',
      errorValidate: {
      }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSubmit = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        model: values.model,
        licensePlate: values.licensePlate,
        transportationDetails: values.transportationDetails,
        driverSurname: values.driverSurname,
        driverName: values.driverName,
        driverPatronymic: values.driverPatronymic,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
        hours: values.hours,
        price: values.price,
        companyId: values.companyId,
        drivingLicense: values.drivingLicense,
        classCar: values.classCar,
        idProject: match.params.idProject
      },
      url: addCarRental.path,
      method: addCarRental.type,
      history: history
    }))
  }
  return (<div className='App-paper' style={{ padding: '16px' }}>
    <form autoComplete="off" onSubmit={handleSubmit} >
      <SimpleAdd
        title='Создать документ аренды спец. техники'
        arr={arrProps}
        formName={formName}
      />
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color='primary' >Сохранить</Button>
    </form>
  </div>)
}
