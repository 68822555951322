import React from 'react';
import { Card, CardContent, CardActions, CardActionArea, Tooltip, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as actions2 from '../../../../../reducers/form';
import DeleteIcon from '@material-ui/icons/Delete';
const stylesCard = theme => ({
  card: {
    margin: theme.spacing(1)
  },
  deleteBtn: {
    // marginLeft: 'auto',
    float: 'right'
  },
  name: {
    marginLeft: '1em'
  },
});

class CardTable extends React.Component {
  deleteRow = () => {
    this.props.actions.formArrayDelete(this.props.id, { field: 'allIds', name: this.props.formName });
    this.props.actions.formObjDelete({ id: this.props.id, field: 'byId', name: this.props.formName });
  }
  openDriver = () => {
    this.props.actions.formChange(this.props.id, { field: 'draverId', name: this.props.formName })
    this.props.actions.formChange(true, { field: 'draverOpen', name: this.props.formName })
  }
  render() {
    const { classes, obj } = this.props
    const value = this.props.obj.value || { label: '', storageUnit: '' }
    return <Card className={classes.card}>
      <CardActionArea onClick={this.openDriver}>
        <CardContent>
          <Typography>Материал:</Typography>
          <Typography className={classes.name} >{value.label}</Typography>
          {obj.idEstimate && <Typography>Наименование по акту:</Typography>}
          {obj.idEstimate && <Typography className={classes.name} >{obj.estimateName}</Typography>}
          <Typography>Количество: {obj.count}</Typography>
          <Typography>Цена за ед.: {obj.price}</Typography>
          <Typography>Ед. изм.: {value.storageUnit}</Typography>
        </CardContent>
      </CardActionArea>
      <IconButton
        aria-label="Delete"
        className={classes.deleteBtn}
        onClick={this.deleteRow}
      ><Tooltip title="Delete"><DeleteIcon /></Tooltip></IconButton>
      <CardActions>
      </CardActions>
    </Card>
  }
}

const mapStateToProps = store => {
  return {
    autocomplite: store.autocomplite
  }
}
const mapDispatchToProps = dispatch => {
  return {
    actions: {
      formObjDelete: meta => dispatch(actions2.formObjDelete(meta)),
      formArrayDelete: (obj, meta) => dispatch(actions2.formArrayDelete(obj, meta)),
      formChange: (obj, meta) => dispatch(actions2.formChange(obj, meta)),
      // formObjChangeObj: (obj,meta)=> dispatch(actions2.formObjChangeObj(obj,meta)),
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesCard)(CardTable));