import { actionCreatorObj } from '../functions/redux'
const MAIN = 'gant/'
const ADD_TREE = MAIN + 'ADD_TREE'
const LOAD_TREE = MAIN + 'LOAD_TREE'
const CHANGE_POS_TREE = MAIN + 'CHANGE_POS_THREE'
const CHANGE_COLOR_TREE = MAIN + 'CHANGE_COLOR_THREE'
// const CHANGE_PARENT_THREE = MAIN + 'CHANGE_PARENT_THREE'
const CHANGE_POS_PARENT_COLOR_THREE = MAIN +'CHANGE_PPC_THREE' 
const CLEAR_THREE = MAIN + 'CLEAR'
const LOAD_ARROW = MAIN + 'LOAD_ARROW'
const ADD_ARROW = MAIN + 'ADD_ARROW'
const DEL_ARROW_ID_ARROW = MAIN + 'DEL_ARROW_ID_ARROW'
const DEL_ARROW_ID_GANT = MAIN + 'DEL_ARROW_ID_GANT'
const LOAD_CRIT_PATH_GANT = MAIN + 'LOAD_CRIT_PATH_GANT'

export const addGantTree = actionCreatorObj(ADD_TREE)
export const loadGantTree = actionCreatorObj(LOAD_TREE)
export const changeGantTreePos = actionCreatorObj(CHANGE_POS_TREE)
export const changeGantTreeColor = actionCreatorObj(CHANGE_COLOR_TREE)
// export const changeGantTreeParent = actionCreatorObj(CHANGE_PARENT_THREE)
export const changeGantTreeAll = actionCreatorObj(CHANGE_POS_PARENT_COLOR_THREE)
export const clearGantThree = actionCreatorObj(CLEAR_THREE)
export const loadGantArrow = actionCreatorObj(LOAD_ARROW)
export const addGantArrow = actionCreatorObj(ADD_ARROW)
export const delGantArrowIdArrow = actionCreatorObj(DEL_ARROW_ID_ARROW)
export const delGantArrowIdGant = actionCreatorObj(DEL_ARROW_ID_GANT)
export const loadCritPathGant = actionCreatorObj(LOAD_CRIT_PATH_GANT)
export const initialState = {
  tree: [],
  flat: [],
  arrow: [],
  adjList: {},
  crit:{
    path:[],
    arrow:[]
  }
};

export default function gant(state = initialState, action) {
  switch (action.type) {
    case LOAD_TREE: return { ...state, tree: action.payload, flat: sortNLR2(action.payload) };
    case ADD_TREE: return addTree(state, action.payload);
    case CHANGE_POS_TREE: return changeTreePos(state, action.payload)
    case CHANGE_COLOR_TREE: return changeTreeColor(state, action.payload)
    // case CHANGE_PARENT_THREE: return changeTreeParent(state, action.payload)
    case CHANGE_POS_PARENT_COLOR_THREE: return changeTreeAll(state, action.payload)
    case LOAD_ARROW: return initialArrow(state, action.payload)
    case ADD_ARROW: return addArrow(state, action.payload)
    case DEL_ARROW_ID_ARROW: return delArrowIdArrow(state, action.payload)
    case DEL_ARROW_ID_GANT: return delArrowIdGant(state, action.payload)
    case LOAD_CRIT_PATH_GANT: return loadCritPath(state,action.payload) 
    case CLEAR_THREE: return initialState
    default: return state;
  }
}
/*
"id": 510,
"parent": 0,
"pos": 0,
"level": 1,
"children": []
*/
function addTree(state, payload) {
  const add = (obj, id, pos, color) => {
    const newChild = {
      id: id,
      parent: obj.id,
      pos: pos,
      color: color,
      colorParent: obj.color,
      level: obj.level + 1,
      children: []
    }
    return {
      ...obj,
      children: [...obj.children,newChild].sort((a, b) => {
        if (a.pos < b.pos) return -1;
        if (a.pos > b.pos) return 1;
        return 0
      })
    }
  }
  const newArr = traverseDF(state.tree, payload.parent, add, payload.id, payload.pos, payload.color)
  return { ...state, tree: newArr, flat: sortNLR2(newArr) }
}

function traverseDF(oldArr = [], parent, action, ...props) {
  const newTree = { id: 0, parent: null, pos: 0, level: 0, color: '#fff', colorParent:'#fff', children: [...oldArr] };
  const recurse = (objTree) => {
    for (let i = 0; i < objTree.length; i++) {
      const el = objTree[i];
      if (el.id === parent) {
        const newEl = action(el, ...props)
        objTree[i] = newEl;
      }
      else if (el.children.length > 0) {
        recurse(el.children)
      }
    }
  }
  if(parent===0){
    return action(newTree, ...props).children
  }
  recurse([newTree]);
  return newTree.children;
}

function sortNLR2(tree) {
  const sorted = [];
  const func = (partTree, pos) => {
    partTree.forEach(path => {
      const { children, ...other } = path
      sorted.push({ ...other, pos: pos + (path.pos || '') });
      if (children.length > 0) {
        func(children, pos + (other.pos || '') + '.');
      }
    });
  };
  func(tree, '');
  return sorted;
}

function changeTreePos(state, payload) {
  const { id, pos, parent } = payload
  const changePos = (obj, id, posNew) => {
    const child = [...obj.children]
    const i = child.findIndex(e => e.id === id)
    child[i].pos = posNew
    return {
      ...obj,
      children: child.sort((a, b) => {
        if (a.pos < b.pos) return -1;
        if (a.pos > b.pos) return 1;
        return 0
      })
    }
  }
  const newArr = traverseDF(state.tree, parent, changePos, id, pos)
  return { ...state, tree: newArr, flat: sortNLR2(newArr) }
}
function changeTreeColor(state, payload) {
  const { id, color } = payload
  const changePos = (obj, colorNew) => {
    return {
      ...obj,
      color: colorNew,
      children: obj.children.map(e => ({ ...e, colorParent: color }))
    }
  }
  const newArr = traverseDF(state.tree, id, changePos, color)
  return { ...state, tree: newArr, flat: sortNLR2(newArr) }
}
// function changeTreeParent(state, payload){
//   const { id, parentNew } = payload
//   const delChild = (obj, colorNew) => {
//     return {
//       ...obj,
//       color: colorNew,
//       children: obj.children.filter(e=>e!==id)
//     }
//   }
//   const old = state.flat.find(e=>e.id===id);
//   const newArr1 = traverseDF(state.tree, id, delChild)
//   const add = (obj, id, pos, color,children) => {
//     const newChild = {
//       id: id,
//       parent: obj.id,
//       pos: pos,
//       color: color,
//       colorParent: obj.color,
//       level: obj.level + 1,
//       children: children
//     }
//     return {
//       ...obj,
//       children: [...obj.children,newChild].sort((a, b) => {
//         if (a.pos < b.pos) return -1;
//         if (a.pos > b.pos) return 1;
//         return 0
//       })
//     }
//   }
//   const newArr2 = traverseDF(newArr1, parentNew, add, old.id, old.pos, old.color, old.children)
//   return { ...state, tree: newArr2, flat: sortNLR2(newArr2) }
// }
function changeTreeAll(state, payload){
  const old = {...state.flat.find(e=>e.id===payload.id), children: []};
  const delChild = (obj) => {
    const childChange = obj.children.find(e=>e.id===payload.id)
    old.children = [...childChange.children]
    return {
      ...obj,
      children: obj.children.filter(e=>e.id!==payload.id)
    }
  }

  const arrDeleted = traverseDF(state.tree, old.parent, delChild)
  const add = (obj, id, pos, color,children) => {
    const newChild = {
      id: id,
      parent: obj.id,
      pos: pos,
      color: color,
      colorParent: obj.color,
      level: obj.level + 1,
      children: children.map(e=>({...e,colorParent: color}))
    }
    return {
      ...obj,
      children: [...obj.children,newChild].sort((a, b) => {
        if (a.pos < b.pos) return -1;
        if (a.pos > b.pos) return 1;
        return 0
      })
    }
  }
  const arrAdded = traverseDF(arrDeleted, payload.parent, add, payload.id, payload.pos, payload.color, old.children)
  return { ...state, tree: arrAdded, flat: sortNLR2(arrAdded) }
}
/*

{
  "fromId": 811,
  "id": 89,
  "to": 652,
  "type": "ff"
}
*/
function initialArrow(state, arrows) {
  const adjList = {}
  arrows.forEach(obj => {
    if (adjList[obj.fromId]) {
      adjList[obj.fromId].push({ to: obj.to, type: obj.type })
    } else {
      adjList[obj.fromId] = [{ to: obj.to, type: obj.type }]
    }
  })
  return {
    ...state,
    arrow: arrows,
    adjList: adjList,
  }
}
function addArrow(state, obj) {
  const oldArrow = [{ to: obj.to, type: obj.type }]
  if (state.adjList[obj.fromId]) {
    oldArrow.push(...state.adjList[obj.fromId])
  }
  return {
    ...state,
    arrow: [...state.arrow, obj],
    adjList: {
      ...state.adjList,
      [obj.fromId]: oldArrow
    }
  }
}
function delArrowIdArrow(state, idArrow) {
  const obj = state.arrow.find(e => e.id === idArrow)
  return {
    ...state,
    arrow: state.arrow.filter(e => e.id !== idArrow),
    adjList: {
      ...state.adjList,
      [obj.fromId]: state.adjList[obj.fromId].filter(e => !(e.to === obj.to && e.type === obj.type))
    }
  }
}
function delArrowIdGant(state, idGant) {
  let newList = {}
  Object.keys(state.adjList).forEach(key => {
    if (key === idGant) return;
    newList[key] = state.adjList[key].filter(e => e.to !== idGant)
  })
  return {
    ...state,
    arrow: state.arrow.filter(e => e.fromId !== idGant).filter(e => e.to !== idGant),
    adjList: newList
  }
}

function loadCritPath(state,crit){
  return {
    ...state,
    crit: {
      path:crit.path,
      arrow:crit.arrow
    }
  }
}