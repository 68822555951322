import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import * as actions from '../../../reducers/form';
import SimpleAdd from '../../../components/SimpleAdd'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import { addPlanAPI } from '../../../api/tariff.api'
import { moduleAccessEnum } from '../../../enum/module-access.enum'

const formName = 'tariff-add'
const arrProps = [
  {
    field: 'name', label: 'Наименование', type: 'text',
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
  {
    field: 'price', label: 'Стоимость в период', type: 'text', textProps: { type: 'number' },
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
  {
    field: 'maxUser', label: 'Максимум пользователей', type: 'text', textProps: { type: 'number' },
  },
  {
    field: 'moduleAccess', label: 'Доступ к модулям', type: 'autoArr',
    textProps: { arr: moduleAccessEnum, selectProps: { isMulti: true } },
  },
  {
    field: 'period', label: 'Период оплаты (мес)', type: 'text', textProps: { type: 'number' },
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
]

export default function TariffAdd({ history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      name: '',
      price: 0,
      maxUser: 0,
      moduleAccess: [],
      period: 0,
      errorValidate: { name: false, price: false, maxUser: false, period: false }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const values = useSelector(state => state.form[formName]?.values)
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        name: values.name,
        price: values.price,
        maxUser: values.maxUser,
        moduleAccess: values.valuemoduleAccess.map(e => e.value),
        period: values.period,
      },
      url: addPlanAPI.path,
      method: addPlanAPI.type,
      history: history
    }, formName))
  };
  return <div className='App-paper'>
    <form autoComplete="off" onSubmit={handleSave}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd
          formName={formName}
          arr={arrProps}
          title='Добавить новый тариф'
          gridProps={{ xs: 12 }}
        />
      </div>
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>
}