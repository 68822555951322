export default function getColorAttend(state) {
  switch (state) {
    case 'attent':
      return '#00ff00'
    case 'late':
      return '#ffaa00'
    case 'absent':
      return '#ff0000'
    default:
      return '#ff0000'
  }
}