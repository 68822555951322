import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Icon } from '@material-ui/core'
import DownIcon from '@material-ui/icons/ArrowDownward';
import UpIcon from '@material-ui/icons/ArrowUpward';
import TableForm from './TableAn'
import AnalitycProps from '../../../components/GridProps'

export default function AnalitycMaterialStorage({ formStorage, formName }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const formTable = useSelector(state => state.formTable[formStorage] || state.formTable.default)
  const { values } = form
  const rowCount = (value) => {
    const icon = (value) ? <UpIcon /> : <DownIcon />
    return <Icon style={{ color: (value) ? '#3bcf00' : '#ff0000' }}>{icon}</Icon>
  }
  return (<div>
    <Grid
      container
      item
      xs={6}
    >
      <AnalitycProps name='Ед изм.' value={values.storageUnit} />
      <AnalitycProps name='Количество по смете:' value={values.count} />
      <AnalitycProps name='Требуется докупить:' value={values.balance} />
      <AnalitycProps name='На складе:' value={values.storage} />
      <AnalitycProps name='Потрачено:' value={values.costs} />
    </Grid>
    <TableForm
      tableHead={[
        { id: 'date', name: 'Дата', date: true, formatDate: 'DD.MM.YYYY' },
        { id: 'count', name: 'Количество', align: 'left' },
        { id: 'type', name: 'Приход/Расход', align: 'center', component: rowCount },
      ]}
      tableData={formTable}
    />
  </div>)
}


