import React from 'react';
import { getTeamList, deleteTeam } from '../../api/team'
import PageList from '../../components/PageListAPI'

export default function TeamList({ match, location, history }) {
  return <div className='App-paper'>
    <PageList
      name='teamList'
      title='Бригады'
      columns={[
        { id: 'name', name: 'Наимнование' },
        { id: 'master', name: 'Мастер' },
        { id: 'phone', name: 'Телефон для связи' },
      ]}
      createItem={true}
      deleteItem={true}
      loadAPI={{
        request: getTeamList,
        param: {
          id: match.params.idProject
        },
      }}
      deleteAPI={deleteTeam(match.params.idProject)}
      match={match}
      history={history}
      location={location}
    />
  </div>
}