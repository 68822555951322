import React, { useEffect, useState } from 'react'
import { Button, Typography, Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { fetchDispath } from '../../functions/fetch'
import { canArchiveProject, setArchiveProject, isArchiveProject } from '../../api/archive'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { green, red } from '@material-ui/core/colors';
import { formSubmitAPI } from '../../reducers/form';

export default function ProjectArchive({ match, history, location }) {
  const [state, setState] = useState({ material: false, equipment: false, equipmentWorker: false, worker: false })
  const [isArchived, setArchived] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
      },
      progress: true,
      request: canArchiveProject,
    })).then(res => {
      if (res.success) {
        setState(res.get)
      }
    }).catch(err => console.log(err))
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
      },
      progress: true,
      request: isArchiveProject,
    })).then(res => {
      if (res.success) {
        setArchived(res.get.archive)
      }
    }).catch(err => console.log(err))
  }, [dispatch, match.params.idProject, setState])
  const handleSubmit = (archive) => {
    dispatch(formSubmitAPI({
      props: { archive },
      url: setArchiveProject.path({ id: match.params.idProject }),
      method: setArchiveProject.type,
      history: history
    }, 'default'))
  }
  const toLink = to => `/projects/${match.params.idProject}/${to}`
  const canArchived = state.material && state.equipment && state.equipmentWorker && state.worker
  return <div>
    {isArchived && <Typography style={{ color: red[500] }}>Проект в архиве</Typography>}
    <Row name='Материалы на складе' can={state.material} to={toLink('storage/materials/removal/add')} history={history} />
    <Row name='Оборудование у рабочих' can={state.equipmentWorker} to={toLink('usetool/acceptance')} history={history} />
    <Row name='Оборудование на объекте' can={state.equipment} to={toLink('storage/inventory/removal/add')} history={history} />
    <Row name='Рабочие на объекте' can={state.worker} to={toLink('worker/ending')} history={history} />
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
    {isArchived
      ? <Button color="primary" onClick={() => handleSubmit(false)} disabled={!canArchived}>Убрать из архива</Button>
      : <Button color="primary" onClick={() => handleSubmit(true)} disabled={!canArchived}>Архивировать</Button>
    }
  </div>
}

function Row({ name, can, history, to }) {
  return <Grid
    container
    direction="row"
    alignItems="center"
  >
    <Grid item>{can ? <CheckIcon style={{ color: green[500] }} /> : <ClearIcon style={{ color: red[500] }} />}</Grid>
    <Grid item><Typography style={{ color: can ? green[500] : red[500] }} >{name}</Typography></Grid>
    <Grid item><Button disabled={can} onClick={() => history.push(to)} color="primary" >Списать</Button></Grid>
  </Grid>
}