import React from 'react'
import Grid from '@material-ui/core/Grid'
import check from '../../functions/can'
import CardMenu from './source/CardMenu'

function CartProps({ arr, idProject, name }) {
  const str = (typeof idProject !== 'undefined') ? `/projects/${idProject}` : ''
  const arrNew = arr.map(obj =>
    check(obj.check) && { to: str + obj.to, primary: obj.primary, disabled: obj.disabled, add: obj.add }
  ).filter(key => typeof key !== 'boolean')
  return arrNew.length > 0 && <Grid item><CardMenu arr={arrNew} name={name} /></Grid>
}

function DocumentMenu(props) {
  const idProject = props.match.params.idProject
  const certificates = [
    { check: 'material:read', to: '/certificates', primary: 'Список ' },
  ]
  const documents = [
    { check: 'shipment:read', to: '/shipment/materials', primary: 'Покупка материалов', add: true },
    { check: 'shipment:read', to: '/receipt/materials', primary: 'Получение давальческих материалов', add: true },
    { check: 'shipment:read', to: '/shipment/order', primary: 'Заказать давальческие материалы' },
  ]
  const rent = [
    { check: 'shipment:read', to: '/shipment/equipment', primary: 'Покупка оборудования', add: true },
    { check: 'shipment:read', to: '/shipment/rent', primary: 'Аренда оборудования', add: true },
    { check: 'shipment:read', to: '/shipment/carrental', primary: 'Аренда техники', add: true },
    { check: 'shipment:read', to: '/shipment/rent/list', primary: 'Оборудование на балансе' },
  ]
  const acceptance = [
    { check: 'shipment:read', to: '/acceptance', primary: 'Акты приемки' },
    { check: 'shipment:read', to: '/delivery/doc', primary: 'Оприходование материалов' },
    { check: 'shipment:read', to: '/input-control', primary: 'Журнал входного контроля' },
    { check: 'shipment:read', to: '/delivery/material', primary: 'Материалы к поставке' },
  ]
  const orders = [
    { check: 'order:read:all', to: '/orders/lenta', primary: 'Лента заказов' },
    { check: 'order:read:all', to: '/orders/process', primary: 'Обработка заказов' },
  ]
  const ocr = [
    { check: 'shipment:read', to: '/ocr/doc', primary: 'Документы' },
    { check: 'shipment:read', to: '/ocr/recognition', primary: 'Распознать' },
    { check: 'shipment:read', to: '/ocr/tack', primary: 'Задачи распознавания' },
  ]
  const order = [
    { check: 'shipment:read', to: '/order/provider', primary: 'Документы', add: true },
    { check: 'shipment:read', to: '/order/provider/summary', primary: 'Сводная таблица' },
  ]
  return <Grid container>
    <CartProps arr={orders} idProject={idProject} name='Обработка заказов' />
    <CartProps arr={order} idProject={idProject} name='Заказ поставщику' />
    <CartProps arr={documents} idProject={idProject} name='Закупка' />
    <CartProps arr={rent} idProject={idProject} name='Оборудование и техника' />
    <CartProps arr={acceptance} idProject={idProject} name='Поступление товаров' />
    <CartProps arr={ocr} idProject={idProject} name='Распознавание документов' />
    <CartProps arr={certificates} idProject={idProject} name='Сертификаты на материалы' />
  </Grid>
}

export default DocumentMenu;