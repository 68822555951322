import React from 'react';
import can from '../../functions/can'
import * as api from '../../api/facility'
import { deleteProject } from '../../api/projects'
import PageList from '../../components/PageListAPI'
import FacilityByIdHeader from './source/FacilityByIdHeader'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom';

export default function FacilityById (props) {
  const toLinkEdit = React.forwardRef((propsRef, ref) => (<Link to={props.match.url + '/edit'} {...propsRef} />))
  const toLinkKS3 = React.forwardRef((propsRef, ref) => (<Link to={props.match.url + '/form/ks3'} {...propsRef} />))
  return (
    <div className='App-paper'>
      <PageList
        name='facilityById'
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'by', name: 'Заказано материалов' },
          { id: 'costs', name: 'Выработано материалов' },
          { id: 'byWork', name: 'Выполнено работ' },
          { id: 'byAll', name: 'Всего затрат по смете' },
          { id: 'byFact', name: 'Текущие затраты по плану' },
          { id: 'byPlan', name: 'Текущие затраты по факту' },
        ]}
        idName='id'
        headerInit={{
          obj: {
            description: '',
            name: ''
          }
        }}
        loadAPI={{
          param: {
            id: props.match.params.id
          },
          request: api.getFacilityProject,
        }}
        csv={false}
        click={false}
        pagination={false}
        headerObj={<FacilityByIdHeader idFacility={props.match.params.id} />}
        headerLink={
          <React.Fragment>
            <Button  component={toLinkEdit} color="primary" >Изменить </Button>
            <Button  component={toLinkKS3} color="primary" >КС3 </Button>
          </React.Fragment>
        }
        createItem={can('facility:create')}
        deleteItem={can('facility:delete')}
        deleteAPI={deleteProject}
        tableProps={{
          pagination: false
        }}
        goBack={false}
        {...props}
      />
      <Button color='primary' component="span" onClick={() => props.history.goBack()}>Назад</Button>
    </div>
  )
}
