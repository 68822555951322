import React, { useEffect, useState } from 'react'
import {
  Dialog, IconButton, Grid, TextField,
  DialogTitle, DialogContent, List, ListItem, Typography,
} from '@material-ui/core';
import Autocomplite from '../../components/Autocomplite'
import OkDeleteIcon from '@material-ui/icons/Done'
import moment from 'moment'
import {
  getGantShCalendar,
  updGantShCalendar
} from '../../api/form-gant.api'
import { fetchDispath } from '../../functions/fetch'
import { useDispatch } from 'react-redux'

export default function CalendarDialog({ open, onClose, dialogProps, resourceProject, idProject }) {
  const dispatch = useDispatch()
  const {
    date,
    work,
    resources,
    resourceId,
    id,
    hours,
  } = dialogProps
  const [workers, setWorkers] = useState([])
  const [arr, setArr] = useState([])
  const [values, setValues] = useState({ value: '', label: '' })
  const [newHour, setHewHour] = useState(0)
  const [isLoad, setIsLoad] = useState(false)
  const [seted, setSeted] = useState(0)
  useEffect(() => {
    if (!open) return;
    if (isLoad) return;
    const objRes = resourceProject.find(e => e.id === resourceId)
    if (!objRes) return;
    dispatch(fetchDispath({
      param: {
        id: id,
        resourceId: resourceId,
        idProject
      },
      querty: {
        date: date,
      },
      progress: true,
      request: getGantShCalendar,
    })).then(res => {
      setIsLoad(true)
      const workersNew = (res.get) ? res.get.workers || [] : []
      let countH = 0
      const arrFilter = workersNew.map(e => { countH += Number(e.hour); return String(e.id) })
      setSeted(countH)
      const newArr = objRes.workers
        .filter(x => !arrFilter.includes(String(x.id)))
        .map(e => ({ value: e.id, label: e.name + ' ' + e.surname + ' ' + e.patronymic }))

      setWorkers(workersNew)
      setArr(newArr)
    })

  }, [open, workers, resourceId, resourceProject, id, date, dispatch, idProject, isLoad])

  const updCalendar = (h, w) => {
    dispatch(fetchDispath({
      param: {
        id: id,
        resourceId: resourceId,
        idProject
      },
      body: {
        date: date,
        hour: h,
        worker: w,
      },
      progress: true,
      request: updGantShCalendar,
    })).then(e => {
      setIsLoad(false)
      setValues({ value: '', label: '' })
    })
  }

  const handleClose = () => {
    setWorkers([])
    setArr([])
    setValues(null)
    setHewHour()
    setIsLoad(false)
    onClose(seted)
  }
  const handleAdd = () => {
    updCalendar(newHour, values.value)
  }
  const handleChangeExist = (h, w) => {
    updCalendar(h, w)
  }
  return (
    <Dialog
      open={open}
      maxWidth='xl'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { minHeight: 500 } }}
    >
      <DialogTitle id="alert-dialog-title">{work}</DialogTitle>
      <DialogContent >
        <Typography>ресурс {resources}</Typography>
        <Typography>дата {moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</Typography>
        <Typography>всего часов {hours}</Typography>
        <Typography>часов распределено {seted}</Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item xs={1}>
            <IconButton size='small' color='primary' onClick={handleAdd}><OkDeleteIcon /></IconButton>
          </Grid>
          <Grid item xs={8}>
            <Autocomplite
              action={(e) => {
                setValues(e)
              }}
              palaseHolder={'работник'}
              value={values}
              arr={arr}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField label='часы' type={'number'} shrink onChange={(e) => setHewHour(e.target.value)} value={newHour || 0} />
          </Grid>
        </Grid>
        <List>
          {workers.map(re => {
            return <ListItem key={re.id}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={2}
              >
                <Grid item xs={9}>
                  <Typography>{re.name} {re.surname} {re.patronymic}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label='часы'
                    type={'number'}
                    shrink
                    onChange={(e) => handleChangeExist(e.target.value, re.id)}
                    value={re.hour || 0}
                  />
                </Grid>
              </Grid>
            </ListItem>
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}