import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, InputBase, Popover, Grid, Typography, IconButton, Tooltip } from '@material-ui/core'
import { fetchDispath } from '../../../functions/fetch'
import * as actions2 from '../../../reducers/form';
import * as api from '../../../api/workdoc'
import print from '../../../functions/print'
import { Link } from 'react-router-dom';

import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';

export default function HeaderLink({ docId, history, formTable, formSelected, rowExample, rowParamExample, match, formHistory }) {
  const [atomAction, setIsAtomAction] = useState(false)
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formSelected] || state.form.default)
  const fHistory = useSelector(state => state.form[formHistory] || state.form.default)
  const { selectRow = '', rowProps, lastRow, title } = form.values
  const selectedRowNumber = String(selectRow)[0] === 'a' ? Number(selectRow.substring(1)) : selectRow
  const handleChangeHeader = () => {
    const row = rowProps[selectedRowNumber]
    changeRowAsHeader(selectedRowNumber)
    // history
    let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
    buffNew.push({
      oldValue: {
        row: selectedRowNumber,
        isSection: row ? row.isSection : false
      },
      newValue: {
        row: selectedRowNumber,
        isSection: row ? !row.isSection : true
      },
      action: 4
    })
    dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
    dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))
  }
  const changeRowAsHeader = (rowId) => {
    const row = rowProps[rowId]
    if (row) {
      dispatch(actions2.formObjChangeObj({ isSection: !row.isSection, }, { field: 'rowProps', id: rowId, name: formSelected }))
    }
    else {
      dispatch(actions2.formObjChangeObj({ ...rowParamExample, isSection: true, row: rowId }, { field: 'rowProps', id: rowId, name: formSelected }))
    }
    return dispatch(fetchDispath({
      param: { id: docId, },
      body: {
        action: 4,
        rows: [{
          row: rowId,
          isSection: (row) ? !row.isSection : true
        }]
      },
      progress: false,
      request: api.saveChangeTable,
    }))
  }
  const formTableData = useSelector(state => state.form[formTable] || state.form.default)
  const { byId } = formTableData.values
  const handleDelete = () => {

    setIsAtomAction(true)
    deleteRow(selectedRowNumber)

    // history
    const deletedRowCell = Object.values(byId).find(v => v.row === selectedRowNumber)
    const deletedRowRow = Object.values(rowProps).find(v => v.row === selectedRowNumber)
    const oldValue = {
      type: 'add',
      // add deleted row
      rows: deletedRowRow,
      cells: deletedRowCell,
      addRow: selectedRowNumber - 1
    }
    const newValue = {
      type: 'delete',
      deletedRow: selectedRowNumber
      // delete row
    }
    let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
    buffNew.push({
      oldValue,
      newValue,
      action: 3
    })
    dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
    dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))

  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const deleteRow = (rowId) => {
    let newById = {}
    Object.values(byId).forEach(obj => {
      if (obj.row < rowId) newById['a' + obj.row] = obj;
      if (obj.row > rowId) newById['a' + (obj.row - 1)] = { ...obj, row: obj.row - 1 }
    })
    dispatch(actions2.formChange(newById, { field: 'byId', name: formTable }))
    let newRow = {}
    Object.values(rowProps).forEach(obj => {
      if (obj.row < rowId) newRow[obj.row] = obj;
      if (obj.row > rowId) newRow[(obj.row - 1)] = { ...obj, row: obj.row - 1 }
    })
    dispatch(actions2.formChange(newRow, { field: 'rowProps', name: formSelected }))
    dispatch(fetchDispath({
      param: { id: docId, },
      body: {
        action: 5,
        rows: [{
          row: rowId,
        }]
      },
      progress: false,
      request: api.saveChangeTable,
    })).then(() => setIsAtomAction(false))
  }
  const handleColor = (newColor) => {
    setAnchorEl(null)
    if (selectedRowNumber === '') return;
    const row = rowProps[selectedRowNumber]

    //hostory
    let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
    buffNew.push({
      oldValue: { color: (row) ? row.color : '', row: selectedRowNumber },
      newValue: { color: newColor, row: selectedRowNumber },
      action: 2
    })
    dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
    dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))

    if (row) {
      dispatch(actions2.formObjChangeObj({ color: newColor, }, { field: 'rowProps', id: selectedRowNumber, name: formSelected }))
    }
    else {
      dispatch(actions2.formObjChangeObj({
        ...rowParamExample, row: selectedRowNumber,
        color: newColor,
      }, { field: 'rowProps', id: selectedRowNumber, name: formSelected }))
    }
    dispatch(fetchDispath({
      param: { id: docId, },
      body: {
        action: 3,
        rows: [{
          row: selectedRowNumber,
          color: newColor
        }]
      },
      progress: false,
      request: api.saveChangeTable,
    }))
  }
  const handleAdd = () => {
    if (selectedRowNumber >= lastRow) return;
    addRow(selectedRowNumber)
    // history
    const newValue = {
      type: 'add',
      // add deleted row
      cells: { ...rowExample, row: selectedRowNumber + 1 },
      addRow: selectedRowNumber
    }
    const oldValue = {
      type: 'delete',
      deletedRow: selectedRowNumber + 1
      // delete row
    }
    let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
    buffNew.push({
      oldValue,
      newValue,
      action: 3
    })
    dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
    dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))



  }
  const addRow = (rowId) => {
    setIsAtomAction(true)
    let newById = {}
    Object.values(byId).forEach(obj => {
      if (obj.row <= rowId) newById['a' + obj.row] = obj;
      if (obj.row > rowId) newById['a' + (obj.row + 1)] = { ...obj, row: obj.row + 1 }
    })
    dispatch(actions2.formChange(newById, { field: 'byId', name: formTable }))
    let newRow = {}
    Object.values(rowProps).forEach(obj => {
      if (obj.row <= rowId) newRow[obj.row] = obj;
      if (obj.row > rowId) newRow[(obj.row + 1)] = { ...obj, row: obj.row + 1 }
    })
    dispatch(actions2.formChange(newRow, { field: 'rowProps', name: formSelected }))
    dispatch(actions2.formArrayAddMany(['a' + (lastRow + 1)], { field: 'allIds', end: true, name: formTable }))
    dispatch(actions2.formChangeAsObj({ lastRow: lastRow + 1 }, { name: formSelected }))
    return dispatch(fetchDispath({
      param: { id: docId, },
      body: {
        action: 6,
        rows: [{
          row: rowId,
        }]
      },
      progress: false,
      request: api.saveChangeTable,
    })).then(() => setIsAtomAction(false))
  }

  const handleUnite = () => {
    const row1 = rowProps[selectedRowNumber] || { isSection: false }
    const row2 = rowProps[selectedRowNumber + 1] || { isSection: false }
    if (!!row1.isSection || !!row2.isSection) return;
    const obj1 = { ...rowExample, ...byId['a' + selectedRowNumber] }
    const obj2 = { ...rowExample, ...byId['a' + (selectedRowNumber + 1)] }
    setIsAtomAction(true)
    let resUnite = {}
    Object.keys(rowExample).forEach(key => {
      const one = obj1[key] || ''
      const two = obj2[key] || ''
      if (one === '' && two === '') { resUnite[key] = '' }
      else { resUnite[key] = one + ' ' + two }
    })

    // history
    const newValue = {
      type: 'unit',
      selectedRow: selectedRowNumber,
      cells: { ...resUnite, row: selectedRowNumber }
    }
    const oldValue = {
      type: 'split',
      selectedRow: selectedRowNumber,
      oneCell: { ...obj1, row: selectedRowNumber },
      twoCell: { ...obj2, row: selectedRowNumber + 1 }
    }
    let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
    buffNew.push({
      oldValue,
      newValue,
      action: 5
    })
    dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
    dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))



    if (!byId['a' + (selectedRowNumber + 1)] && !byId['a' + selectedRowNumber]) {
      deleteRow(selectedRowNumber)
      return;
    }

    let newById = {}
    Object.values(byId).forEach(obj => {
      if (obj.row < selectedRowNumber + 1) newById['a' + obj.row] = obj;
      if (obj.row > selectedRowNumber + 1) newById['a' + (obj.row - 1)] = { ...obj, row: obj.row - 1 }
    })
    dispatch(actions2.formChange({ ...newById, ['a' + selectedRowNumber]: { ...resUnite, row: selectedRowNumber } }, { field: 'byId', name: formTable }))
    let newRow = {}
    Object.values(rowProps).forEach(obj => {
      if (obj.row < selectedRowNumber + 1) newRow[obj.row] = obj;
      if (obj.row > selectedRowNumber + 1) newRow[(obj.row - 1)] = { ...obj, row: obj.row - 1 }
    })
    dispatch(actions2.formChange(newRow, { field: 'rowProps', name: formSelected }))
    dispatch(fetchDispath({
      param: { id: docId, },
      body: {
        action: 1,
        cells: [{ ...resUnite, row: selectedRowNumber }]
      },
      progress: false,
      request: api.saveChangeTable,
    })).then(res => {
      dispatch(fetchDispath({
        param: { id: docId, },
        body: {
          action: 5,
          rows: [{
            row: selectedRowNumber + 1,
          }]
        },
        progress: false,
        request: api.saveChangeTable,
      })).then(() => setIsAtomAction(false))
    })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const [editTitle, setEditTitle] = useState(false)
  const [titleValue, setTitileValue] = useState('')
  const id = open ? 'simple-popover' : undefined;
  const handleStopAndSaveTitle = () => {
    setEditTitle(false)
    dispatch(actions2.formChange(titleValue, { field: 'title', name: formSelected }))
    dispatch(fetchDispath({
      param: { id: docId, },
      body: {
        title: titleValue
      },
      progress: false,
      request: api.updWorkDocProps,
    })).then(() => setIsAtomAction(false))
  }
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    console.log(docId, api.getWorkDocByIdPrint)
    print(
      fetch,
      {
        param: {
          id: docId
        },
        request: api.getWorkDocByIdPrint,
      }
    )
  }
  const toLinkProps = React.forwardRef((props1, ref) => (<Link ref={ref} to={match.url + '/props'} {...props1} />));
  const { buff = [], current = -1 } = fHistory.values
  // console.log(buff,current)
  const handleUndo = () => {
    const { action, oldValue } = buff[current]
    redoUndo(action, oldValue)
  }
  const handleRedo = () => {
    const { action, newValue } = buff[current + 1]
    redoUndo(action, newValue, 1)
  }
  const redoUndo = (action, value, undo = -1) => {
    dispatch(actions2.formChange(current + undo, { field: 'current', name: formHistory }))
    switch (action) {
      case 1: { // edit cell
        dispatch(actions2.formObjChangeObj(value, { field: 'byId', id: 'a' + value.row, name: formTable }))
        dispatch(fetchDispath({
          param: { id: docId, },
          body: {
            action: 1,
            cells: [value]
          },
          progress: false,
          request: api.saveChangeTable,
        }))
        return;
      }
      case 2: { // edit row, color
        dispatch(actions2.formObjChangeObj(value, { field: 'rowProps', id: value.row, name: formSelected }))
        dispatch(fetchDispath({
          param: {
            id: 1,
          },
          body: {
            action: 3,
            rows: [value]
          },
          progress: false,
          request: api.saveChangeTable,
        }))
        return
      }
      case 3: { // add delee row
        if (value.type === 'add') {
          addRow(value.addRow).then(() => {
            if (value.rows) {
              if (value.rows.isSection) {
                dispatch(fetchDispath({
                  param: { id: docId, },
                  body: {
                    action: 4,
                    rows: [{
                      row: value.rows.row,
                      isSection: true
                    }]
                  },
                  progress: false,
                  request: api.saveChangeTable,
                }))
                  .then(() => {
                    dispatch(actions2.formObjChangeObj(value.rows, { field: 'rowProps', id: value.rows.row, name: formSelected }))
                    dispatch(fetchDispath({
                      param: {
                        id: 1,
                      },
                      body: {
                        action: 3,
                        rows: [{
                          color: value.rows.color,
                          section: value.rows.section,
                          row: value.rows.row,
                        }]
                      },
                      progress: false,
                      request: api.saveChangeTable,
                    }))
                  })
              }
              if (!value.rows.isSection && value.rows.color) {

              }
            }
            if (value.cells) {
              dispatch(actions2.formObjChangeObj(value.cells, { field: 'byId', id: 'a' + value.cells.row, name: formTable }))
              dispatch(fetchDispath({
                param: { id: docId, },
                body: {
                  action: 1,
                  cells: [value.cells]
                },
                progress: false,
                request: api.saveChangeTable,
              }))
            }
          })
        }
        else {
          setIsAtomAction(true)
          deleteRow(value.deletedRow)
        }
        return;
      }
      case 4: { // change header or not 
        dispatch(actions2.formObjChangeObj({ isSection: value.isSection, }, { field: 'rowProps', id: value.row, name: formSelected }))
        dispatch(fetchDispath({
          param: { id: docId, },
          body: {
            action: 4,
            rows: [{
              row: value.row,
              isSection: value.isSection
            }]
          },
          progress: false,
          request: api.saveChangeTable,
        }))
        return;
      }
      case 5: { // unit
        console.log(value.type)
        if (value.type === 'unit') {
          console.log(value.cells)
          const rowId = value.selectedRow + 1
          let newById = {}
          Object.values(byId).forEach(obj => {
            if (obj.row < rowId) newById['a' + obj.row] = obj;
            if (obj.row > rowId) newById['a' + (obj.row - 1)] = { ...obj, row: obj.row - 1 }
          })
          newById['a' + value.cells.row] = value.cells
          dispatch(actions2.formChange(newById, { field: 'byId', name: formTable }))
          let newRow = {}
          Object.values(rowProps).forEach(obj => {
            if (obj.row < rowId) newRow[obj.row] = obj;
            if (obj.row > rowId) newRow[(obj.row - 1)] = { ...obj, row: obj.row - 1 }
          })
          dispatch(actions2.formChange(newRow, { field: 'rowProps', name: formSelected }))
          dispatch(fetchDispath({
            param: { id: docId, },
            body: {
              action: 5,
              rows: [{
                row: rowId,
              }]
            },
            progress: false,
            request: api.saveChangeTable,
          })).then(() => {
            setIsAtomAction(false)
            dispatch(fetchDispath({
              param: { id: docId, },
              body: {
                action: 1,
                cells: [value.cells]
              },
              progress: false,
              request: api.saveChangeTable,
            }))
          })
          return;
        }
        // split
        const rowId = value.selectedRow
        setIsAtomAction(true)
        let newById = {}
        Object.values(byId).forEach(obj => {
          if (obj.row <= rowId) newById['a' + obj.row] = obj;
          if (obj.row > rowId) newById['a' + (obj.row + 1)] = { ...obj, row: obj.row + 1 }
        })
        newById['a' + value.oneCell.row] = value.oneCell
        newById['a' + value.twoCell.row] = value.twoCell
        dispatch(actions2.formChange(newById, { field: 'byId', name: formTable }))
        let newRow = {}
        Object.values(rowProps).forEach(obj => {
          if (obj.row <= rowId) newRow[obj.row] = obj;
          if (obj.row > rowId) newRow[(obj.row + 1)] = { ...obj, row: obj.row + 1 }
        })
        dispatch(actions2.formChange(newRow, { field: 'rowProps', name: formSelected }))
        dispatch(actions2.formArrayAddMany(['a' + (lastRow + 1)], { field: 'allIds', end: true, name: formTable }))
        dispatch(actions2.formChangeAsObj({ lastRow: lastRow + 1 }, { name: formSelected }))
        dispatch(fetchDispath({
          param: { id: docId, },
          body: {
            action: 6,
            rows: [{
              row: rowId,
            }]
          },
          progress: false,
          request: api.saveChangeTable,
        })).then(() => {
          setIsAtomAction(false)
          dispatch(fetchDispath({
            param: { id: docId, },
            body: { action: 2, cells: [value.oneCell, value.twoCell] },
            progress: false, request: api.saveChangeTable,
          }))
        })
        return;
      }
      case 6: {
        dispatch(fetchDispath({
          param: { id: docId, },
          body: { action: 2, cells: value },
          progress: false, request: api.saveChangeTable,
        }))
        value.forEach(v => {
          dispatch(actions2.formObjChangeObj(v, { field: 'byId', id: 'a' + v.row, name: formTable }))
        })
        return
      }
      default: return;
    }
  }
  const handleAutoNumber = () => {
    const { byId, allIds = [] } = formTableData.values
    const cellPast = []
    const cellOld = []
    let count = 1;
    allIds.forEach((v, i) => {
      const rowId = i + 1
      const obj = { ...rowExample, ...(byId[v] || {}) }
      const row = rowProps[rowId] || rowParamExample
      if (row.isSection) {
        return;
      }
      if (obj.name && obj.su && obj.count && obj.name !== '' && obj.su !== '' && obj.count !== '') {
        cellPast.push({ row: rowId, pos: count })
        cellOld.push({ row: rowId, pos: obj.pos })
        dispatch(actions2.formObjChangeObj({ row: rowId, pos: count }, { field: 'byId', id: 'a' + rowId, name: formTable }))
        count += 1;
      }
    })
    // history start
    let buffNew = fHistory.values.buff.slice(0, fHistory.values.current + 1)
    buffNew.push({
      oldValue: cellOld,
      newValue: cellPast,
      action: 6
    })
    dispatch(actions2.formChange(buffNew, { field: 'buff', name: formHistory }))
    dispatch(actions2.formChangeAsObj({ current: fHistory.values.current + 1 }, { name: formHistory }))
    // history end
    if (cellPast.length > 0) {

      dispatch(fetchDispath({
        param: { id: docId, },
        body: { action: 2, cells: cellPast },
        progress: false, request: api.saveChangeTable,
      }))
    }
  }
  return <div>
    {!editTitle && <div style={{ cursor: 'pointer' }} onClick={() => { setEditTitle(true); setTitileValue(title) }}>
      <Typography variant='h5'>{title}</Typography>
    </div>}
    {editTitle && <InputBase
      style={{
        fontSize: '1.5rem',
        backgroundColor: 'rgba(14, 101, 235, 0.1)',
      }}
      autofocus
      value={titleValue}
      onChange={(e) => setTitileValue(e.target.value)}
      onBlur={handleStopAndSaveTitle}
    />}
    <Grid container alignItems='center'>
      <Grid item><Button color='primary' onClick={() => history.goBack()}>Назад</Button> </Grid>
      <Grid item>
        <IconButton variant="outlined" color='primary' onClick={handlePrint} >
          <Tooltip title='Печать'><PrintIcon /></Tooltip>
        </IconButton>
      </Grid>
      <Grid item >
        <IconButton variant="outlined" color='primary' component={toLinkProps} >
          <Tooltip title='Настройки'><SettingsIcon /></Tooltip>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton aria-label="undo" variant="outlined" color='primary' disabled={atomAction || current === -1} onClick={handleUndo}>
          <Tooltip title='Отменить'><UndoIcon /></Tooltip></IconButton>
      </Grid>
      <Grid item>
        <IconButton variant="outlined" color='primary' disabled={atomAction || current === buff.length - 1} onClick={handleRedo}>
          <Tooltip title='Вернуть'><RedoIcon /></Tooltip></IconButton>
      </Grid>
      <Grid item>
        <IconButton variant="outlined" color='primary' onClick={handleChangeHeader} disabled={!selectedRowNumber}>
          <Tooltip title='Установить заголовок'><CalendarViewDayIcon /></Tooltip>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton variant="outlined" color='primary' onClick={handleClick} aria-describedby={id} disabled={!selectedRowNumber}>
          <Tooltip title='Изменить цвет строки'><ColorLensIcon /></Tooltip>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton variant="outlined" color='primary' disabled={atomAction || !selectedRowNumber} onClick={handleDelete} >
          <Tooltip title='Удалить строку'><DeleteIcon /></Tooltip>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton variant="outlined" color='primary' disabled={atomAction || !selectedRowNumber} onClick={handleAdd} >
          <Tooltip title='Добавить строку снизу'><AddIcon /></Tooltip>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton variant="outlined" color='primary' disabled={atomAction || !selectedRowNumber} onClick={handleUnite} >
          <Tooltip title='Объединить со строкой снизу'><VerticalAlignCenterIcon /></Tooltip>
        </IconButton>
      </Grid>
      <Grid item>
        <Button onClick={handleAutoNumber} color='primary'>Цифры</Button>
      </Grid>
    </Grid>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div style={{ width: '200px', height: '40px', margin: '4px' }}>
        <Grid container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
        >
          {['#C96E6E', '#C9C963', '#6BBA7B', '#8F8FEF', ''].map(e => {
            return <Grid item key={e}>
              <div style={{ cursor: 'pointer', backgroundColor: e, width: '40px', height: '40px' }} onClick={() => handleColor(e)} />
            </Grid>
          })}
        </Grid>
      </div>
    </Popover>
  </div>
}