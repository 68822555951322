import React, { useState } from 'react';
import { Button, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import AutoCompliteWithLoad from '../../../components/AutocompliteWithLoad'
import { formSubmitAPI, formChange } from '../../../reducers/form';
import { deleteRowsFormTable } from '../../../reducers/formTable';
import { enqueueSnackbar } from '../../../reducers/notifier'
import { editFieldTable } from '../../../reducers/formTable';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
  }
}));

export default function Header({ formName, handleBack }) {
  const [type, setType] = useState('material')
  const form = useSelector(state => state.form[formName] || state.form.default)
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { selectId, idProject, column, estimate, consist, multiSelect, countSelect } = form.values
  const { byId } = formTable
  const dispatch = useDispatch()
  const errSnackbar = (msg) => dispatch(enqueueSnackbar({ message: msg, options: { variant: 'error' } }))
  const handleLoad = () => {
    if (!idProject) {
      errSnackbar('Проект не выбран')
      return;
    }
    if (selectId.length === 0) {
      errSnackbar('Материалы не выбраны')
      return;
    }
    let columnNew = {}
    const columnName = []
    for (const [key, value] of Object.entries(column)) {
      if (value.select !== '') {
        if(type==='work' && (value.select==='typeMark'||value.select==='vendorCode')) continue;
        columnNew[key] = value.select
        columnName.push(value.select)
      }
    }
    if (['name', 'count', 'storageUnit'].filter(x => !columnName.includes(x)).length !== 0) {
      errSnackbar('Не выбраны обязательные поля')
      return
    }
    let keyCount = ''
    for (const [key, value] of Object.entries(columnNew)) {
      if (value === 'count') keyCount = key;
    }
    let errCount = false
    const arr = selectId.map(key => {
      const obj = byId[key]
      const count = parseFloat(String(obj[keyCount]).replace(',', '.').replace(' ', ''))
      if (Number.isNaN(Number(count))) {
        errCount = true
      }
      return { ...byId[key], [keyCount]: count || 0 }
    })
    if (errCount) {
      errSnackbar('Колонка количество имеет нечисловые поля')
      return;
    }
    dispatch(formSubmitAPI({
      props: {
        idProject: idProject,
        arr: arr,
        column: columnNew,
        estimate: estimate,
        consist: consist
      },
      url: (type === 'material') ? '/materials/many' : `/projects/${idProject}/work/load`,
      method: 'POST',
      endFunc: () => {
        dispatch(deleteRowsFormTable(selectId, { name: formName }));
        dispatch(formChange([], { field: 'selectId', name: formName }));
      }
    }, formName))
  }
  const handleChangeCountSelect = (e) => {
    dispatch(formChange(e.target.value, { field: 'countSelect', name: formName }))
  }
  const handleChangeMultiSelect = () => {
    dispatch(formChange(!multiSelect, { field: 'multiSelect', name: formName }))
  }
  const handleDeleteRow = () => {
    dispatch(deleteRowsFormTable(selectId, { name: formName }));
  }
  const handleUniteRow = () => {
    let unitObj = {}
    const arr = selectId.map(e => Number(e.substring(2))).sort((a, b) => (a > b) ? 1 : -1)
    arr.forEach(e => {
      const obj = byId['id' + e]
      Object.keys(obj).forEach(key => {
        if (unitObj[key]) {
          unitObj[key] += obj[key] + ' '
        }
        else {
          unitObj[key] = obj[key] + ' '
        }
      })
    });
    Object.keys(unitObj).forEach(key => {
      dispatch(editFieldTable(unitObj[key], { field: key, id: 'id' + arr[0], name: formName }))
    })
  }
  const classes = useStyles()
  return <React.Fragment>
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      spacing={2}
    >
      <Grid item><Button onClick={handleBack} color='primary'>&lt;Выбор файла</Button></Grid>
      <Grid item>
        <Button color='primary' onClick={handleLoad}>Загрузить</Button>
      </Grid>
      <Grid item xs={3}>
        <AutoCompliteWithLoad
          classes={classes}
          field='idProject'
          name='Проект'
          path='/projects'
          meta={{
            field: 'project',
            value: 'id',
            labelFunc: (obj) => obj.facility + ' ' + obj.name
          }
          }
          formName={formName}
        />
      </Grid>
      <Grid item>
        <InputLabel id="select-type-load-label" >Тип загрузки</InputLabel>
        <Select
          labelId="select-type-load-label"
          id="select-type-load"
          value={type}
          onChange={(e)=>setType(e.target.value)}
        >
          <MenuItem value='material'>материалы</MenuItem>
          <MenuItem value='work'>работа</MenuItem>
        </Select>
      </Grid>
      <Grid item>
        <Button color={multiSelect ? 'secondary' : 'primary'} onClick={() => handleChangeMultiSelect()}>Режим выделения</Button>
      </Grid>

      {multiSelect && <Grid item>
        <InputLabel id="select-row-count-label">Количество строк</InputLabel>
        <Select
          labelId="select-row-count-label"
          id="select-row-count"
          value={countSelect}
          onChange={handleChangeCountSelect}
        >
          {[10,20,30].map(key=><MenuItem key={key} value={key}>{key}</MenuItem>)}
        </Select>
      </Grid>}
      <Grid item>
        <Button color='primary' onClick={handleUniteRow}>Объединить стоки</Button>
      </Grid>
      <Grid item>
        <Button color='primary' onClick={handleDeleteRow}>Удалить строки</Button>
      </Grid>
    </Grid>
  </React.Fragment>
}
