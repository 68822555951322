import React, { useEffect, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography, Tab, Tabs } from '@material-ui/core';
import { getCompaniesById, getCompaniesSRO, updCompaniesSRO } from '../../../api/list'
import { fetchDispath } from '../../../functions/fetch'
import PageList from '../../../components/PageListAPI'
import { Link } from 'react-router-dom';

export default function CompaniesEdit(props) {
  const [propsCompany, setProps] = useState({
    fullName: '',
    shortName: '',
    inn: '',
    ogrn: '',
    kpp: '',
    okpo: '',
    oktmo: '',
    adress: '',
    phone: '',
    email: '',
    selfRegulatory: '',
    selfRegulatoryDoc: '',
    statusSRO: '',
    isSRO: false,
    SRONumber: '',
  })
  const [tabc, changeTab] = useState(0)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.id,
      },
      progress: true,
      request: getCompaniesById,
    }))
      .then(res => {
        setProps({
          fullName: res.get.fullName,
          shortName: res.get.shortName,
          inn: res.get.inn,
          ogrn: res.get.ogrn,
          kpp: res.get.kpp,
          okpo: res.get.okpo,
          oktmo: res.get.oktmo,
          adress: res.get.adress,
          phone: res.get.phone,
          email: res.get.email,
          selfRegulatory: res.get.selfRegulatoryId,
          selfRegulatoryDoc: res.get.selfRegulatoryDoc,
          statusSRO: res.get.statusSRO,
          isSRO: res.get.isSRO,
          SRONumber: res.get.SRONumber,
        })
      })
      .catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, props.match.params.id])
  const toLinkChange = React.forwardRef((props3, ref) => (<Link ref={ref} to={props.match.url + '/edit'} {...props3} />))
  const [reload, setReload] = useState(false)
  const arrProps = [
    { field: 'fullName', type: 'text', label: 'Полное название компании', },
    { field: 'shortName', type: 'text', label: 'Сокращенное название компании', },
    { field: 'inn', type: 'text', label: 'ИНН', },
    { field: 'ogrn', type: 'text', label: 'ОГРН, ОГРНИП', },
    { field: 'kpp', type: 'text', label: 'КПП', },
    { field: 'okpo', type: 'text', label: 'ОКПО', },
    { field: 'oktmo', type: 'text', label: 'ОКТМО', },
    { field: 'adress', type: 'text', label: 'Адрес', },
    { field: 'phone', type: 'text', label: 'Телефон', },
    { field: 'email', type: 'text', label: 'Почта', },
  ]
  const arrPropsSRO = [
    { field: 'statusSRO', type: 'text', label: 'Статус СРО' },
    { field: 'SRONumber', type: 'text', label: '№ Свидетельства' },
  ]
  const handleReloadSRO = () => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.id,
      },
      progress: true,
      request: updCompaniesSRO,
    })).then(() => {
      setReload(true)
    })
  }
  return (
    <div className='App-paper' >
      <Typography variant='h6'>{propsCompany.shortName}</Typography>
      <Tabs value={tabc} onChange={(e, v) => changeTab(v)} indicatorColor='primary'>
        <Tab label='Данные' />
        <Tab label='Допуски СРО' />
      </Tabs>
      {(tabc === 0) && <div>
        <Button color="primary" component={toLinkChange}>Изменить </Button>
        {arrProps.map(e => {
          return <Typography>{e.label + ': ' + propsCompany[e.field]}</Typography>
        })}
        {propsCompany.isSRO&&arrPropsSRO.map(e => {
          return <Typography>{e.label + ': ' + propsCompany[e.field]}</Typography>
        })}
      </div>}

      {tabc === 1 && <div>
        <Button color="primary" onClick={handleReloadSRO}>Обновить </Button>
        <PageList
          name='workerRes'
          columns={[
            { id: 'typeSRO', name: 'тип СРО' },
            { id: 'shortName', name: 'Название СРО' },
            { id: 'sroInn', name: 'ИНН СРО' },
            { id: 'statusMember', name: 'Статус члена сро' },
            { id: 'regNumb', name: 'Регистрационный номер' },
            { id: 'dateReg', type: 'date', formatDate: 'DD.MM.YYYY', name: 'Дата регистрации в реестре СРО' },
            { id: 'dateEnd', type: 'date', formatDate: 'DD.MM.YYYY', name: 'Дата прекращения членства в СРО' },
            { id: 'endDesc', name: 'Основание прекращения членства в СРО' },
            { id: 'sved', name: 'Сведения о соответствии члена СРО условиям членства в СРО' },
            { id: 'vznos', name: 'компенсационный фонд' },
            { id: 'vznosDog', name: 'компенсационный фонд обеспечения договорных обязательств' },
          ]}
          createItem={false}
          deleteItem={false}
          loadAPI={{
            param: {
              id: props.match.params.id,
            },
            request: getCompaniesSRO,
          }}
          idName='id'
          click={false}
          csv={false}
          pagination={false}
          tableProps={{ pagination: false }}
          goBack={false}
          reload={reload}
          reloadFunc={() => setReload(false)}
          {...props}
        />
      </div>}
      <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
    </div>
  );
}
