import React, { useState } from 'react'
import { Button, Grid, Tooltip, Typography, CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../../reducers/materialLoad';
import { startTask } from '../../api/ocr'
import {formSubmitAPI} from '../../reducers/form';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  input: {
    display: 'none',
  },
  edt: {
    padding: '1px', /* Поля вокруг текста */
  },
  main: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
}));
export default function OcrStart ({ history, match }) {
  const dispatch = useDispatch()
  const [fileId, setFileId] = useState(93)
  const [loading, setLoading] = useState(false)
  const [fileName,setFileName] = useState('')
  const [fileShort,setFileShort] = useState('')
  const classes = useStyles()
  const handleSave = () => {
    if (fileId === 0) return;
    dispatch(formSubmitAPI({
      props: {
        idFile:fileId
      },
      url: startTask.path({ id: match.params.idProject }),
      method: startTask.type,
      // history: history,
    }, "formName"))
  }
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoading(true)
    let shortName = e.target.files[0].name
    if (shortName.length > 18) {
      shortName = e.target.files[0].name.slice(0, 7) + '...' + e.target.files[0].name.slice(-8)
    }
    setFileName(e.target.files[0].name)
    setFileShort(shortName)
    dispatch(actions.loadFileAPI({ file: e.target.files[0], path: `/files/add` }))
      .then(res => {
        if (res.success) {
          setFileId(res.id)
        }
        setLoading(false)
      })
      .catch()
  }
  return <div className='App-paper'>
    <div className={classes.formControl}>
      <input
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={(e) => _handleImageChange(e)}
      />
      <label htmlFor="contained-button-file">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item >
            <Button variant='contained' component="span" size="small">Файл</Button>
          </Grid>
          <Grid item >
            <Tooltip title={fileName || ''}><Typography>{fileShort}</Typography></Tooltip>
          </Grid>
          <Grid item >
            {loading && <CircularProgress size={20} />}
          </Grid>
        </Grid>
      </label>
    </div>
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
    <Button color='primary' component="span" onClick={handleSave}>Распознать</Button>
  </div>
} 