import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { formObjChangeObj } from '../../../../reducers/form'
import { Popover, List, ListItem } from '@material-ui/core'
function PopperColumnVisible({ anchorList, onClose, formName }) {
  const column = useSelector(state => state.form[formName]?.values.column||{})
  const dispatch = useDispatch()
  const changeHeader = (id) => {
    dispatch(formObjChangeObj({ visible: true }, { field: 'column', id: id, name: formName }))
  }
  return <Popover
    open={Boolean(anchorList)}
    anchorEl={anchorList}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    onClose={onClose}
  >
    <div className='App-paper'>
      <List>
        {Object
          .keys(column)
          .filter(key => !column[key].visible)
          .map(key => {
            return <ListItem key={key} button onClick={() => changeHeader(key)}>{key}</ListItem>
          })
        }
      </List>
    </div>
  </Popover>
}
export default PopperColumnVisible