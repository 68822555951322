import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import Autocomplite from './Autocomplite'


export default function FilterDate({
  filter, onChange, changeDate, filterDate
}) {
  return <div >
    <Grid container spacing={2} alignItems='flex-end'>
      <Grid xs={3} item>
        <Autocomplite
          selectProps={{
            style:{width:'100%'}
          }}
          palaseHolder='Группировка'
          action={(e) => onChange(e.value)}
          arr={[{ value: 'year', label: 'Год' }, { value: 'day', label: 'День' }, { value: 'month', label: 'Месяц' }]}
        />
      </Grid>
      <Grid item>
        {(filter === "year") ?
          <TextField
            id="date"
            name='date'
            label='Год'
            type="number"
            value={filterDate}
            inputProps={{
              min: 2018,
              max: 2099,
              step: 1
            }}
            onChange={changeDate}
          />
          : <TextField
            id="date"
            name='date'
            type={(filter === "month") ? "month" : "date"}
            label={(filter === "month") ? "Месяц" : "Дата"}
            value={filterDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={changeDate}
          />}
      </Grid>
    </Grid>
  </div>
}