import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../reducers/form';
import Button from '@material-ui/core/Button';
import { addShipmentArenda } from '../../../../api/shipment'
import { loadProvider, addProvider } from '../../../../api/list'
import SimpleAdd from '../../../../components/SimpleAdd'
import validateProps from '../../../../functions/validateProps'
import { enqueueSnackbar } from '../../../../reducers/notifier'
import CompaniesAdd from '../../../list/provider/ProviderAdd'

const formName = 'rentEquipmentAdd'
export default function ArendaEquipmentAdd({ history, match }) {
  const dispatch = useDispatch()
  const arrProps = [
    {
      field: 'idProvider', label: 'Поставщик', type: 'autoLoadCreateModal',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        createAPI: addProvider,
        createBody: 'name',
        path: loadProvider.path,
        meta: {
          field: 'provider',
          value: 'id',
          labelFunc: (obj) => obj.name + ' '+ obj.inn  || ''
        },
        modal:({ handleClose })=><CompaniesAdd match={match} modal={true} modalClose={handleClose}/>
      }
    },
    {
      field: 'idEquipment', label: 'Оборудование', type: 'autoLoad',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        path: '/equipment',
        meta: {
          field: 'equipment',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
    { field: 'serialNumber', type: 'text', label: 'Серийный номер' },
    {
      field: 'valueIsHour', type: 'autoArr', label: 'Вид аренды',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: { arr: [{ value: 'false', label: 'Посуточная (сутки)' }, { value: 'true', label: 'Почасовая (часы)' }] },
      change: text => { dispatch(actions.formChangeAsObj({ isHour: text.value, valueIsHour: text }, { name: formName })) }
    },
    {
      field: 'valueAutoWriteOff', type: 'autoArr', label: 'Тип списания оборудования',
      textProps: { arr: [{ value: 'false', label: 'Ручное списание' }, { value: 'true', label: 'Автоматическое списание' }] },
      change: text => { dispatch(actions.formChangeAsObj({ autoWriteOff: text.value, valueAutoWriteOff: text }, { name: formName })) },
      validator: [value => !!value], errorText: 'Поле обязательно'
    },
    { field: 'time', type: 'text', label: 'Время аренды', textProps: { type: 'number', min: '0' }, validator: [value => value > 0], errorText: 'не может быть меньше нуля' },
    { field: 'dateStart', type: 'text', label: 'Начало работы', textProps: { type: 'datetime-local' }, labelProps: { shrink: true }, validator: [value => value !== ''], errorText: 'Поле обязательно' },
    { field: 'price', type: 'text', label: 'Стоимость аренды', textProps: { type: 'number' }, validator: [value => value >= 0], errorText: 'Поле обязательно' },
  ]
  useEffect(() => {
    dispatch(actions.formInitialize({
      idProvider: '',
      dateStart: '',
      price: '0',
      idEquipment: '',
      time: '',
      isHour: null,
      serialNumber: '',
      autoWriteOff: null,
      valueIsHour: null,
      valueAutoWriteOff: null,
      errorValidate: {
        idProvider: false,
        idEquipment: false,
        serialNumber: false,
        valueIsHour: false,
        valueAutoWriteOff: false,
        time: false,
        dateStart: false,
        price: false,
      }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSubmit = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        idProvider: values.idProvider,
        dateStart: values.dateStart,
        price: values.price,
        idEquipment: values.idEquipment,
        time: values.time,
        serialNumber: values.serialNumber,
        autoWriteOff: values.autoWriteOff,
        isHour: values.isHour,
        idProject: match.params.id
      },
      url: addShipmentArenda.path,
      method: addShipmentArenda.type,
      history: history
    }))
  }
  return (<div className='App-paper' style={{ padding: '16px' }}>
    <form autoComplete="off" onSubmit={handleSubmit} >
      <SimpleAdd
        title='Создать документ аренды'
        arr={arrProps}
        formName={formName}
      />
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color='primary' >Сохранить</Button>
    </form>
  </div>)
}
