import React from 'react'
import { Grid, Typography } from '@material-ui/core';
export default function AllPrice ({ summ }) {
  return <div style={{ textAlign: 'right' }}>
    <Grid
      container
      direction="row"
      justify='flex-end'
      alignItems="flex-end"
    >
      <Grid item xs={3}><Typography variant='h6'>Всего</Typography></Grid>
      <Grid item xs={3}><Typography variant='h6'>{Number(summ).toLocaleString()}</Typography></Grid>
    </Grid>
  </div>
}