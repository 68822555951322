import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../reducers/formTable';
import { fetchDispath } from '../../../functions/fetch'
import { getMOPSummary, getMOP, getMOPSummaryPrint } from "../../../api/doc";
import { Button, Table, TableBody, TableRow, TableCell, TableHead, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import DialogDoc from './source/DialogDoc'
import { formInitialize, formDestroy, formObjAdd, formChange } from '../../../reducers/form';
import { makeStyles } from '@material-ui/core/styles';
import print from '../../../functions/print'
import moment from 'moment'
const formName = 'OrderProviderSummary'
const formNameList = 'OrderProviderList'
export default function OrderProviderSummary({ history, match, location }) {
  const idProject = match.params.idProject
  const dispatch = useDispatch()
  const [providerObj, setProviderObj] = useState({})
  const [providerArr, setProviderArr] = useState([])
  const [openDialog, changeOpenDialog] = useState(false)
  const [next, changeNext] = useState(false)
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const [selected, setSelected] = useState([])
  const load = useCallback((search = {}) => {
    setSelected(search.selected)
    dispatch(fetchDispath({
      querty: {
        ...search,
        idProject
      },
      progress: true,
      request: getMOPSummary,
    })).then(res => {
      const provider = {}
      res.get.forEach(el => {
        el.provider.forEach(p => {
          provider[p.idProvider] = {
            name: p.provider,
            id: p.idProvider
          }
        })
      });
      const get = res.get.map(obj => {
        let providerObj = {}
        const arrDate = []
        // const arrDateMax = []
        obj.provider.forEach(e => {
          providerObj[e.idProvider] = e
          if (e.dateOrder) {
            arrDate.push(new Date(e.dateOrder))
          }
        })
        const min = Math.min(...obj.provider.map(e => e.price))
        const max = Math.max(...obj.provider.map(e => e.price))
        let maxDate = ''
        let minDate = ''
        if (arrDate.length > 0) {
          maxDate = moment(Math.max(...arrDate)).format('YYYY-MM-DD');
          minDate = moment(Math.min(...arrDate)).format('YYYY-MM-DD');
        }
        return {
          ...obj,
          providerObj,
          min,
          max,
          maxDate,
          minDate,
          price: Math.round(100*Number(obj.price)/1.2)/100
        }
      })
      setProviderObj(provider)
      setProviderArr(Object.values(provider).map(e => e.id))
      dispatch(actions.loadFormTable({ get, count: res.get.length }, { name: formName, id: 'id' }))
    }).catch(err => console.log(err))
  }, [dispatch, idProject])
  useEffect(() => {
    dispatch(actions.initFormTable({ name: formName }))
    dispatch(actions.initFormTable({ name: formNameList }))
    dispatch(formInitialize({
      selected: {}
    }, { name: formName }))
    return () => {
      dispatch(actions.clearFormTable(formName))
      dispatch(actions.clearFormTable(formNameList))
      dispatch(formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      querty: {
        idProject: idProject,
        status: 'invoice'
      },
      progress: true,
      request: getMOP,
    })).then(res => {
      dispatch(actions.loadFormTable({ ...res }, { name: formNameList, id: 'id' }))
    }).catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, idProject])
  const handleAuto = () => {
    let selectAuto = {}
    formTable.allIds.forEach(row => {
      const obj = formTable.byId[row]
      providerArr.forEach(id => {
        const provObj = obj.providerObj[id]
        if (provObj && provObj.price <= obj.min) {
          selectAuto[row] = id
        }
      })
    })
    dispatch(formChange(selectAuto, { field: 'selected', name: formName }))
  }
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        querty: {
          selected: selected,
          idProject: idProject,
        },
        request: getMOPSummaryPrint,
      }
    )
  }
  return <div>
    <Button color='primary' onClick={() => history.goBack()}>Назад</Button>
    <Button color='primary' onClick={() => changeOpenDialog(true)} >Сформировать</Button>
    <Button color='primary' onClick={handleAuto}>Автовыбор</Button>
    <Button color='primary' onClick={() => changeNext(!next)} >{next ? 'К выбору' : 'Результат'}</Button>
    <Button color='primary' onClick={handlePrint} >Печать </Button>
    <DialogDoc open={openDialog} onClose={() => changeOpenDialog(false)} formName={formNameList} load={load} />
    {!next && <Table >
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 400 }}>Наименование</TableCell>
          <TableCell style={{ minWidth: 200 }}>Сметная цена(без НДС)</TableCell>
          {providerArr.map(key => {
            return <TableCell key={key} style={{ minWidth: 250 }}>{providerObj[key].name}</TableCell>
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {formTable.allIds.map(row => {
          const obj = formTable.byId[row]
          console.log(obj)
          return <TableRow style={{ backgroundColor: (obj.price<obj.min)?'#f7d0d077':'#fff'}} >
            <TableCell style={{ minWidth: 400 }}><b>{obj.name}</b></TableCell>
            <TableCell style={{ minWidth: 200, color: (obj.price<obj.min)?'#FF0000':'#000000' }}>{obj.price}</TableCell>
            {providerArr.map(id => {
              const provObj = obj.providerObj[id]
              if (!provObj) return <TableCell key={id}></TableCell>
              return <CellProvider key={id} provObj={provObj} obj={obj} formName={formName} providerId={id} materialId={row} />
            })}
          </TableRow>
        })}
      </TableBody>
    </Table>}
    {next && <TableResult formName={formName} />}
  </div>
}

const useStyle = makeStyles(theme => ({
  outerColumn: {
    borderRight: "1px solid grey",
    borderBottom: "1px solid grey",
    borderLeft: "1px solid grey"
  },
  centerColumn: {
    borderBottom: "1px solid grey"
  }
}));

function CellProvider({ provObj, obj, formName, providerId, materialId }) {
  const classes = useStyle()
  const dispatch = useDispatch()
  const Ds = ({ name }) => <div style={{ margin: 4 }}>{name}</div>
  const getColor = (compare, min, max) => {
    if (compare <= min) return '#00AA00'
    if (compare >= max) return '#AA0000'
    return '#000000'
  }
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { selected = {} } = form.values
  const checked = (selected[materialId]) ? selected[materialId] === providerId : false

  const handleClick = () => {
    if (checked) {
      dispatch(formObjAdd(null, { field: 'selected', id: materialId, name: formName }))
    }
    else {
      dispatch(formObjAdd(providerId, { field: 'selected', id: materialId, name: formName }))
    }
  }
  const dateOrder = (!!provObj.dateOrder) ? moment(provObj.dateOrder).format('DD.MM.YYYY') : ''
  return <TableCell style={{ minWidth: 250 }}>
    <Grid container direction="column"  >
      <Grid item xs={12} style={{ border: "1px solid grey" }}>
        <FormControlLabel
          style={{ margin: 4 }}
          control={
            <Checkbox
              checked={checked}
              onChange={handleClick}
              color="primary"
            />
          }
          label={provObj.providerName || '-'}
        />
      </Grid>
      <Grid container direction="column"  >
        <Grid item xs={12} className={classes.outerColumn}>
          <div style={{ margin: 4, color: getColor(provObj.dateOrder, obj.minDate, obj.maxDate) }}>
            <Ds name={'Дата доставки: ' + dateOrder} />
          </div>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction='row'>
        <Grid item xs={4} className={classes.outerColumn}><Ds name='Цена' /></Grid>
        <Grid item xs={4} className={classes.centerColumn}><Ds name='Кол-во' /></Grid>
        <Grid item xs={4} className={classes.outerColumn}><Ds name='Ед.изм.' /></Grid>
      </Grid>
      <Grid container item xs={12} direction='row'>
        <Grid item xs={4} className={classes.outerColumn}><div style={{ margin: 4, color: getColor(provObj.price, obj.min, obj.max) }}>{provObj.price}</div></Grid>
        <Grid item xs={4} className={classes.centerColumn}><Ds name={provObj.count} /></Grid>
        <Grid item xs={4} className={classes.outerColumn}><Ds name={obj.storageUnit} /></Grid>
      </Grid>
    </Grid>
  </TableCell>
}

function TableResult({ formName }) {
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { allIds = [], byId = {} } = formTable
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { selected = {} } = form.values
  let provider = {}
  allIds.forEach(row => {
    const obj = byId[row]
    if (selected[row]) {
      const providerId = selected[row]
      const providerProps = obj.providerObj[providerId]
      provider[providerId] = (provider[providerId])
        ? { ...provider[providerId], material: [...provider[providerId].material, { ...providerProps, name: obj.name, storageUnit: obj.storageUnit, priceEst: obj.price }] }
        : { provider: providerProps.provider, idProvider: providerProps.idProvider, material: [{ ...providerProps, name: obj.name, storageUnit: obj.storageUnit, priceEst: obj.price }] }
    }
  })
  return <Table>
    <TableHead>
      <TableRow>
        <TableCell style={{ minWidth: 400 }}>Наименование</TableCell>
        <TableCell >Количество</TableCell>
        <TableCell >Ед. изм.</TableCell>
        <TableCell >Цена</TableCell>
        <TableCell >Сметная цена(без НДС)</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {Object.values(provider).map(obj => {
        return <React.Fragment>
          <TableRow>
            <TableCell style={{ minWidth: 400 }}><b>{obj.provider}</b></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {obj.material.map(e => {
            return <TableRow>
              <TableCell style={{ minWidth: 400 }}>{e.name}</TableCell>
              <TableCell >{e.count}</TableCell>
              <TableCell >{e.storageUnit}</TableCell>
              <TableCell ><div style={{ color: (e.price <= e.priceEst) ? '#00AA00' : '#AA0000' }}>{e.price}</div></TableCell>
              <TableCell >{e.priceEst}</TableCell>
            </TableRow>
          })}
        </React.Fragment>
      })}
    </TableBody>
  </Table>
}