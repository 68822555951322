import React from 'react'
import { Pie } from 'react-chartjs-2'

const data = (arr) => ({
  datasets: [
    {
      label: '# of Votes',
      data: arr,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
      ],
      borderWidth: 1,
    },
  ],
  labels: ['Выработано', 'Закуплено но не выработано', 'Осталось '],
})

export default function Percent({ orderMatPercent, costsMatPercent, width, height }) {
  return <div style={{ width: width, height: height }}>
    <Pie
      data={data([costsMatPercent, orderMatPercent - costsMatPercent, 100 - orderMatPercent])}
      width={10}
      height={10}
    />
  </div>
}