import React from 'react'
import PageList from '../../../components/PageListAPI'
import { getPaymentsListAPI, delPaymentsByIdAPI } from '../../../api/tariff.api'

export default function PaymentsList(props) {
  return <div className='App-paper'>
    <PageList
      name='tariff-list'
      title='Оплата'
      columns={[
        { id: 'dateCreate', name: 'Дата создания', type: 'date', formatDate:'LLL' },
        { id: 'datePayment', name: 'Дата оплаты', type: 'date', formatDate:'DD.MM.YYYY' },
        { id: 'accountName', name: 'Аккаунт' },
        { id: 'planName', name: 'Тариф' },
        { id: 'price', name: 'Сумма' },
        { id: 'user', name: 'Добавил' },
      ]}
      createItem={true}
      deleteItem={true}
      headerInit={{
        sortBy: 'dateCreate',
        sortDirection: 'desc',
      }}
      loadAPI={{
        request: getPaymentsListAPI,
      }}
      deleteAPI={delPaymentsByIdAPI}
      {...props}
    />
  </div>
}