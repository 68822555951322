import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core';
import * as actions from '../../../reducers/form';
import { getCompaniesById, updCompanies } from '../../../api/list'
import { fetchDispath } from '../../../functions/fetch'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'
import { getCompanies } from '../../../api/list'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite, set as setAutocomplite } from '../../../reducers/autocomplite'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 220,
    marginTop: 5,
    marginBottom: 3,
    width: '50%',
    overflowX: 'visible'

  },
}))
const formReg = 'refSelf'
export default function CompaniesEdit(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const formName = 'CompanyEdit'
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(initialAutocomplite({ field: formReg }))
    dispatch(fetchDispath({
      param: {
        id: props.match.params.id,
      },
      progress: true,
      request: getCompaniesById,
    }))
      .then(res => {
        dispatch(actions.formInitialize({
          fullName: res.get.fullName,
          shortName: res.get.shortName,
          inn: res.get.inn,
          ogrn: res.get.ogrn,
          kpp: res.get.kpp,
          okpo: res.get.okpo,
          oktmo: res.get.oktmo,
          adress: res.get.adress,
          phone: res.get.phone,
          email: res.get.email,
          selfRegulatory: res.get.selfRegulatoryId,
          selfRegulatoryDoc: res.get.selfRegulatoryDoc,
        }, { name: formName }))
        dispatch(setAutocomplite({ value: res.get.selfRegulatoryId + '', label: res.get.selfRegulatory }, { field: formReg }))

      })
      .catch(err => console.log(err))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formReg }))
    }
  }, [dispatch, props.match.params.id])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getCompanies.path, {
      field: formReg,
      value: 'id',
      labelFunc: (obj) => obj.shortName
    }))
  }, [dispatch, props.match.params.id])
  const arrProps = [
    {
      field: 'fullName', type: 'text', label: 'Полное название компании*',
      validator: [value => value !== ''], errorText: 'Поле обязательно',
    },
    {
      field: 'shortName', type: 'text', label: 'Сокращенное название компании*',
      validator: [value => value !== ''], errorText: 'Поле обязательно',
    },
    {
      field: 'inn', type: 'text', label: 'ИНН(10-12 символов)*',
      validator: [value => value !== '', v => v.length >= 10 && v.length <= 12], errorText: 'Поле обязательно',
    },
    {
      field: 'ogrn', type: 'text', label: 'ОГРН(13 символов) ОГРНИП(15)*',
      validator: [value => value !== '', v =>  v!== '' && v.length >= 13 && v.length <= 15], errorText: 'Поле обязательно',
    },
    {
      field: 'kpp', type: 'text', label: 'КПП(9 символов)',
      validator: [v => v === '' || v.length === 9], errorText: 'Не правильное значание',
    },
    {
      field: 'okpo', type: 'text', label: 'ОКПО(8-10 символов)',
      // validator: [v => v === '' || v.length === 12], errorText: 'Не правильное значание',
    },
    {
      field: 'oktmo', type: 'text', label: 'ОКТМО(12 символов)',
    },
    { field: 'adress', type: 'text', label: 'Адрес', },
    { field: 'phone', type: 'text', label: 'Телефон', },
    { field: 'email', type: 'text', label: 'Почта', },
    // {
    //   field: 'selfRegulatory', type: 'autoLoad', label: 'Саморегулируемая организация',
    //   textProps: {
    //     path: getCompanies.path,
    //     meta: {
    //       field: formReg,
    //       value: 'id',
    //       labelFunc: (obj) => obj.shortName
    //     }
    //   }
    // },
    // { field: 'selfRegulatoryDoc', type: 'text', label: 'Свидетельство №', },
  ]
  const handleUpdate = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    const { fullName, shortName, inn, ogrn, kpp, okpo, oktmo, adress, phone, email, selfRegulatory, selfRegulatoryDoc} = values
    dispatch(actions.formSubmitAPI({
      props: {
        fullName,
        shortName,
        inn,
        ogrn,
        kpp,
        okpo,
        oktmo,
        adress,
        phone,
        email,
        selfRegulatory: selfRegulatory||null,
        selfRegulatoryDoc,
      },
      url: updCompanies.path({
        id: props.match.params.id,
        idMaterial: props.match.params.idMaterial
      }),
      method: updCompanies.type,
      history: props.history
    }, formName))
  };
  return (
    <div className='App-paper' >
      <form autoComplete="off" onSubmit={handleUpdate} className={classes.main}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd formName={formName} arr={arrProps} title='Изменить компанию' />
        </div>
        <div className={classes.main}>
          <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
          <Button type="submit" color="primary">Изменить</Button>
        </div>
      </form>
    </div>
  );
}
