import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../../reducers/formTable';
import { formChangeAsObj, formInitialize } from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'
import { Button, Tab, Tabs, Grid, IconButton } from '@material-ui/core';
import PageList from '../../../components/PageListAPI'
import GridProps from '../../../components/GridProps'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { getFile } from '../../../api/list'
import GetAppIcon from '@material-ui/icons/GetApp';
import { getMOPById, printMOPById, updMOPStatusById } from "../../../api/doc";
import print from '../../../functions/print'
import DialogChangeStatus from './source/DialogStatus'
import { enqueueSnackbar } from '../../../reducers/notifier'
import { statusAll } from './source/type.enum'
import ByIdAddFile from './source/ByIdAddFile'
import TableOrderProvider from './source/TableOrderProvider'
import VisibilityIcon from '@material-ui/icons/Visibility';
import DialogFile from '../orderOutfitPlan/source/DialogFile'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%'
  },
}));

export default function OrderProviderById({ match, history, location }) {
  const [value, setValue] = useState(0)
  const [byId, setById] = useState({
    date: '',
    dateOrder: '',
    provider: '',
    idProvider: 0,
    title: ''
  })
  const [statusDoc, setStatusDoc] = useState(statusAll[0] || { value: null, label: '' })
  const [openDialog, changeOpenDialog] = useState(false)
  const [fileName, setFileName] = useState('')
  const dispatch = useDispatch();
  const tableM = 'OutfitOPByIdM'
  const tableF = 'OutfitOPByIdF'
  const formShipment = 'MOP_Shipment'
  const formCopy = 'MOP_Copy'
  useEffect(() => {
    dispatch(formInitialize({ arr: [], provider: { value: null, label: null } }, { name: formShipment }))
    dispatch(actions.initFormTable({ name: tableF }))
    dispatch(actions.initFormTable({ name: tableM }))
    return () => {
      dispatch(actions.clearFormTable(tableM))
      dispatch(actions.clearFormTable(tableF))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        idProject: match.params.idProject,
        id: match.params.idDoc
      },
      progress: true,
      request: getMOPById,
    }))
      .then((res) => {
        if (!res) return;
        setById({
          date: moment(res.get.header.date).format('LL'),
          dateOrder: res.get.header.dateOrder,
          title: res.get.header.title,
          provider: res.get.header.provider,
          idProvider: res.get.header.idProvider,
        })
        const status = statusAll.find(e => e.value === res.get.header.status)
        if (status) setStatusDoc(status)
        dispatch(actions.loadFormTable({ get: res.get.table.map(e => ({ ...e, name: e.name + '/' + e.typeMark + '/' + e.vendorCode, priceNew: e.price || 0, countNew: e.count || 0, dateOrder: e.dateOrder?moment(e.dateOrder).format('YYYY-MM-DD'):'' })) }, { name: tableM, id: 'id' }))
        dispatch(actions.loadFormTable({ get: res.get.files }, { name: tableF, id: 'id' }))
      })
  }, [dispatch, match.params.idProject, match.params.idDoc, setById])
  const handleClick = (obj) => {
    dispatch(fetchDispath({
      param: {
        id: obj.name,
      },
      progress: false,
      request: getFile,
      buff: true
    }))
      .then(res => {
        if (!res) return;
        res.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = obj.name;
          document.body.appendChild(a);
          a.click();
          setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
        })
      })
  }
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          id: match.params.idDoc,
        },
        request: printMOPById,
      }
    )
  }
  const changeStatus = (status) => {
    fetch({
      param: {
        id: match.params.idDoc,
      },
      body: {
        status: status.value,
      },
      progress: true,
      request: updMOPStatusById,
    })
      .then((res) => {
        if (res.success) {
          dispatch(enqueueSnackbar({
            message: `Успешно`,
            options: { variant: 'success' }
          }))
          setStatusDoc(status)
          changeOpenDialog(false)
        }
      })
  }
  const formTable = useSelector(state => state.formTable[tableM] || state.formTable.default)
  const handleClickToShipment = () => {
    const arrMaterial = formTable.allIds;
    const arrObj = {}
    const arr = arrMaterial.map(key => {
      const { idMaterial, count, price = 0, nds = 'none', idEstimate, providerName } = formTable.byId[key]
      console.log(formTable.byId[key])
      if (arrObj[idMaterial]) arrObj[idMaterial] += 1; else arrObj[idMaterial] = 1
      return { id: idMaterial, count: count, price, nds, idEstimate: idEstimate, estimateName: providerName }
    })
    dispatch(formChangeAsObj({ arr, provider: { value: byId.idProvider.toString(), label: byId.provider }, dateOrder: moment(byId.dateOrder).format('YYYY-MM-DD') }, { name: formShipment }))
    const path = `/projects/${match.params.idProject}/shipment/materials/add`
    history.push(path);
  }
  const handleCopy = () => {
    const arrMaterial = formTable.allIds;
    const arrObj = {}
    const arr = arrMaterial.map(key => {
      const { idMaterial, count } = formTable.byId[key]
      if (arrObj[idMaterial]) arrObj[idMaterial] += 1; else arrObj[idMaterial] = 1
      return { id: idMaterial, count: count }
    })
    dispatch(formInitialize({ arr: arr }, { name: formCopy }))
    const path = `/projects/${match.params.idProject}/order/provider/add`
    history.push(path);
  }
  return <div className='App-paper'>
    <DialogFile fileName={fileName} />
    <Button color='primary' onClick={handlePrint} >Печать </Button>
    <Button color='primary' onClick={() => changeOpenDialog(true)} >Изменить статус </Button>
    <ByIdAddFile idDoc={match.params.idDoc} formName={tableF} />
    <Button color='primary' onClick={() => handleClickToShipment()} >Создать док. покупки </Button>
    <Button color='primary' onClick={handleCopy} >Создать копию </Button>
    <DialogChangeStatus open={openDialog} onClose={() => changeOpenDialog(false)} selectedNow={statusDoc} changeStatus={changeStatus} />
    <Grid
      item
      container
      xs={6}
    >
      <GridProps name='Описание' value={byId.title} />
      <GridProps name='Дата создания' value={byId.date} />
      <GridProps name='Желаемая дата отгрузки' value={moment(byId.dateOrder).format('LL')} />
      <GridProps name='Поставщик' value={byId.provider} />
      <GridProps name='Статус' value={statusDoc.label} />
    </Grid>
    <Tabs value={value} onChange={(e, v) => setValue(v)} indicatorColor='primary'>
      <Tab label='Материалы' />
      <Tab label='Файлы' />
    </Tabs>
    {value === 0 &&
      <TableOrderProvider formName={tableM} idDoc={match.params.idDoc} />
    }
    {value === 1 &&
      <PageListCustom
        name={tableF}
        columns={[
          { id: 'type', name: 'Тип' },
          { id: 'originalName', name: 'Название' },
          { id: 'fileView', name: '', type: 'component', width: '5%', component: ({ obj }) => <IconButton onClick={() => setFileName(obj.name)}><VisibilityIcon /></IconButton> },
          { id: 'file', name: '', align: 'right', type: 'component', width: '5%', component: ({ obj }) => <IconButton onClick={() => handleClick(obj)}><GetAppIcon /></IconButton> },
        ]}
        match={match}
        history={history}
        location={location}
      />
    }
    <Button color='primary' onClick={() => history.goBack()}>Назад</Button>
  </div>
}


function PageListCustom({ name, columns, match, history, location }) {
  const classes = useStyles()
  return <PageList
    classes={classes}
    name={name}
    columns={columns}
    idName='id'
    csv={false}
    click={false}
    notLoad={true}
    notClear={true}
    match={match}
    history={history}
    location={location}
    pagination={false}
    goBack={false}
    tableProps={{ pagination: false }}
  />
}