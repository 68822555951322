import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/materialLoad';
import * as actions2 from '../../reducers/form';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import SimpleAdd from '../../components/SimpleAdd'

const useStyle = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  input: {
    display: 'none',
  },
  edt: {
    padding: '1px', /* Поля вокруг текста */
  },
  main: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
}));
export default function SelectFile({ isMaterial, formName }) {
  const dispatch = useDispatch()
  const arrProps = [
    { field: 'start', type: 'text', label: 'Начало', textProps: { autoComplete: 'off', type: 'number' } },
    { field: 'end', type: 'text', label: 'Окончание', textProps: { autoComplete: 'off', type: 'number' } },
    {
      field: 'consist', type: 'autoArr', label: `Если ${(isMaterial) ? 'товары' : 'работа'} есть`,
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: { arr: [{ value: 'change', label: 'Изменить' }, { value: 'add', label: 'Добавить' }], defaultValue: 'add', },
    },
  ]
  const [loading, setLoading] = useState(false)
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoading(false)
    let shortName = e.target.files[0].name
    if (shortName.length > 18) {
      shortName = e.target.files[0].name.slice(0, 7) + '...' + e.target.files[0].name.slice(-8)
    }
    const fileName = e.target.files[0].name
    dispatch(actions.loadFileAPI({ file: e.target.files[0], path: `/files/add` }))
      .then(res => {
        if (res.success) {
          dispatch(actions2.formChangeAsObj({
            file: fileName,
            fileShort: shortName,
            fileId: res.id
          }, { name: formName }))
        }
        setLoading(false)
      })
      .catch()
  }
  const classes = useStyle()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  return (<div>
    <div className={classes.formControl}>
      <input
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={(e) => _handleImageChange(e)}
      />
      <label htmlFor="contained-button-file">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        // spacing={16}
        >
          <Grid item >
            <Button variant='contained' component="span" size="small">Файл</Button>
          </Grid>
          <Grid item >
            <Tooltip title={values.file || ''}><Typography>{values.fileShort}</Typography></Tooltip>
          </Grid>
          <Grid item >
            {loading && <CircularProgress size={20} />}
          </Grid>
        </Grid>
      </label>
    </div>
    <div style={{ padding: '16px' }}>
      <SimpleAdd
        arr={arrProps}
        formName='MaterialLoad'
      />
      {isMaterial && <FormControlLabel
        className={classes.formControl}
        control={
          <Checkbox
            checked={values.estimate || false}
            onChange={(e) => { dispatch(actions2.formChange(e.target.checked, { field: 'estimate', name: formName })) }}
            color="primary"
          />
        }
        label="Материалы заказчика"
      />}
    </div>
  </div>
  );
}