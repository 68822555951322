import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core'
import * as actions from '../../reducers/form';
import { enqueueSnackbar } from '../../reducers/notifier'
import { set as setAutocomplite } from '../../reducers/autocomplite'
import { fetchDispath } from '../../functions/fetch'
import validateProps from '../../functions/validateProps'
import { loadProjectById, updProjectById } from '../../api/projects'
import { getWorkerList } from '../../api/worker'
import SimpleAdd from '../../components/SimpleAdd'

const formName = 'ProjectMain'  
const arrProps = [
    { xs: 12, field: 'name', type: 'text', label: 'Наименование', validator: [(value) => value !== ''], errorText: 'Поле обязательно' },
    { xs: 12, field: 'description', type: 'text', label: 'Описание', textProps: { multiline: true, rows: "4" } },
    {
      field: 'waybillMedical', label: 'Медработник для путевых листов', type: 'autoLoad',
      textProps: {
        path: getWorkerList.path,
        meta: {
          field: 'waybillMedical',
          value: 'id',
          labelFunc: (obj) => obj.surname + ' ' + obj.name[0] + '.' + obj.patronymic[0] + '.',
        },
        selectProps: { isClearable: true },
      }
    },
    {
      field: 'waybillCondition', label: 'Механик для путевых листов', type: 'autoLoad',
      textProps: {
        path: getWorkerList.path,
        meta: {
          field: 'waybillCondition',
          value: 'id',
          labelFunc: (obj) => obj.surname + ' ' + obj.name[0] + '.' + obj.patronymic[0] + '.',
        },
        selectProps: { isClearable: true },
      }
    },
  ]
export default function ProjectMain({ idProject, history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      name: '',
      description: '',
      waybillMedical: null,
      waybillCondition: null,
      valuewaybillMedical: null,
      valuewaybillCondition: null,
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: idProject,
      },
      progress: true,
      request: loadProjectById,
    })).then(res => {
      dispatch(actions.formChangeAsObj({
        name: res.get.name,
        description: res.get.description,
        waybillCondition: res.get.waybillCondition ? res.get.waybillCondition.toString() : null,
        waybillMedical: res.get.waybillMedical ? res.get.waybillMedical.toString() : null,
      }, { name: formName }))
      if (res.get.waybillCondition) {
        dispatch(setAutocomplite({ value: res.get.waybillCondition + '', label: res.get.waybillConditionName }, { field: 'waybillCondition' }))
      }
      if (res.get.waybillMedical) {
        dispatch(setAutocomplite({ value: res.get.waybillMedical + '', label: res.get.waybillMedicalName }, { field: 'waybillMedical' }))
      }
    }).catch(err => console.log(err))
  }, [dispatch, idProject])

  const form = useSelector(state => state.form[formName] || state.form.default)
  const handleSubmit = () => {
    const isError = validateProps(arrProps, form.values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    const { name, description, waybillMedical, waybillCondition } = form.values
    dispatch(actions.formSubmitAPI({
      props: { name, description, waybillMedical, waybillCondition },
      url: updProjectById.path({ id: idProject }),
      method: updProjectById.type,
      history: history
    }, formName))
  }
  return <div>
    <div style={{ padding: '16px' }}>
      <SimpleAdd
        arr={arrProps}
        formName={formName}
        gridProps={{ lg: 6, md: 12 }}
      />
    </div>
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
    <Button type="submit" color='primary' onClick={handleSubmit}>Сохранить</Button>
  </div>
}