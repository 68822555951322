import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import * as api from '../../api/facility'

import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import { getCompanies } from '../../api/list'
import typeResp from './typeResp.enum'
const textError = 'Поле обязательно'
const formName = 'FacilityResponsibleAdd'

export default function FacilityResponsibleAdd({ history, match }) { 
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ name: '', errorValidate: { name: false } }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [
    { field: 'surname', type: 'text', label: 'Фамилия', validator: [value => value !== ''], errorText: textError, },
    { field: 'name', type: 'text', label: 'Имя', validator: [value => value !== ''], errorText: textError, },
    { field: 'patronymic', type: 'text', label: 'Отчество' },
    {
      field: 'idCompany', type: 'autoLoad', label: 'Компания',
      textProps: {
        path: getCompanies.path,
        meta: {
          field: 'company',
          value: 'id',
          labelFunc: (obj) => obj.shortName
        }
      }
    },
    { field: 'position', type: 'text', label: 'Должность' },
    { field: 'doc', type: 'text', label: 'Документ о представительстве' },
    { field: 'nostroy', type: 'text', label: 'номер НОСТРОЙ' },
    { field: 'date', type: 'text', label: 'Сроки полномочий', textProps: { type: 'date' }, labelProps: { shrink: true } },
    { field: 'type', type: 'autoArr', label: 'Представитель', textProps: { arr: typeResp } }
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        date: values.date,
        doc: values.doc,
        idCompany: values.idCompany,
        name: values.name,
        nostroy: values.nostroy,
        patronymic: values.patronymic,
        position: values.position,
        surname: values.surname,
        type: values.type,
      },
      url: api.addFacilityResponsible.path({ idFacility: match.params.idFacility }),
      method: api.addFacilityResponsible.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Добавить ответственное лицо'
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}
