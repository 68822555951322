import React, { useEffect } from 'react'
import { batch, useDispatch } from 'react-redux'
import * as actions2 from '../../reducers/form';
import * as actions from '../../reducers/formTable';
import { fetchDispath } from '../../functions/fetch'
import { getGroup } from '../../api/estimate'
import { getKS2Table } from '../../api/formks2ks3'
import KS2TableList from './KS2TableList';
const formName = 'KS2Table'
const formGName = 'groupEstimate'
const formRName = 'groupResEstimate'
export default function KS2Table({ history, match }) {
  const idProject = match.params.idProject
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions2.formInitialize({
      byId: {},
      allIds: [],
    }, { name: formName }))
    dispatch(actions.initFormTable({ name: formGName }))
    dispatch(actions.initFormTable({ name: formRName }))
    return () => {
      dispatch(actions2.formDestroy({ name: formName }))
      dispatch(actions.clearFormTable(formGName))
      dispatch(actions.clearFormTable(formRName))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getKS2Table,
    })).then(res => {
      let byId = {}
      let material = {}
      const allIds = res.get.map((e, i) => {
        const id = 'id' + e.id + '_' + i
        byId[id] = {
          ...e,
          countNew: e.count,
        }
        e.table.forEach(el => {
          if (material[el.id + el.type + el.groupId]) {
            if (material[el.id + el.type + el.groupId][e.id]) {
              material[el.id + el.type + el.groupId][e.id] += el.count
            }
            else {
              material[el.id + el.type + el.groupId][e.id] = el.count
            }
          }
          else {
            material[el.id + el.type + el.groupId] = { [e.id]: el.count }
          }
        })
        return id
      })
      dispatch(fetchDispath({
        param: {
          id: idProject
        },
        progress: true,
        request: getGroup,
      })).then(res => {
        const { list, group } = res
        const listMaterial = list.map(e => ({ ...e, ks2: material[e.idMP + e.type[0] + e.idGroup] }))
        let gobj = {}
        group.forEach((el, i) => {
          gobj[el.id] = { res: [], summ: 0 };
        });
        listMaterial.forEach((el, i) => {
          gobj[el.idGroup].res.push('id' + el.id + '_' + i);
          gobj[el.idGroup].summ += Number(el.summ) || 0
        });
        batch(() => {
          dispatch(actions2.formChangeAsObj({ byId, allIds }, { name: formName }))
          dispatch(actions.loadFormTable({ count: listMaterial.length, get: listMaterial }, { name: formRName, id: 'id' }))
          dispatch(actions.loadFormTable({
            count: group.length, get: group.map(e => {
              return { ...e, res: gobj[e.id].res, ressumm: gobj[e.id].summ }
            })
          }, { name: formGName, id: 'id' }))
        })
      }).catch(err => console.log(err))

    }).catch(err => console.log(err))

  }, [dispatch, idProject])
  return <div className='App-paper'>
    <KS2TableList
      idProject={idProject}
      formName={formName}
    />
  </div>
}