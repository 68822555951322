import React, { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Button, IconButton } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// let pageRendering = null;
let pageNumPending = null
export default function PDFViewer({ filePath }) {
  console.log(filePath)
  const [pdfDoc, setPdfDoc] = useState(null)
  const [pageNum, setPageNum] = useState(1)
  const [isLoad, setIsLoad] = useState(false)
  const [pageCount, setPaeCount] = useState(1)
  const [scale, setScale] = useState(1.2)
  const CanvasComp = React.forwardRef((props, ref) => (
    <canvas ref={ref}>{props.children}</canvas>
  ));

  const canvas = React.createRef();
  useEffect(() => {
    pdfjsLib.getDocument(filePath).promise.then((pdfDoc_) => {
      setPdfDoc(pdfDoc_)
      setPaeCount(pdfDoc_.numPages);
      setIsLoad(true)
    });
  }, [filePath])
  useEffect(() => {
    if (!isLoad) return;
    // pageRendering = true;
    // Using promise to fetch the page
    pdfDoc.getPage(pageNum).then((page) => {
      var viewport = page.getViewport({ scale: scale });
      canvas.current.height = viewport.height;
      canvas.current.width = viewport.width;

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: canvas.current.getContext('2d'),
        // canvasContext: ctx,
        viewport: viewport
      };
      var renderTask = page.render(renderContext);

      // Wait for rendering to finish
      renderTask.promise.then(function () {
        // pageRendering = false;
        if (pageNumPending !== null) {
          // New page rendering is pending
          // renderPage(pageNumPending);
          pageNumPending = null;
        }
      });
    });
    return () => {
    }
  }, [pageNum, isLoad, pdfDoc, canvas, scale])
  return <div>
    <Button onClick={() => setPageNum(pageNum > 1 ? pageNum - 1 : 1)}>{'<'}</Button>
    <span>Страница: <span id="page_num">{pageNum}</span> / <span id="page_count">{pageCount}</span></span>
    <Button onClick={() => setPageNum(pageNum !== pageCount ? pageNum + 1 : pageCount)}>{'>'}</Button>
    <IconButton onClick={() => setScale(scale + 0.1)}><ZoomInIcon /></IconButton>
    <IconButton onClick={() => setScale(scale - 0.1)}><ZoomOutIcon /></IconButton>
    <CanvasComp ref={canvas} />
  </div>
}