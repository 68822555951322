import React from 'react'
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CardMenu from './source/CardMenu'

function getListRole(role) {
  switch (role) {
    case 'purchase': {
      return ['provider', 'equipment', 'storageUnit', 'position', 'workerTempalte', 'worker', 'companies','resources']
    }
    case 'admin': {
      return ['provider', 'users', 'equipment', 'facility', 'storageUnit', 'position', 'templates', 'workerTempalte', 'worker', 'companies','resources']
    }
    default: {
      return ['workerTempalte']
    }
  }
}

function CartProps({ arr, name, role }) {
  const arrNew = arr.map(({ check, to, primary, disabled }) =>
    getListRole(role).includes(check) && { to, primary, disabled }
  ).filter(key => typeof key !== 'boolean')
  return arrNew.length > 0 && <Grid item><CardMenu arr={arrNew} name={name} /></Grid>
}

export default function ListMenu() {
  const user = useSelector(store => store.user)
  const arr = {
    primary: [
      { check: 'facility', to: '/facility', primary: 'Объекты' },
      { check: 'worker', to: '/worker', primary: 'Работники' },
      { check: 'provider', to: '/provider', primary: 'Поставщики' },
      { check: 'companies', to: '/companies', primary: 'Компании' },
      { check: 'companies', to: '/work-estimate', primary: 'Работы смета' },
      { check: 'companies', to: '/machine', primary: 'Техника' },
    ],
    secondary: [
      { check: 'storageUnit', to: '/storageunit', primary: 'Единица хранения' },
      { check: 'equipment', to: '/equipment', primary: 'Материалы Оборудование' },
      { check: 'workerTempalte', to: '/worktemplate', primary: 'Шаблон работ для наряда' },
      { check: 'position', to: '/position', primary: 'Должности' },
      { check: 'resources', to: '/resources', primary: 'Ресурсы' },
    ],
    settings: [
      { check: 'users', to: '/users', primary: 'Пользователи' },
      { check: 'templates', to: '/templates', primary: 'Шаблоны отчетов' },
      { check: 'templates', to: '/checklist', primary: 'Контрольные листы' },
    ]
  }
  return <Grid container>
    <CartProps arr={arr.primary} name={'Общие'} role={user.auth.role} />
    <CartProps arr={arr.settings} name={'Настройки'} role={user.auth.role} />
    <CartProps arr={arr.secondary} name={'Остальное'} role={user.auth.role} />
  </Grid>
}