import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import { addTenderOur } from '../../api/tender.api'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import { getRegions, getSpecializations } from '../../api/list'
import DocTable from '../../components/DocTable'
import { Typography } from '@material-ui/core';
import { getFacilityOur } from '../../api/facility'


export default function TenderOurAdd({ history }) {
  const dispatch = useDispatch()
  const formName = 'TenderOurAdd'
  useEffect(() => {
    dispatch(actions.formInitialize({
      byId: {}, allIds: [],
      name: '',
      dateConfirmStart: '',
      dateConfirmEnd: '',
      dateOfferEnd: '',
      dateSummingEnd: '',
      valueTypeTender: null,
      typeTender: null,
      ndsUse: null,
      valueNdsUse: null,
      accessKey: Math.random().toString(36).slice(-8),
      regions: [],
      specialization: [],
      idFacility: null,
      valueIdFacility:null,
      errorValidate: {
        name: false,
        dateConfirmStart: false,
        dateConfirmEnd: false,
        dateOfferEnd: false,
        dateSummingEnd: false,
        valueTypeTender: false,
        valueNdsUse: false,
        valueIdFacility:false,
      }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [
    {
      field: 'name', label: 'Наименование', type: 'text',
      validator: [value => value !== ''], errorText: 'Поле обязательно',
    },
    {
      field: 'idFacility', label: 'Объект', type: 'autoLoad',
      textProps: {
        path: getFacilityOur.path,
        meta: {
          field: 'idFacility',
          value: 'id',
          labelFunc: (obj) => obj.name
        },
      },
      change: text => { dispatch(actions.formChangeAsObj({ idFacility: text.value, valueIdFacility: text  }, { name: formName })) },
    },
    {
      field: 'typeTender', type: 'autoArr', label: 'Тип тендера',
      textProps: { arr: [{ value: 'open', label: 'Открытый' }, { value: 'close', label: 'Закрытый' }] },
      change: text => { dispatch(actions.formChangeAsObj({ typeTender: text.value, valueTypeTender: text }, { name: formName })) },
      validator: [value => !!value], errorText: 'Поле обязательно'
    },
    { field: 'dateConfirmStart', type: 'text', label: 'Дата начала подтверждения участия', textProps: { type: 'datetime-local' }, labelProps: { shrink: true }, validator: [value => value !== ''], errorText: 'Поле обязательно' },
    { field: 'dateConfirmEnd', type: 'text', label: 'Дата окончания подтверждения участия', textProps: { type: 'datetime-local' }, labelProps: { shrink: true }, validator: [value => value !== ''], errorText: 'Поле обязательно' },
    { field: 'dateOfferEnd', type: 'text', label: 'Дата окончания предложения', textProps: { type: 'datetime-local' }, labelProps: { shrink: true }, validator: [value => value !== ''], errorText: 'Поле обязательно' },
    { field: 'dateSummingEnd', type: 'text', label: 'Дата подведения итогов', textProps: { type: 'datetime-local' }, labelProps: { shrink: true }, validator: [value => value !== ''], errorText: 'Поле обязательно' },
    {
      field: 'ndsUse', type: 'autoArr', label: 'Учет НДС',
      textProps: { arr: [{ value: 'true', label: 'Учет' }, { value: 'false', label: 'Без учета' }] },
      change: text => { dispatch(actions.formChangeAsObj({ ndsUse: text.value, valueNdsUse: text }, { name: formName })) },
      validator: [value => !!value], errorText: 'Поле обязательно'
    },
    {
      field: 'accessKey', label: 'Ключ доступа', type: 'text',
    },
    {
      field: 'regions', label: 'Регионы', type: 'autoLoad',
      textProps: {
        path: getRegions.path,
        meta: {
          field: 'regions',
          value: 'id',
          labelFunc: (obj) => obj.name
        },
        selectProps: { isMulti: true }
      },
      change: text => { dispatch(actions.formChangeAsObj({ regions: text }, { name: formName })) },
    },
    {
      field: 'specialization', label: 'Специализация', type: 'autoLoad',
      textProps: {
        path: getSpecializations.path,
        meta: {
          field: 'specialization',
          value: 'id',
          labelFunc: (obj) => obj.name
        },
        selectProps: { isMulti: true }
      },
      change: text => { dispatch(actions.formChangeAsObj({ specialization: text }, { name: formName })) },
    }
    // contacts
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: { 
        name: values.name,
        idFacility: values.idFacility, 
        dateConfirmStart: values.dateConfirmStart, 
        dateConfirmEnd: values.dateConfirmEnd, 
        dateOfferEnd: values.dateOfferEnd, 
        dateSummingEnd: values.dateSummingEnd, 
        typeTender: values.typeTender, 
        ndsUse: values.ndsUse, 
        accessKey: values.accessKey, 
        regions: values.regions.map(e=>e.value), 
        specialization: values.specialization.map(e=>e.value), 
        contacts: values.allIds.map(key=>values.byId[key])
      },
      url: addTenderOur.path,
      method: addTenderOur.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd formName={formName} arr={arrProps} title='Добавить тендер' />
        </div>
        <Typography variant='h6'>Контакты для связи</Typography>
        <DocTable
          formName={formName}
          column={[
            { id: 'name', type: 'text', name: 'Имя' },
            { id: 'surname', type: 'text', name: 'Фамилия' },
            { id: 'patronymic', type: 'text', name: 'Отчество' },
            { id: 'email', type: 'text', name: 'Почта' },
            { id: 'phone', type: 'text', name: 'Телефон' },
            { id: 'jobTitle', type: 'text', name: 'Должность' },
            { id: 'additionalContacts', type: 'text', name: 'Доп. контакт' },
          ]}
          rowDefault={{
            name: '',
            surname: '',
            patronymic: '',
            email: '',
            phone: '',
            jobTitle: '',
            additionalContacts: '-',
          }}
        />
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}
