import template from "./template_crud";
export const getDeliveryDoc = template.getProjectId('/delivery/documents');
export const getDeliveryDocById = {
  path: (obj) => `/projects/${obj.id}/delivery/documents/${obj.idDoc}`,
  type: 'GET'
}
export const getDeliveryMaterial = template.getProjectId('/delivery/material');
const delivery = '/delivery';
export const addDelivery = template.addOne(delivery);
export const getDelivery = template.getList(delivery);
export const getDeliveryById = template.getById(delivery);
export const getDeliveryByIdPrint = template.getById(delivery, '/print');
export const deleteDelivery = template.deleteById(delivery);

const inputControl = '/delivery/input-control';
export const updInputControl = template.putProjectId(inputControl);
export const updInputControlCert =(id) => ({
  path: (obj) => `/projects/${id}${inputControl}/${obj.id}/cert`,
  type: 'PUT'
});
export const getInputControl = template.getProjectId(inputControl);
export const getInputControlPrint = template.getProjectId(inputControl+'/print');
export const getInputControlById = template.getProjectIdById(inputControl);