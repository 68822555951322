import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import * as api from '../../../api/list'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'
import { typeMachine } from '../../../enum/type-machine.enum'


export default function MachineAdd({ history }) {
  const dispatch = useDispatch()
  const formName = 'MachineAdd'
  useEffect(() => {
    dispatch(actions.formInitialize({ name: '', errorValidate: { name: false } }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [
    { bp: { md: 6, sm: 12 }, field: 'typeM', type: 'autoArr', label: 'Тип техники', textProps: { arr: typeMachine } },
    { bp: { md: 6, sm: 12 }, field: 'model', type: 'text', label: 'Модель' },
    { bp: { md: 6, sm: 12 }, field: 'govNumber', type: 'text', label: 'Гос. номер' },
    { bp: { md: 6, sm: 12 }, field: 'fuelConsumption', type: 'text', label: 'Норма потребления топлива', textProps: { type: 'number' } },
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        typeM: values.typeM,
        model: values.model,
        govNumber: values.govNumber,
        fuelConsumption: values.fuelConsumption
      },
      url: api.addMachine.path,
      method: api.addMachine.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Добавить технику'
            gridProps={{ xs: 12, xl: 9, lg: 10 }}
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}
