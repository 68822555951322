import React from 'react';
import PageList from '../../../components/PageListAPI'
import { getTemplateById, getTemplateFile, updateTemplateFile } from '../../../api/list'
import Button from '@material-ui/core/Button'
import { fetchDispath } from '../../../functions/fetch'
import { loadFileAPI } from '../../../reducers/materialLoad';
import { enqueueSnackbar } from '../../../reducers/notifier'
import { useDispatch } from 'react-redux';

export default function ReportTemplatesOne(props) {
  const dispatch = useDispatch()
  const handleClick = () => {
    const id = props.match.params.id
    dispatch(fetchDispath({
      param: {
        id: id,
      },
      progress: false,
      request: getTemplateFile,
      buff: true
    }))
      .then(res => {
        const filename = 'template.xlsx'
        res.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
        })
      })
  }
  const _handleImageChange = (e) => {
    e.preventDefault();
    const id = props.match.params.id
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    dispatch(loadFileAPI({
      file: e.target.files[0],
      path: updateTemplateFile.path({ id: id })
    }))
      .then(res => {
        let msg = {}
        if (res) {
          msg = {
            message: `success`,
            options: { variant: 'success' }
          }
        }
        else {
          msg = {
            message: `false`,
            options: { variant: 'error' }
          }
        }
        dispatch(enqueueSnackbar(msg))
      })
      .catch()
  }
  return (
    <div className='App-paper'>
      <Button color='primary' component="span" onClick={handleClick}>Скачать</Button>
      <input
        style={{ display: 'none' }}
        id="contained-button-file"
        type="file"
        onChange={_handleImageChange}
      />
      <label htmlFor="contained-button-file">
        <Button color='primary' component="span">Загузить</Button>
      </label>
      <PageList
        name='templatesreport'
        columns={[{ id: 'value', name: 'Значение' }, { id: 'label', name: 'Наименование' }]}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          param: {
            id: props.match.params.id
          },
          request: getTemplateById,
        }}
        csv={false}
        pagination={false}
        tableProps={{
          pagination: false
        }}
        click={false}
        {...props}
      />
    </div>
  )
}