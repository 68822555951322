import React from 'react'
import Grid from '@material-ui/core/Grid'
import check from '../../functions/can'
import CardMenu from './source/CardMenu'

function CartProps({ arr, idProject, name }) {
  const str = (typeof idProject !== 'undefined') ? `/projects/${idProject}` : ''
  const arrNew = arr.map(obj =>
    check(obj.check) && { to: str + obj.to, primary: obj.primary, disabled: obj.disabled, add: obj.add }
  ).filter(key => typeof key !== 'boolean')
  return arrNew.length > 0 && <Grid item><CardMenu arr={arrNew} name={name} /></Grid>
}

export default function EstimateMenu(props) {
  const idProject = props.match.params.idProject
  const materials = [
    { check: 'material:read', to: '/material', primary: 'Список материалов', add: true },
    { check: 'work:read', to: '/work', primary: 'Список работ', add: true },
    { check: 'material:read', to: '/estimate', primary: 'Смета ' },
  ]
  return <Grid container>
    <CartProps arr={materials} idProject={idProject} name='Списки' />
  </Grid>
}