import template from "./template_crud";
const worker = '/worker'
export const getWorkerList = template.getList(worker);
export const getWorkerListTool = template.getProjectId(worker + '/tool');
export const getWorkerReport = template.getById(worker, '/work/print');
export const getWorkerOne = template.getById(worker);
export const getWorkerTool = template.getById(worker, '/tool');
export const getWorkerOneWork = template.getById(worker, '/work');
export const deleteWorkerOne = template.deleteById(worker);
export const updateWorkerOne = template.updById(worker);
export const addWorker = template.addOne(worker);

export const updWorkerLogin = template.updById(worker, '/apps/login_details');
export const updWorkerChapters = template.updById(worker, '/apps/chapters');



// worker rating
const rating = '/worker/rating'
export const getWorkerRatingList = template.getList(rating);
export const getWorkerRatingById = template.getById(rating);
export const addWorkerRating = template.addOne(rating);
export const deleteWorkerRating = template.deleteById(rating);
// worker resource
const resource = '/resource'
export const getWorkerResourceList = ({
    path: (obj) => worker + '/' + obj.id + resource,
    type: 'GET'
});
export const addWorkerResource = ({
    path: (obj) => worker + '/' + obj.id + resource,
    type: 'POST'
});;
export const deleteWorkerResource = (idWorker) => ({
    path: (obj) => worker + '/' + idWorker + resource + '/' + obj.id,
    type: 'DELETE'
});;

// worker doc
const doc = '/doc'
export const getWorkerDocList = ({
    path: (obj) => worker + '/' + obj.id + doc,
    type: 'GET'
});
export const addWorkerDoc = ({
    path: (obj) => worker + '/' + obj.id + doc,
    type: 'POST'
});;
export const deleteWorkerDoc = (idWorker) => ({
    path: (obj) => worker + '/' + idWorker + doc + '/' + obj.id,
    type: 'DELETE'
});;