import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as actions from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'
import { getOurStorageById, updateOurStorage } from '../../../api/storage'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'

const formName = 'StorageAdd'
export default function StorageUpdate({ history, match }) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [
    { field: 'name', type: 'text', label: 'Название', validator: [value => value !== ''], errorText: 'Поле обязательно', },
    { field: 'address', type: 'text', label: 'Адрес' },
  ]
  useEffect(() => {
    dispatch(actions.formInitialize({ name: '', address: '', errorValidate: { name: false } }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idStorage,
      },
      progress: true,
      request: getOurStorageById,
    })).then(res => {
      dispatch(actions.formInitialize({ name: res.get.name, address: res.get.address }, { name: formName }))
    }).catch(err => console.log(err))
  }, [dispatch, match.params.idStorage])
  const handleOk = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: values,
      url: updateOurStorage.path({
        id: match.params.idStorage,
      }),
      method: updateOurStorage.type,
      history: history
    }))
  };
  return <div className='App-paper'>
    <form autoComplete="off" onSubmit={handleOk}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd formName={formName} arr={arrProps} title='Изменение склада' />
      </div>
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>
}