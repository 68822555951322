import { getProjectId, getList, addOne, postProjectId } from "./template_crud";
const docOcr = '/ocr/doc'
export const getOcrDoc = getProjectId(docOcr)
export const getOcrDocById = {
  path: (obj) => `/projects/${obj.id}${docOcr}/${obj.idDoc}`,
  type: 'GET'
}
export const editOcrDocById = {
  path: (obj) => `/projects/${obj.id}${docOcr}/${obj.idDoc}`,
  type: 'PUT'
}
const taskOcr = '/ocr/task'
export const getTack = getProjectId(taskOcr);
export const getTackCheck = getProjectId(taskOcr+'/check');
export const startTask = postProjectId(taskOcr);

const entera = '/user/entera'
export const setOcrSetting = addOne(entera)
export const egtOcrSetting = getList(entera)