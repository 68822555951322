import React, { useCallback } from 'react';
import { useDispatch, } from 'react-redux'

import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, } from '@material-ui/core';
import { fetchDispath } from '../../functions/fetch'
import { printKS2ById } from '../../api/formks2ks3'
import print from '../../functions/print'
import moment from 'moment'
import 'moment/locale/ru';

export default function FormDialog({ idDoc, idProject }) {
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState('');
  const dispatch = useDispatch()
  const handleClose = () => { setOpen(false); };
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        querty: {
          date: (date!=='')?moment(date).format('DD.MM.YYYY'):undefined
        },
        param: {
          id: idDoc
        },
        request: printKS2ById(idProject),
      }
    )
    setOpen(false);
  }
  return (
    <div>
      <Button  onClick={()=>{setOpen(true)}} color='primary'>Печать
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Параметры печати</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="Date"
            label="Дата составления"
            type="date"
            value={date}
            onChange={e=>setDate(e.target.value)}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={handlePrint}>Печать</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}