import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import * as api from '../../api/list'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import { getStorageUnit, addStorageUnit } from '../../api/list'


export default function WorkEstimateAdd({ history }) {
  const dispatch = useDispatch()
  const formName = 'WorkEstimateAdd'
  useEffect(() => {
    dispatch(actions.formInitialize({ name: '', errorValidate: { name: false } }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [
    { field: 'name', label: 'Наименование', type: 'text', validator: [value => value !== ''], errorText: 'Поле обязательно' },
    {
      field: 'parent', type: 'autoLoad', label: 'Папка',
      textProps: {
        path: api.loadWorkEstimate.path + '?folder=1',
        meta: {
          field: 'parent',
          value: 'id',
          labelFunc: (obj) => obj.name
        },
        selectProps: { isClearable: true },
      }
    },
    { field: 'count', type: 'text', label: 'Количество', textProps: { type: 'number', min: '0' }, validator: [value => value > 0], errorText: 'на не может быть меньше нуля' },
    {
      field: 'idStorageUnit', type: 'autoLoadCreate', label: 'Ед. изм.', textProps: {
        validator: [value => !!value], errorText: 'Поле обязательно',
        createAPI: addStorageUnit,
        createBody: 'name',
        path: getStorageUnit.path,
        meta: {
          field: 'storageUnit',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
    { field: 'price', label: 'Стоимость за ед', type: 'text', textProps: { type: 'number' } },
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        name: values.name,
        price: values.price,
        parent: values.parent,
        idStorageUnit: values.idStorageUnit,
        isFolder: false,
        count: values.count,
      },
      url: api.addWorkEstimate.path,
      method: api.addWorkEstimate.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Добавить работу'
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}
