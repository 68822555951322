import {loadState} from '../localStorage'
import {actionCreatorObj} from '../functions/redux'

const EDIT_USER = 'user/EDIT'
export const editUser=actionCreatorObj(EDIT_USER)

export const initialState = {
  auth:loadState(),
}
export default function reqUsers(state = initialState, action){
  switch (action.type){
    case EDIT_USER : return {...state, auth:action.payload}
    default : return state;
  }
}
export const role={
  admin:'Админ',
  estimate:'Сметчик',
  purchase:'Закупщик',
  customer:'Заказчик',
}