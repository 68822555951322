import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import { getMachineStateById, updMachineState } from '../../../api/list'
import { fetchDispath } from '../../../functions/fetch'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'
import { machineStatus } from '../../../enum/machine-status.enum'
// НЕ ДОДЕЛАНО 
export default function MachineStateEdit({ history, match }) {
  const formName = 'MachineEdit'
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(actions.formInitialize({
      model: '',
      typeM: '',
      govNumber: '',
      fuelConsumption: '',
      errorValidate: { name: false }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getMachineStateById(match.params.id),
      param: {
        id: match.params.idState
      }
    })).then(res => {
      dispatch(actions.formChangeAsObj({
        model: res.get.model,
        typeM: res.get.typeM,
        valuetypeM: { value: res.get.typeM, label: res.get.typeM },
        govNumber: res.get.govNumber,
        fuelConsumption: res.get.fuelConsumption,
      }, { name: formName }))
    }).catch(err => console.log(err))
  }, [dispatch, match.params.id])
  const arrProps = [
    { bp: { md: 12, sm: 12 }, field: 'typeState', type: 'autoArr', label: 'Статус', textProps: { arr: machineStatus } },
    { bp: { md: 6, sm: 12 }, field: 'startDate', type: 'text', label: 'Дата начала', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { bp: { md: 6, sm: 12 }, field: 'endDate', type: 'text', label: 'Дата окончания', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { bp: { md: 12, sm: 12 }, field: 'comment', type: 'text', label: 'Комментарий', textProps: { multiline: true, rows: "4" } },
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        typeM: values.typeM,
        model: values.model,
        govNumber: values.govNumber,
        fuelConsumption: values.fuelConsumption
      },
      url: updMachineState(match.params.id).path({ id: match.params.idState }),
      method: updMachineState(match.params.id).type,
      history: history
    }, formName))
  }
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Изменить технику'
            gridProps={{ xs: 12, xl: 9, lg: 10 }}
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Изменить</Button>
      </form>
    </div>
  );
}