import React, { useState, useEffect } from 'react'
import { Button, Tabs, Tab, Grid, IconButton } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDispath } from '../../functions/fetch'
import { addProjectWayBill, getProjectWayBillTaskOld } from '../../api/projects'
import SimpleAdd from '../../components/SimpleAdd'
import * as actions from '../../reducers/form';
import DocTable from '../../components/DocTable';
import AutocompliteWithLoad from '../../components/AutocompliteWithLoad'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const formNameWay = 'addWayBill'
const initialState = {
  typeTransportation: '',
  typeCommerce: '',
  typeConveyance: '',
  mById: {},
  mAllIds: [],
}
const column = [
  { id: 'order', type: 'text', name: 'в чье распоряжение' },
  { id: 'arrivalTime', type: 'time', name: 'время прибытия, ч. мин' },
  { id: 'loadingAddress', type: 'text', name: 'адрес погрузки' },
  { id: 'unloadingAddress', type: 'text', name: 'адрес разгрузки' },
  { id: 'cargoName', type: 'text', name: 'наименование груза' },
  { id: 'count', type: 'number', name: 'количество ездок' },
  { id: 'distance', type: 'number', name: 'расстояние, км' },
  { id: 'transported', type: 'number', name: 'перевезти, тонн' },
  { id: 'description', type: 'text', name: 'примечание' },
]
const columnSpec = [
  { id: 'order', type: 'text', name: 'в чье распоряжение' },
  { id: 'arrivalTime', type: 'time', name: 'время прибытия, ч. мин' },
  { id: 'typeWork', type: 'text', name: 'вид работы' },
  { id: 'description', type: 'text', name: 'примечание' },
]
export default function WayBillCreateForDialog({ idCalendar, idProject, date, handleAdd, idMachine, typeM }) {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize(initialState, { name: formNameWay }))
    return () => {
      dispatch(actions.formDestroy({ name: formNameWay }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formNameWay] || state.form.default)
  const { values } = form
  const handleAddWayBill = () => {
    const arr = []
    values.mAllIds.forEach(key => {
      arr.push(values.mById[key])
    })
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      body: {
        typeTransportation: values.typeTransportation,
        typeCommerce: values.typeCommerce,
        typeConveyance: values.typeConveyance,
        idCalendar: idCalendar,
        dateStart: date,
        orders: arr,
      },
      progress: true,
      request: addProjectWayBill,
    })).then(e => {
      handleAdd()
    })
  }
  const arrProps = [
    { field: 'typeTransportation', type: 'autoArr', label: 'Вид перевозки ', textProps: { arr: ['', 'коммерческие', 'для собственных нужд', 'работа спецтехники'].map(e => ({ value: e, label: e })) } },
    {
      field: 'typeCommerce', type: 'autoArr', label: 'Если коммерческое, то ',
      textProps: {
        arr: ['',
          'регулярная перевозка пассажиров и багажа',
          'перевозка грузов по договору перевозки грузов или договору фрахтования, в том числе по договору аренды транспорта с экипажем',
          'перевозка пассажиров и багажа по заказу',
          'перевозка пассажиров и багажа легковым такси',
          'организованная перевозка групп детей автобусами по договору фрахтования',
        ].map(e => ({ value: e, label: e }))
      }
    },
    { field: 'typeConveyance', type: 'autoArr', label: 'Вид сообщения', textProps: { arr: ['городское', 'пригородное', 'междугороднее'].map(e => ({ value: e, label: e })) } },
  ]
  const [valueTab, setValueTab] = useState(0)
  console.log(typeM)
  const col = typeM==='спец. автомобиль'?columnSpec:column
  return <div>
    <Button color="primary" onClick={() => setOpen(true)}>Оформить путевой лист</Button>
    {
      open && <div>
        <Tabs value={valueTab} onChange={(e, v) => setValueTab(v)} indicatorColor='primary'>
          <Tab key={0} label='Сведения о перевозке' />
          <Tab key={1} label='Задание водителю' />
        </Tabs>
        {valueTab === 0 && <SimpleAdd
          arr={arrProps}
          formName={formNameWay}
          gridProps={{ xs: 12 }}
        />}
        {valueTab === 1 && <div style={{ overflow: 'auto' }}>
          <AddOldTask idProject={idProject} idMachine={idMachine} formName={formNameWay} />
          <DocTable
            formName={formNameWay}
            byIdField='mById'
            allIdsField='mAllIds'
            column={col}
            rowDefault={{
              id: '',
              order: '',
              arrivalTime: '',
              loadingAddress: '',
              unloadingAddress: '',
              cargoName: '',
              count: '',
              distance: '',
              transported: '',
              typwWork:'',
              description:'',
            }}
          />
        </div>}
        <Button color="primary" onClick={handleAddWayBill}>Оформить</Button>
      </div>
    }
  </div>
}



export function AddOldTask({ idProject, idMachine, formName, createNew }) {
  const [selected, setSelected] = useState(null)
  const [idRow, setIdRow] = useState(0)
  const dispatch = useDispatch()
  const handleChange = (text) => {
    setSelected(text)
  }
  const addRow = () => {
    if (!selected) return
    const newIdRow = idRow + 1
    const newObj = {
      id: '',
      order: '',
      arrivalTime: '',
      loadingAddress: '',
      unloadingAddress: '',
      cargoName: '',
      count: '',
      distance: '',
      transported: '',
      ...selected,
    }
    dispatch(actions.formObjAdd(newObj, { field: 'mById', id: 'ids' + newIdRow, name: formName }))
    dispatch(actions.formArrayAdd('ids' + newIdRow, { field: 'mAllIds', name: formName }))
    setIdRow(newIdRow)
    if (createNew) {
      createNew(newObj,'ids' + newIdRow)
    }
  }
  return <Grid
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="flex-end"
  >
    <Grid item>
      <IconButton size='small' onClick={addRow}><ArrowDownwardIcon /></IconButton>
    </Grid>
    <Grid xs={11} item>
      <AutocompliteWithLoad
        classes={{}}
        field='task'
        name='Старые задачи'
        path={getProjectWayBillTaskOld.path({ id: idProject }) + '?idMachine=' + idMachine}
        meta={{
          field: 'task',
          value: 'id',
          labelFunc: (obj) => `${obj.order} ${obj.loadingAddress} ${obj.unloadingAddress} ${obj.cargoName} ${obj.transported || ''}т.`,
          hidden: ['order', 'loadingAddress', 'unloadingAddress', 'cargoName', 'count', 'distance', 'transported','typeWork']
        }}
        change={handleChange}
        form={false}
      />
    </Grid>
  </Grid>

}

