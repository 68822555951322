import React from 'react';
import can from '../../functions/can'
import * as api from '../../api/facility'
import PageList from '../../components/PageListAPI'

export default function FacilityList(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='facility'
        title='Список объектов'
        columns={[{ id: 'name', name: 'Название' }, { id: 'address', name: 'Адрес' }]}
        createItem={can('facility:create')}
        deleteItem={can('facility:delete')}
        idName='id'
        loadAPI={{
          request: api.getFacility
        }
        }
        deleteAPI={api.deleteFacility}
        {...props}
      />
    </div>
  )
}