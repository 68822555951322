import React from 'react';
import PageList from '../../../components/PageListAPI'
import can from '../../../functions/can'
import { getDocMoveProject, delDocMoveProjectById } from '../../../api/storage'

export default function MoveStorage(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='moveSotrage'
        title='Документы перемещения материалов на  центральный склад'
        columns={[
          { id: 'id', name: '№' },
          { id: 'date', name: 'Дата', type: 'date' },
          { id: 'user', name: 'Создал' },
          { id: 'storageSpace', name: 'Склад' },
        ]}
        createItem={can('storage:update')}
        deleteItem={can('storage:delete')}
        loadAPI={{
          param: {
            id: props.match.params.id
          },
          request: getDocMoveProject,
        }}
        deleteAPI={{
          path: delDocMoveProjectById(props.match.params.id).path,
          type: delDocMoveProjectById(props.match.params.id).type
        }}
        {...props}
      />
    </div>
  )
};


