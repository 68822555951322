/*
import template from "./template_crud";
template.getList()
template.addOne()
template.deleteById()
template.getById()
template.updById()
*/
export const getList = (path) => ({
  path: path,
  type: 'GET'
})
export const getById = (path, ends = '') => ({
  path: (obj) => `${path}/${obj.id}${ends}`,
  type: 'GET'
})
export const updById = (path, ends = '') => ({
  path: (obj) => `${path}/${obj.id}${ends}`,
  type: 'PUT'
})
export const addOne = (path) => ({
  path: path,
  type: 'POST'
})
export const deleteById = (path) => ({
  path: (obj) => `${path}/${obj.id}`,
  type: 'DELETE'
})
export const upd = (path) => ({
  path: path,
  type: 'PUT'
})
// alias
export const post = addOne;
export const get = getList;
export const getId = getById;

export const getProjectId = (path) => ({
  path: (obj) => `/projects/${obj.id}${path}`,
  type: 'GET'
})
export const getProjectIdById = (path)=> (id) => ({
  path: (obj) => `/projects/${id}${path}/${obj.id}`,
  type: 'GET'
})
export const postProjectId = (path) => ({
  path: (obj) => `/projects/${obj.id}${path}`,
  type: 'POST'
})
export const putProjectId = (path)=> (id) => ({
  path: (obj) => `/projects/${id}${path}/${obj.id}`,
  type: 'PUT'
})
export const deleteProjectById = (path)=> (id) => ({
  path: (obj) => `/projects/${id}${path}/${obj.id}`,
  type: 'DELETE'
})

const def = {
  getList,
  getById,
  updById,
  addOne,
  deleteById,
  post,
  get,
  getId,
  upd,
  getProjectId,
  postProjectId,
  deleteProjectById,
  getProjectIdById,
  putProjectId
}
export default def

export const all = (path) => ({
  getList: getList(path),
  getById: getById(path),
  updById: updById(path),
  addOne: addOne(path),
  deleteById: deleteById(path)
})