import React from 'react';
import PageList from '../../../components/PageListAPI'
import { getOurAllStorageMaterial, getOurStorage } from '../../../api/storage'

export default function StorageRemains(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='ourAllStorageMaterial'
        title='Остатаки на складах'
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'storageUnit', name: 'Ед. изм.' },
          { id: 'count', name: 'Количество' },
        ]}
        loadAPI={{
          request: getOurAllStorageMaterial,
        }}
        click={false}
        filterProps={[
          {
            type: 'autocomplite',
            path: getOurStorage.path,
            field: 'id',
            name: 'Склад',
          },
        ]}
        {...props}
      />
    </div>
  )
}

