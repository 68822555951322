import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/form';
import { getStorageUnit, addStorageUnit, typework } from '../../api/list'
import { addWork } from '../../api/work'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'

const initialState = {
  name: '',
  number: '',
  count: '',
  unit: '',
  price: '',
  errorValidate: {
    name: false,
    count: false,
    price: false,
    idStorageUnit: false,
    idType: false
  }
}
const formName = 'WorkAddOne'
export default function WorkAddOne({ history, match }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize(initialState, { name: formName }))
  }, [dispatch])
  const arrProps = [
    { field: 'name', type: 'text', label: 'Название', validator: [value => value !== ''], errorText: 'Поле обязательно', },
    { field: 'number', type: 'text', label: 'Номер' },
    { field: 'count', type: 'text', label: 'Количество', textProps: { type: 'number', min: '0' }, validator: [value => value > 0], errorText: 'на не может быть меньше нуля' },
    { field: 'price', type: 'text', label: 'Цена', textProps: { type: 'number' } },
    {
      field: 'idStorageUnit', type: 'autoLoadCreate', label: 'Ед. изм.', textProps: {
        validator: [value => !!value], errorText: 'Поле обязательно',
        createAPI: addStorageUnit,
        createBody: 'name',
        path: getStorageUnit.path,
        meta: {
          field: 'storageUnit',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
    {
      field: 'idType', type: 'autoLoadCreate', label: 'Тип', textProps: {
        createAPI: typework.addOne,
        createBody: 'name',
        path: typework.getList.path,
        meta: {
          field: 'typeWork',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    }
  ]
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleOk = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        name: values.name,
        number: values.number,
        count: values.count,
        idStorageUnit: values.idStorageUnit,
        price: values.price,
        idType: values.idType,
      },
      url: addWork.path({ id: match.params.idProject }),
      method: addWork.type,
      history: history
    }))
  };
  return <div className='App-paper'>
    <form autoComplete="off" onSubmit={handleOk}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd formName={formName} arr={arrProps} title='Создание работы' />
      </div>
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>
}