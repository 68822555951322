import React from 'react';
import PageList from '../../../components/PageListAPI'
import can from '../../../functions/can'
import * as api from '../../../api/list'

export default function Equipment(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='equipment'
        columns={[{ id: 'name', name: 'Наименование' }, { id: 'typeE', name: 'Тип' }, { id: 'storageUnit', name: 'ед.изм.' }]}
        createItem={can('material:create')}
        deleteItem={can('material:delete')}
        loadAPI={{
          request: api.getEquipment,
        }}
        title='Список материалов, оборудования'
        deleteAPI={api.deleteEquipment}
        filterProps={[
          {
            type: 'autocomplite',
            path: api.typequipment.getList.path,
            field: 'idType',
            name: 'Тип',
          },
        ]}
        {...props}
      />
    </div>
  )
};