import React from 'react';
import Button from '@material-ui/core/Button';
import { loadShipmenConsumablesById, deleteShipmentConsumables } from '../../../../api/shipment'
import PageList from '../../../../components/PageListAPI'
import Header from '../../../../components/ShipmentByIdHeader'
import getStatus from '../../../../functions/statusDoc'
import Bottom from '../../../../components/ShipmentByIdBottom'
import { printShipmentMaterialById } from '../../../../api/shipment'
import { makeStyles } from '@material-ui/core/styles';

const formName = 'shipmentConsumablesById'
const useStyles = makeStyles({
  row: {
    color: '#000000',
    '&$selected': {
      color: '#aaaaaa'
    }
  },
  selected: {},
});
const canDelete = (obj) => obj.delivery === obj.count
  const tableHead = [
    { id: 'name', name: 'Наименование' },
    { id: 'count', name: 'Количество' },
    { id: 'delivery', name: 'Поставлено' },
    { id: 'price', name: 'Цена' },
    { id: 'summ', name: 'Сумма' },
  ];
export default function ShipmentConsumablesById({ history, location, match }) {
  const classes = useStyles();
  return (
    <div className='App-paper'>
      <PageList
        name={formName}
        columns={tableHead}
        loadAPI={{
          request: loadShipmenConsumablesById,
          param: {
            id: match.params.idShipment
          },
          transform: (res => {
            let arr = []
            let summDoc = 0;
            res.get.forEach(el => {
              summDoc += el.count * el.price
              arr.push({ ...el, summ: el.count * el.price })
            });
            let obj = {
              statusRus: getStatus(res.doc.allDelivery),
              paidStatusRus: (res.doc.paidStatus === 'paid') ? 'Оплачено' : 'Не оплачено',
              paidStatus: res.doc.paidStatus,
              provider: res.doc.provider,
              projectName: res.doc.projectName,
              date: res.doc.date,
              allDelivery: res.doc.allDelivery,
              idProvider: res.doc.idProvider,
              user: res.doc.user,
              related: res.doc.related,
              summDoc
            }
            return {
              count: res.count,
              get: arr,
              obj
            }
          }),
        }}
        headerInit={{
          obj: {
            statusRus: getStatus(false),
            provider: '',
            projectName: '',
            date: new Date(),
            allDelivery: false,
            user: '',
            related: false
          }
        }}
        tableProps={{
          pagination: false,
          classes: classes,
          selected: canDelete
        }}
        pagination={false}
        click={false}
        headerLink={<Header
          name={formName}
          printRequest={printShipmentMaterialById}
          typeDelivery='purchaseequip'
          deleteAPI={deleteShipmentConsumables}
          {...{ history, location, match }}
        />}
        csv={false}
        history={history}
        location={location}
        match={match}
        deleteItem={false}
        goBack={false}
      />
      <p />
      <Bottom name={formName} />
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
    </div>
  )
}



