import React, { useState, useEffect } from 'react';
import { read, utils } from 'xlsx';

import CsvViewer from './CsvViewer';


export default function XlsxViewer(props) {
  const [sheets, setSheets] = useState([])
  const [names, setNames] = useState([])
  const [curSheetIndex, setCurIndex] = useState(0)
  useEffect(() => {
    console.log('upd')
    const dataArr = new Uint8Array(props.data);
    const arr = [];

    for (let i = 0; i !== dataArr.length; i += 1) {
      arr.push(String.fromCharCode(dataArr[i]));
    }
    const workbook = read(arr.join(''), { type: 'binary' });
    const names = Object.keys(workbook.Sheets);
    const sheets = names.map(name => (
      utils.sheet_to_csv(workbook.Sheets[name])
    ));
    setSheets(sheets)
    setNames(names)
  }, [props.data])
  const [csvProps, setCSVProps] = useState({})
  useEffect(() => {
    if (sheets.length === 0) return;
    const sheet = sheets[curSheetIndex]
    const csvProps = Object.assign({}, props, { data: sheet });
    setCSVProps(csvProps)
  }, [curSheetIndex, sheets, props])
  return (
    <div className="spreadsheet-viewer" style={{
      border: '1px solid black',
      direction: 'ltr'
    }}>
      <div className="sheet-names">
        {names.map((name, index) => (
          <input
            key={name}
            type="button"
            value={name}
            onClick={() => {
              setCurIndex(index)
            }}
          />
        ))}
      </div>
      {csvProps.data && <CsvViewer {...csvProps} />}
    </div>
  );
}
