import React from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell, IconButton, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete';
import { formObjChange } from '../../../../reducers/form';

export default function SetMaterialTab({ formName }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const { mById = {}, mAllIds = [] } = values
  return <div>
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 10 }} padding='none'>
          </TableCell>
          <TableCell style={{ width: '20%' }}>Раздел</TableCell>
          <TableCell style={{ width: '30%' }}>Материал</TableCell>
          <TableCell style={{ width: '10%' }}>Количество по плану</TableCell>
          <TableCell style={{ width: '10%' }}>Количество по факту</TableCell>
          <TableCell style={{ width: '10%' }}>Склад</TableCell>
          <TableCell>Ед. изм</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {mAllIds.map(key => {
          return <Row key={key} id={key} obj={mById[key]} formName={formName} />
        })}
        <TableRow style={{ height: 40 * 5 }}>
          <TableCell colSpan={8} />
        </TableRow>
      </TableBody>
    </Table>
  </div>
}

function Row({ id, obj, formName }) {
  const dispatch = useDispatch()
  const deleteRow = () => {
    dispatch(formObjChange(!obj.deleted, { id: id, field: 'mById', fieldObj: 'deleted', name: formName }))
  }
  return <TableRow style={{ background: (obj.deleted) ? '#f3bd48' : undefined }}>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <IconButton aria-label="Delete" onClick={deleteRow}  ><DeleteIcon /></IconButton>
    </TableCell>
    <TableCell>{obj.groupName}</TableCell>
    <TableCell>{obj.name}</TableCell>
    <TableCell>{obj.count}</TableCell>
    <TableCell>
      <TextField
        type='number'
        onChange={(e) => dispatch(formObjChange(e.target.value, { id: id, field: 'mById', fieldObj: 'countFact', name: formName }))}
        value={obj.countFact || 0}
        error={obj.countFact>obj.storage}
        helperText={obj.countFact>obj.storage ? 'Больше чем на складе' : ''}
      />
    </TableCell>
    <TableCell>{obj.storage}</TableCell>
    <TableCell>{obj.storageUnit}</TableCell>
  </TableRow>
}