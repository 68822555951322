import { actionCreatorObjMeta, actionCreatorEmptyMeta } from '../functions/redux'
import { errorDispath, fetchGenerate, successfetch, successFalsefetch } from '../functions/fetch'
const MAIN = 'form/'
const FORM_CHANGE = MAIN+'CHANGE'
const FORM_CHANGE_AS_OBJ = MAIN+'CHANGE_AS_OBJ'
const FORM_ARRAY_ADD = MAIN+'ARRAY_ADD'
const FORM_ARRAY_ADD_MANY = MAIN+'ARRAY_ADD_MANY'
const FORM_ARRAY_ADD_MANY_UNIQUE = MAIN+'ARRAY_ADD_MANY_UNIQUE'
const FORM_ARRAY_DELETE = MAIN+'ARRAY_DELETE'
const FORM_ARRAY_DELETE_MANY = MAIN+'ARRAY_DELETE_MANY'
const FORM_OBJ_ADD = MAIN+'OBJ_ADD'
const FORM_OBJ_ADD_MANY = MAIN+'OBJ_ADD_MANY'

const FORM_OBJ_DELETE = MAIN+'OBJ_DELETE'
const FORM_OBJ_CHANGE = MAIN+'OBJ_CHANGE'
const FORM_OBJ_CHANGE_OBJ = MAIN+'OBJ_CHANGE_OBJ'
const FORM_DESTROY = MAIN+'DESTROY'
const FORM_INITIALIZE = MAIN+'INITIALIZE'
const FORM_START_LOADING = MAIN+'START_LOADING'
const FORM_STOP_LOADING = MAIN+'STOP_LOADING'

export const formChange = actionCreatorObjMeta(FORM_CHANGE)
export const formChangeAsObj = actionCreatorObjMeta(FORM_CHANGE_AS_OBJ)
export const formDestroy = actionCreatorEmptyMeta(FORM_DESTROY)
export const formInitialize = actionCreatorObjMeta(FORM_INITIALIZE)
export const fromStartLoading = actionCreatorEmptyMeta(FORM_START_LOADING)
export const fromStopLoading = actionCreatorEmptyMeta(FORM_STOP_LOADING)
export const formArrayAdd = actionCreatorObjMeta(FORM_ARRAY_ADD)
export const formArrayAddMany = actionCreatorObjMeta(FORM_ARRAY_ADD_MANY)
export const formArrayAddManyUnique = actionCreatorObjMeta(FORM_ARRAY_ADD_MANY_UNIQUE)
export const formArrayDelete = actionCreatorObjMeta(FORM_ARRAY_DELETE)
export const formArrayDeleteMany = actionCreatorObjMeta(FORM_ARRAY_DELETE_MANY)
export const formObjAdd = actionCreatorObjMeta(FORM_OBJ_ADD)
export const formObjAddMany = actionCreatorObjMeta(FORM_OBJ_ADD_MANY)
export const formObjDelete = actionCreatorEmptyMeta(FORM_OBJ_DELETE)
export const formObjChange = actionCreatorObjMeta(FORM_OBJ_CHANGE)
export const formObjChangeObj = actionCreatorObjMeta(FORM_OBJ_CHANGE_OBJ)

export function formSubmitAPI({ props, url, method, history, success = undefined, endFunc, endFuncFalse }, name) {
  return dispatch => {
    dispatch(fromStartLoading({ name }))
    fetchGenerate(url, method, props, dispatch)
      .then(res => {
        if (res.success) {
          successfetch(dispatch, false)
          if (history) history.goBack()
          if (success) dispatch(success)
          if (endFunc) endFunc(res)
      }
      else {
          successFalsefetch(dispatch, false)
          if (endFuncFalse) endFuncFalse(res)
        }
        dispatch(fromStopLoading({ name }))
      })
      .catch(errorDispath(dispatch, true))
  }
}
/*
  meta:{
    fieldObj: 'name',
    field: 'name',
    id:0,
    name: 'name'
  }
*/
export const initialState = {
  default: {
    values: {},
    initial: {},
    loading: false
  }
}
export default function redRouter(state = initialState, action) {
  switch (action.type) {
    case FORM_CHANGE: return changeForm(state, action.payload, action.meta)
    case FORM_CHANGE_AS_OBJ: return changeFormAsObj(state, action.payload, action.meta)
    case FORM_ARRAY_ADD: return arrayAddMany(state, [action.payload], action.meta)
    case FORM_ARRAY_ADD_MANY: return arrayAddMany(state, action.payload, action.meta)
    case FORM_ARRAY_ADD_MANY_UNIQUE: return arrayAddManyUnique(state, action.payload, action.meta)
    case FORM_ARRAY_DELETE: return arrayDelete(state, action.payload, action.meta)
    case FORM_ARRAY_DELETE_MANY: return arrayDeleteMany(state, action.payload, action.meta)
    case FORM_OBJ_ADD: return objAdd(state, action.payload, action.meta)
    case FORM_OBJ_ADD_MANY: return objAddMany(state, action.payload, action.meta)
    case FORM_OBJ_DELETE: return objDelete(state, action.meta)
    case FORM_OBJ_CHANGE: return objChange(state, action.payload, action.meta)
    case FORM_OBJ_CHANGE_OBJ: return objChangeObj(state, action.payload, action.meta)
    case FORM_DESTROY: return formDestroyState(state, action.meta.name)
    case FORM_INITIALIZE: return initializeForm(state, action.payload, action.meta.name)
    case FORM_START_LOADING: return loading(state, true, action.meta.name)
    case FORM_STOP_LOADING: return loading(state, false, action.meta.name)
    default: return state;
  }
}

function loading(state, loading, name = 'default') {
  return {
    ...state,
    [name]: {
      ...state[name],
      loading: loading
    }
  }
}

function formDestroyState(state, name = 'default') {
  const { [name]: value, ...second } = state
  return second
}

function arrayAddMany(state, payload, { name = 'default', field, end = false }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        [field]: (end) ? [...state[name].values[field], ...payload] : [...payload, ...state[name].values[field]]
      }
    }
  }
}
function arrayAddManyUnique(state, payload, { name = 'default', field, end = false }) {
  let newArr = []
  if(end){
    newArr = [...new Set([...state[name].values[field], ...payload])]
  }
  else{
    newArr = [...new Set([...payload, ...state[name].values[field]])]
  }
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        [field]: newArr
      }
    }
  }
}
function arrayDelete(state, payload, { name = 'default', field }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        [field]: state[name].values[field].filter(key => key !== payload)
      }
    }

  }
}
function arrayDeleteMany(state, payload, { name = 'default', field }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        [field]: state[name].values[field].filter(key => !payload.includes(key))
      }
    }
  }
}
function objAdd(state, payload, { name = 'default', field, id }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        [field]: {
          ...state[name].values[field],
          [id]: payload
        }
      }
    }
  }
}
function objAddMany(state, payload, { name = 'default', field }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        [field]: {
          ...state[name].values[field],
          ...payload
        }
      }
    }
  }
}
function objDelete(state, { name = 'default', field, id }) {
  const { [id]: value, ...second } = state[name].values[field]
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        [field]: second
      }
    }
  }
}
function objChange(state, payload, { name = 'default', field, id, fieldObj }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        [field]: {
          ...state[name].values[field],
          [id]: {
            ...state[name].values[field][id],
            [fieldObj]: payload
          }
        }
      }
    }
  }
}
function objChangeObj(state, payload, { name = 'default', field, id }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        [field]: {
          ...state[name].values[field],
          [id]: {
            ...state[name].values[field][id],
            ...payload
          }
        }
      }
    }
  }
}
function changeForm(state, payload, { name = 'default', field }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        [field]: payload
      }
    }
  }
}
function changeFormAsObj(state, payload, { name = 'default' }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      values: {
        ...state[name].values,
        ...payload
      }
    }
  }
}
function initializeForm(state, payload, name = 'default') {
  return {
    ...state,
    [name]: {
      values: { ...payload },
      initial: { ...payload },
      loading: false
    }
  }
}