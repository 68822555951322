import React from 'react';
import { getOrdersMy, deleteOrdersMyById } from '../../../api/orders'
import PageList from '../../../components/PageListAPI'


const getColorStatus = (status) => {
  switch (status) {
    case 'ordered': return '#39cc39'
    case 'reject': return '#C51010'
    case 'not': return undefined
    default: return undefined
  }
}

export default function OrdersMy(props) {
  const head = [
    { id: 'date', name: 'Дата заявки', type: 'date', formatDate: 'LL' },
    { id: 'dateDelivery', name: 'Дата поставки', type: 'date', formatDate: 'LL' },
    { id: 'name', name: 'Наименование', },
    { id: 'count', name: 'Количество', },
    { id: 'storageUnit', name: 'Ед. изм.', },
    {
      id: 'statusRus', name: 'Статус', type: 'component',
      component: ({ obj }) => <div style={{ color: getColorStatus(obj.status) }}>{obj.statusRus}</div>
    },
  ].filter(key => key)
  return (
    <div className='App-paper'>
      <PageList
        name='ordersMy'
        title='Мои заказы материалов'
        columns={head}
        createItem={true}
        deleteItem={true}
        idName='id'
        loadAPI={{
          param: {
            id: props.match.params.idProject
          },
          request: getOrdersMy,
          transform: (res => {
            const statusEnum = {
              ordered: 'Заказано',
              reject: 'Отклонено',
              not: 'В обработке'
            };
            return {
              count: res.count,
              get: res.get.map(obj => {
                return { ...obj, statusRus: statusEnum[obj.status] }
              })
            }
          }),
        }
        }
        deleteAPI={deleteOrdersMyById(props.match.params.idProject)}
        disabledDelete={(obj) => obj.status !== 'not'}
        tableProps={{
          order: {
            order: 'desc',
            orderBy: 'date'
          }
        }}
        click={false}
        {...props}
      />
    </div>
  )
}