import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TableForm from './source/TableAn'
import { fetchDispath } from '../../functions/fetch'
import { getAnalyticsProject, getAnalyticsMaterial } from '../../api/analytics'
import AnalitycProps from '../../components/GridProps'
import { Grid, Typography, } from '@material-ui/core'
import * as actions2 from '../../reducers/formTable';
import BarAnProject from './source/BarAnProject';
import * as actions from '../../reducers/form';
import moment from 'moment'
import Percent from './source/Percent'

const formName = 'AnalitycProject'
export default function AnalitycProject({ match }) {
  const dispatch = useDispatch()
  const [analityc, setAnalityc] = useState({
    costsAll: 0,
    costsEstimate: 0,
    costsMatPercent: 0,
    costsShipment: 0,
    orderMatPercent: 0,
    workPercent: 0,
    debit: 0
  })
  useEffect(() => {
    dispatch(actions2.initFormTable({ name: formName }))
    dispatch(actions.formInitialize({
      line: [],
      planArr: [],
      factArr: []
    }, { name: formName }))
    return () => {
      dispatch(actions2.clearFormTable(formName))
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getAnalyticsProject,
      param: { id: match.params.idProject },
    })).then(res => {
      if (res.success) {
        setAnalityc({
          costsShipment: Math.round(res.get.costsShipment),
          costsEstimate: Math.round(res.get.costsEstimate),
          costsAll: Math.round(res.get.costsAll),
          orderMatPercent: res.get.orderMatPercent,
          costsMatPercent: res.get.costsMatPercent,
          workPercent: res.get.workPercent,
          debit: res.get.debit
        })
      }
    }).catch(err => console.log(err))
    dispatch(fetchDispath({
      progress: true,
      request: getAnalyticsMaterial,
      param: { id: match.params.idProject },
    })).then(res => {
      if (res.success) {
        dispatch(actions2.loadFormTable({ get: res.get, count: res.get.length }, { name: formName, id: 'id' }))
        const line = []
        const plan = []
        const fact = []
        res.planFact.forEach(el => {
          plan.push(Math.round(el.plan))
          fact.push(Math.round(el.fact))
          line.push(moment(el.date).format('MMMM YYYY'))
        });
        console.log(line)
        dispatch(actions.formChangeAsObj({
          line: line,
          planArr: plan,
          factArr: fact,
        }, { name: formName }))
      }
    }).catch(err => console.log(err))

  }, [dispatch, match.params.idProject])
  const getStringPrice = (price, avg) => {
    if (!price || !avg) return '';
    const arrow = (price < avg) ? <React.Fragment>&#x2191;</React.Fragment> : <React.Fragment>&#8595;</React.Fragment>;
    const diff = 100 - Math.round(((price > avg) ? avg / price : price / avg) * 100)
    return <Typography style={{ color: (price > avg) ? '#3bcf00' : '#ff0000' }}>
      {(Math.round(avg * 100) / 100).toLocaleString()} ({diff}{arrow})
    </Typography>
  }
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const rowShipmentCost = (value, obj) => {
    let color
    let percent
    let arrow
    if (Number(value) === +Number(obj.estimateCost)) {
      return <Typography >{Number(value).toLocaleString()}</Typography>
    }
    if (Number(value) < +Number(obj.estimateCost)) {
      color = '#3bcf00'
      percent = Math.round(100 * (100 - 100 * value / obj.estimateCost)) / 100
      arrow = <>&#8595;</>
    }
    else {
      color = '#ff0000'
      percent = Math.round(100 * (100 - 100 * obj.estimateCost / value)) / 100
      arrow = <>&#x2191;</>
    }
    return <Typography style={{ color }}>{Number(value).toLocaleString()} ({arrow}{percent}%)</Typography>
  }
  return <div className='App-paper'>
    <div>
      <Typography><b>Общая информация</b> </Typography>
      <Grid item container xs={6}>
        <AnalitycProps
          name='Всего по смете:'
          value={(Math.round(analityc.costsAll * 100) / 100).toLocaleString()}
        />
        <Grid item xs={12}><Typography>Текущие затраты</Typography></Grid>
        <AnalitycProps
          name='&nbsp;&nbsp;&nbsp;по плану:'
          value={(Math.round(analityc.costsEstimate * 100) / 100).toLocaleString()}
        />
        <AnalitycProps
          name='&nbsp;&nbsp;&nbsp;по факту:'
          value={getStringPrice(analityc.costsEstimate, analityc.costsShipment)}
        />
        <AnalitycProps
          name='Долг перед поставщиками:'
          value={(Math.round(analityc.debit * 100) / 100).toLocaleString()}
        />
      </Grid>
    </div>
    <Grid container>
      <Grid item>
        <Typography><b>Выполнение работ</b> </Typography>
        <Percent
          orderMatPercent={analityc.orderMatPercent}
          costsMatPercent={analityc.costsMatPercent}
          width={400}
          height={400}
        />
      </Grid>
      <Grid item>
        <div style={{ height: 400 }}>
          <Typography><b>Затраты на материалы</b> </Typography>
          <BarAnProject
            width={400}
            height={400}
            formName={formName}
          />
        </div>
      </Grid>
    </Grid>
    <div>
      <Typography><b>Список материалов</b></Typography>
      <TableForm
        tableHead={[
          { id: 'name', name: 'Наименование', },
          { id: 'storageUnit', name: ' Ед. изм.', },
          { id: 'shipmentCost', name: 'Потрачено', component: rowShipmentCost },
          { id: 'estimateCost', name: 'По плану', func: (value) => Number(value).toLocaleString() },
          { id: 'shipmentCount', name: 'Количество закуплено', },
          { id: 'estimateCount', name: 'Общее количество', },
        ]}
        tableData={formTable}
      />
    </div>
  </div>
}