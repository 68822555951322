import React, { useState } from 'react';
import { deleteMaterials, getMaterialsById } from '../../../api/receipt'
import PageList from '../../../components/PageListAPI'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import DeleteButton from '../../../components/DeleteButtonDoc'
import moment from 'moment';
const columns = [
  { id: 'name', name: 'Наименование', },
  { id: 'count', name: 'Количество', },
  { id: 'delivery', name: 'Поставлено', },
  { id: 'storageUnit', name: 'Ед. изм ', },
]
const formName = 'receiptMaterialsProject'
export default function ReceiptMaterialOne(props) {
  return (
    <div className='App-paper'>
      <PageList
        title='Документ давальческих материалов'
        name={formName}
        columns={columns}
        createItem={false}
        deleteItem={false}
        idName='id'
        history={props.history}
        match={props.match}
        location={props.location}
        loadAPI={{
          param: {
            id: props.match.params.idDoc
          },
          request: getMaterialsById,
          transform: (res) => {
            return {
              ...res, get: res.get.map(e => ({
                ...e,
                count: e.estimate ? e.estimate.count : e.count,
                delivery: e.estimate ? e.estimate.delivery : e.delivery,
                storageUnit: e.estimate ? e.estimate.storageUnit : e.storageUnit,
              }))
            }
          }
        }}
        headerInit={{
          sortBy: 'date',
          sortDirection: 'desc',
          obj: {
            name: '',
            date: new Date(),
            related: false
          }
        }}
        tableProps={{
          pagination: false,
        }}
        pagination={false}
        headerLink={<Header
          formName={formName}
          idDoc={props.match.params.idDoc}
          history={props.history}
        />}
        csv={false}
        click={false}
      />
    </div>
  )
}

function Header({ formName, idDoc, history }) {
  const date = useSelector(state => state.formTable[formName]?.obj?.date || new Date())
  const name = useSelector(state => state.formTable[formName]?.obj?.name || '')
  const related = useSelector(state => state.formTable[formName]?.obj?.related || false)
  return <div>
    <DeleteButton
      isDisabled={related}
      deleteAPI={deleteMaterials}
      idDoc={idDoc}
      history={history}
    />
    <Grid
      item
      container
      xs={12}
    >
      <GridProps name='Дата создания' value={moment(date).format('LLL')} />
      <GridProps name='Наименование' value={name} />
    </Grid>
  </div>
}

function GridProps({ name = '', value = '', xsStart = 6, xsEnd = 6, typographyProps1 = {} }) {
  return <React.Fragment>
    <Grid item xs={xsStart}><Typography {...typographyProps1}>{name}</Typography></Grid>
    <Grid item xs={xsEnd}>{(typeof value === 'object') ? value : <Typography>{value}</Typography>}</Grid>
  </React.Fragment>
}