import React from 'react'
import Grid from '@material-ui/core/Grid'
// import check from '../../functions/can'
import CardMenu from './source/CardMenu'

function CartProps({ arr, idFacility, name }) {
  const str = (typeof idFacility !== 'undefined') ? `/facility/${idFacility}` : ''
  const arrNew = arr.map(obj =>
    ({ to: (typeof obj.str !== 'undefined') ? obj.str + obj.to : str + obj.to, primary: obj.primary, disabled: obj.disabled })
  )
  return arrNew.length > 0 && <Grid item><CardMenu arr={arrNew} name={name} /></Grid>
}

function FacilityMenu(props) {
  const idFacility = props.match.params.id
  const settings = [
    { to: '/edit', primary: 'Изменить параметры' },
    { to: '/add', primary: 'Добавить проект' }, //  can('project:create')
    { to: '/access', primary: 'Доступ пользователей' }, //  can('project:create')
  ]
  const docs = [
    { to: '/form/ks3', primary: 'КС3' },
    { to: '/files', primary: 'Файлы' },
  ]
  const directory = [
    { to: '/responsible', primary: 'Представители ответственных лиц' },
    { to: '/machine', primary: 'Техника' },
  ]
  return <Grid container>
    <CartProps arr={settings} idFacility={idFacility} name='Настройки' />
    <CartProps arr={docs} idFacility={idFacility} name='Документы' />
    <CartProps arr={directory} idFacility={idFacility} name='Справочники' />
  </Grid>
}

export default FacilityMenu;