import React from 'react';
import { getTemplate } from '../../../api/list'
import PageList from '../../../components/PageListAPI'

export default function ReportTemplates(props) {
  return <div className='App-paper'>
    <PageList
      name='provider'
      columns={[{ id: 'name', name: 'Название' }]}
      createItem={false}
      deleteItem={false}
      loadAPI={{
        request: getTemplate,
      }}
      csv={false}
      pagination={false}
      tableProps={{
        pagination: false
      }}
      {...props}
    />
  </div>
}