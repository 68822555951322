import React, { useState } from 'react';
import { ListItemText, ListItem, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export default function ListItemLink({ primary, to, disabled, classes, add = false }) {
  const history = useHistory();
  const [visible, setVisible] = useState(false)
  const renderLink = React.forwardRef((props, ref) => (<Link ref={ref} to={to} {...props} />))
  const handleClick = () => {
    history.push(to + '/add')
  }
  const handleMouseOut = () => { setVisible(false) }
  const handleMouseOver = () => { setVisible(true) }
  return (
    < >
      <ListItem
        button
        component={renderLink}
        className={classes}
        disabled={typeof disabled !== 'undefined'}
        onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}
      >
        <ListItemText primary={primary} />
        {(add && visible) && <ListItemSecondaryAction onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
          <IconButton edge="end" onClick={handleClick}>
            <AddCircleOutlineIcon color='primary'/>
          </IconButton>
        </ListItemSecondaryAction>}
      </ListItem>
    </>
  );
}