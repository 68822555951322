import React from 'react';
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import 'moment/locale/ru';
import { getOurStorageMoveProjectById } from '../../../../api/storage'
import PageList from '../../../../components/PageListAPI'

const formName = 'oneMoveProject'
export default function OneMoveProject(props) {
  return <div className='App-paper'>
    <PageList
      name={formName}
      title='Документ перемещеня на проект'
      columns={[
        { id: 'nameProject', name: 'Материал' },
        { id: 'count', name: 'Количество' },
        { id: 'delivery', name: 'Поставлено' },
        { id: 'storageUnitProject', name: 'Ед. изм.' },
      ]}
      loadAPI={{
        param: {
          id: props.match.params.idDoc,
        },
        request: getOurStorageMoveProjectById,
        transform: (res => {
          return {
            count: res.get.length,
            get: res.get,
            obj: {
              ...res.doc,
              statusRus: res.doc.status === 'delivered' ? 'Доставлено' : 'Заказано'
            }
          }
        })
      }}
      headerLink={<HeaderDoc formName={formName} />}
      click={false}
      pagination={false}
      tableProps={{
        pagination: false
      }}
      {...props}
    />
  </div>
}

const initDefault = {
  obj: {
    storageSpace: '',
    projectName: '',
    date: new Date(),
    statusRus: '',
  }
}
function HeaderDoc({ formName }) {
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { obj } = formTable || initDefault.obj
  return <div>
    <Typography variant="body1" gutterBottom>
      Склад: {obj.storageSpace}
    </Typography>
    <Typography variant="body1" gutterBottom>
      Проект: {obj.projectName}
    </Typography>
    <Typography variant="body1" gutterBottom>
      Дата: {moment(obj.date).format('LLL')}
    </Typography>
    <Typography variant="body1" gutterBottom>
      Статус: {obj.statusRus}
    </Typography>
  </div>
}