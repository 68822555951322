export function actionCreatorObj(type) {
	return obj=> ( { type, payload:obj })
}
export function actionCreatorObjMeta(type) {
	return (payload,meta)=> ( { type, payload,meta })
}

export function actionCreatorEmpty(type) {
	return ()=> ( { type })
}
export function actionCreatorEmptyMeta(type) {
	return (meta)=> ( { type, meta })
}

export function editObj(state,obj){
  return {...state,
		byId: {
				...state.byId,
				[obj.id]:{
					...state.byId[obj.id],
					...obj.value
				}
		},
	}
}
export function editName(state,{name,obj}){
	return {...state,
		[name]:{
			...state[name],
			...obj
		}
	}
}
export function edit(state,obj){
	return {...state,
		...obj
	}
}
export function deleteObj(state,id){
	const {[id]:value, ...second} = state.byId
	return {...state,
		byId: second,
		allIds:state.allIds.filter( (item) => item !== id)
	}
}

export function addObj(state,obj,id){
	const idNew = 'id'+id
	return {...state,
		byId: {
				...state.byId,
				[idNew]:obj
		},
		allIds:[ idNew,...state.allIds]
	}
}
export function addArr(state,type,obj,push=true,unic=true){
	if(state[type].inludes(obj)) return state
	if (push)
	return {...state,
		[type]:[...state.allIds, obj]
	}
	else 
	return {...state,
		[type]:[ obj,...state.allIds]
	}
}

export function load(state,obj,idName){
	let byId = {}
	let allIds = obj.map(row => {
			let id = 'id'+row[idName]
			byId[id]=row;
			return id;
	})
	return({...state,reload:false, allIds:allIds, byId:byId})
}