import React, { useState, useCallback } from 'react'
import { Table, TableBody, TableHead, TableRow, TableCell, Checkbox, Button, TableSortLabel, TextField, TableContainer } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { formSubmitAPI } from '../../reducers/form';
import { addToHistoryW, addToHistoryM } from '../../reducers/history';
import { deleteEstimate, estimatePrint } from '../../api/estimate'
import { editFieldTable } from '../../reducers/formTable';
import { updEstimateMaterialNumber, updEstimateWorkNumber } from '../../api/estimate'
import print from '../../functions/print'
import { fetchDispath } from '../../functions/fetch'


export default function GroupTable({ tableHead, idProject, loadEstimate, loadGroup, formName }) {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('number')
  const [selected, setSelected] = useState([])
  const dispatch = useDispatch()
  const tableGroup = useSelector(state => state.formTable['groupEstimate'] || state.formTable.default)
  const tableResource = useSelector(state => state.formTable['groupResEstimate'] || state.formTable.default)
  const changeSelect = (id, isSelected) => {
    if (isSelected) {
      setSelected([id, ...selected])
    }
    else {
      setSelected(selected.filter(key => key !== id))
    }
  }
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleClick = () => {
    const arrMaterial = []
    const arrWork = []
    selected.forEach(e => {
      const obj = tableResource.byId[e]
      if (obj.type === 'material') {
        arrMaterial.push(obj.id)
      }
      else {
        arrWork.push(obj.id)
      }
    })
    dispatch(formSubmitAPI({
      props: {
        material: arrMaterial,
        work: arrWork
      },
      url: deleteEstimate.path({ id: idProject }),
      method: deleteEstimate.type,
      endFunc: (res) => {
        loadEstimate()
        loadGroup()
        setSelected([])
      }
    }, formName))
  }
  const handleClickHistory = () => {
    const arrMaterial = []
    const arrWork = []
    const arrGroup = Object.values(tableGroup.byId)
    selected.forEach(e => {
      const obj = tableResource.byId[e]
      const gr = arrGroup.find(e => e.id === obj.idGroup)
      if (obj.type === 'material') {
        arrMaterial.push({ ...obj, groupName: gr.name })
      }
      else {
        arrWork.push({ ...obj, groupName: gr.name })
      }
    })
    dispatch(addToHistoryW(arrWork, { idProject }))
    dispatch(addToHistoryM(arrMaterial, { idProject }))
  }
  const handleRequestSort = (event, property) => {
    let orderNew = 'desc';
    if (orderBy === property && order === 'desc') {
      orderNew = 'asc';
    }
    setOrder(orderNew)
    setOrderBy(property)
  };
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        querty: {
          orderBy,
          order
        },
        param: {
          id: idProject
        },
        request: estimatePrint,
      }
    )
  }
  const buttonDisabled = selected.length === 0
  return (<React.Fragment>
    <Button disabled={buttonDisabled} color="primary" onClick={handleClick}>Удалить из группы</Button>
    <Button disabled={buttonDisabled} color="primary" onClick={handleClickHistory}>Добавить в историю</Button>
    <Button color="primary" onClick={() => handlePrint()}>Печать</Button>
    <TableContainer style={{ height: '75vh' }}>
      <Table size='small' stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {tableHead.map(obj => {
              const align = obj.align || 'inherit'
              return (<TableCell
                key={obj.id}
                align={align}
                sortDirection={orderBy === obj.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === obj.id}
                  direction={order}
                  onClick={(e) => handleRequestSort(e, obj.id)}
                >
                  {obj.name}
                </TableSortLabel>
              </TableCell>);
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableGroup.allIds.map(id => {
            return <Row
              key={id}
              tableResource={tableResource}
              tableHead={tableHead}
              group={tableGroup.byId[id]}
              groupId={id}
              isSelected={isSelected}
              changeSelect={changeSelect}
              order={order}
              orderBy={orderBy}
              idProject={idProject}
            />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </React.Fragment>)
}

function desc(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}
function stableSort(array, objs, orderBy, order) {
  const stabilizedThis = array.map(key => ([key, objs[key][orderBy]]));
  const ord = order === 'desc' ? 1 : -1
  stabilizedThis.sort((a, b) => {
    return ord * desc(a[1], b[1]);
  });
  return stabilizedThis.map(el => el[0]);
}

function Row(props) {
  const [show, changeShow] = useState(true)
  const { tableHead, tableResource, group,
    order, orderBy, changeSelect, isSelected, idProject } = props;
  return (
    <React.Fragment>
      <TableRow>
        <TableCell onClick={() => changeShow(!show)} colSpan={8}>
          <Typography variant='body1'>{group.number + '. ' + group.name}</Typography>
        </TableCell>
        <TableCell colSpan={2} align='right'>
          <Typography variant='body1'>{(Math.round(100 * group.ressumm) / 100).toLocaleString(undefined,{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</Typography>
        </TableCell>
      </TableRow>
      {
        show && stableSort(group.res, tableResource.byId, orderBy, order)
          .map((id, i) => {
            const obj = tableResource.byId[id];
            const selected = isSelected(id)
            return <TableRow key={id} >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selected}
                  onClick={() => changeSelect(id, !selected)}
                />
              </TableCell>
              {tableHead.map(e => {
                if (e.id === 'number') return <CellEdted key={e.id} obj={obj} e={e} id={id} idProject={idProject} />
                return <TableCell key={e.id} align={e.align} onClick={() => changeSelect(id, !selected)}>{obj[e.id]}</TableCell>
              })}
            </TableRow>
          })}
    </React.Fragment>
  );
}


function CellEdted({ obj, e, id, idProject }) {
  const [edit, setEdit] = useState(false)
  const dispatch = useDispatch()
  const handleChange = (e) => {
    e.preventDefault();
    console.log(e.target.value)
    dispatch(editFieldTable(e.target.value, { id, field: 'number', name: 'groupResEstimate' }))
  }
  const handleBlur = (e) => {
    setEdit(false)
    if (obj.type === 'material') {
      dispatch(formSubmitAPI({
        props: {
          id: obj.id,
          number: obj.number
        },
        url: updEstimateMaterialNumber.path({ id: idProject }),
        method: updEstimateMaterialNumber.type,
      }, 'formName'))
    }
    else {
      dispatch(formSubmitAPI({
        props: {
          id: obj.id,
          number: obj.number
        },
        url: updEstimateWorkNumber.path({ id: idProject }),
        method: updEstimateWorkNumber.type,
      }, 'formName'))
    }
  }
  return <TableCell key={e.id} align={e.align} onClick={() => setEdit(!edit)}>
    {edit && <TextField
      fullWidth
      margin="dense"
      type="number"
      value={obj.number || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      autoFocus
      size='small'
    />}
    {!edit && obj.number}
  </TableCell>
}