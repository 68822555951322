import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import CreatableSelect from 'react-select/lib/Creatable';
import { components, styles } from './AutocompliteComponents'

function IntegrationReactSelect({ classes, arr, action, value, palaceHolder, create, textFieldProps={}, selectProps }) {
  const [loading, setLoading] = useState(false)
  const handleCreate = (inputValue) => {
    setLoading(true)
    create(inputValue)
      .then(end => {
        setLoading(false)
        if (end.success) action(end.obj)
      })
  };
  return (
    <div className={classes.root}>
      <NoSsr>
        <CreatableSelect
          isDisabled={loading}
          isLoading={loading}
          onCreateOption={handleCreate}
          classes={classes}
          options={arr}
          textFieldProps={{
            label: palaceHolder,
            InputLabelProps: {
              shrink: true,
            },
            ...textFieldProps
          }}
          components={components}
          value={value}
          onChange={text => action(text)}
          placeholder={palaceHolder}
          native
          formatCreateLabel={(e) => {
            return 'Создать "' + e + '"'
          }}
          {...selectProps}
        />
      </NoSsr>
    </div>
  );
}
export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
