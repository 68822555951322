import React from 'react';
import PageList from '../../components/PageListAPI'
import { getWorkerResource } from '../../api/project-worker'
function ProjectWorker(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='project-worker'
        title='Список доступных людских ресурсов'
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'count', name: 'Количество', },
        ]}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getWorkerResource,
          querty: {
          },
          param: {
            id: props.match.params.idProject
          }
        }}
        click={false}
        pagination={false}
        tableProps={{
          pagination: false
        }}
        goBack={true}
        {...props}
      />
    </div>
  )
};

export default ProjectWorker

