import { combineReducers } from 'redux'
import project, { initialState as projIS } from './project'
import materialLoad, { initialState as mateIS } from './materialLoad'
import loading, { initialState as loadingIS } from './loading'
import users, { initialState as usersIS } from './users'
import notifications, { initialState as notifIS } from './notifier'
import orders, { initialState as orderIS } from './orders'
import form, { initialState as formIS } from './form'
import formTable, { initialState as formTIS } from './formTable'
import autocomplite, { initialState as autoIS } from './autocomplite'
import header, { initialState as headerIS } from './header'
import historyRouter, { initialState as historyIS } from './history'
import gantRouter, { initialState as gantIS } from './gant'

const appReducer = combineReducers({
  formTable: formTable,
  autocomplite: autocomplite,
  loading: loading,
  form: form,
  notifications: notifications,
  project: project,
  materialLoad: materialLoad,
  user: users,
  orders: orders,
  header: header,
  history: historyRouter,
  gant: gantRouter
})


export const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = {
      formTable: formTIS,
      autocomplite: autoIS,
      loading: loadingIS,
      form: formIS,
      notifications: notifIS,
      project: projIS,
      materialLoad: mateIS,
      user: usersIS,
      orders: orderIS,
      header: headerIS,
      history: historyIS,
      gant: gantIS
    }
  }
  return appReducer(state, action)
}