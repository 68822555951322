import { actionCreatorObj, actionCreatorEmpty } from '../functions/redux'
const MAIN = 'header/'
const HEADER_SET_PROJECT = MAIN + 'SET_PROJECT'
const HEADER_UN_SET_PROJECT = MAIN + 'UN_SET_PROJECT'
const HEADER_SET_OBJECT = MAIN + 'SET_OBJECT'

export const headerSetProject = actionCreatorObj(HEADER_SET_PROJECT)
export const headerUnSetProject = actionCreatorEmpty(HEADER_UN_SET_PROJECT)
export const headerSetObject = actionCreatorObj(HEADER_SET_OBJECT)

export const initialState = {
  projectId: 0,
  projectName: '',
  facilityId: 0,
  facilityName: ''
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case HEADER_SET_PROJECT: return { ...state, projectId: action.payload.id, projectName: action.payload.name }
    case HEADER_SET_OBJECT: return { ...state, facilityId: action.payload.id, facilityName: action.payload.name }
    case HEADER_UN_SET_PROJECT: return initialState
    default: return state;
  }
}
