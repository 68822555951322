
export const certificateType = [
    'Сертификат или декларация соответствия техническому регламенту РФ или ТС',
    'Паспорт качества на партию продукции',
    'Отказное письмо',
    'Сертификат пожарной безопасности',
    'Заключение санитарно-эпидемиологической экспертизы',
    'Сертификат Министерства связи (ССС)',
    'Разрешение на применение взрывозащищенного оборудования в РФ',
    'Документ об оцинковании',
    'Инструкция по эксплуатации или монтажу',
    'Протокол испытаний или предварительной сборки',
    'Прочее',]
