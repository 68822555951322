import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, Button, Typography, Grid, TextField } from '@material-ui/core'
import { formInitialize, formDestroy, formChangeAsObj, formSubmitAPI } from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'
import { getOutfitPlanById } from '../../../api/doc'
import { addOutfitFact } from "../../../api/doc";
import MaterialTab from './source/SetMaterialTab'
import WorkerTab from './source/SetWorkerTab'
import WorkTab from './source/SetWorkTab'
import FilesTab from './source/FilesTab'
import moment from 'moment'
import GridProps from '../../../components/GridProps'
import { enqueueSnackbar } from '../../../reducers/notifier';
import SetWorkETab from './source/SetWorkETab';
import SetWorkForZhor from './source/SetWorkForZhor';

const formName = 'outfitPlan'
export default function OutfitSetPlan(props) {
  const [byId, setById] = useState({ date: '', name: '' })
  const [description, setDescription] = useState('')
  const [valueTab, changeValue] = useState(0)
  const dispatch = useDispatch()
  const idProject = props.match.params.idProject
  const idOutfit = props.match.params.id
  useEffect(() => {
    dispatch(formInitialize({
      dateCreate: moment(Date.now()).format('YYYY-MM-DD'),
      work: '',
      mById: {}, // Table material
      mAllIds: [], // Table material
      wById: {}, // Table worker
      wAllIds: [], // Table worker
      wkById: {}, // Table work
      wkAllIds: [], // Table work
      fById: {}, // Table files
      fAllIds: [], // Table files
      eById: {}, // Estimate work
      eAllIds: [], // Estimate work
      zById: {}, // zhor table
      zAllIds: [], // zhor table
    }, { name: formName }))

    return () => {
      dispatch(formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        idProject: idProject,
        id: idOutfit
      },
      progress: true,
      request: getOutfitPlanById,

    }))
      .then((res) => {
        let mById = {}
        let mAllIds = []
        let wById = {}
        let wAllIds = []
        let wkById = {}
        let wkAllIds = []
        let eById = {}
        let eAllIds = []
        res.get.material.forEach((obj, i) => {
          const id = 'id' + obj.id + '_' + i
          mById[id] = { ...obj, countFact: obj.count, deleted: false }
          mAllIds.push(id)
        })
        res.get.worker.forEach((obj, i) => {
          const id = 'id' + obj.id + '_' + i
          wById[id] = { ...obj, deleted: false }
          wAllIds.push(id)
        })
        res.get.work.forEach((v, i) => {
          const id = 'id_' + i
          wkById[id] = { name: v, id: i, deleted: false }
          wkAllIds.push(id)
        })
        res.get.workE.forEach((obj, i) => {
          const id = 'id' + obj.id + '_' + i
          eById[id] = { ...obj, countFact: obj.count, deleted: false }
          eAllIds.push(id)
        })
        dispatch(formChangeAsObj({
          mById: mById, // Table material
          mAllIds: mAllIds, // Table material
          wById: wById, // Table worker
          wAllIds: wAllIds, // Table worker
          wkById: wkById, // Table work
          wkAllIds: wkAllIds, // Table work
          eById,
          eAllIds
        }, { name: formName }))
        setById({ date: res.get.byId.date, name: res.get.byId.name, })
        setDescription(res.get.byId.shortDescription)
      })
  }, [dispatch, idProject, idOutfit])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSave = () => {
    let err = false
    const { mById, mAllIds, wById, wAllIds, wkById, wkAllIds, fById, eAllIds, eById, zById, zAllIds } = values
    const arrWorker = wAllIds.filter(e => !wById[e].deleted).map(e => wById[e].id)
    const arrMaterial = mAllIds.filter(e => !mById[e].deleted && Number(mById[e].countFact) > 0).map(e => {
      const obj = mById[e]
      if (obj.countFact > obj.storage) err = true
      return {
        idMaterial: obj.id,
        count: obj.countFact,
        groupId: obj.groupId,
        idGant: obj.idGant
      }
    })
    if (err) {
      dispatch(enqueueSnackbar({
        message: `нет материалов на складе`,
        options: { variant: 'error' }
      }))
      changeValue(1)
      return;
    }
    if (zAllIds.length === 0) {
      dispatch(enqueueSnackbar({
        message: `заполнение общего журнала работ обязательно`,
        options: { variant: 'error' }
      }))
      return;
    }
    const arrWorkE = eAllIds.filter(e => !eById[e].deleted && Number(eById[e].countFact) > 0).map(key => {
      const obj = eById[key]
      return { id: obj.id, count: obj.countFact, groupId: obj.groupId, idGant: obj.idGant }
    })
    const arrWork = []
    wkAllIds.forEach(key => {
      if (wkById[key].name !== '') arrWork.push(wkById[key].name)
    })
    dispatch(formSubmitAPI({
      props: {
        date: byId.date,
        work: arrWork,
        worker: arrWorker,
        material: arrMaterial,
        shortDescription: description,
        files: Object.values(fById).map(e => e.id),
        workE: arrWorkE,
        idOutfit,
        zhor: Object.values(zById).map(e => e.work)
      },
      url: addOutfitFact.path({ id: idProject }),
      method: addOutfitFact.type,
      history: props.history
    }))
  }
  return <div className='App-paper'>
    <Typography variant='h6'>Фактическое выполнение</Typography>
    <Grid
      item
      container
      xs={6}
    >
      <GridProps name='Дата' value={moment(byId.date).format('LL')} />
      <GridProps name='Создал' value={byId.name} />
    </Grid>
    <TextField
      label="Краткое описание"
      onChange={(e) => setDescription(e.target.value)}
      value={description}
      fullWidth
    />
    <Tabs value={valueTab} onChange={(e, v) => changeValue(v)} indicatorColor='primary'>
      <Tab label="Общий журнал работ" />
      <Tab label="Сметные работы" />
      <Tab label="Материалы" />
      <Tab label="Рабочие" />
      <Tab label="Доп. работы" />
      <Tab label="Файлы" />
    </Tabs>
    {valueTab === 0 && <SetWorkForZhor formName={formName} />}
    {valueTab === 1 && <SetWorkETab formName={formName} />}
    {valueTab === 2 && <MaterialTab formName={formName} />}
    {valueTab === 3 && <WorkerTab formName={formName} />}
    {valueTab === 4 && <WorkTab formName={formName} />}
    {valueTab === 5 && <FilesTab formName={formName} />}
    <Button onClick={() => props.history.goBack()} color='primary'>Назад</Button>
    <Button onClick={handleSave} color='primary'>Сохранить</Button>
  </div>
}

