import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import PageList from '../../components/PageListAPI'
import { getTack, getTackCheck } from '../../api/ocr'
import { docType } from './ocrConst'
import { fetchDispath } from '../../functions/fetch'

export default function OcrTackList (props) {
  const dispatch = useDispatch()
  const [check, setCheck] = useState(false)
  useEffect(() => {
    if (check) return;
    setCheck(true)

    dispatch(fetchDispath({
      param: {
        id: props.match.params.idProject
      },
      progress: true,
      request: getTackCheck,
    })).then().catch(err => console.log(err))
  }, [dispatch, check, props.match.params.idProject])
  return <div className='App-paper'>
    <PageList
      name='equipment'
      columns={[
        { id: 'id', name: 'Номер', width: 120 },
        { id: 'createdDate', name: 'Дата документа', type: 'date', formatDate: 'DD.MM.YYYY', width: 180 },
        { id: 'state', name: 'Статус', },
      ]}
      createItem={false}
      deleteItem={false}
      loadAPI={{
        request: getTack,
        param: {
          id: props.match.params.idProject
        },
        transform: (res => {
          return {
            count: res.count,
            get: res.get.map(obj => ({ ...obj, typeRus: docType[obj.type] }))
          }
        }),
      }}
      headerInit={{
        sortBy: 'createdDate',
        sortDirection: 'desc',
      }}
      click={false}
      title='Задачи распознавания'
      {...props}
    />
  </div>
}
