import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import { getInventory, moveProject } from '../../api/inventory'
import { getOurStorage } from '../../api/storage'
import { loadProject } from '../../api/projects'
import DocTable from '../../components/DocTable'
import DocAdd from '../../components/DocAdd'

const formName = 'InventoryMoveProjectAdd'
const formArr = 'tool'

export default function InventoryMoveProjectAdd({ match, history }) {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(actions.formInitialize({ idProject: '', idStorage: '', byId: {}, allIds: [] },{ name: formName}))
    dispatch(initialAutocomplite({ field: formArr }))

    return ()=>{
      dispatch(actions.formDestroy({ name: formName}))
      dispatch(clearAutocomplite({ field: formArr }))
    }
  },[dispatch])
  const form = useSelector(state=>state.form[formName]||state.form.default)
  const handleSave = () => {
    const { values } = form
    let arr = []
    values.allIds.forEach(row => {
      const obj = values.byId[row]
      if (obj.idInventory > 0) {
        arr.push(obj.idInventory)
      }
    });
    dispatch(actions.formSubmitAPI({
      props: {
        idStorage: values.idStorage,
        idProject: values.idProject,
        arr: arr,
      },
      url: moveProject.addOne.path,
      method: moveProject.addOne.type,
      history: history
    }))
  }
  const handleChangeStorage = useCallback((e) => {
    const pathUsers = getInventory.path + `?idStorage=${e.value}`
    const metaUsers = {
      field: formArr,
      value: 'inventoryNumber',
      labelFunc: (obj) => obj.inventoryNumber.toString().padStart(5, '0') + ' ' + obj.name
    }
    dispatch(loadAutocompliteAPI(pathUsers, metaUsers))
  },[dispatch])

  return <div className='App-paper'>
    <DocAdd
      history={history}
      formName={formName}
      title='Переместить на проект'
      handleSave={handleSave}
      noTableProps={[
        {
          field: 'idStorage', label: 'Склад', type: 'autoLoad', textProps: {
            path: getOurStorage.path,
            meta: {
              field: 'storage',
              value: 'id',
              labelFunc: (obj) => obj.name
            }
          },
          change: handleChangeStorage,
          xs:6
        },
        {
          field: 'idProject', label: 'Проект', type: 'autoLoad', textProps: {
            path: loadProject.path,
            meta: {
              field: 'progects',
              value: 'id',
              labelFunc: (obj) => `${obj.facility} ${obj.name}`
            }
          },
          xs:6
        },
      ]}
    >
      <DocTable
        formName={formName}
        column={[{
          id: 'value', type: 'auto', name: 'Инструмент', formArr: formArr,
          filtered: true, changeValue: text => ({ idInventory: text.value, value: text })
        }]}
        rowDefault={{
          idInventory: '',
          value: null
        }}
      />
    </DocAdd>
  </div>
}