import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { getShipmentMaterialById, deleteShipmentMaterialByIdRow, deleteShipmentMaterial } from '../../../../api/shipment'
import PageList from '../../../../components/PageListAPI'
import Header from '../../../../components/ShipmentByIdHeader'
import getStatus from '../../../../functions/statusDoc'
import Bottom from '../../../../components/ShipmentByIdBottom'
import { printShipmentMaterialById } from '../../../../api/shipment'
import { makeStyles } from '@material-ui/core/styles';
import ListFile from './ListFiles'
const useStyles = makeStyles({
  row: {
    color: '#000000',
    '&$selected': {
      color: '#aaaaaa'
    }
  },
  selected: {},
});
  const tableHead = [
    { id: 'name', name: 'Наименование (сметное)' },
    { id: 'storageUnit', name: 'Ед. изм' },
    { id: 'count', name: 'Количество' },
    { id: 'delivery', name: 'Поставлено' },
    { id: 'price', name: 'Цена за ед.' },
    { id: 'sum', name: 'Сумма' },
    { id: 'summPNDS', name: 'Сумма с НДС' },
  ];
  const formName = 'shipmentMaterialById'
  const canDelete = (obj) => obj.delivery === obj.count
  const disabledDelete = (obj) => obj.delivery > 0  
export default function ShipmentMaterial(props) {
  const classes = useStyles();
  const [arrFile, setFile] = useState([])
  return (
    <div className='App-paper'>
      <PageList
        name={formName}
        columns={tableHead}
        pagination={false}
        loadAPI={{
          request: getShipmentMaterialById,
          param: {
            id: props.match.params.idShipment
          },
          transform: (res => {
            let arr = []
            let sumDoc = 0;
            res.get.forEach(el => {
              const summPNDS= (Number(el.ndsCount) + Number(el.sum)).toFixed(2) 
              sumDoc += Number(el.ndsCount) + Number(el.sum)
              if (el.estimate) {
                arr.push({
                  ...el.estimate,
                  name: el.estimate.name+' ('+el.name+')',
                  estimate: el.estimate,
                  price: el.price * el.estimate.coef,
                  main: {
                    id: el.id,
                    name: el.name,
                    count: el.count,
                    storageUnit: el.storageUnit
                  },
                  summPNDS,
                  sum: el.sum
                })
              }
              else {
                arr.push({...el,summPNDS})
              }
            });
            let obj = {
              statusRus: getStatus(res.doc.allDelivery),
              provider: res.doc.provider,
              paidStatus: res.doc.paidStatus,
              paidStatusRus: (res.doc.paidStatus === 'paid') ? 'Оплачено' : 'Не оплачено',
              idProvider: res.doc.idProvider,
              projectName: res.doc.projectName,
              date: res.doc.date,
              allDelivery: res.doc.allDelivery,
              dateDelivery: res.doc.dateDelivery,
              user: res.doc.user,
              sumDoc: sumDoc.toFixed(2),
              related: res.doc.related,
            }
            setFile(res.files)
            return {
              count: res.count,
              get: arr,
              obj
            }
          }),
        }}
        headerInit={{
          obj: {
            statusRus: getStatus(false),
            paidStatusRus: '',
            provider: '',
            projectName: '',
            date: new Date(),
            allDelivery: false,
            user: '',
            related: false,
          }
        }}
        tableProps={{
          pagination: false,
          classes: classes,
          selected: canDelete
        }}
        click={false}
        headerLink={<Header
          name={formName}
          printRequest={printShipmentMaterialById}
          typeDelivery='purchasemtp'
          arrFile={arrFile}
          setFile={setFile}
          deleteAPI={deleteShipmentMaterial}
          {...props}
        />}
        csv={false}
        history={props.history}
        location={props.location}
        match={props.match}
        deleteItem={true}
        goBack={false}
        disabledDelete={disabledDelete}
        deleteAPI={deleteShipmentMaterialByIdRow(props.match.params.idShipment)}
      />
      <p/>
      <Bottom name={formName} />
      <ListFile arrFile={arrFile} />
      <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
    </div>
  )
}



