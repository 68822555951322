import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Bar } from "react-chartjs-2";
import moment from 'moment';
export default function BarAnProject({ formName, width, height }) {
  const formTable = useSelector(state => state.formTable[formName] || state.form.default)
  const [workArr, setWorkArr] = useState([])
  const [materialArr, setMaterialArr] = useState([])
  const [line, setLine] = useState([])
  const { allIds = [], byId = {} } = formTable
  useEffect(() => {
    const work = []
    const material = []
    const lineA = []
    allIds.forEach(key => {
      const obj = byId[key]
      work.push(Number(obj.work))
      material.push(Number(obj.material))
      lineA.push(moment(obj.date).format('MMMM YYYY'))
    })
    setWorkArr(work)
    setMaterialArr(material)
    setLine(lineA)
  }, [allIds, byId])
  const data = {
    labels: line,
    datasets: [
      {
        label: 'Работы',
        backgroundColor: 'rgba(25, 185, 230,0.2)',
        borderColor: 'rgba(25, 185, 230,1)',
        borderWidth: 1,
        data: workArr
      },
      {
        label: 'Материалы',
        backgroundColor: 'rgba(230, 25, 73,0.2)',
        borderColor: 'rgba(230, 25, 73,1)',
        borderWidth: 1,
        data: materialArr
      }
    ]
  };
  return (
    <Bar
      data={data}
      width={width}
      height={height}
      options={{
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            stacked: true
          }],
          xAxes: [{
            stacked: true
          }]
        }
      }}
    />)
}
