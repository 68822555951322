import React from 'react';
import { getTeamById, deleteTeamWorker } from '../../api/team'
import PageList from '../../components/PageListAPI'
import { Grid, Typography } from '@material-ui/core'
import GridProps from '../../components/GridProps'
import { useSelector } from 'react-redux'
const initTable = {
  master: '',
  phone: '',
  name: ''
}
export default function TeamOne(props) {
  const formName = 'TeamById'
  return (
    <div className='App-paper'>
      <Typography variant='h6'>Бригада</Typography>
      <PageList
        name={formName}
        columns={[{ id: 'name', name: 'Рабочие' }, { id: 'position', name: 'Должность' }]}
        pagination={false}
        loadAPI={{
          request: getTeamById(props.match.params.idProject),
          param: {
            id: props.match.params.idTeam
          },
          transform: (res => {
            if (!res) return;
            return {
              count: res.get.worker.length,
              get: res.get.worker,
              obj: {
                master: res.get.master,
                phone: res.get.phone,
                name: res.get.name,
              }
            }
          }),
        }}
        headerInit={{ obj: initTable }}
        tableProps={{
          pagination: false,
        }}
        click={false}
        headerLink={<Header
          formName={formName}
        />}
        csv={false}
        history={props.history}
        location={props.location}
        match={props.match}
        deleteItem={true}
        deleteAPI={deleteTeamWorker(props.match.params.idProject, props.match.params.id)}
      />
    </div>
  )
}

function Header({ formName }) {
  const fromTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const obj = fromTable.obj || initTable
  return <Grid
    item
    container
  // xs={9}
  >
    <GridProps xsStart={3} xsEnd={9} name='Наименование' value={obj.name} />
    <GridProps xsStart={3} xsEnd={9} name='Мастер' value={obj.master} />
    <GridProps xsStart={3} xsEnd={9} name='Телефон для связи' value={obj.phone} />
  </Grid>
}