import React from 'react';
import { getOutfitFact, deleteOutfitFactById } from '../../../api/doc'
import PageList from '../../../components/PageListAPI'


export default function OufitFactList({ history, match, location }) {
  return <div className='App-paper'>
    <PageList
      name='outfitPlan'
      title='Наряд заказ факт'
      columns={[
        { id: 'id', name: '№' },
        { id: 'date', name: 'Дата наряда', type: 'date', formatDate: 'LL' },
        { id: 'shortDescription', name: 'Краткое описание', },
        { id: 'name', name: 'Добавил', },
      ]}
      deleteItem={true}
      idName='id'
      history={history}
      match={match}
      location={location}
      loadAPI={{
        param: {
          id: match.params.idProject
        },
        request: getOutfitFact,
      }}
      deleteAPI={deleteOutfitFactById(match.params.idProject)}
      headerInit={{
        sortBy:'date', 
        sortDirection:'desc',          
      }}
    />
  </div>
}