import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, ListItem, ListItemText, Button, Drawer } from '@material-ui/core';
import * as actions from '../../reducers/materialLoad';

export default function DrawerColumnEdit() {
  const dispatch = useDispatch()
  const [drawer, setDrawer] = useState(false)
  const materialLoad = useSelector(state => state.materialLoad)
  const { column } = materialLoad
  return (<div>
    <Button onClick={() => setDrawer(true)}>Удаленные</Button>
    <Drawer anchor="right" open={drawer} onClose={() => setDrawer(false)}>
      <List >
        {Object.keys(column).filter(key => !column[key].visible).map(key =>
          <ListItem
            button
            key={key}
            onClick={() => { dispatch(actions.selectColumnMaterialFromFile({ column: key, check: true })) }}
          >
            <ListItemText inset primary={key} />
          </ListItem>)
        }
      </List>
    </Drawer>
  </div>)
}