import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
  Table, TableHead, TableBody, TableRow,
  TableSortLabel, TextField,
  Grid, TableContainer,
  TableCell, Button, Dialog, DialogTitle, DialogContent,
  Typography
} from "@material-ui/core";
import { formObjAdd, formArrayAdd } from '../../../../reducers/form';
import { addFilterAutocomplite } from '../../../../reducers/autocomplite'

const tableColumns = [
  { id: 'label', label: 'Наименование', },
  { id: 'count', label: 'По смете', },
  { id: 'balance', label: 'Выработано', },
  { id: 'storage', label: 'На складе', },
  { id: 'storageUnit', label: 'Ед.изм.' },
]

export default function DialogAddMaterial({ rowDefault, formArr, allIdsField, byIdField, formName }) {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [idRow, setIdRow] = useState(0)
  const [sortBy, setSortBy] = useState('label')
  const [sortDirection, setSortDirection] = useState('desc')
  const [onlyStorage, setOnlyStorage] = useState(false)
  const [filter, setFilter] = useState('')
  const materialArr = useSelector(state => state.autocomplite[formArr] || state.autocomplite.default)
  let filterArr = materialArr.arr.filter(obj => {
    return !(typeof obj.select !== 'undefined')
  })
  if (onlyStorage) {
    filterArr = filterArr.filter(e => e.storage > 0)
  }
  if (filter !== '') {
    filterArr = filterArr.filter(e => e.label.toLowerCase().includes(filter.toLowerCase()))
  }
  const groupObj = JSON.parse(JSON.stringify(materialArr.group))
  filterArr.forEach(e => {
    if (groupObj[e.groupKey]) {
      groupObj[e.groupKey].options.push(e)
    }
  })
  filterArr = stableSort(groupObj, sortBy, sortDirection)
  const handleAddMaterial = text => {
    const newIdRow = idRow - 1
    dispatch(formObjAdd({
      ...rowDefault,
      value: text,
      balance: text.remainder,
      storageUnit: text.storageUnit,
      id: text.id,
      storage: text.storage,
      groupId: text.groupId,
      groupName: text.groupName,
    }, { field: byIdField, id: 'id' + newIdRow, name: formName }))
    dispatch(formArrayAdd('id' + newIdRow, { field: allIdsField, name: formName, end: true }))
    dispatch(addFilterAutocomplite(text.value, { field: formArr, id: 'id' + newIdRow }))
    setIdRow(newIdRow)
  }
  const changeSort = (id) => {
    /** @type {"desc" | "asc"} */
    let sortDirectionNew = 'desc';
    if (sortBy === id && sortDirection === 'desc') {
      sortDirectionNew = 'asc';
    }
    setSortBy(id)
    setSortDirection(sortDirectionNew)
  }
  return <React.Fragment>
    <Button color='primary' onClick={() => setShow(!show)}>Добавить списком</Button>
    <Dialog
      open={show}
      maxWidth='lg'
      keepMounted
      scroll="paper"
      onClose={() => (setShow(false))}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: '100%', width: '100%' } }}
    >
      <DialogTitle id="alert-dialog-title">Добавить материал</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Button color={onlyStorage ? 'secondary' : 'primary'} onClick={() => setOnlyStorage(s => !s)}>Только на складе</Button>
          </Grid>
          <Grid item><Typography>Поиск:</Typography></Grid>
          <Grid item xs={3}>
            <TextField
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Grid>
        </Grid>
        <TableContainer style={{ height: 'calc(100% - 44px)' }}>
          <Table size='small' stickyHeader>
            <TableHead>
              <TableRow >
                <TableCell >Раздел</TableCell>
                {tableColumns.map(e => <TableCell key={e.id}>
                  <TableSortLabel
                    active={sortBy === e.id}
                    direction={sortDirection}
                    onClick={() => changeSort(e.id)}
                  >
                    {e.label}
                  </TableSortLabel>
                </TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterArr.map(group => {
                const count = group.options.length - 1
                return group.options.map((v, i) => {
                  const styled = {
                    borderBottomWidth: count === i ? '3px' : '1px'
                  }
                  return <TableRow hover onClick={() => handleAddMaterial(v)} key={v.value}>
                    <TableCell style={styled}>{v.groupName}</TableCell>
                    <TableCell style={styled}>{v.label}</TableCell>
                    <TableCell style={styled}>{v.count}</TableCell>
                    <TableCell style={styled}>{v.balance}</TableCell>
                    <TableCell style={styled}>{v.storage}</TableCell>
                    <TableCell style={styled}>{v.storageUnit}</TableCell>
                  </TableRow>
                })
              })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  </React.Fragment>
}


function stableSort(groupObj, orderBy, order) {
  const ord = order === 'desc' ? 1 : -1
  return Object.values(groupObj).map(obj => {
    return {
      ...obj,
      options: obj.options.sort((a, b) => {
        return ord * String(a[orderBy]).localeCompare(String(b[orderBy]), undefined, { numeric: true, sensitivity: 'base' })
      })
    }
  })

}