import React, { useState } from 'react';
import {
  Typography, Table, TableBody, TableCell,
  TableHead, TableRow, TableSortLabel, TableContainer
} from '@material-ui/core';
import moment from 'moment'
import 'moment/locale/ru';

function desc(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}
function stableSort(array, objs, orderBy, order) {
  const stabilizedThis = array.map(key => ([key, objs[key][orderBy]]));
  const ord = order === 'desc' ? 1 : -1
  stabilizedThis.sort((a, b) => {
    return ord * desc(a[1], b[1]);
  });
  return stabilizedThis.map(el => el[0]);
}
export default function TableAn({ tableHead, tableData, classes = {} }) {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('date')
  const handleRequestSort = (event, property) => {
    const orderBy = property;
    let orderNew = 'desc';
    if (orderBy === property && order === 'desc') {
      orderNew = 'asc';
    }
    setOrderBy(property)
    setOrder(orderNew)
  };
  return (
    <div>
      <TableContainer style={{ maxHeight: 400, height: '30vh' }}>
        <Table className={classes.table} padding='checkbox' size='small' stickyHeader aria-label="sticky table" >
          <TableHead>
            <TableRow>
              {tableHead.map(obj => {
                const align = obj.align || 'inherit'
                return (<TableCell
                  key={obj.id}
                  align={align}
                  sortDirection={orderBy === obj.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === obj.id}
                    direction={order}
                    onClick={(e) => handleRequestSort(e, obj.id)}
                  >
                    {obj.name}
                  </TableSortLabel>
                </TableCell>);
              })}
            </TableRow>
          </TableHead>
          <TableBody>{
            stableSort(tableData.allIds, tableData.byId, orderBy, order)
              .map(row => {
                return (<RowTableForm
                  tableHead={tableHead}
                  classes={classes}
                  obj={tableData.byId[row]}
                  key={row}
                />)
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

function RowTableForm({ tableHead, classes, obj }) {
  return (
    <TableRow className={classes.row}>{
      tableHead.map(column => {
        const align = obj.align || 'inherit'
        return <TableCell key={column.id} className={classes.cell} align={align}>
          {
            (column.component) ? column.component(obj[column.id], obj)
              : <Typography align='inherit'>{
                (column.date) ?
                  moment(obj[column.id]).format((column.formatDate) ? column.formatDate : 'LLL') :
                  (column.func) ? column.func(obj[column.id]) :
                    obj[column.id]
              }</Typography>
          }
        </TableCell>
      })
    }
    </TableRow>
  )
}