import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { formChange as formChangeAction } from '../reducers/form'
import * as actions from '../reducers/autocomplite'
import AutoComplite from './AutocompliteCreate'
import { fetchDispath } from '../functions/fetch'


export default function AutocompliteWithLoadCreate({
  classes,
  field, // field form
  name,
  path,
  meta,
  formName,
  change,
  selectProps,
  textFieldProps,
  formChange = true,
  createAPI,
  createBody,
  value
}) {
  const dispatch = useDispatch()
  const metaField = meta.field
  const initValue = value
  useEffect(() => {
    dispatch(actions.initialAutocomplite({ field: metaField, value: initValue }))
    return () => {
      dispatch(actions.clearAutocomplite({ field: metaField }))
    }
  }, [dispatch, metaField, initValue])
  useEffect(() => {
    dispatch(actions.loadAutocompliteAPI(path, meta))
  }, [dispatch, path])
  const handleChange = (e) => {
    dispatch(actions.set(e, { field: meta.field }))
    if (formChange) dispatch(formChangeAction(e.value + '', { field: field, name: formName }))
    if (change) change(e)
  }
  const createNew = (inputValue) => {
    return dispatch(fetchDispath({
      body: {
        [createBody]: inputValue,
      },
      progress: false,
      request: createAPI,
    }))
      .then((res) => {
        if (res.success) {
          dispatch(actions.add({ value: res.data.id + '', label: inputValue }, { field: meta.field }))
          dispatch(actions.set({ value: res.data.id + '', label: inputValue }, { field: meta.field }))
          return { success: true, obj: { value: res.data.id + '', label: inputValue } }
        }
        return { success: false }
      })
  }
  const auto = useSelector(state => state.autocomplite[meta.field] || state.autocomplite.default)
  return <div className={classes.formControl}>
    <AutoComplite
      arr={auto.arr}
      action={handleChange}
      palaceHolder={name}
      value={auto.value}
      create={createNew}
      selectProps={selectProps}
      textFieldProps={textFieldProps}
    />
  </div>
}