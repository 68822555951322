import React from 'react'
import Grid from '@material-ui/core/Grid'
// import check from '../../functions/can'
import CardMenu from './source/CardMenu'
function MultiProjectMenu() {
  let str = '/multi/material'
  const arr = {
    documents: [
      { to: str, primary: 'Список' },
      { to: str + '/load', primary: 'Загрузить' },
    ].filter(key => typeof key !== 'boolean'),
    workdoc: [
      { to: '/workdoc', primary: 'Список' },
    ].filter(key => typeof key !== 'boolean'),
  }
  return <Grid container>
    {arr.documents.length > 0 && <Grid item><CardMenu arr={arr.documents} name={'Материалы'} /></Grid>}
    {arr.workdoc.length > 0 && <Grid item><CardMenu arr={arr.workdoc} name={'Рабочая документация'} /></Grid>}
  </Grid>
}

export default MultiProjectMenu;