import React, { useState, useEffect } from 'react'
import {
  Dialog, Grid, DialogActions, DialogContent, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import AutoComplite from '../../components/Autocomplite'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { fetchDispath } from '../../functions/fetch'
import { updGantMany } from '../../api/form-gant.api'

export default function GantDialogParent({ formName, idProject, reload, disabled }) {
  const dispatch = useDispatch()
  const allIds = useSelector(state => state.form[formName]?.values.allIds || [])
  const byId = useSelector(state => state.form[formName]?.values.byId || {})
  const [parent, setParent] = useState(null)
  const [open, setOpen] = useState(false)
  const [arrParent, setArrParent] = useState()
  useEffect(() => {
    const arrParentNew = []
    allIds.forEach(row => {
      const obj = byId[row];
      if (obj.typeGant === 'parent') {
        arrParentNew.push({
          value: obj.idAPI,
          label: obj.pos + ' ' + obj.name
        })
      }
    })
    setArrParent(arrParentNew)
  }, [allIds, byId])
  const handleOk = () => {
    const selected = []
    allIds.forEach(key => {
      if (byId[key].selected) { selected.push(byId[key].idAPI) }
    })
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      body: {
        parent: parent ? parent.value : null,
        selected
      },
      request: updGantMany,
    })).then(res => {
      reload()
      setOpen(false)
    }).catch(err => console.log(err))
  }
  return (
    <React.Fragment>
      <Button onClick={() => setOpen(true)} color='primary' disabled={disabled}>Добавить в группу</Button>
      <Dialog
        open={open}
        maxWidth='sm'
        keepMounted
        fullWidth
        scroll="paper"
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        PaperProps={{ style: { height: 450 } }}
      >
        <DialogContent >
          <Grid container
            direction='column'
            spacing={1}
            alignItems="flex-start"
          >
            <Grid item container direction="row" spacing={2}>
              <Grid item xs={12}>
                <AutoComplite
                  arr={arrParent}
                  action={e => setParent(e)}
                  value={parent}
                  formName={formName}
                  palaseHolder='Родитель'
                  selectProps={{
                    isClearable: true
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction='row' alignItems="flex-end">
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CheckIcon />}
                onClick={handleOk}
                size='small'
              >Сохранить
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloseIcon />}
                onClick={() => setOpen(false)}
                size='small'
              >Отменить
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
