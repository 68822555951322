import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '../../api/facility'
import { updProjectOur } from '../../api/projects'
import { Typography, List, ListItem, ListItemText, IconButton, ListItemSecondaryAction } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { fetchDispath } from '../../functions/fetch'
import { headerSetObject, headerSetProject } from '../../reducers/header'
import { API_BO } from '../../conf'
import moment from 'moment'
import 'moment/locale/ru';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export default function FacilityOurOne(props) {
  const idFacility = props.match.params.id
  return (
    <div className='App-paper'>
      <PropsFacility idFacility={idFacility} />
      <ListProject idFacility={idFacility} />
    </div>
  )
}

function PropsFacility({ idFacility }) {
  const [state, setState] = useState({
    name: '',
    description: '',
    address: '',
    investor: '',
    customer: '',
    contractor: '',
    contractNumber: '',
    contractDate: '',
    ksPrefix: '',
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: idFacility
      },
      progress: true,
      request: api.getFacilityById,
    })).then(res => {
      const contractDate = moment(res.get.contractDate)
      setState({
        name: res.get.name,
        description: res.get.description,
        address: res.get.address,
        investor: res.get.investor,
        customer: res.get.customer,
        contractor: res.get.contractor,
        contractNumber: res.get.contractNumber,
        contractDate: contractDate.isValid() ? contractDate.format('DD.MM.YYYY') : '',
      })
      dispatch(headerSetObject({ id: idFacility, name: res.get.name }))
      dispatch(headerSetProject({ id: 0, name: '' }))
    }).catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, idFacility])
  return (
    <React.Fragment>
      <Typography>Наименование: {state.name}</Typography>
      <Typography>Адрес: {state.address}</Typography>
      <Typography>Инвестор: {state.investor}</Typography>
      <Typography>Заказчик (Генподрядчик): {state.customer}</Typography>
      <Typography>Подрядчик (Субподрядчик): {state.contractor}</Typography>
      <Typography>Договор № {state.contractNumber} Дата  {state.contractDate}</Typography>
      <Typography>Описание: {state.description}</Typography>
      <p />
    </React.Fragment>
  )
}

function ListProject({ idFacility }) {
  const [hiddenView, setHiddenView] = useState(false)
  const [arr, setArr] = useState([])
  const [hiddenArr, setHiddenArr] = useState([])
  const [byId, setById] = useState({})
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: api.getFacilityOurProject,
      param: {
        id: idFacility
      }
    }))
      .then(res => {
        let obj = {}
        const arrHidden = []
        const arrId = []
        res.get.forEach(e => {
          obj[e.id] = { id: e.id, name: e.name, isHidden: e.isHidden }
          if (e.isHidden) arrHidden.push(e.id)
          else arrId.push(e.id)
        })
        setById(obj)
        setArr(arrId)
        setHiddenArr(arrHidden)
      })
      .catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, idFacility])
  const changeHiddenView = (obj) => {
    setById(old => ({ ...old, [obj.id]: { ...obj, isHidden: !obj.isHidden } }))
    if (obj.isHidden) {
      setArr(old => ([...old, obj.id]))
      setHiddenArr(old => old.filter(e => e !== obj.id))
    }
    else {
      setArr(old => old.filter(e => e !== obj.id))
      setHiddenArr(old => ([...old, obj.id]))
    }
  }
  return <React.Fragment>
    <Typography variant='h6'>Проекты <IconButton size='small' onClick={() => setHiddenView(old => !old)}>
      {hiddenView ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </IconButton>
    </Typography>
    <List>
      {arr.map(key => {
        const obj = byId[key]
        return <ListItemLink key={obj.id} obj={obj} hiddenView={hiddenView} changeHiddenView={changeHiddenView} />
      })}
      {hiddenView && hiddenArr.map(key => {
        const obj = byId[key]
        return <ListItemLink key={obj.id} obj={obj} hiddenView={hiddenView} changeHiddenView={changeHiddenView} />
      })}
    </List>
  </React.Fragment>
}
// 
function ListItemLink({ obj, hiddenView, changeHiddenView }) {
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(headerSetProject({ id: obj.id, name: obj.name }))
  }
  const handleChangeHiddenView = () => {
    dispatch(fetchDispath({
      progress: true,
      request: updProjectOur,
      param: {
        id: obj.id
      },
      body: {
        isHidden: !obj.isHidden
      }
    })).then(() => {
      changeHiddenView(obj)
    })
  }
  const renderLink = React.forwardRef((props, ref) => (<Link ref={ref} to={`${API_BO}/projects/${obj.id}`} {...props} />))
  return (
    <ListItem button component={renderLink} onClick={onClick}>
      <ListItemText primary={obj.name} primaryTypographyProps={{ color: obj.isHidden ? 'textSecondary' : 'textPrimary' }} />
      {hiddenView && <ListItemSecondaryAction onClick={handleChangeHiddenView}><IconButton edge="end">
        {obj.isHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton></ListItemSecondaryAction>}
    </ListItem>
  );
}