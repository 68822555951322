import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AutocompliteWithLoad from '../../components/AutocompliteWithLoad'
import { getWorkDocList, getWorkDocById } from '../../api/workdoc'
import {
  Grid, Typography, Button, Table, TableBody, Select, InputLabel, MenuItem,
  TableCell, TableRow, TableHead, TableContainer, Checkbox, FormControlLabel, IconButton, Tooltip
} from "@material-ui/core";
import * as actions from '../../reducers/form';
import { initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import { fetchDispath } from '../../functions/fetch'
import { enqueueSnackbar } from '../../reducers/notifier'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
const formTable = 'LoadDocMaterial'
const formArrList = 'workDoc'
const column = [
  { align: 'left', name: 'pos', width: '4.5%', label: 'Позиция' },
  { align: 'left', name: 'name', width: '38.6%', label: 'Наименование и техническая характеристика' },
  { align: 'center', name: 'type', width: '13.6%', label: 'Тип, марка, обозначение документа, опросного листа' },
  { align: 'center', name: 'code', width: '7.9%', label: 'Код оборудования, изделия, материала' },
  { align: 'center', name: 'plant', width: '10.2%', label: 'Завод-изготовитель' },
  { align: 'center', name: 'su', width: '4.5%', label: 'Единица измерения' },
  { align: 'center', name: 'count', width: '4.5%', label: 'Количество' },
  { align: 'center', name: 'mass', width: '5.6%', label: 'Масса единицы, кг' },
  { align: 'center', name: 'other', width: '10%', label: 'Примечание' }
]
const rowExample = {
  pos: '',
  name: '',
  type: '',
  code: '',
  plant: '',
  su: '',
  count: '',
  mass: '',
  other: '',
  row: ''
}
export default function LoadMaterialFromDoc({ match, history }) {
  const dispatch = useDispatch()
  const [docId, setDocId] = useState('')
  const [type, setType] = useState('material')
  const [multiSelect, setMultiSelect] = useState(false)
  const [countSelect, setCountSelect] = useState(10)
  const [estimate, setEstimate] = useState(false)
  useEffect(() => {
    dispatch(actions.formInitialize({ byId: {}, allIds: [], rowProps: {}, selected: [], idWorkDoc: '' }, { name: formTable }))
    dispatch(initialAutocomplite({ field: formArrList }))
    return () => {
      dispatch(actions.formDestroy({ name: formTable }))
      dispatch(clearAutocomplite(formArrList))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formTable] || state.form.default)
  const { byId = {}, allIds = [], selected = [], idWorkDoc, rowProps } = form.values
  const handleLoadDoc = () => {
    if (docId === '') return;
    dispatch(fetchDispath({
      param: { id: docId, },
      progress: true,
      request: getWorkDocById,
    })).then(res => {
      const { rows, table } = res.get
      let byId = {}
      let allIds = []
      table.forEach(obj => {
        const id = 'a' + obj.row
        byId[id] = obj
        allIds.push('a' + obj.row)
      })
      dispatch(actions.formChangeAsObj({ allIds, byId, }, { name: formTable }))
      let rowProps = {}
      rows.forEach(e => {
        rowProps[e.row] = e
      })
      dispatch(actions.formChangeAsObj({ rowProps }, { name: formTable }))
    }).catch(err => console.log(err))
  }
  const handleSelected = (e, id) => {
    console.log(e, id)
    if (multiSelect) {
      const indexOf = allIds.indexOf(id)
      const arrAdd = allIds.slice(indexOf, indexOf + countSelect)
      let union = []
      if (e) {
        union = [...new Set([...arrAdd, ...selected])];
      }
      else {
        union = selected.filter(x => !arrAdd.includes(x));
      }
      dispatch(actions.formChange(union, { field: 'selected', name: formTable }))

      return;
    }
    if (e) {
      dispatch(actions.formArrayAdd(id, { field: 'selected', name: formTable }))
    }
    else {
      dispatch(actions.formArrayDelete(id, { field: 'selected', name: formTable }))
    }
  }
  const onSelectAllClick = (e) => {
    if (e.target.checked) {
      dispatch(actions.formChange(allIds, { field: 'selected', name: formTable }))
      return;
    }
    dispatch(actions.formChange([], { field: 'selected', name: formTable }))
  }
  const handleLoad = () => {
    const idProject = match.params.id
    if (selected.length === 0) {
      dispatch(enqueueSnackbar({ message: 'Материалы не выбраны', options: { variant: 'error' } }))
      return;
    }
    let columnNew = {
      name: 'name',
      su: 'storageUnit',
      count: 'count'
    }
    if (type === 'material') {
      columnNew.pos = 'number'
      columnNew.type = 'typeMark'
      columnNew.code = 'vendorCode'
    }
    const url = (type === 'material') ? '/materials/many' : `/projects/${idProject}/work/load`
    let errCount = false
    const arr = []
    const arrSelect = []
    selected.forEach(key => {
      const obj = byId[key]
      const row = rowProps[obj.row] || { isSection: false }
      if (row.isSection) return;
      let objNew = {
        name: obj.name || '',
        su: obj.su || '',
        count: obj.count || ''
      }
      if (type === 'material') {
        objNew.pos = obj.pos || ''
        objNew.type = obj.type || ''
        objNew.code = obj.code || ''
      }
      if (typeof Object.values(objNew).find(e => e !== '') === 'undefined') return;
      const count = parseFloat(String(obj.count).replace(',', '.').replace(' ', ''))
      if (Number.isNaN(Number(count))) {
        errCount = true
      }
      arr.push({ ...objNew, count })
      arrSelect.push(key)
    })
    if (errCount) {
      dispatch(enqueueSnackbar({ message: 'Колонка количество имеет нечисловые поля', options: { variant: 'error' } }))
      return;
    }
    if (arr.length === '') return;
    const params = {
      idProject: idProject,
      arr: arr,
      column: columnNew,
      estimate: estimate,
      consist: 'add'
    }
    dispatch(actions.formSubmitAPI({
      props: params,
      url: url,
      method: 'POST',
      endFunc: (res) => {
        dispatch(actions.formChange([], { field: 'selected', name: formTable }));
        dispatch(actions.formChange(allIds.filter(x => !arrSelect.includes(x)), { field: 'allIds', name: formTable }));

      }
    }, formTable))
  }
  return <div className='App-paper'>
    <Typography variant='h5'>Загрузить из рабочей документации</Typography>
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={4} >
        <AutocompliteWithLoad
          classes={{}}
          field='idWorkDoc'
          name='Рабочая документация'
          path={getWorkDocList.path}
          meta={{
            field: 'workDoc',
            value: 'id',
            labelFunc: (obj) => obj.title
          }}
          value={idWorkDoc}
          formName={formArrList}
          form={false}
          change={(e) => setDocId(e.value)}
        />
      </Grid>
      <Grid>
        <Button color='primary' onClick={handleLoadDoc}>Выбрать</Button>
      </Grid>
      <Grid item>
        <InputLabel id="header-select-multi-count-select-wr">Тип загрузки</InputLabel>
        <Select
          labelId="header-select-multi-count-select-wr"
          id="header-select-multi-count-select-wr"
          value={type}
          onChange={e => setType(e.target.value)}
        >
          <MenuItem value='material'>материлы</MenuItem>
          <MenuItem value='work'>работа</MenuItem>
        </Select>
      </Grid>
      <Grid>
        <Button color='primary' onClick={handleLoad}>Загрузить</Button>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={<Checkbox checked={estimate} onChange={e => setEstimate(e.target.checked)} />}
          label="Матералы заказчика"
        />
      </Grid>
      <Grid item>
        <IconButton color={multiSelect ? 'secondary' : 'primary'} onClick={() => setMultiSelect(!multiSelect)}>
          <Tooltip title="Режим выделения">
            <LibraryAddIcon />
          </Tooltip>
        </IconButton>
      </Grid>
      {multiSelect && <Grid item>
        <InputLabel id="header-select-multi-count-select">Количество строк</InputLabel>
        <Select
          labelId="header-select-multi-count-select"
          id="header-select-multi-count-select"
          value={countSelect}
          onChange={e => setCountSelect(e.target.value)}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </Select>
      </Grid>}
    </Grid>
    <TableContainer style={{ height: '83vh' }}>
      <Table size='small' stickyHeader style={{ borderCollapse: 'collapse', width: '99%' }}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>
              <Checkbox
                indeterminate={selected.length > 0 && selected.length < allIds.length}
                checked={allIds.length > 0 && selected.length === allIds.length}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </TableCell>
            {
              column.map((v, i) => {
                return <TableCell key={i} align='center' style={{ width: v.width }}>{v.label}</TableCell>
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {allIds.map((v, i) => {
            const obj = { ...rowExample, ...(byId[v] || {}) }
            const rowId = obj.row
            const row = rowProps[rowId] || { isSection: false }
            const checked = selected.includes(v)
            if (row.isSection) {
              return <TableRow key={i}>
                <TableCell><Checkbox checked={false} disabled /></TableCell>
                <TableCell colSpan={9} style={{ backgroundColor: row.color }}>{row.section}</TableCell>
              </TableRow>
            }
            return <TableRow key={v}>
              <TableCell><Checkbox checked={checked} onChange={(e) => handleSelected(e.target.checked, v)} /></TableCell>
              {column.map((e, i) => {
                return <TableCell key={i} style={{ backgroundColor: row.color }} align={e.align}>{obj[e.name]}</TableCell>
              })}
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>

  </div>
} 