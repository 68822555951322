import rules from "../rbac-rules";
export default function can(action, data){
    const role = localStorage.getItem('role')
    const permissions = rules[role];
    if (!permissions) {
      return false;
    }
    const staticPermissions = permissions.static;
    if (staticPermissions && staticPermissions.includes(action)) {
      return true;
    }
    return false;
  };
  