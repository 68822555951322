import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../../../reducers/autocomplite'
import { getOurStorage } from '../../../../api/storage'
import { loadProject } from '../../../../api/projects'
import { getOurAllStorageMaterial, addOurStorageMoveProject } from '../../../../api/storage'
import { getMaterials } from '../../../../api/material'
import DocAdd from '../../../../components/DocAdd'
import DocTable from '../../../../components/DocTable'

const formName = 'AddMoveProject'
const formMat = 'material'
const formMatStorage = 'materialStorage'

export default function AddMoveProject({ history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      idProject: '', byId: {}, allIds: [], date: '', dateDelivery: '', idStorage: '', typeMaterial: 'material'
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: formMat }))
    dispatch(initialAutocomplite({ field: formMatStorage }))
    return () => {
      dispatch(clearAutocomplite({ field: formMat }))
      dispatch(clearAutocomplite({ field: formMatStorage }))
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSave = () => {
    let arr = []
    values.allIds.forEach(row => {
      const obj = values.byId[row]
      const count = parseFloat(obj.count.replace(',', '.').replace(' ', ''))
      const coef = parseFloat(obj.coef.replace(',', '.').replace(' ', ''))
      if (obj.idMaterial > 0 && count > 0 && coef > 0)
        arr.push({
          count: count,
          idMaterial: obj.idMaterial,
          idMaterialStorage: obj.idMaterialStorage,
          coef: coef,
        })
    });
    dispatch(actions.formSubmitAPI({
      props: {
        arr: arr,
        dateDelivery: values.dateDelivery,
        idStorage: values.idStorage,
        idProject: values.idProject,
      },
      url: addOurStorageMoveProject.path,
      method: addOurStorageMoveProject.type,
      history: history
    }))
  }
  const changeStorage = (e) => {
    dispatch(loadAutocompliteAPI(getOurAllStorageMaterial.path + '?id=' + e.value, {
      field: formMatStorage,
      value: 'id',
      labelFunc: (obj) => obj.name,
      hidden: ['storageUnit', 'count']
    }))
    values.allIds.forEach(key => {
      const obj = {
        idMaterialStorage: '',
        materialStorage: '',
        storageUnitStorage: '',
        valueMaterialStorage: null,
      }
      dispatch(actions.formObjChangeObj(obj, { field: 'byId', id: key, name: formName }))
    })
  }
  const changeProject = (e) => {
    dispatch(loadAutocompliteAPI(getMaterials.path({ id: e.value }), {
      field: 'material',
      value: 'id',
      labelFunc: (obj) => obj.name + ' ' + obj.typeMark + ' ' + obj.vendorCode,
      hidden: ['storageUnit', 'idMaterial']
    }))
    values.allIds.forEach(key => {
      const obj = {
        idMaterial: '',
        material: '',
        storageUnit: '',
        valueMaterial: null,
      }
      dispatch(actions.formObjChangeObj(obj, { field: 'byId', id: key, name: formName }))
    })
  }
  return <div className='App-paper'>
    <DocAdd
      history={history}
      formName={formName}
      title='Переместить на проект'
      handleSave={handleSave}
      noTableProps={[
        {
          field: 'idStorage', label: 'Склад', type: 'autoLoad', textProps: {
            path: getOurStorage.path,
            meta: {
              field: 'storage',
              value: 'id',
              labelFunc: (obj) => obj.name
            }
          },
          change: changeStorage
        },
        {
          field: 'idProject', label: 'Проект', type: 'autoLoad', textProps: {
            path: loadProject.path,
            meta: {
              field: 'project',
              value: 'id',
              labelFunc: (obj) => `${obj.facility} ${obj.name}`
            }
          },
          change: changeProject
        },
        { field: 'dateDelivery', label: 'Дата поставки', type: 'text', textProps: { type: 'date' } },
      ]}
    >
      <DocTable
        formName={formName}
        column={[
          {
            id: 'valueMaterialStorage', type: 'auto', name: 'Материал склада', formArr: formMatStorage,
            filtered: true, changeValue: e => ({
              idMaterialStorage: e.value,
              materialStorage: e.label,
              storageUnitStorage: e.storageUnit,
              countStorage: e.count,
              valueMaterialStorage: e,
            }),
            width: '35%'
          },
          { id: 'storageUnitStorage', type: 'string', name: 'Ед.изм' },
          {
            id: 'valueMaterial', type: 'auto', name: 'Материал сметы', formArr: formMat,
            filtered: true, changeValue: e => ({
              idMaterial: e.value,
              material: e.label,
              storageUnit: e.storageUnit,
              valueMaterial: e,
            }),
            width: '35%'
          },
          { id: 'storageUnit', type: 'string', name: 'Ед.изм' },
          {
            id: 'count', type: 'number', name: 'Количество', title: obj => `max ${obj.countStorage || 0}`, width: '25%',
            textProps: obj => ({ error: obj.count > obj.countStorage, helperText: obj.count > obj.countStorage ? 'Больше чем на складе' : '' })
          },
          { id: 'coef', type: 'number', name: 'Коэфф', },
        ]}
        rowDefault={{
          count: '',
          idMaterial: '',
          idMaterialStorage: '',
          storageUnit: '',
          storageUnitStorage: '',
          valueMaterial: null,
          valueMaterialStorage: null,
          countStorage: '',
          coef: '1',
        }}
      />
    </DocAdd>
  </div>
}