import React from 'react'
import { getProjectGantPrice } from '../../api/form-gant.api'
import PageList from '../../components/PageListAPI'
import BarGantPrice from './BarGantPrice'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    height: '30vh'
  }
}));

export default function GantPrice(props) {
  const classes = useStyles()
  const columns = [
    { id: 'date', name: 'Дата', type: 'date', formatDate: 'MMMM YYYY' },
    { id: 'work', name: 'Работы', type: 'price', align: 'right' },
    { id: 'material', name: 'Материалы', type: 'price', align: 'right' },
    { id: 'all', name: 'Всего', type: 'price', align: 'right' },
  ]
  return (
    <div className='App-paper'>
      <PageList
        classes={classes}
        name='gantPrice'
        title='Кассовый план'
        columns={columns}
        idName='date'
        loadAPI={{
          param: {
            id: props.match.params.idProject
          },
          transform: (res => {
            return {
              count: res.get.length,
              get: res.get.map(obj => {
                return {
                  ...obj,
                  all: Math.round((Number(obj.work) + Number(obj.material)) * 100) / 100
                }
              })
            }
          }),
          request: getProjectGantPrice,
        }}
        tableProps={{
          pagination: false
        }}
        pagination={false}
        click={false}
        goBack={false}
        {...props}
      />
      <div style={{ height: 400 }}>
        <BarGantPrice
          width={400}
          height={400}
          formName='gantPrice'
        />
      </div>
      <Button color='primary' component="span" onClick={() => props.history.goBack()}>Назад</Button>
    </div>
  )
}