import React, { useEffect, useState } from 'react'
import {
  Button, Typography, Dialog, DialogTitle, DialogActions,
  DialogContent, CircularProgress, Chip,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SimpleAdd from '../../components/SimpleAdd'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite, load as loadAuto } from '../../reducers/autocomplite'
import * as actions from '../../reducers/form';
import { addRemarksOneAPI } from '../../api/remarks.api'
import { getUserList } from '../../api/users'
import { getProjectGant } from '../../api/form-gant.api'
import { fetchDispath } from '../../functions/fetch'
import { loadFileAPI } from '../../reducers/materialLoad';
import { addFile, delFile, addLocation } from '../../api/list'
import DescriptionIcon from '@material-ui/icons/Description';

const formName = 'remarks-add'
const formAutoUsers = 'formAutoUsers'
const formLocation = 'remarks-location'
const formGant = 'form-gant'

export default function RemarksAdd({ match, history }) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const formLocationV = useSelector(state => state.form[formLocation] || state.form.default)

  useEffect(() => {
    dispatch(initialAutocomplite({ field: formAutoUsers }))
    dispatch(initialAutocomplite({ field: formGant }))
    dispatch(actions.formInitialize({
      reason: '',
      remarkText: '',
      correctionDate: '',
      responsible: null,
      arrInspectors: null,
      idLocation: null,
      idGant: null,
      location: '',
      fById: {},
      fAllIds: [],
      errorValidate: { reason: false },
    }, { name: formName }))
    dispatch(actions.formInitialize({
      axes: '',
      ranks: '',
      marks: '',
      floors: '',
      tiers: '',
      sections: '',
      premises: '',
      place: '',
    }, { name: formLocation }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formLocation }))
      dispatch(clearAutocomplite({ field: formAutoUsers }))
      dispatch(clearAutocomplite({ field: formGant }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getUserList.path, {
      field: formAutoUsers,
      value: 'id',
      labelFunc: (obj) => obj.surname + ' ' + obj.name + ' ' + obj.patronymic
    }))
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
      },
      progress: false,
      request: getProjectGant,
    })).then(res => {
      const arrGant = res.get.filter(e => e.typeGant === 'work').map(e => ({ label: e.name, value: e.id }))
      dispatch(loadAuto(arrGant, { field: formGant }))
    }).catch(err => console.log(err))
  }, [dispatch,match.params.idProject])
  const handleChangeAuto = (field, value) => dispatch(actions.formChangeAsObj({ [field]: value }, { name: formName }))

  const arrProps = [
    { field: 'reason', label: 'Основание', type: 'text', validator: [(value) => value !== ''], errorText: 'Поле обязательно' },
    { field: 'remarkText', label: 'Замечание', type: 'text', textProps: { multiline: true, rows: "4" } },
    { field: 'correctionDate', label: 'Дата исправления', type: 'text', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { field: 'responsible', label: 'Исполнитель', type: 'autoRedux', change: e => handleChangeAuto('responsible', e.value), textProps: { formArr: formAutoUsers } },
    { field: 'arrInspectors', label: 'Проверяющий', type: 'autoRedux', change: e => handleChangeAuto('arrInspectors', e.value), textProps: { formArr: formAutoUsers, selectProps: { isMulti: true } } },
    { field: 'idGant', label: 'Работа', type: 'autoRedux', change: e => handleChangeAuto('idGant', e.value), textProps: { formArr: formGant } },
  ]

  const handleSubmit = (e) => {
    e.preventDefault();
    const { values } = form
    dispatch(fetchDispath({
      body: {
        axes: formLocationV.values.axes,
        ranks: formLocationV.values.ranks,
        marks: formLocationV.values.marks,
        floors: formLocationV.values.floors,
        tiers: formLocationV.values.tiers,
        sections: formLocationV.values.sections,
        premises: formLocationV.values.premises,
        place: formLocationV.values.place,
      },
      progress: false,
      request: addLocation,
    })).then(res => {
      dispatch(actions.formSubmitAPI({
        props: {
          reason: values.reason,
          remarkText: values.remarkText,
          correctionDate: values.correctionDate,
          responsible: values.responsible,
          arrInspectors: values.valuearrInspectors.map(e => e.value),
          idLocation: res.id,
          files: values.fAllIds.map(key => {
            return values.fById[key].id
          }),
          idGant: values.idGant,
        },
        url: addRemarksOneAPI.path({ id: match.params.idProject }),
        method: addRemarksOneAPI.type,
        history: history
      }))
    }).catch(err => console.log(err))

  }
  return <div className='App-paper'>
    <Typography variant='h6' style={{ marginBottom: '8px' }}>Создать замечание</Typography>
    <form autoComplete="off" onSubmit={handleSubmit} >
      <SetLocation />
      <Typography >Расположение: {form.values.location !== '' ? form.values.location : '_____________________'}</Typography>
      <SimpleAdd
        arr={arrProps}
        formName={formName}
      />
      <TableFile />
      <br/>
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
      <Button type="submit" color='primary'>Сохранить</Button>
    </form>
  </div>
}
const arrLocation = [
  { field: 'axes', label: 'Оси', type: 'text' },
  { field: 'ranks', label: 'Ряды', type: 'text' },
  { field: 'marks', label: 'Отметки', type: 'text' },
  { field: 'floors', label: 'Этажи', type: 'text' },
  { field: 'tiers', label: 'Ярусы', type: 'text' },
  { field: 'sections', label: 'Секции', type: 'text' },
  { field: 'premises', label: 'Помещения', type: 'text' },
  { field: 'place', label: 'Местоположение(если другое не заполнено)', type: 'text' },
]
function SetLocation() {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const handleChangeAuto = (field, value) => dispatch(actions.formChangeAsObj({ [field]: value }, { name: formName }))
  const form = useSelector(state => state.form[formLocation] || state.form.default)
  const handleSave = () => {
    const { values } = form
    const arr = []
    arrLocation.forEach(obj => {
      const val = values[obj.field]
      if (val !== '' && obj.field !== 'place') {
        arr.push(`${obj.label}: ${val}`)
      }
    })
    handleChangeAuto('location', arr.length > 0 ? arr.join(', ') : values.place)
    setOpen(false)
  }
  return <React.Fragment>
    <Button color='primary' onClick={() => setOpen(true)} >Заполнить расположение</Button>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{"Заполнить локацию"}</DialogTitle>
      <DialogContent id="alert-dialog-title">
        <SimpleAdd
          arr={arrLocation}
          formName={formLocation}
          gridProps={{ xs: 12 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSave} color="primary" autoFocus>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}


function TableFile() {
  const dispatch = useDispatch()
  const [loadingFile, setLoadingFile] = useState(false)
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoadingFile(true)
    let fileName = e.target.files[0].name
    const fileType = e.target.files[0].type
    dispatch(loadFileAPI({ file: e.target.files[0], path: addFile.path }))
      .then(res => {
        if (res.success) {
          dispatch(actions.formObjAdd({ id: res.id, name: fileName, type: fileType }, {
            field: 'fById',
            id: 'id' + res.id,
            name: formName
          }))
          dispatch(actions.formArrayAdd('id' + res.id, {
            field: 'fAllIds', name: formName
          }))
        }
        setLoadingFile(false)
      })
      .catch()
  }
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values = {} } = form
  const { fById = {}, fAllIds = [] } = values
  return <React.Fragment>
    <Button disabled={loadingFile} component="label" color='primary'>
      {loadingFile && <CircularProgress size={25} />}
      <span>Прикрепить файл</span>
      <input
        type="file"
        style={{ display: "none" }}
        onChange={(e) => _handleImageChange(e)}
      />
    </Button>
    <br/>
    {fAllIds.map(key => {
      const obj = fById[key]
      return <CustomChip key={key} row={key} obj={obj} formName={formName} />
    })}
  </React.Fragment>
}

function CustomChip({ row, obj, formName }) {
  const dispatch = useDispatch()
  const [deleted, setDeleted] = useState(false)
  const handleDelete = () => {
    setDeleted(true)
    dispatch(fetchDispath({
      param: {
        id: obj.id,
      },
      progress: false,
      request: delFile,
      buff: true
    }))
      .then(res => {
        setDeleted(false)
        if (!res) return;
        dispatch(actions.formArrayDelete(row, { field: 'fAllIds', name: formName }));
        dispatch(actions.formObjDelete({ id: row, field: 'fById', name: formName }));
      })
  }
  return <Chip
    disabled={deleted}
    key={row}
    icon={<DescriptionIcon />}
    label={obj.name}
    onDelete={() => handleDelete()}
  />
}