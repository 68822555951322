import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core';
import * as actions from '../../../reducers/form';
import { loadProviderById, updProvider, getCompanyAPI } from '../../../api/list'
import { fetchDispath } from '../../../functions/fetch'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 220,
    marginTop: 5,
    marginBottom: 3,
    width: '50%',
    overflowX: 'visible'

  },
}))
export default function CompaniesEdit(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const formName = 'ProviderEdit'
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.idProvider,
      },
      progress: true,
      request: loadProviderById,
    }))
      .then(res => {
        dispatch(actions.formInitialize({
          fullName: res.get.fullName,
          name: res.get.name,
          inn: res.get.inn,
          ogrn: res.get.ogrn,
          kpp: res.get.kpp,
          okpo: res.get.okpo,
          oktmo: res.get.oktmo,
          adress: res.get.adress,
          phone: res.get.phone,
          email: res.get.email
        }, { name: formName }))
      })
      .catch(err => console.log(err))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch, props.match.params.idProvider])
  const arrProps = [
    {
      field: 'fullName', type: 'text', label: 'Полное название компании*',
      validator: [value => value !== ''], errorText: 'Поле обязательно',
    },
    {
      field: 'name', type: 'text', label: 'Сокращенное название компании*',
      validator: [value => value !== ''], errorText: 'Поле обязательно',
    },
    {
      field: 'inn', type: 'text', label: 'ИНН(10-12 символов)*',
      validator: [value => value !== '', v => v.length >= 10 && v.length <= 12], errorText: 'Поле обязательно',
    },
    {
      field: 'ogrn', type: 'text', label: 'ОГРН(14 символов)',
      // validator: [value => value !== ''], errorText: 'Поле обязательно',
    },
    {
      field: 'kpp', type: 'text', label: 'КПП(9 символов)',
      validator: [v => v === '' || v.length === 9], errorText: 'Не правильное значение',
    },
    {
      field: 'okpo', type: 'text', label: 'ОКПО(8-10 символов)',
      // validator: [v => v === '' || v.length === 12], errorText: 'Не правильное значение',
    },
    {
      field: 'oktmo', type: 'text', label: 'ОКТМО(12 символов)',
      // validator: [v => v === ''], errorText: 'Не правильное значение',
    },
    { field: 'adress', type: 'text', label: 'Адрес', },
    { field: 'phone', type: 'text', label: 'Телефон', },
    { field: 'email', type: 'text', label: 'Почта', },
  ]
  const handleUpdate = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    const { fullName, name, inn, ogrn, kpp, okpo, oktmo, adress, phone, email } = values
    dispatch(actions.formSubmitAPI({
      props: {
        fullName,
        name,
        inn,
        ogrn,
        kpp,
        okpo,
        oktmo,
        adress,
        phone,
        email
      },
      url: updProvider.path({
        id: props.match.params.idProvider,
      }),
      method: updProvider.type,
      history: props.history
    }, formName))
  };
  const handleLoad = () => {
    dispatch(fetchDispath({
      querty: {
        inn: values.inn
      },
      progress: false,
      request: getCompanyAPI,
    }))
      .then(res => {
        if (res.success) {
          dispatch(actions.formChangeAsObj({
            fullName: res.get.fullName,
            shortName: res.get.shortName,
            inn: res.get.inn,
            ogrn: res.get.ogrn,
            kpp: res.get.kpp,
            okpo: res.get.okpo,
            oktmo: res.get.oktmo,
            adress: res.get.address,
            phone: res.get.phone,
            email: res.get.email
          }, { name: formName }))
        }
        else {
          dispatch(enqueueSnackbar({
            message: `Компания не найдена`,
            options: { variant: 'error' }
          }))
        }
      })
      .catch(err => console.log(err))
  }
  return (
    <div className='App-paper' >
      <Button onClick={handleLoad} color="primary" >Заполнить по ИНН</Button>
      <form autoComplete="off" onSubmit={handleUpdate} className={classes.main}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd formName={formName} arr={arrProps} title='Изменить поставщика' />
        </div>
        <div className={classes.main}>
          <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
          <Button type="submit" color="primary">Изменить</Button>
        </div>
      </form>
    </div>
  );
}
