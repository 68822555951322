import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Button, Typography, TextField, Table, TableBody, TableRow, TableCell, TableHead, TableContainer } from '@material-ui/core'
import { getKS3NotWork, addKS3 } from '../../api/formks2ks3'
import { fetchDispath } from '../../functions/fetch'
import * as actions from '../../reducers/form';
import { enqueueSnackbar } from '../../reducers/notifier'

const formName = 'addKS2'
export default function KS3Add({ history, match }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      byId: {},
      allIds: [],
      selected: [],
      dateStart: '',
      dateEnd: '',
      priceStart: 0,
      priceYear: 0,
      pricePeriod: 0
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byId, allIds = [], dateStart, dateEnd, pricePeriod, priceStart, priceYear } = form.values
  const onHandleClick = () => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idFacility
      },
      querty: {
        dateStart,
        dateEnd
      },
      progress: true,
      request: getKS3NotWork,
    })).then(res => {
      if(res.get.exist) {
        dispatch(enqueueSnackbar({
          message: `уже есть КС3 для этой даты`,
          options: { variant: 'error' }
        }))
        return;
      }
      let byId = {};
      const allIds = res.get.project.map((e, i) => {
        const id = 'id' + e.id + '_' + i
        byId[id] = e
        return id
      })
      dispatch(actions.formChangeAsObj({ byId, allIds, ...res.get.price }, { name: formName }))

    }).catch(err => console.log(err))
  }
  const handleChangeDate = (field, value) => {
    dispatch(actions.formChange(value, { name: formName, field }))
  }
  const handleSave = () => {
    dispatch(actions.formSubmitAPI({
      props: {
        dateStart,
        dateEnd,
        priceStart,
        priceYear,
        pricePeriod,
        arr: allIds.map(key => {
          const obj = byId[key]
          return {
            id: obj.id,
            priceStart: obj.priceStart,
            priceYear: obj.priceYear,
            pricePeriod: obj.pricePeriod,
          }
        })
      },
      url: addKS3.path({ id: match.params.idFacility }),
      method: addKS3.type,
      history: history
    }), formName)
  }
  const tableHead = [
    { id: 'number', name: '№', },
    { id: 'name', name: 'Наименование пусковых комплексов, этапов, объектов, видов выполненных работ, оборудования, затрат', },
    { id: 'priceStart', name: 'Стоимость  с начала проведения работ', },
    { id: 'priceYear', name: 'Стоимость  с начала года', },
    { id: 'pricePeriod', name: 'Стоимость   в том числе за отчетный период', },
  ];
  const arrHead = [{
    number: '',
    name: 'Всего работ и затрат, включаемых в стоимость работ',
    priceStart: priceStart,
    priceYear: priceYear,
    pricePeriod: pricePeriod,
  },
  {
    number: '',
    name: 'в том числе:',
    priceStart: '',
    priceYear: '',
    pricePeriod: '',
  }]
  return <div className='App-paper'>
    <Typography variant='h6'>Добавить акт выполненных работ КС2</Typography>
    <Grid container spacing={2}>
      <Grid item>
        <TextField
          label='Отчетный период (с)'
          onChange={(e) => { handleChangeDate('dateStart', e.target.value) }}
          value={dateStart || ''}
          type='date'
          InputLabelProps={{ shrink: true, }}
        />
      </Grid>
      <Grid item>
        <TextField
          label='Отчетный период (по)'
          onChange={(e) => { handleChangeDate('dateEnd', e.target.value) }}
          value={dateEnd || ''}
          type='date'
          InputLabelProps={{ shrink: true, }}
        />
      </Grid>
      <Grid item>
        <Button color='primary' component="span" onClick={onHandleClick}>Получить</Button>
      </Grid>
    </Grid>
    <TableContainer style={{ height: '60vh' }}>
      <Table size='small' stickyHeader >
        <TableHead>
          <TableRow>
            {tableHead.map((e, i) => (<TableCell key={i}>{e.name}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {arrHead.map((obj, i) => {
            return <TableRow key={i}>
              {tableHead.map((e, j) => (<TableCell key={j}>{obj[e.id]}</TableCell>))}
            </TableRow>
          })}
          {allIds.map((key, i) => {
            const obj = byId[key]
            console.log(obj)
            return <TableRow key={key} >
              <TableCell>{i + 1}</TableCell>
              <TableCell>{obj.name}</TableCell>
              <TableCell>{obj.priceStart}</TableCell>
              <TableCell>{obj.priceYear}</TableCell>
              <TableCell>{obj.pricePeriod}</TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
    <Button color='primary' component="span" onClick={handleSave}>Сохранить</Button>
  </div>
}