import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, Button, Typography, TextField, Table, TableBody, TableRow, TableCell, TableHead, InputBase, TableContainer } from '@material-ui/core'
import { getKS2NotWork, addKS2 } from '../../api/formks2ks3'
import { fetchDispath } from '../../functions/fetch'
import * as actions from '../../reducers/form';
import DeleteIcon from '@material-ui/icons/Delete';

const formName = 'addKS2'
export default function KS2Add ({ history, match }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      byId: {},
      allIds: [],
      selected: [],
      dateStart: '',
      dateEnd: ''
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject
      },
      progress: true,
      request: getKS2NotWork,
    })).then(res => {
      console.log(res)
      let byId = {}
      const allIds = res.get.map((e, i) => {
        const id = 'id' + e.id + '_' + i
        byId[id] = {
          ...e,
          countNew: e.count,
        }
        return id
      })
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formName }))

    }).catch(err => console.log(err))
  }, [dispatch, match.params.idProject])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byId, selected = [], allIds = [], dateStart, dateEnd } = form.values
  const handleSelected = (key) => {
    dispatch(actions.formArrayAdd(key, { field: 'selected', name: formName }))
    dispatch(actions.formArrayDelete(key, { field: 'allIds', name: formName }))
  }
  const handleUnSelected = (key) => {
    dispatch(actions.formArrayAdd(key, { field: 'allIds', name: formName }))
    dispatch(actions.formArrayDelete(key, { field: 'selected', name: formName }))
  }
  const handleChangeCount = (key, value) => {
    console.log(key, value)
    dispatch(actions.formObjChangeObj({ countNew: value }, { name: formName, id: key, field: 'byId' }))
  }
  const handleChangeDate = (field, value) => {
    dispatch(actions.formChange(value, { name: formName, field }))
  }
  const handleSave = () => {
    if (selected.length === 0) return;
    dispatch(actions.formSubmitAPI({
      props: {
        dateStart,
        dateEnd,
        arr: selected.map(key => {
          const obj = byId[key]
          return {
            id: obj.id,
            count: obj.countNew,
            groupId: obj.groupId,
            type: obj.type
          }
        })
      },
      url: addKS2.path({ id: match.params.idProject }),
      method: addKS2.type,
      history: history
    }), formName)
  }

  return <div className='App-paper'>
    <Typography variant='h6'>Добавить акт выполненных работ КС2</Typography>
    <Grid container spacing={2}>
      <Grid item>
        <TextField
          label='Отчетный период (с)'
          onChange={(e) => { handleChangeDate('dateStart', e.target.value) }}
          value={dateStart || ''}
          type='date'
          InputLabelProps={{ shrink: true, }}
        />
      </Grid>
      <Grid item>
        <TextField
          label='Отчетный период (по)'
          onChange={(e) => { handleChangeDate('dateEnd', e.target.value) }}
          value={dateEnd || ''}
          type='date'
          InputLabelProps={{ shrink: true, }}
        />
      </Grid>
    </Grid>
    <TableContainer style={{ height: '39vh' }}>
      <Table size='small' stickyHeader >
        <TableHead>
          <TableRow>
            {['', 'Наменование работ', 'Еденца измерения', 'Количество', 'Цена за еденицу'].map((e, i) => (<TableCell key={i}>{e}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {selected.map(key => {
            const obj = byId[key]
            return <TableRow key={key}>
              <TableCell>
                <IconButton
                  aria-label="Delete"
                  size='small'
                  onClick={(e) => handleUnSelected(key)}
                ><DeleteIcon fontSize='small' /></IconButton>
              </TableCell>
              <TableCell>{obj.name}</TableCell>
              <TableCell>{obj.storageUnit}</TableCell>
              <TableCell>
                <InputBase value={obj.countNew} type='number' onChange={(e) => handleChangeCount(key, e.target.value)} />
              </TableCell>
              <TableCell>{obj.price}</TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TableContainer style={{ height: '39vh' }}>
      <Table size='small' stickyHeader >
        <TableHead>
          <TableRow>
            {['Раздел','Наменование работ', 'Еденца измерения', 'Количество', 'Цена за еденицу', 'Стоимость'].map((e, i) => (<TableCell key={i}>{e}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {allIds.map(key => {
            const obj = byId[key]
            return <TableRow key={key} onClick={() => handleSelected(key)}>
              <TableCell>{obj.groupName}</TableCell>
              <TableCell>{obj.name}</TableCell>
              <TableCell>{obj.storageUnit}</TableCell>
              <TableCell>{obj.count}</TableCell>
              <TableCell>{obj.price}</TableCell>
              <TableCell>{obj.summ}</TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
    <Button color='primary' component="span" onClick={handleSave}>Сохранить</Button>
  </div>
}