import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SelectFile from './SelectFile'
import TableMaterial from './TableMaterial'
import ColumnEdit from './ColumnEdit'
import * as actions2 from '../../reducers/form';
import * as actions from '../../reducers/materialLoad';

const useStyle = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  input: {
    display: 'none',
  },
  edt: {
    padding: '1px', /* Поля вокруг текста */
  },
  main: {
    margin: theme.spacing(1)
  },
}));

const formName = 'MaterialLoad'
function getStepContent(step, isMaterial) {
  switch (step) {
    case 0:
      return <SelectFile isMaterial={isMaterial} formName={formName} />
    case 1:
      return <TableMaterial />
    case 2:
      return <ColumnEdit />
    default:
      return 'Unknown step';
  }
}
export default function MaterialLoad({ match, history, isMaterial }) {
  const [activeStep, setActiveStep] = useState(0)
  const classes = useStyle()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions2.formInitialize({ start: '1', end: '4', file: '', fileId: 19, estimate: false, consist: 'add', valueConsist:{ value: 'add', label: 'Добавить' } }, { name: formName }))
    return () => {
      dispatch(actions2.formDestroy({ name: formName }))
      dispatch(actions.clearMaterialLoad())
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const materialLoad = useSelector(state => state.materialLoad)
  const { values } = form
  const handleNext = () => {
    switch (activeStep) {
      case 0: {
        dispatch(actions.parseFileAPI({
          id: values.fileId,
          end: values.end,
          start: values.start,
        }))
          .then(res => setActiveStep(1))
        return
      }
      case 1: {
        setActiveStep(2)
        return
      }
      case 2: {
        const params = {
          idProject: match.params.id,
          arr: materialLoad.allIds.map(key => {
            return materialLoad.byId[key]
          }),
          column: materialLoad.columnNew,
          estimate: values.estimate,
          consist: values.consist
        }
        console.log(isMaterial)
        const url = (isMaterial) ? '/materials/many' : `/projects/${match.params.idProject}/work/load`
        dispatch(actions2.formSubmitAPI({
          props: params,
          url: url,
          method: 'POST',
          history: history
        }))
        return
      }
      default: {
        return 'Unknown step';
      }
    }
  };
  const handleBack = () => {
    if (activeStep === 0) return history.goBack()
    setActiveStep(activeStep - 1)
  };
  return <div className={classes.root}>
    <Stepper activeStep={activeStep}>
      {['Выбрать файл', 'Строки', 'Колонки'].map((label, index) => {
        const props = {};
        const labelProps = {};
        return (
          <Step key={label} {...props}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
    <div>
      {activeStep === 3 ? (
        <Button onClick={() => setActiveStep(0)} className={classes.button}>
          Загрузить еще
        </Button>
      ) : (
          <div>
            {getStepContent(activeStep, isMaterial)}
            <div>
              <Button onClick={handleBack} className={classes.button}                >
                Назад
              </Button>
              <Button
                disabled={values.fileId === 0}
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === 2 ? 'Завершить' : 'Далее'}
              </Button>
            </div>
          </div>
        )}
    </div>
  </div>
}
