import React from 'react'
import { API_BO } from '../conf'
import Home from '../pages/home/Home'
import MaterialWorkLoad from '../components/loadMaterialWork/MaterialLoad'

import * as facility from '../pages/facility/index'
import * as facilityAccess from '../pages/facilityAccess/index'
import * as facilityMachine from '../pages/facilityMachine/index'
import * as facilityFiles from '../pages/facilityFiles/index'

import * as orders from '../pages/orders/index'

import * as material from '../pages/material/index'

import ProjectAdd from '../pages/projectAdd/ProjectAdd'
import ProjectSettings from '../pages/projectSettings/ProjectSettings'
import StorageMaterial from '../pages/storageMaterial/StorageMaterial'
import StorageDynamics from '../pages/storageMaterial/StorageDynamics';
// Пока отключим не реализован 
// import WorkAddMany from '../pages/work/WorkAddMany'
import * as work from '../pages/work/index'
// import WorkAddOne from '../pages/work/WorkAddOne'
// import WorkList from '../pages/work/WorkList'
// import WorkLoad from '../pages/work/WorkLoad'
// import WorkEdit from '../pages/work/WorkEdit'
// import WorkPrice from '../pages/work/WorkPrice'
//list
import * as equipment from '../pages/list/equipment/index'
import * as users from '../pages/list/users/index';
import * as provider from '../pages/list/provider/index'
import * as storageunit from '../pages/list/storageUnit/index';
import * as workTemplate from '../pages/list/workTempalte/index';
import * as position from '../pages/list/position/index';
import * as reportTemplates from '../pages/list/reportTemplates/index'
import * as companies from '../pages/list/companies/index'
import * as workEstimate from '../pages/work-estimate/index'
import * as resources from '../pages/list/resources/index'
import * as machine from '../pages/list/machine/index'
import * as checklist from '../pages/list/checklist/index'
// documents
// import * as AOC from '../pages/documents/actOfCompetitiotn/index'
import * as shipmentConsumables from '../pages/documents/shipmentConsumables/index'
import * as UseTool from '../pages/documents/useTool/index'
import * as removalPMaterial from '../pages/documents/removalPMaterial/index'
import * as ToolWrite from '../pages/documents/toolWrite/index'
import * as shipmentMaterial from '../pages/documents/shipmentMaterial/index'
import * as arendaEquipment from '../pages/documents/arendaEquipment/index'
import * as reciptMaterials from '../pages/documents/receiptMaterials/index'
import * as orderMaterialProvider from '../pages/documents/orderProvider/index'

import * as storageEquipmentRemoval from '../pages/storageEquipment/index';
import * as acceptance from '../pages/acceptance/index'
import * as worker from '../pages/worker/index'
import * as profile from '../pages/profile/index'
import * as menu from '../pages/menu/index'
import * as workerRating from '../pages/workerrating/index'
import * as ourStorage from '../pages/storage/index'
import * as Analytics from '../pages/projectAnalitycs/index'
import * as projectWorker from '../pages/projectWorker/index'
import * as InventoryList from '../pages/inventory/index'
import * as estimate from '../pages/estimate/index'
import * as outfitPlan from '../pages/documents/orderOutfitPlan/index'
import * as outfitFact from '../pages/documents/orderOutfitFact/index'
import * as ourfitDay from '../pages/outfitDay/index'
import * as moveStorage from '../pages/documents/moveStorage/index'
import * as carRental from '../pages/documents/carRental/index'
import * as team from '../pages/team/index'
import * as ocr from '../pages/ocr/index'
import * as workDoc from '../pages/workDoc/index'
import * as formks2 from '../pages/ks2/index'
import * as formks3 from '../pages/ks3/index'
import * as accounts from '../pages/accounts/index'
import * as acts from '../pages/acts/index';
import * as inputControl from '../pages/inputControl/index';
import * as waybill from '../pages/waybill/index';
import * as remarks from '../pages/remarks/index';
import * as tenderOur from '../pages/tenderOur/index'

// import * as StorageInventory from '../pages/storageInventory/index'
import Workplace from '../pages/workplase/Workplace'
import LoadGrand from '../pages/grand/LoadGrand'
import AnalyticsMenu from '../pages/menu/AnalyticMenu'
import Test from '../pages/test/Test'
import OrderMaterialProvider from '../pages/documents/orderMaterialProvider/OrderMaterialProvider'
import * as multiProject from '../pages/multiProject/index'
import Gant from '../pages/gant/Gant'
import GantPrice from '../pages/gant-price/GantPrice'
import GantShare from '../pages/gant-share/GantShare'
import Schedule from '../pages/schedule/Schedule'
import Calendar from '../pages/calendar/Сalendar'
import ZhorList from '../pages/documents/zhor/ZhorLIst'
import * as certificates from '../pages/certificates/index'
import * as machineCalendar from '../pages/machineCalendar/index'
import * as adminPanel from '../pages/adminPanel/index'

const ADD = '/add';
const ID = '/:id';
const project = API_BO + '/projects/:idProject'
const pAnalytic = project + '/analytics';
const pWorker = project + '/worker';
const pWork = project + '/work';
const pUseTool = project + '/usetool';
const pUseToolTransfer = pUseTool + '/transfer';
const pUseToolAcept = pUseTool + '/acceptance';
const pEstimate = project + '/estimate'
const pCertificates = project + '/certificates'
const pWayBill = project + '/waybill'

const pStorage = API_BO + '/projects/:id/storage';
const pStorageMaterial = pStorage + '/materials';
const pStorageMaterialMove = pStorageMaterial + '/move';
const pStorageEquipment = pStorage + '/equipment';
const pStorageInventory = pStorage + '/inventory';

const pShipmentMaterials = project + '/shipment/materials';
const pShipmentEquipment = project + '/shipment/equipment';
const pCarRental = project + '/shipment/carrental'
const pShipmentOrder = project + '/shipment/order';
const pShipmentArenda = API_BO + '/projects/:id/shipment/rent';
const pReceptMaterials = API_BO + '/projects/:id/receipt/materials';
const pAcceptance = API_BO + '/projects/:id/acceptance'
const pDelivery = API_BO + '/projects/:id/delivery'
// const pAOC = API_BO + '/projects/:id/aoc';
const pMaterial = API_BO + '/projects/:id/material';
const pOrder = project + '/orders';
const pSettings = project + '/settings'
const pInputControl = project + '/input-control'
const pMachineCalendar = project + '/machine-calendar'
const pListCalendar = project + '/machine-list'
const pMachineWorkplace = project + '/machine-workplace'

const storage = API_BO + '/storage';
const sBldg = storage + '/bldg';
const sDoc = storage + '/doc';
const sDocAddMaterial = sDoc + '/addmaterial';
const sDocMoveProject = sDoc + '/move/project';

const sReport = storage + '/report';

const pUsers = API_BO + '/users';
const pFacility = API_BO + '/facility';
const workerPath = API_BO + '/worker';
const workerRatingPath = workerPath + '/rating';
const pProfile = API_BO + '/profile';
const pProvider = API_BO + '/provider';
const pEquipment = API_BO + '/equipment';
const pCompanies = API_BO + '/companies';
const pChecklist = API_BO + '/checklist';
const pWorkEstimate = API_BO + '/work-estimate';
const pResources = API_BO + '/resources';
const pPosition = API_BO + '/position';
const pMachine = API_BO + '/machine';
const pStorageUnit = API_BO + '/storageunit';
const pTemplates = API_BO + '/templates';
const pInventory = API_BO + '/inventory';
const pWorkTemplate = API_BO + '/worktemplate';
const pInventoryMoveProject = pInventory + '/move/project';

const pRemoval = '/removal';
const pRemovalAdd = '/removal/add';
const pRemovalId = '/removal/:idRemoval';

const pShare = '/share/:id'
const pOutfitPlan = project + '/outfit/plan'
const pOutfitFact = project + '/outfit/fact'
const pZhorList = project + '/zhor'
const pOutfitPlanFact = project + '/outfit/planfact'
const pRemarks = project + '/remarks'
const pProd = project + '/prodmenu'
const pTeam = project + '/team'
const pIHW = project + '/act/ihw'
const pOcr = project + '/ocr'
const pOcrDoc = pOcr + '/doc'
const pOcrR = pOcr + '/recognition'
const pOcrT = pOcr + '/tack'

const pWorkDoc = API_BO + '/workdoc'

const pKs2 = project + '/form/ks2'
const pKs3 = pFacility + '/:idFacility/form/ks3'
const pResponsiblePerson = pFacility + '/:idFacility/responsible'
const pFacilityMachine = pFacility + '/:idFacility/machine'
const pFacilityFiles = pFacility + '/:idFacility/files'
const pGant = project + '/form/gant'
const pSchedule = project + '/form/schedule'

const pAccounts = '/accounts'

const pOrderMaterialProvider = project + '/order/provider'

const pCalendar = project + '/calendar'

const pAdmin = API_BO + '/a-panel'
const pTariff = '/tariff'
const pRegion = '/regions'
const pSpecializations = '/specializations'
const pPayments = '/payments'
const pAccountT = '/accounts'
const pTender = API_BO + '/tender'
const pTenderOur = pTender + '/our'
export function routAdmin() {
  const g = localStorage.getItem('g')
  if (g==='false') return []
  return [
    { path: pAdmin, component: menu.admin },
    { path: pAdmin + pTariff, component: adminPanel.tariffList },
    { path: pAdmin + pTariff + ADD, component: adminPanel.tariffAdd },
    { path: pAdmin + pTariff + '/:idDoc', component: adminPanel.tariffOne },
    { path: pAdmin + pPayments, component: adminPanel.paymentsList },
    { path: pAdmin + pPayments + ADD, component: adminPanel.paymentsAdd },
    { path: pAdmin + pAccountT, component: adminPanel.accountList },
    { path: pAdmin + pAccountT + ADD, component: adminPanel.accountAdd },
    { path: pAdmin + pAccountT + '/:idDoc', component: adminPanel.accountOne },
    { path: pAdmin + pAccountT + '/:idDoc/:idTariff', component: adminPanel.accountTariffEdit },

    { path: pAdmin + pRegion, component: adminPanel.regionList },
    { path: pAdmin + pRegion + ADD, component: adminPanel.regionAdd },
    { path: pAdmin + pRegion + '/:idDoc', component: adminPanel.regionOne },

    { path: pAdmin + pSpecializations, component: adminPanel.specializationList },
    { path: pAdmin + pSpecializations + ADD, component: adminPanel.specializationAdd },
    { path: pAdmin + pSpecializations + '/:idDoc', component: adminPanel.specializationOne },

  ]
}
export default function rout() {
  return [
    { path: API_BO + '/', component: Home },
    { path: API_BO + '/test', component: Test },

    { path: API_BO + pAccounts, component: accounts.list },
    { path: API_BO + '/lists', component: menu.list },
    { path: API_BO + '/ocr/setting', component: ocr.ocrSetting },
    { path: pProfile, component: profile.profile },
    { path: pProfile + '/edit', component: profile.edit },
    { path: pProfile + '/editpass', component: profile.editPass },
    { path: API_BO + '/projects/add', component: ProjectAdd },
    { path: project, component: menu.project },
    { path: project + '/documents', component: menu.document },
    { path: project + '/est-menu', component: menu.estimate },
    { path: project + '/workplace', component: Workplace },

    { path: API_BO + '/multi', component: menu.multi },
    { path: API_BO + '/multi/material/load', component: multiProject.loadMaterial },
    { path: API_BO + '/multi/material', component: multiProject.getMaterial },

    // tender 
    { path: pTender, component: menu.tender },
    { path: pTenderOur, component: tenderOur.list },
    { path: pTenderOur + ADD, component: tenderOur.add },
    { path: pTenderOur + '/:idDoc', component: tenderOur.one },
    { path: pTenderOur + '/:idDoc/lots/add', component: tenderOur.addLot },
    

    // work doc
    { path: pWorkDoc, component: workDoc.list },
    { path: pWorkDoc + ADD, component: workDoc.add },
    { path: pWorkDoc + '/:idDoc', component: workDoc.one },
    { path: pWorkDoc + '/:idDoc/props', component: workDoc.props },
    // form ks2
    { path: pKs2, component: formks2.list },
    { path: pKs2 + '/table', component: formks2.table },
    { path: pKs2 + ADD, component: formks2.add },
    { path: pKs2 + '/:idDoc', component: formks2.one },

    { path: pMachineCalendar, component: machineCalendar.calendar },
    { path: pMachineWorkplace, component: machineCalendar.workplace },
    { path: pListCalendar, component: machineCalendar.list },
    { path: pListCalendar + '/:idDoc', component: machineCalendar.one },
    // gant
    { path: pGant, component: Gant },
    { path: pGant + '/price', component: GantPrice },
    { path: pSchedule, component: Schedule },
    // zhor
    { path: pZhorList, component: ZhorList },

    // analytics -------------------
    { path: pAnalytic, component: AnalyticsMenu },
    { path: pAnalytic + '/project', component: Analytics.project },
    { path: pAnalytic + '/material', component: Analytics.material },
    { path: pAnalytic + '/worker', component: Analytics.projectWorker },
    //  ---------------------------
    // project worker -------------
    { path: pWorker, component: projectWorker.list },
    { path: pWorker + ADD, component: projectWorker.add },
    { path: pWorker + '/attent', component: projectWorker.attend },
    { path: pWorker + '/resources', component: projectWorker.resources },
    { path: pWorker + '/ending', component: projectWorker.ending },
    // remarks 
    { path: pRemarks, component: remarks.list },
    { path: pRemarks + ADD, component: remarks.add },
    { path: pRemarks + '/:idDoc', component: remarks.one },
    //  ---------------------------
    { path: pSettings, component: ProjectSettings },
    { path: pStorage, component: menu.storage },
    { path: pStorageMaterial, component: StorageMaterial },
    { path: pStorageMaterial + pRemoval, component: removalPMaterial.list },
    { path: pStorageMaterial + pRemovalAdd, component: removalPMaterial.add },
    { path: pStorageMaterial + pRemovalId, component: removalPMaterial.one },
    { path: pStorageMaterial + '/dynamics', component: StorageDynamics },
    // move storage
    { path: pStorageMaterialMove, component: moveStorage.list },
    { path: pStorageMaterialMove + ADD, component: moveStorage.add },
    { path: pStorageMaterialMove + '/:idDoc', component: moveStorage.one },
    // car rental
    { path: pCarRental, component: carRental.list },
    { path: pCarRental + ADD, component: carRental.add },
    { path: pCarRental + '/:idDoc', component: carRental.one },
    // delivery input control
    { path: pInputControl, component: inputControl.list },
    { path: pInputControl + '/:idDoc', component: inputControl.one },
    // project/:idProject/certificates
    { path: pCertificates, component: certificates.list },
    { path: pCertificates + ADD, component: certificates.add },
    { path: pCertificates + '/:idDoc', component: certificates.one },

    { path: pWayBill, component: waybill.list },
    { path: pWayBill + '/:idDoc', component: waybill.one },
    { path: pWayBill + '/:idDoc/edit', component: waybill.edit },

    { path: pStorageEquipment, component: storageEquipmentRemoval.list },
    { path: pStorageEquipment + pRemoval, component: storageEquipmentRemoval.removalList },
    { path: pStorageEquipment + pRemovalAdd, component: storageEquipmentRemoval.removalAdd },
    { path: pStorageEquipment + pRemovalId, component: storageEquipmentRemoval.removalById },
    { path: pStorageInventory, component: InventoryList.projectList },
    { path: pStorageInventory + pRemoval, component: InventoryList.moveSList },
    { path: pStorageInventory + pRemovalAdd, component: InventoryList.moveSAdd },
    { path: pStorageInventory + pRemovalId, component: InventoryList.movePById },

    { path: pProd, component: menu.prod },

    { path: pDelivery + '/material', component: acceptance.material },
    { path: pDelivery + '/doc', component: acceptance.list },
    { path: pDelivery + '/doc/:idAccept', component: acceptance.one },

    { path: pAcceptance, component: acceptance.listDoc },
    { path: pAcceptance + '/:idAccept', component: acceptance.oneDoc },

    // { path: pAOC, component: AOC.list },
    // { path: pAOC + ADD, component: AOC.add },
    // { path: pAOC + '/:idDoc', component: AOC.one },

    { path: pReceptMaterials, component: reciptMaterials.list },
    { path: pReceptMaterials + ADD, component: reciptMaterials.add },
    { path: pReceptMaterials + '/:idDoc', component: reciptMaterials.one },

    { path: pOrderMaterialProvider, component: orderMaterialProvider.list },
    { path: pOrderMaterialProvider + ADD, component: orderMaterialProvider.add },
    { path: pOrderMaterialProvider + '/summary', component: orderMaterialProvider.summary },
    { path: pOrderMaterialProvider + '/:idDoc', component: orderMaterialProvider.one },


    { path: pOrder + '/my', component: orders.my },
    { path: pOrder + '/my' + ADD, component: orders.myAdd },
    { path: pOrder + '/lenta', component: orders.lenta },
    { path: pOrder + '/process', component: orders.processing },

    { path: pTeam, component: team.list },
    { path: pTeam + ADD, component: team.add },
    { path: pTeam + '/:idTeam', component: team.one },

    // /projects/:idProject/act/ihw
    { path: pIHW, component: acts.ahwList },
    { path: pIHW + ADD, component: acts.ahwAdd },
    { path: pIHW + ID, component: acts.ahwOne },

    { path: pOcrDoc, component: ocr.list },
    { path: pOcrDoc + '/:idDoc', component: ocr.one },
    { path: pOcrR, component: ocr.ocrStart },
    { path: pOcrT, component: ocr.tackList },

    { path: pMaterial, component: material.list },
    { path: pMaterial + '/load', render: (props) => <MaterialWorkLoad {...props} isMaterial={true} /> },
    { path: pMaterial + '/grand', component: LoadGrand },
    { path: pMaterial + '/basket', component: material.basket },
    { path: pMaterial + '/doc', component: material.loadFromDoc },
    { path: pMaterial + '/price', component: material.price },
    { path: pMaterial + ADD, component: material.add },
    { path: pMaterial + '/:idMaterial', component: material.one },
    { path: pMaterial + '/:idMaterial/edit', component: material.edit },
    { path: pMaterial + '/:idMaterial/history', component: material.history },

    { path: pShipmentMaterials, component: shipmentMaterial.list },
    { path: pShipmentMaterials + ADD, component: shipmentMaterial.add },
    { path: pShipmentMaterials + '/:idShipment', component: shipmentMaterial.one },
    // { path: pShipmentMaterials + '/:idShipment/:idMaterial/edit', component: shipmentMaterial.oneAdd },  пока выключена 

    { path: pShipmentEquipment, component: shipmentConsumables.list },
    { path: pShipmentEquipment + ADD, component: shipmentConsumables.add },
    { path: pShipmentEquipment + '/:idShipment', component: shipmentConsumables.one },
    { path: pShipmentEquipment + '/:idShipment/add', component: shipmentConsumables.oneAdd },
    { path: pShipmentEquipment + '/:idShipment/:idMaterial/edit', component: shipmentConsumables.oneAdd },

    { path: pShipmentOrder, component: OrderMaterialProvider },

    { path: pShipmentArenda, component: arendaEquipment.list },
    { path: pShipmentArenda + ADD, component: arendaEquipment.add },
    { path: pShipmentArenda + '/list', component: arendaEquipment.listWriteOff },
    { path: pShipmentArenda + '/list/:idArenda', component: arendaEquipment.byIdWriteOff },

    { path: pWork, component: work.list },
    { path: pWork + ADD, component: work.addOne },
    { path: pWork + '/price', component: work.price },
    { path: pWork + '/load-template', component: work.load },
    { path: pWork + '/load', render: (props) => <MaterialWorkLoad {...props} isMaterial={false} /> },
    { path: pWork + ID, component: work.edit },

    { path: pUseToolTransfer, component: UseTool.list },
    { path: pUseToolTransfer + ADD, component: UseTool.add },
    { path: pUseToolTransfer + ID, component: UseTool.one },
    { path: pUseToolAcept, component: ToolWrite.list },
    { path: pUseToolAcept + ADD, component: ToolWrite.add },
    { path: pUseToolAcept + ID, component: ToolWrite.one },

    { path: pUsers, component: users.list },
    { path: pUsers + ADD, component: users.add },
    { path: pUsers + '/:idUser', component: users.one },

    { path: pOutfitPlan, component: outfitPlan.list },
    { path: pOutfitPlan + '/day', component: ourfitDay.plan },
    { path: pOutfitPlan + ADD, component: outfitPlan.add },
    { path: pOutfitPlan + '/:id', component: outfitPlan.one },
    { path: pOutfitPlan + '/:id/edit', component: outfitPlan.edit },
    { path: pOutfitPlan + '/:id/set', component: outfitPlan.setPlan },

    { path: pOutfitFact, component: outfitFact.list },
    { path: pOutfitFact + '/day', component: ourfitDay.fact },
    { path: pOutfitFact + '/:id', component: outfitFact.one },

    { path: pOutfitPlanFact + '/day', component: ourfitDay.planfact },

    { path: pProvider, component: provider.list },
    { path: pProvider + ADD, component: provider.add },
    { path: pProvider + '/:idProvider', component: provider.one },
    { path: pProvider + '/:idProvider/edit', component: provider.edit },
    { path: pFacility, component: facility.list },
    { path: pFacility + ADD, component: facility.add },
    { path: pFacility + '/our', component: facility.our },
    { path: pFacility + '/our' + ID, component: facility.ourOne },
    { path: pFacility + '/our' + ID + '/menu', component: menu.facility },
    { path: pFacility + ID, component: facility.one },
    { path: pFacility + '/:id/edit', component: facility.edit },
    { path: pFacility + '/:idFacility/add', component: ProjectAdd },

    // facility access
    { path: pFacility + ID + '/access', component: facilityAccess.list },
    { path: pFacility + ID + '/access' + ADD, component: facilityAccess.add },

    // form ks3
    { path: pKs3, component: formks3.list },
    { path: pKs3 + ADD, component: formks3.add },
    { path: pKs3 + '/:idDoc', component: formks3.one },
    // facility responsible persons 
    { path: pResponsiblePerson, component: facility.resList },
    { path: pResponsiblePerson + ADD, component: facility.resAdd },
    { path: pResponsiblePerson + ID, component: facility.resOne },
    // facility machine 
    { path: pFacilityMachine, component: facilityMachine.list },
    { path: pFacilityMachine + ADD, component: facilityMachine.add },
    // facility files 
    { path: pFacilityFiles, component: facilityFiles.list },
    // { path: pFacilityMachine + ADD, component: facilityFiles.add },

    { path: pEquipment, component: equipment.list },
    { path: pEquipment + ADD, component: equipment.add },
    { path: pEquipment + ID, component: equipment.edit },
    { path: pStorageUnit, component: storageunit.list },
    { path: pStorageUnit + ADD, component: storageunit.add },
    { path: pStorageUnit + ID, component: storageunit.edit },
    { path: pPosition, component: position.list },
    { path: pPosition + ADD, component: position.add },
    { path: pPosition + ID, component: position.edit },

    { path: pMachine, component: machine.list },
    { path: pMachine + ADD, component: machine.add },
    { path: pMachine + ID, component: machine.one },
    { path: pMachine + ID + '/edit', component: machine.edit },

    { path: pMachine + ID + '/state' + ADD, component: machine.addState },
    { path: pMachine + ID + '/checklist' + ADD, component: machine.addChecklist },
    { path: pMachine + ID + '/state/:idState/edit', component: machine.editState },

    { path: pWorkTemplate, component: workTemplate.list },
    { path: pWorkTemplate + ADD, component: workTemplate.add },
    { path: pWorkTemplate + ID, component: workTemplate.edit },
    { path: pCompanies, component: companies.list },
    { path: pCompanies + ADD, component: companies.add },
    { path: pCompanies + ID, component: companies.one },
    { path: pCompanies + ID + '/edit', component: companies.edit },

    { path: pWorkEstimate, component: workEstimate.list },
    { path: pWorkEstimate + '/nf' + ADD, component: workEstimate.add },
    { path: pWorkEstimate + '/nf' + ID, component: workEstimate.edit },
    { path: pWorkEstimate + '/f' + ADD, component: workEstimate.addFolder },
    { path: pWorkEstimate + '/f' + ID, component: workEstimate.editFolder },

    { path: pChecklist, component: checklist.list },
    { path: pChecklist + ADD, component: checklist.add },
    { path: pChecklist + ID, component: checklist.one },

    { path: pResources, component: resources.list },
    { path: pResources + '/nf' + ADD, component: resources.add },
    { path: pResources + '/nf' + ID, component: resources.edit },
    { path: pResources + '/f' + ADD, component: resources.addFolder },
    { path: pResources + '/f' + ID, component: resources.editFolder },

    // our storage ------------------------------------------------------------------------------
    { path: API_BO + '/storagemenu', component: menu.ourStorage },
    { path: sBldg, component: ourStorage.listStorage },
    { path: sBldg + ADD, component: ourStorage.addStorage },
    // { path: sBldg + '/:idStorage', component: ourStorage.oneStorage },
    { path: sBldg + '/:idStorage', component: ourStorage.updStorage },
    { path: sDocMoveProject, component: ourStorage.docMoveToProjectList },
    { path: sDocMoveProject + ADD, component: ourStorage.docMoveToProjectAdd },
    { path: sDocMoveProject + '/:idDoc', component: ourStorage.docMoveToProjectById },
    { path: sDocAddMaterial, component: ourStorage.docMaterialAddList },
    { path: sDocAddMaterial + ADD, component: ourStorage.docMaterialAddAdd },
    { path: sDocAddMaterial + '/:idDoc', component: ourStorage.docMaterialAddById },
    { path: sReport + '/remains', component: ourStorage.reportRemains },
    // end our storage--------------------------------------------------------------------------------
    { path: workerPath, component: worker.list },
    { path: workerPath + '/report', component: worker.oneWork },
    { path: workerPath + ADD, component: worker.add },
    { path: workerRatingPath, component: workerRating.list },
    { path: workerRatingPath + ADD, component: workerRating.add },
    { path: workerRatingPath + ID, component: workerRating.one },
    { path: workerPath + ID, component: worker.one },
    { path: workerPath + '/:id/edit', component: worker.edit },

    { path: pTemplates, component: reportTemplates.list },
    { path: pTemplates + ID, component: reportTemplates.one },

    { path: pInventory, component: InventoryList.list },
    { path: pInventoryMoveProject, component: InventoryList.movePList },
    { path: pInventoryMoveProject + ADD, component: InventoryList.movePAdd },
    { path: pInventoryMoveProject + ID, component: InventoryList.movePById },

    { path: pEstimate, component: estimate.list },

    //share 
    { path: pShare + '/estimate', component: estimate.share },
    { path: pShare + '/gant', component: GantShare },

    { path: pCalendar, component: Calendar }
  ]
}

// заготовки не удалять 
//  check('storage:read',{id:idProject, accessId:idProject})&&{path:API_BO+'/projects/:id/storage/equipment', component: StorageMaterial},
//  check('storage:read',{id:idProject, accessId:idProject})&&{path:API_BO+'/projects/:id/storage/equipment/dynamics', component: StorageDynamics},
//  check('storage:read',{id:idProject, accessId:idProject})&&{path:API_BO+'/projects/:id/storage/arenda', component: StorageMaterial},
// check('inventory:read')&&{path:API_BO+'/inventory/:id', component: InventoryList.one},
// check('shipment:create')&&{path:API_BO+'/shipment/add', component: ShipmentAdd},
// check('equipment:read')&&{path:API_BO+'/projects/:idProject/shipment/arenda/:idShipment', component: ShipmentMaterial},