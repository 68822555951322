import React, { useState, useEffect, useCallback } from 'react'
import { TextField, Typography, Button, Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { fetchDispath } from '../../functions/fetch'
import { getWorkerResource } from '../../api/project-worker'

import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import {
  getProjectGantScheduleCalendar,
  getProjectGantScheduleCalendarBusy
} from '../../api/form-gant.api'
import CalendarDialog from './CalendarDialog'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function Calendar({ history, match }) {
  const classes = useStyles();
  const [date, setDate] = useState(moment().format('YYYY-MM'))
  const [arr, setArr] = useState([])

  const [objCalendar, setObjCalendar] = useState({})
  const [resourceProject, setResourceProject] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [reload, setReload] = useState(false)
  const [dialogProps, setDialogProps] = useState({
    date: '2000-01-01',
    work: '',
    resources: '',
    resourceId: 0,
    id: 0,
    hours: 0,
    workers: [],
    errorCount: false
  })

  const dispatch = useDispatch()
  const idProject = match.params.idProject

  useEffect(() => {
    const dateNow = moment(date, 'YYYY-MM').startOf('M')
    const dateNext = moment(dateNow).add(1, 'M')
    const count = dateNext.diff(dateNow, 'd')
    let start = dateNow.days()
    let end = dateNow.endOf('M').days()
    if (start === 0) start = 7
    if (end === 0) end = 7
    start = start - 1
    end = 7 - end
    const arrD = Array.apply(null, Array(start)).map(e => '')
    Array.apply(null, Array(count)).forEach((e, i) => {
      arrD.push(i + 1)
    })
    Array.apply(null, Array(end)).forEach((e, i) => {
      arrD.push('')
    })
    setArr(arrD)
  }, [date])
  const loadDate = useCallback(() => {
    const s = moment(date, 'YYYY-MM').startOf('M')
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      querty: {
        start: s.format('YYYY-MM-DD'),
        end: s.endOf('M').format('YYYY-MM-DD')
      },
      progress: true,
      request: getProjectGantScheduleCalendar,
    })).then(res => {
      let arrObj = []
      res.get.forEach(e => {
        const d = moment(e.date).get('D')
        if (arrObj[d]) {
          arrObj[d].push(e)
        }
        else {
          arrObj[d] = [e]
        }
      })
      setObjCalendar(arrObj)
    })
  }, [dispatch, date, idProject])

  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getWorkerResource,
    })).then(res => {
      setResourceProject(res.get);
    })
  }, [dispatch, idProject])
  const handleAddMiss = (add) => {
    setDate(moment(date, 'YYYY-MM').add(add, 'M').format('YYYY-MM'))
  }
  useEffect(() => {
    loadDate()
  }, [loadDate])
  useEffect(() => {
    if (!reload) return;
    setReload(false)
    loadDate()
  }, [loadDate, reload])
  return <div>
    <CalendarDialog
      open={openDialog}
      onClose={() => { setReload(true); setOpenDialog(false) }}
      dialogProps={dialogProps}
      resourceProject={resourceProject}
      idProject={idProject}
    />
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item>
        <Typography variant='h6'>Рабочий календарь</Typography>
      </Grid>
      <Grid item>
        <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Button onClick={() => handleAddMiss(-1)}>{"<"}</Button>
        </Grid>
        <Grid item>
          <form className={classes.container} noValidate>
            <TextField
              id="date"
              type="month"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </form>
        </Grid>
        <Grid item>
          <Button onClick={() => handleAddMiss(1)}>{">"}</Button>
        </Grid>

      </Grid>
    </Grid>
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
    >
      {Array.apply(null, Array(arr.length / 7)).map((e, i) => {
        return <Grid
          key={i}
          item
          container
          direction="row"
          justifyContent="space-between"
          spacing={3}
          alignItems="stretch"
        >
          {Array.apply(null, Array(7)).map((e, j) => {
            const d = arr[i * 7 + j]
            return <Grid
              key={i + '_' + j}
              item
              style={{ width: '14%', }}

            >
              <DayCalendar
                key={i}
                day={d}
                objCalendar={objCalendar}
                setOpenDialog={() => setOpenDialog(true)}
                setDialogProps={setDialogProps}
                idProject={idProject}
              />
            </Grid>
          })}
        </Grid>
      })}
    </Grid>
  </div>
}


function DayCalendar({ day, objCalendar, setOpenDialog, setDialogProps, idProject }) {
  const dispatch = useDispatch()
  if (day === '' || typeof objCalendar[day] === 'undefined') {
    return <div className='App-paper' style={{ width: '100%', minHeight: 300 }}>{day}</div>
  }
  const arr = objCalendar[day]
  const handleClick = (obj) => {
    const s = moment(obj.date, 'YYYY-MM').startOf('M').add(day - 1, 'd')
    const arrFiltered = arr.map(e => ({ ...e, key: e.id + '_' + e.resourceId })).filter(d => d.id + '_' + d.resourceId !== obj.id + '_' + obj.resourceId)
    console.log(arrFiltered)
    if (arrFiltered.length === 0) {
      setDialogProps({
        date: s.format('YYYY-MM-DD'),
        work: obj.work,
        resources: obj.resources,
        resourceId: obj.resourceId,
        id: obj.id,
        hours: obj.hours,
        busy: []
      })
      setOpenDialog();
      return;
    }
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      querty: {
        date: s.format('YYYY-MM-DD'),
        arrId: arrFiltered.map(e => e.id),
        arrRes: arrFiltered.map(e => e.resourceId)
      },
      progress: true,
      request: getProjectGantScheduleCalendarBusy,
    })).then(res => {
      console.log(res)
      setDialogProps({
        date: s.format('YYYY-MM-DD'),
        work: obj.work,
        resources: obj.resources,
        resourceId: obj.resourceId,
        id: obj.id,
        hours: obj.hours,
        busy: res.get || []
      })
      setOpenDialog();
    })


  }
  return <div className='App-paper' style={{ width: '100%', minHeight: 300, }}>
    <Typography style={{ fontWeight: 'bold' }}>{day}</Typography>
    {arr.map(e => {
      return <div
        style={{ backgroundColor: '#98d9fa', marginBottom: 10, width: '100%', cursor: 'pointer' }}
        onClick={() => { handleClick(e) }}
      >
        <div><Typography style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{e.work}</Typography></div>
        <div><Typography>{e.resources}</Typography></div>
        <div>всего: {e.hours}ч</div>
        <div>распределено: {e.hoursRasp}ч</div>
      </div>
    })}
  </div>
}