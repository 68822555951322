import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, MenuItem, ClickAwayListener, Grow, Paper, Popper, MenuList } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { API_BO } from '../../conf'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  profileButton: {
    position: 'relative',
    marginRight: '10%',
    marginLeft: 'inherit',
    color: "inherit",
  },
}));
export default function Avatar (props) {
  const classes = useStyles()
  const [open, changeOpen] = useState(false)
  const [anchorRef, changeRef] = useState(null)
  const handleToggle = (e) => {
    changeOpen(!open)
    changeRef(!anchorRef ? e.currentTarget : null)
  }
  const handleClose = (e) => {
    // changeOpen(false)
    // changeRef(null)
  }
  const handleToProfile = () => {
    changeOpen(false)
    changeRef(null)
    props.history.push(API_BO + "/profile");
  }
  return <div className={classes.root}>
    <IconButton
      className={classes.profileButton}
      aria-label="Account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleToggle}
      color="inherit"
      size='small'
    >
      <AccountCircle />
    </IconButton>
    <Popper open={open} anchorEl={anchorRef} transition disablePortal placement='bottom-end'>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} >
          <Paper id="menu-list-grow">
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>
                <MenuItem onClick={handleToProfile} >Профиль</MenuItem>
                <LogOut />
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </div>
}

function LogOut() {
  const dispatch = useDispatch()
  const [navigate, setNavigate] = useState(false)
  const handleLogout = () => {
    localStorage.clear();
    dispatch({ type: 'USER_LOGOUT' });
    setNavigate(true)
  }
  if (navigate) {
    return <Redirect to='/login' push={true} />
  }
  return <MenuItem onClick={handleLogout}>Выйти</MenuItem>
}