import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import 'moment/locale/ru';
import { getDeliveryDocById, addDelivery } from '../../api/acceptance'
import * as actions2 from '../../reducers/form';
import TableShipment from './source/TableShipment';
import { fetchDispath } from '../../functions/fetch'
import { Typography, TextField, CircularProgress } from '@material-ui/core';
import DialogItemSerialNum from "./source/DialogItemSerialNum";
import TableFile from './source/TableFile'
import { loadFileAPI } from '../../reducers/materialLoad';
import { addFile } from '../../api/list'

const formName = 'AcceptanceById'
export default function AcceptanceById({ match, history }) {
  const idProject = match.params.id
  const idAccept = match.params.idAccept
  const typeDoc = match.params.idAccept.split('_')[0]
  const [loadingFile, setLoadingFile] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions2.formInitialize({
      byId: {},
      allIds: [],
      idRelated: idAccept,
      idProject: idProject,
      error: 0,
      openDialog: false,
      dialogId: 0,
      number: '',
      dateAccept: new Date(),
      fById: {},
      fAllIds: []
    }, { name: formName }))
    return () => {
      dispatch(actions2.formDestroy({ name: formName }))
    }
  }, [dispatch, idProject, idAccept])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        idDoc: idAccept,
        id: idProject
      },
      progress: true,
      request: getDeliveryDocById,
    })).then(res => {
      const byId = {}
      const allIds = []
      res.get.forEach((obj, index) => {
        const id = index + '_' + obj.id
        byId[id] = {
          ...obj,
          balance: obj.count,
        }
        if (obj.estimate) {
          byId[id].main = {
            name: obj.name,
            balance: obj.count,
            storageUnit: obj.storageUnit
          }
          byId[id].name = obj.estimate.name + ` (${obj.name})`
          byId[id].count = obj.estimate.balance
          byId[id].balance = obj.estimate.balance
          byId[id].storageUnit = obj.estimate.storageUnit
        }
        if (typeDoc === 'purchaseequip') {
          byId[id].item = false
          byId[id].serialNumber = ''
        }
        byId[id].party = ''
        byId[id].defects = ''
        allIds.push(id)
      })
      dispatch(actions2.formChange(byId, { field: 'byId', name: formName }))
      dispatch(actions2.formChange(allIds, { field: 'allIds', name: formName }))
    }).catch(err => console.log(err))
  }, [dispatch, idProject, idAccept, typeDoc])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const setDelivery = () => {
    dispatch(actions2.formSubmitAPI({
      props: {
        arr: values.allIds.map(key => {
          const obj = values.byId[key]
          let row = {
            idMaterial: obj.id,
            count: obj.count,
            party: obj.party,
            defects: obj.defects, 
          }
          if (obj.estimate) {
            row.idStorageUnit = obj.estimate.idStorageUnit
            row.coef = obj.estimate.coef
            row.count = obj.count * obj.estimate.coef
            row.idEstimate = obj.estimate.id
          }
          if (typeDoc === 'purchaseequip') {
            row.item = (obj.item) ? 1 : 0
            row.serialNumber = obj.serialNumber.replace(/\r\n|\n/g, ";")
          }
          return row
        }),
        idRelated: values.idRelated,
        idProject: values.idProject,
        number: values.number,
        dateAccept: values.dateAccept,
        files: Object.values(values.fById).map(e => e.id)
      },
      url: addDelivery.path,
      method: addDelivery.type,
      history: history
    }, formName))
  }
  const changeText = (e, filed) => {
    dispatch(actions2.formChange(e.target.value, { field: filed, name: formName }))
  }
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoadingFile(true)
    let fileName = e.target.files[0].name
    const fileType = e.target.files[0].type
    dispatch(loadFileAPI({ file: e.target.files[0], path: addFile.path }))
      .then(res => {
        if (res.success) {
          dispatch(actions2.formObjAdd({ id: res.id, name: fileName, type: fileType }, {
            field: 'fById',
            id: 'id' + res.id,
            name: formName
          }))
          dispatch(actions2.formArrayAdd('id' + res.id, {
            field: 'fAllIds', name: formName
          }))
        }
        setLoadingFile(false)
      })
      .catch()
  }
  return (
    <div className='App-paper'>
      <DialogItemSerialNum formName='AcceptanceById' />
      <Typography variant='h6'>Приемка материалов на объект</Typography>
      <Button disabled={loadingFile} component="label" color='primary'>
        {loadingFile && <CircularProgress size={25} />}
        <span>Прикрепить файл</span>
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(e) => _handleImageChange(e)}
        />
      </Button>
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            onChange={(e) => changeText(e, 'number')}
            values={values.number}
            label='Номер накладной'
          />
        </Grid>
        <Grid item>
          <TextField
            onChange={(e) => changeText(e, 'dateAccept')}
            values={values.dateAccept}
            type='date'
            label='Дата накладной'
            InputLabelProps={{ shrink: true, }}
          />
        </Grid>
      </Grid>
      <TableShipment typeDoc={typeDoc} formName='AcceptanceById' />
      <TableFile formName={'AcceptanceById'} />
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
      <Button color='primary' onClick={() => { setDelivery() }} disabled={values.error > 0} >Записать </Button>
    </div>
  )
}