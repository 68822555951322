import React from 'react';
import { getWorkDocList } from '../../api/workdoc'
import PageList from '../../components/PageListAPI'

export default function WorkDocList (props) {
  return (
    <div className='App-paper'>
      <PageList
        name='workDocList'
        title='Рабочая документация'
        createItem={true}
        columns={[
          { id: 'dateCreate', name: 'Дата создания', type: 'date' },
          { id: 'title', name: 'Наименование' },
          { id: 'user', name: 'Добавил' },
        ]}
        loadAPI={{
          request: getWorkDocList
        }}
        {...props}
      />
    </div>
  )
}