import React from 'react'
import PageList from '../../components/PageListAPI'
import can from '../../functions/can'
import { deleteWorkerOne, getWorkerList } from '../../api/worker'
import { Link } from 'react-router-dom';
import Rating from '../../components/Rating'
import { Button } from '@material-ui/core'
export default function WorkerList(props) {
  const toLinkRating = React.forwardRef((props1, ref) => (<Link ref={ref} to='worker/rating' {...props1} />));
  return <div className='App-paper'>
    <PageList
      name='workerList'
      columns={[
        { id: 'surname', name: 'Фамилия' },
        { id: 'name', name: 'Имя' },
        { id: 'patronymic', name: 'Отчество' },
        { id: 'position', name: 'Должность' },
        { id: 'star', name: 'Рейтинг', type: 'component', component: ({ obj }) => (<Rating value={Number(obj.star)} />) },
      ]}
      createItem={can('worker:create')}
      deleteItem={can('worker:delete')}
      headerLink={
        <Button component={toLinkRating} color="primary" >Рейтинг</Button>
      }
      loadAPI={{
        request: getWorkerList,
      }}
      deleteAPI={deleteWorkerOne}
      headerInit={{
        sortBy: 'surname',
        sortDirection: 'desc',
      }}
      {...props}
    />
  </div>
}