import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import { Button, Checkbox, Input } from '@material-ui/core';
import {
  getProjectGantSchedule,
  updProjectGantSchedule,
} from '../../api/form-gant.api'
import { makeStyles } from '@material-ui/core/styles';
import DialogSchedule from './DialogSchedule'

import 'moment/locale/ru';
import moment from 'moment'

const formName = 'form-gant'

export default function Schedule({ match, history }) {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState('')
  const idProject = match.params.idProject
  useEffect(() => {
    dispatch(actions.formInitialize({
      byId: {},//example_date.byId,
      allIds: [],// example_date.allIds,
      byIdR: {},//example_date.byId,
      arrFilter: [],
      start: moment(),
      end: moment(),
      count: 0,
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])

  useEffect(() => {
    // пока уберем загрузку
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getProjectGantSchedule,
    })).then(res => {
      let byId = {}
      let startNew = moment(res.get[0].start)
      let endNew = moment(res.get[0].end)
      let parentChild = {}
      let allIds = res.get.map((e, i) => {
        const id = 'id' + e.id
        if (e.typeGant === 'parent') {
          if (parentChild[e.id]) {
            parentChild[e.id].row = id
            parentChild[e.id].name = e.name
          }
          else {
            parentChild[e.id] = { row: id, name: e.name, arr: [], start: null, end: null }
          }
        };

        if (e.parent) {
          if (parentChild[e.parent]) {
            parentChild[e.parent].arr.push(id)
            if (!parentChild[e.parent].start || parentChild[e.parent].start > moment(e.start)) {
              parentChild[e.parent].start = moment(e.start)
            }
            if (!parentChild[e.parent].end || parentChild[e.parent].end < moment(e.end)) {
              parentChild[e.parent].end = moment(e.end)
            }
          }
          else {
            parentChild[e.parent] = { row: null, name: '', arr: [id], start: moment(e.start), end: moment(e.end) }
          }
        }
        const start = moment(e.start, 'YYYY-MM-DD')
        const end = moment(e.end, 'YYYY-MM-DD').add(1,'day')
        byId[id] = {
          ...e,
          id: id,
          idAPI: e.id,
          dependencies: e.dependencies || [],
          depCount: {},
          start: start.format('DD.MM.YYYY'),
          end: end.format('DD.MM.YYYY'),
          duration: end.diff(start, 'days') + 1,
        }
        let depCount = {}
        byId[id].dependencies.forEach(e => { depCount[e.id] = 0 })
        byId[id].depCount = depCount
        if (start < startNew) startNew = moment(start)
        if (end > endNew) endNew = moment(end)
        return id
      })
      allIds = res.arraySort.map(e => {
        if(byId['id' + e.id]) {
          byId['id' + e.id].level = e.level
          return 'id' + e.id
        }
        return undefined
      }).filter(e=>e!==undefined)
      const arrPos = []
      let posParent = ''
      allIds.forEach(key => {
        const obj = byId[key]
        if (obj?.parent) {
          const par = byId[parentChild[obj.parent].row]
          byId[key].parentValue = {
            value: par.id,
            label: par.pos + ' ' + par.name
          }
        }
        if (obj?.typeGant === 'parent') {
          const parent = parentChild[obj.idAPI]
          if (!parent || parent.arr.length === 0) { console.log(parent); return; }
          byId[key].child = parent.arr
          byId[key].start = parent.start.format('DD.MM.YYYY')
          byId[key].end = parent.end.format('DD.MM.YYYY')
          byId[key].duration = parent.end.diff(parent.start, 'days') + 1
        };
        if (posParent !== obj.parent && arrPos.length > 0) {
          do {
            arrPos.pop()
          }
          while (arrPos.length > 0 && arrPos[arrPos.length - 1].id !== obj.parent)
          if (obj.parent) {
            posParent = obj.parent
          }
        }
        const pos = [...arrPos.map(e => e.pos), obj.pos].join('.')
        if (obj.typeGant === 'parent') {
          arrPos.push({ pos: obj.pos, id: obj.idAPI });
          posParent = obj.idAPI
        }
        byId[key].pos = pos
      })
      let byIdR = {}
      startNew.add(-2, 'days')
      res.resources.forEach(e => {
        const dateF = moment(e.date)
        const pos = dateF.diff(startNew, 'days') + 1
        byIdR[e.id + '_' + e.resourceId + '_' + pos] = { ...e, pos: pos, date: dateF, save: true }
        byId['id' + e.id].depCount[e.resourceId] += e.hours
      })

      dispatch(actions.formChangeAsObj({
        byId,
        allIds,
        byIdR,
        start: startNew,
        end: endNew,
        count: endNew.diff(startNew, 'days'),
      }, { name: formName }))
    }).catch(err => console.log(err))
  }, [dispatch, idProject])

  return <div className='App-paper'>
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
    <DialogSchedule selected={selected} formName={formName} idProject={idProject} setSelected={setSelected} />
    <TableSchedule formName={formName} idProject={idProject} selected={selected} setSelected={setSelected} />
  </div>
}
const useStyles = makeStyles(theme => ({
  table: {
    borderCollapse: 'collapse',
    border: '1px solid black',
  },
  tableThHead: {
    fontWeight: 'bold'
  },
  tableTh: {
    border: '1px solid black',
    padding: '5px',
  },
  tableTd: {
    border: '1px solid black',
    padding: '5px',
  },
  tableTdNumber: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'right'
  },
  tableSelected: {
    backgroundColor: '#ddd',
    border: '1px solid black',
    padding: '5px,'
  }
}));



function TableSchedule({ formName, idProject, selected, setSelected }) {
  const classes = useStyles()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { allIds = [], byId = {}, start, byIdR, count = 0 } = form.values
  const startMoment = moment(start)
  return <table className={classes.table}>
    <thead>
      <tr className={classes.tableThHead}>
        <td className={classes.tableTh}></td>
        <td className={classes.tableTh}>№</td>
        <td className={classes.tableTh} style={{ minWidth: '350px' }}>Наименование</td>
        {[
          'Начало', 'Окончание', 'Объемы работ', 'ч.ч за ед', 'План ч.ч', 'Всего ч.ч. по графику', 'Осталось распределить'].map((e, i) => {
            return <td key={i} className={classes.tableTh} style={{ minWidth: '100px' }} >{e}</td>
          })}
        {[...Array(count).keys()].map(e => {
          const startFormat = startMoment.format('DD.MM');
          startMoment.add(1, 'd')
          return <td key={e} className={classes.tableTh} style={{ minWidth: '70px' }}>{startFormat}</td>
        })}
      </tr>
    </thead>
    <tbody>
      {allIds.map(row => {
        const obj = byId[row];
        const diff = moment(obj.start, 'DD.MM.YYYY').diff(start, 'days')
        const countTime = Object.values(obj.depCount).reduce((a, b) => a + b, 0)
        return <React.Fragment>
          <tr key={row}>
            <td className={classes.tableTd} />
            <td className={classes.tableTd}>{obj.pos}</td>
            <td className={classes.tableTd} style={{ textIndent: 15 * obj.level + 'px' }}>{obj.name}</td>
            <td className={classes.tableTd}>{obj.start}</td>
            <td className={classes.tableTd}>{obj.end}</td>
            {obj.typeGant === 'parent' && [1, 2, 3, 4].map(e => <td key={e} className={classes.tableTd} />)}
            {obj.typeGant === 'work' && <React.Fragment>
              <td className={classes.tableTdNumber}>{obj.count + " " + obj.storageUnit}</td>
              <td className={classes.tableTdNumber}>{obj.laborCosts}</td>
              <td className={classes.tableTdNumber}>{obj.laborCosts * obj.count}</td>
              <td className={classes.tableTdNumber}>{countTime}</td>
              <td className={classes.tableTdNumber}>{obj.laborCosts * obj.count - countTime}</td>
            </React.Fragment>}

            {[...Array(count).keys()].map(e => {
              let count = 0
              obj.dependencies.forEach(dep => {
                const row = obj.idAPI + '_' + dep.id + '_' + (e + 1)
                const objA = byIdR[row] || { hours: 0 }
                count += Number(objA.hours)
              })
              return <td
                key={e}
                className={(e >= diff && e <= diff + obj.duration) ? classes.tableSelected : classes.tableTh}
              >{count > 0 ? count : ''}</td>
            })}
          </tr>
          {obj.dependencies.map((dep, i) => {
            const checked = (row + '_' + i) === selected
            return <tr key={dep.id}>
              <td className={classes.tableTd}>
                <Checkbox checked={checked} onClick={() => setSelected(checked ? '' : row + '_' + i)} />
              </td>
              <td className={classes.tableTd} />
              <td className={classes.tableTd} style={{ textIndent: (15 * obj.level + 1) + 'px' }}>{dep.name}</td>
              {[1, 2, 3].map(e => <td key={e} className={classes.tableTd} />)}
              <td className={classes.tableTdNumber}>{dep.count}</td>
              <td className={classes.tableTdNumber}>{dep.count * obj.count}</td>
              <td className={classes.tableTdNumber}>{obj.depCount[dep.id] || 0}</td>
              <td className={classes.tableTdNumber}>{dep.count * obj.count - (obj.depCount[dep.id] || 0)}</td>
              {[...Array(count).keys()].map(e => {
                return <Cores
                  key={obj.idAPI + '_' + dep.id + '_' + e}
                  className={(e >= diff && e < diff + obj.duration) ? classes.tableSelected : classes.tableTh}
                  pos={(e + 1)}
                  resourceId={dep.id}
                  id={obj.idAPI}
                  idProject={idProject}
                  start={start}
                  depCount={obj.depCount}
                />
              })}
            </tr>
          })}
        </React.Fragment>
      })}
    </tbody>
  </table>
}


function Cores({ className, id, pos, resourceId, idProject, start, depCount }) {
  const [edit, setEdit] = useState(false)
  const [oldValue, setOldValue] = useState(0)
  const dispatch = useDispatch()
  const row = id + '_' + resourceId + '_' + pos
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byIdR } = form.values
  const obj = byIdR[row] || {}
  if (edit) {
    const handleBlur = () => {
      setEdit(false)
      dispatch(actions.formObjChange({ ...depCount, [resourceId]: (depCount[resourceId] || 0) + Number(obj.hours) - oldValue }, { field: 'byId', fieldObj: 'depCount', id: 'id' + id, name: formName }))
      if (!obj.save && obj.hours === 0) {
        dispatch(actions.formObjDelete({ id: row, field: 'byIdR', name: formName }));
        return;
      }
      dispatch(fetchDispath({
        param: {
          id: idProject,
          gantId: id,
          resourceId: resourceId,
        },
        body: {
          date: moment(start).add(pos - 1, 'day').format('YYYY-MM-DD'),
          hours: obj.hours
        },
        request: updProjectGantSchedule,
      })).then(e => {
        dispatch(actions.formObjChangeObj({ save: true, date: moment(start).add(pos - 1, 'day') }, { field: 'byIdR', id: row, name: formName }))
      })
    }
    return <td className={className} >
      <Input
        style={{
          fontSize: '0.875rem',
        }}
        autoFocus
        type='number'
        value={obj.hours}
        onFocus={(e) => { setOldValue(Number(obj.hours)); e.target.select() }}
        onBlur={handleBlur}
        onChange={e => { dispatch(actions.formObjChangeObj({ hours: e.target.value }, { field: 'byIdR', id: row, name: formName })) }}
      />
    </td>
  }
  if (!obj.id) {
    const handleAdd = () => {
      dispatch(actions.formObjAdd({
        hours: 0,
        id: id,
        pos: pos,
        resourceId: resourceId,
        save: false
      }, { field: 'byIdR', id: row, name: formName }))
      setEdit(true)
    }
    return <td className={className} onClick={handleAdd}></td>
  }
  else {
    return <td className={className} onClick={() => setEdit(true)}>{obj.hours}</td>
  }
}