import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import * as actions from '../../reducers/formTable';
import { fetchDispath } from '../../functions/fetch'
import { getOutfitFactDay, getOutfitPlanDay } from '../../api/doc'
import { Button, Tab, Tabs, Grid, Typography, TextField } from '@material-ui/core';
import TablePF from './source/TablePlanFact'
import TablePFW from './source/TablePlanFactWorker'
import PlanFactWork from './source/PlanFactWork';

export default function OutfitDayPlanFact({ match, history, location }) {
  const [value, setValue] = useState(0)
  const [date, setDate] = useState('')
  const [loadFact, setLoadFact] = useState(false)
  const [loadPlan, setLoadPlan] = useState(false)
  const [plan, setPlan] = useState({})
  const [fact, setFact] = useState({})
  const [group, setGroup] = useState(true)
  const dispatch = useDispatch();
  const tableW = 'OurfitOPByIdW'
  const tableWE = 'OurfitOPByIdWE'
  const tableWorkPlanFact = 'OurfitOPByIdWorkPF'
  const tableM = 'OurfitOPByIdM'
  const tableHeaderPlan = 'OderTablePlan'
  useEffect(() => {
    dispatch(actions.initFormTable({ name: tableHeaderPlan }))
    dispatch(actions.initFormTable({ name: tableWorkPlanFact }))
    dispatch(actions.initFormTable({ name: tableW }))
    dispatch(actions.initFormTable({ name: tableM }))
    dispatch(actions.initFormTable({ name: tableWE }))
    return () => {
      dispatch(actions.clearFormTable(tableHeaderPlan))
      dispatch(actions.clearFormTable(tableM))
      dispatch(actions.clearFormTable(tableW))
      dispatch(actions.clearFormTable(tableWorkPlanFact))
      dispatch(actions.clearFormTable(tableWE))
    }
  }, [dispatch])
  const getFact = () => {
    if (!date) return;
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
      },
      querty: {
        dateStart: date,
        split: 1
      },
      progress: true,
      request: getOutfitFactDay,

    }))
      .then((res) => {
        if (!res) return;
        setFact(res.get)
        setLoadFact(true)
      })
  }
  const getPlan = () => {
    if (!date) return;
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
      },
      querty: {
        date: date,
        split: 1
      },
      progress: true,
      request: getOutfitPlanDay,

    }))
      .then((res) => {
        if (!res) return;
        setPlan(res.get)
        setLoadPlan(true)
      })
  }
  useEffect(() => {
    if (!loadFact || !loadPlan) return;
    // planFact obj
    let planFact = {}
    plan.plan.forEach(obj => {
      planFact[obj.id] = obj.idFact
    })
    let factPlan = {}
    Object.keys(planFact).forEach(v => {
      factPlan[planFact[v]] = v
    })
    // load material
    let objMaterial = {}
    plan.material.forEach(obj => {
      objMaterial[obj.id + 'id' + planFact[obj.idPlan]] = { ...obj, countFact: 0 }
    })
    fact.material.forEach(obj => {
      objMaterial[obj.id + 'id' + obj.idFact].countFact = obj.count
    })
    dispatch(actions.loadFormTable({ get: Object.values(objMaterial) }, { name: tableM, id: 'id' }))
    // load work estimate 
    let objWorkEst = {}
    plan.workE.forEach(obj => {
      objWorkEst[obj.id + 'id' + planFact[obj.idPlan]] = { ...obj, countFact: 0 }
    })
    fact.workE.forEach(obj => {
      objWorkEst[obj.id + 'id' + obj.idFact].countFact = obj.count
    })
    dispatch(actions.loadFormTable({ get: Object.values(objWorkEst) }, { name: tableWE, id: 'id' }))


    // load work 
    let objWork = {}
    plan.work.forEach(obj => {
      const der = plan.plan.find(v => v.id === obj.id)
      objWork[obj.id] = { id: obj.id, plan: obj.work, fact: [], shortDescription: der.shortDescription }
    })
    fact.work.forEach(obj => {
      if (!factPlan[obj.id]) return;
      objWork[factPlan[obj.id]].fact.push(obj.work)
      objWork[factPlan[obj.id]].idFact = obj.id
    })
    dispatch(actions.loadFormTable({ get: Object.values(objWork) }, { name: tableWorkPlanFact, id: 'id' }))

    //load plan load fact
    dispatch(actions.loadFormTable({ get: [{ shortDescription: 'Все', id: -1, idFact: -1 }, ...plan.plan] }, { name: tableHeaderPlan, id: 'id' }))

    // load worker table 
    let objWorker = {}
    plan.worker.forEach(obj => {
      objWorker[obj.id + 'id' + planFact[obj.idPlan]] = { id: obj.id, name: obj.surname + ' ' + obj.name + ' ' + obj.patronymic, position: obj.position, fact: 'не работал', idPlan: obj.idPlan }
    })
    fact.worker.forEach(obj => {
      objWorker[obj.id + 'id' + obj.idFact].fact = "работал"
    })
    dispatch(actions.loadFormTable({ get: Object.values(objWorker) }, { name: tableW, id: 'id' }))
    // clear date
    setLoadFact(false)
    setLoadPlan(false)
    setPlan({})
    setFact({})
  }, [loadFact, loadPlan, plan, fact, dispatch])
  const getColor = (countPlan, countFact) => {
    if (countPlan === countFact) return undefined;
    if (countFact === 0) return '#b51705'
    return '#ab8300'
  }
  return <div className='App-paper'>
    <Typography variant='h6'>Наряд заказ план/факт на день</Typography>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
    >
      <Grid item>
        <Button color="primary" onClick={() => { getPlan(); getFact(); }} >Сформировать</Button>
      </Grid>
      <Grid item>
        <TextField
          id="date"
          name='date'
          type={"date"}
          label='Дата'
          value={date}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setDate(e.target.value)}
        />
      </Grid>
      <Grid>
        <Button color={group ? 'secondary' : 'primary'} onClick={() => setGroup(!group)}>Группировать</Button>
      </Grid>
    </Grid>
    <Tabs value={value} onChange={(e, v) => setValue(v)} indicatorColor='primary'>
      <Tab label='Сметные работы' />
      <Tab label='Материалы' />
      <Tab label='Работники' />
      <Tab label='Доп. Работа' />
    </Tabs>
    {value === 0 &&
      <TablePF
        formName={tableHeaderPlan}
        field={tableWE}
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'count', name: 'Количество план' },
          { id: 'countFact', name: 'Количество факт', type: 'component', component: ({ obj }) => <span style={{ color: getColor(obj.count, obj.countFact) }}>{obj.countFact}</span> },
          { id: 'storageUnit', name: 'Ед.изм' },
        ]}
        group={group}
      />
    }
    {value === 1 &&
      <TablePF
        formName={tableHeaderPlan}
        field={tableM}
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'count', name: 'Количество план' },
          { id: 'countFact', name: 'Количество факт', type: 'component', component: ({ obj }) => <span style={{ color: getColor(obj.count, obj.countFact) }}>{obj.countFact}</span> },
          { id: 'storageUnit', name: 'Ед.изм' },
        ]}
        group={group}
      />
    }
    {value === 2 &&
      <TablePFW
        formName={tableHeaderPlan}
        field={tableW}
        columns={[
          { id: 'name', name: 'ФИО' },
          { id: 'position', name: 'Должность' },
          { id: 'fact', name: 'Факт' },
        ]}
        group={group}
      />
    }
    {value === 3 && <PlanFactWork field={tableWorkPlanFact} />}
    <Button color='primary' onClick={() => history.goBack()}>Назад</Button>
  </div>
}