import React, { useEffect } from 'react';
import { Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/form';
import { addFacility } from '../../api/facility'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import { getCompanies } from '../../api/list'

const formName = 'FacilityAdd'
const formCompanies = 'Companies'
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
}))
export default function FacilityAdd(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    dispatch(initialAutocomplite({ field: formCompanies }))
    dispatch(actions.formInitialize({
      description: '',
      name: '',
      address: '',
      investor: '',
      customer: '',
      contractor: '',
      contractNumber: '',
      contractDate: null,
      ksPrefix: '',
      projectDocCompany: null,
      template: false,
      errorValidate: { name: false },
      construction: null,
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formCompanies }))

    }
  }, [dispatch])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getCompanies.path, {
      field: formCompanies,
      value: 'id',
      labelFunc: (obj) => obj.shortName
    }))
  }, [dispatch])
  const handleChangeAuto = (field, value) => dispatch(actions.formChangeAsObj({ [field]: value }, { name: formName }))
  const arrProps = [
    { field: 'name', type: 'text', label: 'Наименование', validator: [(value) => value !== ''], errorText: 'Поле обязательно' },
    { field: 'address', type: 'text', label: 'Адрес' },
    { field: 'contractNumber', type: 'text', label: 'Номер договора' },
    { field: 'contractDate', type: 'text', label: 'Дата договора', textProps: { type: 'date' }, labelProps: { shrink: true } },
    { field: 'techCustomer', label: 'Застройщик, технический заказчик', type: 'autoRedux', change: e => handleChangeAuto('techCustomer', e.value), textProps: { formArr: formCompanies } },
    { field: 'investor', label: 'Инвестор', type: 'autoRedux', change: e => handleChangeAuto('investor', e.value), textProps: { formArr: formCompanies } },
    { field: 'customer', label: 'Заказчик (Генподрядчик)', type: 'autoRedux', change: e => handleChangeAuto('customer', e.value), textProps: { formArr: formCompanies } },
    { field: 'contractor', label: 'Подрядчик (Субподрядчик)', type: 'autoRedux', change: e => handleChangeAuto('contractor', e.value), textProps: { formArr: formCompanies } },
    { field: 'construction', label: 'Лицо, осуществляющее строительство', type: 'autoRedux', change: e => handleChangeAuto('construction', e.value), textProps: { formArr: formCompanies } },
    { field: 'techSupervision', label: 'Лицо, выполнившее работы, подлежащие освидетельствованию', type: 'autoRedux', change: e => handleChangeAuto('techSupervision', e.value), textProps: { formArr: formCompanies } },
    { field: 'projectDocCompany', label: 'Лицо,осуществляющее подготовку проектной документации', type: 'autoRedux', change: e => handleChangeAuto('projectDocCompany', e.value), textProps: { formArr: formCompanies } },
    { field: 'ksPrefix', type: 'text', label: 'КС префикс' },
    { field: 'description', type: 'text', label: 'Описание', textProps: { multiline: true, rows: "4" } },
  ]
  const handleSubmit = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        description: values.description,
        name: values.name,
        address: values.address,
        investor: values.investor,
        customer: values.customer,
        contractor: values.contractor,
        contractNumber: values.contractNumber,
        contractDate: values.contractDate,
        techCustomer: values.techCustomer,
        techSupervision: values.techSupervision,
        ksPrefix: values.ksPrefix,
        projectDocCompany: values.projectDocCompany,
        template: values.template,
        construction: values.construction,
      },
      url: addFacility.path,
      method: addFacility.type,
      history: props.history
    }), formName)
  }
  return (<div className='App-paper'>
    <form autoComplete="off" onSubmit={handleSubmit}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd
          arr={arrProps}
          title='Новый объект'
          formName={formName}
        />
        <div><FormControlLabel
          className={classes.formControl}
          control={
            <Checkbox
              checked={values.template || false}
              onChange={(e) => {
                dispatch(actions.formChange(e.target.checked, { field: 'template', name: formName }))
              }}
              color="primary"
            />
          }
          label="Использовать в качестве шаблона"
        /></div>
      </div>
      <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>)
}
