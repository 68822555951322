import React from 'react';
import {
  IconButton, Tooltip, TextField, Checkbox, Table, TableBody,
  TableCell, TableHead, TableRow, Typography, Grid
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ChangeIcon from '@material-ui/icons/Build'
import { useSelector, useDispatch } from 'react-redux';
import * as actions2 from '../../../reducers/form';

function TableDeliveryDoc({ formName, typeDoc }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { allIds = [], byId = {}, error = '' } = form.values
  const isEquip = typeDoc === 'purchaseequip'
  return <div>
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '3%' }} padding={'none'}>
          </TableCell>
          <TableCell style={{ width: '50%' }}>Наименование (сметное название)</TableCell>
          <TableCell style={{ width: '20%' }}>Количество</TableCell>
          <TableCell style={{ width: '5%' }}>Ед. изм.</TableCell>
          <TableCell style={{ width: '10%' }}>Партия</TableCell>
          <TableCell style={{ width: '10%' }}>Отклонение, дефекты</TableCell>
          {isEquip &&
            <Tooltip title={'Генерировать инвентарный номер'} placement="bottom" >
              <TableCell padding="checkbox" align='center'>№</TableCell>
            </Tooltip>
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {allIds
          .map(row => {
            const obj = byId[row];
            return (
              <Row
                key={row}
                id={row}
                obj={obj}
                error={error}
                isEquip={isEquip}
                formName={formName}
              />
            )
          })}
        <TableRow style={{ height: 57 * 5 }}>
          <TableCell colSpan={8} />
        </TableRow>
      </TableBody>
    </Table>
  </div>
}
export default TableDeliveryDoc;

function Row({ id, obj, error, isEquip, formName }) {
  const dispatch = useDispatch()
  const actions = {
    formArrayDelete: () => dispatch(actions2.formArrayDelete(id, { field: 'allIds', name: formName })),
    formObjDelete: () => dispatch(actions2.formObjDelete({ id: id, field: 'byId', name: formName })),
    formObjChangeObj: (obj) => dispatch(actions2.formObjChangeObj(obj, { field: 'byId', id: id, name: formName })),
    formChange: (value, field) => dispatch(actions2.formChange(value, { field: field, name: formName })),
    formChangeAsObj: obj => dispatch(actions2.formChangeAsObj(obj, { name: formName })),
  }
  const textEditRow = (e, name) => {
    let objNew = {
      [name]: e.target.value
    }
    if (obj.balance < obj.count && obj.balance >= e.target.value) {
      objNew.error = error - 1
    }
    if (obj.balance >= obj.count && obj.balance < e.target.value) {
      objNew.error = error + 1
    }
    actions.formObjChangeObj(objNew)
  }
  const deleteRow = () => {
    actions.formArrayDelete();
    actions.formObjDelete();
  }
  const onChangeChBox = (e) => {
    actions.formObjChangeObj({ item: e.target.checked })
    if (e.target.checked) {
      actions.formChangeAsObj({ dialogId: id, openDialog: true })
    }
  }
  const openDialog = () => {
    actions.formChangeAsObj({ dialogId: id, openDialog: true })
  }
  const errorCount = obj.balance ? (obj.balance < obj.count) : false
  return <TableRow>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <Tooltip title="Delete">
        <IconButton onClick={deleteRow}><DeleteIcon /></IconButton>
      </Tooltip>
    </TableCell>
    <TableCell>
      <Typography>{obj.name}</Typography>
      {obj.item && obj.serialNumber !== '' && <ul>
        {obj.serialNumber.split(/\r\n|\n/g).map((str, i) => <li key={i}>{str}</li>)}
      </ul>}
    </TableCell>
    <TableCell>
      <Tooltip title={obj.balance}>
        <TextField
          fullWidth
          error={errorCount}
          margin="dense"
          type="number"
          value={obj.count}
          onChange={(e) => textEditRow(e, 'count')}
          helperText={errorCount ? 'Больше чем в заказе' : ''}
        />
      </Tooltip>
    </TableCell>
    <TableCell>
      {obj.storageUnit}
    </TableCell>
    {['party', 'defects'].map(key => {
      return <TableCell key={key}>
        <TextField
          fullWidth
          margin="dense"
          value={obj[key]}
          onChange={(e) => textEditRow(e, key)}
        />
      </TableCell>
    })}
    {isEquip &&
      <TableCell padding="checkbox" align='center'>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item >
            <Checkbox
              checked={obj.item}
              onChange={onChangeChBox}
              value="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item >
            <IconButton onClick={openDialog}  >
              <ChangeIcon />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    }
  </TableRow>

}