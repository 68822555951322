import {fetchGenerate} from '../functions/fetch'
import {changeToken} from '../localStorage'
export default function requestMiddleware() {
  return ({ dispatch, getState }) => next => (action) => {
    if (typeof action!=='function') {
      return next(action);
    }
    let token=localStorage.getItem('token')
    if(!token) return next(action);
    let refresh_token=localStorage.getItem('refreshToken')
    const base64Url=token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const payload=JSON.parse(window.atob(base64));
    const refreshThreshold = ((new Date()).getTime() + 300000)/1000 // 5 minutes from now
    if (refresh_token && refreshThreshold > payload.exp) {
      return fetchGenerate(`/user/gettoken`,'POST',{refreshToken:refresh_token})
        .then(resJ => {
          changeToken(resJ)
          return next(action);
        })
        .catch(err=>{
          console.log(err)
        });
    }
    else return next(action);
  };
}