import React, { useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid } from '@material-ui/core';
import { fetchDispath } from '../../../functions/fetch'
import moment from 'moment'
import 'moment/locale/ru';
import print from '../../../functions/print'
import { getDeliveryByIdPrint } from '../../../api/acceptance'
import GridProps from '../../../components/GridProps'
const init = {
  obj: {
    date: '',
    userName: ''
  }
}
function AcceptanceDocByIdHeader({ idAccept }) {
  const formTable = useSelector(state => state.formTable['deliveryDoc'])
  const { obj } = formTable || init
  const dispatch = useDispatch()
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          id: idAccept,
        },
        request: getDeliveryByIdPrint,
      }
    )
  }
  return (<div>
    <Button color='primary' onClick={() => handlePrint()}>Печать </Button>
    <Grid item container xs={9}>
      <GridProps name='Дата создания' value={moment(obj.date).format('LLL')} />
      <GridProps name='Принял' value={obj.userName} />
      <GridProps name='Номер накладной' value={obj.number} />
      <GridProps name='Дата приемки' value={moment(obj.dateAccept).format('DD.MM.YYYY')} />
    </Grid>
  </div>)
}

export default AcceptanceDocByIdHeader