import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../../../reducers/autocomplite'
import * as apiList from '../../../../api/list'
import { getOurStorage, addOurStorageMaterial } from '../../../../api/storage'
import DocTable from '../../../../components/DocTable'
import DocAdd from '../../../../components/DocAdd'

const formName = 'SmaAdd'
const formArr = 'equipment'
export default function SmaAdd({ history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ idStorage: '', byId: {}, allIds: [] }, { name: formName }))
    dispatch(initialAutocomplite({ field: formArr }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formArr }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(apiList.getEquipment.path, {
      field: formArr,
      value: 'id',
      labelFunc: (obj) => obj.name,
      hidden: ['storageUnit'],
    }))
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const handleSave = () => {
    const { values } = form
    let arr = []
    values.allIds.forEach(row => {
      const obj = values.byId[row]
      const count = parseFloat(obj.count.replace(',', '.').replace(' ', ''))
      if (obj.idMaterial > 0 && count > 0)
        arr.push({
          idMaterial: obj.idMaterial,
          count: count,
        })
    });
    dispatch(actions.formSubmitAPI({
      props: {
        data: arr,
        idStorage: values.idStorage,
        dateAdd: new Date()
      },
      url: addOurStorageMaterial.path,
      method: addOurStorageMaterial.type,
      history: history
    }))
  }
  return <div className='App-paper'>
    <DocAdd
      formName={formName}
      history={history}
      handleSave={handleSave}
      title='Добавить материал на склад'
      noTableProps={[
        {
          field: 'idStorage', label: 'Склад', type: 'autoLoad', textProps: {
            path: getOurStorage.path,
            meta: {
              field: 'storage',
              value: 'id',
              labelFunc: (obj) => obj.name
            }
          }
        },
      ]}
    >
      <DocTable
        formName={formName}
        allIdsField='allIds'
        byIdField='byId'
        column={[
          {
            id: 'value', name: 'Материал', type: 'auto', formArr: formArr, filtered: true, width: '40%',
            changeValue: text => ({ idMaterial: text.value, storageUnit: text.storageUnit, value: text })
          },
          { id: 'count', name: 'Количество', type: 'number', },
          { id: 'storageUnit', name: 'Ед. изм.', type: 'string' },
        ]}
        rowDefault={{
          count: '',
          storageUnit: '',
          idMaterial: '',
          value: null
        }}
      />
    </DocAdd>
  </div>
}