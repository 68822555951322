import React from 'react';
import PageList from '../../components/PageListAPI'
import { getAccountList } from '../../api/accounts'
import { API_BO } from '../../conf'
import { useDispatch } from 'react-redux';
import { fetchDispath } from '../../functions/fetch'
import { loginAccount } from '../../api/users'
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

function Acceptance(props) {
  const dispatch = useDispatch()
  const onClickFunction = (row, obj) => {
    if (!obj.id) return;
    dispatch(fetchDispath({
      body: {
        accountId: obj.id
      },
      progress: true,
      request: loginAccount,
    })).then(res => {
      localStorage.setItem('accountId', obj.id);
      localStorage.setItem('accountName', obj.name);
      localStorage.setItem('token', res.token);
      localStorage.setItem('role', res.role);
      props.history.push(API_BO + '/facility/our')
    }).catch(err => console.log(err))
  }
  const g = localStorage.getItem('g')
  const toLinkAdd = React.forwardRef((props, ref) => (
    <Link ref={ref} to={API_BO + `/a-panel`} {...props} />
  ));
  return (
    <div className='App-paper'>
      <PageList
        name='deliveryDoc'
        title='Аккаунты'
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'role', name: 'Роль' },
        ]}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getAccountList,
        }}
        {...props}
        headerInit={{
          sortBy: 'date',
          sortDirection: 'desc',
        }}
        csv={false}
        click={false}
        goBack={false}
        onClickFunction={onClickFunction}
        pagination={false}
        tableProps={{
          pagination:false
        }}
        headerLink={<div>
        {g!=='false' &&<Button component={toLinkAdd} color="primary">Панель</Button>}
        </div>}
      />
    </div>
  )
}

export default Acceptance;