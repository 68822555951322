const gantArrowType = {
  obj: {
    'fs': 'конец-начало',
    'ss': 'начало-начало',
    'ff': 'конец-конец',
    'sf': 'начало-конец'
  },
  arr: [
    { value: 'fs', label: 'конец-начало' },
    { value: 'ss', label: 'начало-начало' },
    { value: 'ff', label: 'конец-конец' },
    { value: 'sf', label: 'начало-конец' }
  ]
}
export default gantArrowType