import React from 'react';
import PageList from '../../../components/PageListAPI'
import can from '../../../functions/can'
import { getRemovalStorageMaterial, deleteRemovalStorageMaterial } from '../../../api/storage'
import { ENUM_TYPE_REMOVAL } from './removalPMaterial.const'

export default function RemovalPMaterial(props) {
  const tableHead = [
    { id: 'id', name: '№' },
    { id: 'user', name: 'Создатель' },
    { id: 'dateRemoval', name: 'Дата списания', type: 'date', formatDate: 'DD.MM.YYYY' },
    { id: 'typeRemovalRus', name:'Тип списания '}
  ];
  return (
    <div className='App-paper'>
      <PageList
        name='removalStorageMaterial'
        title='Документы списания материалов'
        columns={tableHead}
        createItem={can('storage:update')}
        deleteItem={can('storage:delete')}
        loadAPI={{
          querty: {
            idProject: props.match.params.id
          },
          request: getRemovalStorageMaterial,
          transform: (res => {
            return {
              count: res.count,
              get: res.get.map(obj => {
                return { ...obj, typeRemovalRus: ENUM_TYPE_REMOVAL.find(e=>e.value===obj.typeRemoval).label }
              })
            }
          }),
        }}
        deleteAPI={{
          path: deleteRemovalStorageMaterial.path,
          type: deleteRemovalStorageMaterial.type
        }}
        {...props}
      />
    </div>
  )
}