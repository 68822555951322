import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import { set as autoSet } from '../../../reducers/autocomplite';
import * as api from '../../../api/list'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'
import { fetchDispath } from '../../../functions/fetch'

const typeArr = [{ value: 'labor', label: 'Люди' }, { value: 'machine', label: 'Машины' }]
export default function ResourcesAdd({ history, match }) {
  const dispatch = useDispatch()
  const formName = 'ResourcesEdit'
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: api.getResourcesById,
      param: {
        id: match.params.id
      }
    })).then(res => {
      dispatch(actions.formChangeAsObj({ 
        name: res.get.name, 
        resource: res.get.idParent, 
        type: res.get.type, 
        price: res.get.price,
        valuetype: typeArr.find(e=>e.value===res.get.type)
       }, { field: 'name', name: formName }))
      dispatch(autoSet({ value: res.get.idParent+"", label: res.get.parent }, { field: 'resource' }))

    }).catch(err => console.log(err))
  }, [dispatch, match.params.id])
  useEffect(() => {
    dispatch(actions.formInitialize({ name: '', errorValidate: { name: false } }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [
    { field: 'name', label: 'Наименование', type: 'text', validator: [value => value !== ''], errorText: 'Поле обязательно' },
    {
      field: 'resource', type: 'autoLoad', label: 'Папка',
      textProps: {
        path: api.getResources.path + '?folder=1',
        meta: {
          field: 'resource',
          value: 'id',
          labelFunc: (obj) => obj.name
        },
        selectProps: { isClearable: true },
      }
    },
    {
      field: 'type', type: 'autoArr', label: 'Тип',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: { arr: typeArr, defaultValue: 'labor', },
    },
    { field: 'price', label: 'Стоимость за ед', type: 'text', textProps: { type: 'number' } },
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: { name: values.name, type: values.type, price: values.price, parentId: values.resource },
      url: api.updResources.path({id: match.params.id}),
      method: api.updResources.type,
      history: history
    }, formName))
  };
  return (
    <div className='App-paper'>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Редактировать ресурс'
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}


