const s = [
  {
    name: 'Круглый воздуховод',
    params: [
      {name: 'Длина(L)', id: 'L', unit:'мм'}, 
      {name: 'Диаметр(d)', id: 'd', unit:'мм'}, 
    ],
    img: 'duct_round.png',
    func:(obj)=> Math.round(obj.L*obj.d*Math.PI/1000000*100)/100 // Pi*L*d
  },
  {
    name: 'Квадратный воздуховод',
    params: [
      {name: 'Длина(L)', id: 'L', unit:'мм'}, 
      {name: 'Высота (b)', id: 'b', unit:'мм'}, 
      {name: 'Ширина(a)', id: 'a', unit:'мм'}, 

    ],
    img: 'duct_rectangular.png',
    func:(obj)=> Math.round(obj.L*2*(obj.b+obj.a)/1000000*100)/100 //  L*2*(a+b)
  },
  {
    name: 'Отвод круглого сечения',
    params: [
      {name: 'Диаметр(D)', id: 'D', unit:'мм'}, 
      {name: 'Угол(α)', id: 'al', unit:'мм'}, 
    ],
    img: 'bend_round.png',
    func:(obj)=> {
      var angle =obj.al;
      var diametr = obj.D;
      var s2 = Math.PI*diametr/1000*0.1;
      let numberZveno = 0;
      let sheyka = 0;
      let srez = 0;
      switch (angle)	{
        case 90 : numberZveno = 2;
          sheyka = (Math.PI/2*diametr/2)/(numberZveno+2)+15;
          srez = Math.PI/2*diametr/(numberZveno*2 +2);
          break;
        case 60 : numberZveno = 2;
          sheyka = (Math.PI/3*diametr/2)/(numberZveno+2)+15;
          srez = Math.PI/3*diametr/(numberZveno*2 +2);
          break;
        case 45 : numberZveno = 0;
          sheyka = (Math.PI/4*diametr/2)/(numberZveno+2)+15;
          srez = Math.PI/4*diametr/(numberZveno*2 +2);
          break;
        case 30 : numberZveno = 0;
          sheyka = (Math.PI/6*diametr/2)/(numberZveno+2)+15;
          srez = Math.PI/6*diametr/(numberZveno*2 +2);
          break;
        case 15 : 
          numberZveno = 0;
          sheyka = (Math.PI/12*diametr/2)/(numberZveno+2)+15;
          srez = Math.PI/12*diametr/(numberZveno*2 +2);
          break;
        default: 
          break;
      }
    var s1 = (Math.PI*diametr*(2*(sheyka+srez/2)+numberZveno*(sheyka+srez)))/1000000;
    return (s1+s2).toFixed(2);
    }
  },
  {
    name: 'Отвод прямоугольного сечения',
    params: [
      {name: 'Ширина(a)', id: 'a', unit:'мм'}, 
      {name: 'Высота(b)', id: 'b', unit:'мм'}, 
      {name: 'Угол(α)', id: 'al', unit:'мм'}, 
    ],
    img: 'bend_rectangular.png',
    func:(obj)=> {
      const angle2 =obj.al;
      const width2 = obj.a;
      const height2 = obj.b;
      const alfa = +angle2;
      const a = +width2/1000;
      const b = +height2/1000;
      return ((a+b)*0.2+Math.PI*(((0.125+a)*(0.125+a))-(0.125*0.125))*alfa*2/360+Math.PI*0.125*alfa*b/180+Math.PI*(0.125+a)*alfa*b/180).toFixed(2);

    } 
  },
  {
    name: 'Переход круглого сечения',
    params: [
      {name: 'Диаметр(D1)', id: 'D1', unit:'мм'}, 
      {name: 'Диаметр(D2)', id: 'D2', unit:'мм'}, 
      {name: 'Длина(L)', id: 'L', unit:'мм'}, 
    ],
    img: 'transition_round.png',
    func:(obj)=> {
      var h = +obj.L/1000;
      var d1 = +obj.D1/1000;
      var d2 = +obj.D2/1000;
      return (Math.PI*Math.sqrt(h*h+((d1-d2)/2)*((d1-d2)/2))*(d1/2+d2/2)+Math.PI*d1*0.05+Math.PI*d2*0.05).toFixed(2);
    }
  },
  {
    name: 'Переход с прямоугольного на круглое сечение',
    params: [
      {name: 'Ширина(a)', id: 'a', unit:'мм'}, 
      {name: 'Высота(b)', id: 'b', unit:'мм'}, 
      {name: 'Диаметр(D)', id: 'D', unit:'мм'}, 
      {name: 'Длина(L)', id: 'L', unit:'мм'}, 
    ],
    img: 'transition_rectangular-round.png',
    func:(obj)=> {
      const a = +obj.a/1000;
      const b = +obj.b/1000;
      const d = +obj.D/1000;
      const h = +obj.L/1000;
      const D = (2*a+2*b)/Math.PI;
      const ugal = h/((D-d)/2);
      const alfa = Math.atan(ugal)/(Math.PI/180);
      const L = (D/2)/Math.cos(alfa*Math.PI/180);
      const l = (d/2)/Math.cos(alfa*Math.PI/180);
      const x = Math.sqrt(L*L-(a/2)*(a/2));
      const SA = 0.5*x*a;
      const y = Math.sqrt(L*L-(b/2)*(b/2));
      const SB = 0.5*y*b;
      const gamma = 4*Math.asin((a/2)/L)/(Math.PI/180)+4*Math.asin((b/2)/L)/(Math.PI/180);
      const S = 2*SA+2*SB-Math.PI*l*l*gamma/360+(2*a+2*b)*0.05+Math.PI*d*0.05;
      return Math.abs(S.toFixed(2));
    }
  },
  {
    name: 'Переход с прямоугольного сечения на прямоугольное',
    params: [
      {name: 'Ширина (A)', id: 'A', unit:'мм'}, 
      {name: 'Высота(B)', id: 'B', unit:'мм'}, 
      {name: 'Ширина (a)', id: 'a', unit:'мм'}, 
      {name: 'Высота(b)', id: 'b', unit:'мм'}, 
      {name: 'Длина(L)', id: 'l', unit:'мм'}, 
    ],
    img: 'transition_rectangular.png',
    func:({A,B,a,b,l})=> {
      A/=1000
      B/=1000
      a/=1000
      b/=1000
      l/=1000
      const ha = Math.sqrt(((B-b)/2)*((B-b)/2)+(l*l));
      const hb = Math.sqrt((A-a)/2*(A-a)/2+l*l);
      return ((2*(A+a)/2*ha+2*(B+b)/2*hb+(2*a+2*b+2*A+2*B)*0.05)).toFixed(2);
    }
  },
  {
    name: 'Тройник круглого сечения',
    params: [
      {name: 'Диаметр(D1)', id: 'D1', unit:'мм'}, 
      {name: 'Диаметр(D2)', id: 'D2', unit:'мм'}, 
      {name: 'Длина(L)', id: 'L', unit:'мм'}, 
      {name: 'Длина(l)', id: 'l', unit:'мм'}, 
    ],
    img: 'branch_round-round.png',
    func:({ D1, D2, L, l})=> (Math.PI*(D1*L+D2*l)/1000000).toFixed(2)
  },
  {
    name: 'Тройник круглого сечения с прямоугольным отводом',
    params: [
      {name: 'Диаметр(D)', id: 'D', unit:'мм'}, 
      {name: 'Длина(L)', id: 'L', unit:'мм'}, 
      {name: 'Ширина(a)', id: 'a', unit:'мм'}, 
      {name: 'Высота(b)', id: 'b', unit:'мм'}, 
      {name: 'Длина(l)', id: 'l', unit:'мм'}, 
    ],
    img: 'branch_round-rectangular.png',
    func:({D, L, a, b, l})=>((Math.PI*D*L+(a+b)*2*l)/1000000).toFixed(2)
  },
  {
    name: 'Тройник прямоугольного сечения с круглым отводом',
    params: [
      {name: 'Диаметр(D)', id: 'D', unit:'мм'}, 
      {name: 'Длина(l)', id: 'l', unit:'мм'}, 
      {name: 'Ширина(a)', id: 'a', unit:'мм'}, 
      {name: 'Высота(b)', id: 'b', unit:'мм'}, 
      {name: 'Длина(L)', id: 'L', unit:'мм'}, 
    ],
    img: 'branch_rectangular-round.png',
    func:({ D, l, a, b, L})=> (((a+b)*2*L+Math.PI*D*l-Math.PI*D*D/4)/1000000).toFixed(2)
  },
  {
    name: 'Тройник прямоугольного сечения с прямоугольным отводом',
    params: [
      {name: 'Ширина(A)', id: 'A', unit:'мм'}, 
      {name: 'Высота(B)', id: 'B', unit:'мм'}, 
      {name: 'Длина(L)', id: 'L', unit:'мм'}, 
      {name: 'Ширина(a)', id: 'a', unit:'мм'}, 
      {name: 'Высота(b)', id: 'b', unit:'мм'}, 
      {name: 'Длина(l)', id: 'l', unit:'мм'}, 
    ],
    img: 'branch_rectangular-rectangular.png',
    func:({A, B, L, a, b, l})=> (((A+B)*2*L+(a+b)*2*l-a*b)/1000000).toFixed(2)
  },
  {
    name: 'Заглушка круглая',
    params: [
      {name: 'Диаметр(D)', id: 'D', unit:'мм'}, 
      {name: 'Длина(L)', id: 'L', unit:'мм'}, 
    ],
    img: 'cap_round.png',
    func:(obj)=> Math.round((Math.PI*obj.D*(0.25+obj.L))/1000000*100)/100 // Pi*D*(1/4+L  )
  },
  {
    name: 'Заглушка квадратная',
    params: [
      {name: 'Высота(b)', id: 'b', unit:'мм'}, 
      {name: 'Ширина(а)', id: 'a', unit:'мм'}, 
      {name: 'Длина(L)', id: 'L', unit:'мм'}, 
    ],
    img: 'cap_rectangular.png',
    func:(obj)=> Math.round((obj.a*obj.b+2*obj.L*(obj.a+obj.b))/1000000*100)/100 // a*b+2*L*(a+b)
  },
  {
    name: 'Утка со смещением в 1-ой плоскости',
    params: [
      {name: 'Ширина(а)', id: 'a', unit:'мм'}, 
      {name: 'Высота(b)', id: 'b', unit:'мм'}, 
      {name: 'Длина(l)', id: 'l', unit:'мм'}, 
      {name: 'Сдвиг(h)', id: 'h', unit:'мм'}, 
    ],
    img: 'duck_one_plane.png',
    func:({a, b, l, h})=>{
      a/=1000; b/=1000; l/=1000; h/=1000;
      return ((2*(a*Math.sqrt(l*l+h*h)+b*l+0.1*(a+b)))).toFixed(2)
    }
  }, 
  {
    name: 'Утака со смещением в 2-х плоскостях',
    params: [
      {name: 'Ширина(а)', id: 'a', unit:'мм'}, 
      {name: 'Высота(b)', id: 'b', unit:'мм'}, 
      {name: 'Длина(l)', id: 'l', unit:'мм'}, 
      {name: 'Сдвиг(h1)', id: 'h1', unit:'мм'}, 
      {name: 'Сдвиг(h2)', id: 'h2', unit:'мм'}, 
    ],
    img: 'duck_two_plane.png',
    func:({a, b, l, h1, h2})=>{
      a/=1000;
      b/=1000;
      l/=1000;
      h1/=1000;
      h2/=1000;
      return ((2*(b*Math.sqrt(l*l+h2*h2)+a*Math.sqrt(l*l+h1*h1)+0.1*(a+b)))).toFixed(2)
    }
  },  
  {
    name: 'Зонт островного типа',
    params: [
      {name: 'Длина(A)', id: 'A', unit:'мм'}, 
      {name: 'Ширина(B)', id: 'B', unit:'мм'}, 
      {name: 'Длина(a)', id: 'a', unit:'мм'}, 
      {name: 'Ширина(b)', id: 'b', unit:'мм'}, 
      {name: 'Высота(h)', id: 'h', unit:'мм'}, 
    ],
    img: 'canopy_island.png',
    func:({A, B, a, b, h})=>{
      A/=1000; B/=1000; a/=1000; b/=1000; h/=1000;       
      const ha = Math.sqrt(((B-b)/2)*((B-b)/2)+(h*h));
      const hb = Math.sqrt((A-a)/2*(A-a)/2+h*h);
      return (2*(A+a)/2*ha+2*(B+b)/2*hb+a*b).toFixed(2);
    }
  }, 
  {
    name: 'Зонт пристенного типа',
    params: [
      {name: 'Полка(с)', id: 'c', unit:'мм'}, 
      {name: 'Длина(a)', id: 'a', unit:'мм'}, 
      {name: 'Ширина(b)', id: 'b', unit:'мм'}, 
      {name: 'Высота(h)', id: 'h', unit:'мм'}, 
    ],
    img: 'canopy_wall.png',
    func:({c, a, b, h})=> {
      c/=1000; a/=1000; b/=1000; h/=1000;
      return (h*(b+c)+a*Math.sqrt((b-c)*(b-c)+(h*h))+a*h+a*c).toFixed(2);
    }
  }, 
  {
    name: 'Круглый зонт',
    params: [
      {name: 'Диаметр(D)', id: 'D', unit:'мм'}, 
    ],
    img: 'canopy_round.png',
    func:({D})=> {
      return (((Math.PI*Math.pow(D + 0.8*D,2)/(4*Math.cos(Math.PI/9)))+100*Math.PI*D)/1000000).toFixed(2);
    } 
  }, 
  {
    name: 'Квадратный зонт',
    params: [
      {name: 'Сторона(А)', id: 'A', unit:'мм'}, 
    ],
    img: 'canopy_square.png',
    func:({ A })=> {
      const H = 0.4*A;
      const BxB = A + 2*H;
      const hSideZont = BxB/2/Math.cos(Math.PI/10);
      return ((2*hSideZont*BxB)/1000000 + 0.4*A/1000).toFixed(1);
    }
  },   
  {
    name: 'Прямоугольный зонт',
    params: [
      {name: 'Длина(а)', id: 'a', unit:'мм'}, 
      {name: 'Ширина(b)', id: 'b', unit:'мм'}, 
    ],
    img: 'canopy_rectangular.png',
    func:({a, b})=> {
      let HH,hZ
      if (a>b) {
        HH = 0.4*a;
        hZ = 0.3*a
      } else {
        HH = 0.4*b;
        hZ = 0.3*b
      }
      const A1 = a+100;
      const B1 = b+100;
      const A2 = a + 2*HH;
      const B2 = b + 2*HH;
      const sA = 1/2*(A1+A2)*Math.sqrt(Math.pow(hZ,2)+Math.pow(((B2-B1)/2),2));
      const sB = 1/2*(B1+B2)*Math.sqrt(Math.pow(hZ,2)+Math.pow(((A2-A1)/2),2));
      return ((2*sA + 2*sB + 2*(a+b)*100 + A1*B1)/1000000).toFixed(1); 
    }
  },   
  {
    name: 'Дефлектор',
    params: [
      {name: 'Диаметр(D)', id: 'D', unit:'мм'}, 
    ],
    img: 'deflector.png',
    func:({ D })=> {
      var s1 = 1.139508495*Math.PI*Math.pow(D,2);
      var s2 = 2.4*Math.PI*Math.pow(D,2);
      var s3 = 1.488679646*Math.PI*Math.pow(D,2);
      return ((s1 + s2 + s3)/1000000).toFixed(1);
    }
  }, 
]
export default s