import React from 'react';
import PageList from '../../components/PageListAPI'
import { getKS3, delKS3ById } from '../../api/formks2ks3'

export default function KS3List(props) {
  const tableHead = [
    { id: 'number', name: '№', },
    { id: 'dateStart', name: 'отчетный перод (с)', type: 'date', formatDate: 'DD.MM.YYYY' },
    { id: 'dateEnd', name: 'отчетный перод (по)', type: 'date', formatDate: 'DD.MM.YYYY' },
  ];
  return (
    <div className='App-paper'>
      <PageList
        title='Справка о стоимости работ и затрат КС3'
        name='ks2list'
        // classes={classes}
        columns={tableHead}
        createItem={true}
        deleteItem={true}
        loadAPI={{
          request: getKS3,
          param: {
            id: props.match.params.idFacility
          },
        }
        }
        deleteAPI={delKS3ById(props.match.params.idFacility)}
        {...props}
      />
    </div>
  )
}