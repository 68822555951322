import React from 'react'
import Grid from '@material-ui/core/Grid';
import CardMenu from './source/CardMenu'

const arr = {
  our: [
    { to: '/tender/our', primary: 'Список', add:true },
  ],
  all: [
    { to: '/tender', primary: 'Список', add:true },
  ],
}
export default function TenderMenu() {
  return <Grid container>
    <Grid item><CardMenu arr={arr.our} name={'Мои тендеры'} /></Grid>
    <Grid item><CardMenu arr={arr.all} name={'Все тендеры'} /></Grid>
  </Grid>
}