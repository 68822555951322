import React from 'react'
import Grid from '@material-ui/core/Grid';
import CardMenu from './source/CardMenu'

const arr = {
  accounts: [
    { to: '/a-panel/accounts', primary: 'Список аккаунтов', add:true },
    { to: '/a-panel/payments', primary: 'Оплата', add:true },
  ],
  tariff: [
    { to: '/a-panel/tariff', primary: 'Список', add:true },
  ],
  list: [
    { to: '/a-panel/regions', primary: 'Регионы', add:true },
    { to: '/a-panel/specializations', primary: 'Специализации', add:true },
  ]
}
export default function MenuAdmin() {
  return <Grid container>
    <Grid item><CardMenu arr={arr.accounts} name={'Аккаунты'} /></Grid>
    <Grid item><CardMenu arr={arr.tariff} name={'Тарифы'} /></Grid>
    <Grid item><CardMenu arr={arr.list} name={'Списки'} /></Grid>
  </Grid>
}