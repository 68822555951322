import template from "./template_crud";
const facility = '/facility'
export const getFacility = template.getList(facility)
export const getFacilityMaterial = template.getList(facility+'/materials')
export const deleteFacility = template.deleteById(facility)
export const addFacility = template.addOne(facility)
export const getFacilityById = template.getById(facility)
export const updFacility = template.updById(facility)
export const getFacilityProject = {
  path: (obj)=>`${facility}/${obj.id}/project`,
  type: 'GET'
}

export const getFacilityOur = {
  path:'/facility/our',
  type: 'GET'
}
export const getFacilityOurProject = {
  path: (obj)=>`${facility}/our/${obj.id}/project`,
  type: 'GET'
}

// facility responsible person
export const getFacilityResponsible = {
  path: (obj)=>`${facility}/${obj.idFacility}/responsible_persons`,
  type: 'GET'
}
export const addFacilityResponsible = {
  path: (obj)=>`${facility}/${obj.idFacility}/responsible_persons`,
  type: 'POST'
} 
export const getOneFacilityResponsible = {
  path: (obj)=>`${facility}/${obj.idFacility}/responsible_persons/${obj.id}`,
  type: 'GET'
}
export const delFacilityResponsible =(idFacility) =>( {
  path: (obj)=>`${facility}/${idFacility}/responsible_persons/${obj.id}`,
  type: 'DELETE'
})
export const editFacilityResponsible = {
  path: (obj)=>`${facility}/${obj.idFacility}/responsible_persons/${obj.id}`,
  type: 'PUT'
}

// facility machine
export const getFacilityMachine = {
  path: (obj)=>`${facility}/${obj.idFacility}/machine`,
  type: 'GET'
}
export const addFacilityMachine = {
  path: (obj)=>`${facility}/${obj.idFacility}/machine`,
  type: 'POST'
} 
export const getOneFacilityMachine = {
  path: (obj)=>`${facility}/${obj.idFacility}/machine/${obj.id}`,
  type: 'GET'
}
export const delFacilityMachine =(idFacility) =>( {
  path: (obj)=>`${facility}/${idFacility}/machine/${obj.id}`,
  type: 'DELETE'
})
export const editFacilityMachine = {
  path: (obj)=>`${facility}/${obj.idFacility}/machine/${obj.id}`,
  type: 'PUT'
}

//facility user 
export const getFacilityAccess = {
  path: (obj)=>`${facility}/${obj.idFacility}/access_all_project`,
  type: 'GET'
}
export const addFacilityAccess = {
  path: (obj)=>`${facility}/${obj.idFacility}/access_all_project`,
  type: 'POST'
} 
export const delFacilityAccess =(idFacility) =>( {
  path: (obj)=>`${facility}/${idFacility}/access_all_project/${obj.id}`,
  type: 'DELETE'
})

// facility files
const files='/files'
export const getFacilityFiles = {
  path: (obj)=>`${facility}/${obj.idFacility}${files}`,
  type: 'GET'
}
export const addFacilityFiles = {
  path: (obj)=>`${facility}/${obj.idFacility}${files}`,
  type: 'POST'
} 
export const getOneFacilityFiles = {
  path: (obj)=>`${facility}/${obj.idFacility}${files}/${obj.id}`,
  type: 'GET'
}
export const delFacilityFiles =(idFacility) =>( {
  path: (obj)=>`${facility}/${idFacility}${files}/${obj.id}`,
  type: 'DELETE'
})
export const editFacilityFiles = {
  path: (obj)=>`${facility}/${obj.idFacility}${files}/${obj.id}`,
  type: 'PUT'
}
