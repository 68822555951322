import React from 'react';
import { loadShipmenConsumables, deleteShipmentConsumables } from '../../../../api/shipment'
import PageList from '../../../../components/PageListAPI'
const getStatus = status => status ? 'Доставлено' : 'Заказано'
export default function ShipmentConsumables({ match, history, location }) {
  const idProject = match.params.idProject
  return (
    <div className='App-paper'>
      <PageList
        name={(typeof idProject !== 'undefined') ? 'shipmentMaterialProject' : 'shipmentMaterial'}
        columns={[
          (typeof idProject === 'undefined') && { id: 'projectName', name: 'Проект', },
          { id: 'id', name: '№' },
          { id: 'date', name: 'Дата', type: 'date' },
          { id: 'provider', name: 'Поставщик', },
          { id: 'user', name: 'Добавил', },
          { id: 'sum', name: 'Сумма', type: 'price' },
          { id: 'statusRus', name: 'Статус доставки', },
          { id: 'paidStatusRus', name: 'Статус оплаты', },
        ].filter(key => key)}
        title='Покупка оборудования'
        createItem={true}
        deleteItem={true}
        idName='id'
        history={history}
        match={match}
        location={location}
        loadAPI={{
          querty: {
            idProject: idProject
          },
          request: loadShipmenConsumables,
          transform: (res => {
            return {
              count: res.count,
              get: res.get.map(obj => {
                return { ...obj, statusRus: getStatus(obj.allDelivery), paidStatusRus: (obj.paidStatus === 'paid') ? 'Оплачено' : 'Не оплачено' }
              })
            }
          }),
        }}
        deleteAPI={deleteShipmentConsumables}
        headerInit={{
          sortBy:'date', 
          sortDirection:'desc',          
        }}
        disabledDelete={(obj) => obj.related}
      />
    </div>
  )
};

