import React from 'react';
import * as api from '../../../api/doc'
import PageList from '../../../components/PageListAPI'

export default function ActHiddenWorksList({ history, match, location }) {
  return (
    <div className='App-paper'>
      <PageList
        name='actHiddenList'
        title='Акты освидетельствования скрытых работ'
        columns={[
          { id: 'id', name: '№' },
          { id: 'dateCreate', name: 'Дата', type: 'date',  formatDate:'LL'  },
          { id: 'works', name: 'Работы', },
        ]}
        createItem={true}
        deleteItem={true}
        idName='id'
        history={history}
        match={match}
        location={location}
        loadAPI={{
          querty: {
            idProject: match.params.idProject
          },
          request: api.getIHW,
        }}
        deleteAPI={api.deleteIHW}
        headerInit={{
          sortBy: 'date',
          sortDirection: 'desc',
        }}
      />
    </div>
  )
}


