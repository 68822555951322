import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDispath } from '../../functions/fetch'
import { Button, Typography } from '@material-ui/core'
import { getOcrDocById, editOcrDocById } from '../../api/ocr'
import * as actions from '../../reducers/form';
import * as actions2 from '../../reducers/formTable';
import Header from './source/OcrByIdHeader'
import Table from './source/OrderByIdTable'
import moment from 'moment'
import 'moment/locale/ru';
import { positionDefault, autoVat } from './ocrConst'
const formName = 'ocrDocById'
export default function OcrDocById ({ match, history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      amount: 0,
      dateCreate: '',
      amountPosition: positionDefault,
      customer: '',
      supplier: '',
      date: '',
      number: '',
      customerNew: '',
      supplierNew: '',
      dateNew: '',
      numberNew: '',
      datePosition: positionDefault,
      numberPosition: positionDefault,
      recognizedCustomer: { innPosition: positionDefault, kppPosition: positionDefault, namePosition: positionDefault },
      recognizedSupplier: { innPosition: positionDefault, kppPosition: positionDefault, namePosition: positionDefault },
      type: '',
      vat: 0,
      vatPosition: positionDefault,
      verified: false,
      allIds: [],
      byId: {}
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions2.clearFormTable(formName))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
        idDoc: match.params.idDoc
      },
      progress: true,
      request: getOcrDocById,
    })).then(res => {
      let byId = {}
      const allIds = res.get.map((obj, i) => {
        byId['id' + obj.id + '_' + i] = {
          ...obj,
          recognizedName: obj.name,
          recognizedUnit: obj.unitName,
          recognizedQuantity: obj.quantity,
          recognizedPrice: obj.price,
          recognizedAmountWithoutVat: obj.amountWithoutVat,
          recognizedAmount: obj.amount,
          recognizedVat: obj.vat,
          recognizedGoodCode: obj.goodCode,
          recognizedVatRate: obj.vatRate,
          valueVatRate: autoVat.find(e => e.value === obj.vatRate)
        }
        return 'id' + obj.id + '_' + i
      })
      dispatch(actions.formChangeAsObj({
        ...res.header,
        customerNew: res.header.customer,
        supplierNew: res.header.supplier,
        dateNew: moment(res.header.date).format('YYYY-MM-DD'),
        numberNew: res.header.number,
        date: moment(res.header.date).format('YYYY-MM-DD'),
        byId,
        allIds
      }, { name: formName }))
    }).catch(err => console.log(err))
  }, [dispatch, match.params.idProject, match.params.idDoc])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSave = () => {
    const table = values.allIds.map(key => {
      const obj = values.byId[key]
      return {
        id: obj.id,
        name: obj.recognizedName,
        unitName: obj.recognizedUnit,
        quantity: obj.recognizedQuantity,
        price: obj.recognizedPrice,
        amountWithoutVat: obj.recognizedAmountWithoutVat,
        amount: obj.recognizedAmount,
        vat: obj.recognizedVat,
        goodCode: obj.recognizedGoodCode,
        vatRate: obj.recognizedVatRate,
      }
    })
    const header = {
      customer: values.customerNew,
      supplier: values.supplierNew,
      date: values.dateNew,
      number: values.numberNew,
      idCustomer: values.idCustomer,
      idSupplier: values.idSupplier,
    }
    dispatch(actions.formSubmitAPI({
      props: {
        header,
        table
      },
      url: editOcrDocById.path({ id: match.params.idProject, idDoc: match.params.idDoc }),
      method: editOcrDocById.type,
      history: history,
    }, formName))
  }
  const imgPath = getOcrDocById.path({
    id: match.params.idProject,
    idDoc: match.params.idDoc
  })
  console.log(imgPath)
  return <div className='App-paper'>
    <Typography variant='h6'>Распознанный документ</Typography>
    <Header formName={formName} imgPath={imgPath}/>
    <Table formName={formName} imgPath={imgPath}/>
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
    <Button color='primary' component="span" onClick={handleSave}>Сохранить</Button>
  </div>
}