import React from 'react';
import PageList from '../../../components/PageListAPI'
import { getChecklist, deleteChecklist } from '../../../api/list'

export default function Checklist(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='checklistList'
        columns={[{ id: 'name', name: 'Наименование' }, { id: 'dateCreate', name: 'дата создания', type: 'date' }]}
        createItem={true}
        deleteItem={true}
        loadAPI={{
          request: getChecklist,
        }}
        title='Контрольные листы'
        deleteAPI={deleteChecklist}
        {...props}
      />
    </div>
  )
};