import React, { useState, useEffect } from 'react'
import {  useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { fetchDispath } from '../../../functions/fetch'
import { getFacilityById } from '../../../api/facility'
import moment from 'moment'
import 'moment/locale/ru';
export default function FacilityByIdHeader ({ idFacility }) {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    name: '',
    description: '',
    address: '',
    investor: '',
    customer: '',
    contractor: '',
    contractNumber : '',
    contractDate : '',
    ksPrefix : '',
  })
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: idFacility
      },
      progress: true,
      request: getFacilityById,
    })).then(res => {
      setState({
        name: res.get.name,
        description: res.get.description,
        address: res.get.address,
        investor: res.get.investor,
        customer: res.get.customer,
        contractor: res.get.contractor,
        contractNumber: res.get.contractNumber,
        contractDate:  moment(res.get.contractDate).format('DD-MM-YYYY'),
      })
    }).catch(err => console.log(err))
  }, [dispatch, idFacility])
  return (
    <div>
      <Typography>Наименование: {state.name}</Typography>
      <Typography>Адрес: {state.address}</Typography>
      <Typography>Инвестор: {state.investor}</Typography>
      <Typography>Заказчик (Генподрядчик): {state.customer}</Typography>
      <Typography>Подрядчик (Субподрядчик): {state.contractor}</Typography>
      <Typography>Номер договора: {state.contractNumber}</Typography>
      <Typography>Дата договора: {state.contractDate}</Typography>
      <Typography>Описание: {state.description}</Typography>
      <p />
      <Typography variant='h6'>Проекты</Typography>
    </div>
  )
}