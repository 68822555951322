import React from 'react';
import PageList from '../../../components/PageListAPI'
import { getCompanies, deleteCompanies } from '../../../api/list'

export default function Companies(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='companiesList'
        columns={[{ id: 'fullName', name: 'Наименование' }]}
        createItem={true}
        deleteItem={true}
        loadAPI={{
          request: getCompanies,
        }}
        title='Компании'
        deleteAPI={deleteCompanies}
        {...props}
      />
    </div>
  )
};