import template from "./template_crud";

// Act of competition
const aoc = '/doc/actofcompetition';
export const addAOC = template.addOne(aoc);
export const getAOC = template.getList(aoc);
export const getAOCById = template.getById(aoc);
export const deleteAOC = template.deleteById(aoc);
// Car rental
const car = '/doc/carrental';
export const addCarRental = template.addOne(car);
export const getCarRental = template.getList(car);
export const getCarRentalById = template.getById(car);
export const deleteCarRental = template.deleteById(car);
// Use tool
const usertool = '/tooluse'
export const addUseTool = template.postProjectId(usertool);
export const getUseTool = template.getProjectId(usertool);
export const getUseToolById = {
  path: (obj) => `/projects/${obj.idProject}/${usertool}/${obj.id}`,
  type: 'GET'
}
export const deleteUseToolById = template.deleteProjectById(usertool);
// tool write off
const toolwriteof = '/toolwriteoff';
export const addToolWrite = template.postProjectId(toolwriteof);
export const getToolWrite = template.getProjectId(toolwriteof);
export const getToolWriteById = {
  path: (obj) => `/projects/${obj.idProject}/${toolwriteof}/${obj.id}`,
  type: 'GET'
}
export const deleteToolWriteById = template.deleteProjectById(toolwriteof);



// outfit order plan
const outfitplan = '/outfitplan'
export const addOutfitPlan = template.postProjectId(outfitplan);
export const getOutfitPlan = template.getProjectId(outfitplan);
export const getOutfitPlanDay = template.getProjectId(outfitplan + '/day');
export const getOutfitPlanWork = template.getProjectId(outfitplan + '/work');
export const getOutfitPlanById = {
  path: (obj) => `/projects/${obj.idProject}${outfitplan}/${obj.id}`,
  type: 'GET'
}
export const editOutfitPlanById = {
  path: (obj) => `/projects/${obj.idProject}${outfitplan}/${obj.id}`,
  type: 'PUT'
}
export const deleteOutfitPlanById = template.deleteProjectById(outfitplan);

// outfit order fact
const outfitfact = '/outfitfact'
export const addOutfitFact = template.postProjectId(outfitfact);
export const getOutfitFact = template.getProjectId(outfitfact);
export const getOutfitFactDay = template.getProjectId(outfitfact + '/day');
export const getZhor = template.getProjectId(outfitfact + '/zhor');
export const getZhorPrint = template.getProjectId(outfitfact + '/zhor/print');
export const getOutfitFactById = {
  path: (obj) => `/projects/${obj.idProject}${outfitfact}/${obj.id}`,
  type: 'GET'
}
export const getOutfitFactByIdPrint = {
  path: (obj) => `/projects/${obj.idProject}${outfitfact}/${obj.id}/print`,
  type: 'GET'
}
export const deleteOutfitFactById = template.deleteProjectById(outfitfact);


// material_order_provider
const mop = '/doc/material_order_provider';
export const addMOP = template.addOne(mop);
export const getMOP = template.getList(mop);
export const getMOPSummary = template.getList(mop + '/summary');
export const getMOPSummaryPrint = template.getList(mop + '/summary/print');
export const getMOPById = template.getById(mop);
export const printMOPById = template.getById(mop, '/print');
export const updMOPStatusById = template.updById(mop, '/status');
export const updMOPPriceById = template.updById(mop, '/table');
export const addMOPFileById = template.updById(mop, '/file');
export const deleteMOP = template.deleteById(mop);


// inspect hidden works
const ihw = '/doc/insp_hidden_works';
export const addIHW = template.addOne(ihw);
export const getIHW = template.getList(ihw);
export const getIHWById = template.getById(ihw);
export const printIHWById = template.getById(ihw, '/print');
export const updIHWById = template.updById(ihw);
export const deleteIHW = template.deleteById(ihw);