const typeResp = [
  { value: 'customer', label: 'Представитель заказчика' },
  { value: 'techCustomer', label: 'Представитель застройщика' },
  { value: 'techCustomerControl', label: 'Представитель застройщика по вопросам строительного контроля' },
  { value: 'construction', label: 'Представитель лица, осуществляющего строительство' },
  { value: 'constructionControl', label: 'Представитель лица, осуществляющего строительство, по вопросам строительного контроля' },
  { value: 'projectDocCompany', label: 'Представитель лица, осуществляющего подготовку проектной документации' },
  { value: 'techSupervision', label: 'Представитель лица, выполнившего работы, подлежащие освидетельствованию' },
  { value: 'other', label: 'Представители иных лиц' },
]
export default typeResp;