import React, { useState } from 'react'
import { Table, TableBody, TableHead, TableRow, TableCell, TableSortLabel, TableContainer } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment'
import 'moment/locale/ru';
const useStyles = makeStyles(theme => ({
  container: {
    height: '90vh'
  }
}));
export default function KS2TableList({ formName }) {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const classes = useStyles()
  const tableGroup = useSelector(state => state.formTable['groupEstimate'] || state.formTable.default)
  const tableResource = useSelector(state => state.formTable['groupResEstimate'] || state.formTable.default)
  const tableKS2 = useSelector(state => state.form[formName] || state.form.default)
  const { allIds = [], byId = {} } = tableKS2.values
  const handleRequestSort = (event, property) => {
    let orderNew = 'desc';
    if (orderBy === property && order === 'desc') {
      orderNew = 'asc';
    }
    setOrder(orderNew)
    setOrderBy(property)
  };
  const tableHead = [
    { name: '№ смета', id: 'number' },
    { name: 'Наименование', id: 'name' },
    { name: 'Ед.изм.', id: 'storageUnit' },
    { name: 'Количество', align: 'right', id: 'count' },
    { name: 'Цена', align: 'right', id: 'price' },
    { name: 'Сумма', align: 'right', id: 'summ' },
  ]
  return (<React.Fragment>
    <TableContainer className={classes.container}>
      <Table size='small' stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>№ п.п.</TableCell>
            {tableHead.map(obj => {
              const align = obj.align || 'inherit'
              return (<TableCell
                key={obj.id}
                align={align}
                sortDirection={orderBy === obj.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === obj.id}
                  direction={order}
                  onClick={(e) => handleRequestSort(e, obj.id)}
                >
                  {obj.name}
                </TableSortLabel>
              </TableCell>);
            })}
            {allIds.map(key => {
              const obj = byId[key]
              const align = obj.align || 'inherit'
              return (<TableCell
                key={obj.id}
                align={align}
              >
                {moment(obj.dateStart).format('DD.MM.YYYY')}
                <br />
                {moment(obj.dateEnd).format('DD.MM.YYYY')}
              </TableCell>);
            })}
            <TableCell>Остаток </TableCell>
            <TableCell> Кол-во по факту</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableGroup.allIds.map(id => {
            return <Row
              key={id}
              tableResource={tableResource}
              tableHead={tableHead}
              group={tableGroup.byId[id]}
              groupId={id}
              order={order}
              orderBy={orderBy}
              KS2={tableKS2.values}
            />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </React.Fragment>)
}

function desc(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}
function stableSort(array, objs, orderBy, order) {
  const stabilizedThis = array.map(key => ([key, objs[key][orderBy]]));
  const ord = order === 'desc' ? 1 : -1
  stabilizedThis.sort((a, b) => {
    return ord * desc(a[1], b[1]);
  });
  return stabilizedThis.map(el => el[0]);
}
function formatDere(a, b, f) {
  const count = x => ((x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0));
  const max = count(a) > count(b) ? count(a) : count(b)
  const cof = Math.pow(10, max)
  return Math.round(f(a * cof, b * cof)) / cof
}
function Row(props) {
  const [show, changeShow] = useState(true)
  const { tableHead, tableResource, group,
    order, orderBy, KS2 } = props;
  return (
    <React.Fragment>
      <TableRow>
        <TableCell onClick={() => changeShow(!show)} colSpan={8 + KS2.allIds.length}>
          <Typography variant='body1'>{group.number + '. ' + group.name}</Typography>
        </TableCell>
      </TableRow>
      {
        show && stableSort(group.res, tableResource.byId, orderBy, order)
          .map((id, i) => {
            const obj = tableResource.byId[id];
            let ksCount = 0
            if (obj.ks2) Object.values(obj.ks2).forEach(e => {
              ksCount = formatDere(ksCount,e,(a,b)=>(a+b))
            })
            return <TableRow key={id}>
              <TableCell>{group.number + '.' + (i + 1)}</TableCell>
              {tableHead.map(e => {
                return <TableCell key={e.id} align={e.align}>{obj[e.id]}</TableCell>
              })}
              {KS2.allIds.map(key => {
                const ks = KS2.byId[key]
                if (obj.ks2) {
                  return <TableCell key={key}>{obj.ks2[ks.id] || 0}</TableCell>
                }
                else return <TableCell key={key}>0</TableCell>
              })}
              <TableCell>{formatDere(obj.count, ksCount, (a, b) => (a - b))}</TableCell>
              <TableCell>{obj.countFact}</TableCell>
            </TableRow>
          })}
    </React.Fragment>
  );
}
