import React from 'react'
import PageList from '../../../components/PageListAPI'
import { getPlanListAPI, delPlanByIdAPI } from '../../../api/tariff.api'

export default function TariffList(props) {
  return (
    <div className='App-paper'>
      <PageList
        title='Тарифы'
        name='tariff-list'
        columns={[{ id: 'name', name: 'Название' }]}
        createItem={true}
        deleteItem={true}
        loadAPI={{
          request: getPlanListAPI,
        }}
        deleteAPI={delPlanByIdAPI}
        {...props}
      />
    </div>
  )
}