import { API_BO } from '../conf'
import Login from '../pages/login/Login'
import LoginReset from '../pages/login/LoginReset'
import { share } from '../pages/estimate/index'
import GantShare from '../pages/gant-share/GantShare'
import Machine from '../pages/machine/Machine'
import * as workerApp from '../pages/worker-app/index'

export default function routNotLogin() {
  return [
    { path: API_BO + '/share/:id/estimate', component: share },
    { path: API_BO + '/share/:id/gant', component: GantShare },
    { path: API_BO + '/machine-set/:id', component: Machine },
    { path: API_BO + '/login', component: Login },
    { path: API_BO + '/login-reset/:token', component: LoginReset },
    
    
    { path: API_BO + '/worker-app/login', component: workerApp.login },
    { path: API_BO + '/worker-app/main', component: workerApp.main },
    { path: API_BO + '/worker-app/driver', component: workerApp.driver },
    { path: API_BO + '/worker-app/driver/:id', component: workerApp.machineId },
  ]
};