import React from 'react'
import { Drawer, Button } from '@material-ui/core'
import { connect } from 'react-redux';
import * as actions2 from '../../../../../reducers/form';
import AutoComplite from '../../../../../components/Autocomplite'
import AutocompliteWithLoadCreate from '../../../../../components/AutocompliteWithLoadCreate'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getMSN, addMSNfunction } from '../../../../../api/material'
const styles = theme => ({
  autocomplite: {
    minWidth: 300,
  },
  formControl: {
    minWidth: 200,
    marginTop: 5,
    marginBottom: 3,
  },
  formControl2: {
    width: '100%',
    marginTop: 5,
    marginBottom: 3,
  },
});
class Driver extends React.Component {
  state = { edittext: false }
  onClose = () => {
    this.props.actions.formChange(false, { field: 'draverOpen', name: this.props.formName })
    this.setState({ edittext: false })
  }
  changeMaterial = text => {
    const { form, formName } = this.props
    const { values } = form[formName] || form.default
    this.props.actions.formObjChangeObj({
      balance: text.balance,
      priceOld: text.price,
      idMaterial: text.value,
      name: text.label,
      storageUnit: text.storageUnit,
      idStorageUnit2: text.idStorageUnit,
      storageUnit2Value: { value: text.idStorageUnit, label: text.storageUnit },
      value: text,
    }, { field: 'byId', id: values.draverId, name: formName })
  };
  changeStorageUnit = text => {
    const { form, formName } = this.props
    const { values } = form[formName] || form.default
    this.props.actions.formObjChangeObj({
      idStorageUnit2: text.value,
      storageUnit2Value: text,
    }, { field: 'byId', id: values.draverId, name: formName })
  };
  textEditRow(e, name) {
    const { form, formName } = this.props
    const { values } = form[formName] || form.default
    this.props.actions.formObjChangeObj({
      [name]: e.target.value
    }, { field: 'byId', id: values.draverId, name: formName })
  }
  changeEdit = () => {
    this.setState((state) => ({ edittext: !state.edittext }))
  }
  handleChange = (e) => {
    const { form, formName } = this.props
    const { values } = form[formName] || form.default

    this.props.actions.formObjChangeObj({
      idEstimate: e.value,
      estimateName: e.label,
    }, { field: 'byId', id: values.draverId, name: formName })
  }
  render() {
    const { form, formName } = this.props
    const { values } = form[formName] || form.default
    const id = values.draverId
    const open = values.draverOpen || false
    const obj = values.byId[id] || { value: { storageUnit: '' } }
    const materialArr = this.props.autocomplite.material || this.props.autocomplite.default
    const storageUnitArr = this.props.autocomplite.storageUnit || this.props.autocomplite.default
    let errorCount = obj.balance ? (obj.balance < obj.count) : false
    if (obj.idStorageUnit !== obj.idStorageUnit2) {
      errorCount = obj.balance ? (obj.balance < obj.count * obj.coef) : false
    }
    return (<Drawer anchor="top" open={open} onClose={this.onClose} >
      <div className='App-paper' >
        <AutoComplite
          palaseHolder='Материал'
          arr={materialArr.arr}
          action={this.changeMaterial}
          value={obj.value || ''}
          formName={formName}

        />
        {(this.state.edittext || obj.idEstimate) && <AutocompliteWithLoadCreate
          classes={{ formControl: this.props.classes.formControl2 }}
          field='idEstimate'
          name=''
          path={getMSN.path({ id: this.props.idProject, idMaterial: obj.idMaterial || 0 })}
          createAPI={addMSNfunction(this.props.idProject, obj.idMaterial)}
          createBody='name'
          change={this.handleChange}
          meta={{
            field: 'idEstimate' + obj.idMaterial,
            value: 'id',
            labelFunc: (obj) => obj.name
          }}
          value={{ value: obj.idEstimate || '', label: obj.estimateName || '' }}
          formChange={false}
          key={id}
          formName={formName}

        />}
        <TextField
          fullWidth
          error={errorCount}
          label='Количество'
          margin="dense"
          type="number"
          value={obj.count}
          onChange={(e) => this.textEditRow(e, 'count')}
          helperText={errorCount ? 'Больше чем по смете' : ''}
        />
        <TextField
          fullWidth
          label='Цена'
          margin="dense"
          type="number"
          value={obj.price}
          onChange={(e) => this.textEditRow(e, 'price')}
        />
        <AutoComplite
          arr={storageUnitArr.arr}
          action={this.changeStorageUnit}
          value={obj.storageUnit2Value}
          formName={formName}

        />
        <TextField
          fullWidth
          margin="dense"
          type="coef"
          value={obj.coef}
          onChange={(e) => this.textEditRow(e, 'coef')}
        />
        <Button onClick={this.changeEdit}>Изменить</Button>
        <Button onClick={this.onClose}>Закрыть</Button>
        <Button onClick={() => this.props.add()}>Добавить еще</Button>
      </div>
    </Drawer>);
  }
}
const mapStateToProps = store => {
  return {
    autocomplite: store.autocomplite,
    form: store.form,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    actions: {
      formObjDelete: meta => dispatch(actions2.formObjDelete(meta)),
      formArrayDelete: (obj, meta) => dispatch(actions2.formArrayDelete(obj, meta)),
      formObjChangeObj: (obj, meta) => dispatch(actions2.formObjChangeObj(obj, meta)),
      formChange: (obj, meta) => dispatch(actions2.formChange(obj, meta)),
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Driver));
