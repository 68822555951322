import React, { useCallback } from 'react'
import { getZhor, getZhorPrint } from '../../../api/doc'
import PageList from '../../../components/PageListAPI'
import { useDispatch } from 'react-redux'
import print from '../../../functions/print'
import { fetchDispath } from '../../../functions/fetch'
import { Button } from '@material-ui/core'

export default function ZhorList({ history, match, location }) {
  return <div className='App-paper'>
    <PageList
      name='zhorList'
      title='Общий журнал работ'
      columns={[
        { id: 'rowNumber', name: '№', width: '20px' },
        { id: 'date', name: 'Дата ', type: 'date', formatDate: 'LL', width: '150px' },
        { id: 'work', name: 'Наименование работ', },
      ]}
      deleteItem={false}
      idName='id'
      history={history}
      match={match}
      location={location}
      loadAPI={{
        param: {
          id: match.params.idProject
        },
        request: getZhor,
      }}
      headerInit={{
        sortBy: 'date',
        sortDirection: 'desc',
      }}
      click={false}
      headerLink={<HeaderZhor idProject={match.params.idProject} />}
    />
  </div>
}

function HeaderZhor({idProject}) {
  const dispatch = useDispatch()
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          id: idProject,
        },
        request: getZhorPrint,
      }
    )
  }
  return <div>
    <Button color='primary' onClick={handlePrint} >Печать </Button>
  </div>
}