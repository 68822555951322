import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import AutoCompliteWithLoad from '../../../../components/AutocompliteWithLoad'
import * as apiList from '../../../../api/list'
import * as api from '../../../../api/shipment'
import * as actions from '../../../../reducers/form';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 120,
    marginTop: 5,
    marginBottom: 3,
    width: '80%'
  },
})

class ShipmentConsumablesByIdAdd extends React.Component {
  constructor(params) {
    super(params)
    const idMaterial = params.match.params.idMaterial
    if (!idMaterial) {
      this.handleOk = this.handleSave
    }
    else this.handleOk = this.handleUpdate
    params.actions.formInitialize({ idConsum: '', price: '', count: '' })
    this.state = { formName: 'ShipmentConsumablesByIdAdd' }

  }
  handleSave = (e) => {
    e.preventDefault();
    const { formName } = this.state
const { form } = this.props
const { values } = form[formName] || form.default

    const params = {
      count: values.count,
      price: values.price,
      idConsum: values.idConsum
    }
    this.props.actions.formSubmit({
      props: params,
      url: api.addShipmenConsumablesById.path({ id: this.props.match.params.idShipment }),
      method: api.addShipmenConsumablesById.type,
      history: this.props.history
    })
  };
  handleUpdate = (e) => {

  }
  textEditText = (e) => {
    const { name, value } = e.target
    this.props.actions.formChange(value, { field: name })
  }
  changeMaterial = obj => {
    this.props.actions.changeText({
      idMaterial: obj.id,
      balance: obj.balance,
      priceOld: obj.price
    });
  }
  componentWillUnmount() {
    this.props.actions.formDestroy()
  }
  render() {
    const { formName } = this.state
    const { form, classes } = this.props
    const { values } = form[formName] || form.default
    return (
      <div className='App-paper'>
        <form autoComplete="off" onSubmit={this.handleOk}>
          <AutoCompliteWithLoad
            classes={classes}
            field='idConsum'
            name='Материалы'
            path={apiList.getEquipment.path}
            meta={{
              field: 'idConsum',
              value: 'id',
              labelFunc: (obj) => obj.name
            }}
            formName={formName}
          />
          <FormControl className={classes.formControl} margin='dense'>
            <TextField
              margin="dense"
              label='Стоимость единицы'
              name='price'
              type="text"
              fullWidth
              onChange={this.textEditText}
              value={values.price || ''}
              autoComplete={'off'} />
          </FormControl>
          <FormControl className={classes.formControl} margin='dense'>
            <TextField
              margin="dense"
              label='Количество'
              id="count"
              name='count'
              type="text"
              fullWidth
              onChange={this.textEditText}
              value={values.count || ''}
              autoComplete={'off'} />
          </FormControl>
          <div className={classes.button} margin='dense'>
            <Button color="primary" onClick={() => this.props.history.goBack()}>Назад </Button>
            <Button type="submit" color="primary">Сохранить</Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    form: store.form,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    actions: {
      formDestroy: () => dispatch(actions.formDestroy({ name: 'ShipmentConsumablesByIdAdd' })),
      formInitialize: obj => dispatch(actions.formInitialize(obj, { name: 'ShipmentConsumablesByIdAdd' })),
      formSubmit: (obj) => dispatch(actions.formSubmitAPI(obj, 'ShipmentConsumablesByIdAdd')),
      formChange: (obj, meta) => dispatch(actions.formChange(obj, { ...meta, name: 'ShipmentConsumablesByIdAdd' })),
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ShipmentConsumablesByIdAdd));