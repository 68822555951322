import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../../../reducers/autocomplite'
import { addFilterAutocomplite } from '../../../../reducers/autocomplite'
import { loadProvider, getEquipment } from '../../../../api/list'
import { addShipmenConsumables } from '../../../../api/shipment'
import { addProvider, } from '../../../../api/list'
import DocTable from '../../../../components/DocTable'
import DocAdd from '../../../../components/DocAdd'
import AllPrice from './source/AllPrice'
import CompaniesAdd from '../../../list/provider/ProviderAdd'

const row = {
  "name": "",
  "count": "",
  "price": "",
  "idMaterial": "",
  'storageUnit': "",
  'value': null
}
const formName = 'ShipmentConsumablesAdd'
const formAuto = 'consumables'
export default function ShipmentConsumablesAdd({ match, history }) {
  const dispatch = useDispatch()
  const idProject = match.params.idProject
  const formOrder = useSelector(state => state.form.selectOrders || state.form.default)
  const arrOrder = formOrder.values.arr || []
  const equipment = useSelector(state => state.autocomplite[formAuto] || state.autocomplite.default)
  const [ordersLoad, setOrdersLoad] = useState(arrOrder.length === 0)
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values = {} } = form
  useEffect(() => {
    dispatch(actions.formInitialize({ idProvider: '', byId: {}, allIds: [], idRow: 0, dateDelivery: '' }, { name: formName }))
    dispatch(initialAutocomplite({ field: formAuto }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: 'selectOrders' }))
      dispatch(clearAutocomplite({ field: formAuto }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getEquipment.path, {
      field: formAuto,
      value: 'id',
      hidden: ['storageUnit'],
      labelFunc: (obj) => obj.name
    }))
  }, [dispatch])
  useEffect(() => {
    if (ordersLoad) return;
    if (arrOrder.length === 0) return;
    if (!equipment.load) return;
    arrOrder.forEach((obj, i) => {
      const findMaterial = equipment.arr.find(e => obj.id.toString() === e.value)
      if (!findMaterial) return;
      const newRow = i + 1
      dispatch(actions.formObjAdd({
        ...row,
        balance: findMaterial.balance,
        priceOld: findMaterial.price,
        idMaterial: findMaterial.value,
        storageUnit: findMaterial.storageUnit,
        name: findMaterial.label,
        value: findMaterial,
        idOrder: obj.idOrder,
        count: obj.count
      }, { field: 'byId', id: 'id' + newRow, name: formName }))
      dispatch(actions.formArrayAdd('id' + newRow, { field: 'allIds', name: formName }))
      dispatch(actions.formChange(newRow, { field: 'idRow', name: formName }))
      dispatch(addFilterAutocomplite(findMaterial.value, { field: formAuto, id: 'id' + newRow }))
    })
    setOrdersLoad(true)
  }, [dispatch, arrOrder, equipment, ordersLoad])
  const handleSave = () => {
    const arr = []
    const arrOrder = [];
    values.allIds.forEach(row => {
      const obj = values.byId[row]
      const count = parseFloat(obj.count.toString().replace(',', '.').replace(' ', ''))
      const price = parseFloat(obj.price.replace(',', '.').replace(' ', ''))
      if (obj.idMaterial > 0 && count > 0 && price > 0) {
        arr.push({
          count: count,
          price: price,
          idConsum: obj.idMaterial,
        })
      }
      if (obj.idOrder) arrOrder.push(obj.idOrder);
    });
    dispatch(actions.formSubmitAPI({
      props: {
        data: arr,
        idProject: idProject,
        idProvider: values.idProvider,
        dateDelivery: values.dateDelivery,
        orders: arrOrder
      },
      url: addShipmenConsumables.path,
      method: addShipmenConsumables.type,
      history: history
    }, formName))
  }
  return (
    <div className='App-paper'>
      <DocAdd
        history={history}
        formName={formName}
        title='Добавить оборудование'
        handleSave={handleSave}
        noTableProps={[
          {
            field: 'idProvider', label: 'Поставщик', type: 'autoLoadCreateModal', textProps: {
              createAPI: addProvider,
              createBody: 'name',
              path: loadProvider.path,
              meta: {
                field: 'provider',
                value: 'id',
                labelFunc: (obj) => obj.name + ' '+ obj.inn || ''
              },
              modal:({ handleClose })=><CompaniesAdd match={match} modal={true} modalClose={handleClose}/>
            }
          },
          { field: 'dateDelivery', label: 'Дата поставки', type: 'text', textProps: { type: 'date' } },
        ]}
      >
        <DocTable
          formName={formName}
          column={[
            {
              id: 'value', type: 'auto', name: 'Материал', formArr: formAuto, width: '50%',
              filtered: true, changeValue: text => ({ idMaterial: text.value, value: text, balance: text.balance, storageUnit: text.storageUnit })
            },
            { id: 'price', type: 'number', name: 'Цена', width: '20%' },
            { id: 'count', type: 'number', name: 'Количество', width: '20%' },
            { id: 'storageUnit', type: 'string', name: 'Ед.изм' }
          ]}
          rowDefault={row}
          idRowStart={arrOrder.length}
        />
        <AllPrice formName={formName} />
      </DocAdd>
    </div>
  )
}
