import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import { getInventory, moveStorage } from '../../api/inventory'
import { getOurStorage } from '../../api/storage'
import DocTable from '../../components/DocTable'
import DocAdd from '../../components/DocAdd'

const formName = 'inventoryMoveToStorage'
const formArr = 'tool'

export default function InventoryMoveStorageAdd({ match, history }) {
  const idProject = match.params.id
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      allIds: [],
      byId: {},
      idStorage: ''
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: formArr }))
    return () => {
      dispatch(clearAutocomplite({ field: formArr }))
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getInventory.path + '?idProject=' + idProject
      , {
        field: formArr,
        value: 'inventoryNumber',
        labelFunc: (obj) => obj.inventoryNumber.toString().padStart(5, '0') + ' ' + obj.name
      }))
  }, [dispatch, idProject])
  const handleSave = () => {
    let arr = []
    values.allIds.forEach(row => {
      const obj = values.byId[row]
      if (obj.idInventory > 0) {
        arr.push(obj.idInventory)
      }
    });
    dispatch(actions.formSubmitAPI({
      props: {
        idStorage: values.idStorage,
        idProject: idProject,
        arr: arr,
      },
      url: moveStorage.addOne.path,
      method: moveStorage.addOne.type,
      history: history
    }))
  }
  return <div className='App-paper'>
    <DocAdd
      history={history}
      formName={formName}
      title='Переместить на склад'
      handleSave={handleSave}
      noTableProps={[
        {
          field: 'idStorage', label: 'Склад', type: 'autoLoad', textProps: {
            path: getOurStorage.path,
            meta: {
              field: 'storage',
              value: 'id',
              labelFunc: (obj) => obj.name
            }
          },
          xs:6
        }
      ]}
    >
      <DocTable
        formName={formName}
        column={[{
          id: 'value', type: 'auto', name: 'Инструмент', formArr: formArr,
          filtered: true, changeValue: text => ({ idInventory: text.value, value: text })
        }]}
        rowDefault={{
          idInventory: '',
          value: null
        }}
      />
    </DocAdd>
  </div>
}