import template from "./template_crud";

const path = '/remarks'
export const getRemarksListAPI = template.getProjectId(path)
export const getRemarksOneAPI = template.getProjectIdById(path)
export const addRemarksOneAPI = template.postProjectId(path)
export const updRemarksOneAPI = template.putProjectId(path)
export const delRemarksOneAPI = template.deleteProjectById(path)
//upd status
function all(pathEnd) {
  const temp = (type) => ({
    path: (obj) => `/projects/${obj.idProject}${path}/${obj.id}/${pathEnd}`,
    type: type
  })
  return {
    get: temp('GET'),
    post: temp('POST'),
    put: temp('PUT'),
    delete: {
      path: (obj) => `/projects/${obj.idProject}${path}/${obj.id}/${pathEnd}/${obj.idDelete}`,
      type: 'DELETE'
    }
  }
}
export const allRemarkStatus = all('status');
export const allRemarkMessage = all('messages');
export const allRemarkFiles = all('files');
export const allRemarkInspector = all('inspector');
