import React from 'react'
import { Popover, FormControl, Select, MenuItem } from '@material-ui/core'
import { formObjChangeObj } from "../../../../reducers/form";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const arr = [
  { label: 'Наименование*', value: 'name' },
  { label: 'Номер', value: 'number' },
  { label: 'Количество*', value: 'count' },
  { label: 'Ед. изм.*', value: 'storageUnit' },
  { label: 'Стоимость единицы', value: 'price' },
  { label: 'Тэги', value: 'tags' },
  { label: 'Тип, марка, обозначение документа, опросного листа', value: 'typeMark' },
  { label: 'Артикул', value: 'vendorCode' }
]
export default function PopperColumnType({ anchorEl, selectKey, onClose, column, formName }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const handleSelectChange = (e) => {
    let selected = { label: '' }
    if (e.target.value !== '') {
      selected = arr.find((l) => l.value === e.target.value)
    }
    dispatch(formObjChangeObj({ select: e.target.value, selectName: selected.label }, { field: 'column', id: selectKey, name: formName }))
    onClose()
  }
  return <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    onClose={onClose}
  >
    <div className='App-paper'>
      <FormControl className={classes.formControl}>
        <Select
          value={(selectKey) ? column[selectKey].select : ''}
          onChange={handleSelectChange}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          {arr.map(e => <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>)}
        </Select>
      </FormControl>
    </div>
  </Popover>
}