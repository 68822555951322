import React from 'react';
import { getCertificates, delCertificates } from '../../api/certificates'
import PageList from '../../components/PageListAPI'
import { certificateType } from './certificates.enum'


export default function CertificatesList({ history, match, location }) {
  return <div className='App-paper'>
    <PageList
      name='CertificatesList'
      title='Сертификаты'
      columns={[
        { id: 'docNumber', name: 'Номер документа', },
        { id: 'dateCreate', name: 'Дата создания', type: 'date', formatDate: 'LL' },
        { id: 'name', name: 'Наименование документа', },
        { id: 'typeDoc', name: 'Тип документа', },
        { id: 'validTo', name: 'Действителен до', type: 'date', formatDate: 'LL'  },
      ]}
      createItem={true}
      deleteItem={true}
      idName='id'
      history={history}
      match={match}
      location={location}
      loadAPI={{
        param: {
          id: match.params.idProject
        },
        request: getCertificates,
      }}
      filterProps={[
        {
          arr: certificateType.map(e=>({ value: e, name: e})),
          field: 'typeDoc',
          name: 'Тип документа',
          type: 'select'
        },
        {
          type: 'find_text',
          field: 'docNumber',
          name: 'Поиск по номеру'
        },
        {
          type: 'find_text',
          field: 'name',
          name: 'Поиск по наименованию'
        },
      ]}
      deleteAPI={delCertificates(match.params.idProject)}
      headerInit={{
        sortBy:'date', 
        sortDirection:'desc',          
      }}
    />
  </div>
}