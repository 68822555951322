import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import * as actions from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'
import { getMaterials } from '../../../api/material'
import { removeStorageMaterial } from '../../../api/storage'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite, load as loadAutocomplite } from '../../../reducers/autocomplite'
import DocTable from '../../../components/DocTable'
import DocAdd from '../../../components/DocAdd'
import { ENUM_TYPE_REMOVAL } from './removalPMaterial.const'
const formName = 'RemovalPMaterialAdd'
const formArr = 'material'
const formArrEst = 'material_est' // давальческие материалы

export default function RemovalPMaterialAdd({ match, history }) {
  const idProject = match.params.id
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      allIds: [],
      byId: {},
      date: moment(Date.now()).format('YYYY-MM-DD'),
      typeRemoval: ENUM_TYPE_REMOVAL[0].value,
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: formArr }))
    dispatch(initialAutocomplite({ field: formArrEst }))
    return () => {
      dispatch(clearAutocomplite({ field: formArr }))
      dispatch(clearAutocomplite({ field: formArrEst }))
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    if (idProject) {
      const pathMaterial = getMaterials.path({ id: idProject }) + '?type=storage'
      dispatch(fetchDispath({
        progress: true,
        request: { path: pathMaterial, type: 'GET' },
      })).then(res => {
        const arrM = []
        const arrEst = []
        if (res.success) {
          res.get.forEach(obj => {
            arrM.push({
              value: obj.id,
              label: obj.name + ' ' + obj.typeMark + ' ' + obj.vendorCode,
              storage: obj.storage,
              storageUnit: obj.storageUnit
            })
            if (obj.estStatus?.storage > 0) {
              arrEst.push({
                value: obj.id,
                label: obj.name + ' ' + obj.typeMark + ' ' + obj.vendorCode,
                storage: obj.estStatus?.storage,
                storageUnit: obj.storageUnit
              })
            }
          })
        }
        dispatch(loadAutocomplite(arrM, { field: formArr }))
        dispatch(loadAutocomplite(arrEst, { field: formArrEst }))
      })

    }
  }, [dispatch, idProject])
  const handleSave = () => {
    let body = {
      arr: [],
      date: values.date,
      idProject: idProject,
      typeRemoval: values.typeRemoval,
    }
    values.allIds.forEach(key => {
      body.arr.push({
        idMaterial: values.byId[key].id,
        count: values.byId[key].count
      })
    })
    dispatch(actions.formSubmitAPI({
      props: body,
      url: removeStorageMaterial.path,
      method: removeStorageMaterial.type,
      history: history
    }))
  }
  return (
    <div className='App-paper'>
      <DocAdd
        history={history}
        formName={formName}
        title='Списать материалы'
        handleSave={handleSave}
        noTableProps={[
          { field: 'date', label: 'Дата списания', type: 'text', textProps: { type: 'date' } },
          { field: 'typeRemoval', label: 'Тип списания материалов', type: 'autoArr', textProps: { arr: ENUM_TYPE_REMOVAL } },
        ]}
      >
        <DocTable
          formName={formName}
          column={[
            {
              id: 'value', type: 'auto', name: 'Материал', formArr: values.typeRemoval === 'into_production' ? formArr : formArrEst,
              filtered: true, changeValue: text => ({
                value: text,
                count: text.storage,
                storage: text.storage,
                storageUnit: text.storageUnit,
                id: text.value,
              }),
              width: '45%'
            },
            {
              id: 'count', type: 'number', name: 'Количество', title: obj => `max ${obj.storage}`, width: '25%',
              textProps: obj => ({ error: obj.count > obj.storage, helperText: obj.count > obj.storage ? 'Больше чем на складе' : '' })
            },
            { id: 'storageUnit', type: 'string', name: 'Ед.изм' }
          ]}
          rowDefault={{
            count: "",
            storage: "",
            id: "",
            storageUnit: "",
            value: null
          }}
        />
      </DocAdd>
    </div>
  )
}
