import { getProjectId, postProjectId, putProjectId, deleteProjectById  } from "./template_crud";

export const getEstimate = getProjectId('/estimate');
export const deleteEstimate = postProjectId('/estimate/material/del')
export const updEstimateMaterialNumber = {
  path: (obj) => `/projects/${obj.id}/estimate/material/number`,
  type: 'PUT'
}
export const updEstimateWorkNumber = {
  path: (obj) => `/projects/${obj.id}/estimate/work/number`,
  type: 'PUT'
}
const estGroup = '/estimate/group'
export const getGroup = getProjectId(estGroup)
export const addGroup = postProjectId(estGroup)
export const editGroup = putProjectId(estGroup)
export const deleteGroup = deleteProjectById(estGroup)
export const addGroupMaterial = {
  path: (obj) => `/projects/${obj.idProject}/estimate/group/${obj.id}`,
  type: 'POST'
}
export const estimatePrint = getProjectId('/estimate/print')