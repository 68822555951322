import React, { useState, useEffect, useCallback } from 'react';
import { getFacilityFiles, addFacilityFiles, delFacilityFiles } from '../../api/facility'
import { addFile } from '../../api/list'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/formTable';
import { loadFileAPI } from '../../reducers/materialLoad';
import * as actionsForm from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import NotDeleteIcon from '@material-ui/icons/Clear'
import OkDeleteIcon from '@material-ui/icons/Done'
import DeleteIcon from '@material-ui/icons/Delete';
import {
  IconButton, Typography, Table, TableHead,
  TableRow, TableCell, TableSortLabel, TableBody,
  TableContainer, Button, Breadcrumbs, CircularProgress,
  Dialog, DialogTitle, DialogContent, TextField, DialogActions
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import moment from 'moment';
import FileViewer from '../../components/fileViewer/FileViever';

const formName = 'facility-files'
export default function FacilityFilesList({ location, match, history }) {
  const idFacility = match.params.idFacility
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.initFormTable({ name: formName, header: {} }))
    dispatch(actionsForm.formInitialize({
      parent: [],
      folder: null
    }, { name: formName }))
    return () => {
      dispatch(actions.clearFormTable(formName))
      dispatch(actionsForm.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const load = useCallback((parent) => {
    dispatch(fetchDispath({
      querty: {
        parent,
      },
      param: {
        idFacility: idFacility,
      },
      progress: true,
      request: getFacilityFiles,
    })).then(res => {
      dispatch(actions.loadFormTable({
        count: res.count,
        get: res.get.map(e => ({ ...e, date: moment(e.date).format('LLL'), creator: e.surname + ' ' + e.name[0] + '.' + e.patronymic[0] }))
      }, { name: formName, id: 'id' }))
    }).catch(err => console.log(err))
  }, [dispatch, idFacility])

  useEffect(() => {
    load()
  }, [load])
  return <div className='App-paper'>
    <CreateFolder load={load} idFacility={idFacility}/>
    <LoadFile load={load} idFacility={idFacility} />
    <CollapsedBreadcrumbs load={load} />
    <TableFiles load={load} idFacility={idFacility} />
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
  </div>
}

function LoadFile({ load, idFacility }) {
  const idFolder = useSelector(state => state.form?.[formName]?.values.folder)
  const [loadingFile, setLoadingFile] = useState(false)
  const dispatch = useDispatch()
  const handleLoadFile = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoadingFile(true)
    let fileName = e.target.files[0].name
    dispatch(loadFileAPI({ file: e.target.files[0], path: addFile.path }))
      .then(res => {
        if (res.success) {
          dispatch(actionsForm.formSubmitAPI({
            props: {
              name: fileName,
              isFolder: 0,
              idFile: res.id,
              parentId: idFolder,
            },
            url: addFacilityFiles.path({ idFacility }),
            method: addFacilityFiles.type,
            endFunc: () => { load(idFolder) }
          }, formName))
        }
        setLoadingFile(false)
      })
      .catch()
  }
  return <Button disabled={loadingFile} component="label" color='primary'>
    {loadingFile && <CircularProgress size={25} />}
    <span>Загрузить файл</span>
    <input
      type="file"
      style={{ display: "none" }}
      onChange={handleLoadFile}
    />
  </Button>
}

function CreateFolder({ idFacility, load }) {
  const idFolder = useSelector(state => state.form?.[formName]?.values.folder)
  const [open, setOpen] = React.useState(false);
  const [value,setValue] = useState('')
  const dispatch = useDispatch()
  const handleClose = () => {
    setOpen(false);
  };
  const handleCreate = ()=>{
    dispatch(actionsForm.formSubmitAPI({
      props: {
        name: value,
        isFolder: 1,
        parentId: idFolder,
      },
      url: addFacilityFiles.path({ idFacility }),
      method: addFacilityFiles.type,
      endFunc: () => { setOpen(false); load(idFolder) }
    }, formName))
  }
  return (
    <React.Fragment>
      <Button color="primary" onClick={()=>setOpen(true)}>Создать папку</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Новая папка</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            variant="outlined"
            value={value}
            onChange={e=>setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleCreate} color="primary">
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function CollapsedBreadcrumbs({ load }) {
  const arrParent = useSelector(state => state.form?.[formName]?.values.parent || [])
  const dispatch = useDispatch()
  const handleMain = () => {
    dispatch(actionsForm.formChangeAsObj({ parent: [], folder: undefined }, { field: 'folder', name: formName }))
    load()
  }
  const selectParent = (index) => {
    const id = arrParent[index].id
    dispatch(actionsForm.formChangeAsObj({
      parent: arrParent.slice(0, index + 1),
      folder: id
    }, { field: 'folder', name: formName }))
    load(id)
  }
  return (
    <Breadcrumbs maxItems={4} aria-label="breadcrumb" >
      <Typography color="inherit" onClick={handleMain} style={{ cursor: 'pointer' }}>
        Домашняя папка
      </Typography>
      {arrParent.map((e, i) => {
        let onClick;
        if (arrParent.length - 1 !== i) onClick = () => selectParent(i)
        return <Typography key={i} color="inherit" onClick={onClick} style={{ cursor: arrParent.length - 1 !== i ? 'pointer' : undefined }}>
          {e.name}
        </Typography>
      })}
    </Breadcrumbs>
  );
}
const useStylesTable = makeStyles(theme => ({
  row: {
    color: '#000000',
    '&$selected': {
      color: '#aaaaaa'
    }
  },
  selected: {},
  container: {
    height: '65vh'
  }
}));
function TableFiles({ load, idFacility }) {
  const [fileSelected, setFileSelected] = useState({ type: '', fileName: '' })
  const classes = useStylesTable()
  const [openFile,setOpenFile] = useState(false)
  const tableData = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { allIds = [], byId = {} } = tableData
  const dispatch = useDispatch()
  const handleClick = (obj) => {
    if (obj.isFolder) {
      load(obj.id)
      dispatch(actionsForm.formChange(obj.id, { field: 'folder', name: formName }))
      dispatch(actionsForm.formArrayAdd({ id: obj.id, name: obj.fileName }, { field: 'parent', name: formName, end: true }))
    }
    else {
      setFileSelected({ type: (obj.type !== 'xls') ? obj.type : 'xlsx', fileName: obj.filePath})
      setOpenFile(true)
    }
  }
  return <React.Fragment>
    <DialogFile fileSelected={fileSelected} openFile={openFile} setOpenFile={setOpenFile}/>
    <TableContainer className={classes.container}>
      <Table size='small' stickyHeader aria-label="sticky table" >
        <TableHeadCustom />
        <TableBody>{
          allIds.map(row => {
            const obj = byId[row]
            return <TableRowCustom obj={obj} handleClick={handleClick} load={load} idFacility={idFacility} />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </React.Fragment>
}

function TableRowCustom({ obj, handleClick, load, idFacility }) {
  const idFolder = useSelector(state => state.form?.[formName]?.values.folder)
  const [isDeleted, changeDeleted] = useState(false)
  const dispatch = useDispatch()
  const handleDelete = () => {
    const url = delFacilityFiles(idFacility)
    dispatch(actionsForm.formSubmitAPI({
      url: url.path({ id: obj.id }),
      method: url.type,
      endFunc: () => { load(idFolder) ;changeDeleted(false) },
      endFuncFalse:  () => { changeDeleted(false) },
    }, formName))
  }
  if (isDeleted) {
    return <RowDeleted handleOk={handleDelete} handleNot={() => changeDeleted(false)} />
  }
  return <TableRow style={{ cursor: 'pointer' }} onClick={() => handleClick(obj)}>
    <TableCell>{
      obj.isFolder ? <FolderIcon />
        : obj.type
    }</TableCell>
    <TableCell>{obj.fileName}</TableCell>
    <TableCell>{obj.date}</TableCell>
    <TableCell>{obj.surname + ' ' + obj.name[0] + '.' + obj.patronymic[0]}</TableCell>
    <TableCell align='right' padding='none'>
      <IconButton
        aria-label="Delete"
        size='small'
        onClick={(e) => { e.stopPropagation(); changeDeleted(true) }}
      ><DeleteIcon fontSize='small' /></IconButton>
    </TableCell>
  </TableRow>
}

function RowDeleted({ handleOk, handleNot }) {
  return <TableRow >
    <TableCell colSpan={4} > Вы уверенны что хотите удалить эту строку?</TableCell>
    <TableCell align='right' style={{ width: 10 }}>
      <IconButton
        size='small'
        onClick={(e) => { e.stopPropagation(); handleOk(); }}
      >
        <OkDeleteIcon fontSize='small' />
      </IconButton>
      <IconButton
        size='small'
        onClick={(e) => { e.stopPropagation(); handleNot(); }}
      >
        <NotDeleteIcon fontSize='small' />
      </IconButton>
    </TableCell>
  </TableRow>
}

const tableColumn = [
  { value: 'type', label: 'Тип', width: 30 },
  { value: 'fileName', label: 'Наименование', width: '70%' },
  { value: 'date', label: 'Дата создания', width: undefined },
  { value: 'creator', label: 'Создал', width: undefined },
]
function TableHeadCustom() {
  const sortBy = useSelector(state => state.formTable[formName]?.sortBy || 'name')
  const sortDirection = useSelector(state => state.formTable[formName]?.sortDirection || 'asc')
  const dispatch = useDispatch()
  const handleRequestSort = (sortByNew) => {
    let sortDirectionNew = 'desc';
    if (sortBy === sortByNew && sortDirection === 'desc') {
      sortDirectionNew = 'asc';
    }
    dispatch(actions.setSortFormTable({ sortBy: sortByNew, sortDirection: sortDirectionNew }, { name: formName }))
  };
  return <TableHead>
    <TableRow>
      {tableColumn.map(col => {
        const active = sortBy === col.value
        return <TableCell key={col.value} sortDirection={active ? sortDirection : false} style={{ width: col.width }}>
          <TableSortLabel
            active={active}
            direction={sortDirection}
            onClick={() => handleRequestSort(col.value)}
          >
            {col.label}
          </TableSortLabel>
        </TableCell>
      })}
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
}

function DialogFile({ fileSelected, openFile, setOpenFile }) {
  return <Dialog
    open={openFile}
    maxWidth={'lg'}
    keepMounted
    fullWidth
    scroll="paper"
    onClose={() => setOpenFile(false)}
  >
    <DialogContent >
      {openFile && <FileViewer
        fileType={fileSelected.type}
        fileName={fileSelected.fileName}
      />}
    </DialogContent>
  </Dialog>
}
