import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import 'moment/locale/ru';
import * as actions from '../../../reducers/formTable';
import { fetchDispath } from '../../../functions/fetch'
import { getDocMoveProjectById } from '../../../api/storage'

const styles = theme => ({
  table: {
    minWidth: 700,
  },
  cell: {
    color: 'inherit'
  },
  main: {
    margin: theme.spacing(1)
  },
  row: {
    color: '#000000',
    '&$selected': {
      color: '#aaaaaa'
    }
  },
  selected: {},
});
class OneMoveProject extends Component {
  constructor(props) {
    super()
    this.state = {
      name: 'oneMoveProject',
      id: 0,
      date: '',
      provider: '',
      idProject: 0,
      status: 'ordered',
      statusRus: 'Заказано'
    }
    props.actions.init({ name: 'oneMoveProject' })
  }
  componentDidMount() {
    this.load(this.state)
  }
  load = (search) => {
    this.props.actions.fetchDispath({
      param: {
        id: this.props.match.params.idDoc
      },
      progress: true,
      request: {
        path: getDocMoveProjectById(this.props.match.params.id).path,
        type: getDocMoveProjectById(this.props.match.params.id).type
      },
    }).then(res => {
      switch (res.doc.status) {
        case 'delivered': { res.doc.statusRus = 'Доставлено'; break }
        case 'ordered': { res.doc.statusRus = 'Заказано'; break }
        default: res.doc.statusRus = 'Заказано'
      }
      this.setState({ ...res.doc })
      this.props.actions.load({ get: res.get, count: res.get.length }, { name: this.state.name, id: 'id' })
    }).catch(err => console.log(err))
  }
  render() {
    const { classes } = this.props;
    const data = this.props.formTable[this.state.name] || this.props.formTable.default
    return (
      <div className='App-paper'>
        <Typography variant='h6' gutterBottom>
          Документ перемещения на склад
        </Typography><Typography variant="body1" gutterBottom>
          Склад: {this.state.storageSpace}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Дата: {moment(this.state.date).format('LLL')}
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell >Материал</TableCell>
              <TableCell align='right'>Количество</TableCell>
              <TableCell align='right'>Поставлено</TableCell>
              <TableCell align='right'>Ед. изм</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.allIds.map(row => {
              const obj = data.byId[row];
              return (
                <TableRow classes={{ root: classes.row, selected: classes.selected, }} selected={obj.delivery === obj.count}
                  id='row' key={row}>
                  <TableCell component="th" scope="row" className={classes.cell}>{obj.nameProject}</TableCell>
                  <TableCell align='right' className={classes.cell}>{obj.count}</TableCell>
                  <TableCell align='right' className={classes.cell}>{obj.delivery}</TableCell>
                  <TableCell align='right' className={classes.cell}>{obj.storageUnitProject}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Button color="primary" onClick={() => this.props.history.goBack()}>Назад </Button>
      </div>
    )
  };
}

OneMoveProject.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = store => {
  return {
    formTable: store.formTable
  }
}
const mapDispatchToProps = dispatch => {
  return {
    actions: {
      load: (obj, meta) => dispatch(actions.loadFormTable(obj, meta)),
      clear: obj => dispatch(actions.clearFormTable(obj)),
      init: obj => dispatch(actions.initFormTable(obj)),
      fetchDispath: obj => dispatch(fetchDispath(obj)),
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OneMoveProject));