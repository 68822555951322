
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { loadProviderMaterialsById, loadProviderById } from '../../../api/list'
import PageList from '../../../components/PageListAPI'
import { fetchDispath } from '../../../functions/fetch'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom';

export default function ProviderShow(props) {
  const [name, setName] = useState('')
  const [inn,setInn] = useState('')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.idProvider
      },
      progress: true,
      request: loadProviderById,
    })).then(res => {
      setName(res.get.name)
      setInn(res.get.inn)
    }).catch(err => console.log(err))
  }, [dispatch, props.match.params.idProvider])
  const toLinkEdit = React.forwardRef((props2, ref) => (
    <Link ref={ref} to={`${props.match.url}/edit`} {...props2} />
  ));
  return (
    <div className='App-paper'>
      <Button component={toLinkEdit} color="primary" >Изменить</Button>
      <Typography variant='h6'>Поставщик {name}</Typography>
      <Typography >ИНН {inn}</Typography>
      <Typography>Средние цены</Typography>
      <PageList
        name='provider'
        columns={[
          { id: 'name', name: 'Наименование' },
          { id: 'avgPrice', name: 'Средняя цена' },
          { id: 'storageUnit', name: 'Ед.изм.' },
        ]}
        loadAPI={{
          request: loadProviderMaterialsById,
          param: {
            id: props.match.params.idProvider
          }
        }}
        click={false}
        pagination={false}
        tableProps={{
          pagination: false
        }}
        csv={false}
        goBack={false}
        {...props}
      />
      <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
    </div>
  )
}
