import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDispath } from '../../functions/fetch'
import * as actions from '../../reducers/form';
import { getMaterialsList, updateMaterialPrice } from '../../api/material'
import {
  Table, TableBody, TableCell, TableHead, Button,
  TableRow, TableContainer, InputBase, Typography
} from '@material-ui/core'
import IncreacePriceDialog from './materialPrice/IncreacePriceDialog'
const formName = 'materialPrice'
const directionSortConst = {
  ASC: 'asc',
  DESC: 'desc',
};
export default function MaterialPrice({ match, history }) {
  const dispatch = useDispatch()
  const projectId = match.params.id
  useEffect(() => {
    dispatch(actions.formInitialize({ byId: {}, allIds: [], }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: { id: projectId, },
      progress: true,
      request: getMaterialsList,
    })).then(res => {
      let byId = {}
      const allIds = res.get.map((v, i) => {
        const rowId = 'b' + v.id + '_' + i
        byId[rowId] = {
          id: v.id,
          pos: v.number,
          count: v.count,
          name: v.name,
          type: v.typeMark,
          code: v.vendorCode,
          su: v.storageUnit,
          priceNew: Number(v.price) || 0,
          priceOld: Number(v.price) || 0
        }
        return rowId
      })
      const newArr = stableSort(allIds, byId, 'pos', directionSortConst.DESC)
      dispatch(actions.formChangeAsObj({ byId, allIds: newArr }, { name: formName }))
    })
  }, [dispatch, projectId])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byId, allIds = [] } = form.values
  return <div className='App-paper'>
    <Typography variant='h6'>Изменение цен на материалы</Typography>
    <IncreacePriceDialog formName={formName} projectId={projectId}/>
    <TableContainer style={{ height: '83vh' }}>
      <Table size='small' stickyHeader >
        <TableHead>
          <TableRow>
            {['Позиция', 'Наименование', 'Тип, марка', 'Код ', 'Единица измерения', 'Количество'].map((v, i) => {
              return <TableCell key={i} style={{ minWidth: '10%' }}>{v}</TableCell>
            })}
            <TableCell style={{ width: '15%' }}>Старая цена</TableCell>
            <TableCell style={{ width: '15%' }}>Новая цена</TableCell>
            <TableCell style={{ minWidth: '15%' }}>Сумма</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allIds.map(key => {
            const obj = byId[key]
            return <TableRow key={key}>
              {['pos', 'name', 'type', 'code', 'su', 'count'].map(v => {
                return <TableCell key={v}>{obj[v]}</TableCell>
              })}
              <TableCell>{obj.priceOld}</TableCell>
              <EditPrice value={obj.priceNew} id={obj.id} row={key} projectId={projectId} />
              <TableCell>{Math.round(obj.count * obj.priceNew * 100) / 100}</TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}

function EditPrice({ value, id, row, projectId }) {
  const dispatch = useDispatch()
  const [edit, setEdit] = useState(value)

  const handleChange = (e) => {
    dispatch(actions.formObjChangeObj({ priceNew: e.target.value }, { field: 'byId', id: row, name: formName }))
  }
  const handleBlure = () => {
    if (edit === value) return;
    dispatch(fetchDispath({
      param: { id: projectId, idMaterial: id },
      body: {
        price: value
      },
      progress: true,
      request: updateMaterialPrice,
    }))
  }
  return <TableCell>
    <InputBase
      value={value}
      type='number'
      onChange={handleChange}
      onBlur={handleBlure}
      onFocus={(e) =>{ setEdit(value); e.target.select()}}
      fullWidth
    />
  </TableCell>
}

function stableSort(array, objs, orderBy, order) {
  const stabilizedThis = array.map(key => ([key, String(objs[key][orderBy])]));
  const ord = order === directionSortConst.DESC ? 1 : -1
  stabilizedThis.sort((a, b) => {
    return ord * a[1].localeCompare(b[1], undefined, { numeric: true, sensitivity: 'base' })
    // return ord * desc(a[1], b[1]);
  });
  return stabilizedThis.map(el => el[0]);
}