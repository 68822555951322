import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import * as actions from '../../../reducers/form';
import SimpleAdd from '../../../components/SimpleAdd'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import { addPaymentsAPI, getPlanListAPI, getAccountsTListAPI } from '../../../api/tariff.api'

const formName = 'payments-add'
const arrProps = [
  {
    xs: 12, bp: { md: 6 },
    field: 'datePayment', label: 'Дата оплаты', type: 'text', textProps: { type: 'date' }, labelProps: { shrink: true },
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
  {
    xs: 12, bp: { md: 6 },
    field: 'idTariff', label: 'Тариф', type: 'autoLoad',
    textProps: {
      path: getPlanListAPI.path,
      meta: {
        field: 'idTariff',
        value: 'id',
        labelFunc: (obj) => obj.name
      }
    }
  },
  {
    xs: 12, bp: { md: 6 },
    field: 'accountId', label: 'Аккаунт', type: 'autoLoad',
    textProps: {
      path: getAccountsTListAPI.path,
      meta: {
        field: 'accountId',
        value: 'id',
        labelFunc: (obj) => obj.name
      }
    }
  },
  {
    xs: 12, bp: { md: 6 },
    field: 'price', label: 'Сумма', type: 'text', textProps: { type: 'number' },
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
]

export default function PaymentsAdd({ history }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      datePayment: '',
      idTariff: null,
      accountId: null,
      price: 0,
      errorValidate: { datePayment: false, price: false, }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const values = useSelector(state => state.form[formName]?.values)
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        datePayment: values.datePayment,
        idTariff: values.idTariff,
        accountId: values.accountId,
        price: values.price,
      },
      url: addPaymentsAPI.path,
      method: addPaymentsAPI.type,
      history: history
    }, formName))
  };
  return <div className='App-paper'>
    <form autoComplete="off" onSubmit={handleSave}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd
          formName={formName}
          arr={arrProps}
          title='Добавить оплату'
          gridProps={{ xs: 12, direction:'column', alignItems:"stretch"  }}
        />
      </div>
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить</Button>
    </form>
  </div>
}
