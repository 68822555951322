import React, { useEffect, useState } from 'react';
import {
  Button, Tab, Tabs, Typography, Table, TableBody,
  Checkbox, FormControlLabel, Grid, FormControl,
  TableHead, TableRow, TableCell, IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/form';
import { getFacility } from '../../api/facility'
import { addProject } from '../../api/projects'
import { getUserList } from '../../api/users'
import DeleteIcon from '@material-ui/icons/Delete';
import { formObjChangeObj, formArrayDelete, formObjDelete } from '../../reducers/form';
import { addFilterAutocomplite, dellFilterAutocomplite, loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import AutoComplite from '../../components/Autocomplite'
import validateProps from '../../functions/validateProps'
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import AutocompliteWithLoad from '../../components/AutocompliteWithLoad'
import { getProjectOur } from '../../api/projects'
import { formChange } from '../../reducers/form'
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
  button: {
    margin: theme.spacing(1),
  },
  from: {
    margin: theme.spacing(3),
  },
}))
const formName = 'projectAdd'
const formUser = 'userProject'
export default function ProjectAdd({ match, history }) {
  const [valueTab, setValueTab] = useState(0)
  const classes = useStyles()
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const id = match.params.idFacility || ''
  const arrProps = [
    { field: 'name', label: 'Наименование', type: 'text', validator: [(value) => value !== ''], errorText: 'Поле обязательно' },
    { field: 'description', label: 'Описание', type: 'text', textProps: { multiline: true, rows: "4" } },
    {
      field: 'idFacility', label: 'Объект', type: 'autoLoad',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        path: getFacility.path,
        meta: {
          field: 'idFacility',
          value: 'id',
          labelFunc: (obj) => obj.name,
          defaultValue: id,
        },
        selectProps: {
          isDisabled: id !== ''
        }
      },
    },
  ]
  useEffect(() => {
    dispatch(actions.formInitialize({
      description: '', name: '', idFacility: id,
      uById: {}, uAllIds: [], isCopy: false,
      material: true,
      work: true,
      estimate: true,
      copyIdProject: ''
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: formUser }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formUser }))
    }
  }, [dispatch, id])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getUserList.path, {
      field: formUser,
      value: 'id',
      labelFunc: (obj) => obj.name + ' ' + obj.surname
    }))
  }, [dispatch])
  const handleSubmit = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        description: values.description,
        name: values.name,
        idFacility: values.idFacility,
        users: Object.values(values.uById).map(obj => obj.id),
        material: Number(values.material),
        work: Number(values.work),
        estimate: Number(values.estimate),
        copyIdProject: Number(values.copyIdProject),
        isCopy: Number(values.isCopy)
      },
      url: addProject.path,
      method: addProject.type,
      history: history
    }, formName))
  }
  return (<div className='App-paper'>
    <Typography variant='h6'>Добавить проект</Typography>
    <Tabs value={valueTab} onChange={(e, v) => setValueTab(v)} indicatorColor='primary'>
      <Tab label="Основные" />
      <Tab label="Пользователи" />
      <Tab label="Копировать данные" />
    </Tabs>
    <form autoComplete="off" onSubmit={handleSubmit} className={classes.form}>
      {valueTab === 0 && <div style={{ padding: '16px' }}>
        <SimpleAdd
          arr={arrProps}
          formName={formName}
        />

      </div>}
      {valueTab === 1 && <TableUser formName={formName} formUser={formUser} />}
      {valueTab === 2 && <CopyProject />}
      <div className={classes.button}>
        <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
        <Button type="submit" color='primary'>Сохранить</Button>
      </div>
    </form>
  </div>)
}



function TableUser({ formName }) {
  const [idRow, setIdRow] = useState(0)
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { uAllIds = [], uById = {} } = form.values
  const addRow = () => {
    dispatch(actions.formObjAdd({ name: '', value: null, id: '' }, { field: 'uById', id: 'id' + idRow, name: formName }))
    dispatch(actions.formArrayAdd('id' + idRow, { field: 'uAllIds', name: formName }))
    setIdRow(idRow + 1)
  }
  return <Table>
    <TableHead>
      <TableRow>
        <TableCell style={{ width: 10 }} padding={'none'}><IconButton onClick={addRow}  ><AddIcon /></IconButton></TableCell>
        <TableCell>Пользователь</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {uAllIds.map(key => {
        const obj = uById[key]
        return <TableRowAddUser key={key} id={key} obj={obj} formName={formName} formUser={formUser} />
      })}
    </TableBody>
  </Table>
}

function TableRowAddUser({ id, obj, formName, formUser }) {
  const dispatch = useDispatch()
  const [edit, changeEdit] = useState(true)
  const deleteRow = () => {
    if (obj.value) {
      dispatch(dellFilterAutocomplite(obj.value.value, { field: formUser, id: id }))
    }
    dispatch(formArrayDelete(id, { field: 'uAllIds', name: formName }));
    dispatch(formObjDelete({ id: id, field: 'uById', name: formName }));
  }
  const changeUser = text => {
    if (obj.value) {
      dispatch(dellFilterAutocomplite(obj.value.value, { field: formUser, id: id }))
    }
    dispatch(formObjChangeObj({
      id: text.value,
      name: text.label,
      value: text,
    }, { field: 'uById', id: id, name: formName }))
    dispatch(addFilterAutocomplite(text.value, { field: formUser, id: id }))
    changeEdit(false)
  };
  const userArr = useSelector(state => state.autocomplite.userProject || state.autocomplite.default)
  const filterArr = userArr.arr.filter(obj => {
    return !(typeof obj.select !== 'undefined' && obj.select !== id)
  })
  return <TableRow>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <IconButton aria-label="Delete"
        onClick={deleteRow}  ><DeleteIcon /></IconButton>
    </TableCell>
    <TableCell>
      {edit &&
        <AutoComplite
          arr={filterArr}
          action={(e) => { changeUser(e); changeEdit(false) }}
          value={obj.value}
          formName={formName}
        />
      }
      {!edit && <div onDoubleClick={() => changeEdit(true)}>{obj.name}</div>}
    </TableCell>
  </TableRow>
}

function CopyProject() {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const dispatch = useDispatch()
  const setValue = (field) => (value) => {
    dispatch(formChange(value, { name: formName, field: field }))
  }
  const [facilityId, setFacilityId] = useState(null)
  const [all, setAll] = useState(false)
  return <div style={{ padding: '16px' }}>
    <CheckboxOne label='Копировать из шаблона или существующего проекта' value={values.isCopy} setValue={setValue('isCopy')} disabled={false} />
    <CheckboxOne label='Показать все объекты' value={all} setValue={() => setAll(e => !e)} disabled={!values.isCopy} />
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid container
        item
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
        xs={6}>
        <FormControl fullWidth>
          <AutocompliteWithLoad
            classes={{}}
            form={false}
            field='idFacility'
            name='Объект'
            path={getFacility.path + ((!all) ? '?template=true' : '')}
            meta={{
              field: 'facility',
              value: 'id',
              labelFunc: (obj) => obj.name
            }}
            formName={"formName"}
            change={(e) => {
              setFacilityId(e.value)
            }}
            textFieldProps={{
              disabled: !values.isCopy
            }}
          />
          {facilityId && <AutocompliteWithLoad
            classes={{}}
            form={false}
            field='idProject'
            name='Проект'
            path={getProjectOur.path + `?facility=${facilityId}`}
            meta={{
              field: 'projects',
              value: 'id',
              labelFunc: (obj) => `${obj.facility} ${obj.name}`
            }}
            formName={"formName"}
            change={(e) => {
              setValue('copyIdProject')(e.value)
            }}
            textFieldProps={{
              disabled: !values.isCopy
            }}
          />}
        </FormControl>
      </Grid>
      <CheckboxOne label='Копировать материалы' value={values.material} setValue={setValue('material')} disabled={!values.isCopy} />
      <CheckboxOne label='Копировать работу' value={values.work} setValue={setValue('work')} disabled={!values.isCopy} />
      <CheckboxOne label='Копировать смету' value={values.estimate} setValue={setValue('estimate')} disabled={!values.isCopy} />
    </Grid>
  </div>
}

const useStyle = makeStyles(theme => ({
  formControl: {
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
}));

function CheckboxOne({ label, value, setValue, disabled }) {
  const classes = useStyle()
  return <Grid item>
    <FormControlLabel
      className={classes.formControl}
      control={
        <Checkbox
          disabled={disabled}
          checked={value}
          onChange={(e) => { setValue(e.target.checked) }}
          color="primary"
        />
      }
      label={label}
    />
  </Grid>
}