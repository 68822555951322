import template from "./template_crud";

const team = '/team'
export const getTeamList = template.getProjectId(team)
export const getTeamById = template.getProjectIdById(team)
export const addTeam = template.postProjectId(team)
export const deleteTeam = template.deleteProjectById(team)
export const updTeam = template.putProjectId(template)
export const addWorkerToTeam = (idProject) => ({
  path: (obj) => `/projects/${idProject}${team}/${obj.id}`,
  type: 'POST'
})
export const deleteTeamWorker = (idProject, idTeam) => ({
  path: (obj) => `/projects/${idProject}${team}/${idTeam}/${obj.id}`,
  type: 'DELETE'
})