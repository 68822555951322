import React, { useState, useEffect } from 'react'
import * as api from '../../../api/list'
import PageList from '../../../components/PageListAPI'
import { Button, Tabs, Tab } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { fetchDispath } from '../../../functions/fetch'
import { machineStatus } from '../../../enum/machine-status.enum'

export default function MachineOne(props) {
  const [value, setValue] = useState(0)
  const toLinkEdit = React.forwardRef((propsRef, ref) => (<Link to={props.match.url + '/edit'} {...propsRef} />))
  // const handleClickFunction = (row, obj) => {
  //   props.history.push(props.match.url + `/state/${obj.id}/edit`);
  // }
  const toLinkAdd = React.forwardRef((propsRef, ref) => (<Link to={props.match.url + '/state/add'} {...propsRef} />))
  return (
    <div className='App-paper'>
      <MachineOneHeader idMachine={props.match.params.id} match={props.match} />
      <Button component={toLinkEdit} color="primary" >Изменить технику </Button>
      <Tabs value={value} onChange={(e, v) => setValue(v)} indicatorColor='primary'>
        <Tab label='Статус' />
        <Tab label='Контрольные листы' />
      </Tabs>
      {value === 0 && <div>
        <Button component={toLinkAdd} color="primary" >Добавить</Button>
        <PageList
          name='facilityById'
          columns={[
            { id: 'typeStateRus', name: 'Статус' },
            { id: 'startDate', name: 'Дата начала', type: 'date', formatDate: 'DD.MM.YYYY' },
            { id: 'endDate', name: 'Дата окончания', type: 'date', formatDate: 'DD.MM.YYYY' },
          ]}
          idName='id'
          headerInit={{
            obj: {
              typeM: '',
              model: '',
              govNumber: '',
              fuelConsumption: ''
            }
          }}
          loadAPI={{
            param: {
              id: props.match.params.id
            },
            request: api.getMachineState(props.match.params.id),
            transform: (res => {
              return {
                count: res.count,
                get: res.get.map(obj => {
                  return { ...obj, typeStateRus: machineStatus.find(e => e.value === obj.typeState).label }
                })
              }
            }),
          }}
          csv={false}
          click={false}
          // onClickFunction={handleClickFunction}  доделать изменение
          pagination={false}
          createItem={false}
          deleteItem={true}
          deleteAPI={api.deleteMachineState(props.match.params.id)}
          tableProps={{
            pagination: false
          }}
          goBack={false}
          {...props}
        /></div>}
      {value === 1 && <MachineChecklist idMachine={props.match.params.id} props={props}/>}
      <Button color='primary' component="span" onClick={() => props.history.goBack()}>Назад</Button>
    </div>
  )
}


function MachineOneHeader({ idMachine, match }) {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    model: '',
    typeM: '',
    govNumber: '',
    fuelConsumption: '',
  })
  useEffect(() => {
    dispatch(fetchDispath({
      request: api.getMachineById,
      param: {
        id: idMachine
      },
      progress: true,
    })).then(res => {
      setState({
        model: res.get.model,
        typeM: res.get.typeM,
        valueTypeM: { value: res.get.typeM, label: res.get.typeM },
        govNumber: res.get.govNumber,
        fuelConsumption: res.get.fuelConsumption,
      })
    }).catch(err => console.log(err))
  }, [dispatch, idMachine])

  return (
    <div>
      <Typography variant='h6'>{state.model}</Typography>
      <Typography>Тип техники: {state.typeM}</Typography>
      <Typography>Гос. номер: {state.govNumber}</Typography>
      <Typography>Норма потребления топлива: {state.fuelConsumption}</Typography>
      <p />
    </div>
  )
}

function MachineChecklist({ idMachine, props }) {
  const toLinkAdd = React.forwardRef((propsRef, ref) => (<Link to={props.match.url + '/checklist/add'} {...propsRef} />))
  return <div>
  <Button component={toLinkAdd} color="primary" >Добавить</Button>
  <PageList
    name='facilityById'
    columns={[
      { id: 'name', name: 'Наименование' },
    ]}
    idName='id'
    loadAPI={{
      param: {
        id: idMachine
      },
      request: api.getMachineChecklist(idMachine),
    }}
    csv={false}
    click={false}
    pagination={false}
    createItem={false}
    deleteItem={true}
    deleteAPI={api.deleteMachineChecklist(idMachine)}
    tableProps={{
      pagination: false
    }}
    goBack={false}
    {...props}
  /></div>
}