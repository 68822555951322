import { stopLoadRequest, startLoadRequest } from './loading'
import { checkStatus, errorDispath, parser, fetchGenerate } from '../functions/fetch'
import { actionCreatorObj, actionCreatorEmpty } from '../functions/redux'

import { API } from '../conf'
const MAIN = 'materialLoad/'
const LOAD_MATERIAL_FROM_FILE = MAIN + 'LOAD_MATERIAL_FROM_FILE'
const DELETE_MATERIAL_FROM_FILE = MAIN + 'DELETE_MATERIAL_FROM_FILE'
const EDIT_MATERIAL_FROM_FILE = MAIN + 'EDIT_MATERIAL_FROM_FILE'
const EDIT_COLUMN_MATERIAL_FROM_FILE = MAIN + 'EDIT_COLUMN_MATERIAL_FROM_FILE'
const SELECT_COLUMN_MATERIAL_FROM_FILE = MAIN + 'SELECT_COLUMN_MATERIAL_FROM_FILE'
const CLEAR_MATERIAL_LOAD = MAIN + 'CLEAR'
const EDIT_TEXT_MATERIAL_LOAD = MAIN + 'EDIT_TEXT'

export const clearMaterialLoad = actionCreatorEmpty(CLEAR_MATERIAL_LOAD)
export const loadMaterialFromFile = actionCreatorObj(LOAD_MATERIAL_FROM_FILE)
export const deleteMaterialFromFile = actionCreatorObj(DELETE_MATERIAL_FROM_FILE)
export const editMaterialFromFile = actionCreatorObj(EDIT_MATERIAL_FROM_FILE)
export const editColumnMaterialFromFile = actionCreatorObj(EDIT_COLUMN_MATERIAL_FROM_FILE)
export const selectColumnMaterialFromFile = actionCreatorObj(SELECT_COLUMN_MATERIAL_FROM_FILE)
export const editTextMaterialLoad = actionCreatorObj(EDIT_TEXT_MATERIAL_LOAD)
export function loadFileAPI({ file, path }) {
  return dispatch => {
    let data = new FormData()
    data.append('file', file)
    return fetch(API + path, {
      method: 'POST',
      body: data,
      headers: {
        'x-access-token': localStorage.getItem('token'),
      }
    })
      .then(checkStatus).then(parser)
      .catch(errorDispath(dispatch))
  }
}

export function parseFileAPI(obj) {
  return dispatch => {
    dispatch(startLoadRequest())
    let serch = []
    if (typeof obj.id !== 'undefined') serch.push(`id=${obj.id}`)
    if (typeof obj.start !== 'undefined' && obj.start !== '') serch.push(`start=${obj.start}`)
    if (typeof obj.end !== 'undefined' && obj.end !== '') serch.push(`end=${obj.end}`)
    return fetchGenerate(`/materials/parse?${serch.join('&')}`, 'GET')
      .then(resJ => {
        dispatch(stopLoadRequest())
        dispatch(loadMaterialFromFile(resJ))
        return true
      })
      .catch(errorDispath(dispatch))
  }
}

export function loadMaterialFromFileAPI(file) {
  return dispatch => {
    dispatch(startLoadRequest())
    let data = new FormData()
    data.append('file', file)
    fetch(`${API}/files/add`, {
      method: 'POST',
      body: data
    })
      .then(checkStatus).then(parser)
      .then(resJ => {
        if (resJ.success) {
          fetchGenerate(`/materials/parse?id=${resJ.id}`, 'GET')
            .then(resJ => {
              dispatch(stopLoadRequest())
              dispatch(loadMaterialFromFile(resJ))
            })
            .catch(errorDispath(dispatch))
        }
        else this.props.actions.stopLoadRequest()
      })
      .catch(errorDispath(dispatch))
  }
}


export const initialState = {
  allIds: [],
  byId: {},
  column: {},
  columnNew: {},
};

export default function redMaterialLoad(state = initialState, action) {
  switch (action.type) {
    case LOAD_MATERIAL_FROM_FILE: return load(state, action.payload)
    case DELETE_MATERIAL_FROM_FILE: return deleteM(state, action.payload)
    case EDIT_MATERIAL_FROM_FILE: return edit(state, action.payload)
    case EDIT_COLUMN_MATERIAL_FROM_FILE: return editColumn(state, action.payload)
    case SELECT_COLUMN_MATERIAL_FROM_FILE: return selectColumn(state, action.payload)
    case EDIT_TEXT_MATERIAL_LOAD: return editText(state, action.payload)
    case CLEAR_MATERIAL_LOAD: return initialState
    default: return state;
  }
}

function editText(state, obj) {
  const { id, key, value } = obj
  return {
    ...state,
    byId: {
      ...state.byId,
      [id]: {
        ...state.byId[id],
        [key]: value
      }
    }
  }
}

function load(state, obj) {
  if (obj.length === 0) return state
  let columnObj = {}
  let column = {}
  let byId = {}
  let idClell = 0
  let allIds = obj.map(row => {
    let id = 'id' + idClell
    idClell++
    byId[id] = row
    Object.keys(row).forEach(value => {
      if (columnObj[value]) {
        columnObj[value].count++
      }
      else columnObj[value] = { count: 1, visible: true }
    })
    return id;
  })
  Object.keys(columnObj).sort((a, b) => {
    if (a.length < b.length) return -1
    if (a.length > b.length) return 1
    if (a.length === b.length && a < b) return -1
    if (a.length === b.length && a > b) return 1
    return 0
  }).forEach(value => { column[value] = columnObj[value] })
  return {
    ...state.load,
    allIds: allIds,
    byId: byId,
    column: column,
    columnNew: {}
  }
}
function deleteM(state, payload) {
  const { id } = payload;
  let { byId, allIds } = state
  let column = {}
  Object.keys(state.column).forEach(key => {
    if (byId[id][key]) {
      if (state.column[key].count !== 1) column[key] = { ...state.column[key], count: state.column[key].count - 1 }
    }
    else column[key] = state.column[key]
  })
  const { [id]: value, ...second } = state.byId
  return {
    ...state,
    column: column,
    byId: second,
    allIds: allIds.filter((item) => item !== id)
  }
}

function edit(state, payload) {
  const { id, props, text } = payload
  return {
    ...state,
    byId: {
      ...state.byId,
      [id]: {
        ...state.byId[id],
        [props]: text
      }
    },
  }
}
function editColumn(state, payload) {
  return {
    ...state,
    columnNew: {
      ...state.columnNew,
      [payload.id]: payload.text
    }
  }
}

function selectColumn(state, obj) {
  return {
    ...state,
    column: {
      ...state.column,
      [obj.column]: {
        ...state.column[obj.column],
        visible: obj.check
      }
    }
  }
}