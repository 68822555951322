const rulesRBAC = {
    // Сметчик
    estimate: {
      static: [
        'material:create',
        'material:update',
        'material:read',
        'material:readAll',
        'material:delete',
          //
        'project:list',
      ]
    },
    // Закупщик
    purchase: {
      static: [
        // rbac front 
        'doc:read',
        'lists:read',
        //--------------------
        'material:list',
        'material:read',
        'material:readAll',
        //
        'project:list',
        'shipment:create',
        'shipment:update',
        'shipment:read',
        'shipment:delete',
        'order:read',
        'order:create',
        'order:delete',
        'order:read:all',
        'storage:read',
        'storage:create',
        'storage:delete',
        'storage:update',
        'provider:list',
        'provider:read',
        'provider:delete',
        'provider:update',
        'provider:create',
        'equipment:list',
        'equipment:read',
        'equipment:create',
        'storage:our:read',
        'storage:our:create',
        'storage:our:delete',
        'storage:our:update',
        'inventory:read',
      ],
    },
    //админ
    admin: {
      static: [
        // rbac front 
        'doc:read',
        'lists:read',
        //-------------------------
        'material:create',
        'material:update',
        'material:read',
        'material:list',
        'material:readAll',
        'material:delete',

        'shipment:create',
        'shipment:update',
        'shipment:read',
        'shipment:delete',
        //
        'project:read',
        'project:list',
        'project:delete',
        'project:update',
        'project:create',
        'project:analytics',
        'project:user:delete',

        'user:create',
        'user:list',
        'user:read',
        
        'order:read',
        'order:create',
        'order:delete',
        'order:read:all',     

        'provider:list',
        'provider:read',
        'provider:delete',
        'provider:update',
        'provider:create',

        'equipment:list',
        'equipment:read',
        'equipment:create',

        'facility:list',
        'facility:delete',
        'facility:create',

        'work:create',
        'work:delete',
        'work:read',
        'work:readAll',
        
        'taskProvider:create',
        'taskProvider:read',
        'task:read',

        'storage:read',
        'storage:create',
        'storage:delete',
        'storage:update',

        'aoc:read',
        'aoc:create',
        'aoc:delete',


        'worker:create',
        'worker:delete',
        'worker:read',
        'storage:our:read',
        'storage:our:create',
        'storage:our:delete',
        'storage:our:update',

        'templates:read',
        'templates:create',
        'templates:delete',
        'templates:update',

        'useTool:read',
        'useTool:create',
        'useTool:delete',

        'inventory:read',
    ],
    },
    customer: {
        static: [
          // rbac front 
          'doc:read',
          'workplace',
          //--------------------
          'order:read',
          'order:create',
          'order:delete',
          "material:read",
          'aoc:read',
          'aoc:create',
          'aoc:delete',
          'work:read',

          'taskProvider:read',
          'taskProvider:create',

          'storage:read',
          'storage:create',
          'storage:delete',
          'storage:update',

          'useTool:read',
          'useTool:create',
          'useTool:delete',
        ],
      },
  };
  
  export default rulesRBAC;
  export const rules = {
    estimate : 'Сметчик',
    purchase : 'Закупщик',
    admin : 'Админ',
    customer : 'Заказчик',
  }
